import React from 'react';

export default class SortDescIcon extends React.PureComponent<React.HTMLAttributes<HTMLSpanElement>> {
  render() {
    return (
      <span {...this.props}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          role="presentation"
        >
          <path d="M11 6v9.586l-3.793-3.793a.999.999 0 00-1.414 0c-.39.39-.39 1.024 0 1.415l5.5 5.499A.993.993 0 0012 19a.993.993 0 00.707-.293l5.5-5.499a1 1 0 10-1.414-1.415L13 15.586V6a1 1 0 00-2 0z" fill="currentColor" fill-rule="evenodd"/>
        </svg>
    </span>
    );
  }
}