import $ from 'jquery';
import config from '../../../../../config';
import {getErrorMessage} from '../../../../utils/general';
import {showError, showSuccess} from '../../../../layouts/actions';

export const ARTWORK_ADMIN_SPECS_IS_LOADING = 'ARTWORK_ADMIN_SPECS_IS_LOADING';
export const ARTWORK_ADMIN_SPECS_IS_SAVING = 'ARTWORK_ADMIN_SPECS_IS_SAVING';
export const ARTWORK_ADMIN_SPECS_SET_CURRENT_STUDIO = 'ARTWORK_ADMIN_SPECS_SET_CURRENT_STUDIO';
export const ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA = 'ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA';
export const ARTWORK_ADMIN_SPECS_RECEIVE = 'ARTWORK_ADMIN_SPECS_RECEIVE';
export const ARTWORK_ADMIN_SPECS_DELETE = 'ARTWORK_ADMIN_SPECS_DELETE';
export const ARTWORK_ADMIN_SPECS_UPDATE = 'ARTWORK_ADMIN_SPECS_UPDATE';
export const ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL = 'ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL';
export const ARTWORK_ADMIN_SPECS_CHECK_FILE_MODAL = 'ARTWORK_ADMIN_SPECS_CHECK_FILE_MODAL';
export const ARTWORK_ADMIN_SPECS_SET_CURRENT_STATE = 'ARTWORK_ADMIN_SPECS_SET_CURRENT_STATE';
export const ARTWORK_ADMIN_SPECS_SET_SEARCH_STRING = 'ARTWORK_ADMIN_SPECS_SET_SEARCH_STRING';

export const setLoading = loading => ({type: ARTWORK_ADMIN_SPECS_IS_LOADING, loading});

export const changeCurrentStudio = currentStudioId => ({type: ARTWORK_ADMIN_SPECS_SET_CURRENT_STUDIO, currentStudioId});

export const changeCurrentState = currentState => ({type: ARTWORK_ADMIN_SPECS_SET_CURRENT_STATE, currentState});

export const changeSearchString = searchString => ({type: ARTWORK_ADMIN_SPECS_SET_SEARCH_STRING, searchString});

const setSpecModalData = data => ({type: ARTWORK_ADMIN_SPECS_SET_SPEC_MODAL_DATA, data});

export const openSpecModal = (specData, selectedVersion) => dispatch => {
  dispatch(setSpecModalData({...specData, selectedVersion}));
};

export const openSpecNameModal = specData => dispatch => {
  dispatch(setSpecModalData({...specData, editField: 'name'}));
};

export const openSpecParamModal = specData => dispatch => {
  dispatch(setSpecModalData({...specData, editField: 'asset_param_types'}));
};

export const openSpecFlagsModal = specData => dispatch => {
  dispatch(setSpecModalData({...specData, isFlags: true}));
};

export const openSpecSafeZoneTemplate = specData => dispatch => {
  dispatch(setSpecModalData({...specData, isSafeZoneTemplate: true}));
};

export const openQcInstructions = specData => dispatch => {
  dispatch(setSpecModalData({...specData, isQcInstructions: true}));
};

export const closeSpecModal = () => dispatch => {
  dispatch(setSpecModalData(null));
};

export const getSpecs = () => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs`
  })
  .done(res => {
    const {studios, specs, params, metadata_dictionaries, specs_flags} = res;
    const {conditions, fields, statuses} = metadata_dictionaries || {};
    dispatch({
      type: ARTWORK_ADMIN_SPECS_RECEIVE,
      metadataDictionaries: {
        conditions: conditions || [],
        fields: fields || [],
        statuses: statuses || [],
      },
      studios,
      params,
      specs_flags,
      specs
    });
  })
  .fail(error => {
    dispatch(showError(`Could not get specs.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_LOADING,
      loading: false
    });
  });
};

export const deleteSpec = specData => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specData.specID}`
  })
  .done(() => {
    dispatch(showSuccess('Spec has been successfully deleted'));
    dispatch({type: ARTWORK_ADMIN_SPECS_DELETE, specData});
  })
  .fail(error => {
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf(' is used') !== -1) {
      dispatch(getSpecs());
    }
    dispatch(showError(`Could not delete spec.  ${message}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const deleteSpecVersion = versionId => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`
  })
  .done(() => {
    dispatch(showSuccess('Spec version has been successfully deleted'));
  })
  .fail(error => {
    dispatch(showError(`Could not delete spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const getSpecVersion = (versionId, versionName) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`
  })
  .fail(error => {
    dispatch(showError(`Could not get "${versionName}" spec.  ${getErrorMessage(error)}`));
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_LOADING,
      loading: false
    });
  });
};

export const saveSpecVersion = (versionId, specItems) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/version/${versionId}`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(specItems)
  })
  .done(() => {
    dispatch(showSuccess(`Spec version has been successfully updated`));
  })
  .fail(error => {
    dispatch(showError(`Could not save spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecVersionAsNew = (specId, specItems) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specId}/new_version`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(specItems)
  })
  .done(() => {
    dispatch(showSuccess(`Spec version has been successfully created`));
  })
  .fail(error => {
    dispatch(showError(`Could not save spec version.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveNewSpec = (studioId, specName, specItems, callback) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      studio_id: studioId,
      name: specName,
      items: specItems
    })
  })
  .done(() => {
    dispatch(showSuccess(`New spec has been successfully created`));
    callback && callback();
  })
  .fail(error => {
    dispatch(showError(`Could not create new spec.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecField = specData => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  let ajaxParams;
  if (specData.editField === 'name') {
    ajaxParams = {
      method: 'PUT',
      data: {
        name: specData.specName
      }
    };
  } else {
    ajaxParams = {
      method: 'POST',
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        paramOptionID: specData.paramOptionID,
        parameters: specData.parameters,
        assetTypes: specData.assetTypes
      })
    };
  }
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${specData.specID}/${specData.editField}`,
    ...ajaxParams
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(getSpecs());
    dispatch(showSuccess('Spec has been successfully updated'));
  })
  .fail(error => {
    dispatch(showError(`Could not update spec.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveFlags = (spec, flags) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/flags`,
    data: {
      flags
    }
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('Settings has been successfully saved'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {flags}});
  })
  .fail(error => {
    dispatch(showError(`Could not save settings.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecActive = (spec, active) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/active`,
    data: {
      active
    }
  })
  .done(() => {
    dispatch(showSuccess(`Spec has been successfully ${active ? 'activated' : 'archived'}`));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {active}});
  })
  .fail(error => {
    dispatch(showError(`Could not ${active ? 'activate' : 'archive'} spec.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveSpecSafeZoneTemplate = (spec, file) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  const data = new FormData();
  data.append('file', file);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/safe_zone_template`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(specSafeZoneTemplateUrl => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('Safe zone template has been successfully saved'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {specSafeZoneTemplateUrl}});
  })
  .fail(error => {
    dispatch(showError(`Could not save safe zone template.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const removeSpecSafeZoneTemplate = spec => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/safe_zone_template`
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('Safe zone template has been successfully removed'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {specSafeZoneTemplateUrl: null}});
  })
  .fail(error => {
    dispatch(showError(`Could not remove safe zone template.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const saveQcInstructions = (spec, text) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/${spec.specID}/qc_instructions`,
    data: {
      qc_instructions: text
    }
  })
  .done(() => {
    dispatch(closeSpecModal());
    dispatch(showSuccess('QC instructions has been successfully saved'));
    dispatch({type: ARTWORK_ADMIN_SPECS_UPDATE, spec, values: {qcInstructions: text}});
  })
  .fail(error => {
    dispatch(showError(`Could not save QC instructions.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const showImportSpecItemsModal = isShow => ({
  type: ARTWORK_ADMIN_SPECS_IMPORT_ITEMS_MODAL,
  isShow
});

export const importSpecItemsFromCsv = csvFile => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  const data = new FormData();
  data.append('file', csvFile);
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/spec_items`,
    data,
    cache: false,
    processData: false,
    contentType: false
  })
  .done(() => {
    dispatch(showImportSpecItemsModal(false));
    dispatch(getSpecs());
    dispatch(showSuccess('Successfully imported spec items.'));
  })
  .fail(error => {
    dispatch(showError(`Could not import file. ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};

export const toggleCheckFileModal = isShow => ({
  type: ARTWORK_ADMIN_SPECS_CHECK_FILE_MODAL,
  isShow
});

export const checkFile = (studioId, filename, callback) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_SPECS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/specs/check_file`,
    data: {
      studio_id: studioId,
      filename
    }
  })
  .done(res => {
    callback(res.result);
  })
  .fail(error => {
    dispatch(showError(`Could not check file. ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_SPECS_IS_SAVING,
      saving: false
    });
  });
};
