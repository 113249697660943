import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Row, {TABLE_GRAY_BG} from './Row';
import EditDatesModal from './EditDateModal';

const Table = props => {
  const {
    dispatch, headers, rows, rowTitle, editDateModal, showScheduledDates, showCalendarLink, onUpdateDate,
    onUpdateModal, onLockRow
  } = props;

  const updatedRows = useMemo(() => {
    return rows.map(row => {
      const milestones = {};
      row.milestones && row.milestones.forEach(item => {
        milestones[item.scheduling_id] = item;
      })
      return {
        ...row,
        milestones
      }
    });
  }, [rows])

  /*const getMinAndMaxDates = (row, schedulingId) => {
    let minDate = null;
    let maxDate = null;
    let flag = false;
    row && headers.forEach(header => {
      if (header.scheduling_id === schedulingId) {
        flag = true;
      } else {
        const item = row.milestones[header.scheduling_id];
        if (item && item.actual_date) {
          if (!flag) {
            minDate = item.actual_date;
          }
          if (flag && maxDate === null) {
            maxDate = item.actual_date;
          }
        }
      }
    });
    minDate = minDate ? moment(minDate, 'MMM DD, YYYY').toDate() : null;
    maxDate = minDate ? moment(maxDate, 'MMM DD, YYYY').toDate() : null;

    return [minDate, maxDate];
  }*/

  const handleDateClick = (rect, qcProjectId, schedulingId, date) => {
    const row = updatedRows.find(row => row.ProjectID === qcProjectId);
    /*const [minDate, maxDate] = getMinAndMaxDates(row, schedulingId);*/

    dispatch(onUpdateModal({
      show: true,
      qcProjectId,
      schedulingId,
      position: {top: rect.bottom, left: rect.left},
      selectedDate: date ? moment(date, 'MMM DD, YYYY').toDate() : null,
      editActualDate: row && row.lock_scheduling > 0
    }));
  }

  const handleUpdateModalField = (name, value) => {
    dispatch(onUpdateModal({
      [name]: value
    }));
  }

  const handleUpdateMilestone = () => {
    const {qcProjectId, schedulingId, selectedDate, updatePrevDates, updateNextDates} = editDateModal;
    const serverDate = moment(selectedDate).format('YYYY-MM-DD');
    dispatch(onUpdateDate({qcProjectId, schedulingId, serverDate, updatePrevDates, updateNextDates}));
  }

  const handleCloseModal = () => {
    dispatch(onUpdateModal({
      show: false,
      qcProjectId: null,
      schedulingId: null,
      position: {}
    }));
  }

  const handleLockRequest = qcProjectId => {
    dispatch(onLockRow(qcProjectId));
  }

  return (
    <StyledTableWrap>
      <table>
        <thead>
          <tr>
            <th className="number">{rowTitle.name}</th>
            {headers.map(header => {
              const title = 'Milestone ' + header.scheduling_name + ', day offset: ' + header.day_offset;
              return (
                <th title={title} key={header.scheduling_id}>{header.scheduling_name}</th>
              );
            })}
            {showCalendarLink ? <th/> : null}
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {updatedRows.map((row, index) => {
            return row.ProjectID && Object.keys(row.milestones).length ? (
              <Row
                key={index}
                headers={headers}
                row={row}
                rowTitleId={rowTitle.id}
                showScheduledDates={showScheduledDates}
                showCalendarLink={showCalendarLink}
                selectedCell={{
                  qcProjectId: editDateModal.qcProjectId,
                  schedulingId: editDateModal.schedulingId
                }}
                onDateClick={handleDateClick}
                onLockRequest={handleLockRequest}
              />
            ) : null
          })}
        </tbody>
      </table>
      <EditDatesModal
        position={editDateModal.position}
        show={editDateModal.show}
        selectedDate={editDateModal.selectedDate}
        editActualDate={editDateModal.editActualDate}
        minDate={editDateModal.minDate}
        maxDate={editDateModal.maxDate}
        updatePrevDates={editDateModal.updatePrevDates}
        updateNextDates={editDateModal.updateNextDates}
        onUpdateField={handleUpdateModalField}
        onSave={handleUpdateMilestone}
        onClose={handleCloseModal}
      />
    </StyledTableWrap>
  );
};

const StyledTableWrap = styled.div`
  overflow-x: auto;
  table {
    min-width: 100%;

    th {
      background-color: ${TABLE_GRAY_BG};
      font-weight: bold;
      font-size: 11px;
      height: 40px;
      line-height: 40px;
      padding: 0 5px;
      text-align: left;
      white-space: nowrap;
      &.number {
        text-align: center;
        width: 139px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 20px 25px;
    table {
      display: block;
      thead {
        display: none;
      }
      tbody {
        display: block;
      }
    }
  }
`;

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowTitle: PropTypes.object.isRequired,
  editDateModal: PropTypes.object.isRequired,
  showScheduledDates: PropTypes.bool.isRequired,
  showCalendarLink: PropTypes.bool,
  onUpdateModal: PropTypes.func.isRequired,
  onUpdateDate: PropTypes.func.isRequired,
  onLockRow: PropTypes.func
};

Table.defaultProps = {
  headers: [],
  rows: []
}

export default connect()(Table);