import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {
  StyledBaseCloseModal,
  StyledMobileModal,
  StyledModal,
  StyledModalBackdrop,
  StyledModalDialog,
  StyledModalSubmitFooter
} from '../../../../styled/ModalStyled';
import {getRequestsAttachments, sendBatchReports, toggleBatchReportsModal} from '../../actions.js';
import Loader from '../../../../components/Loader';
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import Switch from 'react-switch';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import BatchReportsTable from '../table/batch/BatchReportsTable';
import TextEditableField from '../../../RequestDetails/components/forms/TextEditableField';
import classNames from 'classnames';
import {isManagerUser, isValidFileName} from '../../../../utils/general';
import {showError} from '../../../../layouts/actions';
import isMobile from 'is-mobile';
import config from "../../../../../config";

const Steps = {
  START: 1,
  CONFIRM: 2
};

const ReportCategories = {
  QC: 'QC Report',
  FL_PDF: 'PDF Fixlist',
  FL_EXCEL: 'Excel Fixlist',
};

const BatchReportsModal = props => {
  const {
    dispatch,
    reportdetails: {
      filters,
      details,
      completing,
      batchReportsModalData: {
        work_orders: requestsList,
        attachment_categories: attachmentCategories,
        default_subject: defaultSubjectLine
      }
    }
  } = props;

  const currentWO = !!details.QcStatus && requestsList.find(wo => wo.RequestID === details.RequestID);
  const isNonHardingTestRequest = requestsList.some(wo => !wo.IsHardingTestRequest);

  const forManager = isManagerUser();

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  const modalBodyRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(Steps.START);
  const [selectedRequests, setSelectedRequests] = useState(currentWO ? [currentWO] : []);
  const [subject, setSubject] = useState(defaultSubjectLine);
  const [description, setDescription] = useState('');
  const [distroList, setDistroList] = useState('');
  const [attachments, setAttachments] = useState({});
  const [includeFixlist, setIncludeFixlist] = useState(false);
  const [includeExcelFixlist, setIncludeExcelFixlist] = useState(false);
  const [includeExcelAndPdfFixlist, setIncludeExcelAndPdfFixlist] = useState(false);
  const [minSeverity, setMinSeverity] = useState(2);
  const [includeAttachmentCategories, setIncludeAttachmentCategories] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [attachReportsAsLink, setAttachReportsAsLink] = useState(false);

  const [tableSort, setTableSort] = useState({});
  const [tableFilter, setTableFilter] = useState({});

  const withPdfFixList = includeFixlist && (!includeExcelFixlist || includeExcelAndPdfFixlist);
  const withExcelFixList = includeFixlist && (includeExcelFixlist || includeExcelAndPdfFixlist);

  const handleIncludeFixlist = include => {
    setIncludeFixlist(include);
    setIncludeExcelFixlist(false);
    setIncludeExcelAndPdfFixlist(false);
    setMinSeverity(2);

    if (!include) {
      const items = [...selectedRequests];
      items.forEach(r => {
        delete r[ReportCategories.FL_PDF];
        delete r[ReportCategories.FL_EXCEL];
      });
      setSelectedRequests(items);
    }
  };

  const handleIncludeExcelFixlist = include => {
    setIncludeExcelFixlist(include);
    setIncludeExcelAndPdfFixlist(false);

    const items = [...selectedRequests];
    items.forEach(r => {
      include && delete r[ReportCategories.FL_PDF];
      !include && delete r[ReportCategories.FL_EXCEL];
    });
    setSelectedRequests(items);
  };

  const handleIncludeExcelAndPdfFixlist = include => {
    setIncludeExcelAndPdfFixlist(include);
    setIncludeExcelFixlist(false);

    if (!include) {
      const items = [...selectedRequests];
      items.forEach(r => {
        delete r[ReportCategories.FL_EXCEL];
      });
      setSelectedRequests(items);
    }
  };

  const handleAttachReportsAsLink = value => {
    setAttachReportsAsLink(value);
  }

  const handleIncludeAttachmentCategory = (cat, include) => {
    const arr = include ? [...includeAttachmentCategories, cat.value] : includeAttachmentCategories.filter(catId => catId !== cat.value);
    setIncludeAttachmentCategories(arr);
  };

  const handleClose = () => dispatch(toggleBatchReportsModal(false));

  const handleBack = () => {
    try {
      modalBodyRef.current.scrollTop = 0;
    } catch {}
    setCurrentStep(currentStep - 1);
  }

  const handleSelectRequest = items => {
    items.forEach(r => {
      delete r[ReportCategories.QC];
      delete r[ReportCategories.FL_PDF];
      delete r[ReportCategories.FL_EXCEL];
    });
    setSelectedRequests(items);

    setDistroList('');

    const newAttachments = {...attachments};
    Object.values(newAttachments).forEach(arr => {
      (arr || []).forEach(a => {
        delete a.NewFilename;
        delete a.checked;
      });
    });
    setAttachments(newAttachments);
  };

  const handleSubmitSelectedWOs = () => {
    setCurrentStep(Steps.CONFIRM);
    if (!subject) {
      setSubject(defaultSubjectLine);
    }
    if (!distroList) {
      const t = selectedRequests.map(wo => wo.DistributionList).filter(Boolean);
      let newDistroList = t[0];
      if (t.some(s => s !== newDistroList)) {
        newDistroList = Array.from(new Set(t.map(d => d.split(/(,|;|\n|\r)/g).map(s => s.trim()).filter(s => !['', ',', ';'].includes(s))).reduce((ac, ai) => ac.concat(ai), []))).join(';');
      }
      setDistroList(newDistroList || '');
    }

    const requestToLoadAttachments = selectedRequests.filter(wo => !attachments[wo.RequestID]).map(wo => wo.RequestID);
    if (requestToLoadAttachments.length) {
      dispatch(getRequestsAttachments(requestToLoadAttachments)).then(
        res => setAttachments({...attachments, ...res.attachments})
      );
    }
  };

  const handleSend = () => {
    const requestsMap = {};
    const reqAttachmentsMap = {};
    selectedRequests.forEach(r => {
      requestsMap[r.RequestID] = {
        report_pdf: r[ReportCategories.QC] || r.DefaultFilename,
        fixlist_pdf: withPdfFixList ? (r[ReportCategories.FL_PDF] || (r.DefaultFilename + ' Fixlist')) : undefined,
        fixlist_xlsx: withExcelFixList ? (r[ReportCategories.FL_EXCEL] || (r.DefaultFilename + ' Fixlist')) : undefined
      };
      const reqAttachmentsList = (attachments[r.RequestID] || []).filter(a => includeAttachmentCategories.includes(a.CategoryID) && (a.checked || typeof a.checked !== 'boolean'));
      reqAttachmentsList.forEach(a => {
        reqAttachmentsMap[a.RequestAttachmentID] = (a.NewFilename || (r.DefaultFilename + ' ' + a.Category)) + '.' + a.FileExt;
      });
    });
    const data = {
      requests: requestsMap,
      attachments: reqAttachmentsMap,
      subject,
      description,
      distro_list: distroList,
      include_fixlist: includeFixlist,
      min_severity: minSeverity,
      include_excel_fixlist: includeExcelFixlist,
      include_excel_pdf_fixlist: includeExcelAndPdfFixlist,
      include_attachment_categories: includeAttachmentCategories,
      attach_reports_as_link: attachReportsAsLink,
    };
    dispatch(sendBatchReports(data));
  };

  const isDisabledOkBtn = currentStep === Steps.START ? !selectedRequests.length : (!subject.trim() || !description.trim() || !distroList.trim());

  const handleChangeFilename = (requestId, file, oldName, newName, callback) => {
    newName = (newName || '').trim();
    if (newName.toLowerCase() !== oldName.toLowerCase()) {
      if (newName && !isValidFileName(newName)) {
        dispatch(showError('Filename contains illegal characters.  Please fix it.', 'Invalid filename'));
        return;
      }
      if (file.RequestAttachmentID) {
        const items = {...attachments};
        items[requestId].find(a => a.RequestAttachmentID === file.RequestAttachmentID).NewFilename = newName || undefined;
        setAttachments(items);
      } else {
        const items = [...selectedRequests];
        items.find(r => r.RequestID === requestId)[file.Category] = newName || undefined;
        setSelectedRequests(items);
      }
    }
    callback && callback();
  };

  const handleSelectFile = (requestId, file, checked) => {
    if (file.RequestAttachmentID) {
      const items = {...attachments};
      items[requestId].find(a => a.RequestAttachmentID === file.RequestAttachmentID).checked = checked;
      setAttachments(items);
    }
  };

  const renderAttachedTitles = () => {
    const titlesMap = {}, filesMap = {};
    let withFiles = false;
    const items = [...selectedRequests].sort((a, b) => (a.VersionID - b.VersionID) || (a.RequestID - b.RequestID));
    for (let wo of items) {
      titlesMap[wo.RequestID] = wo.DefaultFilename;
      const isNotPassedWorkOrder = config.qcStatus.QC_PASS !== wo.QcStatusID;
      const files = [
        ...(wo.IsHardingTestRequest ? [] : [
          {
            checked: true,
            isReport: true,
            Category: ReportCategories.QC,
            DefaultFilename: wo.DefaultFilename,
            NewFilename: wo[ReportCategories.QC] || wo.DefaultFilename,
            FileExt: 'pdf'
          },
          ...(withPdfFixList && isNotPassedWorkOrder ? [{
            checked: true,
            isReport: true,
            Category: ReportCategories.FL_PDF,
            DefaultFilename: wo.DefaultFilename + ' Fixlist',
            NewFilename: wo[ReportCategories.FL_PDF] || (wo.DefaultFilename + ' Fixlist'),
            FileExt: 'pdf'
          }] : []),
          ...(withExcelFixList && isNotPassedWorkOrder ? [{
            checked: true,
            isReport: true,
            Category: ReportCategories.FL_EXCEL,
            DefaultFilename: wo.DefaultFilename + ' Fixlist',
            NewFilename: wo[ReportCategories.FL_EXCEL] || (wo.DefaultFilename + ' Fixlist'),
            FileExt: 'xlsx'
          }] : [])
        ]),
        ...(attachments[wo.RequestID] || []).filter(a => includeAttachmentCategories.includes(a.CategoryID)).map(a => ({
          ...a,
          checked: typeof a.checked === 'boolean' ? a.checked : true,
          OriginalFilename: a.Filename,
          DefaultFilename: wo.DefaultFilename + ' ' + a.Category,
          NewFilename: a.NewFilename || (wo.DefaultFilename + ' ' + a.Category)
        }))
      ];
      filesMap[wo.RequestID] = files;
      if (files.length) {
        withFiles = true;
      }
    }
    return (
      <FormGroup>
        <ControlLabel>
          List of attached titles
        </ControlLabel>
        {withFiles &&
          <StyledAttachedTitlesNote>
            {isMobile() ? 'Tap' : 'Click'} to edit filenames
          </StyledAttachedTitlesNote>
        }
        <FormControl.Static componentClass="div">
          <StyledAttachedTitles>
            {selectedRequests.map(wo => {
              const title = titlesMap[wo.RequestID];
              const files = filesMap[wo.RequestID];
              const isPassedWorkOrder = config.qcStatus.QC_PASS === wo.QcStatusID;
              return (
                <li key={wo.RequestID}>
                  <i className="fa-light fa-folder-open"/>
                  {title}
                  {files.length &&
                    <ul>
                      {files.map((file, fIdx) => {
                        const editModePrm1 = wo.RequestID;
                        const editModePrm2 = file.RequestAttachmentID || file.Category;
                        const inEditMode = editMode[0] === editModePrm1 && editMode[1] === editModePrm2;
                        const filename = file.NewFilename;
                        const {OriginalFilename} = file;
                        return (
                          <li
                            key={`${wo.RequestID}-${fIdx}`}
                            title={file.Category}
                            className={classNames({
                              'is-report': !!file.isReport,
                              'in-edit-mode': inEditMode
                            })}
                          >
                            {!!OriginalFilename &&
                              <div
                                className="original-filename"
                                title="Original filename"
                              >
                                <i className="fa-light fa-paperclip"/>
                                {OriginalFilename}
                              </div>
                            }
                            <div
                              className={classNames({
                                'result-filename': true,
                                'is-report': !!file.isReport,
                                'in-edit-mode': inEditMode
                              })}
                            >
                              <Checkbox
                                checked={file.checked}
                                onChange={({target: {checked}}) => handleSelectFile(wo.RequestID, file, checked)}
                                disabled={!!file.isReport}
                              />
                              <i className={`fa-light fa-file${!file.isReport ? '' : file.FileExt === 'xlsx' ? '-excel' : '-pdf'}`}/>
                              {inEditMode ?
                                <TextEditableField
                                  value={filename}
                                  onConfirm={(l, n, v, cb) => handleChangeFilename(wo.RequestID, file, filename, v, cb)}
                                  hiddenHint
                                  isStartEdit
                                  onCloseEditMode={() => setEditMode([])}
                                  allowReset={!!file.NewFilename && file.NewFilename.toLowerCase() !== (file.DefaultFilename || '').toLowerCase()}
                                  resetBtnTitle="Reset"
                                  maxLength={100}
                                /> :
                                  <span
                                    className="btn-edit-filename"
                                    onClick={() => setEditMode([editModePrm1, editModePrm2])}
                                    title={`Edit ${file.Category} filename`}
                                  >
                                    {`${filename}.${file.FileExt}`}
                                  </span>
                              }
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  }
                  {!(files[0] || {}).isReport &&
                    <ul className="no-files">
                      <i className="fa-light fa-triangle-exclamation"/>
                      No QC Report file
                    </ul>
                  }
                  {includeAttachmentCategories.map(catId => !files.some(file => file.CategoryID === catId) && (
                    <ul className="no-files" key={`n-cat-${catId}`}>
                      <i className="fa-light fa-triangle-exclamation"/>
                      No {attachmentCategories.find(cat => cat.value === catId).name} file
                    </ul>
                  ))}
                  {includeFixlist && isPassedWorkOrder && (
                    <ul title="Fixlist will not be included in the report because QC status is Pass">
                      <i className="fa-light fa-circle-info"/>
                      QC Pass: No Fixes Needed
                    </ul>
                  )}
                </li>
              );
            })}
          </StyledAttachedTitles>
        </FormControl.Static>
      </FormGroup>
    );
  };

  const renderSwitchField = (label, checked, onChange) => {
    return (
      <FormGroup>
        <div className="switch-field">
          <Switch
            onChange={onChange}
            checked={checked}
            className={`react-switch${checked ? ' checked' : ''}`}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#F6F6F6"
            onColor="#FFFFFF"
            offHandleColor="#A9A9A9"
            onHandleColor="#7FCF18"
            activeBoxShadow="0px 0px 1px 1px transparent"
            handleDiameter={22}
            height={28}
            width={48}
          />
          <span>{label}</span>
        </div>
      </FormGroup>
    );
  };

  return (
    <StyledMobileModal>
      <StyledModalBackdrop
        onClick={handleClose}
      />
      <StyledModalDialog className="mobile-modal-dialog-custom">
        {completing && <Loader className="full-screen"/>}
        <StyledModal>
          <StyledModalHeader className="modal-header-custom">
            <div className="back-btn" onClick={currentStep === Steps.START ? handleClose : handleBack}/>
            <div className="modal-header-content">
              <h4>Batch Reports</h4>
              <div>
                <StyledBaseCloseModal className="icon-close-modal" title="Cancel" onClick={handleClose}/>
              </div>
            </div>
          </StyledModalHeader>
          <StyledModalBody
            ref={modalBodyRef}
            className="modal-body-custom"
            currentStep={currentStep}
          >
            {currentStep === Steps.START ?
              <BatchReportsTable
                data={requestsList}
                selected={selectedRequests}
                onSelect={handleSelectRequest}
                tableSort={tableSort}
                setTableSort={setTableSort}
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
                forManager={forManager}
              /> :
              <>
                <Row>
                  <Col md={4} xs={12}>
                    <InputAdvanced
                      labelSeparator=""
                      label="Subject line"
                      value={subject}
                      onChange={(_, _v) => setSubject(_v)}
                      onBlur={(_, _v) => setSubject(_v.trim())}
                      forceReset
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    <InputAdvanced
                      labelSeparator=""
                      label="Description"
                      value={description}
                      onChange={(_, _v) => setDescription(_v)}
                      onBlur={(_, _v) => setDescription(_v.trim())}
                      forceReset
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={7} xs={12}>
                    <InputAdvanced
                      labelSeparator=""
                      label="Email Distro List"
                      componentClass="textarea"
                      rows={3}
                      value={distroList}
                      onChange={(_, _v) => setDistroList(_v)}
                      onBlur={(_, _v) => setDistroList(_v.trim())}
                      forceReset
                    />
                  </Col>
                </Row>
                {isNonHardingTestRequest &&
                  <>
                    <Row>
                      <Col md={3} xs={12}>
                        {renderSwitchField('Include Fixlist', includeFixlist, handleIncludeFixlist)}
                      </Col>
                    </Row>
                    {includeFixlist &&
                      <>
                        <Row>
                          <Col md={3} xs={6}>
                            <SelectAdvanced
                              label="Min Severity"
                              labelSeparator=""
                              options={filters.issue_severities || []}
                              value={minSeverity}
                              onSelect={(n, v) => setMinSeverity(v)}
                              placeholder=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={7} xs={12}>
                            {renderSwitchField('Include Fixlist as Excel instead PDF', includeExcelFixlist, handleIncludeExcelFixlist)}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={7} xs={12}>
                            {renderSwitchField('Include Fixlist as Excel & PDF', includeExcelAndPdfFixlist, handleIncludeExcelAndPdfFixlist)}
                          </Col>
                        </Row>
                      </>
                    }
                  </>
                }
                {attachmentCategories.map(cat => (
                  <Row key={`cat-${cat.value}`}>
                    <Col md={3} xs={12}>
                      {renderSwitchField(`Include ${cat.name}`, includeAttachmentCategories.includes(cat.value), v => handleIncludeAttachmentCategory(cat, v))}
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col md={7} xs={12}>
                    {renderSwitchField('Attach Reports as Link', attachReportsAsLink, handleAttachReportsAsLink)}
                  </Col>
                </Row>
                <Row>
                  <Col md={7} xs={12}>
                    {renderAttachedTitles()}
                  </Col>
                </Row>
              </>
            }
          </StyledModalBody>
          <StyledSubmitModalFooter
            disabled={isDisabledOkBtn}
          >
            <div>
              <Button
                bsStyle="primary"
                onClick={currentStep === Steps.START ? handleSubmitSelectedWOs : handleSend}
                disabled={isDisabledOkBtn}
              >
                {currentStep === Steps.START ? 'Submit' : 'Send'}
              </Button>
              <Button bsStyle="default" onClick={handleClose}>Cancel</Button>
              {currentStep === Steps.CONFIRM && <Button bsStyle="default" onClick={handleBack}>Back to Select WOs</Button>}
            </div>
          </StyledSubmitModalFooter>
        </StyledModal>
      </StyledModalDialog>
    </StyledMobileModal>
  );
};

const StyledModalHeader = styled.div`
  padding: 31px 35px 0 45px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    padding: 8.5px 0 8.5px 23px;
    @media (max-width: 767px) {
      padding:0 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  position: fixed;
  top: 91px;
  bottom: 71px;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 46px;
  padding-right: 27px;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    ${props => props.currentStep === Steps.START && css`
      overflow-y: hidden;
    `}
    ${props => props.currentStep !== Steps.START && css`
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
    `}
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    bottom: 83px;
    padding: 0 23px;
  }

  .loader-container {
    z-index: 1070;
  }

  textarea {
    width: 100%;
    min-height: 34px;
    resize: vertical;
    overflow: auto;
  }
`;

const StyledSubmitModalFooter = styled(StyledModalSubmitFooter)`
  background-color: #F4F4F4;
  box-shadow: 0px -4px 0 #fff;
  > div {
    float: none;
  }
  button {
    &.btn-default {
      margin-right: 0;
      margin-left: 18px;
      @media (min-width: 768px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
        &:hover {
          background: #f9f9f9 !important;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
        }
      }
    }
  }
`;

const StyledAttachedTitles = styled.ul`
  margin: 0;
  padding-left: 18px;
  list-style-type: none;
  > li {
    + li {
      margin-top: 18px;
    }
  }
  ul {
    padding-left: 19px;
    list-style-type: none;
    + ul {
      margin-top: 15px;
    }
    > li {
      margin-top: 15px;
      .original-filename {
        margin: 0 0 5px 20px;
        width: fit-content;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
        color: #8f8f8f;
        font-size: 12px;
        line-height: 14px;
        word-break: break-all;
      }
    }
    > li > .result-filename {
      width: fit-content;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      column-gap: 5px;
      &.in-edit-mode {
        width: 100%;
        .form-group.text-editable-field--in-edit-mode {
          margin: 0 0 0 -6px;
          width: 100%;
          .editable-control {
            width: 100% !important;
            .form-control {
              padding: 1px 5px;
              height: 22px !important;
              min-height: 22px !important;
            }
          }
          img {
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
          }
        }
      }
      .btn-edit-filename {
        border-radius: 3px;
        min-height: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f5f5f5;
        box-shadow: -6px -1px 0 #f5f5f5, 6px -1px 0 0 #f5f5f5, -6px 1px 0 #f5f5f5, 6px 1px 0 0 #f5f5f5;
        word-break: break-all;
        cursor: pointer;
        &:hover {
          background-color: #e5e5e5;
          box-shadow: -6px -1px 0 #e5e5e5, 6px -1px 0 0 #e5e5e5, -6px 1px 0 #e5e5e5, 6px 1px 0 0 #e5e5e5;
        }
      }
      > .checkbox {
        margin: 0 2px 0 0;
        max-width: 13px;
        max-height: 13px;
        label {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 13px;
          min-height: 13px;
        }
        input[type="checkbox"] {
          margin-top: 0;
          margin-left: -13px;
        }
      }
    }
  }
  i {
    margin-right: 6px;
    width: 15px;
    min-width: 15px;
    text-align: center;
  }
  .no-files {
    color: #989898;
    i {
      color: orange;
    }
  }
`;

const StyledAttachedTitlesNote = styled.div`
  color: #585858;
  font-size: 13px;
  font-weight: 300;
`;

BatchReportsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(BatchReportsModal));
