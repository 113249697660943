import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Col, FormControl, FormGroup, Row} from 'react-bootstrap';
import {FilterSelectStyled} from './FiltersStyled';
import Select from 'react-select';
import DateField from './DateField';
import moment from 'moment';

export const getMaxOperatorUtilizedDate = () => {
  const currentMoment = moment();
  return +currentMoment.startOf('day').subtract(1, 'days');
};

export const getMaxOnTimeMetricsDate = () => {
  const currentMoment = moment();
  return +currentMoment;
};

const isAllOption = o => ['All', 'Any'].includes(o.label) && +o.value === -999999;

class Filter extends Component {
  getCheckboxField() {
    const {filter, value, onChange} = this.props;
    const {defaultValue} = filter;
    const checked = typeof value === 'boolean' ? value : typeof defaultValue === 'boolean' ? defaultValue : false;
    return (
      <FormControl
        id={filter.name}
        type="checkbox"
        checked={checked}
        onChange={event => onChange(event.target.id, event.target.checked)}
      />
    );
  }

  getInputField() {
    const {filter, value, onChange} = this.props;
    return (
      <FormControl
        id={filter.name}
        value={value || ''}
        onChange={event => onChange(event.target.id, event.target.value)}
        placeholder={filter.placeholder || undefined}
      />
    );
  }

  getSelectOptions() {
    const {filter, value: selectedValues} = this.props;
    const options = ((filter && filter.values && filter.values.map(item => {
      return {
        label: item.name,
        value: item.value
      };
    })) || []);
    const isCreatable = filter.type === 'selectStr' && !!filter.isCreatable;
    if (isCreatable && Array.isArray(selectedValues) && selectedValues.length && selectedValues.every(v => typeof v === 'string')) {
      selectedValues.forEach(v => {
        if (!options.some(o => o.value.toLowerCase() === v.toLowerCase())) {
          options.push({
            label: v,
            value: v
          });
        }
      });
    }
    if (filter && !filter.isOrderedValues) {
      options.sort((a, b) => typeof a.label === 'string' && typeof b.label === 'string' ? a.label.localeCompare(b.label) : 0);
    }
    return options;
  }

  getSelectField() {
    const {filter, value, onChange} = this.props;
    const isCreatable = filter.type === 'selectStr' && !!filter.isCreatable;
    const selectProps = {
          multi: true,
          defaultValue: [],
          value,
          options: this.getSelectOptions(),
          onChange: newValue => {
            const isMultiSelected = Array.isArray(newValue) && newValue.length > 1;
            if (isMultiSelected && newValue.some(isAllOption) && !(value || []).some(isAllOption)) {
              onChange(filter.name, newValue.filter(isAllOption));
            } else if (isMultiSelected && newValue.some(isAllOption) && (value || []).some(isAllOption)) {
              onChange(filter.name, newValue.filter(o => !isAllOption(o)));
            } else {
              onChange(filter.name, newValue);
            }
          },
          filterOption: (option, filter) => {
            if (filter) {
              const {label} = option;
              const terms = filter.toLowerCase().split(/\s+/);
              return label.toLowerCase().includes(filter.toLowerCase()) || terms.every(item => label.toLowerCase().includes(item));
            }
            return true;
          },
          placeholder: filter.placeholder || undefined,
    };
    return (
      <FilterSelectStyled>
        {isCreatable ?
          <Select.Creatable
            {...selectProps}
            promptTextCreator={label => 'Filter by "' + label + '"'}
          /> :
          <Select
            {...selectProps}
          />
        }
      </FilterSelectStyled>
    );
  }

  onDateChange = (id, date) => {
    const {filter, value, onChange} = this.props;
    onChange(filter.name, {
      ...value,
      [id]: date
    });
  };

  getDateField() {
    const {value, filter} = this.props;
    const isOperatorUtilizedDate = filter.name === 'operator_utilized_date_range';
    const isOnTimeMetricsDate = filter.name === 'on_time_metrics_date_range';
    let startDate = value && value.start;
    let endDate = value && value.end;
    let maxValue;
    if (isOperatorUtilizedDate) {
      maxValue = new Date(getMaxOperatorUtilizedDate());

      if (startDate) {
        startDate = moment(startDate).toDate();
      }
      if (endDate) {
        endDate = moment(endDate).toDate();
      }
    } else if (isOnTimeMetricsDate) {
      maxValue = new Date(getMaxOnTimeMetricsDate());
    }
    return (
      <Row className="date-group">
        <Col md={6}>
          <DateField
            id="start"
            placeholder="From"
            value={startDate}
            onChange={this.onDateChange}
            maxValue={endDate || maxValue}
          />
        </Col>
        <Col md={6}>
          <DateField
            id="end"
            placeholder="To"
            value={endDate}
            onChange={(isOperatorUtilizedDate || isOnTimeMetricsDate) ? (n, v) => this.onDateChange(n, v || maxValue) : this.onDateChange}
            minValue={startDate}
            maxValue={maxValue}
          />
        </Col>
      </Row>
    );
  }

  getDatesField() {
    const {filter, value, onChange} = this.props;
    const options = [
      {label: "Past 30 days", value: 1},
      {label: "Past 60 days", value: 2},
      {label: "This Year", value: 3},
      {label: "All Time", value: 4}
    ];
    return (
      <FilterSelectStyled>
        <Select
          className="select-in-cell"
          value={value || filter.defaultValue}
          options={options}
          onChange={event => onChange(filter.name, event ? event.value : filter.defaultValue)}
        />
      </FilterSelectStyled>
    );
  }

  getDatesAltField() {
    const {filter, value, onChange} = this.props;
    return (
      <FilterSelectStyled>
        <Select
          className="select-in-cell"
          value={value || filter.defaultValue}
          options={this.getSelectOptions()}
          onChange={event => onChange(filter.name, event ? event.value : filter.defaultValue)}
        />
      </FilterSelectStyled>
    );
  }

  render() {
    const {filter} = this.props;

    let ValueField;
    switch(filter.type) {
      case 'bool':
        ValueField = this.getCheckboxField();
        break;
      case 'string':
        ValueField = this.getInputField();
        break;
      case 'select':
        ValueField = this.getSelectField();
        break;
      case 'selectAlt':
        ValueField = this.getSelectField();
        break;
      case 'selectStr':
        ValueField = this.getSelectField();
        break;
      case 'date':
        ValueField = this.getDateField();
        break;
      case 'periodAlt':
        ValueField = this.getDatesAltField();
        break;
      case 'period':
        ValueField = this.getDatesField();
        break;
      default:
        break;
    };

    return (
      <FilterStyled>
       <Row>
         <Col md={5}>
           <FormControl
             value={filter.title === 'Finishing House' ? 'Post Partner' : filter.title}
             disabled={true}
             className="filter--title"
           />
         </Col>
         <Col md={5}>{ValueField}</Col>
       </Row>
      </FilterStyled>
    );
  }
}

const FilterStyled = styled(FormGroup)`
  margin-bottom: 6px;
  .control-label {
    font-size: 12px;
    margin-right: 10px;
  }
  .form-control {
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    height: 27px;
    &:not(.filter--title) {
      padding-left: 9px;
    }
    &[type="checkbox"] {
      margin: 7px 0 0;
      padding: 0;
      width: auto;
      height: auto;
      border: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #aaa !important;
      font-size: 11px !important;
    }
    &:invalid {
      background-color: #f0dada;
      border-color: #cf4040;
      outline-color: #cf4040;
    }
  }
  .date-group {
    display: flex;
    justify-content: space-between;
    [class*="col-"] {
      padding: 0 15px;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .react-datepicker {
    font-size: 0.9em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.7em;
    line-height: 1.7em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
`;

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default Filter;