import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Modal, Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import SwitchAdvanced from '../forms/SwitchAdvanced';
import calendarImg from '../../../assets/images/season/season.svg';
import isMobile from 'is-mobile';

const MODAL_HEIGHT = 233;
const MODAL_WIDTH = 301;

const EditDateModal = props => {
  const {show, position, selectedDate, minDate, maxDate, editActualDate, updatePrevDates, updateNextDates, onUpdateField, onSave, onClose} = props;
  const {top, left} = position;

  const handleSetDate = date => {
    onUpdateField('selectedDate', date);
  }

  const switchProps = isMobile() ? {
    handleDiameter: 18.33,
    height: 20,
    width: 30.83,
    offHandleColor: '#ddd',
    offColor: '#fff',
    onColor: '#fff'
  } : {};

  const DatePickerCustomInput = React.forwardRef((inputProps, inputRef) => (
    <input
      {...inputProps}
      readOnly
      ref={inputRef}
    />
  ));

  return (
    <StyledModal
      backdrop={isMobile()}
      backdropClassName="edit-date-modal-backdrop"
      onHide={isMobile() ? () => onClose() : undefined}
      animation={isMobile()}
      show={show}
      style={isMobile() ? {} : {
        top: top + MODAL_HEIGHT < window.innerHeight ? top : (window.innerHeight - MODAL_HEIGHT),
        left: left + MODAL_WIDTH < window.innerWidth ? left : (window.innerWidth - MODAL_WIDTH)
      }}>
      <Modal.Header>
        <Modal.Title>Edit Dates</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label>{editActualDate ? 'Actual' : 'Planned'} Date</label>
        <DatePicker
          dateFormat="MMMM d, yyyy"
          selected={selectedDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleSetDate}
          customInput={isMobile() ? <DatePickerCustomInput/> : undefined}
        />
        <div className="switch-wrap prev-dates">
          <SwitchAdvanced
            {...switchProps}
            name="updatePrevDates"
            value={updatePrevDates}
            onChange={onUpdateField}
          />
          <span className={updatePrevDates === true ? '' : 'off'}>Automatically update previous milestone dates</span>
        </div>
        <div className="switch-wrap next-dates">
          <SwitchAdvanced
            {...switchProps}
            name="updateNextDates"
            value={updateNextDates}
            onChange={onUpdateField}
          />
          <span className={updateNextDates === true ? '' : 'off'}>Automatically update subsequent milestone dates</span>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="update" onClick={() => {onSave()}}>Update Milestone</Button>
        <Button className="cancel" onClick={() => {onClose()}}>Cancel</Button>
      </Modal.Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  height: ${MODAL_HEIGHT}px;
  width: ${MODAL_WIDTH}px;
  position: fixed;
  top: 50px;
  right: 30px;
  left: initial;
  bottom: initial;
  z-index: 1050;

  @media (max-width: 767px) {
    top: unset;
    right: 2.4vw;
    left: 4.2vw;
    bottom: 0;
    width: 93.4vw;
    height: auto;
    border-radius: 5px 5px 0px 0px;
  }

  .modal-open & {
    overflow: visible;
  }

  .modal-backdrop {
    display: none;
  }

  .modal-dialog {
    margin: 0;
    width: auto;
  }
  .modal-content {
    border: 0;
    box-shadow: none;
    @media (max-width: 767px) {
      position: relative;
    }
  }
  .modal-header {
    border-bottom: 0;
    height: 35px;
    padding: 11px 14px;
    @media (max-width: 767px) {
      height: 51px;
      display: flex;
      align-items: center;
      padding-left: 21px;
      padding-right: 21px;
    }
    h4 {
      font-size: 11px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  }
  .modal-body {
    background-color: #f7f7f7;
    padding: 11px 15px 5px;
    @media (max-width: 767px) {
      padding: 17px 20px 22px;
    }

    label {
      display: block;
      font-weight: normal;
      font-size: 10px;
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 8px;
      }
    }
    input {
      background: url(${calendarImg}) no-repeat right 7px center #ffffff;
      border: 1px solid #e4e4e4;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 10px;
      font-weight: bold;
      line-height: 28px;
      padding: 0 6px;
      width: 140px;
      height: 28px;
      @media (max-width: 767px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 33px;
        width: 156px;
        height: 35px;
        padding: 0 9px;
        background-color: #FFFFFF;
        background-position: right 11px center;
        border: 0.5px solid #E4E4E4;
        border-radius: 3px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      }
    }
    .switch-wrap {
      color: #595959;
      display: flex;
      font-size: 9px;
      line-height: 24px;
      &.prev-dates {
        margin-top: 12px;
      }
      .switch-adv {
        margin-right: 8px;
        .switch-field {
          line-height: 15px;
        }
      }
      @media (max-width: 767px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;
        &.prev-dates {
          margin-top: 19px;
        }
        .switch-adv {
          margin: 0;
          .switch-field {
            display: flex;
            .react-switch-bg {
              border: none;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            }
            .react-switch-handle {
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
          }
        }
        span {
          margin-right: 35px;
          font-size: 13px;
          line-height: 18px;
          color: #595959;
          &.off {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .modal-footer {
    border-top: 0;
    padding: 15px 24px;
    text-align: left;
    @media (max-width: 767px) {
      padding: 0;
      height: 50px;
    }
    button {
      background-color: #595959;
      border: 0;
      border-radius: 25px;
      color: #ffffff;
      font-size: 11px;
      padding: 10px 18px 8px;
      &:hover, &:active, &:focus {
        background-color: #595959;
        box-shadow: none;
        color: #ffffff;
        outline: none;
      }
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.8;
      }
      &.cancel {
        background-color: transparent;
        color: #6F6F6F;
        @media (max-width: 767px) {
          position: absolute;
          top: 18px;
          right: 21px;
          font-size: 13px;
          line-height: 15px;
          padding: 0;
        }
      }
      &.update {
        @media (max-width: 767px) {
          border-radius: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
  .react-datepicker {
    font-size: 1rem;
    font-family: 'Roboto',sans-serif;
    .react-datepicker__current-month{
      font-size: 1.2rem;
    }
    .react-datepicker__day, .react-datepicker__day-name {
      margin: 0.4rem;
    }
  }
`;

EditDateModal.propTypes = {
  show: PropTypes.bool,
  position: PropTypes.object.isRequired,
  selectedDate: PropTypes.object,
  editActualDate: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  updatePrevDates: PropTypes.bool,
  updateNextDates: PropTypes.bool,
  onUpdateField: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EditDateModal;