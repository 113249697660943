import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleCell = ({value, row, className, target}) => {
  const {Tag, TrailerEpisodeName} = row;
  const title = `${value}${TrailerEpisodeName ? ` / ${TrailerEpisodeName}` : ''}${Tag ? ` - ${Tag}` : ''}`;
  return (
    <StyledTitleCell className={className}>
      <Link title="Open Report" to={`/requests/${row.RequestID}`} target={target}>{title}</Link>
    </StyledTitleCell>
  );
};

const StyledTitleCell = styled.td`
  a {
    color: #29A4CB;
    font-weight: bold;
    &:hover {
      color: #228daf;
    }
  }
`;

TitleCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object.isRequired,
  target: PropTypes.string,
  className: PropTypes.string
};

export default TitleCell;
