import React, {useState, Fragment} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Collapse, FormGroup, ControlLabel} from 'react-bootstrap';

const MetadataSection = (props) => {
  const {label, data, isExpanded, onToggleView, details} = props;
  const labelUC = label.toUpperCase();
  const {
    AudioLanguage,
    VideoStandard,
    VideoFormat,
  } = details;
  const prepareFieldLabel = k => {
    if (k.startsWith('assetqc.string.metadataKey.')) {
      k = k.replace('assetqc.string.metadataKey.', '');
      return /.*[A-Z]{2,}.*/.test(k) ? k : k.replace(/([A-Z]|[0-9]+)/g, ' $1');
    }
    return k.replace(/_/g, ' ');
  };
  return (
    <StyledSection>
      <ControlLabel
        onClick={onToggleView}
        title={`Click to ${isExpanded ? 'hide' : 'view'} info`}
        className={isExpanded ? 'expanded' : undefined}
      >
        {labelUC}<i className="fas fa-angle-down"/>
      </ControlLabel>
      <Collapse
        in={isExpanded}
      >
        <div>
          <dl>
            {Object.entries(data).map(([k, v]) => !['Type'].includes(k) && (
              <Fragment key={`${label}-${k}`}>
                <dt>
                  {prepareFieldLabel(k)}
                </dt>
                <dd>
                  {v || '-'}
                </dd>
                {
                  labelUC === 'VIDEO' && k === 'Container_Format' ?
                    <>
                      {!!VideoFormat &&
                        <>
                          <dt>
                            Video Format
                          </dt>
                          <dd>
                            {VideoFormat}
                          </dd>
                        </>
                      }
                      {!!VideoStandard &&
                        <>
                          <dt>
                            Video Standard
                          </dt>
                          <dd>
                            {VideoStandard}
                          </dd>
                        </>
                      }
                    </> :
                      labelUC === 'AUDIO' && k === 'Audio_Language_Code' && !!AudioLanguage &&
                        <>
                          <dt>
                            Audio Language
                          </dt>
                          <dd>
                            {AudioLanguage}
                          </dd>
                        </>
                }
              </Fragment>
            ))}
          </dl>
        </div>
      </Collapse>
    </StyledSection>
  );
};

function Metadata(props) {
  const requestDetails = useSelector(state => state.netflixRequestDetails);
  const {details} = requestDetails;
  const {Json} = details;
  const metadata = Json && typeof Json === 'object' ? Json.metadata : null;

  const [collapsed, setCollapsed] = useState({});

  return (
    <StyledMetadata>
      {metadata && Object.keys(metadata).length ?
        Object.keys(metadata).map(k => {
          const isExpanded = collapsed[k] !== false;
          return (
            <MetadataSection
              key={k}
              label={k}
              data={metadata[k]}
              isExpanded={collapsed[k] !== false}
              onToggleView={() => setCollapsed({...collapsed, [k]: !isExpanded})}
              details={details}
            />
          );
        }) :
        <div className="no-metadata">
          No metadata
        </div>
      }
    </StyledMetadata>
  );
}

const StyledSection = styled(FormGroup)`
  label {
    padding: 3px 6px;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: rgba(9,30,66,0.04);
    color: #42526E;
    cursor: pointer;
    :hover {
      background: rgba(9,30,66,0.1);
    }
    i {
      margin-left: 6px;
      transition: transform 0.2s ease 0s;
      will-change: transform;
    }
    &.expanded i {
      transform: rotate(-90deg);
    }
  }
  dt {
    text-transform: capitalize;
  }
  dl {
    margin-left: 6px;
  }
  dd {
    margin-bottom: 5px;
  }
`;

const StyledMetadata = styled.div`
  padding-bottom: 20px
  .no-metadata {
    opacity: 0.65;
  }
`;

export default Metadata;
