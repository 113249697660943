import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import styled from 'styled-components';
import Switch from 'react-switch';
import {
  toggleNetflixMode,
} from '../actions';

function ToggleNetflix() {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestsState = useSelector(state => state.requests);
  const {loaders, isNetflixMode} = requestsState;

  const handleToggle = checked => {
    dispatch(toggleNetflixMode(checked, history));
    history.replace({search: ''});
  };

  const switchId = "switch-toggle-netflix";

  return (
    <StyledRow className="row-toggle-netflix">
      <Col xs={12} className="col-toggle-netflix">
        <label htmlFor={switchId}>
          <Switch
            id={switchId}
            onChange={handleToggle}
            checked={isNetflixMode}
            className="switch-mode"
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#F6F6F6"
            onColor="#FFFFFF"
            offHandleColor="#A9A9A9"
            onHandleColor="#7FCF18"
            activeBoxShadow="0px 0px 1px 1px transparent"
            handleDiameter={18}
            height={20}
            width={36}
            disabled={Object.values(loaders).some(Boolean)}
          />
          <span>
            Netflix Requests
          </span>
        </label>
      </Col>
    </StyledRow>
  );
}

const StyledRow = styled(Row)`
  background: #ededed;

  @media (max-width: 991px) {
    background: #fff;
    border-radius: 3px 3px 0 0;
    + .row-requests-list-title {
      border-radius: 0 !important;
    }
  }

  .col-toggle-netflix > label {
    margin: 15px 0 0 50px;
    width: fit-content;
    @media (max-width: 991px) {
      margin-left: 0;
    }

    display: flex;
    align-items: center;
    justify-content: flex-start;

    > span {
      margin-left: 5px;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #484848;
    }

    .switch-mode > [class$="-bg"] {
      box-shadow: 0 0 0 1px rgb(175, 173, 173, 0.4);
    }

    :hover {
      > span {
        color: #282828;
      }
      .switch-mode > [class$="-bg"] {
        box-shadow: 0 0 0 1px rgb(196, 196, 196, 0.88);
      }
    }
  }
`;

export default ToggleNetflix;
