import React from 'react';
import styled from 'styled-components';

export const CommentsShowMore = ({total, showMore, onToggle}) => {
  return total > 3 ? (
    <StyledShowMore
      className="collapsed"
      data-toggle="collapse"
      data-target=".more-comments"
      onClick={onToggle}
    >
      {showMore ? 'Show Less' : 'Show More'}
    </StyledShowMore>
  ) : null;
};

const StyledShowMore = styled.span`
  margin: 23px 0;
  width: 84px;
  display: block;
  text-align: center;
  padding: 6px 0;
  background: #F2F2F2;
  border-radius: 100px;
  font-size: 10px;
  line-height: 12px;
  color: rgba(40, 40, 40, 0.7);
  cursor: pointer;
  &:hover, &:active {
    color: #282828;
    background: #dcdcdc;
  }
  .scope-section-body & {
    margin-left: 31px;
  }

  @media (min-width: 768px) {
    .scope-section-header & {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .scope-section-body .table-block + & {
      display: none;
    }
  }
`;
