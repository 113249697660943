import {TYPE_STAGES, TYPE_STAGE_FOR_NEW_STEP, VERSION_ALL, OFFICE_ALL, PATCHES_ANY} from './constants';
import {
  SETTINGS_CLEAR_QC_PROFILE,
  SETTINGS_GET_QC_PROFILE, SETTINGS_UPDATE_QC_PROFILE_SETTINGS, SETTINGS_SET_SCHEDULING_DATA,
  SETTINGS_SET_STEPS, SETTINGS_SET_STEP_TYPES,
  SETTINGS_SET_IS_OPEN_TEMPLATE_CONFIGURE, SETTINGS_SET_OPTIONS_TEMPLATE_CONFIGURE, SETTINGS_SAVE_SELECTED_OPTIONS,
  SETTINGS_SET_IS_OPEN_NEW_TYPE_MODAL,
  SETTINGS_ADD_NEW_STEP, SETTINGS_SET_IS_OPEN_NEW_DESCRIPTION_MODAL, SETTINGS_SET_COMBINATIONS,
  SETTINGS_INSERT_STEP, SETTINGS_TB_DELETE_NEW_STEP, SETTINGS_TB_CLEAR_FOR_QC_PROFILE, SETTINGS_TB_SET_FILTERS,
  SETTINGS_SET_TEMPLATE_CONFIGURE_COMBINATIONS, SETTINGS_TOGGLE_HIDE_VIDEO_CAPTIONS,
  SETTINGS_SCHEDULES_SET_LIST, SETTINGS_SCHEDULES_SET_PROFILE, SETTINGS_SET_IS_OPEN_STEP_EST_TEMPLATE_MODAL,
  SETTINGS_UPDATE_QC_PROFILE_FLAGS,
  SETTINGS_UPDATE_QC_PROFILE_FIELD,
} from './actions';

const SMN_SUFFIX = 'SMN';

const filterProductCodesBySuffixes = (productCodes, suffixes) => {
  return productCodes.filter(i => suffixes.some(suffix => i.name.endsWith(`-${suffix}`)));
};

const filterSmnProductCodes = productCodes => {
  return filterProductCodesBySuffixes(productCodes, [SMN_SUFFIX]);
};

const getProductCodesForProfile = (allProductCodes, smnProductCodes, suffixesForProfile) => {
  const suffixes = (suffixesForProfile || []).filter(i => i !== SMN_SUFFIX);
  if (suffixes.length) {
    return [...filterProductCodesBySuffixes(allProductCodes, suffixes), ...smnProductCodes];
  } else if (!suffixesForProfile || !suffixesForProfile.length) {
    return [...allProductCodes];
  }
  return [...smnProductCodes];
};

const prepareTypeOptions = types => {
  return types.map(item => {
    const {type, stage} = item;
    const value = type.toLowerCase();
    const hasSameName = types.find(t => t.stage !== stage && t.type.toLowerCase() === value);
    const name = !hasSameName ? type : `${type} (${TYPE_STAGES[stage]})`;
    return {value: `${stage}__${value}`, name, shortName: type, stage};
  });
};

const initialState = {
  qcProfileSettings: {
    qcProfileIssueTypeList: [],
    qcProfileSeverityList: [],
    qcProfileMinFixSeverity: 1,
    qcProfileScheduleId: null,
  },
  severityTypeList: [],
  severityConstituteList: [],
  scheduleProfileList: [],
  schedulingData: [],
  systemEventTypes: [],
  templateBuilderSettings: {
    qcProfileData: {},
    qcProfileFlags: [],
    clientSpecs: [],
    specDocuments: [],
    autoQcSpecs: [],
    productCodes: [],
    smnProductCodes: [],
    productCodesForProfile: [],
    hiddenFields: [],
    typeOptions: [],
    stepTypes: [],
    stepAssignees: [],
    stepCategories: [],
    steps: {},
    options: null,
    selectedOptions: new Map(),
    isOpenTemplateConfigure: false,
    isOpenNewTypeModal: false,
    newTypeData: {inputValue: ''},
    editDescData: {currentStep: {}},
    isOpenNewDescriptionModal: false,
    isOpenStepEstTemplateModal: false,
    combinations: [],
    templateConfigureCombinations: [],
    hideVideoCaptions: true
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_GET_QC_PROFILE: {
      const selectedOptions = new Map();
      if (action.templateBuilderSettings.selectedOptions) {
        action.templateBuilderSettings.selectedOptions.forEach(option => {
          selectedOptions.set(option.key, option.value);
        });
      }
      const {productCodeSuffixes} = action.templateBuilderSettings;
      const {productCodes, smnProductCodes} = state.templateBuilderSettings;
      const productCodesForProfile = getProductCodesForProfile(productCodes, smnProductCodes, productCodeSuffixes);
      const combinations = action.templateBuilderSettings.combinations || initialState.templateBuilderSettings.combinations;
      return {
        ...state,
        qcProfileSettings: action.qcProfileSettings || initialState.qcProfileSettings,
        severityTypeList: action.severityTypeList || initialState.severityTypeList,
        severityConstituteList: action.severityConstituteList || initialState.severityConstituteList,
        scheduleProfileList: action.scheduleProfileList || state.scheduleProfileList,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          qcProfileData: action.templateBuilderSettings.qcProfileData || initialState.templateBuilderSettings.qcProfileData,
          qcProfileFlags: action.templateBuilderSettings.qcProfileFlags || initialState.templateBuilderSettings.qcProfileFlags,
          steps: action.templateBuilderSettings.steps || initialState.templateBuilderSettings.steps,
          combinations,
          templateConfigureCombinations: combinations.map(c => ({...c})),
          selectedOptions,
          productCodesForProfile,
          hiddenFields: action.templateBuilderSettings.hiddenFields || initialState.templateBuilderSettings.hiddenFields,
          hideVideoCaptions: action.templateBuilderSettings.hiddenFields && action.templateBuilderSettings.hiddenFields.indexOf('video_captions') > -1
        }
      }
    }

    case SETTINGS_UPDATE_QC_PROFILE_SETTINGS: {
      return {
        ...state,
        qcProfileSettings: action.value
      }
    }

    case SETTINGS_SET_SCHEDULING_DATA: {
      return {
        ...state,
        schedulingData: action.data
      }
    }

    case SETTINGS_SET_IS_OPEN_TEMPLATE_CONFIGURE: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
            isOpenTemplateConfigure: action.isOpenTemplateConfigure

        }
      }
    }

    case SETTINGS_SET_IS_OPEN_NEW_TYPE_MODAL: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          isOpenNewTypeModal: action.isOpenNewTypeModal,
          newTypeData: action.newTypeData || initialState.templateBuilderSettings.newTypeData
        }
      }
    }

    case SETTINGS_SET_IS_OPEN_NEW_DESCRIPTION_MODAL: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          isOpenNewDescriptionModal: action.isOpenNewDescriptionModal,
          editDescData: action.editDescData || initialState.templateBuilderSettings.editDescData
        }
      }
    }

    case SETTINGS_SET_COMBINATIONS: {
      const combinations = action.combinations || initialState.templateBuilderSettings.combinations;
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          combinations,
          templateConfigureCombinations: combinations.map(c => ({...c}))
        }
      }
    }

    case SETTINGS_SET_OPTIONS_TEMPLATE_CONFIGURE: {
      const options = action.res;
      const selectedOptions = state.templateBuilderSettings.selectedOptions;
      options.forEach(optionSet => {
        selectedOptions.set(optionSet.key, []);
      });
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
            options: action.res

        }
      }
    }

    case SETTINGS_SAVE_SELECTED_OPTIONS: {
      const optionsSetName = action.optionsSetName;
      const option = action.option;
      const selectedOptions = new Map(state.templateBuilderSettings.selectedOptions);
      const optionSet = selectedOptions.get(optionsSetName);
      const foundOption = optionSet.indexOf(
          optionSet.find(currentOption =>
              currentOption.OptionID === option.OptionID)
      );
      if(foundOption !== -1) {
        optionSet.splice(foundOption, 1);
      } else {
        option.OptionSetName = optionsSetName;
        optionSet.push(option);
      }
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          selectedOptions: selectedOptions

        }
      }
    }

    case SETTINGS_ADD_NEW_STEP: {
      const stage = TYPE_STAGE_FOR_NEW_STEP;
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          steps: {
            ...state.templateBuilderSettings.steps,
            [stage]: [{stage, version: VERSION_ALL, office: OFFICE_ALL, patches: PATCHES_ANY, category_flags: 0}]
          }
        }
      }
    }

    case SETTINGS_INSERT_STEP: {
      const steps = {...state.templateBuilderSettings.steps};
      const index = action.index + 1;
      const {stage} = action;
      const start = steps[stage].slice(0, index);
      const end = steps[stage].slice(index);
      steps[stage] = [...start, {stage, version: VERSION_ALL, office: OFFICE_ALL, patches: PATCHES_ANY, category_flags: 0}, ...end];
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          steps
        }
      }
    }

    case SETTINGS_SET_STEPS: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          steps: action.steps
        }
      }
    }

    case SETTINGS_SET_STEP_TYPES: {
      const {data} = action;
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          typeOptions: prepareTypeOptions(data),
          stepTypes: data
        }
      }
    }

    case SETTINGS_SET_TEMPLATE_CONFIGURE_COMBINATIONS: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          templateConfigureCombinations: action.templateConfigureCombinations
        }
      }
    }

    case SETTINGS_TB_DELETE_NEW_STEP: {
      const steps = {...state.templateBuilderSettings.steps};
      for(const stage of Object.keys(steps)) {
        const stepIndex = steps[stage].findIndex(s => !s.step_subtype_id);
        if (stepIndex >= 0) {
          steps[stage].splice(stepIndex, 1);
          if (!steps[stage].length) {
            delete steps[stage];
          }
          break;
        }
      }
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          steps
        }
      }
    }

    case SETTINGS_CLEAR_QC_PROFILE: {
      return {
        ...state,
        qcProfileSettings: {
          ...state.qcProfileSettings,
          qcProfileIssueTypeList: initialState.qcProfileSettings.qcProfileIssueTypeList,
          qcProfileSeverityList: initialState.qcProfileSettings.qcProfileSeverityList,
          qcProfileMinFixSeverity: initialState.qcProfileSettings.qcProfileMinFixSeverity,
          qcProfileScheduleId: initialState.qcProfileSettings.qcProfileScheduleId,
        },
        severityTypeList: initialState.severityTypeList,
        severityConstituteList: initialState.severityConstituteList,
        scheduleProfileList: initialState.scheduleProfileList,
        schedulingData: initialState.schedulingData,
        systemEventTypes: initialState.systemEventTypes,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          productCodesForProfile: initialState.templateBuilderSettings.productCodesForProfile,
          hiddenFields: initialState.templateBuilderSettings.hiddenFields,
          selectedOptions: initialState.templateBuilderSettings.selectedOptions,
          combinations: initialState.templateBuilderSettings.combinations,
          templateConfigureCombinations: initialState.templateBuilderSettings.templateConfigureCombinations,
          steps: initialState.templateBuilderSettings.steps
        }
      }
    }

    case SETTINGS_TB_CLEAR_FOR_QC_PROFILE: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          productCodesForProfile: initialState.templateBuilderSettings.productCodesForProfile,
          hiddenFields: initialState.templateBuilderSettings.hiddenFields,
          selectedOptions: initialState.templateBuilderSettings.selectedOptions,
          combinations: initialState.templateBuilderSettings.combinations,
          templateConfigureCombinations: initialState.templateBuilderSettings.templateConfigureCombinations,
          steps: initialState.templateBuilderSettings.steps
        }
      }
    }

    case SETTINGS_TB_SET_FILTERS: {
      const {product_codes, step_types, step_assignees, step_categories, options, spec_documents, auto_qc_specs, client_specs} = action.filters;
      const productCodes = product_codes.map(i => ({value: i.name, name: i.name}));
      const smnProductCodes = filterSmnProductCodes(productCodes);
      const typeOptions = prepareTypeOptions(step_types);
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          clientSpecs: client_specs || [],
          specDocuments: spec_documents,
          autoQcSpecs: auto_qc_specs,
          productCodes,
          smnProductCodes,
          typeOptions,
          stepTypes: step_types,
          stepAssignees: step_assignees,
          stepCategories: step_categories.sort((a, b) => a.name.localeCompare(b.name)),
          options
        }
      }
    }

    case SETTINGS_TOGGLE_HIDE_VIDEO_CAPTIONS: {
      const hiddenFields = state.templateBuilderSettings.hiddenFields;
      if (action.value.hideVideoCaptions === true) {
        hiddenFields.push("video_captions");
      } else {
        const index = hiddenFields.indexOf("video_captions");
        hiddenFields.splice(index, 1);
      }
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          hiddenFields,
          hideVideoCaptions: action.value.hideVideoCaptions
        }
      }
    }

    case SETTINGS_SCHEDULES_SET_LIST: {
      return {
        ...state,
        scheduleProfileList: action.data || initialState.scheduleProfileList
      }
    }

    case SETTINGS_SCHEDULES_SET_PROFILE: {
      return {
        ...state,
        schedulingData: action.data.schedulingData || initialState.schedulingData,
        systemEventTypes: action.data.systemEventTypes || initialState.systemEventTypes,
      }
    }

    case SETTINGS_SET_IS_OPEN_STEP_EST_TEMPLATE_MODAL: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          isOpenStepEstTemplateModal: action.isOpenStepEstTemplateModal,
          editDescData: action.editDescData
        }
      }
    }

    case SETTINGS_UPDATE_QC_PROFILE_FLAGS: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          qcProfileFlags: action.flags
        }
      }
    }

    case SETTINGS_UPDATE_QC_PROFILE_FIELD: {
      return {
        ...state,
        templateBuilderSettings: {
          ...state.templateBuilderSettings,
          qcProfileData: {
            ...state.templateBuilderSettings.qcProfileData,
            [action.name]: action.value
          }
        }
      }
    }

    default: {
      return state;
    }
  }
}
