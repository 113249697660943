import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage, isManagerUser, isRequiredRuntimeTextless, getAsperaConnect, getDefaultRedirectUrl
} from '../../utils/general';
import {
  SELECT_FILES_OPTIONS,
  QC_REQUEST_TYPE_NEW_QC_REQUEST,
  NEW_REQUEST_STEP_FORMATS,
  NEW_REQUEST_STEP_PRODUCTION,
  FINISHING_HOUSE__N_A,
  isNewQuoteProject,
} from './constants';
import {showSuccess, showError, showConfirmAlertToAutoCreateReplacementWO} from '../../layouts/actions';
import {initAspera} from 'react-aspera-connect';
import _ from 'lodash';
import {hasChangedWoSteps} from '../RequestDetails/components/editWorkOrderStepsTable/utils';

export const NEWREQUEST_CHANGE_STEP = 'NEWREQUEST_CHANGE_STEP';
export const NEWREQUEST_CHANGE_SUBSTEP = 'NEWREQUEST_CHANGE_SUBSTEP';
export const NEWREQUEST_IS_LOADING = 'NEWREQUEST_IS_LOADING';
export const NEWREQUEST_GET_FORM_DICTIONARIES = 'NEWREQUEST_GET_FORM_DICTIONARIES';
export const NEWREQUEST_CHANGE_FORM_FIELD = 'NEWREQUEST_CHANGE_FORM_FIELD';
export const NEWREQUEST_FORM_REQUEST = 'NEWREQUEST_FORM_REQUEST';
export const NEWREQUEST_FORM_RECEIVE = 'NEWREQUEST_FORM_RECEIVE';
export const NEWREQUEST_FORM_SAVE = 'NEWREQUEST_FORM_SAVE';
export const NEWREQUEST_SET_UNSAVED = 'NEWREQUEST_SET_UNSAVED';
export const NEWREQUEST_CLEAN_FORM = 'NEWREQUEST_CLEAN_FORM';
export const NEWREQUEST_RESET_FORM = 'NEWREQUEST_RESET_FORM';
export const NEWREQUEST_AUTO_SAVE = 'NEWREQUEST_AUTO_SAVE';
export const NEWREQUEST_IS_LOADING_AUTO_SAVED = 'NEWREQUEST_IS_LOADING_AUTO_SAVED';
export const NEWREQUEST_GET_AUTO_SAVED = 'NEWREQUEST_GET_AUTO_SAVED';
export const NEWREQUEST_APPLY_VERSION = 'NEWREQUEST_APPLY_VERSION';
export const NEWREQUEST_GET_REPORTS = 'NEWREQUEST_GET_REPORTS';
export const NEWREQUEST_IS_LOADING_REPORTS = 'NEWREQUEST_IS_LOADING_REPORTS';
export const NEWREQUEST_SAVE_DISTRIBUTOR = 'NEWREQUEST_SAVE_DISTRIBUTOR';

export const changeStep = step => ({
  type: NEWREQUEST_CHANGE_STEP,
  step
});

export const changeSubStep = step => ({
  type: NEWREQUEST_CHANGE_SUBSTEP,
  step
});

export const getAsperaTransferSpec = requestParams => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/account/transfer_spec`,
      type: 'GET',
      data: requestParams
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getUploadedFiles = requestParams => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/assets`,
      type: 'GET',
      data: requestParams
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const uploadFile = assetInfo => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/asset/create`,
      type: 'POST',
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify(assetInfo)
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getReports = (onlySeasons, csParams) => dispatch => {
  dispatch({type: NEWREQUEST_IS_LOADING_REPORTS, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${onlySeasons ? 'seasons' : 'needs_fixes'}`,
    data: csParams
  })
  .done(res => {
    dispatch({type: NEWREQUEST_GET_REPORTS, reports: res.reports});
  })
  .fail(() => {
    dispatch({type: NEWREQUEST_IS_LOADING_REPORTS, loading: false});
  });
};

export const prepareDictionaries = res => {
  const dictionaries = res.filters;
  if (!dictionaries.finishing_houses) {
    dictionaries.finishing_houses = [{value: FINISHING_HOUSE__N_A, name: 'N/A'}];
  } else {
    dictionaries.finishing_houses.splice(0, 0, {value: FINISHING_HOUSE__N_A, name: 'N/A'});
  }
  dictionaries.series = _.orderBy(dictionaries.series || [], [d => (d.series_name || '').toLowerCase()]);
  dictionaries.studios = _.orderBy(dictionaries.studios || [], [d => (d.name || '').toLowerCase()]);
  if (dictionaries.productions) {
    dictionaries.productions = _.orderBy(dictionaries.productions, [d => (d.name || '').toLowerCase()]);
  }
  if (dictionaries.qc_profiles) {
    dictionaries.qc_profiles = _.orderBy(dictionaries.qc_profiles, [d => (d.name || '').toLowerCase()]);
  }
  if (dictionaries.memfis_contacts) {
    dictionaries.memfis_contacts.forEach(d => d.name = d.name + (d.company_name ? ' Company: ' + d.company_name : ''));
    dictionaries.memfis_contacts = _.orderBy(dictionaries.memfis_contacts, [d => (d.name || '').toLowerCase()]);
  }
  if (dictionaries.cs_users) {
    dictionaries.cs_users = _.orderBy(dictionaries.cs_users, [d => (d.name || '').toLowerCase()]);
  }
  if (dictionaries.audio_sounds) {
    dictionaries.audio_sounds = _.orderBy(dictionaries.audio_sounds, [d => (d.name || '').toLowerCase()]);
  }
  return dictionaries;
};

export const getDictionaries = userParam => (dispatch, getState) => {
  const {for_company_ids} = userParam || {};
  dispatch({type: NEWREQUEST_IS_LOADING, loading: true, for_company_ids});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/filters`,
    data: userParam
  })
  .done(res => {
    const dictionaries = for_company_ids ? {...getState().newrequest.dictionaries, ...res.filters} : prepareDictionaries(res);
    dispatch({type: NEWREQUEST_GET_FORM_DICTIONARIES, dictionaries});
  })
  .fail(() => {
    dispatch(showError('Something went wrong'));
  });
};

export const changeFormField = (name, value) => ({
  type: NEWREQUEST_CHANGE_FORM_FIELD,
  name,
  value
});

const markAsSaved = (history, closePage) => dispatch => {
  dispatch({type: NEWREQUEST_FORM_SAVE});
  dispatch({type: NEWREQUEST_AUTO_SAVE, saving: false});
  if (closePage) {
    history.push(getDefaultRedirectUrl());
  }
};

const convertValuesToFiles = values => {
  const files = {};
  const {uploadedFiles, selectedFiles, select_files_type_id} = values;
  if (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && uploadedFiles && uploadedFiles.length) {
    files.manual_uploads = uploadedFiles.map(item => (item.asset_name));
  } else if (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && selectedFiles && selectedFiles.length) {
    files.package_ids = selectedFiles.map(asset => (asset.id));
  } else if (select_files_type_id === SELECT_FILES_OPTIONS.UploadLater) {
    files.upload_later = true;
  } else if (select_files_type_id === SELECT_FILES_OPTIONS.PhysicalMediaDelivery) {
    files.media_delivery = true;
  }
  return files;
};

const prepareProductionIDs = (val) => {
  return Array.isArray(val) ? val.filter(id => !!id) : val ? [val] : [];
};

const prepareFinishingHouseIDs = (val) => {
  if (Array.isArray(val) && val.length > 1) {
    return val.filter(id => id !== FINISHING_HOUSE__N_A);
  }
  return val;
};

const convertValuesToRequestData = values => {
  const {content_type_id, series_id, season_name, season, general, audio_format,
   video_format, qc_type_id, distributor_id, qc_profile_id, title, show_code, distribution_list, client_contact_id,
   studio_id, production_id, cs_studio_id, cs_production_id, cs_user_id, office_flag, trailer_episode_name,
   version_tag, finishing_house_id, cs_finishing_house_id, caption_id, user_permissions, additional_description,
   send_from_qod, memfis_company_id, distribution_list_update_type, pending_approval_studio_partner_type_ids,
   client_contact_update_type, office_flag_update_type, memfis_company_update_type,
   post_executive_user_update_type, post_executive_user_id, quote_project_id,
   is_create_client_proxies, overage_request,
   update_project_billing_notes, project_billing_notes, update_project_qc_notes, project_qc_notes
  } = values;

  const isNewQuote = isNewQuoteProject(values);

  let preparedUserPermissions;
  if (user_permissions) {
    preparedUserPermissions = {};
    Object.keys(user_permissions).forEach(k => {
      user_permissions[k].forEach(i => {
        if (i.UserID && i.CompanyID) {
          preparedUserPermissions[i.CompanyID] = {...preparedUserPermissions[i.CompanyID], [i.UserID]: i.PermissionType};
        }
      });
    });
  }

  const requestData = {
    studio_id,
    production_id: prepareProductionIDs(production_id),
    finishing_house_id: prepareFinishingHouseIDs(finishing_house_id),
    cs_studio_id,
    cs_production_id,
    cs_finishing_house_id,
    trailer_episode_name,
    season_name,
    title,
    show_code,
    distribution_list,
    distribution_list_update_type,
    pending_approval_studio_partner_type_ids,
    client_contact_id,
    client_contact_update_type,
    cs_user_id,
    office_flag,
    office_flag_update_type,
    send_from_qod,
    is_create_client_proxies,
    overage_request,
    memfis_company_id,
    memfis_company_update_type,
    post_executive_user_id,
    post_executive_user_update_type,
    caption_id,
    version_tag: !video_format ? version_tag : null,
    additional_description: !video_format ? additional_description : null,
    update_project_billing_notes,
    project_billing_notes,
    update_project_qc_notes,
    project_qc_notes,
    details: isNewQuote ? undefined : {
      general, season, audio_format,
      video_formats: video_format ? video_format.map(vf => {
        const vfValues = {...vf};
        const filesValues = {...vfValues.files};
        delete vfValues.files;
        return {
          ...vfValues,
          files: convertValuesToFiles(filesValues)
        };
      }) : null,
      qc_type: {content_type_id, qc_type_id, distributor_id, qc_profile_id}
    },
    user_permissions: preparedUserPermissions,
    ...(isNewQuote ? {
      quote_project_id,
      revision_num: values.quote_revision_num,
      content_type_id,
      distributor_id,
      qc_profiles_new: qc_profile_id,
      estimated_quote: values.estimated_quote,
      general,
      ...convertValuesToFiles(values)
    } : !video_format ? convertValuesToFiles(values) : {})
  };
  if (typeof series_id === 'number') {
    requestData.series_id = series_id;
  } else if (typeof series_id === 'string') {
    requestData.series_name = series_id;
  }
  return requestData;
};

export const showSuccessMessage = () => dispatch => {
  const message = 'Your QC request has been successfully created. Our Client Services team will review your request and submit a work order.';
  dispatch(showSuccess(message, 'QC Request Created'));
};

export const asperaStartTransfer = (transferData, callback) => dispatch => {
  if (!transferData || typeof transferData !== 'object') {
    if (callback) {
      callback();
    }
    return;
  }

  const {request_id, transfer_spec} = transferData;
  if (!transfer_spec || !Array.isArray(transfer_spec.transfer_specs) || !transfer_spec.transfer_specs.length) {
    if (callback) {
      callback();
    }
    return;
  }

  const asperaWeb = getAsperaConnect(config.initAsperaParams);
  if (!asperaWeb) {
    if (callback) {
      callback();
    }
    return;
  }

  asperaWeb.initSession();
  if (asperaWeb.getStatus() !== 'RUNNING') {
    initAspera(config.initAsperaParams);
  }

  const transferSpec = {...transfer_spec.transfer_specs[0].transfer_spec};
  if (!transferSpec.authentication) {
    transferSpec.authentication = 'token'
  }
  asperaWeb.startTransfer(transferSpec, {allow_dialogs: false}, {
    error: err => {
      console.log(`Failed to start aspera transfer: ${JSON.stringify(err, null, 4)}`);
      dispatch(showError(`Failed to start aspera transfer for request # ${request_id}`));
      if (callback) {
        callback();
      }
    },
    success: res => {
      console.log('Started aspera transfer: ', res);
      const message = 'Aspera transfer for your QC request has been successfully started.';
      dispatch(showSuccess(message, `Aspera Start Transfer: Request # ${request_id}`));
      $.ajax({
        method: 'POST',
        url: `${config.apiUrl}/v1/qc_on_demand/requests/${request_id}/start_transfer`,
        data: {
          cookie: transferSpec.cookie
        }
      })
      .done(() => {})
      .fail(err => {
        dispatch(showError(getErrorMessage(err), `Failed to post after start aspera transfer for request # ${request_id}`));
      });
      if (callback) {
        callback();
      }
    }
  });
};

export const saveRequest = (history, values) => dispatch => {
  dispatch({type: NEWREQUEST_FORM_REQUEST});
  const requestData = convertValuesToRequestData(values);
  const {video_formats} = requestData.details;
  let manualUploadsForFormats = [];
  let lastIndex = -1;
  if (video_formats) {
    video_formats.forEach((vf, index) => {
      const {files} = vf;
      if (files.manual_uploads) {
        lastIndex = index;
        manualUploadsForFormats.push(true);
      } else {
        manualUploadsForFormats.push(false);
      }
    });
  } else if (requestData.manual_uploads) {
    lastIndex = 0;
    manualUploadsForFormats.push(true);
  }
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/new`,
    data: JSON.stringify(requestData)
  })
  .done(res => {
    dispatch(showSuccessMessage());
    if (manualUploadsForFormats.find(i => i)) {
      manualUploadsForFormats.forEach((hasManualUploads, index) => {
        if (hasManualUploads) {
          dispatch(asperaStartTransfer(res[index], () => {
            if (index === lastIndex) {
              dispatch(markAsSaved(history, true));
            }
          }));
        }
      });
    } else {
      dispatch(markAsSaved(history, true));
    }
  })
  .fail(err => {
    dispatch({type: NEWREQUEST_FORM_RECEIVE});
    dispatch(showError(getErrorMessage(err)));
  });
};

export const submitReplacement = (history, values) => dispatch => {
  dispatch({type: NEWREQUEST_FORM_REQUEST});
  const {manual_uploads} = values;
  const hasManualUploads = Boolean(manual_uploads && manual_uploads.length);
  const preparedValues = {
    ...values,
    manual_uploads: hasManualUploads ? manual_uploads.map(item => (item.asset_name)) : null,
    from_wizard: true
  };
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/replacement/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(preparedValues)
  })
  .done(res => {
    dispatch(showSuccessMessage());
    const actionsAfterSaving = () => {
      if (isManagerUser()) {
        dispatch(showConfirmAlertToAutoCreateReplacementWO(res.request_id));
      }
      dispatch(markAsSaved(history, true));
    };
    if (hasManualUploads) {
      dispatch(asperaStartTransfer(res, actionsAfterSaving));
    } else {
      actionsAfterSaving();
    }
  })
  .fail(err => {
    dispatch({type: NEWREQUEST_FORM_RECEIVE});
    dispatch(showError(getErrorMessage(err)));
  });
};

export const saveNewEpisode = (history, values) => dispatch => {
  dispatch({type: NEWREQUEST_FORM_REQUEST});
  const {
    origin_request_id, cs_studio_id, cs_production_id, cs_finishing_house_id, general
  } = values;
  const files = convertValuesToFiles(values);
  const episode = {
    ...general,
    season_request_id: origin_request_id,
    cs_studio_id,
    cs_production_id,
    cs_finishing_house_id,
    ...files
  };

  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/episode/new`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(episode)
  })
  .done(res => {
    dispatch(showSuccessMessage());
    if (files.manual_uploads) {
      dispatch(asperaStartTransfer(res, () => {
        dispatch(markAsSaved(history, true));
      }));
    } else {
      dispatch(markAsSaved(history, true));
    }
  })
  .fail(err => {
    dispatch({type: NEWREQUEST_FORM_RECEIVE});
    dispatch(showError(getErrorMessage(err)));
  });
};

export const saveNewQuote = (history, values) => dispatch => {
  dispatch({type: NEWREQUEST_FORM_REQUEST});
  const preparedData = convertValuesToRequestData(values);
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      quote_project: preparedData
    })
  })
  .done(res => {
    const message = 'Project Quote has been successfully saved.';
    dispatch(showSuccess(message, 'Project Quote Created'));
    dispatch(markAsSaved(history, false));
    history.push(`/requests/quote-project/${res.quote_project_id}`);
  })
  .fail(err => {
    dispatch({type: NEWREQUEST_FORM_RECEIVE});
    dispatch(showError(getErrorMessage(err)));
  });
};

export const setUnsavedState = () => dispatch => {
  dispatch({type: NEWREQUEST_SET_UNSAVED});
};

export const cleanForm = () => dispatch => {
  dispatch({type: NEWREQUEST_CLEAN_FORM});
};

export const resetForm = values => (dispatch, getState) => {
  const {cs_studio_id, cs_production_id, cs_finishing_house_id} = getState().newrequest.values;
  dispatch({type: NEWREQUEST_RESET_FORM, values: {...values, cs_studio_id, cs_production_id, cs_finishing_house_id}});
};

export const autoSave = (history, newrequest, closePage) => dispatch => {
  const {currentStep, currentSubStep, values} = newrequest;
  const data = {date: new Date(), currentStep, currentSubStep, values};
  dispatch({type: NEWREQUEST_AUTO_SAVE, saving: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/draft`,
    contentType: 'application/json;charset=utf-8',
    data: JSON.stringify({
      steps_json: JSON.stringify(data)
    })
  })
  .always(() => {
    dispatch(markAsSaved(history, closePage));
  });
};

const applyVersion = version => dispatch => {
  const data = {
    currentStep: version.currentStep,
    currentSubStep: version.currentSubStep,
    values: version.values || {}
  };
  dispatch({type: NEWREQUEST_APPLY_VERSION, data});
};

export const getAutoSaved = () => dispatch => {
  dispatch({type: NEWREQUEST_IS_LOADING_AUTO_SAVED, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/draft`
  })
    .done(res => {
      dispatch({type: NEWREQUEST_GET_AUTO_SAVED, data: JSON.parse(res.steps_json || null) || null});
    })
    .fail(() => {
       dispatch({type: NEWREQUEST_IS_LOADING_AUTO_SAVED, loading: false});
    });
};

export const resetAutoSaved = () => ({type: NEWREQUEST_GET_AUTO_SAVED, data: null});

export const resumeAuto = version => dispatch => {
  dispatch(resetAutoSaved());
  const values = {...version.values};
  const {
    video_format, audio_format, uploadedFiles, selectedFiles, select_files_type_id, request_type_id, production_id
  } = values;
  const OLD__STEP_PRODUCTION = 40;
  const OLD__STEP_FINISHING_HOUSE = 44;
  const OLD__STEP_FORMATS = 50;
  if ([OLD__STEP_PRODUCTION, OLD__STEP_FINISHING_HOUSE, OLD__STEP_FORMATS].includes(version.currentStep)) {
    version.currentStep = NEW_REQUEST_STEP_FORMATS;
  }
  if (video_format && !Array.isArray(video_format)) {
    const newVideoFormat = {
      ...video_format,
      files: select_files_type_id ? {uploadedFiles, selectedFiles, select_files_type_id} : {}
    };
    if (audio_format) {
      newVideoFormat.audio_format = audio_format;
    }
    values.video_format = [newVideoFormat];
    ['audio_format', 'select_files_type_id', 'selectedFiles', 'uploadedFiles'].forEach(k => {
      delete values[k];
    });
  }
  if (request_type_id === QC_REQUEST_TYPE_NEW_QC_REQUEST && version.currentStep > NEW_REQUEST_STEP_PRODUCTION &&
      !Array.isArray(production_id)) {
    values.production_id = prepareProductionIDs(production_id);
  }
  if (values.memfis_client_id) {
    values.memfis_company_id = values.memfis_client_id;
    delete values.memfis_client_id;
  }
  dispatch(applyVersion({...version, values}));
};

export const saveDistributor = (values, callback) => (dispatch, getState) => {
  dispatch({type: NEWREQUEST_SAVE_DISTRIBUTOR, saving: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/distributor`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(values)
  })
  .done(res => {
    const {dictionaries: {distributors}} = getState().newrequest;
    if (distributors.find(({value}) => value === res)) {
      dispatch({type: NEWREQUEST_SAVE_DISTRIBUTOR, saving: false});
    } else {
      dispatch({type: NEWREQUEST_SAVE_DISTRIBUTOR, saving: false,
        distributor: {...values, value: res, name: values.distributor_name}});
    }
    if (callback) {
      callback();
    }
  })
  .fail(error => {
    dispatch({type: NEWREQUEST_SAVE_DISTRIBUTOR, saving: false});
    dispatch(showError(getErrorMessage(error)));
  });
};

const prepareParamsToGetCostDetails = values => {
  const {qc_profile_id, video_format, audio_format, caption_id, general, season} = values;

  let episodes_minutes = [], episodes_minutes_textless;
  if (season && season.episodes) {
    episodes_minutes = season.episodes.map(ep => Math.ceil(ep.runtime_in_sec / 60));
  } else {
    episodes_minutes.push(Math.ceil(((general || {}).runtime_in_sec || 0) / 60));
  }

  if (isRequiredRuntimeTextless({video_format})) {
    if (season && season.episodes) {
      episodes_minutes_textless = season.episodes.map(ep => Math.ceil((ep.runtime_in_sec__textless || 0) / 60));
    } else {
      episodes_minutes_textless = [Math.ceil(((general || {}).runtime_in_sec__textless || 0) / 60)];
    }
  }

  const params = {
    qc_profile_id,
    video_formats: video_format,
    audio_format,
    caption_id,
    runtime_minutes: episodes_minutes,
    runtime_textless_minutes: episodes_minutes_textless,
    qc_type_id: values.qc_type_id,
    ...(values.request_type_id !== QC_REQUEST_TYPE_NEW_QC_REQUEST) && {origin_request_id: values.origin_request_id}
  };

  return params;
};

export const getCostDetails = (values, withoutStepsParam) => {
  const {office_flag, replacement} = values;
  const isNewQuote = isNewQuoteProject(values);
  const params = isNewQuote ? convertValuesToRequestData(values) : prepareParamsToGetCostDetails(values);
  let wo_steps;
  if (!withoutStepsParam && replacement && replacement.wo_steps && (replacement.use_original_work_order_steps || hasChangedWoSteps(replacement.wo_steps))) {
    wo_steps = replacement.wo_steps;
  }
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/${isNewQuote ? 'quote_project/' : ''}cost_details`,
      type: 'POST',
      contentType: 'application/json;charset=utf-8',
      dataType: 'json',
      data: JSON.stringify({
        ...params,
        office_flag,
        wo_steps
      })
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getStepsForReplacement = (revisionRequestId, {includePatchSteps, useOriginalWorkOrderSteps}) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/replacement_steps`,
      type: 'GET',
      data: {
        origin_request_id: revisionRequestId,
        include_patch_steps: includePatchSteps,
        use_original_work_order_steps: useOriginalWorkOrderSteps,
      }
    })
    .done(res => {
      resolve(res);
    })
    .fail(error => {
      reject(error);
    });
  });
};

const prepareParamsToGetVersions = values => {
  const {
    content_type_id, distributor_id, season_name, series_id, trailer_episode_name,
    studio_id, production_id, finishing_house_id, cs_studio_id, cs_production_id, cs_finishing_house_id
  } = values;
  return {
    ...prepareParamsToGetCostDetails(values),
    studio_id,
    production_id: prepareProductionIDs(production_id),
    finishing_house_id: prepareFinishingHouseIDs(finishing_house_id),
    cs_studio_id,
    cs_production_id,
    cs_finishing_house_id,
    cs_production_ids: prepareProductionIDs(production_id),
    cs_finishing_house_ids: prepareFinishingHouseIDs(finishing_house_id),
    content_type_id,
    distributor_id,
    trailer_episode_name,
    season_name,
    series_id: (typeof series_id === 'number' ? series_id : null)
  };
};

export const getProjectDataForWizard = values => {
  const params = prepareParamsToGetVersions(values);
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/new/project_data`,
      type: 'GET',
      data: {
        params: JSON.stringify(params)
      }
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getExistingTagsForNewRequest = values => {
  const params = prepareParamsToGetVersions(values);
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/new/existing_tags`,
      type: 'GET',
      data: {
        params: JSON.stringify(params)
      }
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getSeasonEpisodes = seasonRequestId => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/season/${seasonRequestId}/episodes`,
      type: 'GET'
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getProfileMediaConfiguration = (qcProfileId, qcTypeId) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/new/profile/${qcProfileId}/media_configuration?qc_type_id=${qcTypeId}`,
      type: 'GET'
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getUserPermissions = (values, filters) => {
  const {
    content_type_id, distributor_id, season_name, series_id, trailer_episode_name, qc_profile_id,
    studio_id, production_id, finishing_house_id, cs_studio_id, cs_production_id, cs_finishing_house_id
  } = values;
  const isManager = isManagerUser();
  let mngrStudioId, mngrProductionId, mngrFinishingHouseId, mngrFinishingHouseIDs, mngrProductionIDs;
  if (isManager) {
    if (typeof studio_id === 'number' || typeof cs_studio_id === 'number') {
      mngrStudioId = cs_studio_id || studio_id;
    }
    if (typeof cs_production_id === 'number' || typeof production_id === 'number') {
      mngrProductionId = cs_production_id || production_id;
    }
    if (typeof cs_finishing_house_id === 'number' && cs_finishing_house_id > 0) {
      mngrFinishingHouseId = cs_finishing_house_id;
    }
    if (Array.isArray(finishing_house_id) && finishing_house_id.length) {
      mngrFinishingHouseIDs = finishing_house_id.filter(fhId => fhId !== FINISHING_HOUSE__N_A);
    }
    if (Array.isArray(production_id) && production_id.length) {
      mngrProductionIDs = production_id.filter(prId => !!prId);
    }
  }
  const params = {
    cs_studio_id: mngrStudioId,
    cs_production_id: mngrProductionId,
    cs_production_ids: mngrProductionIDs,
    cs_finishing_house_id: mngrFinishingHouseId,
    cs_finishing_house_ids: mngrFinishingHouseIDs,
    ...(typeof series_id === 'number' ? {
      series_id,
      studio_id: isManager ? mngrStudioId : studio_id,
      season_name,
      trailer_episode_name,
      content_type_id,
      distributor_id,
      qc_profile_id
    } : {})
  };
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/users`,
      type: 'GET',
      data: {
        params: JSON.stringify(params)
      }
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getDictionariesForWoEditSteps = (values) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/css/requests/edit_steps_data`,
      type: 'GET',
      data: {
        for_wizard: true,
        request_id: (values.origin_request || {}).RequestID,
      }
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const getCsvForQuoteSteps = (values) => dispatch => {
  const params = convertValuesToRequestData(values);
  return $.ajax({
      url: `${config.apiUrl}/v1/qc_on_demand/requests/quote_project/cost_details_csv`,
      type: 'POST',
      contentType: 'application/json;charset=utf-8',
      data: JSON.stringify(params)
    })
      .done(res => {
        const blob = new Blob([res], {type: 'text/csv'});
        const blobLink = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = 'quote_project_steps.csv';
        a.href = blobLink;
        a.click();
      })
      .fail(() => {
        dispatch(showError('Something went wrong'));
      });
};
