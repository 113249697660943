import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ADMIN_ANALYTICS_IS_LOADING,
  ADMIN_ANALYTICS_IS_SAVING,
  ADMIN_ANALYTICS_SET_REPORTS,
  ADMIN_ANALYTICS_SET_REPORT_MODAL_DATA,
} from './actions';

const initialState = {
  isLoading: false,
  isSaving: false,
  reports: [],
  dictionaries: {},
  reportModalData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case ADMIN_ANALYTICS_IS_LOADING: {
      return {
        ...state,
        isLoading: action.loading || action.saving || initialState.isLoading
      };
    }

    case ADMIN_ANALYTICS_IS_SAVING: {
      return {
        ...state,
        isSaving: action.saving || action.loading || initialState.isSaving
      };
    }

    case ADMIN_ANALYTICS_SET_REPORTS: {
      return {
        ...state,
        isLoading: initialState.isLoading,
        isSaving: initialState.isSaving,
        dictionaries: action.dictionaries || state.dictionaries,
        reports: action.reports || initialState.reports
      };
    }

    case ADMIN_ANALYTICS_SET_REPORT_MODAL_DATA: {
      return {
        ...state,
        reportModalData: action.data || initialState.reportModalData
      };
    }

    default:
      return state;
  }
}
