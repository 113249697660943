import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {createNewRequest} from '../../actions';

class RowWithoutRequest extends Component {
  onClick = () => {
    const {dispatch, history, row} = this.props;
    dispatch(createNewRequest(history, row));
  };

  render() {
    const {row, canManage} = this.props;
    return (
      <StyledRowWithoutRequest>
        <td className="episode-title">
          {row.EpisodeNum}{row.Title ? `. ${row.Title}` : row.Tag ? <span className="req-tag" title="Tag">[{row.Tag}]</span> : ''}
        </td>
        <td className="format">{row.RequestFormat}</td>
        <td className="last-update">No Request Created</td>
        <td className="create-btn-cell" colSpan={3}>
          {canManage ? <StyledButtonCreate
            className="create-btn hidden-xs"
            onClick={this.onClick}
          >+ Create New QC Request</StyledButtonCreate> : <>&nbsp;</>}
        </td>
      </StyledRowWithoutRequest>
    );
  }
}

const StyledRowWithoutRequest = styled.tr`
  &:hover .create-btn {
    display: block;
  }
  > td {
    color: #b3b3b3;
    &.episode-title {
      position: relative;
      span.req-tag {
        margin-right: 4px;
        font-size: 11px;
        position: absolute;
        right: 0;
        top: 3px;
      }
    }
  }
`;

const StyledButtonCreate = styled(Button)`
  border: none;
  color: #0DB4D9;
  display: none;
  padding: 0;
  &:hover, &:focus {
    background: transparent;
    color: #228daf;
    outline: none;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 14px;
  }
`;

RowWithoutRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  canManage: PropTypes.bool
};

export default connect()(
  withRouter(RowWithoutRequest)
);
