import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils/general';
import {showSuccess, showError} from '../../../layouts/actions';

export const NET_REQUEST_DETAILS_CLEAR = 'NET_REQUEST_DETAILS_CLEAR';
export const NET_REQUEST_DETAILS_SET_ACTIVE_TAB = 'NET_REQUEST_DETAILS_SET_ACTIVE_TAB';
export const NET_REQUEST_DETAILS_SET_LOADING = 'NET_REQUEST_DETAILS_SET_LOADING';
export const NET_REQUEST_DETAILS_UPDATE = 'NET_REQUEST_DETAILS_UPDATE';
export const NET_REQUEST_DETAILS_RECEIVE = 'NET_REQUEST_DETAILS_RECEIVE';
export const NET_REQUEST_DETAILS_WO_STEPS_RECEIVE = 'NET_REQUEST_DETAILS_WO_STEPS_RECEIVE';
export const NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL = 'NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL';
export const NET_REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS = 'NET_REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS';

export const clearRequestDetails = () => ({type: NET_REQUEST_DETAILS_CLEAR});

export const setActiveTab = activeTab => ({type: NET_REQUEST_DETAILS_SET_ACTIVE_TAB, activeTab});

export const setLoading = (name, loading) => ({type: NET_REQUEST_DETAILS_SET_LOADING, name, loading});

export const updateRequestData = data => ({type: NET_REQUEST_DETAILS_UPDATE, data});

const getRequestSteps = () => (dispatch, getState) => {
  const {details: {RequestID, QcCost: requestQcCost}} = getState().netflixRequestDetails;
  const loadingName = 'isLoadingWoSteps';
  dispatch(setLoading(loadingName, true));
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${RequestID}/steps`
  })
  .done(res => {
    dispatch({type: NET_REQUEST_DETAILS_WO_STEPS_RECEIVE, data: res});
    if (typeof requestQcCost === 'undefined') {
      dispatch(updateRequestData({QcCost: null}));
    }
  })
  .fail(error => {
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request steps'));
  })
  .always(() => {
    dispatch(setLoading(loadingName, false));
  });
};

export const getRequestDetails = (history, requestId) => dispatch => {
  const loadingName = 'isLoadingDetails';
  dispatch(setLoading(loadingName, true));
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${requestId}`
  })
  .done(res => {
    const {request: details, filters} = res;
    dispatch({type: NET_REQUEST_DETAILS_RECEIVE, filters, details});
    dispatch(setLoading(loadingName, false));
  })
  .then(() => {
    dispatch(getRequestSteps());
  })
  .fail(error => {
    dispatch(setLoading(loadingName, false));
    console.log(getErrorMessage(error));
    dispatch(showError('Could not get request details'));
    if (history) {
      history.push('/requests');
    } else {
      window.location.href = '/requests';
    }
  });
};

export const updateRequestField = ({label, name, value, callback, needToReloadSteps}) => (dispatch, getState) => {
  const {details: {RequestID}} = getState().netflixRequestDetails;
  const loadingName = 'isSavingDetails';
  dispatch(setLoading(loadingName, true));
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${RequestID}/field`,
    data: {
      name,
      value
    }
  })
  .done(res => {
    callback && callback();
    dispatch(updateRequestData({[name]: value, ...res}));
    dispatch(showSuccess(`The ${label} has been updated.`));
    if (needToReloadSteps) {
      dispatch(getRequestSteps());
    }
  })
  .fail(error => {
    dispatch(showError(`Could not update the ${label}.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch(setLoading(loadingName, false));
  });
};

const getDictionariesForEditSteps = () => (dispatch, getState) => {
  const {details: {RequestID}} = getState().netflixRequestDetails;
  const loadingName = 'isLoadingWoStepDictionaries';
  dispatch(setLoading(loadingName, true));
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${RequestID}/edit_steps_data`
  })
  .done(res => {
    dispatch({
      type: NET_REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS,
      filters: res
    });
  })
  .fail(error => {
    dispatch(showError(`Could not get dictionaries for edit the steps.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch(setLoading(loadingName, false));
  });
};

export const showWoStepsModal = () =>  dispatch => {
  dispatch(getDictionariesForEditSteps());
  dispatch({type: NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL, isShow: true});
};

export const closeWoStepsModal = needToReloadSteps => dispatch => {
  if (needToReloadSteps) {
    dispatch(getRequestSteps());
  }
  dispatch({type: NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL});
};

export const updateRequestSteps = (steps, callback) => (dispatch, getState) => {
  const {details: {RequestID}} = getState().netflixRequestDetails;
  const loadingName = 'isSavingWoSteps';
  dispatch(setLoading(loadingName, true));
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${RequestID}/steps`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(steps)
  })
  .done(() => {
    dispatch(setLoading(loadingName, false));
    dispatch(showSuccess('Steps have been successfully changed'));
    callback && callback();
    dispatch(getRequestSteps());
  })
  .fail(error => {
    dispatch(setLoading(loadingName, false));
    dispatch(showError(`Could not update steps.  ${getErrorMessage(error)}`));
  });
};

export const getSpecSteps = (type, setLoadingSteps) => (dispatch, getState) => {
  const {details: {RequestID}} = getState().netflixRequestDetails;
  setLoadingSteps(true);
  return $.ajax({
    type: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/css/requests/netflix/${RequestID}/spec_steps`,
    data: {
      type
    }
  })
  .always(() => {
    setLoadingSteps(false);
  })
  .fail(error => {
    dispatch(showError(getErrorMessage(error) || `Could not get ${type === 'patch' ? 'patch' : 'common'} steps.`));
  });
};
