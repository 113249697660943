import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {getOptionName} from '../../../../utils/general';
import {StyledLink} from '../Top';
import MultiSelect from './MultiSelect';
import {FINISHING_HOUSE__N_A} from '../../../NewRequest/constants';
import confirmImg from '../../../../../assets/images/settings/confirm.svg';
import cancelImg from '../../../../../assets/images/settings/cancel.svg';
import UpdateWOsConfirmModal from '../modals/UpdateWOsConfirmModal';

function StudioPartnersField(props) {
  const {
    componentArgs, dictionaries, dictionaryName, label, name, partners, onChange, isFinishingHouse, isPartnerRequest,
    seasonProjectName, versionName, requestId, onClickEdit, isArtworkRequest
  } = props;
  const labelText = `${label}${partners && partners.length > 1 ? 's' : ''}`;
  const options = dictionaries[dictionaryName] || [];
  const requiredPartnerId = isPartnerRequest && partners && partners.length && partners[0] ? partners[0] : null;

  const [editModeValues, setEditModeValues] = useState(null);

  const handleCloseEditMode = () => setEditModeValues(null);

  const [showConfirmUpdate, setShowConfirmUpdate] = useState(null);

  const handleCloseConfirm = () => setShowConfirmUpdate(null);

  useEffect(() => {
    setEditModeValues(null);
    setShowConfirmUpdate(null);
  }, [requestId, setEditModeValues, setShowConfirmUpdate]);

  const handleApplyChanges = () => {
    if (JSON.stringify(partners || []) !== JSON.stringify(editModeValues)) {
      setShowConfirmUpdate({
        onClose: handleCloseConfirm,
        onConfirm: updateType => {
          onChange(label, name, editModeValues, isArtworkRequest ? 'all' : updateType);
          handleCloseEditMode();
          handleCloseConfirm();
        },
        modalTitle: <>You are about to change the {label}s{(seasonProjectName ? <> for <span style={{fontWeight: 500}}>{seasonProjectName}</span> requests</> : '')}.</>,
        modalText: isArtworkRequest ? 'Would you like to update all related requests' : 'Would you like to:',
        submitButtonLabel: 'OK',
        hideCheckbox: isArtworkRequest,
        updateTypes: isArtworkRequest ? null :[{
          value: 'current_version', name: `Update all requests of ${versionName || 'this'} version only`
        }, {
          value: 'all', name: 'Update all requests'
        }]
      });
    } else {
      handleCloseEditMode();
    }
  };

  const buildRequestsUrl = ids => {
    if (Array.isArray(ids) && ids.length) {
      const filterField = isFinishingHouse ? 'finishing_house' : 'production';
      return `/requests?${ids.map((id, i) => `${filterField}[${i}]=${id}`).join('&')}`;
    }
    if (typeof ids === 'number') {
      return buildRequestsUrl([ids]);
    }
    return null;
  };

  const handleChange = (value) => {
    if (!Array.isArray(value)) {
      value = [];
    }
    if (requiredPartnerId) {
      value = value.filter(i => i !== requiredPartnerId);
    }
    if (isFinishingHouse) {
      const oldValue = editModeValues;
      if (value.includes(FINISHING_HOUSE__N_A)) {
        if (!oldValue.includes(FINISHING_HOUSE__N_A)) {
          value = [FINISHING_HOUSE__N_A];
        } else if (value.length > 1) {
          value = value.filter(i => i !== FINISHING_HOUSE__N_A);
        }
      }
      if (!value.length) {
        value = [FINISHING_HOUSE__N_A];
      }
    }
    if (requiredPartnerId && !value.includes(requiredPartnerId)) {
      value.unshift(requiredPartnerId);
      value = value.filter(i => i !== FINISHING_HOUSE__N_A);
    }
    setEditModeValues(value);
  };

  const inner = editModeValues ?
    <StyledEditMode>
      {showConfirmUpdate !== null && <UpdateWOsConfirmModal {...showConfirmUpdate} />}
      <MultiSelect
        label={labelText}
        options={options}
        value={editModeValues.map(i => options.find(o => o.value === i))}
        onChange={selected => handleChange((selected || []).map(i => i.value))}
        requiredValue={requiredPartnerId ? [requiredPartnerId] : null}
      />
      <img src={confirmImg} title="Save" alt="" onClick={handleApplyChanges} />
      <img src={cancelImg} title="Cancel" alt="" onClick={handleCloseEditMode} />
    </StyledEditMode> :
    <>
      <label>
        {(partners || []).length > 1 ?
          <StyledLink
            to={buildRequestsUrl(partners)}
            target="_blank"
            title="Click to view related requests"
          >
            {labelText}
          </StyledLink> : labelText
        }
      </label>
      <StyledPartners>
       {(!partners || !partners.length) ? <div className="studio-partner--name no-value">None</div> :
         <>
          <div className="studio-partner--name">
            <StyledLink
              to={buildRequestsUrl(partners[0])}
              target="_blank"
              title="Click to view related requests"
            >
              {getOptionName(options, partners[0])}
            </StyledLink>
          </div>
          {partners.length > 1 &&
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={
                <Popover id="studio-partners-popover">
                  {partners.slice(1).map(spId => (
                    <div key={`sp-${spId}`}>
                      <a
                        href={buildRequestsUrl(spId)}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Click to view related requests"
                      >
                        {getOptionName(options, spId)}
                      </a>
                    </div>
                  ))}
                </Popover>
              }
            >
              <StyledBtnCompany
                className="btn--studio-partners btn--studio-partners--show-more"
                title="Show More"
              >
                <span>+{partners.length - 1}</span>
              </StyledBtnCompany>
            </OverlayTrigger>
          }
         </>
       }
       {!isArtworkRequest &&
         <StyledBtnCompany
          className="btn--studio-partners btn--studio-partners--edit"
          title="Edit"
          onClick={onClickEdit || (() => setEditModeValues(partners || []))}
         >
          <span><i className="far fa-edit"/></span>
         </StyledBtnCompany>
       }
      </StyledPartners>
    </>
  ;

  return props.component ? <props.component {...componentArgs}>{inner}</props.component> : <div {...componentArgs}>{inner}</div>;
}

export const StyledBtnCompany = styled.div`
  margin-left: 8px;
  span {
    padding: 3px 6px;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: rgba(9,30,66,0.04);
    color: #42526E;
    cursor: pointer;
    :hover {
      background: rgba(9,30,66,0.1);
    }
  }
  &[aria-describedby] {
    span {
      background: rgb(37, 56, 88) !important;
      transition-duration: 0s, 0.15s;
      color: rgb(244, 245, 247);
    }
  }
`;

const StyledPartners = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  .studio-partner--name {
    color: #555;
    &.no-value {
      font-weight: 300;
      opacity: 0.65;
    }
  }
`;

const StyledEditMode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  > .form-group {
    flex-grow: 1;
  }
  > img {
    margin-top: 26px;
    margin-left: 15px;
    flex: 0 0 21px;
    cursor: pointer;
  }
`;

StudioPartnersField.propTypes = {
  component: PropTypes.any,
  componentArgs: PropTypes.object,
  dictionaries: PropTypes.object.isRequired,
  dictionaryName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  partners: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func,
  isFinishingHouse: PropTypes.bool,
  isPartnerRequest: PropTypes.bool.isRequired,
  seasonProjectName: PropTypes.string,
  versionName: PropTypes.string,
  requestId: PropTypes.any.isRequired,
};

export default StudioPartnersField;
