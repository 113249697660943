import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StudioPartnersTable from '../table/StudioPartnersTable';
import styled from 'styled-components';
import {FINISHING_HOUSE__N_A} from '../../constants';

class FinishingHouses extends Component {
  getRequiredValue = () => {
    const {data, filters} = this.props;
    return filters.finishing_house_id || data.cs_finishing_house_id || null;
  };

  setDefaultValueIfNeeded = () => {
    const {data, onSelect, fieldName} = this.props;
    const requiredValue = this.getRequiredValue();
    if (!Array.isArray(data[fieldName]) || !data[fieldName].length) {
      const selected = [FINISHING_HOUSE__N_A];
      if (requiredValue) {
        selected.push(requiredValue);
      }
      onSelect(fieldName, selected);
    } else if (requiredValue && !data[fieldName].includes(requiredValue)) {
      onSelect(fieldName, [...data[fieldName], requiredValue]);
    }
  };

  componentDidMount() {
    this.setDefaultValueIfNeeded();
  }

  handleSelect = id => {
    const {data, onSelect, fieldName} = this.props;
    let selected = [...data[fieldName]];
    const requiredValue = this.getRequiredValue();
    if (requiredValue) {
      selected = selected.filter(i => i !== requiredValue);
    }
    if (id === FINISHING_HOUSE__N_A) {
      if (!selected.includes(FINISHING_HOUSE__N_A)) {
        selected = [FINISHING_HOUSE__N_A];
      } else if (selected.length > 1) {
        selected = selected.filter(i => i !== FINISHING_HOUSE__N_A);
      }
    } else if (id > 0) {
      selected.push(id);
      selected = selected.filter(i => i !== FINISHING_HOUSE__N_A);
    } else if (id < 0) {
      selected = selected.filter(i => i !== Math.abs(id));
    }
    if (!selected.length) {
      selected = [FINISHING_HOUSE__N_A];
    }
    if (requiredValue) {
      selected.push(requiredValue);
    }
    onSelect(fieldName, selected);
  };

  render() {
    const {data, filters, fieldName, filterName, label} = this.props;
    const selected = data[fieldName];
    const requiredValue = this.getRequiredValue();
    return (
      <StepBodyStyled>
        <StudioPartnersTable
          data={!requiredValue ? filters[filterName] : filters[filterName].filter(o => o.value !== requiredValue)}
          onSelect={this.handleSelect}
          selected={selected}
          label={label}
        />
      </StepBodyStyled>
    );
  }
}

const StepBodyStyled = styled.div`
  margin: 29px 20px 10px 73px;
`;

FinishingHouses.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

FinishingHouses.defaultProps = {
  data: {}
};

export default FinishingHouses;
