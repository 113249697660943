import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IssueTableStyled from '../../ReportDetails/components/IssueTableStyled';
import {getSortedRowsBy} from '../../../utils/general';
import Table from '../../../components/table/Table';
import Loader from '../../../components/Loader';
import DateCell from '../../../components/table/cells/DateCell';
import CostCell from '../../SeasonDetails/components/episodes/CostCell';
import TitleCell from '../../SeasonDetails/components/episodes/TitleCell';
import ListCell from './ListCell';
import LinkRequestCell from './LinkRequestCell';
import LinkReportCell from './LinkReportCell';
import QcProfileCell from './QcProfileCell';
import LinkSeasonReportCell from "./LinkSeasonReportCell";
import LinkCell from "./LinkCell";

class TableWrap extends Component {
  onScroll = () => {
    const {AnalyticsTable} = this.refs;
    const prevScrollTop = Number(AnalyticsTable.getAttribute('data-scrolltop'));
    AnalyticsTable.setAttribute('data-scrolltop', AnalyticsTable.scrollTop);
    const pagingDistanceToBottom = AnalyticsTable.scrollHeight / 3;
    if (AnalyticsTable.scrollTop > prevScrollTop &&
        AnalyticsTable.offsetHeight + AnalyticsTable.scrollTop + pagingDistanceToBottom > AnalyticsTable.scrollHeight) {
      this.props.onLoadMore();
    }
  };

  componentDidMount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.AnalyticsTable.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.AnalyticsTable.removeEventListener('scroll', this.onScroll);
    }
  }

  getHeaders() {
    const res = {};
    const {headers, reportName} = this.props;
    headers && headers.forEach(item => {
      res[item.name] = {
        title: (item.label === 'Finishing House' ? 'Post Partner' : item.label)
      };
      if (['submitted_on', 'DueDate'].includes(item.name) || item.type === 'date') {
        res[item.name].component = DateCell;
        res[item.name].componentArgs = {
          toFormat: item.format || 'MMMM D, YYYY HH:mm A'
        };
      } else if (['TotalCost'].includes(item.name)) {
        res[item.name].component = CostCell;
      } else if (item.name === 'title' && reportName === 'request_by_status') {
        res[item.name].component = TitleCell;
        res[item.name].componentArgs = {
          target: '_blank'
        };
      } else if (item.type === 'list') {
        res[item.name].component = ListCell;
      } else if (item.name === 'QC Profile Name' && reportName === 'qod_qc_profiles') {
        res[item.name].component = QcProfileCell;
      } else if (item.isLinkToRequestColumn) {
        res[item.name].component = LinkRequestCell;
      } else if (item.isLinkToReportColumn) {
        res[item.name].component = LinkReportCell;
      } else if (item.isLinkToSeasonReport) {
        res[item.name].component = LinkSeasonReportCell;
      }
      if (item.linkField) {
        res[item.name].component = LinkCell;
        res[item.name].componentArgs = {
          link_field: item.linkField
        };
      }
    });
    return res;
  }

  renderHeaderRow() {
    const {headers} = this.props;
    if (headers.some(item => Boolean(item.group))) {
      const groups = {};
      headers.forEach(item => {
        if (item.group) {
          groups[item.group] = (groups[item.group] || 0) + 1;
        } else {
          groups[item.label] = 0;
        }
      });
      return (
        <thead>
          <tr>
            {Object.keys(groups).map((k, i) =>
              <th
                key={`line-1-${i}`}
                className={k.toLowerCase().replace(/\W/g, '-')}
                colSpan={groups[k] || undefined}
              >
                {k}
              </th>
            )}
          </tr>
          <tr>
            {headers.map((item, i) =>
              <th
                key={`line-2-${i}`}
                className={item.label.toLowerCase().replace(/\W/g, '-')}
              >
                {item.group ? item.label : <span>&nbsp;</span>}
              </th>
            )}
          </tr>
        </thead>
      );
    }
    return null;
  }

  getRowClassNames = row => {
    const {reportName} = this.props;
    if (reportName !== 'season_report_v2_with_cost') {
      return;
    }

    if (row['Status'] === 'Waiting for Assets') {
      return 'color_grey';
    }
    if (row['Status'] === 'QC In progress') {
      return 'color_blue';
    }
    if (row['Status'] === 'Completed') {
      if (row['QC Result'] === 'Review') {
        return 'color_orange';
      }
      if (row['QC Result'] === 'Pass') {
        return 'color_green';
      }
      if (row['Delivered On'] !== null) {
        return 'color_green';
      }
      return 'color_orange';
    }
  };

  render() {
    const {data, loading, tableSort, reportName, moreLoading} = this.props;
    const headers = this.getHeaders();
    return (
      <TableStyled>
        {loading ? <Loader/> : null}
        <div className={this.props.hidden ? 'is-hidden' : undefined}>
          <AnalyticsTableStyled issueBorderWidth={1} ref="AnalyticsTable" id="AnalyticsTable">
            {Object.keys(headers).length ? (
              <Table
                headerRow={this.renderHeaderRow()}
                className={`withoutSort ${reportName || ''}`}
                headers={headers}
                rows={getSortedRowsBy(data, tableSort.field, tableSort.direction)}
                emptyText="No Data"
                getRowClassNames={this.getRowClassNames}
              />
            ) : null}
          </AnalyticsTableStyled>
          {moreLoading ? <Loader className="loader-more"/> : null}
        </div>
      </TableStyled>
    );
  }
}

export const HEADER_HEIGHT = '59px';
export const TOP_HEIGHT = '64px';
export const REPORT_HEADER_HEIGHT = '44px';
export const TOP_PADDING_1 = '30px';
export const TOP_PADDING_2 = '10px';

const AnalyticsTableStyled = styled(IssueTableStyled)`
  max-height: calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT} - ${REPORT_HEADER_HEIGHT} - (2 * ${TOP_PADDING_1}) - (2 * ${TOP_PADDING_2}));
  overflow-y: auto;
  padding-right: 5px;
  @media (min-width: 992px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent/*rgba(255, 255, 255, 1)*/;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }

  table tbody tr {
    &.color_orange td {
      color: orange !important;
    }
    &.color_green td {
      color: green !important;
    }
    &.color_blue td {
      color: blue !important;
    }
    &.color_grey td {
      color: grey !important;
    }
  }
`;

const TableStyled = styled.div`
  padding: 30px 40px;
  > div {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 10px 15px;
    position: relative;
    &.is-hidden {
      visibility: hidden;
    }
    .loader-container.loader-more {
      padding: 0;
      top: unset;
      left: 15px;
      bottom: 10px;
      width: calc(100% - 40px);
      height: 30px;
      .react-spinner {
        top: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .table {

    &.quibi_daily_qc_wrap_report {
      tr {
        th, td {
          min-width: 100px;
          &[class^="subtitling-"] {
            min-width: 120px;
          }
          &:last-child {
            min-width: 200px;
          }
          &:first-child {
            border-left-width: 0 !important;
          }
        }
      }
    }

    &.formatting_issues_across_asset_formats,
    &.due_today {
      tr {
        th {
          white-space: nowrap;
          min-width: 80px;
          &[title="Title/Version"] {
            min-width: 250px;
          }
        }
      }
    }

    > thead > tr {
      th {
        position: sticky;
        top: 0;
        background-color: #fff;
        font-size: 12px;
        padding: 7px;

        &[colspan] {
          text-align: center;
          padding-right: 52px;
        }
      }

      &:nth-child(2) th {
        top: 31px;
      }

      &:first-child th {
        border-bottom-style: dotted;
      }

      &:last-child th {
        border-top: 0;
        border-bottom-style: solid;
        border-bottom-width: 2px;
      }
    }

    > tbody > tr {
      cursor: default;

      td {
        font-size: 12px;
        padding: 7px;
        color: rgb(29, 28, 29);
      }
    }
  }
`;

TableWrap.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  tableSort: PropTypes.object.isRequired,
  reportName: PropTypes.string,
  moreLoading: PropTypes.bool,
  onLoadMore: PropTypes.func
};

export default TableWrap;
