/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getDictionariesForWoEditSteps, getStepsForReplacement} from '../../actions';
import Loader from '../../../../components/Loader';
import {Checkbox} from 'react-bootstrap';
import EditWorkOrderStepsTable from '../../../RequestDetails/components/editWorkOrderStepsTable/EditWorkOrderStepsTable';

function CsWorkOrderSteps(props) {
  const {data, onChange} = props;
  const {origin_request_id, replacement, qc_profile_id} = data;

  const [isLoading, setLoading] = useState(false);
  const [woSteps, setWoSteps] = useState(replacement.wo_steps || []);
  const [isLoadingDictionaries, setLoadingDictionaries] = useState(false);
  const [dictionaries, setDictionaries] = useState({});

  const hasStepsForRequest = woSteps.some(s => s.origin_request_id === origin_request_id);

  const handleChangeSteps = (newSteps, includePatchSteps, useOriginalWorkOrderSteps) => {
    onChange('replacement', {...replacement, wo_steps: newSteps.map(s => ({...s, origin_request_id})), include_patch_steps: includePatchSteps, use_original_work_order_steps: useOriginalWorkOrderSteps});
  };

  const handleChangeIncludePatchSteps = ({target: {checked}}) => {
    setLoading(true);
    loadStepsForReplacement({includePatchSteps: checked, useOriginalWorkOrderSteps: false});
  };

  const handleChangeUseOriginalWorkOrderSteps = ({target: {checked}}) => {
    setLoading(true);
    loadStepsForReplacement({includePatchSteps: false, useOriginalWorkOrderSteps: checked});
  };

  const loadStepsForReplacement = (params) => {
    getStepsForReplacement(origin_request_id, params).then(
      res => {
        setLoading(false);
        const stepsForReplacement = (res || []).map((s, i) => ({...s, origin_request_id, StepOrder: i + 1, tmplProductCode: s.ProductCode, tmplRate: s.OverrideRate, tmplUnit: s.Unit}));
        setWoSteps(stepsForReplacement);
        handleChangeSteps(stepsForReplacement, params.includePatchSteps, params.useOriginalWorkOrderSteps);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    )
  }

  useEffect(() => {
    if (!hasStepsForRequest) {
      setLoading(true);
      setWoSteps([]);
      handleChangeSteps([]);
      loadStepsForReplacement({});
    }
  }, [origin_request_id, hasStepsForRequest]);

  useEffect(() => {
    setLoadingDictionaries(true);
    getDictionariesForWoEditSteps(data).then(
      res => {
        setLoadingDictionaries(false);
        setDictionaries(res || {});
      },
      error => {
        console.log(error);
        setLoadingDictionaries(false);
        setDictionaries({});
      }
    );
  }, [qc_profile_id]);

  return (
    <StyledStepBodyWrap className="step-body-wrapper cs-wo-steps">
      {(isLoading || isLoadingDictionaries) && <Loader className="full-screen"/>}
      <Checkbox
        checked={replacement.include_patch_steps || false}
        onChange={handleChangeIncludePatchSteps}
        title="Reset all changes and use steps from template with patch"
      >
        Use Template Steps With Patch
      </Checkbox>
      <Checkbox
          checked={replacement.use_original_work_order_steps || false}
          onChange={handleChangeUseOriginalWorkOrderSteps}
          title="Reset all changes and use steps from original work order"
      >
        Use steps from original work order
      </Checkbox>
      <EditWorkOrderStepsTable
        loading={isLoading || isLoadingDictionaries}
        filters={dictionaries}
        storedSteps={[]}
        steps={replacement.wo_steps || []}
        onChangeSteps={handleChangeSteps}
        isCreatedWO={false}
      />
    </StyledStepBodyWrap>
  );
}
const StyledStepBodyWrap = styled.div`
  .table {
    tr {
      th, td {
        &.status,
        &.time-spent {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .submit-replacement-modal & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

CsWorkOrderSteps.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CsWorkOrderSteps;
