import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils/general';
import {showError, showSuccess} from '../../../layouts/actions';

export const ADMIN_ANALYTICS_IS_LOADING = 'ADMIN_ANALYTICS_IS_LOADING';
export const ADMIN_ANALYTICS_IS_SAVING = 'ADMIN_ANALYTICS_IS_SAVING';
export const ADMIN_ANALYTICS_SET_REPORTS = 'ADMIN_ANALYTICS_SET_REPORTS';
export const ADMIN_ANALYTICS_SET_REPORT_MODAL_DATA = 'ADMIN_ANALYTICS_SET_REPORT_MODAL_DATA';

export const getReports = isInitialLoading => dispatch => {
  dispatch({type: ADMIN_ANALYTICS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/analytics`,
    data: {
      with_dictionaries: isInitialLoading
    }
  })
  .done(res => {
    dispatch({type: ADMIN_ANALYTICS_SET_REPORTS, ...res});
  })
  .fail(error => {
    dispatch({type: ADMIN_ANALYTICS_IS_LOADING, loading: false});
    if (isInitialLoading) {
      dispatch(showError(`Could not get analytics reports.  ${getErrorMessage(error)}`));
    }
  });
};

const setReportModalData = data => ({type: ADMIN_ANALYTICS_SET_REPORT_MODAL_DATA, data});

export const openReportModal = (data, type) => dispatch => {
  dispatch(setReportModalData({...data, ...(type ? {[type]: true} : {})}));
};

export const closeReportModal = () => dispatch => {
  dispatch(setReportModalData(null));
};

export const updateReportPermissions = (report, permissions) => (dispatch, getState) => {
  const reports = [...getState().adminAnalytics.reports];
  dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/analytics/${report.ReportID}/permissions`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(permissions)
  })
  .done(() => {
    const idx = reports.findIndex(r => r.ReportID === report.ReportID);
    if (idx !== -1) {
      reports[idx] = {...reports[idx], Permissions: permissions};
    }
    dispatch({type: ADMIN_ANALYTICS_SET_REPORTS, reports});
    dispatch(showSuccess(`Report permissions has been successfully updated`));
  })
  .fail(error => {
    dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: false});
    dispatch(showError(`Could not update report permissions.  ${getErrorMessage(error)}`));
  });
};

export const updateReport = (report, values) => (dispatch, getState) => {
  const reports = [...getState().adminAnalytics.reports];
  dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/analytics`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(values)
  })
  .done(() => {
    const idx = reports.findIndex(r => r.ReportID === report.ReportID);
    if (idx !== -1) {
      reports[idx] = {...reports[idx], ...values};
    }
    dispatch({type: ADMIN_ANALYTICS_SET_REPORTS, reports});
    dispatch(showSuccess(`Report has been successfully updated`));
  })
  .fail(error => {
    dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: false});
    dispatch(showError(`Could not update report.  ${getErrorMessage(error)}`));
  });
};

export const saveReportEmail = (report, email) => (dispatch, getState) => {
  const reports = [...getState().adminAnalytics.reports];
  dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/analytics/report_email`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(email)
  })
  .done(savedReportEmailId => {
    const idx = reports.findIndex(r => r.ReportID === report.ReportID);
    if (idx !== -1) {
      const emailsList = [...reports[idx].Emails || []];
      email.ReportEmailID = savedReportEmailId;
      const idx2 = emailsList.findIndex(r => r.ReportEmailID === email.ReportEmailID);
      if (idx2 !== -1) {
        emailsList[idx2] = {...emailsList[idx2], ...email};
      } else {
        emailsList.push(email);
      }
      reports[idx] = {...reports[idx], Emails: emailsList};
    }
    dispatch({type: ADMIN_ANALYTICS_SET_REPORTS, reports});
    dispatch(showSuccess(`Report schedule has been successfully saved`));
  })
  .fail(error => {
    dispatch({type: ADMIN_ANALYTICS_IS_SAVING, loading: false});
    dispatch(showError(`Could not save report schedule.  ${getErrorMessage(error)}`));
  });
};
