import styled from 'styled-components';
import backImg from '../../assets/images/back.svg';

export const StyledModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: ${props => props.opacity !== undefined ? props.opacity : 0.7};

  @media (max-width: 991px) {
    opacity: ${props => props.opacity !== undefined ? props.opacity : 0.4};
  }
`;

export const StyledModalDialog = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10%;
  z-index: 1050;

  .loader-container {
    z-index: 10700 !important;
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

export const StyledModal = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
`;

export const StyledIssueModalHeader = styled.div`
  background-color: #F9F9F9;
  border-left: 5px solid ${props => props.severityColor ? `#${props.severityColor}` : 'transparent'};
  padding: 41px 45px 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #282828;
    margin-top: 0;
    margin-bottom: 25px;
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

export const StyledBaseCloseModal = styled.div`
  cursor: pointer;
  font-size: 23px;
  line-height: 1;
  color: #646464;
  &:hover {
    color: #565656;
  }
`;

export const StyledCloseModal = styled(StyledBaseCloseModal)`
  position: absolute;
  top: 41px;
  right: 45px;
`;

export const StyledIssueModalBody = styled.div`
  position: fixed;
  top: ${props => (props.top || '143px')};
  bottom: ${props => (props.bottom || '71px')};
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(0, 0, 0, .1);
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    top: 68px;
    bottom: 83px;
  }
`;

export const StyledModalSubmitFooter = styled.div`
  background-color: #f3f3f3;
  height: 71px;
  padding: 17px 90px 16px 45px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  &>div {
    float: right;

    @media (max-width: 767px) {
      float: none;
      width: 100%;
      height: 100%;
      background-color: #484848 !important;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05) !important;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
  button {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background-color: #646464 !important;
    border-radius: 100px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 11px 40px 12px;
    &.btn-primary {
      @media (min-width: 992px) {
        min-width: 128px;
        &:hover:not([disabled]) {
          background-color: #565656 !important;
        }
      }
      &[disabled] {
        color: #c8c8c8;
        /*cursor: default;*/
      }

      @media (max-width: 767px) {
        border-radius: 0;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 14px;
        background-color: #484848 !important;
        padding: 18px 0 28px;
        width: 100%;
      }
    }
    &.btn-default {
      background-color: transparent !important;
      color: #282828;
      padding-left: 0;
      padding-right: 0;
      margin-right: 18px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    height: 83px;
    padding: 0;
    opacity: ${props => (props.disabled ? 0.65 : 1)};
  }
`;

export const StyledMobileModal = styled.div`
  @media (max-width: 768px) {
    .modal-dialog-custom {
      display: table;
      left: 0;
      width: 85%;
      margin: auto;
      height: auto;
      .modal-styled-custom {
        background-color: #f7f7f7;
        border-radius: 5px;
        height: auto;
        margin: auto;
        overflow-y: auto;
      }
      .modal-header-custom {
        background: #fff;
        left: 0;
        padding: 20px 25px;
        position: relative; 
        width: 100%;
        .icon-close-modal {
          right: 25px;
          top: 14px;
          &:before {
            content: 'Close';
            font-size: 13px;
          }
        }
      }
      .modal-body-custom {
        left: 0;
        padding: 25px 25px calc(50px + 25px);
        position: relative;
        top: 0;
        width: 100%;
        > .wrap-content {
          max-height: 40vh;
          overflow-x: hidden;
          overflow-y: auto;

          .control-label {
            color: #282828;
          }
        }
        .btn-toolbar {
          margin-left: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          .btn-cancel {
            display: none;
          }
          .btn-save {
            background: #595959;
            border-radius: 0;
            color: #fff;
            height: 50px;
            font-size: 0;
            opacity: 1;
            line-height: 28px;
            margin: 0;
            width: 100%;
            &:before {
              content: 'Save';
              color: #fff;
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
            }
            &:disabled {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .mobile-modal-dialog-custom {

      .modal-header-custom {
        padding: 19px 0 0;
        border-left: 0;
        background-color: #fff;

        h4 {
          font-weight: bold;
          line-height: 20px;
          padding-left: 56px;
          margin: 0 0 29px;
        }

        .icon-close-modal {
          top: 22px;
          right: 31px;
          font-size: 13px;
          &:before {
            content: 'Cancel';
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 14px;
            color: #999;
          }
        }

        .back-btn {
          background: url(${backImg}) no-repeat;
          background-position: 0;
          height: 15px;
          width: 20px;
          position: absolute;
          top: 22px;
          left: 23px;
        }
      }
      .modal-body-custom {
        top: 68px;
      }
    }
  }
`;
