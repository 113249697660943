import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {savePreviousPageUrl} from '../pages/Login/actions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getUserRoles, buildRedirectUrlToLoginPage, getDefaultRedirectUrl} from "../utils/general";

const hasAccess = resourceToCheck => {
  const userRoles = getUserRoles();
  return !_.isEmpty(_.intersection(userRoles, resourceToCheck));
};

class PrivateRoute extends Component {
  UNSAFE_componentWillMount() {
    const {dispatch, resource} = this.props;
    if (!hasAccess(resource)) {
      dispatch(savePreviousPageUrl());
    }
  }

  render() {
    const {resource, ...props} = this.props;

    if (hasAccess(resource)) {
      return (
        <Route {...props}/>
      );
    }

    const userRoles = getUserRoles();
    return userRoles.length > 0 ? (
      <Redirect to={{pathname: getDefaultRedirectUrl()}}/>
    ) : (
      <Redirect to={buildRedirectUrlToLoginPage()}/>
    );
  }
}

PrivateRoute.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.array
};

export default connect()(PrivateRoute);
