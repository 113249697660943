import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal, Button} from 'react-bootstrap';
import styled, {createGlobalStyle} from 'styled-components';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const nextedStyle = {
  marginLeft: '10px',
  width: 'unset'
}

const SortableItem = SortableElement(({value}) => <div className="sortable-row-helper">{value}</div>);
const SortableNestedItem = SortableElement(({value}) => <div className="sortable-row-helper" style={nextedStyle}>{value}</div>);

const SortableProfileList = SortableContainer(({items}) => {
  return (
      <div>
        {items.map((value, index) => {
          return (
            <>
              <SortableItem
                key={`item-${value.id}`}
                index={index}
                value={(index + 1) + '. ' + value.name}
              />
              {value.video_formats && value.video_formats.map((v, i) => (
                <SortableNestedItem
                  key={`item-${v.id}`}
                  index={i}
                  value={(index + 1) + '.' + (i + 1) + '. ' + v.name}
                  collection={index + 1}
                />
              ))}
            </>
          )
        })}
      </div>
  );
});

class ReorderProjectGroupsModal extends Component {
  state = {
    groups: this.props.groups
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.groups);
  };

  onSortEnd = ({oldIndex, newIndex, collection}) => {

    if (collection === 0) {
      this.setState(({groups}) => ({
        groups: arrayMove(groups, oldIndex, newIndex),
      }));
      return;
    }
    const colIdx = collection - 1;
    if (this.state.groups[colIdx].video_formats) {
      const newGroups = [...this.state.groups];

      newGroups[colIdx].video_formats = arrayMove(
        this.state.groups[colIdx].video_formats,
        oldIndex,
        newIndex,
      );

      this.setState({
        groups: newGroups
      });
    } else {
      this.setState(({groups}) => ({
        groups: arrayMove(groups, oldIndex, newIndex),
      }));
    }
  };

  render() {
    const {groups} = this.state;
    return (
      <Modal
        className="reorder-quote-project-groups-modal"
        show={true}
        onHide={this.handleClose}
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Reorder project groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <div>Use drag and drop to change the order of groups.</div>
            <SortableProfileList items={groups} onSortEnd={this.onSortEnd} />
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStyled
            bsStyle="primary"
            onClick={this.handleSubmit}
            >
            Submit
          </ButtonStyled>
          <ButtonStyled bsStyle="default" onClick={this.handleClose}>Cancel</ButtonStyled>
        </Modal.Footer>
        <GlobalStyled/>
      </Modal>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  div.sortable-row-helper {
    z-index: 1070;
    background-color: #f1f4f7;
    width: 100%;
    margin: 0 0 3px;
    padding: 2px;
    border: 2px solid #e3e9ef;
    cursor: move;
  }
`;

const ButtonStyled = styled(Button)`
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-radius: 100px;

    &.btn-primary {
      padding-left: 20px;
      padding-right: 20px;
      background: #33333F;
      opacity: 0.8;
    }
`;

const ModalContainerStyled = styled.div`
  div {
    margin-bottom: 10px;
  }
`;

ReorderProjectGroupsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired
};

export default connect()(ReorderProjectGroupsModal);