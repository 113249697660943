import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../../components/Loader';
import logoImg from '../../../../assets/images/resillion_logo.png';
import {HEADER_HEIGHT, TOP_HEIGHT} from './Table';

class Menu extends Component {
  render() {
    const {data, activeMenuItem, changeMenuItem, loading} = this.props;

    const categories = (data && data.length ? data.reduce((r, a) => {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, {}) : {});

    const menu = Object.keys(categories).map((key, keyIndex) => {
      const reports = categories[key];
      return (
        <Fragment key={`category-${keyIndex}`}>
          <li className='active'>{key}</li>
          <SubNavStyled className="sub-nav">
            {reports && reports.length ? reports.map((item, index) => {
              const className = activeMenuItem === item.name ? 'active' : '';
              return (
                <li key={index} className={className}>
                  <button className="btn-link" id={item.name} onClick={changeMenuItem}>{item.title}</button>
                </li>
              );
            }) : null}
          </SubNavStyled>
        </Fragment>
      );
    });

    return (
      <MenuStyled>
        {loading ? <Loader/> : null}
        <MainNavStyled className="main-nav">
          {
            menu
          }
        </MainNavStyled>
        <FooterLogoStyled>
          <Link to="/"><img src={logoImg} width="100" alt="logo"/></Link>
        </FooterLogoStyled>
      </MenuStyled>
    );
  }
}

const FOOTER_LOGO_HEIGHT = '70px';
const MARGIN_TOP = '32px';
const MARGIN_BOTTOM = '15px';

const MenuStyled = styled.div`
  padding: 32px 25px;
  @media (min-width: 992px) {
    max-height: calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT} - ${FOOTER_LOGO_HEIGHT} - ${MARGIN_TOP} - ${MARGIN_BOTTOM});
    overflow-y: auto;
    margin-top: ${MARGIN_TOP};
    margin-bottom: ${MARGIN_BOTTOM};
    padding-top: 0;
    padding-bottom: 17px;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent/*rgba(255, 255, 255, 1)*/;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    a {
      display: block;
    }
  }
`;

const MainNavStyled = styled.ul`
  font-size: 13px;
  > li {
    &.active {
      color: #282828;
      font-weight: bold;
    }
  }
`;

const SubNavStyled = styled.ul`
  font-size: 11px;
  margin-bottom: 45px;
  @media (min-width: 992px) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  > li {
    border-bottom: 0.5px solid #d2d2d2;
    &.active {
      border-color: #bababa;
      > .btn-link {
        color: #282828;
        font-weight: bold;
      }
    }

    > .btn-link {
      border: none;
      color: #464646;
      padding: 10px 5px;
      text-align: left;
      width: 100%;
      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }
`;

const FooterLogoStyled = styled.div`
  background: #eaeaea;
  bottom: 0;
  padding: 20px 0;
  position: fixed;
  width: 100%;
`;

Menu.propTypes = {
  data: PropTypes.array.isRequired,
  activeMenuItem: PropTypes.string.isRequired,
  changeMenuItem: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default Menu;
