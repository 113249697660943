import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Clearfix, Alert} from 'react-bootstrap';
import styled from 'styled-components';
import {Checkbox} from 'react-bootstrap';
import RadioButtonsAdvanced from '../../../../components/forms/RadioButtonsAdvanced';
import CheckboxAdvanced from '../../../../components/forms/CheckboxAdvanced';
import {StyledLink} from '../Top';

export const UPDATE_TYPE__MANUAL_SELECT = 9999;

class UpdateWOsConfirmModal extends Component {
  state = {
    requests: null,
    selectedRequests: null,
    needToUpdateWOs: true,
    updateType: null
  };

  handleSubmit = () => {
    const {onConfirm, updateTypes} = this.props;
    const {needToUpdateWOs, updateType, selectedRequests} = this.state;
    onConfirm(updateTypes ? updateType : needToUpdateWOs, selectedRequests);
  };

  handleSelectUpdateType = (_, value) => {
    const {selectedRequestId} = this.props;
    const isManualSelect = value === UPDATE_TYPE__MANUAL_SELECT;
    this.setState({updateType: value, selectedRequests: isManualSelect && this.state.requests ? [selectedRequestId] : null});
    if (isManualSelect && !this.state.requests) {
      this.props.onLoadRequests().then(res => {
        this.setState({requests: res.data, selectedRequests: [selectedRequestId]});
        try {
          setTimeout(() => {
            document.getElementById(`checkbox--request-${selectedRequestId}`).scrollIntoView();
          }, 250);
        } catch {}
      });
    }
  };

  handleChangeNeedToUpdateWOs = (_, value) => {
    this.setState({needToUpdateWOs: value});
  };

  renderRequestsTable() {
    const {updateType, requests, selectedRequests} = this.state;
    return updateType === UPDATE_TYPE__MANUAL_SELECT && !!requests && (
      <StyledRequests>
        {requests.map(r => (
          <Fragment key={r.RequestID}>
            <Checkbox
              id={`checkbox--request-${r.RequestID}`}
              checked={selectedRequests.includes(r.RequestID)}
              onChange={({target: {checked}}) => this.setState({selectedRequests: (checked ? [...selectedRequests, r.RequestID] : selectedRequests.filter(id => id !== r.RequestID))})}
            >
            {`${r.RequestTitleWithVersion}${r.WOMemfis ? ` (${r.WOMemfis})` : ''}`}
            </Checkbox>
            <StyledLink
              title="View Request Details"
              target="_blank"
              to={`/requests/details/${r.RequestID}`}
            >
              <i className="fa-regular fa-arrow-up-right-from-square"/>
            </StyledLink>
          </Fragment>
        ))}
      </StyledRequests>
    );
  }

  render() {
    const {onClose, checkboxLabel, paramName, oldValue, newValue, updateTypes, hideCheckbox, modalTitle, modalText,
      submitButtonLabel, actionLabel, warning, additionalInfo, updateTypesLabel
    } = this.props;
    const {needToUpdateWOs, updateType, selectedRequests} = this.state;
    return (
      <ModalStyled
        className="cs-create-request-modal"
        show={true}
        onHide={onClose}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title style={modalTitle ? {fontWeight: 'normal'} : undefined}>
            {modalTitle || `The ${paramName} has been ${actionLabel || 'changed'}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            {modalText ||
              <OldNewValuesStyled>
                <div>
                  <div><strong>Old value</strong></div>
                  <div className={oldValue ? undefined : 'no-value'}>{oldValue || 'None'}</div>
                </div>
                <div>
                  <div><strong>New value</strong></div>
                  <div className={newValue ? undefined : 'no-value'}>{newValue || 'None'}</div>
                </div>
              </OldNewValuesStyled>
            }
            {!!additionalInfo &&
              <div className="additional-info">
                {additionalInfo}
              </div>
            }
            <Clearfix>
              {
                updateTypes ? <>
                  <RadioButtonsAdvanced
                    className="clearfix"
                    label={updateTypesLabel}
                    labelSeparator=""
                    name="update_type"
                    options={updateTypes}
                    value={updateType}
                    onChange={this.handleSelectUpdateType}
                  />
                  {this.renderRequestsTable()}
                 </> : !hideCheckbox ?
                    <CheckboxAdvanced
                      label={checkboxLabel || 'Should existing work orders be updated?'}
                      name="need-to-update-wo"
                      value={needToUpdateWOs}
                      onChange={this.handleChangeNeedToUpdateWOs}
                    /> : null
              }
            </Clearfix>
            {!!warning &&
              <StyledWarning bsStyle="warning">
                <i className="fa-solid fa-triangle-exclamation"/>
                {warning}
              </StyledWarning>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStyled
            bsStyle="default"
            onClick={onClose}
          >
            Cancel
          </ButtonStyled>
          <ButtonStyled
            bsStyle="primary"
            onClick={this.handleSubmit}
            disabled={!!updateTypes && (!updateType || (updateType === UPDATE_TYPE__MANUAL_SELECT && !(selectedRequests || []).length))}
          >
            {submitButtonLabel || 'Save'}
          </ButtonStyled>
        </Modal.Footer>
      </ModalStyled>
    );
  }
}

const ModalStyled = styled(Modal)`
  .modal-header {
    border-bottom: none;
    h4 {
      padding: 5px 15px 0;
    }
  }
  .modal-footer {
    border-top: none;
  }
  .form-group {
    font-size: 13px;
    &.checkbox {
      input {
        margin: 2px 0 0 !important;
      }
      label {
        font-weight: bold !important;
      }
    }
    &.radio-buttons {
      .field {
        label {
          margin: 5px 0 !important;
          width: 100%;
        }
      }
    }
  }
`;

const OldNewValuesStyled = styled.div`
  margin-bottom: 20px;
  font-size: 13px;
  > div {
    margin-top: 12px;
    + div {
      margin-top: 8px;
    }
    > div {
      &:first-child {
        margin-bottom: 3px;
      }
      &.no-value {
        opacity: 0.65;
      }
    }
  }
  + .additional-info {
    margin-bottom: 20px;
    font-size: 13px;
  }
`;

const ButtonStyled = styled(Button)`
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 100px;
  font-weight: bold;

  & + & {
    margin-left: 20px !important;
  }

  &:not(:disabled):hover {
    background-color: #f1f1f1 !important;
  }

  &.btn-primary {
    color: #5d3996;

    &:disabled {
      opacity: 0.65;
      cursor: default;
    }

    &:not(:disabled):hover {
      color: #401782;
    }
  }

  &.btn-default {
    color: #bbb;

    &:hover {
      color: #969696;
    }
  }
`;

const StyledWarning = styled(Alert)`
  margin-top: 20px;
  display: grid;
  grid-template-columns: fit-content(15px) auto;
  column-gap: 8px;
  color: #fff;
  background-color: #E75592;
  border-color: #E75592;
  font-size: 13px;
  i {
    font-size: 15px;
  }
`;

const StyledRequests = styled.div`
  margin: 0 0 0 3px;
  display: grid;
  grid-template-columns: auto fit-content(15px);
  column-gap: 15px;
  row-gap: 8px;
  min-height: 50px;
  max-height: calc(100vh - 530px);
  overflow-y: auto;
  font-size: 13px;
  @media (max-width: 767px) {
    max-height: 200px;
  }
  i {
    font-size: 15px;
  }
  .checkbox {
    margin: 0;
  }
  input[type="checkbox"] {
    margin-top: 2px;
  }
  a {
    margin-right: 3px;
    display: block;
    width: fit-content;
    height: fit-content;
  }
`;

UpdateWOsConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onLoadRequests: PropTypes.func,
  selectedRequestId: PropTypes.number,
  checkboxLabel: PropTypes.string,
  paramName: PropTypes.string,
  oldValue: PropTypes.any,
  newValue: PropTypes.any,
  updateTypes: PropTypes.array,
  modalTitle: PropTypes.any,
  modalText: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  actionLabel: PropTypes.string,
  warning: PropTypes.string,
};

export default UpdateWOsConfirmModal;
