import React from 'react';
import PropTypes from 'prop-types';

const N_A__VALUE = -1;
const N_A__NAME = 'N/A';

export const prepareHoursSpentValue = (val, isDefaultNA) => val === 0 ? String(val) : (typeof val !== 'number' && isDefaultNA ? N_A__VALUE : val);

export const buildHoursSpentName = (hours, asNumber) => {
  if (typeof hours !== 'number') {
    return asNumber ? N_A__NAME : '';//'None';
  }
  if (hours <= N_A__VALUE) {
    return N_A__NAME;
  }
  if (asNumber) {
    return String(hours);
  }
  const preparedHours = Math.floor(hours);
  const preparedMinutes = Math.floor((hours * 60) - (preparedHours * 60));
  return ((preparedHours === 0 ? '' : preparedHours + ' hour' + (preparedHours === 1 ? '' : 's')) + ' ' + (preparedMinutes === 0 ? '' : preparedMinutes + ' minute' + (preparedMinutes === 1 ? '' : 's'))).trim()
};

export const prepareHoursSpentOptions = (value, maxHours, asNumber) => {
  const options = [];
  options.push({
    value: N_A__VALUE,
    name: buildHoursSpentName(N_A__VALUE)
  });
  const hoursToInc = 0.25;
  if (!maxHours) {
    maxHours = 6;
  }
  for (let i = hoursToInc; i <= maxHours; i += hoursToInc) {
    options.push({
      value: i,
      name: buildHoursSpentName(i, asNumber)
    });
  }
  const isNumberValue = typeof value === 'number';
  if (isNumberValue && !options.find(o => o.value === value)) {
    options.push({
      value: prepareHoursSpentValue(value),
      name: buildHoursSpentName(value, asNumber)
    });
    options.sort((a, b) => +a.value - +b.value);
  }
  return options;
};

export const HoursSpentCell = ({value, className, asNumber, defaultValue}) => {
  if (value === null && defaultValue) {
    return (
      <td className={className}>
        {defaultValue}
      </td>
    );
  }

  return (
    <td
      className={className}
      title={asNumber ? (buildHoursSpentName(value) || '').replace(N_A__NAME, '') : undefined}
    >
      {buildHoursSpentName(value, asNumber)}
    </td>
  );
};

HoursSpentCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  asNumber: PropTypes.bool
};
