import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Cell} from '../../components/table/cells/Cell';

export const Row = ({headers, row, rowKey, onRowClick, getRowClassNames, isSeparatedRows}) => {
  const cells = Object.keys(headers)
  .map(k => {
    const CustomComponent = headers[k].component;
    const customArgs = headers[k].componentArgs || {};
    const className = headers[k].className;
    return CustomComponent ?
      <CustomComponent {...customArgs} value={row[k]} row={row} key={k} rowKey={rowKey} className={className} title={headers[k].title}/> :
      <Cell value={row[k]} key={k} title={headers[k].title} className={className}/>;
  });

  return (
    <Fragment>
      <tr className={getRowClassNames(row)} onClick={onRowClick ? () => onRowClick(row) : undefined}>{cells}</tr>
      {isSeparatedRows ? <tr className="tr-separate"><th colSpan={cells.length}/></tr> : null}
    </Fragment>
  );
};

Row.propTypes = {
  headers: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  rowKey: PropTypes.any,
  onRowClick: PropTypes.func,
  getRowClassNames: PropTypes.func,
  isSeparatedRows: PropTypes.bool
};

Row.defaultProps = {
  getRowClassNames: () => {}
};
