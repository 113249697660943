import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Row, Col, Alert} from 'react-bootstrap';
import {getUploadedFiles} from '../../actions';
import {initAspera} from 'react-aspera-connect';
import {SELECT_FILES_OPTIONS, isNewQuoteProject} from '../../constants';
import AssetsTable from '../table/AssetsTable';
import Loader from '../../../../components/Loader';
import ibmAsperaImg from '../../../../../assets/images/requires-ibm-aspera.svg';
import styled from 'styled-components';
import config from '../../../../../config';
import {getAsperaConnect} from '../../../../utils/general';
import ManualUploads from '../table/ManualUploads';
import _ from 'lodash';

class SelectFiles extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      asperaWeb: getAsperaConnect(config.initAsperaParams),
      loading: false,
      assets: null,
      warningMessages: null,
      isShowTryAgainAlert: false
    };

    this.options = {
      [SELECT_FILES_OPTIONS.IBMAspera]: {
        name: 'IBM Aspera',
        desc: 'The selected upload option requires an Aspera Workgroup connection. Please contact help@us.resillion.com for assistance.'
      },
      [SELECT_FILES_OPTIONS.UploadNow]: {
        name: 'Upload a File Now',
        desc: 'Securely upload a file immediately using the Aspera Connect browser plugin.'
      },
      [SELECT_FILES_OPTIONS.UploadLater]: {
        name: 'Upload Later',
        desc: 'Upload the files needed for the project at a later date.  Please note that the SLA time does not begin until files have been received'
      },
      [SELECT_FILES_OPTIONS.PhysicalMediaDelivery]: {
        name: 'Physical Media Delivery',
        desc: 'The files will be delivered physically - usually via hard drive sent to our facility.  Our CS team will contact you for details.'
      }
    };
  }

  isUnavailableAspera = () => !this.state.asperaWeb;

  handleChangeType = value => {
    const {assets} = this.state;
    const {onChange} = this.props;
    onChange('select_files_type_id', value);
    onChange('selectedFiles', value === SELECT_FILES_OPTIONS.IBMAspera ? [] : null);
    onChange('uploadedFiles', undefined);
    if (value === SELECT_FILES_OPTIONS.IBMAspera && !assets) {
      this.handleLoadAssets();
    }
    if (value !== SELECT_FILES_OPTIONS.IBMAspera) {
      this.setState({assets: null});
    }
  };

  componentDidMount() {
    const {assets} = this.state;
    const {data, onChange, notSetDefaultUploadOption, allowFileUpload} = this.props;
    const {select_files_type_id} = data;
    if ((!allowFileUpload && [SELECT_FILES_OPTIONS.UploadNow, SELECT_FILES_OPTIONS.IBMAspera].includes(select_files_type_id)) ||
        (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && this.isUnavailableAspera())) {
      this.handleChangeType(SELECT_FILES_OPTIONS.UploadLater);
    } else if (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && !assets) {
      this.handleLoadAssets();
    } else if (!select_files_type_id && !notSetDefaultUploadOption) {
      onChange('select_files_type_id', SELECT_FILES_OPTIONS.UploadLater);
    }
  }

  getUploadParams = () => {
    const {data} = this.props;
    const params = {};
    ['studio_id', 'request_id'].forEach(key => {
      if (typeof data[key] === 'number') {
        params[key] = data[key];
      }
    });
    if (!params.studio_id && data.origin_request) {
      params.studio_id = data.origin_request.StudioID;
    }
    if (data.cs_studio_id) {
      params.cs_studio_id = data.cs_studio_id;
    }
    if (data.cs_production_id) {
      params.cs_production_id = data.cs_production_id;
    }
    if (data.cs_finishing_house_id) {
      params.cs_finishing_house_id = data.cs_finishing_house_id;
    }
    return params;
  };

  handleLoadAssets = () => {
    if (this.state.assets) {
      return;
    }
    this.setState({loading: true});
    getUploadedFiles(this.getUploadParams())
      .then(
        res => {
          const assets = res.uploads || [];
          const {data, onChange} = this.props;
          const {select_files_type_id, selectedFiles} = data;
          if (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera) {
            const files = [].concat(selectedFiles || []);
            if (selectedFiles) {
              const missedFiles = selectedFiles.filter(f => !assets.find(a => a.id === f.id));
              missedFiles.forEach(f => {
                const index = files.findIndex(a => a.id === f.id);
                if (index >= 0) {
                  files.splice(index, 1);
                }
              });
              if (missedFiles.length) {
                onChange('selectedFiles', files);
              }
            }
          }
          this.setState({loading: false, assets});
        },
        error => {
          console.log(error);
          this.setState({loading: false});
        }
      );
  };

  handleAsperaSelectFiles = dataTransferObj => {
    if (!dataTransferObj.dataTransfer || !dataTransferObj.dataTransfer.files) {
      console.log(dataTransferObj);
      this.setState({isShowTryAgainAlert: true});
      return;
    }

    const {files} = dataTransferObj.dataTransfer;
    if (files.length === 0) {
      return;
    }

    const warningMessages = [];
    const {data, assignedFiles, onChange} = this.props;
    const uploadedFiles = data.uploadedFiles || [];
    const alreadyUploaded = _.remove(files, file => uploadedFiles.find(item => item.asset_name.toLowerCase() === file.name.toLowerCase()));
    if (!_.isEmpty(alreadyUploaded)) {
      warningMessages.push(`You already have chosen a following file(s) or folder(s): ${alreadyUploaded.map(f => f.name).join(', ')}`);
    }

    const usedFiles = [...uploadedFiles, ...assignedFiles || []];
    const usedFileNames = usedFiles.map(file => file.asset_name.split('\\').pop().split('/').pop().toLowerCase());

    const nonUniques = _.remove(files, file => usedFileNames.includes(file.name.split('\\').pop().split('/').pop().toLowerCase()));
    if (!_.isEmpty(nonUniques)) {
       const names = nonUniques.map(f => f.name.split('\\').pop().split('/').pop()).join(', ');
       warningMessages.push(`You already have chosen a file(s) or folder(s) named '${names}' - each file or folder chosen must have a unique name.`);
    }

    if (!_.isEmpty(warningMessages)) {
      this.setState({warningMessages});
      setTimeout(() => {
        this.setState({warningMessages: null});
      }, 10000);
    }

    if (files.length === 0) {
      return;
    }

    const isDirectory = files[0].type === 'inode/directory';
    onChange('uploadedFiles', [...uploadedFiles, ...files.map(file => ({asset_name: file.name, isDirectory}))]);
  };

  handleAsperaShowSelectFileDialog = () => {
    this.setState({warningMessages: null, isShowTryAgainAlert: false});
    const {asperaWeb} = this.state;
    asperaWeb.initSession();
    asperaWeb.showSelectFileDialog(
      {success: this.handleAsperaSelectFiles},
      {allowMultipleSelection: true, title: 'Transfers - Aspera Connect: Select a File'}
    );
    if (asperaWeb.getStatus() !== 'RUNNING') {
      initAspera(config.initAsperaParams);
    }
  };

  handleAsperaShowSelectFolderDialog = () => {
    this.setState({warningMessages: null, isShowTryAgainAlert: false});
    const {asperaWeb} = this.state;
    asperaWeb.initSession();
    asperaWeb.showSelectFolderDialog(
      {success: this.handleAsperaSelectFiles},
      {allowMultipleSelection: true, title: 'Transfers - Aspera Connect: Select a Folder'}
    );
    if (asperaWeb.getStatus() !== 'RUNNING') {
      initAspera(config.initAsperaParams);
    }
  };

  renderRequiresIbmAspera(isSelected) {
    return (
      <Col md={2} xs={4} className={`requires-ibm-aspera${isSelected ? ' is-selected' : ''}`}>
        <img src={ibmAsperaImg} alt=""/>
      </Col>
    );
  }

  renderSelectOption(optionId) {
    const {select_files_type_id} = this.props.data;
    return (
      <Col md={5} xs={8} className="select-files-option-data">
        <div
          className={`qc-types-item${select_files_type_id === optionId ? ' qc-type-selected' : ''}`}
          onClick={() => this.handleChangeType(optionId)}
          >
          <span className="icon-circle-check"/><span className="qc-type-name">{this.options[optionId].name}</span>
          <div className="qc-type-note">
            {this.options[optionId].desc}
          </div>
        </div>
      </Col>
    );
  }

  renderOptionLine() {
    return (
      <Row>
        <Col md={12} xs={12}>
          <div className="qc-type-line"/>
        </Col>
      </Row>
    );
  }

  renderUploadNowOption() {
    const {isShowTryAgainAlert} = this.state;
    const {data, onChange} = this.props;
    const {select_files_type_id, uploadedFiles} = data;
    const isDisabled = this.isUnavailableAspera();
    const isSelected = select_files_type_id === SELECT_FILES_OPTIONS.UploadNow;
    return (
      <div
        className={`select-file-option option-upload-now${isDisabled ? ' disabled' : ''}`}
        title={isDisabled ? 'The Aspera Connect is currently unavailable.  Please contact qodsupport@us.resillion.com for assistance.' : undefined}
      >
        <Row>
          {this.renderSelectOption(SELECT_FILES_OPTIONS.UploadNow)}
          {this.renderRequiresIbmAspera(isSelected)}
          {
            (isDisabled || !isSelected) ? null : (
              <Col md={5} xs={12} className="show-dialog-btn-toolbar">
                {isShowTryAgainAlert ?
                  <Alert bsStyle="danger" onDismiss={() => this.setState({isShowTryAgainAlert: false})}>
                    Close a file browser dialog and try again please
                  </Alert> : null
                }
                <Button bsStyle="primary" onClick={this.handleAsperaShowSelectFileDialog}>
                  Choose file/s to upload now
                </Button>
                <Button bsStyle="primary" onClick={this.handleAsperaShowSelectFolderDialog}>
                  Choose folder/s to upload now
                </Button>
              </Col>
            )
          }
        </Row>
        {
          !isDisabled && isSelected && uploadedFiles && uploadedFiles.length ?
            <ManualUploads
              data={uploadedFiles}
              onRemove={index => {
                uploadedFiles.splice(index, 1);
                onChange('uploadedFiles', uploadedFiles);
              }}
              onRemoveAll={() => onChange('uploadedFiles', [])}
              /> : null
        }
        {this.renderOptionLine()}
      </div>
    );
  }

  render() {
    const {assets, loading, warningMessages} = this.state;
    const {data, assignedFiles, usedPackages, onChange} = this.props;
    const {select_files_type_id, selectedFiles} = data;
    const isNewQuote = isNewQuoteProject(data);
    const allowFileUpload = this.props.allowFileUpload && !isNewQuote;
    return (
      <SelectFilesContainer className="step-body-wrapper select-files">
        {loading ? <Loader/> : null}
        {warningMessages ?
          <Alert bsStyle="warning" onDismiss={() => this.setState({warningMessages: null})}>
            {warningMessages.map((m, i) => (
              <div key={`m-${i}`}>
                {m}
              </div>
            ))}
          </Alert> : null
        }
        <div className="select-files-header">
          {isNewQuote ? 'Delivery requirement' : 'Select File(s)'}
        </div>
        <div className="select-file-options lg">
          {allowFileUpload && <div className="select-file-option option-ibm-aspera">
            <Row>
              {this.renderSelectOption(SELECT_FILES_OPTIONS.IBMAspera)}
              {select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera ? this.renderRequiresIbmAspera(true) : null}
            </Row>
            {
              select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && assets ?
                <AssetsTable
                  data={[].concat(assets).concat(assignedFiles || []).filter(a => !(usedPackages || []).find(p => p.id === a.id))}
                  selected={selectedFiles}
                  onSelect={(value, checked) => {
                    if (!Array.isArray(value)) {
                      if (checked) {
                        selectedFiles.push(value);
                      } else {
                        selectedFiles.splice(selectedFiles.findIndex(a => a.id === value.id), 1);
                      }
                      onChange('selectedFiles', selectedFiles);
                    } else {
                      onChange('selectedFiles', value);
                    }
                  }}
                  /> : null
            }
            {this.renderOptionLine()}
          </div>}
          {allowFileUpload && this.renderUploadNowOption()}
          <div className="select-file-option option-upload-later">
            <Row>
              {this.renderSelectOption(SELECT_FILES_OPTIONS.UploadLater)}
            </Row>
            {this.renderOptionLine()}
          </div>
          <div className="select-file-option option-physical-media-delivery">
            <Row>
              {this.renderSelectOption(SELECT_FILES_OPTIONS.PhysicalMediaDelivery)}
            </Row>
            {this.renderOptionLine()}
          </div>
        </div>
      </SelectFilesContainer>
    );
  }
}

const SelectFilesContainer = styled.div`
  position: relative;

  .loader-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    .react-spinner {
      top: 49% !important;
    }
  }

  .alert {
    &.alert-warning {
      position: fixed;
      top: 110px;
      left: 25px;
      width: calc(100% - 50px);
      margin: 0;
      z-index: 10000;
    }
  }

  .select-files-header {
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
  }

  .requires-ibm-aspera {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    opacity: 0.4;

    &.is-selected {
      opacity: 1;
    }

    img {
      margin-top: 2px;
      margin-bottom: 25px;
      max-width: 131px;
    }
  }

  .select-file-options {
    padding: 32px 0 0;

    .select-file-option {

        &.disabled {
          position: relative;
          :after {
            content: attr(title);
            position: absolute;
            top: -4px;
            left: 0;
            padding-left: 33px;
            width: 100%;
            height: 100%;
            color: #a94442;
            font-size: 17px;
            font-weight: 500;
            opacity: 0;
            transition: opacity 0.2s ease 0s;
          }
          > * {
            pointer-events: none;
            &.row:first-child {
              opacity: 0.5;
            }
          }
          :hover {
            :after {
              opacity: 1;
            }
            > .row:first-child {
              opacity: 0.3;
            }
          }
        }

        &.option-upload-now {
          .btn {
            margin-left: 33px;
            @media (min-width: 992px) {
              margin-left: 0;
            }
            & + .btn {
              margin-top: 15px;
            }
          }
          .show-dialog-btn-toolbar {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media (min-width: 992px) {
              margin-top: -27px;
            }
            @media (max-width: 991px) {
              margin-bottom: 15px;
            }
            .alert {
              margin: 0;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              z-index: 1000;
              @media (max-width: 991px) {
                left: 48px;
              }
            }
          }
        }

        .qc-type-line {
          margin: 0 0 0 33px;
          height: 4px;
          background: #C4C4C4;
          opacity: 0.2;
        }

        .qc-types-item {
          cursor: pointer;
          position: relative;
          padding: 0 0 0 33px;
          margin-right: 40px;

          .qc-type-note {
            font-weight: 300;
            font-size: 13px;
            line-height: 17px;
            opacity: 0.4;
            margin-bottom: 17px;
          }

          .qc-type-name {
            font-weight: 700;
            font-size: 15px;
            line-height: 16px;
            opacity: 0.5;
          }

          .icon-circle-check {
            color: #F3F3F3;
            font-size: 21.6px;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:hover {
            .qc-type-note {
              opacity: 0.7;
            }
            .qc-type-name {
              opacity: 1;
            }
          }

          &.qc-type-selected {
            cursor: default;

            .icon-circle-check {
              color: #E75592;
            }

            .qc-type-note {
              opacity: 0.7;
            }

            .qc-type-name {
              opacity: 1;
            }
          }
        }

        &.select-file-option:not(:first-child)>.row:first-child {
          margin-top: 34px;
        }
    }

    .file-upload-status {
      margin-top: -34px;
    }
  }

  @media (min-width: 992px) {
    .select-file-options:not(.lg) {
      padding-right: 161px;

      .select-file-option {
        margin-right: 40px;
      }
    }
  }
`;

SelectFiles.propTypes = {
  showAsperaStatus: PropTypes.bool,
  assignedFiles: PropTypes.array,
  usedPackages: PropTypes.array,
  allowFileUpload: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

SelectFiles.defaultProps = {
  showAsperaStatus: true,
  data: {}
};

export default SelectFiles;
