/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import {StyledTableWrap, StyledButton, StyledButtonToolbar} from './styledTable';
import HeaderRow from './HeaderRow';
import Row from './Row';
import {buildNewStep, calcOrderNumForNewLastStep, checkDiscountPercentStep, CommonStepType} from './utils';
import {showError, showInfo} from '../../../../layouts/actions';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import AddPatchStepsOptionsModal from "./AddPatchStepsOptionsModal";

const SortableItem = SortableElement(Row);

const SortableTable = SortableContainer(({children, tableRef}) => {
  return <table className="table" ref={tableRef}>{children}</table>
});

const EditWorkOrderStepsTable = props => {
  const {
    dispatch, loading, filters, storedSteps, steps, onChangeSteps, isCreatedWO, onLoadSpecSteps, isNetflixWO,
    isBilledRequest, onlyAddingPatchSteps, loadingFilters
  } = props;
  const allowToAddSpecSteps = !!onLoadSpecSteps;
  const stepAssignees = filters.step_assignees || [];
  const stepCategories = filters.step_categories || [];
  const stepStatuses = filters.step_statuses || [];
  const allProductCodes = filters.product_codes || [];
  const productCodes = filters.product_codes_for_profile || [];
  const labelSuggestions = filters.step_labels;
  const descriptionSuggestions = filters.step_descriptions;
  const noSteps = !steps.length;
  const isExistsDiscountPercentStep = steps.some(s => checkDiscountPercentStep(s, isCreatedWO));

  const tableRef = useRef(null);
  const [showPatchStepsOptions, setShowPatchStepsOptions] = useState(false);

  const handleFocusCell = () => {
    tableRef.current.parentElement.classList.add('edit-mode');
    //tableRef.current.parentElement.scrollTo(0, 0);
  };

  const handleBlurCell = () => {
    tableRef.current.parentElement.classList.remove('edit-mode');
  };

  const findOriginalStep = (step) => {
    const {WOStepSubtypeID, RequestStepID} = step;
    if (WOStepSubtypeID) {
      return storedSteps.find(s => !s.isNew && s.WOStepSubtypeID === WOStepSubtypeID);
    }
    return storedSteps.find(s => !s.isNew && s.RequestStepID === RequestStepID);
  };

  const handleSelect = (index, name, option) => {
    if (!option) {
      if (name === 'AssigneeId') {
        option = {value: null};
      } else {
        return;
      }
    }

    if (name === 'StepStatusID' && option.time_spent_is_required && typeof steps[index].HoursSpent !== 'number') {
      dispatch(showError('Please fill in Time Spent before marking the step complete.'));
      return;
    }

    const preValue = steps[index][name];
    const value = name === 'Quantity' ? (option.value || null) : option.value;
    if (value === preValue) {
      return;
    }

    const updSteps = [...steps];
    updSteps[index][name] = value;
    const nameField = name === 'AssigneeId' ? 'Assignee' : name === 'StepStatusID' ? 'Status' : null;
    if (nameField) {
      updSteps[index][nameField] = option.name;
    }

    const names = [name];
    if (name === 'ProductCode') {
      if (updSteps[index].OverrideRate !== option.Rate && !checkDiscountPercentStep(updSteps[index], isCreatedWO) && (!updSteps[index].OverrideRate || updSteps[index].OverrideRate > 0)) {
        updSteps[index].OverrideRate = option.Rate;
        names.push('OverrideRate');
      }
      const newQuantity = (option.Unit === 'Per Min' || option.Unit === 'Per Hr' ? null : 1);
      if (updSteps[index].Unit !== option.Unit && updSteps[index].Quantity !== newQuantity) {
        updSteps[index].Quantity = newQuantity;
        names.push('Quantity');
      }
      updSteps[index].Unit = option.Unit;
    } else if (name === 'OverrideRate') {
      if (checkDiscountPercentStep(updSteps[index], isCreatedWO)) {
        const newDiscountDetails = typeof value === 'number' ? `${value}%` : '%';
        if (!(updSteps[index].Details || '').trim()) {
          updSteps[index].Details = newDiscountDetails + ' Discount';
          names.push('Details');
        } else {
          const temp = updSteps[index].Details.match(/%(\s*discou.*)/i) || updSteps[index].Details.match(/[$]\d+[.]\d+(\s*discou.*)/i);
          if (temp && temp.length > 1) {
            updSteps[index].Details = newDiscountDetails + ' ' + temp[1].trim();
            names.push('Details');
          }
        }
      } else if (isCreatedWO && value < 0 && updSteps[index].Details) {
        const temp = updSteps[index].Details.match(/%(\s*discou.*)/i) || updSteps[index].Details.match(/[$]\d+[.]\d+(\s*discou.*)/i);
        if (temp && temp.length > 1) {
          updSteps[index].Details = temp[1].trim();
          names.push('Details');
        }
      }
    }

    if (!updSteps[index].isNew) {
      let changedFields = [...updSteps[index].changedFields || []];
      const originalStep = findOriginalStep(updSteps[index]);
      const returnedOriginalValue = originalStep && originalStep[name] === value;
      if (returnedOriginalValue) {
        changedFields = changedFields.filter(k => k !== name && k !== nameField);
        if (name === 'ProductCode') {
          if (names.includes('OverrideRate') && originalStep.OverrideRate === updSteps[index].OverrideRate) {
            changedFields = changedFields.filter(k => k !== 'OverrideRate');
          }
          if (names.includes('Quantity') && originalStep.Quantity === updSteps[index].Quantity) {
            changedFields = changedFields.filter(k => k !== 'Quantity');
          }
        } else if (names[1] === 'Details' && originalStep.Details === updSteps[index].Details) {
          changedFields = changedFields.filter(k => k !== 'Details');
        }
      } else if (!changedFields.includes(name)) {
        changedFields = [...changedFields, ...names, ...(nameField ? [nameField] : [])];
      }
      updSteps[index].changedFields = changedFields;
    }

    onChangeSteps(updSteps);
  };

  const updateStepOrders = (updatedSteps) => {
    let orderNum = 0;
    for (let i = 0; i < updatedSteps.length; i++) {
      if (updatedSteps[i].isDeleted) {
        continue;
      }

      updatedSteps[i].StepOrder = ++orderNum;

      if (!updatedSteps[i].isNew) {
        let changedFields = [...updatedSteps[i].changedFields || []];
        const originalStep = findOriginalStep(updatedSteps[i]);
        if (originalStep && originalStep.StepOrder === updatedSteps[i].StepOrder) {
          changedFields = changedFields.filter(k => k !== 'StepOrder');
        } else if (!changedFields.includes('StepOrder')) {
          changedFields = [...changedFields, 'StepOrder'];
        }
        updatedSteps[i].changedFields = changedFields;
      }
    }

    onChangeSteps(updatedSteps);
  };

  const onClickDeleteRow = (index) => {
    const updSteps = [...steps];
    if (updSteps[index].isNew) {
      updSteps.splice(index, 1);
    } else {
      updSteps[index].isDeleted = true;
    }
    updateStepOrders(updSteps);
  };

  const onClickUnDeleteRow = (index) => {
    const updSteps = [...steps];
    delete updSteps[index].isDeleted;
    updateStepOrders(updSteps);
  };

  const onClickDuplicateRow = (index) => {
    let updatedSteps = [...steps];
    const newStepIndex = index + 1;
    const start = updatedSteps.slice(0, newStepIndex);
    const end = updatedSteps.slice(newStepIndex);
    const newStep = {
      ...updatedSteps[index],
      ...buildNewStep(stepStatuses),
    };
    delete newStep.RequestStepID;
    delete newStep.WOStepSubtypeID;
    delete newStep.isDeleted;
    updatedSteps = [...start, newStep, ...end];
    updateStepOrders(updatedSteps);
  };

  const onClickInsertRow = (index) => {
    let updatedSteps = [...steps];
    const newStepIndex = index + 1;
    const start = updatedSteps.slice(0, newStepIndex);
    const end = updatedSteps.slice(newStepIndex);
    updatedSteps = [...start, buildNewStep(stepStatuses), ...end];
    updateStepOrders(updatedSteps);
  };

  const onClickAddRow = () => {
    const orderNum = calcOrderNumForNewLastStep(steps);
    const newStepData = buildNewStep(stepStatuses, orderNum);
    const updatedSteps = [...steps, newStepData];
    onChangeSteps(updatedSteps);
  };

  const onClickAddCommonSteps = (commonStepType) => { // discount or harding test
    onLoadSpecSteps(commonStepType).then(
      commonSteps => {
        const orderNum = calcOrderNumForNewLastStep(steps);
        const updatedSteps = [...steps];
        commonSteps.forEach((commonStep, i) => {
          updatedSteps.push({...commonStep, ...buildNewStep(stepStatuses, orderNum + i), isCommonStep: true});
        });
        onChangeSteps(updatedSteps);
      }
    );
  };

  const onClickAddPatchSteps = () => {
    setShowPatchStepsOptions(true);
  }

  const onSubmitPatchStepsOptions = (overageCompany) => {
    setShowPatchStepsOptions(false);
    onLoadSpecSteps('patch').then(
      patchSteps => {
        if (!patchSteps || !patchSteps.length) {
          dispatch(showInfo('Patch steps not found' + (isNetflixWO ? '' : ' for QC profile')));
        } else {
          const orderNum = calcOrderNumForNewLastStep(steps);
          const updatedSteps = [...steps];
          patchSteps.forEach((patchStep, i) => {
            if ('STUDIO' === overageCompany) {
              // add the "Bill To Studio" and "Overage" flags
              patchStep.StepFlags = patchStep.StepFlags | (262144 + 2097152);
            } else if ('PRODUCTION' === overageCompany) {
              // add the "Overage" flag
              patchStep.StepFlags = patchStep.StepFlags | 2097152;
            }
            updatedSteps.push({...patchStep, ...buildNewStep(stepStatuses, orderNum + i), IsPatchStep: 1});
          });
          onChangeSteps(updatedSteps);
          try {
            const newRowElm = tableRef.current.getElementsByClassName('is-new-step-row')[0];
            if (newRowElm.scrollIntoViewIfNeeded) {
              newRowElm.scrollIntoViewIfNeeded();
            } else {
              newRowElm.scrollIntoView();
            }
          } catch {}
        }
      }
    );
  };

  const onClickAddTransferSteps = () => {
    onLoadSpecSteps('transfer').then(
        transferSteps => {
          if (!transferSteps || !transferSteps.length) {
            dispatch(showInfo('Transfer steps not found' + (isNetflixWO ? '' : ' for QC profile')));
          } else {
            const orderNum = calcOrderNumForNewLastStep(steps);
            const updatedSteps = [...steps];
            transferSteps.forEach((transferStep, i) => {
              updatedSteps.push({...transferStep, ...buildNewStep(stepStatuses, orderNum + i)});
            });
            onChangeSteps(updatedSteps);
            try {
              const newRowElm = tableRef.current.getElementsByClassName('is-new-step-row')[0];
              if (newRowElm.scrollIntoViewIfNeeded) {
                newRowElm.scrollIntoViewIfNeeded();
              } else {
                newRowElm.scrollIntoView();
              }
            } catch {}
          }
        }
    );
  }

  const onClickAddEdgePassSteps = () => {
    onLoadSpecSteps('edge_pass').then(
        edgePassSteps => {
          if (!edgePassSteps || !edgePassSteps.length) {
            dispatch(showInfo('Edge Pass steps not found' + (isNetflixWO ? '' : ' for QC profile')));
          } else {
            const orderNum = calcOrderNumForNewLastStep(steps);
            const updatedSteps = [...steps];
            edgePassSteps.forEach((edgePassStep, i) => {
              updatedSteps.push({...edgePassStep, ...buildNewStep(stepStatuses, orderNum + i)});
            });
            onChangeSteps(updatedSteps);
            try {
              const newRowElm = tableRef.current.getElementsByClassName('is-new-step-row')[0];
              if (newRowElm.scrollIntoViewIfNeeded) {
                newRowElm.scrollIntoViewIfNeeded();
              } else {
                newRowElm.scrollIntoView();
              }
            } catch {}
          }
        }
    );
  }

  const onClickAddDeliverySteps = () => {
    onLoadSpecSteps('delivery').then(
      deliverySteps => {
        if (!deliverySteps || !deliverySteps.length) {
          dispatch(showInfo('Delivery steps not found' + (isNetflixWO ? '' : ' for QC profile')));
        } else {
          const orderNum = calcOrderNumForNewLastStep(steps);
          const updatedSteps = [...steps];
          deliverySteps.forEach((deliveryStep, i) => {
            updatedSteps.push({...deliveryStep, ...buildNewStep(stepStatuses, orderNum + i)});
          });
          onChangeSteps(updatedSteps);
          try {
            const newRowElm = tableRef.current.getElementsByClassName('is-new-step-row')[0];
            if (newRowElm.scrollIntoViewIfNeeded) {
              newRowElm.scrollIntoViewIfNeeded();
            } else {
              newRowElm.scrollIntoView();
            }
          } catch {}
        }
      }
    );
  }

  useEffect(() => {
    if (isBilledRequest && !loadingFilters) {
      onClickAddPatchSteps();
    }
  }, [isBilledRequest, loadingFilters]);

  const onSortEnd = ({oldIndex, newIndex, collection}) => {
    if (oldIndex === newIndex) {
      return;
    }

    const updatedSteps = arrayMove(
      steps,
      oldIndex,
      newIndex,
    );
    updateStepOrders(updatedSteps);
  };

  const onClosePatchStepsOptions = () => {
    setShowPatchStepsOptions(false);
  }

  return (
    <StyledContainer>
      {showPatchStepsOptions && <AddPatchStepsOptionsModal onClose={onClosePatchStepsOptions} onSubmit={onSubmitPatchStepsOptions}/>}
      <StyledTableWrap
        isCreatedWO={isCreatedWO}
        withSuggestions={Boolean((labelSuggestions || []).length || (descriptionSuggestions || []).length)}
      >
        <SortableTable
          tableRef={tableRef}
          helperClass="sortable-table-row-helper"
          useDragHandle
          onSortEnd={onSortEnd}
        >
          <thead>
            <HeaderRow/>
          </thead>
          <tbody>
            {noSteps ?
              <tr>
                <td
                  className="data-table-empty"
                  colSpan="1000"
                >
                  {loading ? 'Loading...' : 'We could not find the steps for this request'}
                </td>
              </tr> : steps.map((item, i) => (
                <SortableItem
                  key={`step-${i}`}
                  index={i}
                  value={item}
                  onSelect={(n, o) => handleSelect(i, n, o)}
                  onInput={(n, v) => handleSelect(i, n, {value: v})}
                  productCodes={productCodes}
                  allProductCodes={allProductCodes}
                  assignees={stepAssignees}
                  categories={stepCategories}
                  statuses={stepStatuses}
                  labels={labelSuggestions}
                  descriptions={descriptionSuggestions}
                  onFocus={handleFocusCell}
                  onBlur={handleBlurCell}
                  onDelete={() => onClickDeleteRow(i)}
                  onUndelete={() => onClickUnDeleteRow(i)}
                  onInsert={() => onClickInsertRow(i)}
                  onDuplicate={() => onClickDuplicateRow(i)}
                  isCreatedWO={isCreatedWO}
                  onlyAddingPatchSteps={onlyAddingPatchSteps}
                />
              ))
            }
          </tbody>
        </SortableTable>
      </StyledTableWrap>
      <StyledButtonToolbar className="clearfix">
        {!onlyAddingPatchSteps &&
          <StyledButton
            onClick={onClickAddRow}
            title="Add a new step"
            className="btn-in-modal"
          >
            <i className="fa fa-plus"/>
          </StyledButton>
        }
        {allowToAddSpecSteps &&
          <>
            {!onlyAddingPatchSteps &&
              <>
                <StyledButton
                  onClick={() => onClickAddCommonSteps(CommonStepType.HARDING_TEST)}
                  className="btn-in-modal"
                >
                  Add Harding Test Step
                </StyledButton>
                <StyledButton
                  onClick={() => onClickAddCommonSteps(CommonStepType.DISCOUNT_PERCENT)}
                  className="btn-in-modal"
                  disabled={isExistsDiscountPercentStep}
                  title={isExistsDiscountPercentStep ? 'A discount percent step already exists' : undefined}
                >
                  Add Discount Step
                </StyledButton>
              </>
            }
            <StyledButton
              onClick={onClickAddPatchSteps}
              className="btn-in-modal"
            >
              Add Patch Steps
            </StyledButton>
            <StyledButton
                onClick={onClickAddTransferSteps}
                className="btn-in-modal"
            >
              Add Transfer Steps
            </StyledButton>
            <StyledButton
                onClick={onClickAddEdgePassSteps}
                className="btn-in-modal"
            >
              Add Edge Pass Steps
            </StyledButton>
            <StyledButton
              onClick={onClickAddDeliverySteps}
              className="btn-in-modal"
            >
              Add Delivery Steps
            </StyledButton>
          </>
        }
      </StyledButtonToolbar>
      <GlobalStyled/>
    </StyledContainer>
  );
};

const GlobalStyled = createGlobalStyle`
  tr.sortable-table-row-helper {
    z-index: 1070;

    td {
      border-top: 0;
      border-bottom: 0;
      padding: 7px 15px 7px 0;
      background: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: left;
      vertical-align: middle;
      color: #282828;

      &:first-child {
        padding-left: 10px;
      }

      &.step-label {
        > div {
          width: 170px;
        }
      }

      &.step-description {
        > div {
          width: 200px;
        }
      }

      &.product-code {
        > div {
          width: 150px;
        }
      }

      &.assignee {
        > div {
          width: 135px;
        }
      }

      &.category-flags {
        > div {
          width: 100px;
        }
      }

      &.actions {
        display: none;
      }

      &.step-order {
        white-space: nowrap;
        sup {
          left: 0.5em;
          text-transform: uppercase;
        }
      }
    }

    &.is-new-step-row {
      td {
        &.sort,
        &.step-order {
          color: #0287e5;
        }
      }
    }
  }
`;

const StyledContainer = styled.div`
`;

export default connect()(EditWorkOrderStepsTable);