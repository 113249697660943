import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import {Row, Col, FormGroup, ControlLabel, FormControl, Checkbox, Clearfix} from 'react-bootstrap';
import SelectAdvanced from "../../../../components/forms/SelectAdvanced";
import RadioButtonsAdvanced from "../../../../components/forms/RadioButtonsAdvanced";
import {OfficeFlagOptions, isSelectedOffice} from '../../../../utils/general';
import {getProjectDataForWizard} from '../../actions';
import Loader from '../../../../components/Loader';
import styled from 'styled-components';
import MultiSelect from '../../../RequestDetails/components/forms/MultiSelect';
import {isNewQuoteProject} from '../../constants';
import {CompanyFlags} from '../../../Admin/constants';

const DEFAULT_OFFICE_FLAG = -1;

function Screen(props) {
  const {data, filters, onChange} = props;
  const {memfis_clients, post_executive_studio_users} = filters;
  const [isLoading, setLoading] = useState(false);
  const [isRequiredOfficeFlag, setIsRequiredOfficeFlag] = useState(false);
  const [storedProjectData, setStoredProjectData] = useState({});
  const [optionsToUpdateSetting, setOptionsToUpdateSetting] = useState([]);

  const studioCompanyId = (data.cs_studio_id || data.studio_id);
  const studioCompany = (filters.studios || []).find(c => c.value === studioCompanyId);
  const isCreateClientProxies = data.is_create_client_proxies;

  useEffect(() => {
    if (typeof isCreateClientProxies !== 'boolean' && studioCompany) {
      onChange('is_create_client_proxies', ((studioCompany.flags || 0) & CompanyFlags.CREATE_CLIENT_PROXIES) > 0);
    }
  }, [isCreateClientProxies, studioCompany, onChange]);

  useEffect(() => {
    if (isNewQuoteProject(data)) {
      setIsRequiredOfficeFlag(true);
      const notSelectedRequiredOfficeFlag = !isSelectedOffice(data.office_flag);
      if (notSelectedRequiredOfficeFlag) {
        onChange('office_flag', DEFAULT_OFFICE_FLAG);
      }
      return;
    }

    setLoading(true);
    getProjectDataForWizard(data).then(
      res => {
        setLoading(false);

        const {is_required_office_flag} = res;
        setIsRequiredOfficeFlag(is_required_office_flag);
        const notSelectedRequiredOfficeFlag = is_required_office_flag && !isSelectedOffice(data.office_flag);
        if (notSelectedRequiredOfficeFlag) {
          onChange('office_flag', isSelectedOffice(res.OfficeFlag) ? res.OfficeFlag : DEFAULT_OFFICE_FLAG);
        }

        const isExistProject = res.QcProjectID > 0;
        setStoredProjectData(isExistProject ? {...res} : {QcProjectID: 0});

        const optionsToUpdate = res.UpdateOptions || [];
        setOptionsToUpdateSetting(optionsToUpdate);

        [
          'DistributionList', 'ClientContactID', 'MemfisCompanyID', 'PostExecutiveUserID', 'OfficeFlag'
        ].forEach(fieldName => {
          const fieldNameForRequest = fieldName.replace(/([A-Z]+)/g, '_$1').replace(/^_/, '').toLowerCase();
          const fieldNameForUpdType = fieldNameForRequest.replace(/_id$/, '') + '_update_type';
          let fieldUpdateType = data[fieldNameForUpdType];
          if (!optionsToUpdate.length || !optionsToUpdate.find(o => o.value === fieldUpdateType)) {
            fieldUpdateType = 0;
            if (fieldUpdateType !== data[fieldNameForUpdType]) {
              onChange(fieldNameForUpdType, fieldUpdateType);
            }
          }
          if (isExistProject && !fieldUpdateType && (fieldName !== 'OfficeFlag' || !notSelectedRequiredOfficeFlag)) {
            onChange(fieldNameForRequest, res[fieldName]);
          }
        });

        if (!isExistProject) {
          onChange('distribution_list', res.defaultDistributionList);
        }

        if (res.cs_employee_account_no) {
          onChange('cs_user_id', res.cs_employee_account_no);
        }

        if (!res.PendingApprovalStudioPartnerTypeIDs) {
          onChange('pending_approval_studio_partner_type_ids', null);
        } else if (!data.pending_approval_studio_partner_type_ids) {
          onChange('pending_approval_studio_partner_type_ids', res.PendingApprovalStudioPartnerTypeIDs);
        }

        onChange('project_billing_notes', res.BillingNotes);
        onChange('project_qc_notes', res.QcNotes);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [data, onChange]);

  useEffect(() => {
    if (isNewQuoteProject(data)) {
      return;
    }

    if (data.memfis_company_id && !(memfis_clients || []).find(i => i.value === data.memfis_company_id)) {
      onChange('memfis_company_id', null);
      onChange('memfis_company_update_type', optionsToUpdateSetting.length ? optionsToUpdateSetting[0].value : 0);
    }
    if (data.post_executive_user_id && !(post_executive_studio_users || []).find(i => i.value === data.post_executive_user_id)) {
      onChange('post_executive_user_id', null);
      onChange('post_executive_user_update_type', optionsToUpdateSetting.length ? optionsToUpdateSetting[0].value : 0);
    }
  }, [data, onChange, memfis_clients, post_executive_studio_users, optionsToUpdateSetting]);

  const handleFieldValueChanged = (name, value) => {
    if (!value) {
      const fieldNameForUpdType = name.replace(/_id$/, '') + '_update_type';
      if (data[fieldNameForUpdType]) {
        value = ['distribution_list', 'memfis_company_id'].includes(name) ? '' : 0;
      }
    }
    onChange(name, value);
  };

  const handleChangeOffice = (name, value) => {
    if (!value && isRequiredOfficeFlag) {
      value = DEFAULT_OFFICE_FLAG;
    }
    handleFieldValueChanged(name, value);
  };

  const handleTextareaBlur = event => {
    handleFieldValueChanged(event.target.name, event.target.value);
  };

  const handleSelectUpdateTypeOfField = (fieldName, value) => {
    const fieldNameForRequest = fieldName.replace(/([A-Z]+)/g, '_$1').replace(/^_/, '').toLowerCase();
    const fieldNameForUpdType = fieldNameForRequest.replace(/_id$/, '') + '_update_type';
    if (!value) {
      let storedFieldValue = storedProjectData[fieldName];
      if (fieldName === 'OfficeFlag' && isRequiredOfficeFlag && !isSelectedOffice(storedFieldValue)) {
        storedFieldValue = DEFAULT_OFFICE_FLAG;
      }
      onChange(fieldNameForRequest, storedFieldValue);
    }
    onChange(fieldNameForUpdType, value);
  };

  const isQuote = isNewQuoteProject(data);

  const seriesShowCode = (filters.series.find(t => t.series_id === data.series_id) || {}).show_code;
  const isDisabledProjectRelatedFields = !isQuote && storedProjectData.QcProjectID > 0;
  const isShowUpdateOptions = isDisabledProjectRelatedFields && !!optionsToUpdateSetting.length;

  return (
    <StyledCsSetting className="step-body-wrapper cs-settings">
      {isLoading ? <Loader className="full-screen"/> : null}
      <Row className="show-code">
        <Col md={3} xs={12}>
          <InputAdvanced
            label="Show Code"
            labelSeparator=""
            name="show_code"
            value={seriesShowCode || data.show_code}
            maxLength={50}
            disabled={Boolean(seriesShowCode)}
            onChange={handleFieldValueChanged}
            onBlur={handleFieldValueChanged}
          />
        </Col>
      </Row>
        <Row>
            <Col md={10} xs={12}>
                <FormGroup>
                    <ControlLabel className={isQuote ? "required" : undefined}>
                      {isQuote ? 'Send to' : 'Distribution List'}
                    </ControlLabel>
                    <FormControl
                        name="distribution_list"
                        type="text"
                        componentClass="textarea"
                        placeholder="list of emails"
                        value={data.distribution_list || ''}
                        onChange={handleTextareaBlur}
                        onBlur={handleTextareaBlur}
                        disabled={isDisabledProjectRelatedFields && !data.distribution_list_update_type}
                    />
                </FormGroup>
                {isShowUpdateOptions &&
                  <Clearfix>
                    <StyledRadioButtons
                      label="Select the option to update the Distribution List (if necessary)"
                      labelSeparator=":"
                      name="distribution_list_update_type"
                      options={[...optionsToUpdateSetting, ...(data.distribution_list_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                      value={data.distribution_list_update_type || null}
                      onChange={(n, v) => handleSelectUpdateTypeOfField('DistributionList', v)}
                    />
                  </Clearfix>
                }
            </Col>
        </Row>
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Client Contact"
            name="client_contact_id"
            value={data.client_contact_id}
            options={filters.memfis_contacts}
            onSelect={handleFieldValueChanged}
            placeholder=""
            labelSeparator=""
            disabled={isDisabledProjectRelatedFields && !data.client_contact_update_type}
          />
        </Col>
      </Row>
      {isShowUpdateOptions &&
        <StyledRow>
          <Col md={10} xs={12}>
            <Clearfix>
              <StyledRadioButtons
                label="Select the option to update the Client Contact (if necessary)"
                labelSeparator=":"
                name="client_contact_update_type"
                options={[...optionsToUpdateSetting, ...(data.client_contact_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                value={data.client_contact_update_type || null}
                onChange={(n, v) => handleSelectUpdateTypeOfField('ClientContactID', v)}
              />
            </Clearfix>
          </Col>
        </StyledRow>
      }
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Client Service Representative User"
            name="cs_user_id"
            value={data.cs_user_id}
            options={filters.cs_users}
            onSelect={handleFieldValueChanged}
            placeholder=""
            labelSeparator=""
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} xs={12}>
          <StyledSelectAdvanced
            label="Office"
            name="office_flag"
            value={data.office_flag && data.office_flag !== DEFAULT_OFFICE_FLAG ? data.office_flag : null}
            options={OfficeFlagOptions}
            onSelect={handleChangeOffice}
            placeholder=""
            labelSeparator=""
            labelClassName={isRequiredOfficeFlag ? 'required' : undefined}
            disabled={isDisabledProjectRelatedFields && (!isRequiredOfficeFlag || isSelectedOffice(data.office_flag)) && !data.office_flag_update_type}
          />
        </Col>
      </Row>
      {isShowUpdateOptions && (!isRequiredOfficeFlag || isSelectedOffice(data.office_flag)) &&
        <StyledRow>
          <Col md={10} xs={12}>
            <Clearfix>
              <StyledRadioButtons
                label="Select the option to update the Office (if necessary)"
                labelSeparator=":"
                name="office_flag_update_type"
                options={[...optionsToUpdateSetting, ...(data.office_flag_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                value={data.office_flag_update_type || null}
                onChange={(n, v) => handleSelectUpdateTypeOfField('OfficeFlag', v)}
              />
            </Clearfix>
          </Col>
        </StyledRow>
      }
      {!!(memfis_clients || []).length && !isQuote && <>
        <Row>
          <Col md={3} xs={12}>
            <StyledSelectAdvanced
              label="MEMFIS Client"
              name="memfis_company_id"
              value={data.memfis_company_id}
              options={memfis_clients}
              onSelect={handleFieldValueChanged}
              placeholder=""
              labelSeparator=""
              disabled={isDisabledProjectRelatedFields && !data.memfis_company_update_type}
            />
          </Col>
        </Row>
        {isShowUpdateOptions &&
          <StyledRow>
            <Col md={10} xs={12}>
              <Clearfix>
                <StyledRadioButtons
                  label="Select the option to update the MEMFIS Client (if necessary)"
                  labelSeparator=":"
                  name="memfis_company_update_type"
                  options={[...optionsToUpdateSetting, ...(data.memfis_company_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                  value={data.memfis_company_update_type || null}
                  onChange={(n, v) => handleSelectUpdateTypeOfField('MemfisCompanyID', v)}
                />
              </Clearfix>
            </Col>
          </StyledRow>
        }
      </>}
      {!!(post_executive_studio_users || []).length &&
        <>
          <Row>
            <Col md={3} xs={12}>
              <StyledSelectAdvanced
                label="Studio Contact"
                name="post_executive_user_id"
                value={data.post_executive_user_id}
                options={post_executive_studio_users}
                onSelect={handleFieldValueChanged}
                placeholder=""
                labelSeparator=""
                disabled={isDisabledProjectRelatedFields && !data.post_executive_user_update_type}
              />
            </Col>
          </Row>
          {isShowUpdateOptions &&
            <StyledRow>
              <Col md={10} xs={12}>
                <Clearfix>
                  <StyledRadioButtons
                    label="Select the option to update the Studio Contact (if necessary)"
                    labelSeparator=":"
                    name="post_executive_user_update_type"
                    options={[...optionsToUpdateSetting, ...(data.post_executive_user_update_type ? [{value: 0, name: 'Use original value'}] : [])]}
                    value={data.post_executive_user_update_type || null}
                    onChange={(n, v) => handleSelectUpdateTypeOfField('PostExecutiveUserID', v)}
                  />
                </Clearfix>
              </Col>
            </StyledRow>
          }
        </>
      }
      {!!filters.pending_approval_studio_partner_types &&
        <Row>
          <Col md={3} xs={12}>
            <MultiSelect
              label="Approval required for"
              value={(data.pending_approval_studio_partner_type_ids || []).map(i => filters.pending_approval_studio_partner_types.find(o => o.value === i)).filter(Boolean)}
              options={filters.pending_approval_studio_partner_types}
              onChange={selected => handleFieldValueChanged('pending_approval_studio_partner_type_ids', (selected || []).map(i => i.value))}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col md={12} xs={12}>
          <StyledCheckbox
            checked={data.send_from_qod || false}
            onChange={({target: {checked}}) => handleFieldValueChanged('send_from_qod', checked)}
          >
            Send from QOD
          </StyledCheckbox>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <StyledCheckbox
            checked={isCreateClientProxies || false}
            onChange={({target: {checked}}) => handleFieldValueChanged('is_create_client_proxies', checked)}
          >
            Allow create proxies for client users
          </StyledCheckbox>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          <StyledCheckbox
            checked={data.overage_request || false}
            onChange={({target: {checked}}) => handleFieldValueChanged('overage_request', checked)}
          >
            Overage Request
          </StyledCheckbox>
        </Col>
      </Row>
      <Row>
        <Col md={10} xs={12}>
          <FormGroup>
            <ControlLabel>
              Project Billing Notes:
            </ControlLabel>
            <StyledCheckbox
              checked={data.update_project_billing_notes || false}
              onChange={({target: {checked}}) => handleFieldValueChanged('update_project_billing_notes', checked)}
            >
              Allow edit the billing notes for project
            </StyledCheckbox>
            <FormControl
              name="project_billing_notes"
              type="text"
              componentClass="textarea"
              placeholder="billing notes"
              value={data.project_billing_notes || ''}
              onChange={handleTextareaBlur}
              onBlur={handleTextareaBlur}
              disabled={!data.update_project_billing_notes}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={10} xs={12}>
          <FormGroup>
            <ControlLabel>
              Project QC Notes:
            </ControlLabel>
            <StyledCheckbox
              checked={data.update_project_qc_notes || false}
              onChange={({target: {checked}}) => handleFieldValueChanged('update_project_qc_notes', checked)}
            >
              Allow edit the QC notes for project
            </StyledCheckbox>
            <FormControl
              name="project_qc_notes"
              type="text"
              componentClass="textarea"
              placeholder="Enter QC notes"
              value={data.project_qc_notes || ''}
              onChange={handleTextareaBlur}
              onBlur={handleTextareaBlur}
              disabled={!data.update_project_qc_notes}
            />
          </FormGroup>
        </Col>
      </Row>
    </StyledCsSetting>
  );
};

const StyledCsSetting = styled.div`
  label {
    &.required {
      position: relative;
      &:after {
        content: '*';
        color: #ff4040;
        position: absolute;
        right: -10px;
        top: -2px;
      }
    }
  }
`;

const StyledSelectAdvanced = styled(SelectAdvanced)`
  .Select.has-value.Select--single,
  .Select.Select--single,
  .Select--multi {
    &.is-disabled > .Select-control {
      background-color: #eee;
      border-color: #ccc;
      cursor: not-allowed;
      .Select-arrow-zone {
        display: none;
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
  label {
    font-weight: 700;
  }
  input[type="checkbox"] {
    margin-top: 2px;
  }
`;

const StyledRadioButtons = styled(RadioButtonsAdvanced)`
  > label {
    font-size: 13px;
  }
  .field label {
    width: 100%;
    margin: 5px 0px !important;
    &:last-of-type {
      margin-bottom: 20px !important;
    }
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 15px;
`;

Screen.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Screen;
