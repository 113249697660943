import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ArrowCell = React.memo(({row, collapsedEl, className}) => {
  const CollapsedRow = collapsedEl.component;
  const collapsedRowArgs = collapsedEl.componentArgs || {};
  return (
    <ActionsCellStyled className={className}>
      <span className="arrow"/>
      <CollapsedRow {...collapsedRowArgs} row={row}/>
    </ActionsCellStyled>
  );
});

const ActionsCellStyled = styled.td`
  text-align: center;

  > div {
    display: none;
  }

  tr.open & {

    @media (max-width: 767px) {
      position: relative;

      > div {
        display: block;
      }
    }
  }
`;

ArrowCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string,
  collapsedEl: PropTypes.object
};

export default ArrowCell;
