import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {Clearfix} from 'react-bootstrap';
import classNames from 'classnames';
import {
  NEW_REQUEST_STEP_CHOICES, NEW_REQUEST_STEP_ORIGINAL_REPORT, NEW_REQUEST_STEP_STUDIO,
  NEW_REQUEST_STEP_DISTRIBUTION, NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS, NEW_REQUEST_STEP_PRODUCTION,
  NEW_REQUEST_STEP_FORMATS, NEW_REQUEST_STEP_MEDIA, NEW_REQUEST_STEP_TURNAROUND, NEW_REQUEST_STEP_FILES,
  NEW_REQUEST_STEP_CS_SETTINGS, NEW_REQUEST_STEP_REVIEW_AND_CONFIRM, QC_REQUEST_TYPES, NEW_REQUEST_STEP_FINISHING_HOUSE,
  NEW_REQUEST_STEP_SECURITY, NEW_REQUEST_STEP_CS_TAGS, NEW_REQUEST_STEP_CS_WO_STEPS, NEW_REQUEST_STEP_CS_GENERAL_NOTES,
  isNewQuoteProject,
} from '../constants';
import Choices from './screens/Choices';
import OriginalReport from './screens/OriginalReport';
import Distribution from './screens/Distribution/Distribution';
import Formats from './screens/Formats';
import FormatsForQuote from './screens/FormatsForQuote';
import MediaDetails from './screens/MediaDetails';
import MediaDetailsForQuote from './screens/MediaDetailsForQuote';
import Turnaround from './screens/Turnaround';
import SelectFiles from './screens/SelectFiles';
import SelectFilesForMultiFormats from './screens/SelectFilesForMultiFormats';
import ReviewAndConfirm from './screens/ReviewAndConfirm/ReviewAndConfirm';
import Production from './screens/Production';
import FinishingHouses from './screens/FinishingHouses';
import StudiosForPartner from './screens/StudiosForPartner';
import SummaryReplacement from './screens/SummaryReplacement/SummaryReplacement';
import CsSettings from './screens/CsSettings';
import CsTags from './screens/CsTags';
import CsWorkOrderSteps from './screens/CsWorkOrderSteps';
import CsGeneralNotes from './screens/CsGeneralNotes';
import Security from './screens/Security';

class StepBody extends Component {

  componentDidUpdate(prevProps, prevState, ss) {
    const {currentStep} = this.props;
    if (currentStep && currentStep !== prevProps.currentStep) {
      document.getElementsByClassName('step-body')[0].scrollTop = 0;
    }
  }

  renderStepBodyContent = () => {
    const {
      currentStep, currentSubStep, data, filters, onChange, onApplyChoices, onLoadReports, reports, onSaveDistributor,
      onClickChangeDistributor, onGetReplacement, reportdetails
    } = this.props;
    let bodyContent = null;
    const isNewQuote = isNewQuoteProject(data);
    switch (currentStep) {
      case NEW_REQUEST_STEP_CHOICES:
        bodyContent = (
          <Choices
            data={data}
            filters={filters}
            onSelect={onChange}
            onContinue={onApplyChoices}
            />
        );
        break;
      case NEW_REQUEST_STEP_STUDIO:
        bodyContent = (
          <StudiosForPartner
            data={data}
            filters={filters}
            onSelect={onChange}
            />
        );
        break;
      case NEW_REQUEST_STEP_ORIGINAL_REPORT:
        bodyContent = (
          <OriginalReport
            data={data}
            reports={reports}
            onSelect={onChange}
            onLoadReports={onLoadReports}
            />
        );
        break;
      case NEW_REQUEST_STEP_DISTRIBUTION:
        bodyContent = (
          <Distribution
            data={data}
            showOptions={currentSubStep === NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS}
            filters={filters}
            onSelect={onChange}
            onSave={onSaveDistributor}
            onClickChange={onClickChangeDistributor}
            />
        );
        break;
      case NEW_REQUEST_STEP_PRODUCTION:
        bodyContent = (
          <Production
            data={data}
            filters={filters}
            onSelect={onChange}
            label="Production"
            fieldName="production_id"
            filterName="productions"
          />
        );
        break;
      case NEW_REQUEST_STEP_FINISHING_HOUSE:
        bodyContent = (
          <FinishingHouses
            data={data}
            filters={filters}
            onSelect={onChange}
            label="Post Partner"
            fieldName="finishing_house_id"
            filterName="finishing_houses"
          />
        );
        break;
      case NEW_REQUEST_STEP_FORMATS:
        bodyContent = isNewQuote ? (
          <FormatsForQuote
            data={data || {}}
            filters={filters}
            onChange={onChange}
          />
        ) : (
          <Formats
            data={data}
            filters={filters}
            onSelect={onChange}
            />
        );
        break;
      case NEW_REQUEST_STEP_MEDIA:
        bodyContent = isNewQuote ? (
          <MediaDetailsForQuote
            data={data || {}}
            filters={filters}
            onChange={onChange}
          />
        ) : (
          <MediaDetails
            data={data}
            filters={filters}
            onChange={onChange}
            />
        );
        break;
      case NEW_REQUEST_STEP_TURNAROUND:
        bodyContent = (
          <Turnaround
            data={data}
            filters={filters}
            onChange={onChange}
            />
        );
        break;
      case NEW_REQUEST_STEP_FILES:
        bodyContent = data.request_type_id === QC_REQUEST_TYPES.Replacement.id ? (
          <SummaryReplacement
            allowFileUpload={filters.allow_file_upload}
            data={data}
            onChange={onChange}
            reportdetails={reportdetails}
            onGetReplacement={onGetReplacement}
          />
        ) : !data.video_format ? (
          <SelectFiles
            allowFileUpload={filters.allow_file_upload}
            data={data}
            onChange={onChange}
            />
        ) : (
          <SelectFilesForMultiFormats
            data={data}
            filters={filters}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_CS_SETTINGS:
        bodyContent = (
          <CsSettings
            data={data}
            filters={filters}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_CS_TAGS:
        bodyContent = (
          <CsTags
            data={data}
            filters={filters}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_SECURITY:
        bodyContent = (
          <Security
            data={data}
            filters={filters}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_CS_WO_STEPS:
        bodyContent = (
          <CsWorkOrderSteps
            data={data}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_CS_GENERAL_NOTES:
        bodyContent = (
          <CsGeneralNotes
            data={data}
            onChange={onChange}
          />
        );
        break;
      case NEW_REQUEST_STEP_REVIEW_AND_CONFIRM:
        bodyContent = (
          <ReviewAndConfirm
            dispatch={this.props.dispatch}
            data={data}
            filters={filters}
            onChange={onChange}
            />
        );
        break;
      default:
        break;
    }
    return bodyContent;
  };

  render() {
    const {currentStep, data} = this.props;
    const compClassNames = classNames({
      'step-content-container step-body': true,
      'step-choices': currentStep === NEW_REQUEST_STEP_CHOICES,
      'step-resume': currentStep === NEW_REQUEST_STEP_CHOICES && !data.request_type_id
    });
    return <StyledStepBody className={compClassNames}>{this.renderStepBodyContent()}</StyledStepBody>;
  }
}

const StyledStepBody = styled(Clearfix)`
  .new-request .new-request-wrapper & {  
    &.step-body {
      background-color: #fff;
  
      &.step-choices {
        background-color: transparent;
        padding-bottom: 20px;
        min-height: calc(100vh - 183px);
  
        &.step-resume {
          min-height: calc(100vh - 211px - 183px);
        }
      }
  
      &:not(.step-choices) {
        height: calc(100vh - 107px - 6px - 71px);
        overflow-y: auto;
  
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
          background-color: transparent;
        }
  
        &::-webkit-scrollbar {
          width: 8px;
          background-color: transparent;
        }
  
        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          -webkit-box-shadow: none;
          background-color: #c4c4c4;
        }
      }

      .create-request-as {
        display: flex;
        flex-direction: row;
        gap: 39px;
      }

      .request-type-item {
        height: 356px;
        max-width: 293px;
        width: calc((100% / 3) - 24px);
        background-color: #fff;
        border-radius: 5px;
        padding: 163px 34px 41px;
        position: relative;
        cursor: pointer;
        transition: all ease-out 0.5s;
        &.request-types-4 {
          width: calc((100% / 4) - 29px);
        }
        &.request-types-2 {
          width: calc((100% / 2) - 19px);
        }

        i.request-type-img,
        img.request-type-img {
          position: absolute;
          left: 34px;
          transition: top ease-out 0.5s;

          &.img-new-qc-request {
            width: 86px;
            height: 86px;
            top: 68px;
          }
  
          &.img-replacement {
            width: 60px;
            height: 81px;
            top: 80px;
          }
  
          &.img-new-episode {
            width: 71px;
            height: 71px;
            top: 83px;
          }

          &.img-new-quote {
            width: 55px;
            height: 55px;
            top: 96px;
            font-size: 55px;
            color: #0000ff;
          }
        }

        .request-type-name {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 25px;
          color: #282828;
          opacity: 0.7;
          padding: 0 0 19px;
          word-break: break-word;
        }

        .request-type-desc {
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 17px;
          color: #282828;
          opacity: 0.7;
          word-break: break-word;
        }

        &.not-selected:not(:hover) {
          background-color: #F4F4F4;
          opacity: 0.5;
        }

        &:not(.selected) {
          overflow: hidden;
          &:hover {
            box-shadow: 0 0 6px 2px #a1cd86;
            .request-type-name,
            .request-type-desc {
              opacity: 0.75 !important;
            }
          }
        }

        &.selected {
          cursor: default;
          background-color: #F4F4F4;

          &.request-type-new-quote,
          &.request-type-new-qc-request {
            padding-top: 118px;
            width: calc(100% - ((293px + 39px) * 2));
            max-width: 608px;
            min-width: 293px;
            min-height: 356px;
            height: auto;
            &.request-types-4 {
              width: calc(100% - ((293px + 39px) * 3));
            }
            &.request-types-2 {
              width: calc(100% - (293px + 39px));
            }
          }

          &:not(.request-type-new-qc-request, .request-type-new-quote) .request-type-selects {
            position: absolute;
            left: 34px;
            bottom: 41px;
          }

          .icon-circle-check {
            position: absolute;
            top: 23px;
            right: 20.4px;
            color: #A1CD86;
            font-size: 22px;
          }

          i.request-type-img,
          img.request-type-img {
            &.img-new-qc-request {
              top: 23px;
            }
            &.img-new-quote {
              top: 55px;
            }
          }

          .Select-control {
            background-color: #fff;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

StepBody.propTypes = {
  currentStep: PropTypes.number.isRequired,
  currentSubStep: PropTypes.number,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onApplyChoices: PropTypes.func.isRequired,
  onLoadReports: PropTypes.func.isRequired,
  reports: PropTypes.array,
  onGetReplacement: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired,
  onSaveDistributor: PropTypes.func.isRequired,
  onClickChangeDistributor: PropTypes.func.isRequired
};

StepBody.defaultProps = {
  data: {}
};

export default StepBody;
