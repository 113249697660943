import {Button, Modal, FormGroup, FormControl, ControlLabel, Row, Col, Grid} from "react-bootstrap";
import DatePicker from "react-datepicker";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import {
  updateQuickUpdateDate,
  saveQuickUpdates,
  closeQuickUpdates,
} from '../actions';

const QuickUpdateDate = props => {
  const {dispatch, data} = props;
  const {show,/* Textless,*/ Notes} = data;

  const handleClose = () => {
    dispatch(closeQuickUpdates());
  };

  const handleSave = () => {
    dispatch(saveQuickUpdates());
  };
  const handleChangeField = (values) => {
    dispatch(updateQuickUpdateDate(values));
  };

  const handleChangeDate = (name, value) => {
    handleChangeField({[name]: value ? value.toISOString() : null});
  };

  const handleChangeNotes = event => {
    handleChangeField({Notes: event.target.value});
  };

  const onKeyUp = event => {
    const ENTER = 13;
    if (event.keyCode === ENTER && event.target.nodeName !== 'TEXTAREA') {
      handleSave();
    }
  }

  const DatePickerCustomInput = React.forwardRef((inputProps, inputRef) => (
    <input
      {...inputProps}
      readOnly
      ref={inputRef}
    />
  ));

  const renderDateCol = (name, label, labelPrefix) => {
    const currentValue = data[name];
    const preparedValue = currentValue ? moment(currentValue).toDate() : null;
    return (
      <Col xs={12} md={3}>
        <div className="custom-date-field">
          {
            label ?
              <ControlLabel className="label-with-prefix">
                {labelPrefix ? <span className="visible-xs">{labelPrefix}</span> : null}
                {label}
              </ControlLabel> : null
          }
          <DatePicker
            dateFormat="MMMM d, yyyy haa"
            selected={preparedValue}
            onChange={value => handleChangeDate(name, value)}
            showTimeSelect
            timeFormat="h aa"
            timeIntervals={60}
            timeCaption="Time"
            customInput={isMobile() ? <DatePickerCustomInput/> : undefined}
            isClearable={isMobile()}
          />
        </div>
      </Col>
    );
  };

  return (
    <StyledModal
      bsSize="large"
      backdrop={true}
      backdropClassName="edit-date-modal-backdrop quick-update-modal-backdrop"
      onHide={handleClose}
      onKeyUp={onKeyUp}
      enforceFocus={false}
      animation={isMobile()}
      show={show}
    >
      <Modal.Header>
        <Modal.Title>Quick Update</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Grid className="customDatePickerWidth">
          <Row className="main-row">
            <Col xs={12} md={2} className="hidden-xs">
              <ControlLabel className="version">V1</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <Row>
                {renderDateCol('V1_HorizontalScheduled', 'Horizontal Scheduled', 'V1')}
                {renderDateCol('V1_VerticalScheduled', 'Vertical Scheduled', 'V1')}
                {renderDateCol('V1_HorizontalReceived', 'Horizontal Received', 'V1')}
                {renderDateCol('V1_VerticalReceived', 'Vertical Received', 'V1')}
              </Row>
            </Col>
          </Row>
          <Row className="main-row">
            <Col xs={12} md={2} className="hidden-xs">
              <ControlLabel className="version">V2</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <Row>
                <Col xs={12} md={3} className="hidden-xs"/>
                <Col xs={12} md={3} className="hidden-xs"/>
                {renderDateCol('V2_HorizontalReceived', 'Horizontal Received', 'V2')}
                {renderDateCol('V2_VerticalReceived', 'Vertical Received', 'V2')}
              </Row>
            </Col>
          </Row>
          <Row className="main-row">
            <Col xs={12} md={2} className="hidden-xs">
              <ControlLabel className="version">V3</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <Row>
                <Col xs={12} md={3} className="hidden-xs"/>
                <Col xs={12} md={3} className="hidden-xs"/>
                {renderDateCol('V3_HorizontalReceived', 'Horizontal Received', 'V3')}
                {renderDateCol('V3_VerticalReceived', 'Vertical Received', 'V3')}
              </Row>
            </Col>
          </Row>
          <Row className="main-row">
            <Col xs={12} md={2} className="hidden-xs">
              <ControlLabel className="version">Textless</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <Row>
                <Col xs={12} md={3} className="hidden-xs"/>
                <Col xs={12} md={3} className="hidden-xs"/>
                {renderDateCol('TextlessHorizontalReceived', 'Horizontal Received', 'Textless')}
                {renderDateCol('TextlessVerticalReceived', 'Vertical Received', 'Textless')}
              </Row>
            </Col>
          </Row>
          <Row className="main-row margin-top-12">
            <Col xs={12} md={2}>
              <ControlLabel>Uploaded On</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <Row>
                {renderDateCol('UploadedOn')}
              </Row>
            </Col>
          </Row>
          <Row className="main-row margin-top-12">
            <Col xs={12} md={2}>
              <ControlLabel>Notes</ControlLabel>
            </Col>
            <Col xs={12} md={10}>
              <TextareaStyled>
                <FormControl
                  value={Notes || ''}
                  onChange={handleChangeNotes}
                  onBlur={handleChangeNotes}
                  componentClass="textarea"
                  rows="3"
                />
              </TextareaStyled>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>

      <Modal.Footer>
        <Button className="update" onClick={handleSave}>Save</Button>
        <Button className="cancel" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </StyledModal>
  );
};

const TextareaStyled = styled(FormGroup)`
  textarea {
    max-width: 100%;
    font-size: 10px;
  }
`;

const StyledModal = styled(Modal)`
  .customDatePickerWidth .custom-date-field {
    > .react-datepicker-wrapper {
      width: 100%;
      > .react-datepicker__input-container > input {
        width: 100%;
      }
      .react-datepicker__close-icon {
        &::after {
          background-color: #595959;
        }
        &:hover {
          &::after {
            opacity: 0.9;
         }
        }
      }
    }
    .react-datepicker-popper {
      @media (min-width: 768px) {
        min-width: 290px;
      }
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    top: unset;
    right: 2.4vw;
    left: 4.2vw;
    bottom: 0;
    min-width: unset;
    width: 93.4vw;
    height: auto;
    max-height: 100vh;
    border-radius: 5px 5px 0px 0px;
  }

  .modal-open & {
    overflow: visible;
  }

  .modal-backdrop {
    display: none;
  }

  .modal-dialog {
    @media (max-width: 767px) {
      margin: 0;
      width: auto;
    }
  }

  .modal-content {
    border: 1px solid #999999;
    box-shadow: none;
    @media (max-width: 767px) {
      position: relative;
    }

    .main-row {
      @media (min-width: 992px) {
        &.margin-top-12 {
          margin-top: 12px;
        }
        > .col-md-2 {
          width: 11%;
          label.version {
            margin-top: 34px;
          }
        }
        > .col-md-10 {
          width: 89%;
        }
      }
    }
  }

  .modal-header {
    border-bottom: 0;
    height: 35px;
    padding: 11px 14px;
    @media (max-width: 767px) {
      height: 51px;
      display: flex;
      align-items: center;
      padding-left: 21px;
      padding-right: 21px;
    }
    h4 {
      font-size: 11px;
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  }
  .modal-body {
    background-color: #f7f7f7;
    padding: 11px 0 5px;
    @media (max-width: 767px) {
      padding: 17px 0 22px;
      max-height: calc(100vh - 175px);
      overflow-y: auto;
    }

    label {
      display: block;
      font-weight: normal;
      font-size: 10px;
      margin-top: 10px;
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 8px;
        &.label-with-prefix {
          display: flex;
          align-items: flex-start;
          span {
            padding-right: 5px;
          }
        }
      }
      @media (min-width: 768px) {
        &.label-with-prefix {
          text-align: center;
        }
      }
    }
    input {
      border: 1px solid #e4e4e4;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 10px;
      line-height: 28px;
      padding: 0 6px;
      width: 140px;
      height: 28px;
      @media (max-width: 767px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 33px;
        width: 156px;
        height: 35px;
        padding: 0 9px;
        background-color: #FFFFFF;
        background-position: right 11px center;
        border: 0.5px solid #E4E4E4;
        border-radius: 3px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      }
    }
  }
  .modal-footer {
    border-top: 0;
    padding: 15px 24px;
    text-align: left;
    @media (max-width: 767px) {
      padding: 0;
      height: 50px;
    }
    button {
      background-color: #595959;
      border: 0;
      border-radius: 25px;
      color: #ffffff;
      font-size: 11px;
      padding: 8px 18px;
      &:hover, &:active, &:focus {
        background-color: #595959;
        box-shadow: none;
        color: #ffffff;
        outline: none;
      }
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.8;
      }
      &.cancel {
        background-color: transparent;
        color: #6F6F6F;
        @media (max-width: 767px) {
          position: absolute;
          top: 18px;
          right: 21px;
          font-size: 13px;
          line-height: 15px;
          padding: 0;
        }
      }
      &.update {
        @media (max-width: 767px) {
          border-radius: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .react-datepicker {
    font-size: 1rem;
    font-family: 'Roboto',sans-serif;
    .react-datepicker__current-month{
      font-size: 1.2rem;
    }
    .react-datepicker__day, .react-datepicker__day-name {
      margin: 0.4rem;
    }
  }
`;

QuickUpdateDate.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func
};

export default QuickUpdateDate;