import React from 'react';
import PropTypes from 'prop-types';

export const SeverityCell = React.memo(({row, className}) => {
  return (
    <td
      className={`capitalized-all${className ? ` ${className}` : ''}`}
      title="Severity"
      style={row.SeverityColor ? {color: `#${row.SeverityColor}`} : {}}
    >
      {row.Severity}
    </td>
  );
});

SeverityCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string
};
