import React, {Component} from 'react';
import styled from 'styled-components';
import {ContentStyled} from '../modals/IssueDiscussModal';
import NotificationImg from '../../../../../assets/images/notification.svg';

class Activities extends Component {
  render() {
    const notifications = [];
    return (
      <StyledComments>
        {notifications && notifications.length ? notifications.map((notification, index) => {
          return (
            <NotificationStyled key={index}>
              <img src={NotificationImg} alt="Notification" className="content-column"/>
              <div className="content-column">
                <div className="username">{notification.UserName}</div>
                <div>{notification.Comment}</div>
              </div>
            </NotificationStyled>
          )
        }) : <div className="no-data">No Recent Activity</div>
        }
      </StyledComments>
    );
  }
}

const StyledComments = styled.div`
  max-height: calc(100vh - 85px);
  overflow: auto;
  padding: 15px 15px;
`;

const NotificationStyled = styled(ContentStyled)`
  background-color: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 12px;
  padding: 15px;
  img {
    margin-right: 10px;
  }
  .username {
    font-weight: bold;
  }
`;

export default Activities;
