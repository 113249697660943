import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {hideNotification} from '../actions';
import {AlertList} from 'react-bs-notifier';
import closeImg from '../../../assets/images/close.svg';

class HeaderBar extends Component {
  dismissAlert = notification => {
    const {dispatch} = this.props;
    dispatch(hideNotification(notification));
  };

  render() {
    const {main} = this.props;

    return (
      <StyledAlerts className="notifications">
        <AlertList
          alerts={main.alerts}
          onDismiss={this.dismissAlert}
        />
      </StyledAlerts>
    );
  }
}

const StyledAlerts = styled.div`
  > div {
    padding-top: 12px;
    right: 50%;
    > div > div > div { // change plugin transition
      overflow: initial !important;
      transform: none !important;
    }
  }

  .alert {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 10px;
    color: #282828;
    font-size: 12px;
    line-height: 13px;
    margin-right: -290px;
    padding: 26px 23px 23px 23px;
    position: relative;
    width: 581px;
    @media (max-width: 767px) {
      margin-right: -48vw;
      width: calc(86vw + 1px);
    }
    
    &.alert-success {
      background: #FFE279;
    }
    
    &.alert-danger {
      background: #f5c6cb;
    }
    
    i {
      display: none;
      @media (max-width: 767px) {
        & + div {
          width: calc(100% - 50px);
        }
      }
    }
    
    .close {
      background: url(${closeImg}) no-repeat center center;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      font-size: 0;
      height: 25px;
      width: 50px;
      opacity: 1;
      position: absolute;
      top: 25px;
      right: 0;
    }
    
    h4 {
      font-weight: bold;
      font-size: 13px;
    }
    
    h4+div {
      max-width: 365px;
    }
  }

  button.close {
    outline: none;
    padding-left: 20px;
    padding-bottom: 8px;
  }
`;

HeaderBar.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    main: state.main
  };
}

export default connect(
  mapStateToProps
)(HeaderBar);
