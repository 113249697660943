import $ from 'jquery';
import config from '../../../config';
import qs from 'qs';
import {showError, showSuccess} from "../../layouts/actions";

export const PROJECTS_CLEAR = 'PROJECTS_CLEAR';
export const PROJECTS_SET_LOADING = 'PROJECTS_SET_LOADING';
export const PROJECTS_GET_PROJECTS = 'PROJECTS_GET_PROJECTS';
export const PROJECTS_GET_FILTERS = 'PROJECTS_GET_FILTERS';
export const PROJECTS_ADD_FILTERS = 'PROJECTS_ADD_FILTERS';
export const PROJECTS_CHANGE_FILTER_TYPE = 'PROJECTS_CHANGE_FILTER_TYPE';
export const PROJECTS_CHANGE_FILTER = 'PROJECTS_CHANGE_FILTER';
export const PROJECTS_SAVED_FILTER = 'PROJECTS_SAVED_FILTER';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';

export const clearAll = () => ({type: PROJECTS_CLEAR});

const getValidFilter = (allFilters, filter, value) => {
  const allowedFilters = allFilters ? Object.keys(allFilters) : [];
  const isExist = allowedFilters.includes(filter);

  if (isExist) {
    const existFilter = allFilters[filter] || {};

    const existFilterValues = existFilter ? existFilter.map(f => f.value) : [];
    return Array.isArray(value) ?
      value.filter(item => existFilterValues.includes(!isNaN(+item) ? +item : item)).map(item => (!isNaN(+item) ? +item : item)) :
      null;
  }

  return null;
};

const getValidFilters = (allFilters, urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: filters.sortDirect === 'desc' ? 'desc' : 'asc'
      }
    } else if (key !== 'search' && key !== 'show_quotes') {
      let validFilter = getValidFilter(allFilters, key, filters[key]);
      if (!validFilter) {
        const othFilters = {};
        allFilters.filters.forEach(item => {
          othFilters[item.name] = item.values;
        });
        validFilter = getValidFilter(othFilters, key, filters[key]);
      }
      if (validFilter) {
        res[key] = validFilter;
      }
    } else {
      res[key] = filters[key];
    }
  });

  return res;
};

export const getValidFiltersForInitSearch = (urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: (filters.sortDirect || filters.sortdirect) === 'desc' ? 'desc' : 'asc'
      }
    } else if (!['sortDirect', 'sortdirect'].includes(key)) {
      res[key] = filters[key];
    }
  });

  return res;
};

const prepareSingleFiltersParams = filters => {
  return {...filters};
};

export const getFilters = (history, projectsFilters, srcFilters) => (dispatch, getState) => {
  dispatch({type: PROJECTS_SET_LOADING, name: 'filters', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/projects/filters`,
    data: {
      filters: JSON.stringify(prepareSingleFiltersParams(projectsFilters))
    }
  })
    .then(res => {
      const validUrlFilters = getValidFilters(res, srcFilters);
      const urlParams = {};
      const someFilters = {};
      Object.keys(validUrlFilters).forEach(filter => {
        if (['search', 'sort', 'show_quotes'].includes(filter)) {
          urlParams[filter] = validUrlFilters[filter];
        } else {
          someFilters[filter] = validUrlFilters[filter];
        }
      });
      dispatch(savedAllFilters(urlParams));
      return {allowedFilters: res.filters, someFilters};
    })
    .then(({allowedFilters, someFilters}) => {
      dispatch({type: PROJECTS_GET_FILTERS, allowedFilters});

      const someFiltersKeys = Object.keys(someFilters).sort((a, b) => {
        return a.localeCompare(b);
      });
      someFiltersKeys.forEach(filterName => {
        const filter = allowedFilters.find(item => item.name === filterName);
        if (filter) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            isOrderedValues: filter.isOrderedValues,
            defaultValue: someFilters[filterName],
            disabled: true
          }));
        }
      });

      const requiredFilters = allowedFilters.filter(filter => filter.required);
      requiredFilters.forEach(filter => {
        if (!Object.keys(someFilters).includes(filter.name)) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            isOrderedValues: filter.isOrderedValues,
            defaultValue: filter.defaultValue,
            disabled: true,
            required: true
          }));
        }
      });

      dispatch({type: PROJECTS_SET_LOADING, name: 'filters', loading: false});
    })
    .then(() => getState().projects.selectedFilters)
    .then(selectedFilters => savedState(history, selectedFilters))
    .catch(() => {
      dispatch({type: PROJECTS_SET_LOADING, name: 'filters', loading: false});
    });
};

export const savedAllFilters = urlFilters => dispatch => {
  dispatch({type: PROJECTS_SAVED_FILTER, urlFilters});
};

export const changeFilter = (history, name, value) => dispatch => {
  dispatch({type: PROJECTS_CHANGE_FILTER, name, value});
  return dispatch(doSearch(history, null));
};

export const changeFilterType = (history, filterName, newFilterName, newFilterLabel, defaultValue) => dispatch => {
  dispatch({type: PROJECTS_CHANGE_FILTER_TYPE, filterName, newFilterName, newFilterLabel, defaultValue});
  return dispatch(doSearch(history, null));
};

export const addFilter = filter => dispatch => {
  dispatch({type: PROJECTS_ADD_FILTERS, filter});
};

const prepareFiltersParams = filters => {
  if (!filters) {
    return {};
  }
  const filtersObj = {};
  filters.forEach(filter => {
    const name = filter.name.replace(' ', '_').toLowerCase();
    filtersObj[name] = filter.value
  });

  if (filtersObj.sort) {
    const {field, direction} = filtersObj.sort;
    filtersObj.field = field ? field.toLowerCase() : null;
    filtersObj.direction = direction;
    delete filtersObj.sort;
  }
  return prepareSingleFiltersParams(filtersObj);
};

const savedState = (history, selectedFilters) => {
  const filters = {};
  selectedFilters.forEach(filter => {
    if ((Array.isArray(filter.value) && filter.value.length) || filter.value) {
      filters[filter.name] = filter.value;
    }
    if (filter.name === 'sort') {
      filters[filter.name] = filter.value.field;
      filters.sortDirect = filter.value.direction;
    }
  });

  history.replace({
    pathname: `/projects`,
    search: `${qs.stringify(filters)}`
  });

  localStorage.setItem('projectsFilters', JSON.stringify(filters));
};

export const doSearch = (history, startedFilters, isLoadMore) => (dispatch, getState) => {
  const state = getState();
  const filtersObj = prepareFiltersParams(state.projects.selectedFilters);
  const start = isLoadMore ? state.projects.data.length : 0;

  !startedFilters && !isLoadMore && savedState(history, state.projects.selectedFilters);

  const loadingName = isLoadMore ? 'more' :'projects' ;
  dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/projects`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(filtersObj)
    }
  })
    .done(res => {
      let {data, total} = res.projects;
      if (isLoadMore) {
        data = [...state.projects.data, ...data]
      }
      dispatch({type: PROJECTS_GET_PROJECTS, data, total: total});
    })
    .fail(() => {
      dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: false});
    });
};

export const wrapProject = (projectObj, needCreateWorkOrder) => (dispatch) => {
  const loadingName = 'projects' ;
  dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: true});

  const projectId = projectObj.QcProjectIDs.split(',')[0];
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/projects/${projectId}/wrapped?create_wo=${needCreateWorkOrder}`,
  })
    .done(res => {
      dispatch(showSuccess('The project has been wrapped.'));

      const newProjectObj = {
        ...projectObj,
        WrapDate: new Date().toString()
      }
      dispatch({type: PROJECT_UPDATE, project: newProjectObj});
      dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: false});
    })
    .fail(() => {
      dispatch(showError('Failed mark project as wrapped.'));
      dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: false});
    });
};

export const unwrapProject = (projectObj, cancelWorkOrder) => (dispatch) => {
  const loadingName = 'projects' ;
  dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: true});

  const projectId = projectObj.QcProjectIDs.split(',')[0];
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/projects/${projectId}/wrapped?unwrap=true&cancel_wo=${cancelWorkOrder}`,
  })
      .done(res => {
        dispatch(showSuccess('The project has been unwrapped'));

        const newProjectObj = {
          ...projectObj,
          WrapDate: null
        }
        dispatch({type: PROJECT_UPDATE, project: newProjectObj});
        dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: false});
      })
      .fail(() => {
        dispatch(showError('Failed unwrap project'));
        dispatch({type: PROJECTS_SET_LOADING, name: loadingName, loading: false});
      });
};