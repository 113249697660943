import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import Header from './components/Header';
import {Grid, Row, Alert} from 'react-bootstrap';
import {hideLoginMessage, resendOnboardingEmail} from '../pages/Login/actions';
import Loader from '../components/Loader';

class LoginLayout extends Component {
  constructor(props, context) {
    super(props, context);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    const {title} = this.props;
    document.title = `QC On Demand: ${title}`;

    window.addEventListener("focus", this.onFocus);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus() {
    const {title} = this.props;
    setTimeout(function() {
      document.title = 'QC On Demand: ' + title;
    }, 200);
  }

  onResendRecoverPwd = () => {
    const {dispatch, history} = this.props;
    dispatch(hideLoginMessage());
    history.replace({pathname: '/forgot-password'});
  };

  onResendOnboardingEmail = () => {
    const {dispatch} = this.props;
    dispatch(resendOnboardingEmail());
  };

  renderLoginMessage = () => {
    const {user: {loginMessage, loginErrorMessage, isShowResendBtnToCreatePwd, isShowResendBtnToResetPwd}} = this.props;
    if (loginMessage) {
      return <Alert bsStyle="success">{loginMessage}</Alert>;
    }
    if (loginErrorMessage) {
      let resendBtn;
      if (isShowResendBtnToResetPwd) {
        resendBtn = <>The password reset link has expired.  Please click <HereBtn onClick={this.onResendRecoverPwd} title="Resend a password reset email">here</HereBtn> to resend.</>;
      }
      if (isShowResendBtnToCreatePwd) {
        resendBtn = <>Your password reset link has expired.  Please click <HereBtn onClick={this.onResendOnboardingEmail} title="Resend the onboarding email">here</HereBtn> to resend it.</>;
      }
      return <Alert bsStyle="danger">{resendBtn || loginErrorMessage}</Alert>;
    }
    return null;
  };

  render() {
    const {user} = this.props;
    return (
      <StyledLoginLayout>
        { user.showLoading && <Loader/> }
        <Header
          hideLinks
          />
        <div className="login_page">
          <Grid>
            <Row>
              <div className="userlogin-form">
                <div>Resillion US</div>
                {this.renderLoginMessage()}
                {this.props.children}
              </div>
            </Row>
          </Grid>
        </div>
        <GlobalStyled/>
      </StyledLoginLayout>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  html, body {
    height: 100%;
  }
`

const HereBtn = styled.span`
  color: #679dd7;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: #5a6378;
  }
`;

const StyledLoginLayout = styled.div`
  .login_page {
    float: left;
    text-align: center;
    margin: 0 0 17px;
    min-height: 460px;
    position: relative;
    height: calc(100vh - 223px);
    width: 100%;
    .container {
      display: table;
      height: 100%;
    }
    .row {
      display: table-cell;
      vertical-align: middle;
    }
  }
  
  .userlogin-form {
    background: #fbfdff;
    box-shadow: 0 0 5px 0 rgba(9, 40, 61, 0.5);
    box-sizing: border-box;
    display: inline-block;
    margin: 10px 0;
    padding: 40px 30px 30px;
    text-align: left;
    width: 375px;

    > div:first-child {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  
  .userlogin-form .forgot {
    display: block;
    float: left;
  }
  
  .userlogin-form .forgot a {
    color: #679dd7;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.7px;
    text-decoration: underline;
  }
  
  .userlogin-form .forgot a:hover {
    color: #5a6378;
  }
  
  .userlogin-form p {
    margin-bottom: 18px;
    position: relative;
    float: left;
    width: 100%;
    &.pwd-instructions {
      margin-top: 18px;
      margin-bottom: 0;
      font-size: 12px;
      span {
        white-space: nowrap;
      }
    }
  }
  
  .userlogin-form {
    .pwd-instructions {
      margin-top: 18px;
      margin-bottom: 0;
      position: relative;
      float: left;
      width: 100%;
      font-size: 12px;
      span {
        white-space: nowrap;
      }
    }
  }

  .userlogin-form label {
    font-size: 18px;
    color: #1b212e;
    font-weight: 700;
    margin-bottom: 7px;
    letter-spacing: 1px;
  }
  
  .userlogin-form p:first-child {
    margin-bottom: 25px;
  }
  
  .userlogin-form input[type="text"],
  .userlogin-form input[type="email"],
  .userlogin-form input[type="password"] {
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    padding: 10px 20px 10px 50px;
    height: 51px;
    width: 100%;
    &:invalid {
      background-color: #f0dada;
      border-color: #cf4040;
    }
    outline: 0;
  }
  
  .userlogin-form input[type="submit"] {
    background: #68a1d3;
    border: 0 none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    padding: 13px;
    text-transform: uppercase;
    transition: all 1s ease 0.0s;
    width: 100%;
    @media (max-width: 767px) {
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
  
  .userlogin-form input[type="submit"]:hover {
    background: #5a6378;
  }
  
  .userlogin-form input[type="text"]:focus,
  .userlogin-form input[type="email"]:focus,
  .userlogin-form input[type="password"]:focus {
    background-color: transparent;
    border-color: #68a1d3;
  }
  
  .text-icon {
    padding: 0 0 0 17px;
    position: absolute;
    left: 0;
    color: #c3c3c3;
    line-height: 1;
    font-size: 19px;
    bottom: 19%;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;

    .react-spinner {
      top: 50%;
      margin: -16px 0 0 -16px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .userlogin-form {
      margin: 40px 0;
      width: 90%;
    }
  }

  .totp-instructions {
    font-size: 12px;

    ul {
      padding-inline-start: 20px;
    }
  }
`;

LoginLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  title: PropTypes.string,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(withRouter(LoginLayout));
