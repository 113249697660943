import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ARTWORK_ALST_CLEAR,
  ARTWORK_ALST_TOGGLE_OPERATOR_MODE,
  ARTWORK_ALST_SET_PROJECTS_VIEW,
  ARTWORK_ALST_SET_OPENED_ASSET_ID,
  ARTWORK_ALST_SAVED_FILTER,
  ARTWORK_ALST_CHANGE_FILTER,
  ARTWORK_ALST_CHANGE_FILTER_TYPE,
  ARTWORK_ALST_ADD_FILTERS,
  ARTWORK_ALST_REMOVE_FILTERS,
  ARTWORK_ALST_SET_LOADING,
  ARTWORK_ALST_SET_FILTERS,
  ARTWORK_ALST_SET_PROJECTS,
  ARTWORK_ALST_UPDATE_PROJECT_ASSETS,
  ARTWORK_ALST_UPDATE_DETAIL,
  ARTWORK_ALST_ADD_DETAIL_ISSUE,
  ARTWORK_ALST_DELETE_DETAIL_ISSUE,
} from './actions';
import {
  NonNumericFilters,
  PROJECTS_VIEW__GALLERY
} from './utils';
import {isArtworkOperatorUser, isArtworkManagerUser} from '../../../utils/general';

const initialState = {
  loaders: {
    filters: false,
    projects: false,
    more: false,
  },

  allowedFilters: [],
  selectedFilters: [{
    name: 'search',
    value: null
  }, {
    name: 'sort',
    value: {}
  }],

  projectsView: PROJECTS_VIEW__GALLERY,
  projectsTotal: 0,
  projectsData: [],

  isOperatorMode: isArtworkOperatorUser() || isArtworkManagerUser(),

  openedAssetId: null,
  openedAssetProjectId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ARTWORK_ALST_CLEAR: {
      return {...initialState};
    }

    case ARTWORK_ALST_TOGGLE_OPERATOR_MODE: {
      return {
        ...state,
        isOperatorMode: action.modeIsOn || initialState.isOperatorMode
      };
    }

    case ARTWORK_ALST_SET_PROJECTS_VIEW: {
      return {
        ...state,
        projectsView: action.view || initialState.projectsView
      };
    }

    case ARTWORK_ALST_SET_OPENED_ASSET_ID: {
      let {assetId, projectId} = action;
      if (assetId && !projectId) {
        const {projectsData} = state;
        for (let p of projectsData) {
          const assets = (p.assets || {}).data || [];
          const assetIndex = assets.findIndex(a => a.asset_id === assetId);
          if (assetIndex >= 0) {
            projectId = assets[assetIndex].project_id;
            break;
          }
        }
      }
      return {
        ...state,
        openedAssetId: assetId || initialState.openedAssetId,
        openedAssetProjectId: projectId || initialState.openedAssetProjectId
      };
    }

    case ARTWORK_ALST_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ARTWORK_ALST_SAVED_FILTER: {
      const urlFilters = {...action.urlFilters};
      if (!urlFilters.search) {
        urlFilters.search = null;
      }
      if (!urlFilters.sort) {
        urlFilters.sort = {};
      }

      const nonNumericFilters = [
        ...NonNumericFilters,
        'from', 'to', 'state', ''
      ];
      const selectedFilters = [];
      Object.keys(urlFilters).forEach(key => {
        const value = Array.isArray(urlFilters[key]) && !nonNumericFilters.includes(key) ?
          urlFilters[key].map(item => !isNaN(+item) ? +item : item) :
          urlFilters[key];

        const res = {name: key, value};
        if (key === 'Last Updated') {
          res.disabled = true;
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      });

      return {
        ...state,
        selectedFilters
      }
    }

    case ARTWORK_ALST_CHANGE_FILTER: {
      const {selectedFilters} = state;
      const isFilterExist = !!selectedFilters.find(filter => filter.name === action.name);
      if (isFilterExist) {
        return {
          ...state,
          selectedFilters: selectedFilters.map(item => {
            return item.name === action.name ? {
              ...item,
              value: action.value || null
            } : item;
          })
        };
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters,
          {
            name: action.name,
            value: action.value
          }
        ]
      }
    }

    case ARTWORK_ALST_CHANGE_FILTER_TYPE: {
      return {
        ...state,
        selectedFilters: state.selectedFilters.map(item => {
          return item.name === action.filter.name ? {
            ...action.newSelectedFilter,
            title: action.newSelectedFilter.label || action.newSelectedFilter.title,
            value: action.newSelectedFilter.defaultValue ?
              (Array.isArray(action.newSelectedFilter.defaultValue) ? action.newSelectedFilter.defaultValue : [action.newSelectedFilter.defaultValue]) : null
          } : item;
        })
      };
    }

    case ARTWORK_ALST_ADD_FILTERS: {
      const selectedFilters = [...state.selectedFilters];

      const filterIndex = selectedFilters.findIndex(f => f.name === action.filter.name);
      if (filterIndex !== -1) {
        selectedFilters[filterIndex] = {
          ...selectedFilters[filterIndex],
          title: action.filter.label || action.filter.title,
          type: action.filter.type,
          multi: action.filter.multi,
          forFilterName: action.filter.forFilterName,
          hasAdditionalFilters: action.filter.hasAdditionalFilters,
          isStudioParameter: action.filter.isStudioParameter,
          studioID: action.filter.studioID,
          isOrderedValues: action.filter.isOrderedValues,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };
      } else {
        const res = {
          ...action.filter,
          name: action.filter.name,
          title: action.filter.label || action.filter.title,
          value: action.filter.defaultValue ?
           (Array.isArray(action.filter.defaultValue) ? action.filter.defaultValue : [action.filter.defaultValue]) : null,
          orderNum: action.filter.orderNum,
          disabled: action.filter.disabled
        };

        if (action.filter.required) {
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      }

      return {
        ...state,
        selectedFilters: selectedFilters.sort((a, b) => (a.orderNum || state.allowedFilters.length) - (b.orderNum || state.allowedFilters.length))
      };
    }

    case ARTWORK_ALST_REMOVE_FILTERS: {
      const selectedFilters = [...state.selectedFilters];
      return {
        ...state,
        selectedFilters: selectedFilters.filter(f => f.name !== action.filter.name)
      };
    }

    case ARTWORK_ALST_SET_FILTERS: {
      return {
        ...state,
        allowedFilters: action.allowedFilters
      }
    }

    case ARTWORK_ALST_SET_PROJECTS: {
      const {selectedFilters} = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map(item => {
          return item.name === 'search' && typeof item.value === 'string' ? {
            ...item,
            value: item.value.trim()
          } : item;
        }),
        loaders: {
          ...state.loaders,
          projects: false,
          more: false
        },
        projectsData: [...action.data || initialState.projectsData],
        projectsTotal: action.total || initialState.projectsTotal
      };
    }

    case ARTWORK_ALST_UPDATE_PROJECT_ASSETS: {
      const projectsData = [...state.projectsData];
      const {project: {project_id}, assets} = action;
      const projectIndex = projectsData.findIndex(p => p.project_id === project_id);
      if (projectIndex >= 0) {
        projectsData[projectIndex].assets = assets;
      }
      return {
        ...state,
        projectsData
      };
    }

    case ARTWORK_ALST_UPDATE_DETAIL: {
      const {projectsData} = state;
      for (let p of projectsData) {
        const assets = (p.assets || {}).data || [];
        const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
        if (assetIndex >= 0) {
          assets[assetIndex] = {...assets[assetIndex], ...action.data};
          break;
        }
      }
      return {
        ...state,
        projectsData
      }
    }

    case ARTWORK_ALST_ADD_DETAIL_ISSUE: {
      const {projectsData} = state;
      for (let p of projectsData) {
        const assets = (p.assets || {}).data || [];
        const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
        if (assetIndex >= 0) {
          const issuesCount = assets[assetIndex].issues_count || 0;
          assets[assetIndex] = {...assets[assetIndex],  issues_count: issuesCount + 1};
          break;
        }
      }
      return {
        ...state,
        projectsData
      }
    }

    case ARTWORK_ALST_DELETE_DETAIL_ISSUE: {
      const {projectsData} = state;
      for (let p of projectsData) {
        const assets = (p.assets || {}).data || [];
        const assetIndex = assets.findIndex(a => a.asset_id === action.assetId);
        if (assetIndex >= 0) {
          const issuesCount = assets[assetIndex].issues_count || 0;
          assets[assetIndex] = {...assets[assetIndex],  issues_count: Math.max(issuesCount - 1, 0)};
          break;
        }
      }
      return {
        ...state,
        projectsData
      }
    }

    default: {
      return state;
    }
  }
}
