/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {
  toggleSchedule, toggleEditScheduleModal, updateMilestoneDate, updateEditDateModal, lockRequest
} from '../../actions';
import Timeline from './Timeline';
import Schedule from './Schedule';
import EditScheduleModal from '../modals/EditScheduleModal';
import editImg from '../../../../../assets/images/request/edit.svg';
import arrowUpImg from '../../../../../assets/images/request/arrow-up.svg';

const TimelineCollapse = props => {
  const {requestId, onSelectRequest} = props;
  const dispatch = useDispatch();
  const reportDetails = useSelector(state => state.reportdetails);
  const {
    loaders, details, timeline, showSchedule, showEditScheduleModal, schedulingHeaders, milestones, editDateModal
  } = reportDetails;

  const handleRequestChanged = React.useCallback((requestId) => onSelectRequest(requestId), [onSelectRequest]);

  const handleToggleEditSchedule = () => {
    dispatch(toggleEditScheduleModal(!showEditScheduleModal));
  };

  const handleExpandClick = () => {
    dispatch(toggleSchedule(!showSchedule));
  };

  return (
    <StyledWrap>
      <div className="top-row">
        <div className="timeline">
          <Timeline
            requestId={requestId}
            onSelect={handleRequestChanged}
          />
        </div>
        <ul className="actions">
          {schedulingHeaders && schedulingHeaders.length ?
            <li>
              <a className="edit" onClick={handleToggleEditSchedule}>
                <img src={editImg} alt=""></img><span className="hidden-xs">Edit Schedule</span>
              </a>
            </li> : null
          }
          <li>
            <a className={`expand ${showSchedule ? 'in' : ''}`} onClick={handleExpandClick} title='Timeline'>
              <img src={arrowUpImg} alt="" />
            </a>
          </li>
        </ul>
      </div>
      {showSchedule ?
        <Schedule
          milestones={timeline}
        /> : null}
      {showEditScheduleModal ?
        <EditScheduleModal
          details={details}
          headers={schedulingHeaders}
          milestones={milestones}
          lockScheduling={details.lock_scheduling}
          editDateModal={editDateModal}
          loading={loaders.editScheduleModal}
          onUpdateModal={updateEditDateModal}
          onUpdateDate={updateMilestoneDate}
          onLockRow={lockRequest}
          onClose={handleToggleEditSchedule}
        /> : null}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  background-color: #f3f3f3;
  margin-bottom: 1px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  .top-row {
    display: flex;

    @media (max-width: 767px) {
      background: #F9F9F9;
      /*padding: 0;
      width: 100%;*/
      display: flex !important;
      justify-content: flex-end;
    }
  }

  .timeline {
    flex-grow: 1;
    overflow-x: hidden;
  }

  .actions {
    display: flex;
    margin: 0 15px 0 0;
    padding-left: 15px;

    @media (max-width: 767px) {
      margin-right: 3px;
      padding: 0;
    }

    li {
      list-style: none;

      @media (min-width: 768px) {
        &:last-of-type {
          border-left: 1px solid #7c7c7c;
        }
      }
    }

    a {
      align-items: center;
      display: flex;
      height: 31px;
      margin: 10px 22px;

      @media (max-width: 767px) {
        height: 30px;
        margin: 14px 15px;
      }
    }

    .edit {
      color: #282828;
      font-size: 10px;
      line-height: 19px;
      white-space: nowrap;
      img {
        margin: 0 7px;
      }
      @media (max-width: 767px) {
        margin-left: 10px;
        margin-right: 3px;
      }
    }
    .expand {
      transform: rotate(180deg);
      transition: none;
      &.in {
        transform: initial;
      }
      @media (max-width: 767px) {
        img {
          margin: 0 7px;
        }
      }
    }
  }
`;

TimelineCollapse.propTypes = {
  requestId: PropTypes.any.isRequired,
  onSelectRequest: PropTypes.func.isRequired
};

export default TimelineCollapse;