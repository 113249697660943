import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {prepareTopFiltersForUrl} from '../../actions';

class Request extends Component {
  getLinkTo = state => {
    const {request, period, statuses, topFilters} = this.props;
    let link = 'requests?';
    if (request.Status === 'Not Started') {
      link += `request_status[0]=${statuses.PENDING}&request_status[1]=${statuses.WAITING_FOR_ASSETS}&`;
    } else if (request.Status === 'In Progress') {
      link += `request_status[0]=${statuses.IN_PROGRESS}&`;
    } else if (request.Status === 'Completed') {
      link += `request_status[0]=${statuses.CLOSED}&request_status[1]=${statuses.NEEDS_FIXES}&`;
    }
    link += `state[0]=${state}&period[0]=${period}&last_updated[0]=4`;
    const preparedTopFilters = prepareTopFiltersForUrl(topFilters);
    if (preparedTopFilters) {
      link += '&' + preparedTopFilters;
    }
    return link;
  };

  render() {
    const {request, measure, otherOptions} = this.props;
    const clsName = request.Status.replace(' ', '-').toLowerCase();
    const otherCount = request['All'] - request['Overdue'] - request['At Risk'];
    return (
      <StyledRequest className={clsName}>
        <div className="progress-line">
          <Link
            to={this.getLinkTo('other')}
            className="other"
            style={{width: measure * (request['All'] - request['At Risk'] - request['Overdue'])}}
            title={`Other: ${otherCount}`}
          />
          <Fragment>
            {otherOptions.at_risk ?
              <Link
                to={this.getLinkTo('at_risk')}
                className="at-risk"
                style={{width: measure * request['At Risk']}}
                title={`At Risk: ${request['At Risk']}`}
              /> : null}
            {otherOptions.overdue ?
              <Link
                to={this.getLinkTo('overdue')}
                className="overdue"
                style={{width: measure * request['Overdue']}}
                title={`Overdue: ${request['Overdue']}`}
              /> : null}
          </Fragment>
        </div>
        <div className="count">
          {request.Status}
          <span>{request['All']}</span>
        </div>
        <div className="footer-legend">
          {otherOptions.at_risk ? <span className="at-risk">{request['At Risk']}</span> : null}
          {otherOptions.overdue ? <span className="overdue">{request['Overdue']}</span> : null}
        </div>
      </StyledRequest>
    );
  }
}

const StyledRequest = styled.div`
  &.not-started {
    min-width: 100px;
  }
  &.in-progress {
    min-width: 100px;
    .progress-line .other {
      background-color: #77D9F8;
    }
  }
  &.completed {
    border: 0;
    flex-grow: 1;
    min-width: 100px;
  }
  .progress-line {
    a {
      display: block;
      
      &.other {
        background-color: #D0D0D0;
        height: 100%;
      }
      &.at-risk {
        background-color: #FFC737;
        height: 100%;
      }
      &.overdue {
        background-color: #E20606;
        height: 100%;
      }
    }
  }
  .count {
    color: rgba(40, 40, 40, 0.5);
    font-size: 12px;
    line-height: 13px;
    padding: 12px 0 0 12px;
    height: 59px;
    width: 100%;
    span {
      color: #343434;
      display: block;
      font-size: 17px;
      font-weight: bold;
      line-height: 25px;
    }
  }
  .footer-legend {
    background-color: #f3f3f3;
    color: rgb(95, 95, 95, 0.6);
    display: flex;
    height: 25px;
    font-size: 12px;
    line-height: 22px;
    padding-left: 12px;
    span {
      border-top-width: 1px;
      border-top-style: solid;
      display: block;
      width: 23px;
      &.at-risk {
        border-color: #f9dd95;
      }
      &.overdue {
        border-color: #eb7d7d;
      }
    }
  }
`;

Request.propTypes = {
  request: PropTypes.object.isRequired,
  measure: PropTypes.number.isRequired,
  otherOptions: PropTypes.object,
  statuses: PropTypes.object.isRequired,
  topFilters: PropTypes.object.isRequired,
};

export default withRouter(Request);