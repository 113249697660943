import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {COLORED_FILTER_ID, setTopActiveTab, downloadAttachment} from '../../actions';
import {toggleShowSidebar} from '../../../../layouts/actions';
import Tabs, {getTabTitle} from '../../../../components/Tabs';
import {Tab, Row, Col} from 'react-bootstrap';
import InteractiveViewTab from './InteractiveViewTab';
import TableFilters from '../../../../components/filters/TableFilters';
import MobileFiltersModal from '../modals/MobileFiltersModal';
import IssueTable from '../IssueTable';
import listViewImg from '../../../../../assets/images/list-view.svg';
import interactiveViewImg from '../../../../../assets/images/interactive-view.svg';
import filterBtnImg from '../../../../../assets/images/filter-btn.svg';
import {isUserCanManage, isUserCanComment, isOperatorUser} from '../../../../utils/general';
import {
  validateTimeCodeSettings,
} from '../../utils';

class ViewTab extends Component {
  state = {
    showMobileFiltersModal: false
  };

  getHardingTestAttachment = () => {
    const {reportdetails: {details: {IsHardingTestRequest, attachments}}} = this.props;
    return !isOperatorUser() && IsHardingTestRequest && (attachments || []).find(a => (a.category || '').toLowerCase() === 'pse test');
  };

  isDisplayedHardingTestReport = (hardingTestAttachment) => {
    const {reportdetails: {activeTopTab}} = this.props;
    return !!hardingTestAttachment && String(activeTopTab) === '1';
  };

  toggleHardingTestReportClass = () => {
    const hardingTestAttachment = this.getHardingTestAttachment();
    try {
      const elm = document.getElementById('report-details--container');
      if (this.isDisplayedHardingTestReport(hardingTestAttachment)) {
        elm.classList.add('harding-test--report');
      } else {
        elm.classList.remove('harding-test--report');
      }
    } catch {}
  };

  componentDidMount() {
    const {activeTopTab} = this.props.reportdetails;
    this.ifViewTabShowSidebar(activeTopTab);
    this.toggleHardingTestReportClass();
  }

  componentDidUpdate(prevProps) {
    const {activeTopTab, details} = this.props.reportdetails;
    if (activeTopTab !== prevProps.reportdetails.activeTopTab ||
        (details || {}).RequestID !== (prevProps.reportdetails.details || {}).RequestID) {
      this.toggleHardingTestReportClass();
    }
  }

  handleDownloadAttachment = attachment => {
    const {dispatch, requestId} = this.props;
    dispatch(downloadAttachment(attachment.id, requestId))
  };

  handleChangeActiveTab = key => {
    const {dispatch, reportdetails, history, requestId, sidebarIsOpen} = this.props;
    if (reportdetails.activeTopTab !== key) {
      dispatch(setTopActiveTab(history, requestId, key));
      this.ifViewTabShowSidebar(key);
      if (sidebarIsOpen) {
        //dispatch(toggleShowSidebar(true));
      }
    }
  };

  ifViewTabShowSidebar(activeTopTab) {
    this.toggleShowSidebar(activeTopTab === 2);
  }

  toggleShowSidebar = isShow => {
    const {dispatch} = this.props;
    dispatch(toggleShowSidebar(isShow));
  };

  getFiltersEl = () => {
    const {reportdetails, onChangeFilters, onAddFilter, onChangeFilterType} = this.props;
    const {loaders} = reportdetails;
    return (
      <TableFilters
        data={reportdetails.details}
        loading={reportdetails.loading || reportdetails.loadingIssueComments || reportdetails.issueFiltersLoading ||
          loaders.issueImageModal || reportdetails.issuesLoading
        }
        coloredFilterName={COLORED_FILTER_ID}
        allowedFilters={reportdetails.allowedFilters}
        shownFilters={reportdetails.shownFilters}
        onChangeFilters={onChangeFilters}
        onChangeFilterType={onChangeFilterType}
        onAddFilter={onAddFilter}
      />
    );
  };

  showFilters = () => {
    this.setState({showMobileFiltersModal: true});
  };

  hideFilters = () => {
    this.setState({showMobileFiltersModal: false});
  };

  getMobileFiltersModal = () => {
    const {showMobileFiltersModal} = this.state;
    return showMobileFiltersModal ? (
      <div className="visible-xs">
        <MobileFiltersModal onClose={this.hideFilters}>
          {this.getFiltersEl()}
        </MobileFiltersModal>
      </div>
    ) : null;
  };

  render() {
    const {
      reportdetails, onClickFixNotes, onClickDiscuss, onClickViewIssue, onClickIssueImage, onClickEditIssue,
      onClickDeleteIssue, isEnabledInteractiveView, dispatch, requestId, onClickUndeleteIssue, onScrollToIssueOnSidebar,
      onClickDuplicateIssue
    } = this.props;
    const {loaders, details: {UserPermissionType, allow_edit_issues, IsHardingTestRequest}} = reportdetails;

    const hardingTestAttachment = this.getHardingTestAttachment();

    const listViewTitle = getTabTitle("List View");
    const interactiveViewTitle = getTabTitle("Live View");

    const loading = reportdetails.loading || reportdetails.issuesLoading || reportdetails.issueFiltersLoading;

    let interactiveTab = null;
    if (isEnabledInteractiveView && !IsHardingTestRequest) {
      interactiveTab = (
        <Tab eventKey={2} title={interactiveViewTitle} className="interactive-view">
          <Col md={2} className="hidden-xs">
            {this.getFiltersEl()}
          </Col>
          <Col md={10} className="version-tab-content">
            <InteractiveViewTab
              loading={loading}
              dispatch={dispatch}
              reportdetails={reportdetails}
              requestId={requestId}
              onScrollToIssueOnSidebar={onScrollToIssueOnSidebar}
            />
          </Col>
          {this.getMobileFiltersModal()}
        </Tab>
      )
    }

    const issueTableView = (
        <IssueTable
            loading={loading || reportdetails.loadingIssueComments || loaders.issueImageModal}
            data={reportdetails.issues}
            allowEditOnRequest={allow_edit_issues}
            isValidTimeCodeSettings={validateTimeCodeSettings(reportdetails.details)}
            canManage={isUserCanManage(UserPermissionType)}
            canComment={isUserCanComment(UserPermissionType)}
            onClickFixNotes={onClickFixNotes}
            onClickDiscuss={onClickDiscuss}
            onClickView={onClickViewIssue}
            onClickImage={onClickIssueImage}
            onClickEdit={onClickEditIssue}
            onClickDuplicate={onClickDuplicateIssue}
            onClickDelete={onClickDeleteIssue}
            onClickUndelete={onClickUndeleteIssue}
            isEnabledInteractiveView={isEnabledInteractiveView}
            emptyText="No Data"
        />
    );

    let listViewTabContent;
    if (IsHardingTestRequest) {
      if (hardingTestAttachment) {
        listViewTabContent = (
          <StyledDownloadReport
            onClick={() => this.handleDownloadAttachment(hardingTestAttachment)}
          >
            Download Report
          </StyledDownloadReport>
        );
      } else {
        listViewTabContent = (
          <Row>
            <Col md={10} className="version-tab-content pse-test-report">
              { issueTableView }
            </Col>
          </Row>
        )
      }
    } else {
      listViewTabContent = (
        <Row>
          <Col md={2} className="hidden-xs">
            { this.getFiltersEl() }
          </Col>
          <Col md={10} className="version-tab-content">
            { issueTableView }
          </Col>
          { this.getMobileFiltersModal() }
        </Row>
      );
    }

    return (
      <StyledTabs
        id="tabs"
        className={this.isDisplayedHardingTestReport(hardingTestAttachment) ? 'harding-test--report--tabs' : undefined}
        activeKey={reportdetails.activeTopTab}
        onSelect={this.handleChangeActiveTab}
        animation={false}
      >
        <Tab eventKey={1} title={listViewTitle} className="list-view">
          { listViewTabContent }
        </Tab>
        { interactiveTab }
        <i className="visible-xs filter-btn" onClick={this.showFilters}/>
      </StyledTabs>
    );
  }
}

const StyledTabs = styled(Tabs)`
  #tabs-tab-1 .tab-title {
    background-image: url(${listViewImg});
    background-position: left 9px center;
  }
  #tabs-tab-2 .tab-title {
    background-image: url(${interactiveViewImg});
    background-position: left 4px center;
  }

  &.harding-test--report--tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      align-items: flex-start;
    }
    .tab-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding: 10px 0 0;
      width: 100%;
      min-height: 60px;
    }
  }

  @media (max-width: 768px) {
    .filter-btn {
      background: url(${filterBtnImg}) no-repeat center center;
      position: absolute;
      top: -25px;
      right: 0;
      height: 20px;
      width: 20px;
    }
    .version-tab-content {
      margin: 0 -15px;
    }
  }

  .pse-test-report {
    margin: 0 auto;
    float: unset;
  }
`;

const StyledDownloadReport = styled.span`
  color: #29a4cb;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #228daf;
  }
`;

ViewTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  requestId: PropTypes.any.isRequired,
  reportdetails: PropTypes.object.isRequired,
  isEnabledInteractiveView: PropTypes.bool.isRequired,
  onClickFixNotes: PropTypes.func.isRequired,
  onClickDiscuss: PropTypes.func.isRequired,
  onClickViewIssue: PropTypes.func.isRequired,
  onClickIssueImage: PropTypes.func.isRequired,
  onClickEditIssue: PropTypes.func.isRequired,
  onClickDuplicateIssue: PropTypes.func.isRequired,
  onClickDeleteIssue: PropTypes.func.isRequired,
  onClickUndeleteIssue: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  onScrollToIssueOnSidebar: PropTypes.func.isRequired
};

export default ViewTab;
