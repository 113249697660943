import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal, StyledModalSubmitFooter
} from '../../../../styled/ModalStyled';
import {
  toggleImportIssuesModal, getIssuesToImportFromAnotherReport, importIssuesFromAnotherReport
} from '../../actions.js';
import Loader from '../../../../components/Loader';
import {Button, Row, Col} from 'react-bootstrap';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import ImportIssuesTable from '../ImportIssuesTable';
import {confirmAlert} from 'react-confirm-alert';

const STEPS = {
  VERSION: 1,
  ISSUES: 2
};

const ImportIssuesModal = props => {
  const {dispatch, history, match, reportdetails: {details}} = props;
  const requestId = Number(match.params.requestId);
  const versions = (details.versions_for_import || []).filter(v => v.RequestID !== requestId && v.RequestID !== details.ParentID)
    .map(v => ({...v, VersionName: `${v.Title} ${v.VersionName || ''} (${v.WOMemfis || 'no work order'})`}));

  const prevRevisions = (details.prev_request_list || []).filter(v => v.RequestID !== requestId)
    .map(v => ({...v, RevisionName: `${v.ReplacementNumber ? 'Replacement ' + v.ReplacementNumber : 'Original'} (${v.WOMemfis || 'no work order'})`}));

  const [loading, setLoading] = useState(false);
  const [step, setCurrentStep] = useState(STEPS.VERSION);
  const [enteredWoNum, setEnteredWoNum] = useState('');
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedPrevRevision, setSelectedPrevRevision] = useState(null);
  const [allIssues, setAllIssues] = useState([]);
  const [checkedIssues, setCheckedIssues] = useState([]);

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  const handleClose = () => dispatch(toggleImportIssuesModal(false));

  const handleBackToFirstStep = () => setCurrentStep(STEPS.VERSION);

  const handleFindIssues = () => {
    setLoading(true);
    dispatch(getIssuesToImportFromAnotherReport(requestId, enteredWoNum, selectedVersion, selectedPrevRevision))
      .then(
        res => {
          setLoading(false);
          setAllIssues(res.issues);
          setCheckedIssues(res.issues.map(i => i.IssueID));
          setCurrentStep(STEPS.ISSUES);
        },
        error => {
          setLoading(false);
          setAllIssues([]);
          setCheckedIssues([]);
          setCurrentStep(STEPS.ISSUES);
        }
      );
  };

  const handleImportIssues = () => {
    setLoading(true);
    // temp hack
    let importRequestId = allIssues[0].RequestID;
    dispatch(importIssuesFromAnotherReport(history, requestId, importRequestId, checkedIssues))
      .then(() => {}, error => setLoading(false));
  };

  const handleImportIssuesButton = () => {
    const title = enteredWoNum || (selectedVersion && versions.find(v => v.RequestID === selectedVersion).VersionName) || (selectedPrevRevision && prevRevisions.find(v => v.RequestID === selectedPrevRevision).RevisionName);
    confirmAlert({
      title: 'Are You Sure?',
      childrenElement: () => (
        <div style={{fontSize: '13px', lineHeight: '17px', wordBreak: 'break-word'}}>
          You're about to import <span style={{fontWeight: 500}}>
          {checkedIssues.length} issue{checkedIssues.length > 1 ? 's' : ''}</span> from <span style={{fontWeight: 500}}>
          {title}</span>.  Are you sure?
        </div>
      ),
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: handleImportIssues
      }]
    });
  };

  return (
    <StyledMobileModal>
      <StyledModalBackdrop
        onClick={handleClose}
      />
      <StyledModalDialog className="mobile-modal-dialog-custom">
        {loading && <Loader className="full-screen"/>}
        <StyledModal>
          <StyledModalHeader className="modal-header-custom">
            <div className="back-btn" onClick={step === STEPS.VERSION ? handleClose : handleBackToFirstStep}/>
            <div className="modal-header-content">
              <h4>Import Issues</h4>
              <div>
                <StyledBaseCloseModal className="icon-close-modal" title="Cancel Import" onClick={handleClose}/>
              </div>
            </div>
          </StyledModalHeader>
          <StyledModalBody className={`modal-body-custom step-${step === STEPS.VERSION ? 'version' : 'issues'}`}>
            {step === STEPS.VERSION ?
              <>
                <Row>
                  <Col md={4} xs={12}>
                    <InputAdvanced
                      labelSeparator=""
                      label="WO#"
                      value={enteredWoNum}
                      forceReset
                      onChange={(_, _v) => setEnteredWoNum(_v)}
                      onBlur={(_, _v) => {
                        _v = _v.trim();
                        setEnteredWoNum(_v);
                        if (_v) {
                          setSelectedVersion(null);
                          setSelectedPrevRevision(null);
                        }
                      }}
                    />
                  </Col>
                </Row>
                {!!versions.length &&
                  <>
                    <Row>
                      <Col md={4} xs={12}>
                        <div style={{margin: '5px 0', textAlign: 'center', color: '#757575'}}>OR</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} xs={12}>
                        <SelectAdvanced
                          labelSeparator=""
                          label="Version:"
                          value={selectedVersion}
                          valueKey="RequestID"
                          labelKey="VersionName"
                          options={versions}
                          onSelect={(_, _v) => {
                            setSelectedVersion(_v);
                            if (_v) {
                              setEnteredWoNum('');
                              setSelectedPrevRevision(null);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                }
                {!!details.ParentID &&
                  <>
                    <Row>
                      <Col md={4} xs={12}>
                        <div style={{margin: '5px 0', textAlign: 'center', color: '#757575'}}>OR</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} xs={12}>
                        <SelectAdvanced
                          labelSeparator=""
                          label="Previous revision:"
                          value={selectedPrevRevision}
                          valueKey="RequestID"
                          labelKey="RevisionName"
                          options={prevRevisions}
                          onSelect={(_, _v) => {
                            setSelectedPrevRevision(_v);
                            if (_v) {
                              setEnteredWoNum('');
                              setSelectedVersion(null);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                }
              </> :
                <ImportIssuesTable
                  data={allIssues}
                  checkedIssues={checkedIssues}
                  onCheckIssues={setCheckedIssues}
                />
            }
          </StyledModalBody>
          <StyledSubmitModalFooter
            disabled={step === STEPS.VERSION ? (!enteredWoNum.trim() && !selectedVersion && !selectedPrevRevision) : !checkedIssues.length}
          >
            <div>
              {
                step === STEPS.VERSION ?
                  <Button
                    bsStyle="primary"
                    onClick={handleFindIssues}
                    disabled={!enteredWoNum.trim() && !selectedVersion && !selectedPrevRevision}
                  >Find Issues</Button> :
                    <Button
                      bsStyle="primary"
                      onClick={handleImportIssuesButton}
                      disabled={!checkedIssues.length}
                    >Import Issues</Button>
              }
              <Button bsStyle="default" onClick={handleClose}>Cancel Import</Button>
              {step === STEPS.ISSUES && <Button bsStyle="default" onClick={handleBackToFirstStep}>Back to Find Issues</Button>}
            </div>
          </StyledSubmitModalFooter>
        </StyledModal>
      </StyledModalDialog>
    </StyledMobileModal>
  );
};

const StyledModalHeader = styled.div`
  padding: 31px 35px 0 45px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    padding: 8.5px 0 8.5px 23px;
    @media (max-width: 767px) {
      padding:0 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  position: fixed;
  top: 104px;
  bottom: 71px;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 46px;
  padding-right: 27px;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
    &.step-issues {
      overflow-y: hidden;
      .table-container > div {
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          -webkit-box-shadow: none;
          background-color: #c4c4c4;
        }
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    bottom: 83px;
    padding: 0;
    &.step-version {
      padding: 0 23px;
    }
  }

  .loader-container {
    z-index: 1070;
  }
`;

const StyledSubmitModalFooter = styled(StyledModalSubmitFooter)`
  background-color: #F4F4F4;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  > div {
    float: none;
  }
  button {
    &.btn-primary {
      @media (min-width: 768px) {
        min-width: 167px;
      }
    }
    &.btn-default {
      margin-right: 0;
      margin-left: 18px;
    }
  }
`;

ImportIssuesModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(ImportIssuesModal));
