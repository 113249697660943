import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectAdvanced from '../../../components/forms/SelectAdvanced';

class Filters extends Component {
  getItemClass(item) {
    const {selectedSeasonId} = this.props;
    return `${+item.QcProjectID === +selectedSeasonId ? 'active' : ''}`;
  }

  onItemClick(item) {
    const {selectedSeasonId, onChangeFilter} = this.props;
    if (+item.QcProjectID !== +selectedSeasonId) {
      onChangeFilter(item.QcProjectID);
    }
  }

  render() {
    const {seasons, selectedSeasonId} = this.props;
    const seasonsOptions = (seasons || []).map(item => ({...item, value: +item.QcProjectID}));
    return (
      <StyledFilters>
        <ul className="hidden-xs">
          {seasons && seasons.length ? seasons.map((item, index) =>
            <li
              key={index}
              className={this.getItemClass(item)}
              onClick={() => this.onItemClick(item)}
            >{item.Title}</li>
          ) : null}
        </ul>
        <SelectAdvanced
          className="visible-xs"
          placeholder=""
          value={seasonsOptions.length ? +selectedSeasonId : null}
          options={seasonsOptions}
          labelKey="Title"
          onSelect={(_, id) => this.onItemClick(seasonsOptions.find(item => item.value === id))}
          searchable={false}
        />
      </StyledFilters>
    )
  }
}

const StyledFilters = styled.div`
  margin: 26px 10px 0 35px;
  ul {
    border-bottom: 0.5px solid #cbcbcb;
    list-style: none;
    padding: 0 0 10px;
    > li {
      cursor: pointer;
      font-size: 10px;
      margin-bottom: 2px;
      padding: 7px;
      &.active, &:hover {
        background: #585858;
        border-radius: 3px;
        color: #fff;
      }
      &.active:hover {
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 767px) {
    margin: -85px 25px 0;

    .Select {
      * {
        color: #282828 !important;
        font-size: 13px !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .Select-control {
        border: 0.5px solid #DADADA;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        height: 30px;
        .Select-value {
          line-height: 28px;
          padding-right: 39px;
        }
        .Select-input {
          display: none !important;
        }
        .Select-arrow-zone {
          opacity: 0.6;
          padding-right: 23px;
          width: 32px;
          img {
            width: 9px;
          }
        }
      }
      .Select-menu-outer {
        max-height: 150px;
        overflow: auto;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-color: #DADADA;
      }
      &:not(.is-open) {
        .Select-control {
          background-color: #F2F2F2 !important;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
          .Select-value .Select-value-label {
            opacity: 0.7;
          }
        }
      }
    }
  }
`;

Filters.propTypes = {
  seasons: PropTypes.array,
  selectedSeasonId: PropTypes.number,
  onChangeFilter: PropTypes.func.isRequired
};

export default Filters;
