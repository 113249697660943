import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {formatDate} from '../../../../utils/general';

class RequestList extends Component {
  render() {
    const {list} = this.props;
    const data = list && list.length >= 5 ? list.slice(0, 5) : list;
    return (
      <ListStyled>
        {list && list.length ? data.map((item, index) => {
          const {RequestID, Title, RequestStatusColor, RequestStatus, DueDate} = item;
          const backgroundColor = RequestStatusColor ? `#${RequestStatusColor}` : RequestStatusColor;
          return (
            <li key={index} className="item">
              <div className="item-title-status">
                <Link title="Open Report" to={`/requests/${RequestID}`} className="item-title">{Title}</Link>
                <Button style={{backgroundColor}}>{RequestStatus}</Button>
              </div>
              <div className="item-body">
                Submitted <b>{formatDate(item.SubmittedOn, '', 'MMMM D, YYYY HH:mm A')}</b>
                <br/><span className="eta">ETA</span> <b>{DueDate ? formatDate(DueDate, '', 'MMMM D, YYYY HH:mm A') : 'N/A'}</b>
              </div>
            </li>
          )
        }) : null}
      </ListStyled>
    );
  }
}

const ListStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  .item {
    border-bottom: 1px solid #D3D3D3;
    padding: 20px 18px 20px 21px;
  }
  .item-title-status {
    display: flex;
    justify-content: space-between;
  }
  .item-title {
    color: #29A4CB;
    font-weight: bold;
    font-size: 13px;
  }
  .btn {
    background: #444444;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    float: right;
    font-size: 10px;
    margin-left: 15px;
    padding: 3px 8px;
    min-width: fit-content;
    max-height: 20px;
    white-space: nowrap;
    pointer-events: none;
  }
  .item-body {
    font-size: 11px;
    margin-top: 7px;
    @media (min-width: 992px) {
      br {
        display: none;
      }
      .eta {
        margin-left: 10px;
      }
    }
  }
`;

RequestList.propTypes = {
  list: PropTypes.array
};

export default RequestList;
