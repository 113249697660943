import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListCell = (props) => {
  const {value, className, title} = props;
  const items = typeof value === 'string' ? value.split(/\n/g) : null;
  return (
    <StyledListCell
      className={className}
      title={title}
    >
      {
        items && items.length > 1 ? <ul>{items.map((t, ti) => <li key={`ct--${ti}`}>{t}</li>)}</ul> : value
      }
    </StyledListCell>
  );
};

const StyledListCell = styled.td`
  ul {
    padding-inline-start: 14px;
    li {
      white-space: nowrap;
    }
  }
`;

ListCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string
};

export default ListCell;
