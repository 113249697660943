import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import {getSortedRowsBy} from '../../../../utils/general';

const DownloadCell = ({className, row, onClick}) => (
  <td className={className}>
    <span
      title="Download"
      onClick={() => onClick(row)}
    >
      Download
    </span>
  </td>
);

const AdditionalReportsTable = props => {
  const {data, onDownload} = props;

  const [tableSort, setTableSort] = useState({});

  const handleHeaderCellClick = (field, direction) => {
    if (field !== 'id') {
      setTableSort({field, direction});
    }
  };

  const headers = {
    category: {
      title: 'Type',
      className: 'attachment-type',
      sortPlaceholder: <span className="icon-sorting"/>
    },
    filename: {
      title: 'File Name',
      className: 'attachment-filename',
      sortPlaceholder: <span className="icon-sorting"/>
    },
    id: {
      title: '',
      className: 'attachment-download',
      component: DownloadCell,
      componentArgs: {
        onClick: onDownload
      }
    },
  };

  return (
    <div className="table-container midl_in">
      <TableContainer>
        <Table
          headers={headers}
          rows={getSortedRowsBy(data, tableSort.field, tableSort.direction)}
          sort={tableSort}
          onHeaderCellClick={handleHeaderCellClick}
          emptyText="No Reports"
        />
      </TableContainer>
    </div>
  );
};

const TableContainer = styled.div`
  padding-bottom: 15px;
  max-width: 100%;
  overflow: auto;
  @media (min-width: 768px) {
    width: fit-content;
    min-width: 33%;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;

    th, td {
      border: none;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      color: #282828;
      text-align: left;
    }

    th {
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      &:hover {
        color: #6a6a6a;
      }
      &.attachment-download {
        pointer-events: none;
      }
      span.icon-sorting {
        visibility: hidden;
      }
    }

    td {
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      &.data-table-empty {
        width: 100% !important;
        text-align: center !important;
        @media (max-width: 767px) {
          text-align: left !important;
        }
      }
      &.attachment-filename {
        word-break: break-all;
      }
      &.attachment-download {
        span {
          color: #29a4cb;
          cursor: pointer;
          &:hover {
            color: #228daf;
          }
        }
      }
    }
  }
`;

AdditionalReportsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default AdditionalReportsTable;
