import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {showAddIssueModal} from '../../actions.js';
import {StyledCloseModal} from '../../../../styled/ModalStyled';
import {Modal, Button, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import Draggable from 'react-draggable';
import Loader from '../../../../components/Loader';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import {getSecondsFromTimeCodeSmpte, getTimeCodeForVideoPlayer} from '../../../../utils/general';
import backImg from '../../../../../assets/images/back.svg';

const MODAL_WIDTH = 402;

const ISSUE_STATUSES_PENDING = 9;
const FORMAT_ISSUE_CATEGORY_ID = 3;

const getFrameRateFromProps = props => {
  const {reportdetails: {details: {request_proxy_timecode_settings: {frameRate, isDropFrame}}}} = props;
  return {frameRate, isDropFrame};
};

const getStartFileOffsetSecondsFromProps = props => {
  const {reportdetails: {details: {request_proxy_timecode_settings: {startFileOffsetSeconds}}}} = props;
  return startFileOffsetSeconds;
};

export const getPlayerCurrentTimeCode = props => {
  let formattedValue;
  try {
    try {
      formattedValue = document.getElementsByTagName('video')[0].nextElementSibling.nextElementSibling.getElementsByClassName('timecode-item')[0].textContent;
    } catch {}
    if (!formattedValue) {
      const currentPlayerTime = document.getElementsByTagName('video')[0].currentTime;
      if (currentPlayerTime) {
        formattedValue = getTimeCodeForVideoPlayer(currentPlayerTime, getFrameRateFromProps(props), getStartFileOffsetSecondsFromProps(props));
      }
    }
  } catch {}
  return formattedValue;
};

class AddIssueModal extends Component {

  isEditingIssue = issue_id => !!issue_id && issue_id > 0;

  isDuplicationIssue = issue => {
    const currentIssue = issue || this.props.currentIssue;
    return !!(currentIssue || {}).__isDuplicate && this.isEditingIssue(currentIssue.IssueID);
  };

  isIssueForCaptionCue = issue => !!issue && !!issue.forCaptionCueID && !!issue.forCaptionCueID > 0;

  getCaptionsCategoryId = () => {
    const {reportdetails: {filters: {issue_categories_and_types}}} = this.props;
    return ((issue_categories_and_types || []).find(c => c.name === 'Captions') || {}).value;
  };

  getFrameRate = () => {
    return getFrameRateFromProps(this.props);
  };

  getStartFileOffsetSeconds = () => {
    return getStartFileOffsetSecondsFromProps(this.props);
  };

  getMinTimecode = () => {
    const {reportdetails: {details: {request_proxy_timecode_settings: {startFileTimecode}}}} = this.props;
    return this.getStartFileOffsetSeconds() ? startFileTimecode : '00:00:00:00';
  };

  getMessageForOutTime = (tc1, tc2) => {
    return tc1 && tc2 && getSecondsFromTimeCodeSmpte(tc2, this.getFrameRate()) < getSecondsFromTimeCodeSmpte(tc1, this.getFrameRate()) ? '"Out Time" less than "In Time"' : null;
  };

  getMessageForTimeRelativelyStartFile = seconds => {
    const minSeconds = this.getStartFileOffsetSeconds() || 0;
    return seconds < minSeconds ? `Time less than start of file (${this.getMinTimecode()})` : null;
  };

  prepareStartData = (currentIssue) => {
    return currentIssue && (this.isEditingIssue(currentIssue.IssueID) || currentIssue.select_mode) ? {
      issue_id: currentIssue.IssueID,
      issue_category_id: currentIssue.CategoryID,
      issue_type_id: currentIssue.IssueTypeID,
      severity_id: currentIssue.SeverityID,
      status_id: currentIssue.StatusID,
      issue_location_id: currentIssue.LocationID,
      issue_channel: currentIssue.Channel,
      issue_description: currentIssue.Description,
      in_time: {
        message: null,
        reset: currentIssue.TimeCodeStart !== null,
        value: currentIssue.TimeCodeStart && currentIssue.TimeCodeStart
      },
      out_time: {
        message: this.getMessageForOutTime(currentIssue.TimeCodeStart, currentIssue.TimeCodeEnd),
        reset: currentIssue.TimeCodeEnd !== null,
        value: currentIssue.TimeCodeEnd && currentIssue.TimeCodeEnd
      }
    } : {
      issue_id: null,
      issue_category_id: (this.isIssueForCaptionCue(currentIssue) && this.getCaptionsCategoryId()) || null,
      issue_type_id: null,
      severity_id: null,
      status_id: ISSUE_STATUSES_PENDING,
      issue_location_id: null,
      issue_channel: null,
      issue_description: null,
      in_time: (this.isIssueForCaptionCue(currentIssue) ? {
        reset: currentIssue.TimeCodeStart !== null,
        value: currentIssue.TimeCodeStart && currentIssue.TimeCodeStart
      } : {reset: false, value: ''}),
      out_time: (this.isIssueForCaptionCue(currentIssue) ? {
        reset: currentIssue.TimeCodeEnd !== null,
        value: currentIssue.TimeCodeEnd && currentIssue.TimeCodeEnd
      } : {reset: false, value: ''})
    };
  };

  state = this.prepareStartData(this.props.currentIssue);

  changeInTimeFieldValue = () => {
    const {currentIssue} = this.props;
    if (this.isIssueForCaptionCue(currentIssue)) {
      return;
    }

    const formattedValue = getPlayerCurrentTimeCode(this.props);
    if (formattedValue && formattedValue !== this.getMinTimecode()) {
      if ('start' === currentIssue.select_mode) {
        const newValues = {
          ...this.state,
          in_time: {
            reset: true,
            value: formattedValue
          },
          out_time: {
            ...this.state.out_time,
            message: this.getMessageForOutTime(formattedValue, this.state.out_time.value),
          }
        };
        this.setState(newValues);
        return;
      }
      if ('end' === currentIssue.select_mode) {
        const newValues = {
          ...this.state,
          out_time: {
            message: this.getMessageForOutTime(this.state.in_time.value, formattedValue),
            reset: true,
            value: formattedValue
          }
        };
        this.setState(newValues);
        return;
      }

      const newValues = {
        ...this.state,
        in_time: {
          reset: true,
          value: formattedValue
        },
        out_time: {
          message: this.getMessageForOutTime(this.state.in_time.value, formattedValue),
          reset: true,
          value: formattedValue
        }
      };
      this.setState(newValues);
    }
  };

  componentDidMount() {
    const {body} = document;
    body.className += ' modal-open--overflow-auto';

    const {currentIssue} = this.props;
    const isCreateNewIssue = !this.isEditingIssue((currentIssue || {}).IssueID);
    if (isCreateNewIssue || (currentIssue && currentIssue.select_mode)) {
      this.changeInTimeFieldValue();
    }

    const timer = setInterval(() => {
      const modalDialog = document.getElementsByClassName('modal-dialog--edit-issue')[0];
      if (modalDialog) {
        try {
          if (window.innerHeight > modalDialog.clientHeight) {
            modalDialog.parentElement.style.top = `${Math.floor((window.innerHeight - modalDialog.clientHeight) / 2)}px`;
          }
          clearInterval(timer);
        } catch (e) {
          clearInterval(timer);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {currentIssue} = this.props;
    if ((JSON.stringify(currentIssue) !== JSON.stringify(prevProps.currentIssue))) {
      this.setState(this.prepareStartData(currentIssue));
      this.changeInTimeFieldValue();
    }
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace('modal-open--overflow-auto', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showAddIssueModal(false));
  };

  handleSave = () => {
    const {
      issue_id, issue_category_id, issue_type_id, severity_id, issue_location_id, issue_description, in_time, out_time,
      status_id, issue_channel
    } = this.state;
    const values = {issue_id, issue_category_id, issue_type_id, severity_id, issue_location_id, issue_description,
      status_id, issue_channel
    };
    if (this.isDuplicationIssue()) {
      values.is_duplicate = true;
    }
    values.in_time = in_time.value;
    values.out_time = out_time.value;

    this.props.saveIssue(values);
  };

  handleFieldValueChanged = (name, value) => {
    if (value === '') {
      value = null;
    }
    const values = {...this.state, [name]: value, invalid_issue_type: false};
    if (name === 'issue_category_id') {
      if (values.issue_type_id) {
        values.invalid_issue_type = true;
      }
      values.issue_type_id = null;
    }
    this.setState(values);
  };

  handleTextareaBlur = event => {
    this.handleFieldValueChanged(event.target.name, event.target.value);
  };

  handleChangeTime = (name, value) => {
    if (value.match(/^[0-9:]+$/) && !value.match(/^[:]+$/)) {
      this.handleFieldValueChanged(name, {reset: false, value, message: null});
    } else {
      this.handleFieldValueChanged(name, {reset: value !== this.state[name].value, value: value.replace(/[^0-9:]/g, ''), message: null});
    }
  };

  handleBlurTime = (name, newValue) => {
    let seconds = getSecondsFromTimeCodeSmpte(newValue, this.getFrameRate(), -1);
    if (seconds !== -1) {
      const {in_time, out_time} = this.state;
      const message = this.getMessageForTimeRelativelyStartFile(seconds);
      if (name === 'in_time') {
        const messageForOutTime = this.getMessageForOutTime(newValue, out_time.value);
        this.setState({
          ...this.state,
          in_time: {
            ...in_time,
            message
          },
          out_time: {
            ...out_time,
            message: this.getMessageForTimeRelativelyStartFile(getSecondsFromTimeCodeSmpte(out_time.value, this.getFrameRate())) || messageForOutTime
          }
        });
      } else {
        this.setState({
          ...this.state,
          out_time: {
            ...out_time,
            message: message || this.getMessageForOutTime(in_time.value, newValue)
          }
        });
      }
    } else {
      this.handleFieldValueChanged(name, {reset: newValue !== '', value: '', message: ''});
    }
  };

  handleStartTimecodeSelectMode = () => {
    const issue = {
      IssueID: this.state.issue_id,
      CategoryID: this.state.issue_category_id,
      IssueTypeID: this.state.issue_type_id,
      SeverityID: this.state.severity_id,
      StatusID: this.state.status_id,
      LocationID: this.state.issue_location_id,
      Channel: this.state.issue_channel,
      Description: this.state.issue_description,
      TimeCodeStart: this.state.in_time.value,
      TimeCodeEnd: this.state.out_time.value,
      select_mode: 'start'
    }
    this.props.handleSelectMode(issue);
  };

  handleEndTimecodeSelectMode = () => {
    const issue = {
      IssueID: this.state.issue_id,
      CategoryID: this.state.issue_category_id,
      IssueTypeID: this.state.issue_type_id,
      SeverityID: this.state.severity_id,
      StatusID: this.state.status_id,
      LocationID: this.state.issue_location_id,
      Channel: this.state.issue_channel,
      Description: this.state.issue_description,
      TimeCodeStart: this.state.in_time.value,
      TimeCodeEnd: this.state.out_time.value,
      select_mode: 'end'
    }
    this.props.handleSelectMode(issue);
  };

  render() {
    const {reportdetails} = this.props;
    const {saving, filters} = reportdetails;
    const {issue_categories_and_types, issue_severities, issue_locations, issue_statuses} = filters;
    const {
      issue_id, severity_id, issue_description, issue_category_id, issue_type_id, issue_location_id, in_time, out_time,
      status_id, invalid_issue_type, issue_channel
    } = this.state;
    const isEditing = this.isEditingIssue(issue_id);
    const isDuplication = this.isDuplicationIssue();
    const isDisabledSeverity = isDuplication;
    const isDisabledStatus = !isEditing || isDuplication;
    const isDisabledChannel = isDuplication;
    const isDisabledDescription = isDuplication;
    const isDisabledSave = !issue_category_id
                        || (!isDuplication && issue_category_id !== FORMAT_ISSUE_CATEGORY_ID && !issue_description)
                        || !issue_location_id
                        || (!isDuplication && !severity_id)
                        || !issue_type_id
                        || !!(in_time.message || out_time.message)
                        || (!isDuplication && !status_id);
    const issueTypes = (issue_categories_and_types.find(c => c.value === issue_category_id) || {}).issue_types || [];
    return (
      <Draggable handle=".modal-header">
        <StyledModal
          animation={false}
          backdrop={false}
          show={true}
          onHide={this.handleClose}
          dialogClassName="modal-dialog--edit-issue"
          >
          <Modal.Header>
            <div className="back-btn" onClick={() => this.handleClose()}/>
            <h4>{isDuplication ? 'Duplicate' : isEditing ? 'Edit' : 'Add'} Issue</h4>
            <StyledCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
          </Modal.Header>
          <Modal.Body>
            {saving ? <Loader className="full-screen"/> : null}
            <SelectAdvanced
              label="Category"
              labelSeparator=""
              name="issue_category_id"
              placeholder="Select Category"
              options={issue_categories_and_types}
              value={issue_category_id}
              onSelect={this.handleFieldValueChanged}
            />
            <SelectAdvanced
              className={invalid_issue_type ? 'invalid' : undefined}
              label="Type"
              labelSeparator=""
              name="issue_type_id"
              placeholder="Select Type"
              options={issueTypes}
              value={issue_type_id}
              onSelect={this.handleFieldValueChanged}
            />
            <SelectAdvanced
              className={isDisabledSeverity ? 'form-group--is-disabled' : undefined}
              label="Severity"
              labelSeparator=""
              name="severity_id"
              placeholder={isDisabledSeverity ? "" : "Select Severity"}
              options={issue_severities}
              value={severity_id}
              onSelect={this.handleFieldValueChanged}
              disabled={isDisabledSeverity}
            />
            <SelectAdvanced
              className={isDisabledStatus ? 'form-group--is-disabled' : undefined}
              label="Status"
              labelSeparator=""
              name="status_id"
              placeholder={isDisabledStatus ? "" : "Select Status"}
              options={issue_statuses}
              value={status_id}
              onSelect={this.handleFieldValueChanged}
              disabled={isDisabledStatus}
            />
            <SelectAdvanced
              label="Location"
              labelSeparator=""
              name="issue_location_id"
              placeholder="Select Location"
              options={issue_locations}
              value={issue_location_id}
              onSelect={this.handleFieldValueChanged}
            />
            <InputAdvanced
              formGroupClassName={isDisabledChannel ? 'form-group--is-disabled' : undefined}
              labelSeparator=""
              label="Channel"
              name="issue_channel"
              placeholder={isDisabledChannel ? "" : "Enter Channel"}
              value={issue_channel}
              maxLength={50}
              forceReset
              onChange={this.handleFieldValueChanged}
              onBlur={this.handleFieldValueChanged}
              disabled={isDisabledChannel}
            />
            <div className="timecode">
              <InputAdvanced
                labelSeparator=""
                label="In Time (SMPTE timecode)"
                name="in_time"
                placeholder="Enter value"
                value={in_time.value}
                forceReset={in_time.reset}
                onChange={this.handleChangeTime}
                onBlur={this.handleBlurTime}
                errorText={in_time.message || ''}
              />
              <Button bsStyle="default" onClick={this.handleStartTimecodeSelectMode} title="Select mode for pre-populate the In timecode based on the current location in the proxy, use Return to Issue button">Select</Button>
            </div>
            <div className="timecode">
              <InputAdvanced
                labelSeparator=""
                label="Out Time (SMPTE timecode)"
                name="out_time"
                placeholder="Enter value"
                value={out_time.value}
                forceReset={out_time.reset}
                onChange={this.handleChangeTime}
                onBlur={this.handleBlurTime}
                errorText={out_time.message || ''}
              />
              <Button bsStyle="default" onClick={this.handleEndTimecodeSelectMode} title="Select mode for pre-populate the Out timecode based on the current location in the proxy, use Return to Issue button">Select</Button>
            </div>
            <FormGroup
              className={isDisabledDescription ? 'form-group--is-disabled' : undefined}
            >
              <ControlLabel>Description</ControlLabel>
              <FormControl
                name="issue_description"
                type="text"
                componentClass="textarea"
                rows="3"
                placeholder={isDisabledDescription ? "" : "Enter issue description"}
                defaultValue={issue_description}
                onChange={this.handleTextareaBlur}
                onBlur={this.handleTextareaBlur}
                disabled={isDisabledDescription}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
            <Button bsStyle="primary" onClick={this.handleSave} disabled={isDisabledSave}>Save</Button>
          </Modal.Footer>
        </StyledModal>
      </Draggable>
    );
  }
}

const StyledModal = styled(Modal)`
  padding: 0 !important;
  width: ${MODAL_WIDTH}px;
  height: fit-content;
  max-height: calc(100vh - 2px);
  left: calc((100vw - ${MODAL_WIDTH}px) / 2);
  transition: top .15s ease-out 0.01s;

  @media (max-width: ${MODAL_WIDTH}px) {
    width: 98vw;
    height: 90vh;
    top: 5vh;
    left: 1vw;
  }

  * {
    font-family: 'Roboto', sans-serif !important;
  }

  .modal-dialog {
    width: auto;
    margin: 0;

    .modal-content {
      box-shadow: none;
      border-radius: 0;

      .modal-header {
        padding: 24px 24px 0px;
        height: 69px;
        position: relative;
        background-color: #F9F9F9;
        border: none;
        h4 {
          font-weight: normal;
          font-size: 18px;
          line-height: 19px;
          color: #282828;
          margin: 0;
        }
        .icon-close-modal {
          top: 21px;
          right: 25px;
        }

        @media (max-width: 767px) {
          padding: 19px 0 0;
          background-color: #fff;

          h4 {
            font-weight: bold;
            line-height: 20px;
            padding-left: 56px;
          }

          .icon-close-modal {
            top: 22px;
            right: 31px;
            font-size: 13px;
            &:before {
              content: 'Cancel';
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 14px;
              color: #999;
            }
          }

          .back-btn {
            background: url(${backImg}) no-repeat;
            background-position: 0;
            height: 15px;
            width: 20px;
            position: absolute;
            top: 22px;
            left: 23px;
          }
        }
      }

      .modal-body {
        padding: 15px 24px;
        max-height: calc(100vh - 69px - 71px - 4px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        @media (max-width: ${MODAL_WIDTH}px) {
          height: calc(90vh - 69px - 71px - 2px);
        }

        label {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;

          @media (max-width: 767px) {
            font-size: 15px;
            line-height: 16px;
          }
        }

        .help-block {
          font-size: 12px;
          line-height: 13px;
        }

        .form-control {
          &input {
            font-size: 13px;
          }
          &textarea {
            font-size: 13px;
            line-height: 15px;
          }
        }

        .form-group {
          &.form-group--is-disabled {
            opacity: 0.65;
            pointer-events: none;
          }
          &.invalid {
            .Select {
              .Select-control {
                border-color: #ffdddd;
              }
              &:not(.is-open), &:not(.is-focused) {
                .Select-control {
                  border-color: #ff4040;
                }
              }
            }
          }
        }

        .timecode {
          display: flex;
          flex-direction: row;
          align-items: start;

          .form-group {
            flex: 1 auto;
          }

          button {
            margin-top: 22px;
            margin-left: 5px;
          }
        }
      }

      .modal-footer {
        height: 71px;
        padding: 17px 24px 0px;
        background-color: #F9F9F9;
        border: none;

        @media (max-width: 767px) {
          padding: 0;
        }

        button {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            color: #fff;
            background-color: #646464 !important;
            border-radius: 100px;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            padding: 11px 40px 12px;

            &.btn-primary {
              @media (min-width: 992px) {
                &:hover:not([disabled]) {
                  background-color: #565656 !important;
                }
              }

              &[disabled] {
                color: #c8c8c8;
                /*cursor: default;*/
              }

              @media (max-width: 767px) {
                border-radius: 0;
                text-transform: uppercase;
                font-size: 13px;
                line-height: 14px;
                background-color: #484848 !important;
                margin: 0;
                height: 100%;
                width: 100%;
              }
            }

            &.btn-default {
              background-color: transparent !important;
              color: #282828;
              padding-left: 0;
              padding-right: 0;
              margin-right: 18px;

              @media (max-width: 767px) {
                display: none;
              }
            }
        }
      }
    }
  }
`;

AddIssueModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  saveIssue: PropTypes.func.isRequired,
  currentIssue: PropTypes.object,
  reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(AddIssueModal);
