import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  WO_CONFLICTS_CLEAR, WO_CONFLICTS_IS_LOADING, WO_CONFLICTS_RECEIVE, WO_CONFLICTS_ACCEPT_VERSION
} from './actions';

const initialState = {
  loading: false,
  conflictsData: null
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case WO_CONFLICTS_CLEAR: {
      return {...initialState};
    }

    case WO_CONFLICTS_IS_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case WO_CONFLICTS_RECEIVE: {
      return {
        ...state,
        loading: false,
        conflictsData: action.data
      };
    }

    case WO_CONFLICTS_ACCEPT_VERSION: {
      return {
        ...state,
        loading: false,
        conflictsData: {...state.conflictsData, AcceptedVersion: action.version}
      };
    }

    default:
      return state;
  }
}
