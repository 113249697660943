import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import VideoPlayer from '../videoplayer/VideoPlayer';
import StyledVideoPlayer from '../videoplayer/StyledVideoPlayer';
import {generateProxy, getProxy} from '../../actions';
import {isInternalUser} from '../../../../utils/general';

class InteractiveViewTab extends Component {
  state = {
    displayError: false
  };

  clearTimer = () => {
    if (this.updateTimerId) {
      clearTimeout(this.updateTimerId);
      this.updateTimerId = undefined;
    }
  };

  setTimer = () => {
    const {dispatch} = this.props;
    this.clearTimer();
    this.updateTimerId = setTimeout(() => {
      dispatch(getProxy());
      this.clearTimer();
    }, 30 * 1000);
  };

  needToSetTimer = (hasRawProxy, proxyStatus) => !!hasRawProxy && ['pending', 'in progress'].includes(proxyStatus);

  componentDidMount() {
    const {reportdetails} = this.props;
    const hasRawProxy = reportdetails.details.has_raw_proxy;
    const proxyStatus = reportdetails.details.request_proxy_status;
    const {IsAtmosQcProfile} = reportdetails.details;
    if (!IsAtmosQcProfile && this.needToSetTimer(hasRawProxy, proxyStatus)) {
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps) {
    const {requestId, reportdetails: {details, isProxySaving, isProxyLoading}} = this.props;

    const {IsAtmosQcProfile} = details;
    if (IsAtmosQcProfile) {
      this.clearTimer();
      return;
    }

    const hasRawProxy = details.has_raw_proxy;
    const proxyStatus = details.request_proxy_status;
    const needsTimer = this.needToSetTimer(hasRawProxy, proxyStatus);
    const isChangedRequest = requestId !== prevProps.requestId || details.RequestID !== (prevProps.details || {}).RequestID;
    if (this.updateTimerId) {
      if (isChangedRequest || !needsTimer) {
        this.clearTimer();
        needsTimer && this.setTimer();
      }
    } else if ((isChangedRequest ||
                (!isProxySaving && prevProps.reportdetails.isProxySaving) ||
                (!isProxyLoading && prevProps.reportdetails.isProxyLoading)) &&
               needsTimer) {
      this.setTimer();
    }

    if (details.request_proxy && details.request_proxy !== prevProps.reportdetails.details.request_proxy) {
      this.clearTimer();
      this.setState({displayError: false});
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  onErrorLoadVideo = (event) => {
    this.setState({displayError: true});
  };

  handleGenerateProxy = () => {
    const {dispatch, requestId} = this.props;
    dispatch(generateProxy(requestId));
  };

  buildErrorFragment = (message) => {
    const defaultErrorMsg = (<span>This video is not available<br/>Sorry about that.</span>);

    return (
      <Fragment>
        <StyledVideoPlayer className="video-container-error">
          <video className="video" ref="player" src="" />
          <div className="overlay-error">
            <div className="content">
              {message || defaultErrorMsg}
            </div>
          </div>
        </StyledVideoPlayer>
      </Fragment>
    );
  }

  render() {
    const {dispatch, reportdetails, loading, onScrollToIssueOnSidebar} = this.props;
    const {displayError} = this.state;

    const supportEmail = 'qodsupport@us.resillion.com';
    const supportEmailCtrl = (<a href={`mailto:${supportEmail}`} title={`mailto:${supportEmail}`}>{supportEmail}</a>);

    const pendingMsg = (<span>Your watermarked proxy video is being prepared.<br/>Please check back in a few minutes</span>);
    const errorMsg = (
        <span>
          We’re sorry. Something went wrong with your live view proxy.<br/>
          Please contact {supportEmail} for assistance
        </span>
    );

    const isDeletedRawProxy = reportdetails.details.is_deleted_raw_proxy;
    const rawProxyDeleteAfterXDays = reportdetails.details.raw_proxy_delete_after_x_days;
    const hasRawProxy = reportdetails.details.has_raw_proxy;
    const proxyStatus = reportdetails.details.request_proxy_status;
    const proxyPercentComplete = reportdetails.details.request_proxy_percent_complete;

    if (isInternalUser()) {
      delete reportdetails.details.forbidden_create_client_proxies;
    }

    let message;
    if (reportdetails.details.IsAtmosQcProfile) {
      message = 'We are unable to generate a proxy for assets with Dolby Atmos due to the nature of the audio configuration';
    } else if (proxyStatus === 'setup in progress') {
      message = (
          <span>
            Video is currently processing and will be available shortly.<br/>
            Please check back again and contact {supportEmail} if not available after 60 mins.
          </span>
      );
    } else if (proxyStatus === 'pending') {
      message = pendingMsg;
    } else if (proxyStatus === 'in progress') {
      message = !hasRawProxy ? pendingMsg : (
        <span>
          Please wait.  Your secure proxy is currently being created.
          {typeof proxyPercentComplete === 'number' ? <><br/>{`${proxyPercentComplete}% Complete`}</> : null}
        </span>
      );
    } else if (proxyStatus === 'error') {
      message = errorMsg;
    } else if (proxyStatus === 'none') {
      message = !hasRawProxy ? (isDeletedRawProxy ?
        <span>
          We’re sorry, but this proxy is no longer available.<br/>
          Proxies are removed {rawProxyDeleteAfterXDays || 'X'} days after a request has been closed for security reasons
        </span> :
        errorMsg
      ) : reportdetails.details.forbidden_create_client_proxies ? (
        <span>
          Live view proxies are not enabled for this request.<br/>
          For questions, please reach out to {supportEmailCtrl}
        </span>
      ) : ['direct', 'dash'].includes(reportdetails.details.raw_proxy_file_type) ? (
        <span>
          This is a high security proxy and you do not have access to it.<br/>
          Please reach out to {supportEmailCtrl} or your client services representative for assistance
        </span>
      ) : (
        <span>
          A secure proxy hasn't been created for your account.<br/>
          <GenerateProxyBtn
            onClick={loading ? undefined : this.handleGenerateProxy}
            className={loading ? 'disabled' : undefined}
          >
            Click here to generate your secure proxy
          </GenerateProxyBtn>
        </span>
      );
    } else if (reportdetails.isProxyLoading && reportdetails.details.use_dash_proxy && !reportdetails.details.request_proxy) {
      message = (
        <span>
          Please wait. We prepare player for DASH proxy
        </span>
      );
    }

    if (displayError || message) {
      return this.buildErrorFragment(message);
    }

    return (
      <Fragment>
        {(loading || reportdetails.isProxySaving) ? <Loader/> : null}
        {
          reportdetails.details.request_proxy ?
            <VideoPlayer
              dispatch={dispatch}
              savedState={reportdetails.savedState}
              issues={reportdetails.issues}
              useDashPlayer={reportdetails.details.use_dash_proxy}
              url={reportdetails.details.request_proxy}
              captions={reportdetails.details.proxy_captions || []}
              isActiveTopTab={reportdetails.activeTopTab === 2}
              isPlaying={reportdetails.videoPlayer.isPlaying}
              isChangedSelecting={reportdetails.videoPlayer.isChangedSelecting}
              selectedIssueID={reportdetails.videoPlayer.selectedIssue.IssueID}
              selectedCueID={(reportdetails.videoPlayer.selectedCue || {}).CueID}
              timeCodeStart={
                reportdetails.videoPlayer.selectedCue ? reportdetails.videoPlayer.selectedCue.StartTimeInSeconds :
                  reportdetails.videoPlayer.selectedIssue.ProxyTimeCodeStart
              }
              timeCodeSettings={reportdetails.details.request_proxy_timecode_settings}
              onIssueClick={onScrollToIssueOnSidebar}
              onErrorLoadVideo={this.onErrorLoadVideo}
            /> : this.buildErrorFragment(message)
        }
      </Fragment>
    );
  }
}

const GenerateProxyBtn = styled.span`
  &:not(.disabled) {
    color: #679dd7;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      color: #a8caef;
    }
  }
`;

InteractiveViewTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  reportdetails: PropTypes.object.isRequired,
  requestId: PropTypes.any.isRequired,
  onScrollToIssueOnSidebar: PropTypes.func.isRequired,
};

export default InteractiveViewTab;
