import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import {isInternalUser, isStudioUser, isOperatorUser, isDwManagerUser, isProductionUserWithoutDashboard,
  isArtworkClientUser,
} from '../../utils/general';
import config from '../../../config';
import {
  getDashboardData, getRequestQueueData, getRequestByStatusData, getIssueByTypeData,
  getLargestNumberData, getReplacementConstData, getPassPercentageData,
  clearDashboard,
  changeChartFilters,
  changeTopFiltersAndGetData
} from './actions';
import {Row, Col} from 'react-bootstrap';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import RequestQueue from './components/RequestQueue/index';
import RequestsByStatus from './components/RequestsByStatus/index';
import QCIssuesByType from './components/QCIssuesByType/index';
import NumberOfReplacements from './components/NumberOfReplacements';
import ReplacementCost from './components/ReplacementCost';
import PassPercentage from './components/PassPercentage/index';
import TopFilters from './components/TopFilters';
import logoImg from '../../../assets/images/resillion_logo.png';

const ReplGroupByShow = {id: 1, title: 'by Show'};
const ReplGroupByProduction = {id: 2, title: 'by Production'};
const ReplGroupByStudio = {id: 3, title: 'by Studio'};
const ReplGroupByFinishingHouse = {id: 4, title: 'by Post Partner'};

const InternalUserGroups = [ReplGroupByShow, ReplGroupByStudio, ReplGroupByProduction, ReplGroupByFinishingHouse];
const StudioUserGroups = [ReplGroupByShow, ReplGroupByProduction, ReplGroupByFinishingHouse];
const PartnerUserGroups = [ReplGroupByShow, ReplGroupByStudio];

class Dashboard extends PageComponent {
  constructor(props, context) {
    super(props, context);

    const isAuthorized = Cookies.get('__auth');
    if (!isAuthorized) {
      const {history} = props;
      history.push('/login');
    }
  }

  componentDidMount() {
    const {dispatch, history} = this.props;
    if (isArtworkClientUser()) {
      history.push('/dashboard');
    } else if (isProductionUserWithoutDashboard() || isOperatorUser()) {
      history.push('/requests');
    } else {
      dispatch(getDashboardData());
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(clearDashboard());
  };

  getRequestQueueData = (obj) => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('requestQueue', obj));
    dispatch(getRequestQueueData(obj));
  };

  getRequestsByStatusData = period => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('requestsByStatus', {period}));
    dispatch(getRequestByStatusData(period));
  };

  getIssueByTypeData = period => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('issuesByType', {period}));
    dispatch(getIssueByTypeData(period));
  };

  getLargestNumberData = (period, group) => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('replacementLargestNumber', {period, group}));
    dispatch(getLargestNumberData({period, group}));
  };

  getPassPercentageData = (period, group) => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('passPercentage', {period, group}));
    dispatch(getPassPercentageData({period, group}));
  };

  getReplacementConstData = (period, group) => {
    const {dispatch} = this.props;
    dispatch(changeChartFilters('replacementCost', {period, group}));
    dispatch(getReplacementConstData({period, group}));
  };

  prepareReplGroupOptions = () => {
    if (isInternalUser()) {
      return InternalUserGroups;
    }
    if (isStudioUser()) {
      return StudioUserGroups;
    }
    return PartnerUserGroups;
  };

  handleChangeTopFilters = (filterName, filtersValues) => {
    const {dispatch} = this.props;
    dispatch(changeTopFiltersAndGetData(filterName, filtersValues));
  };

  render() {
    const {
      requestQueue, requestsByStatus, issuesByType, replacementLargestNumber,
      replacementCost, passPercentage, loaders, dictionaries, selectedTopFilters, selectedChartFilters
    } = this.props.dashboard;
    const replGroupOptions = this.prepareReplGroupOptions();

    return (
      <StyledDashboard title="Home" mobileTitle="Dashboard">
        <div className="dashboard">
          <Row>
            <Col md={12} xs={12} className="nopadding">
              <TopFilters
                loading={Object.keys(loaders).some(k => Boolean(loaders[k]))}
                dictionaries={dictionaries}
                values={selectedTopFilters}
                onChange={this.handleChangeTopFilters}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} xs={12} className="nopadding">
              <RequestQueue
                filters={{...selectedChartFilters.requestQueue, topFilters: selectedTopFilters}}
                data={requestQueue}
                loading={loaders.dashboard || loaders.requestQueue}
                getData={this.getRequestQueueData}
              />
            </Col>
            <Col md={9} xs={12} className="nopadding">
              <Col xs={12} className="nopadding">
                <RequestsByStatus
                  filters={{...selectedChartFilters.requestsByStatus, topFilters: selectedTopFilters}}
                  data={requestsByStatus}
                  statuses={config.requestStatuses}
                  loading={loaders.dashboard || loaders.requestsByStatus}
                  getData={this.getRequestsByStatusData}
                />
              </Col>
              <Col md={6} xs={12} className="nopadding">
                <QCIssuesByType
                  filters={selectedChartFilters.issuesByType}
                  data={issuesByType}
                  loading={loaders.dashboard || loaders.issueByType}
                  getData={this.getIssueByTypeData}
                />
                {!isDwManagerUser() &&
                <ReplacementCost
                  filters={selectedChartFilters.replacementCost}
                  data={replacementCost}
                  loading={loaders.dashboard || loaders.replacementCost}
                  getData={this.getReplacementConstData}
                  groups={replGroupOptions}
                />
                }
              </Col>
              <Col md={6} xs={12} className="nopadding">
                <NumberOfReplacements
                  filters={selectedChartFilters.replacementLargestNumber}
                  data={replacementLargestNumber}
                  loading={loaders.dashboard || loaders.numberOfReplacements}
                  getData={this.getLargestNumberData}
                  groups={replGroupOptions}
                />
                <PassPercentage
                  filters={selectedChartFilters.passPercentage}
                  data={passPercentage}
                  loading={loaders.dashboard || loaders.passPercentage}
                  getData={this.getPassPercentageData}
                  groups={replGroupOptions}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <StyledLogo>
                <Link to="/"><img src={logoImg} width="120" alt="logo"/></Link>
              </StyledLogo>
            </Col>
          </Row>
        </div>
      </StyledDashboard>
    );
  }
}

const StyledDashboard = styled(AppLayout)`
  .dashboard {
    background-color: #f1f1f1;
    min-height: calc(100vh - 59px);
    margin: 0;
    padding: 0 0 25px 0;

    @media (max-width: 991px) {
      background: linear-gradient(transparent, transparent), linear-gradient(#f1f1f1, #f1f1f1);
      background-position: 0 0, 0 65px;
      background-size: auto 65px, auto auto;
      background-repeat: no-repeat;
      margin-top: 10px;
      padding-bottom: 0;
    }
  }

  .row {
    margin: 0;
  }

  .box {
    background-color: #f9f9f9;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 25px 0 0 25px;
    position: relative;

    .loader-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 3;

      .react-spinner {
        top: 50%;
        margin: -16px 0 0 -16px;
      }
    }

    @media (max-width: 991px) {
      margin: 10px 10px 0 10px;
    }
  }
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;

  @media (min-width: 992px) {
    display: none;
  }
`;

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    dashboard: state.dashboard
  };
}

export default connect(
  mapStateToProps
)(withRouter(Dashboard));
