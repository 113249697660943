import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Autocomplete extends Component {
  state = {
    activeSuggestion: 0,
    showSuggestions: false,
    inputText: this.props.inputProps.value || ''
  };

  handleFocusOut = e => {
    const {inputProps: {onChange, onBlur}} = this.props;
    if (e.relatedTarget && e.relatedTarget.className.includes('suggestion-item')) {
      const inputText = e.relatedTarget.innerText;
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        inputText
      });
      onChange && onChange({target: {value: inputText}}, onBlur);
    } else if (onBlur) {
      onBlur();
    }
  };

  componentDidMount() {
    const {inputProps: {onBlur}} = this.props;
    if (onBlur) {
      try {
        this._form.addEventListener('focusout', this.handleFocusOut);
      } catch {}
    }
  }

  componentWillUnmount() {
    const {inputProps: {onBlur}} = this.props;
    if (onBlur) {
      try {
        this._form.removeEventListener('focusout', this.handleFocusOut);
      } catch {}
    }
  }

  handleChange = e => {
    const {inputProps: {onChange}} = this.props;
    const inputText = e.currentTarget.value;

    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      inputText
    });

    onChange && onChange({target: {value: inputText}});
  };

  handleKeyDown = e => {
    const {inputProps: {onChange, onBlur}} = this.props;
    const {activeSuggestion} = this.state;

    const suggestionList = this.state.inputText.length === 0
        ? this.props.suggestions
        : this.props.suggestions.filter(suggestion => suggestion.toLowerCase().indexOf(this.state.inputText.toLowerCase()) > -1);

    if (e.keyCode === 13) { // enter
      const inputText = suggestionList[activeSuggestion] || e.currentTarget.value;
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        inputText
      });
      onChange && onChange({target: {value: inputText}}, onBlur);
    } else if (e.keyCode === 38) { // up arrow
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({activeSuggestion: activeSuggestion - 1});
    } else if (e.keyCode === 40) { // down arrow
      if (activeSuggestion - 1 === suggestionList.length) {
        return;
      }
      this.setState({activeSuggestion: activeSuggestion + 1});
    }
  };

  handleInputFocus = event => {
    this.setState({
      showSuggestions: true
    });

    const { onFocus } = this.props.inputProps;
    if (onFocus) {
      onFocus(event);
    }
  };

  render() {
    const {
      handleChange,
      handleKeyDown,
      state: {
        activeSuggestion,
        showSuggestions,
        inputText
      }
    } = this;

    const inputProps = {...this.props.inputProps};
    delete inputProps.value;
    delete inputProps.onChange;
    delete inputProps.onBlur;

    const suggestionList = inputText.length === 0
      ? this.props.suggestions
      : this.props.suggestions.filter(suggestion => suggestion.toLowerCase().indexOf(inputText.toLowerCase()) > -1);

    let suggestionsListComponent;
    if (showSuggestions && suggestionList.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {suggestionList.map((suggestion, index) => (
            <li
              className={`suggestion-item${index === activeSuggestion ? ' is-active' : ''}`}
              key={suggestion}
              tabIndex={index}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <form ref={f => {this._form = f;}}>
        <input
          {...inputProps}
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={this.handleInputFocus}
          value={inputText}
        />
        {suggestionsListComponent}
      </form>
    );
  }
}

Autocomplete.propTypes = {
  inputProps: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired
};

Autocomplete.defaultProps = {
  inputProps: {},
  suggestions: []
};

export default Autocomplete;
