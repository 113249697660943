import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Dropdown, FormControl, Button} from 'react-bootstrap';
import _ from 'lodash';
import sortUpImg from '../../../../assets/images/issueTable/sort-up.svg';
import sortDownImg from '../../../../assets/images/issueTable/sort-down.svg';
import sortUpActiveImg from '../../../../assets/images/issueTable/sort-up-active.svg';
import sortDownActiveImg from '../../../../assets/images/issueTable/sort-down-active.svg';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

class CustomMenu extends React.Component {
  render() {
    const {children, labelledBy, bsClass, bsRole, open, onClose, rootCloseEvent} = this.props;
    return (
      <RootCloseWrapper
        disabled={!open}
        onRootClose={onClose}
        event={rootCloseEvent || 'click'}
      >
      <div
        className={bsClass || 'dropdown-menu'}
        role={bsRole || 'menu'}
        aria-labelledby={labelledBy}
      >
        {children[0]}
        <ul className="list-unstyled">
          {children.slice(1, -1)}
        </ul>
        {children.slice(-1)}
      </div>
      </RootCloseWrapper>
    );
  }
}

export const HeaderFilterCell = props => {
  const {
    field,
    className,
    title,
    sortable,
    sort = {},
    onSort = () => {},
    getFilter,
    onFilter
  } = props;

  const filter = getFilter(field);
  const options = _.uniq(filter.options || []).filter(o => typeof o === 'number' || !!o).sort();
  const selectedOptions = filter.selectedOptions || [];
  const searchString = filter.searchString || '';
  const filtered = !!selectedOptions.length || !!searchString;

  const handleSelect = value => {
    onFilter(field, 'selectedOptions', selectedOptions.includes(value) ? selectedOptions.filter(o => o !== value) : [...selectedOptions, value]);
  };

  const handleInput = ({target: {value}}) => {
    onFilter(field, 'searchString', value);
  };

  const handleClearFilter = () => {
    onFilter(field);
  };

  const filterEl = (
    <Dropdown
      id={`filter--${field}`}
      rootCloseEvent="click"
    >
      <FilterStyled
        bsRole="toggle"
        title="Click to Filter"
      >
        <i className={`${filtered ? 'fas' : 'far'} fa-filter`}/>
      </FilterStyled>
      <CustomMenu
        bsRole="menu"
      >
        <FormControl
          className={`dropdown-menu-custom-item--form-control${filtered ? ' is-filtered' : ''}`}
          type="text"
          title="Type to filter..."
          placeholder="Type to filter..."
          value={searchString}
          onChange={handleInput}
          onBlur={handleInput}
        />
        {options.filter(o => !searchString.trim() || (String(o).toLowerCase().indexOf(searchString.trim().toLowerCase()) !== -1)).map(o => {
          const isSelected = selectedOptions.includes(o);
          return (
            <li
              key={o}
              className="dropdown-menu-custom-item"
              title={isSelected ? undefined : 'Select to filter'}
              onClick={() => handleSelect(o)}
            >
              <i className={`far fa-square${isSelected ? '-check' : ''}`}/>{o}
            </li>
          );
        })}
        <Button onClick={handleClearFilter} disabled={!filtered}>Clear Filter</Button>
      </CustomMenu>
    </Dropdown>
  );

  const sorted = !!sortable && sort.field === field;

  const handleSort = () => {
    if (sortable) {
      let nextField = field;
      let nextDirection = sorted ? (sort.direction === 'asc' ? 'desc' : 'asc') : 'asc';
      if (sort.direction === 'desc' && sorted) {
        nextField = undefined;
        nextDirection = undefined;
      }
      onSort(nextField, nextDirection);
    }
  };

  const sortEl = !!sortable && (
    <SortArrowStyled
      className={!sorted ? 'icon-sorting' : `sort-${sort.direction === 'asc' ? 'up' : 'down'}`}
      title="Click to Sort"
      onClick={handleSort}
    />
  );

  return (
    <CellStyled
      className={className}
      title={props.hint}
    >
      <div>
        <span
          className="cell-title"
          title={sortable ? 'Click to Sort' : undefined}
          onClick={handleSort}
        >
          {title}
        </span>
        {sortEl}
        {filterEl}
      </div>
    </CellStyled>
  );
};

const CellStyled = styled.th`
  > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    .cell-title {
      white-space: normal;
      &[title] {
        cursor: pointer;
      }
    }
  }
  .dropdown-menu {
    min-width: 130px;
    font-size: 12px;
    font-weight: 400;
    cursor: default;
    ul {
      margin-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
      max-height: 150px;
      overflow: auto;
    }
    .dropdown-menu-custom-item {
      padding: 2px 0;
      max-width: 200px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      white-space: normal;
      cursor: pointer;
      + .dropdown-menu-custom-item {
        margin-top: 2px;
      }
      i {
        margin-right: 5px;
        &.fa-square-check {
          color: #29a4cb;
        }
      }
      &:hover {
        text-shadow: 0 0 #999;
        i {
          &.fa-square-check {
            color: #228daf;
            text-shadow: none;
          }
        }
      }
    }
    .form-control {
      margin: 0 5px;
      width: calc(100% - 10px);
      font-size: 12px;
      &::placeholder {
        color: #282828;
        opacity: 0.5;
        font-weight: 400;
      }
      &.is-filtered {
        + ul {
          max-height: 126px;
        }
      }
    }
    .btn {
      margin-left: 8px;
      padding: 6px 12px;
      width: fit-content;
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      outline: none !important;
      color: #29a4cb;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: left;
      &[disabled] {
        display: none;
      }
      &:not([disabled]):hover {
        color: #228daf;
      }
    }
  }
`;

const FilterStyled = styled.span`
  margin-left: 2px;
  height: 13px;
  width: 15px;
  display: block;
  font-size: 11px;
  text-align: right;
  cursor: pointer;
`;

const SortArrowStyled = styled.span`
  height: 10px;
  width: 10px;
  cursor: pointer;
  &.icon-sorting {
    min-width: 10px;
    font-size: 12px;
    overflow: hidden;
    visibility: hidden;
  }
  &.sort-down {
    background-image: url(${sortUpImg}), url(${sortDownActiveImg});
  }
  &.sort-up {
    background-image: url(${sortUpActiveImg}), url(${sortDownImg});
  }
  background-position: right top, right bottom;
  background-repeat: no-repeat, no-repeat;
`;

HeaderFilterCell.propTypes = {
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  hint: PropTypes.string,
  title: PropTypes.any.isRequired,
  sortable: PropTypes.bool,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  getFilter: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired
};
