import $ from 'jquery';
import qs from 'qs';
import config from '../../../../config';
import {changeTopFilters} from '../components/TopFilters/actions';

export const ARTWORK_DASHBOARD_GET_DATA = 'ARTWORK_DASHBOARD_GET_DATA';
export const ARTWORK_DASHBOARD_GET_REQUEST_QUEUE_DATA = 'ARTWORK_DASHBOARD_GET_REQUEST_QUEUE_DATA';
export const ARTWORK_DASHBOARD_GET_REQUESTS_BY_STATUS_DATA = 'ARTWORK_DASHBOARD_GET_REQUESTS_BY_STATUS_DATA';
export const ARTWORK_DASHBOARD_GET_ISSUES_BY_TYPE_DATA = 'ARTWORK_DASHBOARD_GET_ISSUES_BY_TYPE_DATA';
export const ARTWORK_DASHBOARD_GET_LARGEST_NUMBER_DATA = 'ARTWORK_DASHBOARD_GET_LARGEST_NUMBER_DATA';
export const ARTWORK_DASHBOARD_GET_PASS_PERCENTAGE_DATA = 'ARTWORK_DASHBOARD_GET_PASS_PERCENTAGE_DATA';
export const ARTWORK_DASHBOARD_GET_REPLACEMENT_CONST_DATA = 'ARTWORK_DASHBOARD_GET_REPLACEMENT_CONST_DATA';
export const ARTWORK_DASHBOARD_SET_LOADING = 'ARTWORK_DASHBOARD_SET_LOADING';
export const ARTWORK_DASHBOARD_CHANGE_CHART_FILTERS = 'ARTWORK_DASHBOARD_CHANGE_CHART_FILTERS';
export const ARTWORK_DASHBOARD_CLEAR = 'ARTWORK_DASHBOARD_CLEAR';

export const clearDashboard = () => ({type: ARTWORK_DASHBOARD_CLEAR});

export const changeChartFilters = (name, value) => ({type: ARTWORK_DASHBOARD_CHANGE_CHART_FILTERS, name, value});

export const getDashboardData = topFilters => dispatch => {
  localStorage.setItem('artworkDashboardTopFilters', JSON.stringify(topFilters));
  dispatch(changeTopFilters(topFilters));

  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'dashboard', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard`,
    data: prepareTopFiltersValues(topFilters)
  })
    .done(res => {
      dispatch({type: ARTWORK_DASHBOARD_GET_DATA, data: res});
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'dashboard', value: false});
    })
    .fail(() => {
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'dashboard', value: false});
    })
};

const prepareTopFiltersValues = (values) => {
  const {titleId, projectId} = values;
  const preparedValues = {};
  if (titleId) {
    preparedValues.title_id = titleId;
  }
  if (projectId) {
    preparedValues.project_id = projectId;
  }
  if (values.amazonSeriesId) {
    preparedValues.amazon_series_id = values.amazonSeriesId;
  }
  if (values.amazonConceptName) {
    preparedValues.amazon_concept_name = values.amazonConceptName;
  }
  if (values.amazonAssetType) {
    preparedValues.amazon_asset_type = values.amazonAssetType;
  }
  return preparedValues;
};

const prepareTopFilters = (values, state) => {
  if (!values) {
    values = {...state.artworkTopFilters.values};
  }
  return prepareTopFiltersValues(values);
};

export const prepareTopFiltersForUrl = (values) => {
  return qs.stringify(prepareTopFiltersValues(values));
};

export const getRequestQueueData = ({day, month, year, type}, topFilters) => (dispatch, getState) => {
  const data = {
    ...prepareTopFilters(topFilters, getState()),
    ...day && {day},
    ...month && {month},
    ...year && {year},
    ...type && {type}
  };

  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestQueue', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/request_queue`,
    data
  })
  .done(res => {
    dispatch({type: ARTWORK_DASHBOARD_GET_REQUEST_QUEUE_DATA, data: res});
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestQueue', value: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestQueue', value: false});
  })
};

export const getRequestByStatusData = (period, topFilters) => (dispatch, getState) => {
  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/requests_by_status`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period
    }
  })
    .done(res => {
      dispatch({type: ARTWORK_DASHBOARD_GET_REQUESTS_BY_STATUS_DATA, data: res['requests_by_status']});
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: false});
    })
    .fail(() => {
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'requestsByStatus', value: false});
    })
};

export const getIssueByTypeData = (period, topFilters) => (dispatch, getState) => {
  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'issueByType', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/issues_by_type`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period
    }
  })
  .done(res => {
    dispatch({type: ARTWORK_DASHBOARD_GET_ISSUES_BY_TYPE_DATA, data: res['issues_by_type']});
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'issueByType', value: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'issueByType', value: false});
  })
};

export const getLargestNumberData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/replacement/largest_number`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
  .done(res => {
    dispatch({type: ARTWORK_DASHBOARD_GET_LARGEST_NUMBER_DATA, data: res['replacement_largest_number']});
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'numberOfReplacements', value: false});
  })
};

export const getReplacementConstData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'replacementCost', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/replacement/cost`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
  .done(res => {
    dispatch({type: ARTWORK_DASHBOARD_GET_REPLACEMENT_CONST_DATA, data: res['replacement_cost']});
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'replacementCost', value: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'replacementCost', value: false});
  })
};

export const getPassPercentageData = ({period, group}, topFilters) => (dispatch, getState) => {
  dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'passPercentage', value: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dashboard/pass`,
    data: {
      ...prepareTopFilters(topFilters, getState()),
      period,
      group
    }
  })
    .done(res => {
      dispatch({type: ARTWORK_DASHBOARD_GET_PASS_PERCENTAGE_DATA, data: res['pass_percentage']});
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'passPercentage', value: false});
    })
    .fail(() => {
      dispatch({type: ARTWORK_DASHBOARD_SET_LOADING, name: 'passPercentage', value: false});
    })
};

export const changeTopFiltersAndGetData = (filtersValues) => (dispatch, getState) => {
  localStorage.setItem('artworkDashboardTopFilters', JSON.stringify(filtersValues));
  dispatch(changeTopFilters(filtersValues));

  const {selectedChartFilters} = getState().artworkDashboard;
  dispatch(getRequestQueueData(selectedChartFilters.requestQueue, filtersValues));
  dispatch(getRequestByStatusData(selectedChartFilters.requestsByStatus.period, filtersValues));
  dispatch(getIssueByTypeData(selectedChartFilters.issuesByType.period, filtersValues));
  dispatch(getLargestNumberData(selectedChartFilters.replacementLargestNumber, filtersValues));
  dispatch(getReplacementConstData(selectedChartFilters.replacementCost, filtersValues));
  dispatch(getPassPercentageData(selectedChartFilters.passPercentage, filtersValues));
};
