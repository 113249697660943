import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils/general';
import {showError, showSuccess} from '../../../layouts/actions';
import {
  savedState,
  prepareFiltersParams,
  prepareSingleFiltersParams,
  getValidFilters,
} from '../Assets/actions';

export const ARTWORK_REQ_QUEUE_CLEAR = 'ARTWORK_REQ_QUEUE_CLEAR';
export const ARTWORK_REQ_QUEUE_SET_LOADING = 'ARTWORK_REQ_QUEUE_SET_LOADING';
export const ARTWORK_REQ_QUEUE_DICTIONARIES = 'ARTWORK_REQ_QUEUE_DICTIONARIES';
export const ARTWORK_REQ_QUEUE_RECEIVE = 'ARTWORK_REQ_QUEUE_RECEIVE';
export const ARTWORK_REQ_QUEUE_ASSIGN_REQUEST = 'ARTWORK_REQ_QUEUE_ASSIGN_REQUEST';
export const ARTWORK_REQ_QUEUE_PICK_REQUEST = 'ARTWORK_REQ_QUEUE_PICK_REQUEST';
export const ARTWORK_REQ_QUEUE_CHANGE_FILTER_TYPE = 'ARTWORK_REQ_QUEUE_CHANGE_FILTER_TYPE';
export const ARTWORK_REQ_QUEUE_CHANGE_FILTER = 'ARTWORK_REQ_QUEUE_CHANGE_FILTER';
export const ARTWORK_REQ_QUEUE_SAVED_FILTER = 'ARTWORK_REQ_QUEUE_SAVED_FILTER';
export const ARTWORK_REQ_QUEUE_SET_FILTERS = 'ARTWORK_REQ_QUEUE_SET_FILTERS';
export const ARTWORK_REQ_QUEUE_ADD_FILTERS = 'ARTWORK_REQ_QUEUE_ADD_FILTERS';
export const ARTWORK_REQ_QUEUE_REMOVE_FILTERS = 'ARTWORK_REQ_QUEUE_REMOVE_FILTERS';
export const ARTWORK_REQ_QUEUE_SELECT = 'ARTWORK_REQ_QUEUE_SELECT';

export const clearAll = () => ({type: ARTWORK_REQ_QUEUE_CLEAR});

export const selectRequests = data => ({type: ARTWORK_REQ_QUEUE_SELECT, data});

export const getDictionaries = () => dispatch => {
  const loadingName = 'dictionaries';
  dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/request_queue/dictionaries`
  })
  .done(res => {
    const operators = (res.operators || []).map(o => ({...o, value: o.UserID, name: o.UserName, disabled: !o.Active}));
    dispatch({type: ARTWORK_REQ_QUEUE_DICTIONARIES, operators});
  })
  .always(() => {
    dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
  });
};

export const savedAllLeftFilters = urlFilters => ({type: ARTWORK_REQ_QUEUE_SAVED_FILTER, urlFilters});

export const getLocalStorageFilterName = () => 'artworkRqFilters' + window.location.pathname.replace(/\W/g, '_').toLowerCase();

export const getRequests = (requestsType, startedFilters, parameterNames, isLoadMore) => (dispatch, getState) => {
  const state = getState();

  if (!requestsType) {
    requestsType = state.artworkRequestQueue.requestsType;
  }

  !startedFilters && savedState(null, state.artworkRequestQueue.selectedFilters, getLocalStorageFilterName());
  const filtersObj = {
    ...prepareFiltersParams(state.artworkRequestQueue.selectedFilters, parameterNames),
  };

  const start = isLoadMore ? state.artworkRequestQueue.data.length : 0;

  const loadingName = isLoadMore ? 'more' :'requests';
  dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/${requestsType.replace('-', '_')}`,
    data: {
      filters: JSON.stringify(filtersObj),
      start,
      limit: config.pagingSize,
    }
  })
  .done(res => {
    let {data, total} = res.data;
    if (isLoadMore) {
      data = [...state.artworkRequestQueue.data, ...data]
    }
    dispatch({type: ARTWORK_REQ_QUEUE_RECEIVE, data, total, requestsType});
  })
  .fail(error => {
    dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get requests.  ${getErrorMessage(error)}`));
  });
};

export const assignRequestsByManager = (requestIDs, values, callback) => dispatch => {
  const {ArtworkOperatorId} = values;
  const actionPrefix = ArtworkOperatorId ? '' : 'un';

  const loadingName = 'assign';
  dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/assign/${ArtworkOperatorId || 0}`,
    data: {
      asset_id: requestIDs
    }
  })
  .done(() => {
    callback && callback();
    dispatch({type: ARTWORK_REQ_QUEUE_ASSIGN_REQUEST, requestIDs, values});
    dispatch(showSuccess(`Operator has been successfully ${actionPrefix}assigned`));
  })
  .fail(error => {
    callback && callback();
    dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
    const msg = getErrorMessage(error);
    if (msg && msg.toLowerCase().indexOf('operator') >= 0) {
      dispatch(getDictionaries());
    }
    dispatch(showError(`Could not ${actionPrefix}assign.  ${msg}`));
  });
};

export const assignRequestsByOperator = (requestIDs, isRequestQueue) => dispatch => {
  const requestsSuffix = requestIDs.length === 1 ? '' : 's';
  const actionPrefix = isRequestQueue ? '' : 'un';

  const loadingName = 'assign';
  dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: true});

  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${actionPrefix}assign`,
    data: {
      asset_id: requestIDs
    }
  })
  .done(() => {
    dispatch({type: ARTWORK_REQ_QUEUE_PICK_REQUEST, requestIDs});
    dispatch(showSuccess(`Request${requestsSuffix} has been successfully ${actionPrefix}picked`));
  })
  .fail(error => {
    dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not ${actionPrefix}pick a request${requestsSuffix}.  ${getErrorMessage(error)}`));
  });
};

export const changeFilter = (name, value) => dispatch => {
  dispatch({type: ARTWORK_REQ_QUEUE_CHANGE_FILTER, name, value});
  return dispatch(getRequests());
};

export const changeFilterType = (filter, newSelectedFilter) => dispatch => {
  dispatch({type: ARTWORK_REQ_QUEUE_CHANGE_FILTER_TYPE, filter, newSelectedFilter});
  return dispatch(getRequests());
};

export const addFilter = filter => ({type: ARTWORK_REQ_QUEUE_ADD_FILTERS, filter});

export const removeFilter = filter => ({type: ARTWORK_REQ_QUEUE_REMOVE_FILTERS, filter});

export const getFilters = (storedReportFilters, srcFilters) => (dispatch, getState) => {
  const loadingName = 'filters';
  dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/filters`,
    data: {
      filters: JSON.stringify(prepareSingleFiltersParams(storedReportFilters)),
      for_request_queue: true
    }
  })
    .then(res => {
      const validUrlFilters = getValidFilters(res, srcFilters);
      const urlParams = {};
      const someFilters = {};
      Object.keys(validUrlFilters).forEach(filter => {
        if (['search', 'sort'].includes(filter)) {
          urlParams[filter] = validUrlFilters[filter];
        } else {
          someFilters[filter] = validUrlFilters[filter];
        }
      });
      dispatch(savedAllLeftFilters(urlParams));
      return {allowedFilters: res.filters, someFilters};
    })
    .then(({allowedFilters, someFilters}) => {
      dispatch({type: ARTWORK_REQ_QUEUE_SET_FILTERS, allowedFilters});

      const someFiltersKeys = Object.keys(someFilters).sort((a, b) => {
        return a.localeCompare(b);
      });
      someFiltersKeys.forEach(filterName => {
        const filter = allowedFilters.find(item => item.name === filterName);
        if (filter) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: someFilters[filterName],
            disabled: true
          }));
        }
      });

      const requiredFilters = allowedFilters.filter(filter => filter.required);
      requiredFilters.forEach(filter => {
        if (true || !Object.keys(someFilters).includes(filter.name)) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: filter.defaultValue,
            disabled: true,
            required: true
          }));
        }
      });

      dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
    })
    .then(() => ({selectedFilters: getState().artworkRequestQueue.selectedFilters, allowedFilters: getState().artworkRequestQueue.allowedFilters}))
    .then(({selectedFilters, allowedFilters}) => {
      const additionalFilters = allowedFilters.filter(filter => filter.forFilterName);
      additionalFilters.forEach(filter => {
        const selMainFilter = selectedFilters.find(f => f.name === filter.forFilterName);
        if (!selMainFilter) {
          dispatch(removeFilter(filter));
        } else if (selMainFilter.value && (Array.isArray(selMainFilter.value) ? selMainFilter.value.length && selMainFilter.value[0] : selMainFilter.value)) {
          dispatch(addFilter({...filter, disabled: true}));
        }
      });
    })
    .then(() => getState().artworkRequestQueue.selectedFilters)
    .then(selectedFilters => savedState(null, selectedFilters, getLocalStorageFilterName()))
    .catch(() => {
      dispatch({type: ARTWORK_REQ_QUEUE_SET_LOADING, name: loadingName, loading: false});
    });
};
