/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';
import {showNotificationsModal, getNotificationsByFilter, markNotificationAsAnswered} from '../../actions';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal
} from '../../../styled/ModalStyled';
import NotificationsList from './NotificationsList';
import logoImg from '../../../../assets/images/resillion_logo.png';
import backImg from '../../../../assets/images/back.svg';
import alertImg from '../../../../assets/images/notification/black-alert.svg';
import alertNewImg from '../../../../assets/images/notification/black-alert-new.svg';
import classNames from 'classnames';
import Loader from '../../../components/Loader';
import {isInternalUser} from '../../../utils/general';

class NotificationsModal extends Component {
  filters = [{
    name: 'ALL',
    value: 'all'
  }, ...(isInternalUser() ? [{
    name: 'Unanswered Comments',
    value: 'unanswered_comments'
  }] : []), {
    name: 'Action Items',
    value: 'action_items'
  }, {
    name: 'System Alerts',
    value: 'system_alerts'
  }, {
    name: 'Archived',
    value: 'archived'
  }];

  componentDidMount() {
    const {body} = document;
    body.className += ' modal-open';
    document.onkeydown = this.handleKeyDown;
  }

  componentWillUnmount() {
    const {body} = document;
    body.className = body.className.replace('modal-open', '').trim();
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showNotificationsModal(false));
  };

  handleKeyDown = event => {
    event = event || window.event;
    if (event.keyCode === 27 && event.target.nodeName === 'BODY') {
      this.handleClose();
    }
  };

  handleFilterClick = value => {
    const {dispatch} = this.props;
    dispatch(getNotificationsByFilter(value));
    try {
      const elms = document.getElementsByClassName('right-column');
      elms[elms.length - 1].scrollTop = 0;
    } catch (ignored) {/**/}
  };

  handleLoadMore = () => {
    const {dispatch, mainState} = this.props;
    dispatch(getNotificationsByFilter(mainState.notificationsModalFilter, true));
  };

  handleMarkAsAnswered = ntfItem => {
    const {dispatch} = this.props;
    dispatch(markNotificationAsAnswered(ntfItem));
  };

  renderNtfListInfo() {
    const {notificationsModalFilter, notifications} = this.props.mainState;
    const {total, data} = notifications[notificationsModalFilter];
    return (
      <div className="items-info">
        {data.length} of {total} {total !== 1 ? 'entries' : 'entry'}
      </div>
    );
  }

  isShowLoadMore() {
    const {notificationsModalFilter, notifications} = this.props.mainState;
    return notifications[notificationsModalFilter].data.length < notifications[notificationsModalFilter].total;
  }

  renderLoadMoreBtn() {
    const {notificationsIsLoading, notificationsIsMoreLoading} = this.props.mainState;
    const showLoadMore = this.isShowLoadMore();
    return showLoadMore && (
      <StyledModalFooter>
        <Button
          bsStyle="primary"
          onClick={this.handleLoadMore}
          disabled={notificationsIsLoading}
        >
          Load More
          {notificationsIsMoreLoading && <Loader/>}
        </Button>
      </StyledModalFooter>
    );
  }

  renderAllNtfInOneList() {
    const {notifications} = this.props.mainState;
    return (
      <div className="right-column all-in-one-list">
        {this.renderNtfListInfo()}
        <NotificationsList
          title="ALL"
          list={notifications.all.data}
          onMarkAsAnswered={isInternalUser() ? this.handleMarkAsAnswered : null}
        />
        {this.renderLoadMoreBtn()}
      </div>
    );
  }

  renderNtfLists(data) {
    const {notificationsModalFilter} = this.props.mainState;
    const {action_items, system_alerts, archived, unanswered_comments} = data;
    const isInternalUserVal = isInternalUser();
    const showUnansweredComments = isInternalUserVal && ['all', 'unanswered_comments'].includes(notificationsModalFilter);
    return (
      <div className="right-column">
        {this.renderNtfListInfo()}
        {showUnansweredComments ?
          <NotificationsList
            title="Unanswered Comments"
            list={Array.isArray(unanswered_comments) ? unanswered_comments : unanswered_comments.data}
            onMarkAsAnswered={isInternalUserVal ? this.handleMarkAsAnswered : null}
          /> : null
        }
        {['all', 'action_items'].includes(notificationsModalFilter) ?
          <NotificationsList
            title="Action Items"
            list={Array.isArray(action_items) ? action_items : action_items.data}
          /> : null
        }
        {['all', 'system_alerts'].includes(notificationsModalFilter) ?
          <NotificationsList
            title="System Alerts"
            list={((Array.isArray(system_alerts) ? system_alerts : system_alerts.data) ||  []).filter(a => !showUnansweredComments || a.category2 !== 'unanswered_comments')}
          /> : null
        }
        {['all', 'archived'].includes(notificationsModalFilter) ?
          <NotificationsList
            title="Archived"
            list={Array.isArray(archived) ? archived : archived.data}
          /> : null
        }
        {this.renderLoadMoreBtn()}
      </div>
    );
  }

  render() {
    const {
      notifications, notificationsModalFilter, notificationsIsLoading, notificationsIsMoreLoading,
      notificationsIsSaving
    } = this.props.mainState;
    const ntfIcon = classNames({
      'notification-icon': true,
      'new': (notifications.system_alerts.total || notifications.action_items.total)
    });
    const showLoadMore = this.isShowLoadMore();
    return (
      <div>
        <StyledModalBackdrop onClick={this.handleClose}/>
        <StyledModalDialog>
          <StyledModal>
            <StyledModalHeader>
              <div>
                <h4>New Alerts</h4>
                <div className="back-btn" onClick={this.handleClose}/>
                <div className={ntfIcon}/>
              </div>
            </StyledModalHeader>
            <StyledCloseModal className="icon-close-modal" onClick={this.handleClose}/>
            <StyledModalBody className={showLoadMore ? 'with-load-more' : undefined}>
              {((notificationsIsLoading && !notificationsIsMoreLoading) || notificationsIsSaving) &&
                <Loader className="full-screen"/>
              }
              <div className={`left-column${isInternalUser() ? ' for-internal-user' : ''}`}>
                <ul className={notificationsIsLoading ? 'disabled' : undefined}>
                  {this.filters.map((item, index) => {
                    return (
                      <li key={index} className={item.value === 'all' ? 'all-filter-item' : ''}>
                        <a
                          className={notificationsModalFilter === item.value ? 'active' : ''}
                          onClick={() => {this.handleFilterClick(item.value);}}>
                          {item.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {notificationsModalFilter !== 'all' ? null : this.renderAllNtfInOneList()}
              {this.renderNtfLists(notificationsModalFilter !== 'all' ? notifications : notifications.all.preparedData)}
            </StyledModalBody>
            <StyledLogo>
              <Link to="/"><img src={logoImg} width="100" alt="logo"/></Link>
            </StyledLogo>
          </StyledModal>
        </StyledModalDialog>
      </div>
    );
  }
}

const StyledModalHeader = styled.div`
  padding: 41px 45px 26px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #282828;
    margin-top: 0;
    margin-bottom: 0;
  }

  .loader-container {
    padding: 0;
    position: absolute;
    top: 40px;
    right: calc(50% - 10px);
    .react-spinner {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
    padding-bottom: 9px;

    h4 {
      display: none;
    }

    .loader-container {
      top: 20px;
    }

    .back-btn {
      background: url(${backImg}) no-repeat;
      background-position: 0 3px;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 19px;
      left: 23px;
    }
    .notification-icon {
      position: absolute;
      top: 19px;
      right: 25px;
      background-repeat: no-repeat;
      background-image: url(${alertImg});
      background-position: 0 0;
      width: 19px;
      height: 20px;
      &.new {
        background-image: url(${alertNewImg});
      }
    }
  }
`;

const StyledCloseModal = styled(StyledBaseCloseModal)`
  position: absolute;
  top: 48px;
  right: 43px;
  z-index: 1061;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledModalBody = styled.div`
  display: flex;
  position: fixed;
  top: 85px;
  bottom: 43px;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
  }

  .left-column {
    line-height: 40px;
    padding: 0 45px 0 0;
    text-align: right;
    width: 185px;
    ul {
      list-style: none;
      &.disabled {
        pointer-events: none;
      }
      li {
        @media (min-width: 992px) {
          width: 100px;
        }
      }
      a {
        color: #282828;
        font-size: 12px;
        transition: none;
        white-space: nowrap;
        &:hover {
          text-shadow: 0 0 0 #282828;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
    &.for-internal-user {
      width: 225px;
      ul {
        li {
          @media (min-width: 992px) {
            width: 140px;
          }
        }
      }
    }
  }
  .right-column {
    flex-grow: 1;
    padding-top: 10px;
    overflow: auto;
    @media (min-width: 992px) {
      &.all-in-one-list {
        display: none
      }
    }
    .items-info {
      padding: 10px 20px;
      background: #fff;
      font-size: 12px;
      text-align: right;
      position: sticky;
      top: 0;
      z-index: 1;
      @media (min-width: 992px) {
        padding: 12px 26px 13px 22px;
        position: fixed;
        top: 85px;
        right: 17px;
        border-bottom-left-radius: 3px;
        z-index: 1062;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(0, 0, 0, .1);
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }


  @media (max-width: 991px) {
    left: 2.66%;
    top: 50px;
    bottom: 0;
    flex-direction: column;

    .left-column {
      line-height: 16px;
      font-size: 13px;
      padding: 0;
      text-align: left;
      width: 100% !important;

      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0 23px;
        max-width: 100%;
        overflow-x: auto;
        background: #424242;
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          padding-right: 23px;

          &.all-filter-item {
            display: none;
          }

          a {
            color: #fff;
            opacity: 0.5;
            font-size: 13px;
            transition: none;
            padding: 7px 33px;
            border-radius: 100px;
            &:hover {
              font-weight: normal;
            }
            &.active {
              font-weight: normal;
              background: #000;
              opacity: 1;
            }
          }
        }
      }
    }
    .right-column {
      padding-top: 0;
      &.all-in-one-list + .right-column {
        display: none
      }
    }
  }

  &.with-load-more {
    @media (max-width: 767px) {
      bottom: 0;
    }
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 71px;
  padding-left: 0;/*185px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;*/

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    height: 60px;
    padding-left: 0;
  }

  button {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background-color: #646464;
    border-radius: 100px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 11px 40px 12px;

    &.btn-primary {

      &[disabled] {
        color: #c8c8c8;
        pointer-events: none;
      }

      @media (min-width: 992px) {

        &:hover {
          background-color: #565656;
        }
      }

      @media (max-width: 767px) {
        border-radius: 0;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 14px;
        background-color: #484848;
        padding: 23px 0;
        width: 100%;
      }
    }

    position: relative;

    .loader-container {
      padding: 0;
      height: 100%;
      position: absolute;
      bottom: -2px;
      right: -40px;
      left: unset;
      top: unset;
      width: unset;
      background-color: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      .react-spinner {
        width: 30px;
        height: 30px;
        top: 0;
        display: block;
      }
      @media (max-width: 767px) {
        width: 100%;
        bottom: 0;
        right: 0;
        background-color: rgb(255 255 255 / 0.5);
      }
    }
  }
`;

const StyledLogo = styled.div`
  position: absolute;
  bottom: 27px;
  left: 40px;

  @media (max-width: 991px) {
    display: none;
  }
`;

NotificationsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mainState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    mainState: state.main
  };
}

export default connect(
  mapStateToProps
)(withRouter(NotificationsModal));
