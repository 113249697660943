import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HeaderRow} from '../../components/table/HeaderRow';
import {Row} from '../../components/table/Row';

class Table extends Component {
  componentDidMount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.tbody.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onLoadMore === 'function') {
      this.refs.tbody.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const {tbody} = this.refs;

    const prevScrollTop = Number(tbody.getAttribute('data-scrolltop'));
    tbody.setAttribute('data-scrolltop', tbody.scrollTop);

    const pagingDistanceToBottom = tbody.scrollHeight / 3;
    if (tbody.scrollTop > prevScrollTop && tbody.offsetHeight + tbody.scrollTop + pagingDistanceToBottom > tbody.scrollHeight) {
      this.props.onLoadMore();
    }
  };

  render() {
    const {
      headerRow, headers, rows, sort, onHeaderCellClick, onRowClick, getRowClassNames,
      keyName, stripped, emptyText, isSeparatedRows, className
    } = this.props;
    return (
      <table className={`table${stripped ? ' table-striped' : ''} ${className || ''}`}>
        {headerRow || <HeaderRow headers={headers} sort={sort} onHeaderCellClick={onHeaderCellClick}/>}
        <tbody ref="tbody">
          {rows.map((row, i) =>
            <Row
              headers={headers}
              row={row}
              key={keyName ? `${row[keyName]}` : i}
              rowKey={keyName}
              onRowClick={onRowClick}
              getRowClassNames={getRowClassNames}
              isSeparatedRows={isSeparatedRows}
            />
          )}
          {
            !rows.length && emptyText ? (
              <tr><td className="data-table-empty" colSpan={Object.keys(headers).length}>{emptyText}</td></tr>
            ) : null
          }
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  headerRow: PropTypes.any,
  headers: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  sort: PropTypes.object,
  keyName: PropTypes.string,
  stripped: PropTypes.bool,
  emptyText: PropTypes.string,
  isSeparatedRows: PropTypes.bool,
  getRowClassNames: PropTypes.func,
  onHeaderCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onLoadMore: PropTypes.func,
  className: PropTypes.string
};

Table.defaultProps = {
  stripped: true
};

export default Table;

