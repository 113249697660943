import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {formatDate} from '../../../../../utils/general';

export const LastReportCell = ({value, row, onCloseSearch}) => {
  const linkTo = `/requests/${row.last_report_id}`;
  return (
    <StyledCell>
      {
        row.last_report_id ? (
          <Link
            to={linkTo}
            onClick={event => {
              event.stopPropagation();
              onCloseSearch(linkTo);
            }}
            >
            Last Report {formatDate(value, '', 'MMMM D, YYYY')}
          </Link>
        ) : null
      }
    </StyledCell>
  );
};

const StyledCell = styled.td`
  width: 170px;
  padding-left: 15px !important;
  a {
    vertical-align: bottom;
    font-weight: normal;
    font-size: 9px;
    text-decoration: underline;
    color: #28C0F0;
    &:hover {
      color: #1a99c1;
    }
  }
`;

LastReportCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  onCloseSearch: PropTypes.func
};
