import config from '../../../config';

export const UserRoles = {
  STUDIO: {value: 1, name: config.roles.STUDIO},
  PRODUCTION: {value: 2, name: config.roles.PRODUCTION},
  CLIENT_SERVICE: {value: 3, name: config.roles.CLIENT_SERVICE},
  OPERATOR: {value: 4, name: config.roles.OPERATOR},
  OPS_MANAGER: {value: 5, name: config.roles.OPS_MANAGER},
  FINISHING_HOUSE: {value: 6, name: config.roles.FINISHING_HOUSE},
  DW_MANAGER: {value: 7, name: config.roles.DW_MANAGER},
  ARTWORK_STUDIO: {value: 8, name: config.roles.ARTWORK_STUDIO},
  ARTWORK_POST_PARTNER: {value: 10, name: config.roles.ARTWORK_POST_PARTNER},
};

export const isArtworkClientUserRole = roleId => [
  UserRoles.ARTWORK_STUDIO.value,
  UserRoles.ARTWORK_POST_PARTNER.value,
].includes(roleId);

export const isClientUserRole = roleId =>
  [UserRoles.STUDIO.value, UserRoles.PRODUCTION.value, UserRoles.FINISHING_HOUSE.value].includes(roleId);

export const isAnyClientUserRole = roleId => isArtworkClientUserRole(roleId) || isClientUserRole(roleId);

export const isOperatorUserRole = roleId => roleId === UserRoles.OPERATOR.value;

export const isManagerUserRole = roleId =>
  [UserRoles.CLIENT_SERVICE, UserRoles.OPS_MANAGER, UserRoles.DW_MANAGER].some(r => r.value === roleId);

export const CompanyTypes = {
  STUDIO: {value: 1, name: 'studio', label: 'Studio'},
  PRODUCTION: {value: 2, name: 'production', label: 'Production'},
  FINISHING_HOUSE: {value: 4, name: 'finishing_house', label: 'Post Partner'},
  ARTWORK_STUDIO: {value: 5, name: 'artwork_studio', label: 'Artwork Studio'},
  ARTWORK_POST_PARTNER: {value: 6, name: 'artwork_post_partner', label: 'Artwork Post Partner'},
};

export const isArtworkCompanyType = typeId => [
  CompanyTypes.ARTWORK_STUDIO.value,
  CompanyTypes.ARTWORK_POST_PARTNER.value,
].includes(typeId);

export const CompanyTypesOptions = Object.values(CompanyTypes);

export const CompanyTypesForUserRoles = {
  [UserRoles.STUDIO.value]: CompanyTypes.STUDIO.name,
  [UserRoles.PRODUCTION.value]: CompanyTypes.PRODUCTION.name,
  [UserRoles.FINISHING_HOUSE.value]: CompanyTypes.FINISHING_HOUSE.name,
  [UserRoles.ARTWORK_STUDIO.value]: CompanyTypes.ARTWORK_STUDIO.name,
  [UserRoles.ARTWORK_POST_PARTNER.value]: CompanyTypes.ARTWORK_POST_PARTNER.name,
};

export const CompanyFlags = {
  PLACE_WATERMARK_IN_CENTER: 1,
  DEFAULT_VIEW_ONLY: 2,
  ALLOW_FILE_UPLOAD: 4,
  USE_SHOW_MANAGERS: 8,
  USE_POST_EXECUTIVES: 16, // for studio
  USE_STATUS_APPROVALS: 32, // for studio
  SHOW_DASHBOARD: 64, // for production
  ENABLE_OPERATOR_QUEUE: 128, // for artwork studio
  ENABLE_PSD_VIEWER_IN_BROWSER: 256, // for artwork studio
  NOTIFY_CLIENT_API: 512, // for studio
  CREATE_CLIENT_PROXIES: 1024, // for studio
  ENABLE_XYTECH_IMPORT: 2048, // for studio
};

export const UserFlags = {
  QOD_ADMIN: 1 << 2,
  ALWAYS_CREATE_PROXY: 1 << 3,
  MANAGE_USERS: 1 << 4,
  CREATE_USERS: 1 << 5,
  MANAGE_USERS_PERMISSIONS: 1 << 7,
  ARTWORK_MANAGER: 1 << 8,
  ARTWORK_OPERATOR: 1 << 9,
  ANALYTICS: 1 << 10,
  ARTWORK_DELIVERY: 1 << 14, // "Artwork Delivery" flag for artwork manager/operator
  CAN_ADD_ISSUES: 1 << 15, // for internal users
  MANAGE_OPERATOR_SCHEDULE: 1 << 16, // for internal users
  NETFLIX_INTERNAL: 1 << 17, // for internal users
  EDIT_OLD_SCHEDULES: 1 << 18, // 262144, "Edit Old Schedules" flag for MANAGE_OPERATOR_SCHEDULE
};

export const hasFlag = (flags, flag) => ((flags || 0) & flag) === flag;

export const getCompanyTypeLabel = typeId => {
  const companyTypeKey = Object.keys(CompanyTypes).find(k => CompanyTypes[k].value === typeId);
  let companyType = companyTypeKey && CompanyTypes[companyTypeKey].label;
  if (companyType === 'Finishing House') {
    companyType = 'Post Partner';
  }
  return companyType || '';
};

export const isDistributorAlreadyExists = (distributors, distributor) => {
  return distributors.some(d => d.DistributorID !== distributor.DistributorID &&
                                d.Distributor.toLowerCase() === distributor.Distributor.toLowerCase() &&
                                (d.CompanyID || null) === (distributor.CompanyID || null)
  );
};

export const UserRolesOptions = [{
  isClient: true,
  value: UserRoles.STUDIO.value,
  name: 'Studio'
}, {
  isClient: true,
  value: UserRoles.PRODUCTION.value,
  name: 'Production'
}, {
  isClient: true,
  value: UserRoles.FINISHING_HOUSE.value,
  name: 'Post Partner'
}, {
  value: UserRoles.OPERATOR.value,
  name: 'Operator'
}, {
  value: UserRoles.OPS_MANAGER.value,
  name: 'Ops Manager'
}, {
  value: UserRoles.CLIENT_SERVICE.value,
  name: 'Client Service'
}, {
  value: UserRoles.DW_MANAGER.value,
  name: 'DW Manager'
}, {
  isClient: true,
  value: UserRoles.ARTWORK_STUDIO.value,
  name: 'Artwork Studio'
}, {
  isClient: true,
  value: UserRoles.ARTWORK_POST_PARTNER.value,
  name: 'Artwork Post Partner'
}];
