import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SidebarWrap from './../../../../layouts/components/Sidebar';
import Comments from './Comments';
import Activities from './Activities';

class Sidebar extends Component {
  state = {
    activeLinkLevel: 2,
    displayCaptionsOnSidebar: false
  };

  updateComponent = () => {
    try {
      const sidebar = document.getElementsByClassName('sidebar-report-details-comments')[0];
      const bodyWidth = document.body.getBoundingClientRect().width;
      if (sidebar.previousElementSibling.getBoundingClientRect().top >= 0 || bodyWidth <= 767) {
        sidebar.classList.remove('sidebar-fixed');
      } else {
        sidebar.classList.add('sidebar-fixed');
      }
    } catch {}
  };

  handleWindowScroll = () => {
    this.updateComponent();
  };

  handleWindowResize = () => {
    this.updateComponent();
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  handleAllActivityClick = () => {
    this.setState({
      activeLinkLevel: 1
    });
  };

  handleReportClick = () => {
    this.setState({
      activeLinkLevel: 2
    });
  };

  getBreadcrumbsData = () => {
    const {reportTitle} = this.props;
    return [{
      name: 'All Activity',
      active: this.state.activeLinkLevel === 1,
      handleClick: this.handleAllActivityClick
    }, {
      name: reportTitle,
      active: this.state.activeLinkLevel === 2,
      handleClick: this.handleReportClick
    }];
  };

  onDisplayCaptionsClick = () => {
    const {displayCaptionsOnSidebar} = this.state;
    this.setState({
      displayCaptionsOnSidebar: !displayCaptionsOnSidebar
    });
  };

  render() {
    const {onClickDeleteIssue, onClickUndeleteIssue, showClosedCaptionsBtn} = this.props;
    const {activeLinkLevel, displayCaptionsOnSidebar} = this.state;
    const isShowComment = activeLinkLevel === 2;
    return (
      <SidebarWrap
        className="sidebar-report-details-comments"
        breadcrumbsData={this.getBreadcrumbsData()}
        captionsClick={showClosedCaptionsBtn && this.onDisplayCaptionsClick}
      >
        {isShowComment ?
          <Comments
            onDeleteIssue={onClickDeleteIssue}
            onUndeleteIssue={onClickUndeleteIssue}
            displayCaptionsOnSidebar={displayCaptionsOnSidebar}
          /> : <Activities/>
        }
      </SidebarWrap>
    );
  }
}

Sidebar.propTypes = {
  reportTitle: PropTypes.string,
  onClickDeleteIssue: PropTypes.func.isRequired,
  onClickUndeleteIssue: PropTypes.func.isRequired,
  showClosedCaptionsBtn: PropTypes.bool
};

export default Sidebar;
