import $ from 'jquery';
import config from '../../../config';
import {getErrorMessage} from '../../utils/general';
import {showError} from '../../layouts/actions';

export const REQUEST_WOID_IS_LOADING = 'REQUEST_WOID_IS_LOADING';

export const getRequestByWOMemfis = (history, woID) => dispatch => {
  dispatch({type: REQUEST_WOID_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/wo/${woID}`
  })
  .done(res => {
    const {requestID} = res;
    history.push(`/requests/details/${requestID}`);
  })
  .fail(error => {
    dispatch({type: REQUEST_WOID_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error), 'Request by WOID'));
    history.push('/requests');
  });
};
