import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  REQUESTS_CLEAR, REQUESTS_ADD_FILTERS, REQUESTS_GET_FILTERS, REQUESTS_CHANGE_FILTER_TYPE,
  REQUESTS_GET_REQUESTS, REQUESTS_SET_LOADING, REQUESTS_CHANGE_FILTER, REQUESTS_SAVED_FILTER,
  REQUESTS_CHANGE_QUICK_UPDATE_VALUES, REQUESTS_CLOSE_QUICK_UPDATE,
  REQUESTS_TOGGLE_NETFLIX,
} from './actions';

const initialState = {
  data: [],
  allowedFilters: [],
  selectedFilters: [{
    name: 'search',
    value: null
  }, {
    name: 'sort',
    value: {}
  }],
  total: 0,
  loaders: {
    quickUpdate: false,
    filters: true,
    requests: true,
    more: false
  },
  quickUpdateDate: {
    show: false,
    QcProjectID: null,
  },
  isNetflixMode: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case REQUESTS_CLEAR: {
      return {...initialState};
    }

    case REQUESTS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case REQUESTS_GET_FILTERS: {
      if (action.noStatistics && state.allowedFilters.length) {
        return state;
      }
      return {
        ...state,
        allowedFilters: action.allowedFilters
      }
    }

    case REQUESTS_CHANGE_FILTER: {
      const {selectedFilters} = state;
      const isFilterExist = !!selectedFilters.find(filter => filter.name === action.name);
      if (isFilterExist) {
        return {
          ...state,
          selectedFilters: selectedFilters.map(item => {
            return item.name === action.name ? {
              ...item,
              value: action.value || null
            } : item;
          })
        };
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters,
          {
            name: action.name,
            value: action.value
          }
        ]
      }
    }

    case REQUESTS_SAVED_FILTER: {
      const urlFilters = {...action.urlFilters};
      if (!urlFilters.search) {
        urlFilters.search = null;
      }
      if (!urlFilters.sort) {
        urlFilters.sort = {};
      }

      const nonNumericFilters = [
        'from', 'to', 'state',
        'season_num', 'episode_num', 'req_version',
        'dw_qc_status',
        'project_group',
      ];
      const selectedFilters = [];
      Object.keys(urlFilters).forEach(key => {
        const value = Array.isArray(urlFilters[key]) && !nonNumericFilters.includes(key) && !key.startsWith('netflix_') ?
          urlFilters[key].map(item => +item) :
          urlFilters[key];

        const res = {name: key, value};
        if (key === 'Last Updated') {
          res.disabled = true;
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      });

      return {
        ...state,
        selectedFilters
      }
    }

    case REQUESTS_CHANGE_FILTER_TYPE: {
      return {
        ...state,
        selectedFilters: state.selectedFilters.map(item => {
          return item.name === action.filterName ? {
            name: action.newFilterName,
            title: action.newFilterLabel,
            value: action.defaultValue ? (Array.isArray(action.defaultValue) ? action.defaultValue : [action.defaultValue]) : null
          } : item;
        })
      };
    }

    case REQUESTS_ADD_FILTERS: {
      const selectedFilters = [...state.selectedFilters];
      const res = {
        name: action.filter.name,
        title: action.filter.label,
        value: action.filter.defaultValue ?
         (Array.isArray(action.filter.defaultValue) ? action.filter.defaultValue : [action.filter.defaultValue]) : null,
        disabled: action.filter.disabled
      };

      if (action.filter.required) {
        selectedFilters.unshift(res);
      } else {
        selectedFilters.push(res);
      }

      return {
        ...state,
        selectedFilters
      };
    }

    case REQUESTS_GET_REQUESTS: {
      const {selectedFilters} = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map(item => {
          return item.name === 'search' && typeof item.value === 'string' ? {
            ...item,
            value: item.value.trim()
          } : item;
        }),
        loaders: {
          ...state.loaders,
          requests: false,
          more: false
        },
        data: [...action.data],
        total: action.total
      };
    }

    case REQUESTS_CHANGE_QUICK_UPDATE_VALUES: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          quickUpdate: false
        },
        quickUpdateDate: {
          ...state.quickUpdateDate,
          ...action.values
        }
      }
    }

    case REQUESTS_CLOSE_QUICK_UPDATE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          quickUpdate: false
        },
        quickUpdateDate: initialState.quickUpdateDate
      }
    }

    case REQUESTS_TOGGLE_NETFLIX: {
      return {
        ...(action.isInit ? state : initialState),
        isNetflixMode: action.modeIsOn || initialState.isNetflixMode
      }
    }

    default: {
      return state;
    }
  }
}
