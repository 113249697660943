/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../../../pages/Login/actions';
import {
  NET_ADMIN_STEPS_SET_LOADING,
  NET_ADMIN_STEPS_CHANGE_TOP_FILTERS,
  NET_ADMIN_STEPS_RECEIVE,
  NET_ADMIN_QC_PROFILE_RECEIVE,
} from './actions';

const initialState = {
  loaders: {
    isLoadingSteps: false,
  },
  topFilters: {},
  steps: {},
  tasks: [],
  dictionaries: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case NET_ADMIN_STEPS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case NET_ADMIN_STEPS_CHANGE_TOP_FILTERS: {
      return {
        ...state,
        topFilters: {
          ...state.topFilters,
          ...action.data
        }
      };
    }

    case NET_ADMIN_STEPS_RECEIVE: {
      return {
        ...state,
        dictionaries: action.dictionaries || initialState.dictionaries,
        steps: action.steps || initialState.steps,
      };
    }

    case NET_ADMIN_QC_PROFILE_RECEIVE: {
      return {
        ...state,
        steps: action.steps || initialState.steps,
        tasks: action.qc_tasks || initialState.tasks,
      };
    }

    default:
      return state;
  }
}
