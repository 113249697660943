import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {
  setActiveTab,
  showWoStepsModal,
} from '../../actions';
import Tabs, {getTabTitle} from '../../../../../components/Tabs';
import {Tab} from 'react-bootstrap';
import CostDetails from '../../../../RequestDetails/components/CostDetails';
import WorkOrderTab from '../../../../RequestDetails/components/WorkOrderTab';
import General from './General';
import Metadata from './Metadata';

const TAB_KEY_GENERAL = 1;
const TAB_KEY_METADATA = 2;
export const TAB_KEY_COST = 4;
const TAB_KEY_WO = 5;
export const DEFAULT_TAB = TAB_KEY_GENERAL;

function AllTabs(props) {
  const dispatch = useDispatch();
  const requestDetails = useSelector(state => state.netflixRequestDetails);
  const {
    activeTab, loaders, details, woSteps, costDetails
  } = requestDetails;
  const {isLoadingWoSteps} = loaders;

  const handleChangeActiveTab = key => {
    if (key !== activeTab) {
      dispatch(setActiveTab(key));
    }
  };

  const handleClickEditSteps = () => {
    dispatch(showWoStepsModal());
  };

  return (
    <StyledTabs
      id="tabs"
      activeKey={activeTab}
      onSelect={handleChangeActiveTab}
      animation={false}
    >
      <Tab
        eventKey={TAB_KEY_GENERAL}
        title={getTabTitle("General")}
        className="general-view"
      >
        <General/>
      </Tab>
      <Tab
        eventKey={TAB_KEY_METADATA}
        title={getTabTitle("Metadata")}
        className="metadata-view"
      >
        <Metadata/>
      </Tab>
      <Tab
        eventKey={TAB_KEY_COST}
        title={getTabTitle("Cost Details", isLoadingWoSteps ? 'with-loader' : null)}
        className="cost-details-view"
      >
        <CostDetails
          isLoading={isLoadingWoSteps}
          data={costDetails}
          isSeasonRequest={false}
        />
      </Tab>
      <Tab
        eventKey={TAB_KEY_WO}
        title={getTabTitle("Work Order", isLoadingWoSteps ? 'with-loader' : null)}
        className="work-order-view"
      >
        <WorkOrderTab
          details={details}
          subSteps={woSteps}
          isLoadingSubSteps={isLoadingWoSteps}
          onClickEdit={handleClickEditSteps}
        />
      </Tab>
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 45px;

  @media (max-width: 767px) {
    margin: 0;
    padding-top: 70px;
    background-color: #f3f3f3;
  }

  > .nav-tabs {
    float: none;
    margin-left: 27px;
    margin-right: 0;

    @media (max-width: 767px) {
      margin-left: 25px;
    }

    > li {
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 13px;
      }

      a {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        color: #818284;
        margin-right: 0;

        @media (max-width: 767px) {
          border-bottom: none;
        }

        .tab-title {
          padding: 10px 27px 10px 7px !important;

          @media (min-width: 768px) {
            padding-left: 13px !important;
          }

          @media (max-width: 767px) {
            background-color: #EAEAEA;
          }

          .text {
            display: inline;

            @media (max-width: 767px) {
              font-size: 11px;
              line-height: 12px;
            }
          }

          &.red {

            .text {
              color: #ff7575;
            }
          }

          &.unsaved {

            .text {
              position: relative;

              &:after {
                content: '*';
                position: absolute;
                top: -7px;
                right: -10px;
                color: #ff7575;
                font-size: 14px;
              }
            }
          }
        }
      }

      &.active {

        a {
          background-color: #fff;
          box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
          color: #282828;

          @media (max-width: 767px) {
            .tab-title {
              background-color: #fff;
            }
          }

          .tab-title {

            &.red {

              .text {
                color: red;
              }
            }

            &.unsaved {

              .text {

                &:after {
                  color: red;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-content {
    flex: 1 0 auto;
    border: 1px solid #ececec;
    border-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;

    @media (max-width: 767px) {
      background-color: #fff;
      border: 0;
      margin-left: 0;
      margin-right: 0;
    }

    > .tab-pane {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .unavailable-feature .alert-text {
    border: 1px solid rgba(97, 96, 97, .4);
    border-radius: 4px;
    background-color: rgba(97, 96, 97, .1);
    padding: 7px;
    display: inline-block;
  }
`;

export default AllTabs;
