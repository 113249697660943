import React from 'react';
import StyledBtn from './FilterBtnStyled';

const AddFilterBtn = ({onClick}) => {
  return (
    <StyledBtn
      className="add-another-btn"
      onClick={onClick}
    >+ Add Another Filter</StyledBtn>
  );
};

export default AddFilterBtn;