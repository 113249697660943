/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {
  markCompleted,
  completeDwQc,
  exportRequestIssues,
  showGeneralComments,
  toggleImportIssuesModal,
  toggleAdditionalReportsModal,
} from '../actions';
import {
  validateTimeCodeSettings,
} from '../utils';
import {Row} from 'react-bootstrap';
import Can from '../../../components/Can';
import backImg from '../../../../assets/images/back.svg';
import doneImg from '../../../../assets/images/circle-check-gray.svg';
import folderImg from '../../../../assets/images/folder.svg';
import uploadImg from '../../../../assets/images/upload.svg';
import submitReplacementImg from '../../../../assets/images/submit_replacement.svg';
import emailImg from '../../../../assets/images/email.svg';
import printImg from '../../../../assets/images/print.svg';
import exportImg from '../../../../assets/images/export.svg';
import commentImg from '../../../../assets/images/issueTable/comment.svg';
import backWhiteBtn from '../../../../assets/images/back-btn-white.svg';
import moreBtn from '../../../../assets/images/more-btn.svg';
import {confirmAlert} from 'react-confirm-alert';
import {getUserRoles, isUserCanComment, isManagerUser} from '../../../utils/general';
import SelectAdvanced from '../../../components/forms/SelectAdvanced';
import {toggleShowSidebar} from '../../../layouts/actions';
import isMobile from 'is-mobile';

class Title extends Component {
  state = {
    isShowHeaderMore: false
  };

  toggleShowMore = () => {
    this.setState({isShowHeaderMore: !this.state.isShowHeaderMore});
  };

  handleBackToList = () => {
    const {history} = this.props;
    history.push('/requests');
  };

  handleBackToRequestDetails = () => {
    const {history, match} = this.props;
    history.push(`/requests/details/${match.params.requestId}`);
  };

  handleConfirmCompleted = () => {
    const {dispatch, match} = this.props;
    dispatch(markCompleted(match.params.requestId));
  };

  handleCompleteDwQc = () => {
    const {dispatch, match} = this.props;
    dispatch(completeDwQc(match.params.requestId));
  }

  showConfirmAlert = () => {
    confirmAlert({
      title: 'Are You Sure?',
      message: 'Not all of the issues on this request have been fixed. ' +
        'Continuing will mark all remaining issues as Approved. Are you sure?',
      buttons: [{
        label: 'Cancel'
      }, {
        label: 'Confirm',
        onClick: this.handleConfirmCompleted
      }]
    });
  };

  handleMarkRequestAsDone = () => {
    const {data} = this.props;
    if (data.has_unresolved_issues) {
      this.showConfirmAlert();
    } else {
      this.handleConfirmCompleted();
    }
  };

  handleExportIssues = () => {
    const {dispatch, match} = this.props;
    dispatch(exportRequestIssues(match.params.requestId));
  };

  handleShowGeneralComments = () => {
    const {dispatch, match, isInteractiveView, loadingGeneralComments} = this.props;
    !loadingGeneralComments && dispatch(showGeneralComments(match.params.requestId, isInteractiveView ? undefined : () => {
      dispatch(toggleShowSidebar(true));
      try {
        document.getElementsByClassName('sidebar-general-comment-form')[0].getElementsByTagName('textarea')[0].focus();
      } catch {}
    }));
  };

  handleClickImportIssues = () => {
    const {dispatch} = this.props;
    dispatch(toggleImportIssuesModal(true));
  };

  handleClickAdditionalReports = () => {
    const {dispatch} = this.props;
    dispatch(toggleAdditionalReportsModal(true));
  };

  render() {
    const {isShowHeaderMore} = this.state;
    const {
      data, onClickAddIssue, onClickCreateFixList, onClickSubmitReplacement, onClickCreateEmailReport, onSelectVersion,
      isDisabledAddIssue, loading, match, timeline, onClickImportCsvIssues, issuesLoading, onClickPrintReport,
      onClickStartBatchReports, onClickEditFrameRate, onClickGeneralReportData, onClickReturnToIssue, onClickBatchEditIssues
    } = this.props;
    const requestId = Number(match.params.requestId);
    const {QcStatusID, RequestStatusID, UserPermissionType, LinguisticQcComplete, versions, IsReportSent, IsHardingTestRequest} = data;
    const showVersions = !!versions && versions.length > 1;
    const showProfileInVersion = showVersions && versions.some(v => v.QcProfileID !== data.QcProfileID);
    const isInvalidTimeCodeSettings = !validateTimeCodeSettings(data);
    return (
      <StyledTitle>
        <div className="title-content">
          <div className="wrap-title">
            <img src={backImg} alt="Back Button" title="Back to request list" className={`back-btn${loading ? ' disabled' : ''}`} onClick={this.handleBackToList}/>
            {isManagerUser() &&
              <BackToDetailsBtn
                className={loading ? 'disabled' : undefined}
                title="Open Request Details"
                onClick={this.handleBackToRequestDetails}
              >
                <i className={`fa${isMobile() ? 's' : 'r'} fa-file-alt`}/>
              </BackToDetailsBtn>
            }
            <div className="title">
              <span>QC Report</span>
              <h1>{data.Title}{data.TrailerEpisodeName ? ` / ${data.TrailerEpisodeName}` : ''}{!showVersions && data.Tag ? ` - ${data.Tag}` : ''}{data.WOMemfis ? ' (' + data.WOMemfis + ')' : ''}</h1>
            </div>
            <i className="visible-xs icon-menu" onClick={this.toggleShowMore}/>
          </div>
          <div className="hidden-xs format-versions" title="Version">
            {
              showVersions ? (
                <SelectAdvanced
                  name="RequestID"
                  value={data.ParentID || data.RequestID}
                  valueKey="VersionID"
                  labelKey="VersionName"
                  options={versions}
                  optionRenderer={option => {
                    const {VersionName, QcProfile} = option;
                    if (showProfileInVersion) {
                      return <><span style={{marginRight: '7px', opacity: 0.7}} title="QC Profile">{QcProfile}</span>{VersionName}</>;
                    }
                    return VersionName;
                  }}
                  onSelect={onSelectVersion}
                  disabled={!onSelectVersion}
                />
              ) : null
            }
          </div>
        </div>
        <div className={`title-actions ${isShowHeaderMore ? 'mobile-show-more' : ''}`}>
          {
            <MenuStyled className="menu">
              <Can
                roles={getUserRoles()}
                perform="report-details:show-general-report-btn"
                yes={() => (
                  <li>
                    <a onClick={onClickGeneralReportData}>
                      <i className="far fa-file-lines icon"/><span>General report</span>
                    </a>
                  </li>
                )}
              />
              <Can
                  roles={getUserRoles()}
                  perform="report-details:fill-report-frame-rate"
                  yes={() => (
                      <>
                        <li>
                          <a onClick={onClickEditFrameRate}>
                            <i className="fas fa-camera icon"/><span>Framerate</span>
                          </a>
                        </li>
                        <li className="separator"/>
                      </>
                  )}
              />
              <Can
                roles={getUserRoles()}
                perform="report-details:show-add-issue-btn"
                data={data}
                yes={() => (
                  <li>
                    <a
                      onClick={isInvalidTimeCodeSettings ? undefined : onClickAddIssue}
                      disabled={isDisabledAddIssue || isInvalidTimeCodeSettings}
                      className={isInvalidTimeCodeSettings ? 'disabled' : undefined}
                      title={
                        isInvalidTimeCodeSettings
                          ? 'Adding an issue is disallowed until MEMFIS sends START OF FILE and frame rate for this request'
                          : 'Add new issue, shortcut: (N)'
                      }
                    >
                      <i
                        className={`${isInvalidTimeCodeSettings ? 'far' : 'fas'} fa-plus-circle icon`}
                      />
                      <span>Add Issue</span>
                    </a>
                  </li>
                )}
              />
              <Can
                  roles={getUserRoles()}
                  perform="report-details:show-batch-edit-issues-btn"
                  yes={() => (
                      <li>
                        <a onClick={onClickBatchEditIssues}>
                          <i className="fa-regular fa-edit icon"/><span>Batch Edit Issues</span>
                        </a>
                      </li>
                  )}
              />
              {onClickReturnToIssue && <Can
                roles={getUserRoles()}
                perform="report-details:show-add-issue-btn"
                data={data}
                yes={() => (
                    <>
                      <li>
                        <a onClick={onClickReturnToIssue}>
                          <i className="fas fa-regular fa-circle-left icon"/><span>Return to Issue</span>
                        </a>
                      </li>
                    </>
                )}
              />}
              <Can
                roles={getUserRoles()}
                perform="report-details:show-import-issues-btn"
                yes={() => (
                  <>
                    <li>
                      <a onClick={this.handleClickImportIssues}>
                        <i className="fas fa-file-import icon"/><span>Import issues</span>
                      </a>
                    </li>
                    <li className="separator"/>
                  </>
                )}
              />
              {LinguisticQcComplete > 0 ? null : (
                <Can
                  roles={getUserRoles()}
                  perform="report-details:complete-dw-qc-btn"
                  data={data}
                  yes={() => (
                    <li className="item-complete-dw-qc-btn">
                      <a onClick={this.handleCompleteDwQc}><i className="done-icon icon"/><span>Complete QC</span></a>
                    </li>
                  )}
                /> )
              }
              <Can
                roles={getUserRoles()}
                perform="report-details:show-mark-done-btn"
                data={{RequestStatusID, UserPermissionType}}
                yes={() => (
                  <Fragment>
                    <li className="item-mark-done-btn">
                      <a onClick={this.handleMarkRequestAsDone}><i className="done-icon icon"/><span>Mark as Done</span></a>
                    </li>
                    <li className="separator"/>
                  </Fragment>
                )}
              />
              {IsHardingTestRequest > 0 ? null : (
                <Can
                  roles={getUserRoles()}
                  perform="report-details:show-create-fixlist-btn"
                  yes={() => (
                    <li>
                      <a onClick={onClickCreateFixList}><i className="folder-icon icon"/><span>Create Fixlist</span></a>
                    </li>
                  )}
                />)
              }
              <Can
                roles={getUserRoles()}
                perform="report-details:show-submit-replacement-btn"
                data={{QcStatusID, RequestStatusID, UserPermissionType, requestId, timeline}}
                yes={() => (
                  <li>
                    <a onClick={onClickSubmitReplacement}><i className="submit-replacement-icon icon"/><span>Submit Replacement</span></a>
                  </li>
                )}
              />
              <Can
                roles={getUserRoles()}
                perform="report-details:show-email-report-btn"
                data={{IsReportSent}}
                yes={() => (
                  <Fragment>
                    <li className="separator"/>
                    <li>
                      <a onClick={onClickCreateEmailReport} disabled={issuesLoading}><i className="email-icon icon"/><span>Email QC Report</span></a>
                    </li>
                  </Fragment>
                )}
              />
              <Can
                roles={getUserRoles()}
                perform="report-details:show-batch-reports-btn"
                yes={() => (
                  <li>
                    <a onClick={onClickStartBatchReports} disabled={issuesLoading}><i className="fas fa-envelopes icon"/><span>Batch QC Reports</span></a>
                  </li>
                )}
              />
              {!IsHardingTestRequest &&
                <Can
                  roles={getUserRoles()}
                  perform="report-details:show-print-report-btn"
                  data={{IsReportSent}}
                  yes={() => (
                    <li>
                      <a onClick={onClickPrintReport} disabled={issuesLoading}><i className="print-icon icon"/><span>Print QC Report</span></a>
                    </li>
                  )}
                />
              }
              <li>
                <a onClick={this.handleClickAdditionalReports}>
                  <i className="fa-regular fa-file-chart-column icon"/><span>Additional Reports</span>
                </a>
              </li>
              {!IsHardingTestRequest && <>
                <li className="separator"/>
                <li>
                  <a onClick={this.handleExportIssues}><i className="export-icon icon"/><span>Export Issues</span></a>
                </li>
                <Can
                  roles={getUserRoles()}
                  perform="report-details:show-upload-issues-statuses-btn"
                  data={UserPermissionType}
                  yes={() => (
                    <li>
                      <a onClick={onClickImportCsvIssues}>
                        <i className="upload-icon icon"/><span>Upload Issue Statuses</span>
                      </a>
                    </li>
                  )}
                />
                </>
              }
              {isUserCanComment(UserPermissionType) &&
                <>
                  <li className="separator"/>
                  <li>
                    <a onClick={this.handleShowGeneralComments}>
                      <i className="comment-icon icon"/><span>General Comments</span>
                    </a>
                  </li>
                </>
              }
            </MenuStyled>
          }
        </div>
      </StyledTitle>
    );
  }
}

export const MenuStyled = styled.ul`
  display: flex;
  padding: 21px 0 1px 0;
  justify-content: flex-end;
  width: 100%;
  li {
    list-style: none;
    margin: 0 10px;
    &.separator {
      border: 1px solid #ebebeb;
      margin: -10px 10px;
      + li.separator {
        display: none;
      }
    }
    &:hover a span {
      max-width: 1000px;
      transition: all 1.5s ease-in-out;
    }
    &.item-complete-dw-qc-btn {
      + .item-mark-done-btn {
        i {
          background-color: #d1ffd1;
          border-radius: 50%;
        }
      }
    }
  }
  a {
    color: #282828;
    display: flex;
    font-size: 11px;
    line-height: 23px;
    white-space: nowrap;
    &[disabled] {
      &:not(.disabled) {
        pointer-events: none;
      }
      &.disabled {
        cursor: default;
      }
      opacity: 0.65;
    }
    span {
      max-width: 0;
      overflow: hidden;
      transition: all 0.1s ease-in-out;
    }
  }
  .icon {
    background-repeat: no-repeat;
    display: block;
    margin: 0 3px;
    height: 21px;
    width: 21px;
    &.fa-light,
    &.fa-thin,
    &.fa-regular,
    &.fa-solid,
    &.far,
    &.fas {
      color: #282828;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      &.fa-file-chart-column {
        font-size: 18px;
      }
      &.fa-video {
        font-size: 16px;
      }
    }
  }
  .done-icon {
    background-image: url(${doneImg});
  }
  .folder-icon {
    background-image: url(${folderImg});
  }
  .upload-icon {
    background-image: url(${uploadImg});
  }
  .submit-replacement-icon {
    background-image: url(${submitReplacementImg});
  }
  .email-icon {
    background-image: url(${emailImg});
  }
  .print-icon {
    background-image: url(${printImg});
  }
  .export-icon {
    background-image: url(${exportImg});
  }
  .comment-icon {
    background-image: url(${commentImg});
    background-size: 16px;
    background-position: center 5px;
  }
`;

const StyledTitle = styled(Row)`
  margin-bottom: 6px;
  padding: 0 15px;

  display: flex;

  > div, .wrap-title {
    display: flex;
  }

  .title-content {
    flex: auto !important;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .title-actions {
    flex-shrink: 0;
    display: inline;
  }

  .back-btn {
    cursor: pointer;
    margin-right: 9px;
    margin-left: 10px;
    margin-top: 13px;
    vertical-align: top;
    width: 20px;
    &.disabled {
      pointer-events: none;
      opacity: 0.65;
    }
  }
  .title {
    span {
      color: #282828;
      font-size: 13px;
      line-height: 14px;
      opacity: 0.4;
    }
    h1 {
      color: #282828;
      font-size: 24px;
      line-height: 25px;
      margin: 0 0 10px;
      font-weight: normal;
      letter-spacing: unset;
      text-transform: unset;
      word-break: break-all;
    }
  }

  @media (max-width: 768px) {
    background: linear-gradient(100.26deg,#0DB4D9 0%,rgba(2,80,173,0.25) 99.75%),#004A8D;
    margin-bottom: 0;
    padding: 0;
    position: relative;
    .title-content {
      flex-wrap: wrap;
      width: 100%;
      .back-btn {
        content: url(${backWhiteBtn});
        margin-top: 0;
        margin-left: 0;
      }
      .wrap-title {
        padding: 15px 20px;
        position: relative;
        width: 100%;
        .icon-menu {
          background: url(${moreBtn}) no-repeat center center;
          height: 20px;
          position: absolute;
          right: 20px;
          top: 37px;
          width: 20px;
        }
      }
      .title {
        width: 77%;
        h1 {
          color: #fff;
          font-size: 18px;
          max-width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
        }
        span {
          color: #fff;
        }
      }
    }
    .title-actions {
      background: #fff;
      border-radius: 3px;
      display: none;
      position: absolute;
      right: 40px;
      top: 29px;
      &.mobile-show-more {
        display: block;
      }
      .menu {
        margin-bottom: 5px;
        padding-top: 5px;
        .separator {
          margin: -5px 5px;
        }
      }
      &:after {
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #fff;
        content:"\\A";
        position: absolute;
        right: -5px;
        top: 7px
      }
    }
  }

  .format-versions {
    margin: 0 0 10px;

    .form-group {
      margin: 0 0 0 39px;
      width: 280px;
      .Select.is-searchable.Select--single {
        z-index: 2;
        * {
          font-size: 12px !important;
        }
        .Select-control {
          border-radius: 3px;
          cursor: pointer;
          height: 24px;
          min-width: 159px;
          .Select-multi-value-wrapper {
            .Select-placeholder, .Select-value {
              line-height: 12px;
              padding: 7px 17px 4px 8px;
            }
            .Select-value .Select-value-label {
              font-weight: normal;
            }
            .Select-input {
              height: 22px;
              padding-left: 8px;
              padding-right: 7px;
              input {
                line-height: 12px;
                padding-top: 7px;
                padding-bottom: 4px;
              }
            }
          }
          .Select-arrow-zone {
            width: 21px;
            img {
              opacity: 0.6;
              width: 10px;
            }
          }
        }
        &:not(.is-open), &:not(.is-focused) {
          .Select-control {
            background-color: #F2F2F2;
            border: 0.5px solid #DADADA;
          }
        }

        .Select-menu {
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background: rgba(0, 0, 0, .1);
          }
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgba(0, 0, 0, .2);
            -webkit-box-shadow: none;
          }
        }
      }
    }
  }
`;

const BackToDetailsBtn = styled.div`
  margin-top: 11px;
  margin-right: 10px;
  margin-left: 4px;
  min-width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }
  @media (max-width: 767px) {
    margin-top: -2px;
    margin-left: 0;
    color: #fff;
  }
`;

Title.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingGeneralComments: PropTypes.bool.isRequired,
  isDisabledAddIssue: PropTypes.bool.isRequired,
  onClickAddIssue: PropTypes.func,
  onClickCreateEmailReport: PropTypes.func,
  onClickStartBatchReports: PropTypes.func,
  onClickPrintReport: PropTypes.func,
  onClickCreateFixList: PropTypes.func,
  onClickSubmitReplacement: PropTypes.func,
  onClickImportCsvIssues: PropTypes.func,
  onClickGeneralReportData: PropTypes.func,
  onSelectVersion: PropTypes.func,
  isInteractiveView: PropTypes.bool
};

export default connect()(
  withRouter(Title)
);
