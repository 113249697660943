import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {buildReportLink} from '../../../utils/general';

const LinkReportCell = (props) => {
  const {value, row, className, title} = props;
  const url = value && row.RequestID && buildReportLink(row);
  return (
    <StyledLinkReportCell
      className={className}
      title={title}
    >
      {url ? <Link title="Open Report" to={url} target="_blank">{value}</Link> : value}
    </StyledLinkReportCell>
  );
};

const StyledLinkReportCell = styled.td`
  a {
    color: #29A4CB;
    font-weight: bold;
    &:hover {
      color: #228daf;
    }
  }
`;

LinkReportCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string
};

export default LinkReportCell;
