import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import Autocomplete from '../../../../components/forms/Autocomplete';

function InputControl(props) {
  const {
    value, onChange, onFocus, onBlur, placeholder, maxLength, type, minWidth, suggestions
  } = props;

  const withSuggestions = Boolean(suggestions && suggestions.length);

  const inputRef = useRef(null);

  const inputProps = {
    ref: inputRef,
    placeholder,
    value: value || '',
    onChange: ({target}, callback) => {
      onChange(target.value);
      //inputRef.current.blur();
      if (withSuggestions && typeof callback === 'function') {
        callback();
      }
    },
    onFocus,
    onBlur,
    autoFocus: true,
    maxLength,
    style: {minWidth: minWidth || 65}
  };

  useEffect(() => {
    try {
      if (inputRef && inputRef.current) {
        inputRef.current.value = value || '';
      }
    } catch {}
  }, [value]);

  if (withSuggestions) {
    return <Autocomplete inputProps={inputProps} suggestions={suggestions} />;
  }

  return type === 'textarea' ? <textarea rows={3} {...inputProps} /> : <input {...inputProps} />;
}

function InputTextCell(props) {
  const {
    className, value, onFocus, onBlur, placeholder, readOnly
  } = props;

  const [editMode, setEditMode] = useState(false);

  const startX = useRef(0);
  const startY = useRef(0);

  const mouseHasMovedAfterMouseDown = event => {
    return Math.abs(startX.current - event.clientX) >= 5 || Math.abs(startY.current - event.clientY) >= 5;
  }

  const onReadViewClick = event => {
    if (!mouseHasMovedAfterMouseDown(event)) {
      setEditMode(true);
      onFocus();
    }
  }

  return (
    <StyledCell className={className}>
      {
        readOnly ? value :
          editMode ?
            <InputControl
              {...props}
              onBlur={() => {setEditMode(false);onBlur();}}
            /> :
            <div
              className={`selected-option${value ? '' : ' empty'}`}
              onClick={onReadViewClick}
              onMouseDown={e => {
                startX.current = e.clientX;
                startY.current = e.clientY;
              }}
              title={`Click to ${value ? 'edit' : 'enter'}`}
            >
              <span>{value || (placeholder === undefined ? 'Enter...' : placeholder)}</span>
            </div>
      }
    </StyledCell>
  );
}

const StyledCell = styled.td`
  > .selected-option {
    padding-left: 2px;
    min-height: 30px;
    border: 0.5px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.empty {
      color: #808080;
    }
  }
  &:not(.disabled) {
    > .selected-option {
      :hover {
        background-color: #f2f2f2;
        border-color: #b3b3b3;
      }
    }
  }
  input, textarea {
    padding: 3px 5px;
    background: #FFFFFF;
    border: 0.5px solid #DADADA;
    border-radius: 3px;
    outline: 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
    line-height: 13px;
    :hover, :focus {
      border-color: #007eff;
    }
  }
  input {
    height: 30px;
    line-height: 23px;
  }
  textarea {
    padding: 5px;
    max-width: 100%;
    min-height: 30px;
    resize: vertical;
  }

  position: relative;
  .suggestions {
    list-style: none;
    margin: 0 0 8px;
    padding: 4px 0;
    width: 100%;
    max-height: 95px;
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    border: 0.5px solid #DADADA;
    border-radius: 0 0 3px 3px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 12px;
    z-index: 1;
    .suggestion-item {
      padding: 8px 12px 8px 5px;
      width: 100%;
      color: inherit;
      cursor: default;
      display: block;
      font-size: inherit;
      user-select: none;
      box-sizing: border-box;
      &:not(.is-active){
        &:hover {
          background-color: #DEEBFF;
        }
        &:focus {
          background-color: #B2D4FF;
        }
      }
      &.is-active {
        color: #fff;
        background-color: #2684FF;
      }
    }
  }
`;

export default InputTextCell;