import config from '../../../config';
import $ from 'jquery';
import {getSeasonEpisodes} from '../NewRequest/actions';
import {QC_REQUEST_TYPE_EPISODE, NEW_REQUEST_STEP_TURNAROUND} from '../NewRequest/constants';
import {showError} from '../../layouts/actions';
import {isManagerUser} from '../../utils/general';

export const SEASON_DETAILS_SET_ACTIVE_TAB = 'SEASON_DETAILS_SET_ACTIVE_TAB';
export const SEASON_DETAILS_CLEAR = 'SEASON_DETAILS_CLEAR';
export const SEASON_DETAILS_IS_LOADING = 'SEASON_DETAILS_IS_LOADING';
export const SEASON_DETAILS_GET_LIST = 'SEASON_DETAILS_GET_LIST';
export const SEASON_DETAILS_SELECT_SEASON = 'SEASON_DETAILS_SELECT_SEASON';
export const SEASON_DETAILS_UPDATE_EDIT_MODAL = 'SEASON_DETAILS_UPDATE_EDIT_MODAL';
export const SEASON_DETAILS_UPDATE_EPISODE_MILESTONES = 'SEASON_DETAILS_UPDATE_EPISODE_MILESTONES';
export const SEASON_DETAILS_LOCK_EPISODE = 'SEASON_DETAILS_LOCK_EPISODE';
export const SEASON_DETAILS_CS_CREATE_REQ_MODAL_SHOW = 'SEASON_DETAILS_CS_CREATE_REQ_MODAL_SHOW';
export const SEASON_DETAILS_CS_CREATE_REQ_MODAL_HIDE = 'SEASON_DETAILS_CS_CREATE_REQ_MODAL_HIDE';

export const setActiveTab = activeTab => dispatch => {
  dispatch({type: SEASON_DETAILS_SET_ACTIVE_TAB, activeTab});
}

export const getSeasonDetails = (history, requestId) => dispatch => {
  dispatch(clearSeasonDetails());
  history.replace({pathname: `/requests/seasons/${requestId}`});
  dispatch({type: SEASON_DETAILS_IS_LOADING, loading: true});

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/seasons/${requestId}`
  })
  .then(data => {
    dispatch({type: SEASON_DETAILS_GET_LIST, details: data});
    dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
  })
  .fail(() => {
    dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
  });
};

export const selectSeason = selectedSeasonId => dispatch => {
  dispatch({type: SEASON_DETAILS_SELECT_SEASON, selectedSeasonId});
};

export const createNewEpisodeRequest = (history, row, currentSeason, csParam, urlParam) => dispatch => {
  const redirectUrl = `/requests/new?${urlParam ? `${urlParam}&` : ''}fromDraft=true`;
  getSeasonEpisodes(currentSeason.RequestID)
  .then(episodes => {
    const episode = episodes.find(item => item.SeasonEpisodeID === row.SeasonEpisodeID);
    const data = {
      date: new Date(),
      currentStep: NEW_REQUEST_STEP_TURNAROUND,
      currentSubStep: null,
      values: {
        ...csParam,
        request_type_id: QC_REQUEST_TYPE_EPISODE,
        general: {
          allow_interactive_report: true,
          episode_number: row.EpisodeNum,
          episode_name: row.Title,
          season_episode_id: episode && episode.SeasonEpisodeID,
          runtime_in_sec: episode && episode.Runtime
        },
        origin_request_id: currentSeason.RequestID,
        origin_request: {...currentSeason}
      }
    };
    dispatch(saveNewRequestState(data));
  })
  .then(() => {
    history.push(redirectUrl);
  });
};

const showCsCreateRequestModal = data => ({type: SEASON_DETAILS_CS_CREATE_REQ_MODAL_SHOW, data});

export const hideCsCreateRequestModal = () => ({type: SEASON_DETAILS_CS_CREATE_REQ_MODAL_HIDE});

export const createNewRequest = (history, row) => (dispatch, getState) => {
  const {SeasonRequestID} = row;
  const {seasons, request_title, series_name, project_id} = getState().seasondetails.details;
  const season = (seasons || []).find(s => s.QcProjectID === project_id) || {};
  const seasonName = season.SeasonName || (request_title || '').replace((series_name || ''), '').trim();
  const currentSeason = {RequestID: SeasonRequestID, SeasonName: seasonName};
  if (isManagerUser() && (season.ProductionName || season.FinishingHouseName)) {
    dispatch(showCsCreateRequestModal({row, currentSeason, season}));
  } else {
    const csParam = isManagerUser() ? {cs_studio_id: season.StudioID} : {};
    const urlParam = isManagerUser() ? `cs_studio=${season.StudioID}` : '';
    dispatch(createNewEpisodeRequest(history, row, currentSeason, csParam, urlParam));
  }
};

export const saveNewRequestState = data => () => {
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/draft`,
    async: false,
    contentType: 'application/json;charset=utf-8',
    data: JSON.stringify({
      steps_json: JSON.stringify(data)
    })
  });
};

export const clearSeasonDetails = () => dispatch => {
  dispatch({type: SEASON_DETAILS_CLEAR});
};

export const updateEditDateModal = values => dispatch => {
  dispatch({type: SEASON_DETAILS_UPDATE_EDIT_MODAL, values})
};

export const updateMilestoneDate = ({
  qcProjectId,
  schedulingId,
  serverDate,
  updatePrevDates,
  updateNextDates
}) => dispatch => {
  dispatch({type: SEASON_DETAILS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${qcProjectId}/schedule`,
    contentType: 'application/json;charset=utf-8',
    data: JSON.stringify({
      profile_scheduling_id: schedulingId,
      date: serverDate,
      left: updatePrevDates,
      right: updateNextDates
    })
  })
    .then(data => {
      const {milestones} = data;
      dispatch(updateEditDateModal({show: false, qcProjectId: null, schedulingId: null, position: {}}));
      dispatch({type: SEASON_DETAILS_UPDATE_EPISODE_MILESTONES, qcProjectId: qcProjectId, milestones})
      dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
    })
    .fail(() => {
      dispatch(updateEditDateModal({show: false, qcProjectId: null, schedulingId: null, position: {}}));
      dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
    });
};

export const lockRequest = qcProjectId => dispatch => {
  dispatch({type: SEASON_DETAILS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${qcProjectId}/lock`
  })
    .then(data => {
      dispatch({type: SEASON_DETAILS_LOCK_EPISODE, qcProjectId});
      dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
    })
    .fail(() => {
      dispatch({type: SEASON_DETAILS_IS_LOADING, loading: false});
      dispatch(showError('Something went wrong, we can\'t lock scheduling'));
    });
}
