import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

export const DistributorInfoCell = ({row, getSpecQcProfile}) => {
  const specQcProfile = getSpecQcProfile(row);
  return (
    <td>
      <Row>
        <Col md={3} xs={3}>
          {specQcProfile ? specQcProfile.name : null}
        </Col>
        <Col md={9} xs={9}>
          {row.website}
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12}>
          {row.brief_note || ' '}
        </Col>
      </Row>
    </td>
  );
};

DistributorInfoCell.propTypes = {
  row: PropTypes.object,
  getSpecQcProfile: PropTypes.func
};
