import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import {getRequestByUUID} from './actions';
import Loader from '../../components/Loader';

class RequestByUUID extends PageComponent {
  componentDidMount() {
    const {dispatch, history, match} = this.props;
    dispatch(getRequestByUUID(history, match.params.requestUUID));
  }

  render() {
    const {requestuuid} = this.props;
    const {loading} = requestuuid;

    return (
      <AppLayout title="Request by UUID" withMainHeader={false}>
        {loading ? <Loader className="full-screen"/> : null}
      </AppLayout>
    );
  }
}

RequestByUUID.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestuuid: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    requestuuid: state.requestuuid,
    user: state.user,
    main: state.main
  };
}

export default connect(
  mapStateToProps
)(withRouter(RequestByUUID));
