import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import alertImg from '../../../../assets/images/notification/alert-gray.svg';
import closeImg from '../../../../assets/images/notification/close.svg';
import {showNotificationBar, showNotificationsModal} from '../../actions';

class NotificationBar extends Component {
  timerId = null;

  handleOpenModal = () => {
    const {dispatch} = this.props;
    dispatch(showNotificationsModal(true, 'system_alerts'));
  };

  handleCloseBar = () => {
    this.clearTimeout();
    const {dispatch} = this.props;
    dispatch(showNotificationBar(false));
  };

  clearTimeout = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  componentDidMount() {
    this.timerId = setTimeout(() => this.handleCloseBar(), 7000);
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    const {mainState: {unseenNotifications: {system_alerts_count, last_system_alert_text}}} = this.props;
    return system_alerts_count ? (
      <StyledBar>
        <div>
          <img src={alertImg} alt=""/>
          <span>{last_system_alert_text}</span>
          {
            system_alerts_count > 1 ? (
              <button className="open-modal" onClick={this.handleOpenModal}>+ {system_alerts_count - 1} New</button>
            ) : null
          }
        </div>
        <div>
          <button className="close-bar" onClick={this.handleCloseBar}/>
        </div>
      </StyledBar>
    ) : null;
  }
}

const StyledBar = styled.section`
  animation: alert-animation 1s 6s ease-in;
  @keyframes alert-animation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
  background: #FFFAEC;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  color: rgba(40, 40, 40, 0.3);
  padding: 16px 22px 15px 25px;
  button {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    &.open-modal {
      background: #4E4E4E;
      border-radius: 100px;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
      color: #FFFFFF;
      padding: 7px 18px;
      margin-left: 18px;
      &:hover {
        background: #424242;
      }

      @media (max-width: 767px) {
        margin: 11px 0 0;
      }
    }
    &.close-bar {
      width: 13px;
      height: 13px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url(${closeImg});
      background-position: 0;
      margin: 6px 0 0 10px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }

      @media (max-width: 767px) {
        margin: 0;
        opacity: 1;
      }
    }
  }
  > div {
    display: flex;
    padding: 0;
    img {
      width: 19px;
      height: 21px;
      margin-top: 2px;
      margin-right: 13px;
      opacity: 0.4;
    }
    span {
      padding-top: 8px;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
      span {
        line-height: 17px;
      }
      img {
        margin: 0;
        opacity: 1;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 19px 22px 20px 26px;
    margin: 20px 0 0;
  }
`;

NotificationBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mainState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    mainState: state.main
  };
}

export default connect(
  mapStateToProps
)(withRouter(NotificationBar));
