/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  ANALYTICS_IS_LOADING, ANALYTICS_GET_REPORTS, ANALYTICS_TABLE_IS_LOADING,
  ANALYTICS_CHANGE_MENU_ITEM, ANALYTICS_GET_FILTERED_REPORT,
  ANALYTICS_GET_REPORT
} from './actions';

const initialState = {
  activeMenuItem: '',
  usedFilters: {},
  reports: [],
  filteredReport: {},
  loading: false,
  tableLoading: false,
  moreLoading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT: {
      return initialState;
    }

    case ANALYTICS_IS_LOADING: {
      return {...state, loading: action.loading}
    }

    case ANALYTICS_TABLE_IS_LOADING: {
      return {...state, tableLoading: action.loading, moreLoading: action.isLoadMore || initialState.moreLoading}
    }

    case ANALYTICS_GET_REPORTS: {
      return {
        ...state,
        reports: action.reports || initialState.reports,
        usedFilters: action.validUrlFilters
      }
    }

    case ANALYTICS_GET_REPORT: {
      const reports = [...state.reports || []];
      const idx = reports.findIndex(item => item.name === action.report.name);
      if (idx !== -1) {
        reports[idx] = {...reports[idx], ...action.report};
      }
      return {
        ...state,
        reports
      }
    }

    case ANALYTICS_CHANGE_MENU_ITEM: {
      return {
        ...state,
        activeMenuItem: action.activeMenuItem,
        filteredReport: {}
      }
    }

    case ANALYTICS_GET_FILTERED_REPORT: {
      if (state.activeMenuItem !== action.activeMenuItem) {
        return state;
      }
      return {...state, tableLoading: false, moreLoading: false, filteredReport: action.filteredReport}
    }

    default:
      return state;
  }
}
