import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const IssueSubHeader = ({issue}) => {
  return (
    <IssueSubHeaderStyled severityColor={issue.SeverityColor} className="issue-subheader-modal">
      <h5>{issue.TimeCodeStart}{issue.TimeCodeEnd ? ` - ${issue.TimeCodeEnd}` : ''}</h5>
      <div>
        <span className="issue-type">{issue.IssueType}{issue.Description ? `. ${issue.Description}` : ''}</span>
        <span className="severity">{issue.Severity}</span>
      </div>
    </IssueSubHeaderStyled>
  );
};

const IssueSubHeaderStyled = styled.div`
  background-color: #F9F9F9;
  border-left: 5px solid ${props => props.severityColor ? `#${props.severityColor}` : 'transparent'};
  padding: 0 45px 26px;
  position: fixed;
  top: 85px;
  right: 0;
  left: 10%;
  z-index: 1060;
  h5 {
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    color: #282828;
    margin-top: 0;
    margin-bottom: 3px;
    &+div {
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #282828;
      text-transform: uppercase;
      span.issue-type {
        opacity: 0.7;
      }
      span.severity {
        opacity: 0.4;
        padding-left: 24px;
        &:before {
          content: 'Severity:';
          padding-right: 3px;
          text-transform: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    position: relative;
    left: 0;
    top: unset;
    padding: 19px 18px 20px;
    background-color: rgba(196, 196, 196, 0.2);
    h5 {
      font-size: 15px;
      line-height: 21px;
      & + div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        span.issue-type {
          line-height: 19px;
          opacity: 1;
        }
        span.severity {
          line-height: 16px;
          padding: 9px 0 0;
        }
      }
    }
  }
`;

IssueSubHeader.propTypes = {
  issue: PropTypes.object
};
