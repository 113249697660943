import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/table/CollapsedTable';
import CollapsedRow from './CollapsedRow';
import styled from 'styled-components';
import ViewAllLink from './ViewAllLink';
import {NameCell} from './cells/NameCell';
import {QcRequestsCell} from './cells/QcRequestsCell';
import {LastReportCell} from './cells/LastReportCell';
import {ActionsCell} from './cells/ActionsCell';
import {SCOPE_ISSUES, SCOPE_TITLES} from '../../constants';

class SectionTable extends Component {
  headers = {
    name: {
      component: NameCell,
      componentArgs: {
        scope: this.props.scope
      }
    },
    request_count: {
      component: QcRequestsCell
    },
    last_report: {
      component: LastReportCell,
      componentArgs: {
        onCloseSearch: this.props.onCloseSearch
      }
    },
    actions: {
      className: 'actions',
      component: ActionsCell,
      componentArgs: {
        onCloseSearch: this.props.onCloseSearch
      }
    }
  };

  state = {
    showCollapsedRowId: null
  };

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data))) {
      this.setState({
        showCollapsedRowId: null
      });
    }
  }

  handleShowCollapsedRow = id => {
    const showCollapsedRowId = id === this.state.showCollapsedRowId ? null : id;
    this.setState({showCollapsedRowId});
    if (id !== null) {
      const {scope, data, onLoadRequests} = this.props;
      if (!data[id].requests) {
        onLoadRequests(scope, data[id]);
      }
    }
  };

  getRowCollapsed = () => {
    const {loadingRequestsByParam, onCloseSearch, scope, search} = this.props;
    return {
      component: CollapsedRow,
      componentArgs: {
        isLoading: () => loadingRequestsByParam !== null && loadingRequestsByParam.scope === scope.toLowerCase(),
        onCloseSearch: onCloseSearch,
        getViewAllLinkTo: row => {
          let issueDesc = '';
          if (scope === SCOPE_ISSUES && row.source && row.source.toLowerCase() === 'description') {
            issueDesc = `&issue_description=${search}`;
          }
          let paramName;
          if (scope === SCOPE_ISSUES) {
            paramName = 'issue_type';
          } else if (scope === SCOPE_TITLES) {
            paramName = 'series';
          } else {
            paramName = 'distributor';
          }
          return `/requests?${paramName}[0]=${row.id}${issueDesc}`;
        }
      }
    };
  };

  render() {
    const {loading, data, total, viewAllLinkTo, onCloseSearch} = this.props;
    return (
      <SectionTableStyled className={`${total > 3 ? ' more-rows' : ''}`}>
        {
          loading ? <Loader/> : null
        }
        <div className="table-block">
          <Table
            isSeparatedRows
            headers={this.headers}
            rows={data.slice(0, 3)}
            getRowClassNames={row => row.request_count ? '' : 'disabled'}
            rowCollapsedEl={this.getRowCollapsed()}
            onRowClick={this.handleShowCollapsedRow}
            showCollapsedRowId={this.state.showCollapsedRowId}
            />
        </div>
        <ViewAllLink to={viewAllLinkTo} onClick={onCloseSearch} total={total}/>
      </SectionTableStyled>
    );
  }
}

const SectionTableStyled = styled.div`
 & > .table-block, & > .loader-container + .table-block {
   max-width: 100%;
   overflow-x: auto;

  > .table {
    border-collapse: separate;
    color: #282828;
    margin: 0;

    > thead > tr > th {
      border: none;
      padding: 0;
      height: 0;
    }

    > tbody {
      &:before {
          content: "-";
          display: block;
          line-height: 23px;
          color: transparent;
          @media (max-width: 767px) {
            line-height: 10px;
          }
      }

      > tr {
        background-color: transparent;
        cursor: pointer;
        &:focus .arrow, &:hover .arrow {
          opacity: 1;
        }

        &.tr-separate {
          cursor: auto;
          > th {
            border: none;
            padding: 4px 2px;
          }
          &:last-child {
            > th {
              padding: 0;
            }
          }
        }

        > td {
          border: none;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
          padding: 0 10px 12px 0;
          vertical-align: bottom !important;

          &.data-table-empty {
            text-align: center;
            width: 100% !important;
          }
        }

        &.tr-collapsed > td {
          cursor: auto;
          border: none;
          padding: 0;
          border-bottom: 1px solid #e2e2e2;
          background: #f9f9f9;
          .more-rows {
            > .table-info {
              margin-bottom: 16px;
            }
            > .table-block > .table > tbody > tr:last-child > td{
              border-bottom: 0.5px solid rgba(0, 0, 0, 0.2) !important;

              @media (max-width: 767px) {
                border-bottom: none !important;
              }
            }
          }
        }

        &.open > td {
          border-bottom-color: transparent;
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }
  }
 }
 &:not(.more-rows) {
  .table > tbody > tr:not(.tr-separate):last-child > td {
    border-bottom: 0;
  }
 }
`;

SectionTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  viewAllLinkTo: PropTypes.any.isRequired,
  onCloseSearch: PropTypes.func.isRequired,
  onLoadRequests: PropTypes.func.isRequired,
  loadingRequestsByParam: PropTypes.object
};

export default SectionTable;
