import config from '../../../config';
import {getTextlessOptionName} from '../../utils/general';

export const SELECT_FILES_OPTIONS = {IBMAspera: 1, UploadNow: 2, UploadLater: 3, PhysicalMediaDelivery: 4};

export const CONTENT_TYPES = {Feature: 1, Season: 2, Episode: 3, Trailer: 4};

export const QC_REQUEST_TYPE_NEW_QC_REQUEST = 1;
export const QC_REQUEST_TYPE_REPLACEMENT = 2;
export const QC_REQUEST_TYPE_EPISODE = 3;// NOTE: used on client side IN WIZARD only (do not send to server when confirming the creation of a request!)
export const QC_REQUEST_TYPE_QUOTE = 4;// NOTE: used on client side IN WIZARD only (do not send to server when confirming the creation of a request!)
export const QC_REQUEST_TYPES = {
  NewQcRequest: {id: QC_REQUEST_TYPE_NEW_QC_REQUEST, title: 'New QC Request', description: 'Select this option to request QC on a new title or season'},
  Replacement: {id: QC_REQUEST_TYPE_REPLACEMENT, title: 'Replacement', description: 'Select this option to submit a replacement pass for an existing request'},
  Episode: {id: QC_REQUEST_TYPE_EPISODE, title: 'New Episode', description: 'Select this option to request QC on a new episode for an existing season'},
  Quote: {id: QC_REQUEST_TYPE_QUOTE, title: 'New Project Quote (beta)', description: 'Select this option to create "Quotes" for new project'}
};


export const isNewQuoteProject = data => (data || {}).request_type_id === QC_REQUEST_TYPE_QUOTE;

export const VIDEO_FILTER_NAME_FORMATS = 'video_formats';
export const VIDEO_FILTER_NAME_RESOLUTIONS = 'video_resolutions';
export const VIDEO_FILTER_NAME_COLOR_SPACES = 'video_color_spaces';
export const VIDEO_FILTER_NAME_VIDEO_CAPTIONS = 'video_captions';
export const VIDEO_FILTER_NAME_VIDEO_TEXTLESS = 'video_textless';
export const VIDEO_FORMAT_FIELD_FORMAT_ID = 'format_id';
export const VIDEO_FORMAT_FIELD_RESOLUTION_ID = 'resolution_id';
export const VIDEO_FORMAT_FIELD_COLOR_SPACE_ID = 'color_space_id';
export const VIDEO_FORMAT_FIELD_CAPTION_ID = 'caption_id';
export const VIDEO_FORMAT_FIELD_TEXTLESS = 'textless';

export const ALT_NAMES_OF_VIDEO_FIELDS = {
  [VIDEO_FORMAT_FIELD_FORMAT_ID]: 'VideoFormatID',
  [VIDEO_FORMAT_FIELD_RESOLUTION_ID]: 'ResolutionID',
  [VIDEO_FORMAT_FIELD_COLOR_SPACE_ID]: 'ColorSpaceID',
  [VIDEO_FORMAT_FIELD_CAPTION_ID]: 'CaptionID',
  [VIDEO_FORMAT_FIELD_TEXTLESS]: 'Textless'
};

export const VIDEO_FORMAT_FIELDS = [{
  name: VIDEO_FORMAT_FIELD_FORMAT_ID,
  altName: ALT_NAMES_OF_VIDEO_FIELDS[VIDEO_FORMAT_FIELD_FORMAT_ID],
  label: 'Video Format',
  filterName: VIDEO_FILTER_NAME_FORMATS
}, {
  name: VIDEO_FORMAT_FIELD_RESOLUTION_ID,
  altName: ALT_NAMES_OF_VIDEO_FIELDS[VIDEO_FORMAT_FIELD_RESOLUTION_ID],
  label: 'Resolution',
  filterName: VIDEO_FILTER_NAME_RESOLUTIONS,
  useToBuildRequestFormat: true
}, {
  name: VIDEO_FORMAT_FIELD_COLOR_SPACE_ID,
  altName: ALT_NAMES_OF_VIDEO_FIELDS[VIDEO_FORMAT_FIELD_COLOR_SPACE_ID],
  label: 'Color Space',
  filterName: VIDEO_FILTER_NAME_COLOR_SPACES,
  useToBuildRequestFormat: true
}, {
  name: VIDEO_FORMAT_FIELD_CAPTION_ID,
  altName: ALT_NAMES_OF_VIDEO_FIELDS[VIDEO_FORMAT_FIELD_CAPTION_ID],
  label: 'Captions',
  filterName: VIDEO_FILTER_NAME_VIDEO_CAPTIONS
}, {
  name: VIDEO_FORMAT_FIELD_TEXTLESS,
  altName: ALT_NAMES_OF_VIDEO_FIELDS[VIDEO_FORMAT_FIELD_TEXTLESS],
  label: 'Textless',
  filterName: VIDEO_FILTER_NAME_VIDEO_TEXTLESS,
  useToBuildRequestFormat: true
}];

export const getVideoResolutionsFilter = (filters, qcProfileId) => {
  return (filters[VIDEO_FILTER_NAME_RESOLUTIONS] || {})[qcProfileId] || [];
};
export const getVideoFormatFilter = (filters, filterName, qcProfileId) => {
  if (filterName === VIDEO_FILTER_NAME_RESOLUTIONS) {
    return getVideoResolutionsFilter(filters, qcProfileId);
  }
  return filters[filterName] || [];
};
export const getVideoFormatOptionName = (filters, filterName, qcProfileId, optionValue) => {
  return (getVideoFormatFilter(filters, filterName, qcProfileId).find(o => o.value === optionValue) || {}).name;
};

const videoFieldsForStdFormat = [VIDEO_FORMAT_FIELD_RESOLUTION_ID, VIDEO_FORMAT_FIELD_COLOR_SPACE_ID, VIDEO_FORMAT_FIELD_TEXTLESS];
export const buildVideoFormatName = (filters, qcProfileId, videoFormat, fully) => {
  return VIDEO_FORMAT_FIELDS.map(field => !fully && !videoFieldsForStdFormat.includes(field.name) ? null :
    field.name === VIDEO_FORMAT_FIELD_TEXTLESS ? getTextlessOptionName(videoFormat[field.name]) :
      getVideoFormatOptionName(filters, field.filterName, qcProfileId, videoFormat[field.name])
  ).filter(Boolean).join('/');
};

export const NEW_REQUEST_STEP_CHOICES = 10;
export const NEW_REQUEST_STEP_STUDIO = 15;
export const NEW_REQUEST_STEP_ORIGINAL_REPORT = 20;
export const NEW_REQUEST_STEP_DISTRIBUTION = 30;
export const NEW_REQUEST_STEP_DISTRIBUTION_SELECT = 31;
export const NEW_REQUEST_STEP_DISTRIBUTION_OPTIONS = 32;
export const NEW_REQUEST_STEP_FORMATS = 39;
export const NEW_REQUEST_STEP_PRODUCTION = 51;
export const NEW_REQUEST_STEP_FINISHING_HOUSE = 54;
export const NEW_REQUEST_STEP_MEDIA = 60;
export const NEW_REQUEST_STEP_TURNAROUND = 70;
export const NEW_REQUEST_STEP_FILES = 80;
export const NEW_REQUEST_STEP_CS_SETTINGS = 90;
export const NEW_REQUEST_STEP_CS_TAGS = 100;
export const NEW_REQUEST_STEP_CS_WO_STEPS = 140;
export const NEW_REQUEST_STEP_CS_GENERAL_NOTES = 150;
export const NEW_REQUEST_STEP_SECURITY = 160;
export const NEW_REQUEST_STEP_REVIEW_AND_CONFIRM = 180;

export const NEW_REQUEST_STEPS = [{
  id: NEW_REQUEST_STEP_CHOICES,
  name: 'Choices'
}, {
  id: NEW_REQUEST_STEP_STUDIO,
  name: 'Select Studio',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote],
  excludeRoles: [config.roles.STUDIO]
}, {
  id: NEW_REQUEST_STEP_ORIGINAL_REPORT,
  name: {
    [QC_REQUEST_TYPES.Replacement.id]: 'Original Report',
    [QC_REQUEST_TYPES.Episode.id]: 'Select Season'
  },
  requestTypes: [QC_REQUEST_TYPES.Replacement, QC_REQUEST_TYPES.Episode]
}, {
  id: NEW_REQUEST_STEP_DISTRIBUTION,
  name: 'Distribution',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote]
}, {
  id: NEW_REQUEST_STEP_FORMATS,
  name: 'Formats',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote]
}, {
  id: NEW_REQUEST_STEP_PRODUCTION,
  name: 'Productions',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote],
}, {
  id: NEW_REQUEST_STEP_FINISHING_HOUSE,
  name: 'Post Partners',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest],
}, {
  id: NEW_REQUEST_STEP_MEDIA,
  name: 'Media Details',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote]
}, {
  id: NEW_REQUEST_STEP_TURNAROUND,
  name: 'Turnaround & Other Info',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Episode, QC_REQUEST_TYPES.Quote]
}, {
  id: NEW_REQUEST_STEP_FILES,
  name: {
    [QC_REQUEST_TYPES.Quote.id]: 'Delivery',
    [QC_REQUEST_TYPES.Replacement.id]: 'Summary',
    [QC_REQUEST_TYPES.NewQcRequest.id]: 'Select Files',
    [QC_REQUEST_TYPES.Episode.id]: 'Select Files'
  },
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Replacement, QC_REQUEST_TYPES.Episode, QC_REQUEST_TYPES.Quote]
}, {
  id: NEW_REQUEST_STEP_CS_SETTINGS,
  name: 'Settings',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote],
  excludeRoles: [config.roles.STUDIO, config.roles.PRODUCTION, config.roles.FINISHING_HOUSE]
}, {
  id: NEW_REQUEST_STEP_CS_TAGS,
  name: 'Tags',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest, QC_REQUEST_TYPES.Quote],
  excludeRoles: [config.roles.STUDIO, config.roles.PRODUCTION, config.roles.FINISHING_HOUSE || config.roles.POST_PARTNER]
}, {
  id: NEW_REQUEST_STEP_SECURITY,
  name: 'Security',
  requestTypes: [QC_REQUEST_TYPES.NewQcRequest]
}, {
  id: NEW_REQUEST_STEP_CS_WO_STEPS,
  name: 'Work Order',
  requestTypes: [QC_REQUEST_TYPES.Replacement],
  excludeRoles: [config.roles.STUDIO, config.roles.PRODUCTION, config.roles.FINISHING_HOUSE]
}, {
  id: NEW_REQUEST_STEP_CS_GENERAL_NOTES,
  name: 'General Notes',
  requestTypes: [QC_REQUEST_TYPES.Replacement],
  excludeRoles: [config.roles.STUDIO, config.roles.PRODUCTION, config.roles.FINISHING_HOUSE]
}, {
  id: NEW_REQUEST_STEP_REVIEW_AND_CONFIRM,
  name: 'Review & Confirm'
}];


export const FINISHING_HOUSE__N_A = 0;
