import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getTextlessOptionName} from '../../../../utils/general';

class EstimatedCost extends Component {
  formatCost = item => {
    const parsedCost = parseFloat(item.cost || 0);
    if (parsedCost === 0) {
      return <span><strong>Included</strong></span>;
    }
    const temp = String(parsedCost.toFixed(2)).split('.');
    return <span className={item.negative ? 'negative' : undefined}><strong>${temp[0]}</strong>.{temp[1]}</span>;
  };

  renderErrorPricingFromPartners() {
    const email = 'qodsupport@us.resillion.com';
    return <EstimatedCostError>
      <span className="icon-info-circle"/>
      <div className="estimated-cost-error-title">Estimated Cost</div>
      <div>
        Your studio partner does not allow QC costs to be shared. If you have any questions, please email
        us at <a href={`mailto:${email}`} title={`mailto:${email}`}>{email}</a>
      </div>
    </EstimatedCostError>;
  }

  renderError() {
    return <EstimatedCostError>
      <span className="icon-info-circle"/>
      <div className="estimated-cost-error-title">Estimated Cost</div>
      <div>
        Your rate card does not currently support these configuration options.
        Please complete the request and a customer service representative will contact you with cost details.
      </div>
    </EstimatedCostError>
  }

  buildVersionName(costDetails) {
    var textless = getTextlessOptionName(costDetails.textless);
    var request_format = [costDetails.resolution, costDetails.color_space, textless].filter(Boolean).join("/");
    if (request_format) {
      return request_format + ' - Cost Details';
    }
    return 'Cost Details';
  }

  render() {
    const {data, isSeasonRequest} = this.props;
    if (data && data.hidePricingFromPartners) {
      return this.renderErrorPricingFromPartners();
    }
    return !data || !data.success ? this.renderError() : (
      <EstimatedCostStyled>
        {
          isSeasonRequest ? (
            <div className="estimated-cost-season-label">
              <span>Projected Cost per Season</span>
            </div>
          ) : null
        }
        <div className="estimated-cost-info">
          Estimated Cost
          <div className="estimated-cost-value">${data.estimated_cost.toFixed(2)}</div>
        </div>
        <div className="how-is-this-calculated">
        {
          data.cost_details && data.cost_details.length ? data.cost_details.map((versionCost, idx) => (
          <div key={`cost-details-${idx}`}>
            <div
              className="how-is-this-calculated-label"
              data-toggle="collapse"
              data-target={`#cost-details-${idx}`}
              >
              {data.cost_details.length > 1 ? this.buildVersionName(versionCost) : "Cost Details"}
            </div>
            <div id={`cost-details-${idx}`} className="collapse in">
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Code</th><th>Unit</th><th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    versionCost.details && versionCost.details.length ? versionCost.details.map((item, index) => (
                      <tr key={`it-${index}`}>
                        <td>{item.product_code}</td><td>{item.unit}</td><td>{this.formatCost(item)}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td className="data-table-empty" colSpan="3">Included</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
          )): null
        }
        </div>
      </EstimatedCostStyled>
    );
  }
}

const EstimatedCostError = styled.div`
  margin: 20px 0 0 0;
  padding: 24px 25px 24px 60px;
  background: #E75592;
  color: #FFFFFF;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  position: relative;
  border-radius: 5px;

  .icon-info-circle {
    font-weight: normal;
    font-size: 26px;
    color: #fff;
    position: absolute;
    top: 19px;
    left: 20px;
  }

  .estimated-cost-error-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    padding-bottom: 5px;
  }
  a[href^="mailto"] {
    :not(:hover) {
      color: #02599d;
    }
  }
`;

export const EstimatedCostStyled = styled.div`
  text-align: left;
  margin-top: 72px;
  margin-bottom: 22px;
  padding-right: 6px;

  .estimated-cost-title {
    color: rgb(40, 40, 40);
    font-weight: 700;
    font-size: 13px;
  }

  .estimated-cost-not-calculated {
    margin-top: 20px;
    color: red;
  }

  .estimated-cost-info {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    color: #28C0F0;

    .estimated-cost-value {
      font-size: 24px;
      line-height: 25px;
      padding-top: 4px;
    }
  }

  .estimated-cost-season-label {
    display: inline-block;
    background: #F8F8F8;
    border: 0.5px solid #DFDFDF;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 9px 45px 6px 9px;
    font-size: 12px;
    line-height: 13px;
    color: #414141;
  }

  .how-is-this-calculated {
    background: #f9f9f9;
    margin-top: 25px;
    border-radius: 3px;
    padding: 10px 0 14px;

    .how-is-this-calculated-label {
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: rgba(40, 40, 40, 0.5);
      padding: 5px 17px;
      &:hover {
        cursor: pointer;
        color: rgba(40, 40, 40, 0.8);
      }
    }

    .collapse.in {
      padding-bottom: 10px;
    }

    table.table {
      border-collapse: separate;
      border-spacing: 0;
      margin: 0;

      > thead > tr > th {
        vertical-align: bottom;
        border: none;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        text-align: left;
        color: rgba(40, 40, 40, 0.7);
        padding: 10px 0 9px 17px;
        &:last-child {
          padding-right: 16px;
        }
      }

      > tbody {
        > tr {
          > td {
            vertical-align: top;
            border: none;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            text-align: left;
            color: #282828;
            padding: 15px 0 15px 18px;
            background: rgba(255, 255, 255, 0.5);
            &:nth-child(2) {
              color: rgba(40, 40, 40, 0.7);
            }
            &:last-child {
              padding-right: 16px;
            }
            &.data-table-empty {
              text-align: center;
              width: 100% !important;
            }
            span {
              &.negative {
                position: relative;
                &:before {
                  content: '-';
                  position: absolute;
                  left: -5px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

EstimatedCost.propTypes = {
  data: PropTypes.object,
  isSeasonRequest: PropTypes.bool.isRequired
};

export default EstimatedCost;
