import React, {useState} from 'react';
import styled from 'styled-components';
import {Collapse, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';
import MediaDetails from './MediaDetails';

function MediaDetailsForQuote(props) {
  const {
    data,
    filters,
    onChange
  } = props;

  const {season_name} = data;
  const qcProfilesList = data.qc_profile_id || [];

  const [collapsed, setCollapsed] = useState({});

  const handleChange = (profileIdx, name, value, fieldName, fieldValue) => {
    const newQcProfiles = [...qcProfilesList];
    if (name === 'season') {
      if (fieldName === 'total_episodes') {
        for (let i = profileIdx + 1; i < newQcProfiles.length; i++) {
          const nextProfileSeason = {...newQcProfiles[i][name]};
          if (nextProfileSeason.episodes.length < value.episodes.length) {
            for (let j = nextProfileSeason.episodes.length; j < value.episodes.length; j++) {
              const newEpisode = {...nextProfileSeason.episodes[j - 1], episode_number: value.episodes[j].episode_number};
              nextProfileSeason.episodes.push(newEpisode);
            }
          }
          if (nextProfileSeason.episodes.length > value.episodes.length) {
            for (let j = nextProfileSeason.episodes.length; j > value.episodes.length; j--) {
              nextProfileSeason.episodes.pop();
            }
          }
          nextProfileSeason.total_episodes = value.total_episodes;
          newQcProfiles[i] = {
            ...newQcProfiles[i],
            [name]: nextProfileSeason
          };
        }
      }

      if (fieldName === 'runtime_in_sec' || fieldName === 'runtime_in_sec__textless') {
        for (let i = profileIdx + 1; i < newQcProfiles.length; i++) {
          const nextProfile = newQcProfiles[i];
          for (let j = 0; j < nextProfile.season.episodes.length; j++) {
            if (nextProfile.season.episodes[j].created) {
              nextProfile.season.episodes[j][fieldName] = fieldValue;
            }
          }
        }
      }

      if (fieldName === 'content_flag') {
        for (let i = profileIdx + 1; i < newQcProfiles.length; i++) {
          const nextProfileSeason = {...newQcProfiles[i][name]};
          nextProfileSeason.content_flag = value.content_flag;
          newQcProfiles[i] = {
            ...newQcProfiles[i],
            [name]: nextProfileSeason
          };
        }
      }
    }
    newQcProfiles[profileIdx] = {
      ...newQcProfiles[profileIdx],
      [name]: value
    };
    onChange('qc_profile_id', newQcProfiles);
  };

  return (
    <StyledContainer>
      <StyledSeasonNum
        className="step-body-wrapper media-details"
      >
        <ControlLabel>
          Season #
        </ControlLabel>
        <FormControl.Static>
          {season_name}
        </FormControl.Static>
      </StyledSeasonNum>
      {qcProfilesList.map(profileItem => ({
        profile: filters.qc_profiles.find(p => p.value === profileItem.qc_profile_id),
        profileData: profileItem
      })).map((profileItem, profileIdx) => {
        const {profile, profileData} = profileItem;
        const qcType = filters.qc_types.find(t => t.value === profileData.qc_type_id);
        if (!qcType || !profile) {
          return null;
        }
        const isExpanded = !collapsed[profileIdx];
        return (
          <div
            key={`season-${profileIdx}`}
            className="combo-item-cont"
          >
            <div
              className="combo-item-title"
              onClick={() => setCollapsed({...collapsed, [profileIdx]: isExpanded})}
            >
              <strong title="QC Profile">
                {profile.name}
              </strong>
              <span>
                <i className="fa-light fa-angle-right"/>
              </span>
              <strong title="QC Type">
                {qcType.name}
              </strong>
              <span className={isExpanded ? undefined : 'collapsed'}>
                <i className="fa-light fa-angle-right"/>
              </span>
            </div>
            <Collapse in={isExpanded}>
              <div>
                <MediaDetails
                  isNewQuote
                  data={{
                    series_id: data.series_id,
                    season_name,
                    ...profileData,
                  }}
                  filters={filters}
                  onChange={(n, v, fieldName, fieldValue) => handleChange(profileIdx, n, v, fieldName, fieldValue)}
                />
              </div>
            </Collapse>
          </div>
        );
      })}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin-bottom: 30px;
  .combo-item-cont {
    + .combo-item-cont {
      margin-top: 15px;
    }
    .combo-item-title {
      margin: 32px 20px 10px 73px;
      width: fit-content;
      border-radius: 2px;
      background: #f3f3f3;
      box-shadow: -3px 0 0 3px #f3f3f3;
      cursor: pointer;
      span {
        margin: 0 8px;
        font-weight: 300;
        i {
          transition: transform 0.2s ease 0s;
          will-change: transform;
        }
        &.collapsed {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }
    .step-body-wrapper {
      margin-left: 90px !important;
    }
  }
  .media-details-top-row {
    .season-number {
      display: none !important;
      + .total-episodes {
        @media (min-width: 992px) {
          margin-left: 20px !important;
        }
      }
    }
  }
  .episode-runtime-row {
    margin-top: 15px !important;
    .episode-runtime-item {
      .runtimes-label {
        display: none !important;
      }
      .episode-info.col-md-9 {
        width: 100% !important;
      }
    }
  }
`;

const StyledSeasonNum = styled(FormGroup)`
  .form-control-static {
    font-weight: 700;
    font-size: 17px;
    line-height: 18px;
  }
`;

export default MediaDetailsForQuote;
