import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {Row} from 'react-bootstrap';
import backImg from '../../../../assets/images/back.svg';
import backWhiteImg from '../../../../assets/images/back-btn-white.svg';

class Title extends Component {
  onBack = () => {
    const {history} = this.props;
    history.push('/requests');
  };

  render() {
    const {title, loading} = this.props;
    return (
      <StyledTitle>
        <img src={backImg} alt="Back" className="back-btn" onClick={loading ? undefined : this.onBack}/>
        <div className="title">
          <h1>{title}</h1>
          <span>Select the episode to view OC Report</span>
        </div>
        <i className="visible-xs icon-menu" onClick={this.toggleShowMore}/>
      </StyledTitle>
    )
  }
}

const StyledTitle = styled(Row)`
  display: flex;
  margin-bottom: 6px;
  padding: 0 15px;
  .back-btn {
    cursor: pointer; 
    height: 20px;
    margin-left: 10px;
    margin-right: 9px;
    width: 20px;
  }
  .title {
    span {
      color: #282828;
      font-size: 13px;
      line-height: 14px;
      opacity: 0.4;
    }
    h1 {
      color: #282828;
      font-size: 24px;
      line-height: 25px;
      min-height: 25px;
      margin: 0;
      font-weight: normal;
      letter-spacing: unset;
      text-transform: unset;
    }
  }

  @media (max-width: 767px) {
    background: linear-gradient(122.07deg,#0DB4D9 0%,rgba(2,80,173,0.25) 99.75%),#004A8D;
    margin-bottom: 0;
    padding: 15px 20px;
    .back-btn {
      content: url(${backWhiteImg});
      height: auto;
      margin-left: 0;
    }
    .title span, .title h1 {
      color: #fff;
    }
  }
`;

Title.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

export default withRouter(Title);
