/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {
  showEmailReportModal, sendEmailReport, sendFixListEmail, hideWarningInEmailReportModal, getDataForEmailReport
} from '../../actions';
import {Row, Col, Button, Checkbox, FormGroup, FormControl, ControlLabel, InputGroup, Collapse} from 'react-bootstrap';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import Switch from 'react-switch';
import {isManagerUser, isClientServiceUser, isOpsManagerUser, isQcStatusReview} from '../../../../utils/general';
import Select, {components} from 'react-select-v2';
import expandImg from '../../../../../assets/images/expand.svg';
import {confirmAlert} from 'react-confirm-alert';
import {EditorContent, useEditor, mergeAttributes} from '@tiptap/react';
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import {isPendingApprovalIssueStatus} from "../../utils";
import { SwatchesPicker } from 'react-color'

const extensions = [Document, Paragraph, Text, TextStyle, Color, Paragraph.extend({
  parseHTML() {
    return [{ tag: 'div' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})]

const TipTapEditor = props => {

  const handleUpdate = (html) => {
    props.update(html);
  }

  const editor = useEditor({
    extensions,
    content: props.initText,
    onUpdate({ editor }) {
      if (editor.isEmpty) {
        handleUpdate(null);
      } else {
        handleUpdate(editor.getHTML());
      }
    },
  })

  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleClick = () => setShowColorPicker(!showColorPicker);

  const handleClose = () => setShowColorPicker(false);

  const handleChangeColor = (color) => {
    editor.chain().focus().setColor(color.hex).run();
    handleClose();
  }

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const pickerStyle = {
    'default': {
      overflow: {
        height: 'unset',
        overflowY: 'unset',
      }
    }
  }

  return (
      <>
        <div className="control-group">
          <div className="button-group">
            <div>
              <span className="text-color-button" onClick={ handleClick } style={{color: editor.getAttributes('textStyle').color}} title="Text color">
                <i className="fa-solid fa-a"/>
              </span>
              { showColorPicker ?
                <div style={ popover }>
                  <div style={ cover } onClick={ handleClose }/>
                  <SwatchesPicker
                    color={editor.getAttributes('textStyle').color}
                    onChange={ handleChangeColor }
                    colors={[['#000000', '#0747A6', '#008DA6', '#006644', '#FF991F'], ['#FF0000', '#403294', '#97A0AF', '#4C9AFF', '#00B8D9'], ['#36B37E', '#FFC400', '#FF5630', '#6554C0', '#B3D4FF'], ['#FFF0B3', '#FFBDAD']]}
                    styles={pickerStyle}
                  />
                </div> : null
              }
            </div>
          </div>
        </div>
        <div className="editor-content">
          <EditorContent editor={editor} />
        </div>
      </>
  )
}

class EmailReportModal extends Component {
  initState = reportdetails => {
    const {
      showFixListModal: isShowFixListModal,
      details
    } = reportdetails;
    const forReviewStatus = !isShowFixListModal && isQcStatusReview(details.QcStatusID) && (isClientServiceUser() || isOpsManagerUser());

    let additionalAttachments = null;
    let emailAttachments = [];
    // 3 = Amazon company id
    if (details.StudioID === 3) {
      const version = details.versions && details.versions.find(v => v.RequestID === details.RequestID);
      if (details.attachments && version && version.RequestFormat.match(/hdr.*texted/i)) {
        emailAttachments = details.attachments.filter(a => (a.label && a.label.match(/hdr.*report/i)) || a.filename.match(/hdr.*report/i));
      }
    }
    // 21 = The Network company id
    if (details.StudioID === 21) {
      if (details.attachments) {
        emailAttachments = details.attachments.filter(a => (a.label && a.label.match(/hdr.*report/i)) || a.filename.match(/hdr.*report/i) || (a.category && a.category.includes('PSE')));
      }
    }
    if (emailAttachments.length > 0) {
      additionalAttachments = emailAttachments;
    }

    const hasPendingApprovalIssues = reportdetails.issues.find(issue => isPendingApprovalIssueStatus(issue.ClientStatusID, issue.ClientStatus)) != null;
    let senderNote = null;
    if (hasPendingApprovalIssues) {
      senderNote = '<div><span style="color: #FF0000">Please note that some issues are still pending studio approval</span></div>';
    }

    return {
      has_issue_level_4: false,
      isCollapsedEmailBody: false,
      templateEmailBodyWarning: isShowFixListModal ? undefined : null,
      templateEmailBody: isShowFixListModal ? undefined : null,
      report_filename: isShowFixListModal ? undefined : details.DefaultEmailReportFilename,
      email_subject: isShowFixListModal ? undefined : details.DefaultEmailSubject,
      email_recipients: details.DistributionList,
      sender_note: senderNote,
      include_fixlist: forReviewStatus,
      min_severity: forReviewStatus ? 4 : 2,
      include_fixed_issues: false,
      include_comments: false,
      include_excel_fixlist: false,
      include_excel_pdf_fixlist: forReviewStatus,
      do_not_include_link: false,
      enable_override_from: false,
      override_from_email: null,
      override_from_name: null,
      email_bcc_address: null,
      additionalAttachments: additionalAttachments
    };
  };

  state = this.initState(this.props.reportdetails);

  setTemplateEmailBodyHTML = html => {
    try {
      document.querySelector('.email-body-template .form-control').innerHTML = html;
    } catch {}
  };

  loadDataForEmailReport = (values, onlyEmailBody) => {
    const {dispatch, match, reportdetails} = this.props;
    if (isManagerUser() && !reportdetails.showFixListModal) {
      const params = {
        only_email_body: onlyEmailBody,
        include_fixlist: values.include_fixlist,
        include_fixed_issues: values.include_fixed_issues,
        with_attachment: Boolean(values.additionalAttachments || []),
        sender_note: values.sender_note,
        do_not_include_link: values.do_not_include_link,
      };
      dispatch(getDataForEmailReport(match.params.requestId, params)).then(
        res => {
          const {DefaultEmailReportFilename, DefaultEmailSubject, TemplateEmailBodyWarning, TemplateEmailBody, HasIssueLevel4} = res.data;
          this.setState({
            has_issue_level_4: 'true' === HasIssueLevel4,
            isCollapsedEmailBody: false,
            templateEmailBodyWarning: TemplateEmailBodyWarning,
            templateEmailBody: TemplateEmailBody,
            ...onlyEmailBody ? {} : {
              report_filename: DefaultEmailReportFilename,
              email_subject: DefaultEmailSubject
            }
          });
          if (TemplateEmailBody) {
            this.setTemplateEmailBodyHTML(TemplateEmailBody);
          }
        }
      );
    }
  };

  loadEmailBody = values => {
    !this.state.templateEmailBodyWarning && this.loadDataForEmailReport(values, true);
  };

  componentDidMount() {
    document.body.classList.add('modal-open');
    this.loadDataForEmailReport(this.state, false);
  }

  componentDidUpdate(prevProps) {
    const elements = document.getElementsByClassName('issue-modal-backdrop');
    if (elements && elements.length) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.opacity = '0';
      }
    }
    const {dispatch, reportdetails: {warnInEmailReportModal}} = this.props;
    if (warnInEmailReportModal && !prevProps.reportdetails.warnInEmailReportModal) {
      confirmAlert({
        title: 'Email Report Warning',
        childrenElement: () =>
          <div className="custom-msg">
            {warnInEmailReportModal.split(';').map((warn, i) =>
              <div key={`warn-${i}`} className="custom-msg-item">{warn}</div>
            )}
            <div className="custom-msg-item"><b>Are you sure you want to send it?</b></div>
          </div>,
        buttons: [{
          label: 'Cancel',
          onClick: () => dispatch(hideWarningInEmailReportModal())
        }, {
          label: 'Send anyway',
          onClick: this.handleEmailReport
        }]
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showEmailReportModal(false));
  };

  handleEmailReport = () => {
    const {dispatch, match, reportdetails} = this.props;
    const params = {...this.state};
    delete params.isCollapsedEmailBody;
    delete params.templateEmailBodyWarning;
    delete params.templateEmailBody;
    delete params.has_issue_level_4;
    if (reportdetails.showFixListModal) {
      dispatch(sendFixListEmail(match.params.requestId, reportdetails.fixlistMinSeverity, params));
    } else {
      if (params.additionalAttachments && params.additionalAttachments.length) {
        params.additional_attachments = params.additionalAttachments.map(a => a.id);
      }
      delete params.additionalAttachments;
      if (reportdetails.warnInEmailReportModal) {
        params.ignore_qod_users_validation = true;
      } else if (this.state.has_issue_level_4) {
        confirmAlert({
          title: `Email Report Warning`,
          childrenElement: () => (
              <div className="custom-msg">
                <div className="custom-msg-item">This report contains Level 4 issues</div>
                <div className="custom-msg-item"><b>Are you sure you want to send it?</b></div>
              </div>
          ),
          buttons: [{
            label: 'No'
          }, {
            label: 'Send anyway',
            onClick: () => {
              dispatch(sendEmailReport(match.params.requestId, params))
            }
          }]
        });
        return;
      }

      dispatch(sendEmailReport(match.params.requestId, params));
    }
  };

  handleFieldValueChanged = (name, value) => {
    if (value === '') {
      value = null;
    }

    let templateEmailBody = this.state.templateEmailBody;
    if (name === 'sender_note' && templateEmailBody) {
      const strStart = '<!--BEGIN ADDITIONAL NOTES-->';
      const strEnd = '<!--END ADDITIONAL NOTES-->';
      const idxStart = templateEmailBody.indexOf(strStart)
      const idxEnd = templateEmailBody.indexOf(strEnd)
      const part1 = templateEmailBody.substring(0, idxStart)
      const part2 = templateEmailBody.substring(idxEnd + strEnd.length);
      templateEmailBody = part1 + strStart +
                          (!value ? '' : '<br/><br/><span style="text-decoration: underline;"><b>ADDITIONAL NOTES:</b></span><br/>' + value.replace(/\r?\n/g, '<br/>')) +
                          strEnd + part2;
      this.setTemplateEmailBodyHTML(templateEmailBody);
    }

    this.setState({
      templateEmailBody,
      [name]: value
    });
  };

  handleTextareaBlur = event => {
    this.handleFieldValueChanged(event.target.name, event.target.value);
  };

  handleSelectAttachments = selected => {
    if (Boolean((selected || []).length) !== Boolean((this.state.additionalAttachments || []).length)) {
      const values = {
        ...this.state,
        additionalAttachments: selected
      };
      this.loadEmailBody(values);
    }
    this.setState({additionalAttachments: selected});
  };

  handleSwitch = (name, value) => {
    const values = {
      ...this.state,
      [name]: value
    };
    this.loadEmailBody(values);
    this.setState({[name]: value});
  };

  handleIncludeFixlist = value => {
    this.handleSwitch('include_fixlist', value);
  };

  handleIncludeFixedIssues = include_fixed_issues => {
    this.handleSwitch('include_fixed_issues', include_fixed_issues);
  };

  handleIncludeIssuesComments = include_comments => this.setState({include_comments});

  handleSelectMinSeverity = (_, value) => {
    this.setState({
      ...this.state,
      min_severity: value
    });
  };

  handleIncludeExcelFixlist = (value) => {
    const {include_excel_pdf_fixlist} = this.state;
    this.setState({
      ...this.state,
      include_excel_fixlist: value,
      include_excel_pdf_fixlist: include_excel_pdf_fixlist ? false : null
    });
  };

  handleIncludeExcelAndPdfFixlist = (value) => {
    const {include_excel_fixlist} = this.state;
    this.setState({
      ...this.state,
      include_excel_pdf_fixlist: value,
      include_excel_fixlist: include_excel_fixlist ? false : null
    });
  };

  handleEnableOverrideFrom = (value) => {
    const {override_from_email, override_from_name} = this.state;
    this.setState({
      ...this.state,
      enable_override_from: value,
      override_from_email: value ? override_from_email : null,
      override_from_name: value ? override_from_name : null
    });
  };

  handleChangeOverrideFromText = (name, value) => {
    this.handleFieldValueChanged(name, value);
  };

  handleBlurOverrideFromText = (name, value) => {
    this.handleFieldValueChanged(name, value);
  };

  handleMessageNoteChange = (html) => {
    this.handleFieldValueChanged('sender_note', html);
  }

  renderSwitchField = (label, checked, onChange) => {
    return (
      <div className="form-group">
        <div className="field">
          <div className="switch-field switch-not-sure">
            <Switch
              onChange={onChange}
              checked={checked}
              className={`react-switch${checked ? ' checked' : ''}`}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#F6F6F6"
              onColor="#FFFFFF"
              offHandleColor="#A9A9A9"
              onHandleColor="#7FCF18"
              activeBoxShadow="0px 0px 1px 1px transparent"
              handleDiameter={22}
              height={28}
              width={48}
            />
            <span>{label}</span>
          </div>
        </div>
      </div>
    );
  };

  renderAdditionalFieldsForManager = () => {
    const {reportdetails} = this.props;
    const {filters, details: {attachments, IsHardingTestRequest}} = reportdetails;
    const {
      include_fixlist, min_severity, include_excel_fixlist, include_excel_pdf_fixlist, enable_override_from,
      override_from_email, override_from_name, email_bcc_address, include_fixed_issues, additionalAttachments,
      do_not_include_link
    } = this.state;

    if (!isManagerUser() || reportdetails.showFixListModal) {
      return null;
    }

    return (
      <>
        {!IsHardingTestRequest &&
          <>
            <Row>
              <Col md={3} xs={12}>
                {this.renderSwitchField('Include Fixed Issues', include_fixed_issues, this.handleIncludeFixedIssues)}
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={12}>
                {this.renderSwitchField('Include Fixlist', include_fixlist, this.handleIncludeFixlist)}
              </Col>
            </Row>
          </>
        }
      {
        include_fixlist ? (
          <>
          <Row>
            <Col md={3} xs={6} className="min-severity-field">
              <SelectAdvanced
                label="Min Severity"
                labelSeparator=""
                options={filters.issue_severities || []}
                value={min_severity}
                onSelect={this.handleSelectMinSeverity}
                placeholder=""
                />
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12}>
              {this.renderSwitchField('Include Fixlist as Excel instead PDF', include_excel_fixlist, this.handleIncludeExcelFixlist)}
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12}>
              {this.renderSwitchField('Include Fixlist as Excel & PDF', include_excel_pdf_fixlist, this.handleIncludeExcelAndPdfFixlist)}
            </Col>
          </Row>
          </>
        ) : null
      }
      {(isClientServiceUser() || isOpsManagerUser()) &&
        <Row>
          <Col md={2} xs={12}>
            {this.renderSwitchField('Do not include link', do_not_include_link, (v) => this.handleSwitch('do_not_include_link', v))}
          </Col>
        </Row>
      }
      <Row>
        <Col md={2} xs={12}>
          {this.renderSwitchField('Override From: ', enable_override_from, this.handleEnableOverrideFrom)}
        </Col>
      </Row>
      {
        enable_override_from ? (
          <Row>
            <Col md={3} xs={6} className="min-severity-field">
              <InputAdvanced
                labelSeparator=""
                label="From Email: "
                name="override_from_email"
                value={override_from_email}
                type="text"
                maxLength={30}
                onChange={this.handleChangeOverrideFromText}
                onBlur={this.handleBlurOverrideFromText}
              />
            </Col>
            <Col md={3} xs={6} className="min-severity-field">
              <InputAdvanced
                labelSeparator=""
                label="From Name: "
                name="override_from_name"
                value={override_from_name}
                type="text"
                maxLength={30}
                onChange={this.handleChangeOverrideFromText}
                onBlur={this.handleBlurOverrideFromText}
              />
            </Col>
          </Row>
        ) : null
      }
      <Row>
        <Col md={7} xs={12} className="min-severity-field">
          <InputAdvanced
            labelSeparator=""
            label="BCC: "
            name="email_bcc_address"
            value={email_bcc_address}
            type="text"
            onChange={this.handleChangeOverrideFromText}
            onBlur={this.handleBlurOverrideFromText}
            placeholder='comma separated list of addresses'
          />
        </Col>
      </Row>
      {!IsHardingTestRequest && (attachments || []).length > 0 &&
        <>
          <Row>
            <Col md={12} xs={12}>
              <Checkbox
                className={`additional-attachments-checkbox${!!additionalAttachments ? '' : ' margin-bottom-20'}`}
                checked={!!additionalAttachments}
                onChange={({target: {checked}}) => this.handleSelectAttachments(checked ? [] : null)}
              >
                Additional Attachments
              </Checkbox>
            </Col>
          </Row>
          {!!additionalAttachments &&
            <Row>
              <Col md={7} xs={12}>
                <FormGroup className="additional-attachments-form-group">
                  <ControlLabel>
                    Select attachments to include in the email
                  </ControlLabel>
                  <div className="field">
                    <Select
                      className="select-in-cell additional-attachments-list"
                      classNamePrefix="additional-attachments-select"
                      isMulti
                      getOptionValue={o => o.id}
                      getOptionLabel={o => <>{o.filename}{attachments.length === 1 ? '' : <small>({o.label && o.label !== 'null' ? `${o.label} / ` : ''}{o.category}{o.pse_test ? ` / ${o.pse_test}` : ''})</small>}</>}
                      options={attachments}
                      value={additionalAttachments}
                      onChange={this.handleSelectAttachments}
                      isSearchable={false}
                      closeMenuOnSelect={false}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: diProps => (
                          <components.DropdownIndicator {...diProps}>
                            <img src={expandImg} alt=""/>
                          </components.DropdownIndicator>
                        )
                      }}
                      maxMenuHeight={200}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          }
        </>
      }
      </>
    )
  };

  render() {
    const {reportdetails} = this.props;
    const {completing, details: {IsHardingTestRequest}} = reportdetails;
    const {
      email_recipients, sender_note, include_comments, email_subject, report_filename,
      isCollapsedEmailBody, templateEmailBodyWarning, templateEmailBody
    } = this.state;
    return (
      <StyledMobileModal className="mobile-modal-with-issue-table">
        <StyledModalBackdrop
          opacity={reportdetails.showFixListModal ? 0 : undefined}
          onClick={() => this.handleClose()}
          />
        <StyledModalDialog className="mobile-modal-dialog-custom">
          {completing ? <Loader/> : null}
          <StyledModal>
            <StyledModalHeader className="modal-header-custom">
              <div className="back-btn" onClick={this.handleClose}/>
              <div className="modal-header-content">
                <h4>Email Report</h4>
                <div>
                  <StyledBaseCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
                </div>
              </div>
              <div className="modal-fields">
                {isManagerUser() && !reportdetails.showFixListModal &&
                  <>
                  {!IsHardingTestRequest &&
                    <Row>
                      <Col md={7} xs={12}>
                        <FormGroup className="report_filename">
                          <ControlLabel>
                            PDF Report Filename
                          </ControlLabel>
                          <InputGroup>
                            <FormControl
                              name="report_filename"
                              value={report_filename || ''}
                              onBlur={this.handleTextareaBlur}
                              onChange={this.handleTextareaBlur}
                            />
                            <InputGroup.Addon>.pdf</InputGroup.Addon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col md={7} xs={12}>
                      <FormGroup className="email_subject">
                        <ControlLabel>
                          Email Subject Line
                        </ControlLabel>
                        <FormControl
                          name="email_subject"
                          value={email_subject || ''}
                          onBlur={this.handleTextareaBlur}
                          onChange={this.handleTextareaBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} xs={12}>
                      <FormGroup
                        className="email-body-template"
                        validationState={templateEmailBodyWarning ? 'warning' : undefined}
                      >
                        <ControlLabel
                          title={isCollapsedEmailBody ? 'Expand' : 'Collapse'}
                          onClick={() => this.setState({isCollapsedEmailBody: !isCollapsedEmailBody})}
                        >
                          Email Body
                          {!!templateEmailBody &&
                            <span className={isCollapsedEmailBody ? 'collapsed' : undefined}>
                              <i className="fa-light fa-angle-right"/>
                            </span>
                          }
                        </ControlLabel>
                        <Collapse in={!isCollapsedEmailBody}>
                          <div>
                            <FormControl
                              componentClass="div"
                            >
                              {
                                templateEmailBodyWarning ? (
                                  <>
                                    <i className="fa-light fa-triangle-exclamation"/>
                                    {templateEmailBodyWarning}
                                  </>
                                ) : <>&nbsp;</>
                              }
                            </FormControl>
                          </div>
                        </Collapse>
                      </FormGroup>
                    </Col>
                  </Row>
                  </>
                }
                <Row>
                  <Col md={7} xs={12}>
                    <FormGroup className="email_recipients">
                      <ControlLabel>
                        Email Recipients
                      </ControlLabel>
                      <textarea
                          className="form-control"
                          name="email_recipients"
                          rows="3"
                          value={email_recipients || ''}
                          onBlur={this.handleTextareaBlur}
                          onChange={this.handleTextareaBlur}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={7} xs={12}>
                    <FormGroup className="sender_note">
                      <ControlLabel>
                        Message Note
                      </ControlLabel>
                      <div className="tiptap-editor">
                        <TipTapEditor initText={sender_note} update={this.handleMessageNoteChange}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} xs={12}>
                    {this.renderSwitchField('Include Comments for Issues', include_comments, this.handleIncludeIssuesComments)}
                  </Col>
                </Row>
                {this.renderAdditionalFieldsForManager()}
                <Row>
                  <Col md={12} xs={12} className="modal-btn-toolbar">
                    <Button
                      bsStyle="primary"
                      onClick={this.handleEmailReport}
                      disabled={!email_recipients}
                      title={(!email_recipients) ? 'Fill fields above' : undefined}
                      >
                      Send Email
                    </Button>
                    <Button
                      bsStyle="default"
                      onClick={this.handleClose}
                      >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </StyledModalHeader>
          </StyledModal>
        </StyledModalDialog>
        <GlobalStyled/>
      </StyledMobileModal>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  .react-confirm-alert-body {
    @media (min-width: 768px) {
      padding-right: 34px !important;
      .react-confirm-alert-button-group {
        margin-right: 0 !important;
      }
    }
    .custom-msg-item {
      font-size: 14px;
      line-height: 16px;
      + .custom-msg-item {
        margin-top: 8px;
      }
    }
  }
`;

const StyledModalHeader = styled.div`
  padding: 21px 25px 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8.5px 0 8.5px 23px;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .modal-fields {
    padding: 19px 0;
    max-height: calc(100vh - 21px - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    @media (min-width: 992px) {
      .col-md-7 {
        width: 61.69354839%;
      }
    }

    @media (max-width: 767px) {
      padding: 3px 25px 33px 23px;
      max-height: calc(100vh - 19px - 49px);
    }

    .form-group {
      &.email-body-template {
        .control-label {
          cursor: pointer;
          span {
            margin: 0 0 0 8px;
            font-weight: 300;
            i {
              transition: transform 0.2s ease 0s;
              will-change: transform;
            }
            &.collapsed {
              i {
                transform: rotate(90deg);
              }
            }
          }
        }
        .form-control {
          height: auto;
          line-height: 1.2;
          pointer-events: none;
        }
        &.has-warning {
          pointer-events: none;
          .form-control {
            padding: 0 !important;
            border: none !important;
            background: none !important;
            box-shadow: none !important;
            color: #989898 !important;
            i {
              margin-right: 6px;
              color: #8a6d3b;
            }
          }
        }
      }
      &.email-body-template,
      &.email_subject,
      &.report_filename,
      &.email_recipients {
        margin-bottom: 12px;

        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
      &.sender_note {
        margin-bottom: 16px;

        @media (max-width: 767px) {
          margin-bottom: 28px;
        }

        .tiptap-editor {
          border: 1px solid #ccc;
          border-radius: 3px;
          .control-group {
            padding: 5px;
            border-bottom: 2px solid #eee;
            .text-color-button {
              cursor: pointer;
            }
          }
          .editor-content {
            max-height: 15rem;
            overflow: auto;
            background: #F8F8F8;
            padding: 5px;
          }
        }

        .tiptap {
          :first-child {
            margin-top: 0;
          }
          :focus {
            outline: none;
          }
        }

        .tiptap p {
          margin: 0;
        }
      }
    }

    .form-control {
      background: #F8F8F8;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 13px;
      color: #282828;
      padding: 16px 11px;

      &::placeholder {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: #282828;
        opacity: 0.7;
      }

      &:focus {
        border-color: #ccc;
      }

      &[name="report_filename"] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        + .input-group-addon {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-color: #EBEBEB;
          font-size: 13px;
          line-height: 13px;
        }
        &:focus {
          + .input-group-addon {
            border-color: #ccc;
          }
        }
      }
    }

    textarea.form-control {
      height: 93px;
      width: 100%;
      overflow: auto;
      resize: none;
    }
    input.form-control {
      height: 47px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .checkbox.additional-attachments-checkbox {
      &.margin-bottom-20 {
        margin-bottom: 20px;
      }
      label {
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
      }
      input[type="checkbox"] {
        margin-top: 1px;
      }
    }
    .form-group.additional-attachments-form-group {
      margin-bottom: 25px;
      padding-left: 2px;
      .additional-attachments-list {
        font-size: 12px;
        line-height: 13px;
        color: #282828;
        .additional-attachments-select__control {
          background-color: #F8F8F8;
          border-color: #EBEBEB;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          .additional-attachments-select__placeholder {
            font-size: 12px;
            line-height: 13px;
            color: #282828;
            opacity: 0.3;
          }
          .additional-attachments-select__indicator {
            opacity: 0.2;
            &.additional-attachments-select__clear-indicator {
              :hover {
                opacity: 0.4;
              }
            }
          }
          &.additional-attachments-select__control--is-focused {
            border-color: #ccc;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            .additional-attachments-select__placeholder {
              opacity: 0.5;
            }
            .additional-attachments-select__indicator {
              opacity: 0.4;
            }
          }
          &.additional-attachments-select__control--menu-is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .additional-attachments-select__menu {
          margin-top: 0;
          border-radius: 3px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        .additional-attachments-select__multi-value__label {
          white-space: normal;
        }
        small {
          margin-left: 5px;
          opacity: 0.75;
        }
      }
    }
  }
  button:not(.close) {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background: #414141 !important;
    opacity: 0.8;
    border-radius: 100px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 11px 40px 12px;
    &.btn-primary {
      @media (min-width: 992px) {
        min-width: 128px;
      }
      &:disabled, &.disabled {
        background: #e0e0e1 !important;
        color: #c8c8c8;
        cursor: default;
      }
      &:not([disabled]):hover {
        opacity: 1;
      }
    }
    &.btn-default {
      background-color: transparent !important;
      color: #282828;
      margin-left: 17px;

      padding-left: 20px;
      padding-right: 20px;
      background: none;
      opacity: 0.8;
      border: #ccc 1px solid !important;

      &:hover {
        background: #f5f5f5 !important;
      }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  .modal-btn-toolbar {
    @media (max-width: 767px) {
      display: flex;
      justify-content: flex-start;
      button:first-child {
        order: 2;
      }
      button:last-child {
        order: 1;
        margin: 0 34px 0 0;
      }
    }
  }
`;

EmailReportModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(EmailReportModal));
