import React from 'react';
import styled, {css} from 'styled-components';
import {Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SelectAdvanced from '../../../../../components/forms/SelectAdvanced';
import {Link} from 'react-router-dom';
import HelpCircleIcon from '../../../../../components/icons/HeaderHelpIcon';
import {
  getHiddenWizardFields,
  buildDefaultAudioFormat,
  buildDefaultVideoFormat
} from '../../../../../utils/general';
import {
  CONTENT_TYPES,
} from '../../../constants';
import { v1 as uuidv1 } from 'uuid';

function QcProfilesForQuote(props) {
  const {
    data,
    distributorQcProfiles,
    qcProfiles,
    qcTypes,
    onChange
  } = props;

  const selectedQcProfiles = data.qc_profile_id || [];
  const isUseDefaultDistributorQcProfile = distributorQcProfiles.length === 1;
  const profilesList = distributorQcProfiles.length > 0 ? distributorQcProfiles : qcProfiles;

  const renderQcTypeItem = (profileItem, profileIdx, qcType) => {
    const profileId = profileItem.qc_profile_id;
    const isSelected = profileItem.qc_type_id === qcType.value;
    return (
      <Col md={6} xs={12}>
        <div
          className={`qc-types-item${isSelected ? ' qc-type-selected' : ''}`}
          onClick={() => {
            if (isSelected) {
              return;
            }
            const hiddenFields = getHiddenWizardFields(qcProfiles, profileId);
            const newSelectedQcProfiles = [...selectedQcProfiles];
            newSelectedQcProfiles[profileIdx] = {
              qc_profile_id: profileId,
              qc_type_id: qcType.value,
              video_format: qcType.is_video ? [buildDefaultVideoFormat(hiddenFields, qcType)] : null,
              audio_format: qcType.is_audio && !qcType.is_video ? buildDefaultAudioFormat(hiddenFields) : null,
              /*captions: null,
              version_tag: null,
              additional_description: null,
              revisions_num: null,*/
              profile_index: profileIdx,
              profile_uuid: uuidv1(),
              revisions_num: 1,
              season: data.content_type_id === CONTENT_TYPES.Season ? {
                season_number: data.season_name,
                total_episodes: 1,
                episodes: []
              } : null
            };
            onChange('qc_profile_id', newSelectedQcProfiles);
          }}
        >
          <span className="icon-circle-check"/>
          <span className="qc-type-name">{qcType.name}</span>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`tooltip-${profileId}-${qcType.value}`}>
                {qcType.description}
              </Tooltip>
            }
          >
            <HelpCircleIcon className="help-tooltip" onClick={e => e.preventDefault()}/>
          </OverlayTrigger>
        </div>
      </Col>
    );
  };

  return (
    <StyledContainer>
      {profilesList.length ?
        <ComboTable
          noSelected={!selectedQcProfiles.length}
          isDefault={isUseDefaultDistributorQcProfile}
        >
          <div className="row-qc-profile row-top">
            <div className="cell-qc-profile cell-top">
              QC Profile
            </div>
            <div className="cell-qc-type cell-top">
              QC Type
            </div>
            {!isUseDefaultDistributorQcProfile && <div className="cell-qc-profile-remove"/>}
          </div>
          {selectedQcProfiles.map((profileItem, profileIdx) => {
            const profileId = profileItem.qc_profile_id;
            const profile = profilesList.find(p => p.value === profileId);
            return !!profile && (
              <div className="row-qc-profile" key={`row-qc-profile-${profileIdx}`}>
                <div className="cell-qc-profile">
                  <Link
                    title="Open QC Profile"
                    to={`/settings/qc-profiles?id=${profileId}`}
                    target="_blank"
                  >
                    {profile.name}
                  </Link>
                  {isUseDefaultDistributorQcProfile &&
                    <div className="note-qc-profile">
                      Using the default QC profile available for this distributor.
                    </div>
                  }
                </div>
                <div className="cell-qc-type qc-types">
                  <div className="qc-types-items">
                    {qcTypes.map((t, i) => (i % 2) === 0 ? (
                      <Row key={`qc-type-items-${i}`}>
                        {renderQcTypeItem(profileItem, profileIdx, t)}
                        {(i + 1) < qcTypes.length ? renderQcTypeItem(profileItem, profileIdx, qcTypes[i + 1]) : null}
                      </Row>
                    ) : null)}
                  </div>
                </div>
                {!isUseDefaultDistributorQcProfile &&
                  <div className="cell-qc-profile-remove">
                    <Button
                      onClick={() => {
                        const newVal = [...selectedQcProfiles];
                        newVal.splice(profileIdx, 1);
                        onChange('qc_profile_id', newVal);
                      }}
                      title="Remove this QC Profile from list"
                    >
                      <span className="icon-trash"/>
                    </Button>
                  </div>
                }
              </div>
            );
          })}
          {profilesList.length > 0 &&
            <div className="row-qc-profile row-select">
              <div className="cell-qc-profile cell-qc-profile-select">
                <SelectAdvanced
                  options={profilesList}
                  placeholder="Select QC Profile"
                  onSelect={(n, v) => !!v && onChange('qc_profile_id', [...selectedQcProfiles, {qc_profile_id: v}])}
                />
              </div>
              <div className="cell-qc-type"/>
              {!isUseDefaultDistributorQcProfile && <div className="cell-qc-profile-remove"/>}
            </div>
          }
        </ComboTable> :
        <div className="no-profiles">
          There are no QC profiles available.  Create them in the template builder.
        </div>
      }
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  .no-profiles {
    color: #a7a7a7;
    text-align: center;
  }
`;

const ComboTable = styled.div`
  .row-qc-profile {
    margin-left: -20px;
    margin-right: -20px;
    padding: 13px 37px 17px 19px;
    width: calc(100% + 40px);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    column-gap: 68px;
    + .row-qc-profile {
      margin-top: 15px;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 7px;
    }
    &:not(.row-top) {
      background: #f9f9f9;
      border: 1px solid #f2f2f2;
      .Select.is-searchable.Select--single {
        .Select-control {
          .Select-placeholder {
            opacity: 0.4 !important;
          }
        }
        &:not(.is-open),
        &:not(.is-focused) {
          .Select-control {
            background-color: #fff;
          }
        }
      }
    }
    &.row-top {
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid transparent;
      + .row-qc-profile {
        margin-top: 8px;
        &.row-select {
          padding-top: 0;
          background: transparent;
          border-color: transparent;
          .Select.is-searchable.Select--single {
            &:not(.is-open),
            &:not(.is-focused) {
              .Select-control {
                background-color: #f3f3f3;
              }
            }
          }
        }
      }
    }
    &:last-of-type {
      margin-bottom: 35px;
    }
  }
  font-size: 13px;
  .cell-top {
    font-weight: 700;
  }
  .cell-qc-profile {
    width: calc(25% - 39px);
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
    &:not(.cell-qc-profile-select) {
      font-weight: 700;
    }
    .note-qc-profile {
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      opacity: 0.5;
      margin-top: 5px;
    }
    a {
      font-size: 14px;
      color: #29A4CB;
      border-radius: 2px;
      transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
      &:hover {
        color: #228daf;
        background: #fff;
        box-shadow: 0 0 0 5px #fff;
      }
    }
  }
  .cell-qc-type {
    @media (min-width: 768px) {
      width: calc(75% - 39px*2 - 38px);
    }
    @media (max-width: 767px) {
      width: calc(100% - 38px - 30px);
    }
    ${props => props.noSelected && css`
      color: transparent;
    `}
    .qc-types-items {
      .row {
        + .row {
          margin-top: 15px;
        }
        .qc-types-item {
          margin: 0 !important;
          &:not(.qc-type-selected) {
            .icon-circle-check {
              color: #e3e3e3 !important;
            }
          }
          .icon-circle-check {
            top: -3px !important;
          }
          .help-tooltip {
            width: 18px;
            height: 18px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 3px;
            margin-top: -3px;
            color: #282828;
            opacity: 0.3;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  .cell-qc-profile-remove {
    flex: 0 0 38px;
    .btn {
      margin: 0;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px !important;
      &:focus,
      &:hover,
      &:active {
        background: #e6e6e6 !important;
      }
    }
  }
  .form-group {
    margin: 0;
  }
`;

export default QcProfilesForQuote;
