import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import InputTextCell from './InputTextCell';
import InputNumberCell from './InputNumberCell';
import SelectableCell from '../../../Settings/components/qcProfiles/templateBuilder/table/SelectableCell';
import MultiSelectableCell from '../../../Settings/components/qcProfiles/templateBuilder/table/MultiSelectableCell';
import {prepareHoursSpentOptions, prepareHoursSpentValue} from '../tablecells/HoursSpentCell'
import {IS_BILLABLE_OPTIONS, IS_BILLABLE_VALUE_YES, getIsBillableOptionByValue} from '../tablecells/IsBillableCell'
import {SortableHandle} from 'react-sortable-hoc';
import {checkDiscountPercentStep} from './utils';

const DragHandle = SortableHandle(() => <i className="fa fa-bars" title="Move to change order"/>);

function Row(props) {
  const {
    value,
    onDelete,
    onUndelete,
    onInsert,
    onDuplicate,
    onFocus, onBlur,
    onInput, onSelect,
    productCodes, allProductCodes, categories, assignees, statuses,
    labels, descriptions,
    isCreatedWO,
    onlyAddingPatchSteps,
  } = props;

  // fixme - remove deprecated and unused code
  const isCanceled = false; // value.StepStatusID === 0 && !(value.changedFields || []).includes('StepStatusID');
  const isNew = !!value.isNew;
  const isDeleted = !!value.isDeleted;
  const isDisabledStatusAndTimeSpent = !isCreatedWO || isNew;
  // deprecated
  const isDisabledProductCodeAndCategory = false; // !isNew;
  const isEmptyPlaceholder = isCanceled || isDeleted;
  const isDiscountPercentStep = checkDiscountPercentStep(value, isCreatedWO);
  const isPatchStep = !!value.IsPatchStep;

  // for case when we back from next window in wizard
  const isOverrideProductCode = (value.ProductCode !== value.tmplProductCode || value.OverrideRate !== value.tmplRate);
  const [overrideProductCode, setOverrideProductCode] = useState(isOverrideProductCode);
  const templateStepProductCode = useRef({
    Rate: value.tmplRate,
    Unit: value.tmplUnit,
    name: value.tmplProductCode,
    value: value.tmplProductCode
  });

  const handleSelectBlur = () => {
    const modalBody = document.querySelector('.modal-body-custom');
    if (modalBody) {
      modalBody.classList.remove('no-scroll');
    }

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const handleSelectFocus = () => {
    const modalBody = document.querySelector('.modal-body-custom');
    if (modalBody) {
      modalBody.classList.add('no-scroll');
    }

    if (props.onFocus) {
      props.onFocus();
    }
  };

  let selectResourceCategoryFlag = false;
  for (const category of categories) {
    if ((value.StepFlags & category.value) > 0 && category.flag_type === 'Resource') {
      selectResourceCategoryFlag = true;
    }
  }
  let categoryOptions = [];
  for (const category of categories) {
    if (category.flag_type === 'Resource') {
      if ((value.StepFlags & category.value) > 0) {
        categoryOptions.push(category);
        continue;
      }
      if (selectResourceCategoryFlag) {
        continue
      }
    }
    categoryOptions.push(category);
  }

  const useOverrideValuesForTemplateStep = () => {
    const productCode = {
      Rate: value.override_step.OverrideRate,
      Unit: value.override_step.Unit,
      name: value.override_step.ProductCode,
      value: value.override_step.ProductCode
    };
    onSelect('ProductCode', productCode);
    onSelect('OverrideRate', {value: productCode.Rate});
    setOverrideProductCode(true);
  };

  const useTemplateValuesForStep = () => {
    onSelect('ProductCode', templateStepProductCode.current);
    onSelect('OverrideRate', {value: templateStepProductCode.current.Rate});
    setOverrideProductCode(false);
  };

  let overrideStepRow = null;
  if (value.override_step) {
    if (overrideProductCode) {
      overrideStepRow = (
          <tr>
            <td colSpan="15">
              <span>Step above has another values in template: product code = {templateStepProductCode.current.name}; rate = {templateStepProductCode.current.Rate}</span>
              <Button
                  className="btn-as-text"
                  title="Use these values for step"
                  onClick={useTemplateValuesForStep}
              >
                Revert product code & rate from template for this step
              </Button>
            </td>
          </tr>
      )
    } else {
      overrideStepRow = (
          <tr>
            <td colSpan="15">
              <span>Step above has another values in previous work order: product code = {value.override_step.ProductCode}; rate = {value.override_step.OverrideRate}</span>
              <Button
                  className="btn-as-text"
                  title="Use these values for step"
                  onClick={useOverrideValuesForTemplateStep}
              >
                Use these values for this step
              </Button>
            </td>
          </tr>
      )
    }
  }

  return (
    <React.Fragment>
    <tr
      className={classNames({
        canceled: isCanceled,
        deleted: isDeleted,
        disabled: (onlyAddingPatchSteps && !isPatchStep && !isNew),
        'disabled-sort': onlyAddingPatchSteps,
        'is-new-step-row': isNew,
      })}
    >
      <td className="sort">
        <DragHandle/>
      </td>
      <td className="step-order">
        {isDeleted ? <>&nbsp;</> : value.StepOrder}{(isNew || isDeleted) ? <sup>{isDeleted ? 'deleted' : 'new'}</sup> : ''}
      </td>
      <InputTextCell
        className="step-label"
        placeholder="Enter Label..."
        value={value.Label}
        onChange={t => onInput('Label', t)}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={30}
        minWidth={170}
        suggestions={labels}
      />
      <InputTextCell
        className="step-description"
        placeholder="Enter Description..."
        value={value.Details}
        onChange={t => onInput('Details', t)}
        onFocus={onFocus}
        onBlur={onBlur}
        /*type="textarea"*/
        maxLength={150}
        minWidth={200}
        suggestions={descriptions}
      />
      <SelectableCell
        className={classNames({
          'product-code': true,
          disabled: isDisabledProductCodeAndCategory,
          'without--cost': value.hasOwnProperty('Cost') && value.Cost == null
        })}
        placeholder={(isEmptyPlaceholder || isDisabledProductCodeAndCategory) ? '' : undefined}
        value={value.ProductCode}
        options={productCodes}
        onChange={o => onSelect('ProductCode', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        isProductCode
        allProductCodes={allProductCodes}
        menuMaxHeight={150}
        minWidth={225}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <td className="step-unit">
        {value.Unit}
      </td>
      <InputNumberCell
        className={classNames({
          'override-rate': true,
          'is-percent': isDiscountPercentStep
        })}
        placeholder={(isEmptyPlaceholder) ? '' : isDiscountPercentStep ? 'Enter %' : undefined}
        value={value.OverrideRate}
        onChange={v => onInput('OverrideRate', v)}
        onFocus={onFocus}
        onBlur={onBlur}
        minValue={!isCreatedWO ? 0 : undefined}
        maxValue={isDiscountPercentStep? 100 : undefined}
        isCost={!isDiscountPercentStep}
        isPercent={isDiscountPercentStep}
      />
      <SelectableCell
        className={classNames({
          'assignee': true,
        })}
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        assigneeName={value.Assignee}
        value={value.AssigneeId || null}
        options={assignees}
        onChange={o => onSelect('AssigneeId', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={170}
        isClearable
        isAssigneeControl
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      {categories.length > 0 ?
        <MultiSelectableCell
          className={classNames({
            'category-flags': true,
            disabled: isDisabledProductCodeAndCategory,
          })}
          disabled={isEmptyPlaceholder || isDisabledProductCodeAndCategory}
          placeholder={(isEmptyPlaceholder || isDisabledProductCodeAndCategory) ? '' : undefined}
          value={value.StepFlags}
          options={categoryOptions}
          onChange={v => {
            const allCategoriesFlags = categories.map(o => o.value).reduce((a, b) => a | b, 0);
            const newStepFlags = ((value.StepFlags || 0) & ~allCategoriesFlags) | v;
            onInput('StepFlags', newStepFlags);
          }}
          onFocus={ /*handleSelectFocus*/onFocus }
          onBlur={ /*handleSelectBlur*/onBlur }
          menuMaxHeight={150}
          //minWidth={180}
          menuPlacement="auto"
          menuPortalTarget={true}
        /> : <td className="category-flags">
          <span>Select...</span>
        </td>
      }
      <SelectableCell
        className={classNames({
          'time-spent': true,
          disabled: isDisabledStatusAndTimeSpent,
        })}
        placeholder={(isEmptyPlaceholder || isDisabledStatusAndTimeSpent) ? '' : undefined}
        value={prepareHoursSpentValue(value.HoursSpent)}
        options={prepareHoursSpentOptions(value.HoursSpent)}
        onChange={o => onSelect('HoursSpent', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <SelectableCell
        className={classNames({
          'status': true,
          disabled: isDisabledStatusAndTimeSpent,
        })}
        placeholder={(isEmptyPlaceholder || isDisabledStatusAndTimeSpent) ? '' : undefined}
        value={value.StepStatusID}
        options={statuses}
        onChange={o => onSelect('StepStatusID', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <SelectableCell
        className="is-billable"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={getIsBillableOptionByValue(value.IsBillable).value}
        options={IS_BILLABLE_OPTIONS}
        onChange={o => !!o && onInput('IsBillable', o.value === IS_BILLABLE_VALUE_YES)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        /*minWidth={100}*/
        menuPortalTarget={true}
      />
      <InputNumberCell
        className="quantity"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={value.Quantity}
        onChange={v => onInput('Quantity', v)}
        onFocus={onFocus}
        onBlur={onBlur}
        minValue={0}
      />
      <SelectableCell
        className="estimated-hours"
        placeholder={(isEmptyPlaceholder) ? '' : undefined}
        value={prepareHoursSpentValue(value.EstimatedHours, true)}
        options={prepareHoursSpentOptions(value.EstimatedHours, 24, true)}
        onChange={o => onSelect('EstimatedHours', o)}
        onFocus={ handleSelectFocus }
        onBlur={ handleSelectBlur }
        menuMaxHeight={150}
        minWidth={120}
        menuPortalTarget={true}
        menuPlacement="auto"
      />
      <td className="actions">
        <div>
          {isDeleted ?
            <Button
              className="btn-in-modal"
              title="Click to undelete step"
              onClick={onUndelete}
            >
              <i className="far fa-fw fa-trash-restore"/>
            </Button> : (isNew || !isCreatedWO) &&
            <Button
              className="btn-in-modal"
              title="Delete step"
              onClick={onDelete}
            >
              <i className="far fa-fw fa-trash-can"/>
            </Button>
          }
          {!onlyAddingPatchSteps &&
            <Button
              className="btn-in-modal"
              title="Insert new step below"
              onClick={onInsert}
            >
              <i className="far fa-fw fa-plus-circle"/>
            </Button>
          }
          {(isNew || !onlyAddingPatchSteps || isPatchStep) &&
            <Button
              className="btn-in-modal"
              title="Duplicate step"
              onClick={onDuplicate}
            >
              <i className="far fa-fw fa-clone"/>
            </Button>
          }
        </div>
      </td>
    </tr>
    { overrideStepRow }
    </React.Fragment>
  );
}

export default Row;