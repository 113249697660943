import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getDate, getMonth, getYear, getISODay} from 'date-fns';
import DatePicker, {registerLocale} from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import ArrowLeft from '../../../../../assets/images/dashboard/calendar-arrow-left.svg';
import ArrowRight from '../../../../../assets/images/dashboard/calendar-arrow-right.svg';
import isMobile from 'is-mobile';

registerLocale('en', en);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

class Calendar extends Component {
  highlightDates = [];

  componentDidMount() {
    this.highlightCalendarDates();
    this.scrollDaysToStartOfMonth();
  }

  UNSAFE_componentWillReceiveProps() {
    this.highlightCalendarDates();
  }

  getDataWithDate() {
    const {data} = this.props;
    return data ? data.map(item => {
      const date = /(\d+)-(\d+)-(\d+)/.exec(item.date);
      return {
        count: item.count,
        date: new Date(Number(date[1]), Number(date[2]) - 1, Number(date[3]))
      }
    }) : [];
  }

  highlightCalendarDates() {
    const data = this.getDataWithDate();
    this.highlightDates = [
      {'day--gighlighted-light': data.filter(item => item.count <= 2).map(item => item.date)},
      {'day--gighlighted-middle': data.filter(item => item.count > 2 && item.count <= 4).map(item => item.date)},
      {'day--gighlighted-dark': data.filter(item => item.count > 4).map(item => item.date)}
    ];
  }

  changeMonth = date => {
    const {onChangeDate} = this.props;
    this.highlightCalendarDates();
    this.scrollDaysToStartOfMonth();
    onChangeDate({month: getMonth(date) + 1, year: getYear(date)});
  };

  scrollDaysToStartOfMonth = () => {
    if (isMobile()) {
      try {
        document.getElementsByClassName('react-datepicker__month')[0].scrollLeft = 0;
      } catch (e) {
        console.log(e);
      }
    }
  };

  getHeader = props => {
    const {
      date,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled
    } = props;
    return (
      <CalendarHeaderStyled>
        <span className="month">{months[getMonth(date)]}</span>
        <span className="year">{getYear(date)}</span>
        <button
          className="btn btn-prev"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
        </button>
        <button
          className="btn btn-next"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
        </button>
      </CalendarHeaderStyled>
    );
  };

  onSelectDate = date => {
    const {data, day, onChangeDate} = this.props;
    const selectedDate = moment(date).format('YYYY-MM-DD');
    const isDateNotEmpty = data.some(item => item.date === selectedDate);
    if (isDateNotEmpty) {
      const isDateChanged = !(day && date.getDate() === day);
      if (isDateChanged) {
        onChangeDate({day: getDate(date), month: getMonth(date) + 1, year: getYear(date)});
      } else {
        onChangeDate({month: getMonth(date) + 1, year: getYear(date)});
      }
    }
  };

  getDayClassName = date => {
    return `iso-day--${getISODay(date)}`;
  };

  render() {
    const {year, month, day} = this.props;
    const selectedDate = (year && month && day) ? new Date(year, month - 1, day) : null;

    return (
      <CalendarStyled className={isMobile() ? 'is-mobile-calendar' : ''}>
        <DatePicker
          inline
          locale="en"
          monthsShown={1}
          useWeekdaysShort={true}
          renderCustomHeader={this.getHeader}
          highlightDates={this.highlightDates}
          selected={selectedDate}
          onSelect={this.onSelectDate}
          onMonthChange={this.changeMonth}
          dayClassName={this.getDayClassName}
        />
      </CalendarStyled>
    )
  }
}

const CalendarHeaderStyled = styled.div`
  align-items: center;
  display: flex;
  text-align: left;
  .btn {
    margin: 0 3px;
    padding: 5px;
    &:focus, &:hover {
      outline: none;
    }
    &.btn-prev {
      background: url(${ArrowLeft}) center center no-repeat;
      background-size: 9px;
    }
    &.btn-next {
      background: url(${ArrowRight}) center center no-repeat;
      background-size: 9px;
    }
  }
  .month {
    font-size: 13px;
    font-weight: bold;
    margin-right: 3px;
  }
  .year {
    font-size: 13px;
    margin-right: 5px;
  }
`;

const CalendarStyled = styled.div`
  border-bottom: 1px solid #D3D3D3;
  padding: 15px 20px;
  .react-datepicker {
    border: none;
    background: transparent;
    width: 100%;
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__header {
      background: transparent;
      border: none;
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      color: #444444;
      font-size: 13px;
      line-height: 1.6vw;
      margin-bottom: 0.8vw;
      width: 1.6vw;

      @media (max-width: 991px) {
        line-height: 21px;
        width: 21px
      }
    }
    .react-datepicker__day {
      cursor: default;
      border-radius: 100%;
    }
    .react-datepicker__day--today {
      background: transparent;
      font-weight: normal;
    }
    .react-datepicker__day--outside-month {
      opacity: 0.2;
    }
    .react-datepicker__day--selected {
      background: #42CB83 !important;
    }

    [class*="day--gighlighted-"] {
      color: #fff;
    }
    .day--gighlighted-light {
      background: #9b86be;
      cursor: pointer;
    }
    .day--gighlighted-middle {
      background: #5D3996;
      cursor: pointer;
    }
    .day--gighlighted-dark {
      background: #3D226A;
      cursor: pointer;
    }
  }

  @media (max-width: 992px) {
    padding: 10px;
  }

  &.is-mobile-calendar {
    @media (max-width: 767px) {
      background: #F9F9F9;
      padding: 0;
      border-bottom-color: #EFEFEF;

      .react-datepicker {

        .react-datepicker__header {
          border-radius: 0;
          padding: 15px;
          border-top: 1px solid #EFEFEF !important;
          border-bottom: 1px solid #EFEFEF !important;
        }

        .react-datepicker__day-names {
          display: none;
        }

        .react-datepicker__month {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          margin: 0 15px;
        }

        .react-datepicker__day--outside-month {
          display: none;
        }

        .react-datepicker__day {
          position: relative;
          margin-top: 58px;
          margin-right: calc((100vw - 21px * 7 - 70px) / 6);
          margin-bottom: 19px;

          &.iso-day--1:before {
            content: 'M';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--2:before {
            content: 'T';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--3:before {
            content: 'W';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--4:before {
            content: 'T';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--5:before {
            content: 'F';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--6:before {
            content: 'S';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
          &.iso-day--7:before {
            content: 'S';
            position: absolute;
            top: -36px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #505050;
            opacity: 0.4;
          }
        }
      }
    }
  }
`;

Calendar.propTypes = {
  data: PropTypes.array,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeDate: PropTypes.func.isRequired
};

export default Calendar;
