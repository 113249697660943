import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StatusCell = ({value, row, className}) => {
  return (
    <StyledStatusCell className={className} statusColor={row.status_color}>{value}</StyledStatusCell>
  );
};

const StyledStatusCell = styled.td`
  color: ${props => props.statusColor ? `#${props.statusColor}` : 'unset'};
  opacity: 0.7;
`;

StatusCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default StatusCell;
