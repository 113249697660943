import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const LinkSeasonReportCell = (props) => {
    const {value, row, className, title} = props;
    let url = null;
    if (value && row.TitleForFilter) {
        url = 'analytics?report=season_report_v2&season_title[0]=' + row.TitleForFilter;
    }
    return (
        <StyledLinkSeasonReportCell
            className={className}
            title={title}
        >
            {url ? <Link title="Open Season Status Report" to={url} target="_blank">{value}</Link> : value}
        </StyledLinkSeasonReportCell>
    );
};

const StyledLinkSeasonReportCell = styled.td`
  a {
    color: #29A4CB;
    font-weight: bold;
    &:hover {
      color: #228daf;
    }
  }
`;

LinkSeasonReportCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string
};

export default LinkSeasonReportCell;
