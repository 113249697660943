import styled from 'styled-components';

const StyledDistribution = styled.div`
  .new-request .new-request-wrapper & { 
    &.distribution-step {
    margin-top: 27px;
  
    .distributors-table {
  
      .table-info, .create-new-distributor {
        margin-top: 2px;
  
        .distributor-select-text {
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
  
          &+div {
            font-weight: 500;
            font-size: 12px;
            line-height: 13px;
            opacity: 0.5;
            margin-top: 4px;
          }
        }
      }
  
      .create-new-distributor {
        margin-top: 2px;
        padding: 17px 31px 5px 30px;
        background-color: #f5f5f5;
  
        .distributor-select-text {
          margin-bottom: 11px;
        }
  
        .distributor-new-fields {
          .form-group {
            width: 100%;
            margin-bottom: 20px;
  
            .form-control {
              font-family: 'Roboto', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 31px;
              color: #282828;
              background-color: #fff;
              border: 1px solid #E2E2E2;
              border-radius: 5px;
              box-sizing: border-box;
              box-shadow: none;
              padding: 6px 12px 0;
              height: auto;
  
              &::placeholder {
                color: #282828;
                opacity: 0.5;
                font-weight: 400;
              }
            }
  
            &.optional {
              position: relative;
  
              &:after {
                content: 'Optional';
                position: absolute;
                right: 8px;
                top: 7px;
                text-transform: uppercase;
                font-family: 'Roboto', sans-serif;
                font-weight: bold;
                font-size: 10px;
                line-height: 31px;
                color: #282828;
                opacity: 0.1;
              }
  
              .form-control {
                padding-right: 9px;
              }
            }
          }
  
          .table-buttons-container {
            margin-top: 0;
            width: 100%;
  
            .btn {
  
              &.btn-default {
                margin-right: 28px;
                margin-left: 8px;
                color: #626262;
  
                &:hover {
                  color: #282828;
                }
              }
  
              &.btn-primary {
                padding: 0 56px;
              }
            }
          }
        }
      }
  
      .btn.btn-primary {
        padding: 0 34px;
      }
  
      .table-buttons-container {
        width: 389px;
        margin-top: 4px;
  
        .btn-create-new-distributor {
          margin: -4px 0 23px 26px;
  
          &[disabled] {
            background-color: transparent;
            color: #626262;
            opacity: 0.3;
            padding: 0 21px;
            cursor: default;
  
            img {
              max-width: unset;
              padding-left: 14px;
            }
          }
        }
      }
  
      table.table {
  
        th {
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
        }
  
        td {
          padding-top: 18px;
          vertical-align: top;
  
          &:nth-child(2) {
            width: 183px;
            font-weight: 700;
            font-size: 15px;
            line-height: 16px;
          }
  
          &:nth-child(3) {
            font-weight: 500;
            font-size: 13px;
            line-height: 14px;
  
            .row:last-child {
              padding-top: 3px;
              font-weight: 300;
              line-height: 17px;
              opacity: 0.7;
            }
          }
  
          &:first-child {
            width: 90px;
            padding-top: 16px;
            padding-bottom: 31px;
            padding-right: 31px;
          }
  
          &:last-child {
            width: 151px;
            padding-top: 19px;
            padding-left: 16px;
            text-align: center;
  
            img {
              max-width: 99px;
              max-height: 32px;
            }
          }
        }
      }
    }
  
    &.distributor-options {
      margin: 0 -15px 10px;
  
      .distributor-top {
        width: 100%;
        height: auto;
        background-color: #f3f3f3;
        padding: 29px 35px 0 88px;
  
        .distributor-select-text {
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
        }
  
        .distributor-change {
          .btn.btn-primary {
            padding: 0 39px;
            border: 1px solid #C7C7C7;
            color: #B3B3B3;
            background-color: transparent;
            margin-top: 19px;
            margin-bottom: 29px;
  
            &:hover {
              color: #fff;
              background-color: #33333F;
              border-color: #33333F;
            }
          }
        }
  
        .distributor-selected-info {
  
          img.distributor-logo {
            max-width: 100%;
            height: 41px;
            /*min-width: 41px;*/
            overflow: hidden;
            margin-top: 16px;
            margin-bottom: 29px;
          }
  
          &.no-logo {
  
            .distributor-name {
              font-weight: 700;
              font-size: 15px;
              line-height: 16px;
              margin: 23px 15px 5px 0;
  
              .distributor-website {
                font-weight: 300;
                font-size: 12px;
                line-height: 13px;
                color: #878793;
                margin-top: 6px;
              }
            }
  
            .distributor-change .btn.btn-primary {
              margin-top: 23px;
              margin-bottom: 25px;
            }
          }
        }
      }
  
      .qc-profiles-and-types {
        margin: 0 121px 10px 88px;
        padding: 37px 0 0;
  
        label.control-label {
          display: block;
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
          color: #282828;
          padding-bottom: 7px;
        }
  
        .qc-profiles {
  
          .qc-profile-default {
            position: relative;
            width: 100%;
  
            .qc-profiles-and-types-label+.row {
              font-weight: 700;
              font-size: 15px;
              line-height: 16px;
              margin-top: 3px;
            }
  
            .qc-profiles-and-types-label+.row+.row {
              font-weight: 500;
              font-size: 12px;
              line-height: 13px;
              opacity: 0.5;
              margin-top: 5px;
              margin-bottom: 30px;
            }
          }
  
          .qc-profile-select {
            position: relative;
            width: 100%;
  
            .overlay-what-is-this {
              position: absolute;
              top: 0;
              right: 0;
              font-weight: 700;
              font-size: 13px;
              line-height: 14px;
              color: #282828;
              opacity: 0.3;
              cursor: pointer;
  
              &:hover {
                color: #23527c;
                opacity: 0.7;
              }
            }

            .Select.is-searchable.Select--single .Select-control .Select-multi-value-wrapper .Select-value {
              padding-right: 32px;
            }

            .Select.is-searchable.Select--single .Select-option .approved {
              opacity: 0.8 !important;
              font-size: 12px !important;
            }
          }
        }
  
        .qc-types {
  
          .qc-types-items {
            padding: 10px 0 0;
  
            .row {
  
              .qc-type-line {
                margin: 17px 40px 42px 33px;
                height: 4px;
                background: #C4C4C4;
                opacity: 0.2;
              }
  
              .qc-types-item {
                cursor: pointer;
                position: relative;
                padding: 0 0 0 33px;
                margin-right: 40px;
  
                .qc-type-note {
                  font-weight: 300;
                  font-size: 13px;
                  line-height: 17px;
                  opacity: 0.4;
                }
  
                .qc-type-name {
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 16px;
                  opacity: 0.5;
                }
  
                .icon-circle-check {
                  color: #F3F3F3;
                  font-size: 21.6px;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
  
                &:hover {
                  .qc-type-note {
                    opacity: 0.7;
                  }
                  .qc-type-name {
                    opacity: 1;
                  }
                }
  
                &.qc-type-selected {
                  cursor: default;
  
                  .icon-circle-check {
                    color: #E75592;
                  }
  
                  .qc-type-note {
                    opacity: 0.7;
                  }
  
                  .qc-type-name {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
    @media (min-width: 992px) {
    .distributor-new-fields {
      .col-md-2 {
        width: 20%;
        padding-right: 0;
      }
      .col-md-3 {
        width: 255px;
        padding-right: 0;
      }
      .col-md-4 {
        width: calc(60% - 255px);
      }
    }

    .distributor-selected-info {
      .distributor-current.col-md-4 {
        width: auto;
      }
      .distributor-change.col-md-8 {
        min-width: 130px;
        width: auto;
      }
    }
    .qc-profiles-and-types {
      .qc-profiles.col-md-3 {
        padding-right: 31px;
      }
      .qc-types.col-md-9 {
        padding-left: 52px;
      }
    }
  }
  }
`;

export default StyledDistribution;