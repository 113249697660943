import React from 'react';

export default class HeaderSearchIcon extends React.PureComponent<React.HTMLAttributes<HTMLSpanElement>> {
  render() {
    return (
      <span {...this.props}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.1999 15.2999L15.2999 16.1999L10.6365 11.591C9.51502 12.5033 8.08416 13.05 6.525 13.05C2.92149 13.05 0 10.1285 0 6.525C0 2.92149 2.92149 0 6.525 0C10.1285 0 13.05 2.92149 13.05 6.525C13.05 8.08416 12.5033 9.51503 11.591 10.6365L16.1999 15.2999ZM6.52486 11.6999C9.38308 11.6999 11.6999 9.38313 11.6999 6.52491C11.6999 3.66669 9.38308 1.34991 6.52486 1.34991C3.66664 1.34991 1.34986 3.66669 1.34986 6.52491C1.34986 9.38313 3.66664 11.6999 6.52486 11.6999Z" fill="white"/>
        </svg>
    </span>
    );
  }
}