import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  PROJECTS_CLEAR,
  PROJECTS_SET_LOADING,
  PROJECTS_GET_PROJECTS,
  PROJECTS_GET_FILTERS,
  PROJECTS_ADD_FILTERS,
  PROJECTS_CHANGE_FILTER_TYPE,
  PROJECTS_CHANGE_FILTER,
  PROJECTS_SAVED_FILTER,
  PROJECT_UPDATE,
} from './actions';

const initialState = {
  total: 0,
  data: [],
  allowedFilters: [],
  selectedFilters: [{
    name: 'search',
    value: null
  }, {
    name: 'sort',
    value: {}
  }],
  loaders: {
    filters: true,
    projects: true,
    more: false
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case PROJECTS_CLEAR: {
      return {...initialState};
    }

    case PROJECTS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case PROJECTS_GET_PROJECTS: {
      const {selectedFilters} = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map(item => {
          return item.name === 'search' && typeof item.value === 'string' ? {
            ...item,
            value: item.value.trim()
          } : item;
        }),
        loaders: {
          ...state.loaders,
          projects: false,
          more: false
        },
        data: [...action.data],
        total: action.total
      };
    }

    case PROJECTS_GET_FILTERS: {
      return {
        ...state,
        allowedFilters: action.allowedFilters
      }
    }

    case PROJECTS_CHANGE_FILTER: {
      const {selectedFilters} = state;
      const isFilterExist = !!selectedFilters.find(filter => filter.name === action.name);
      if (isFilterExist) {
        return {
          ...state,
          selectedFilters: selectedFilters.map(item => {
            return item.name === action.name ? {
              ...item,
              value: action.value || null
            } : item;
          })
        };
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters,
          {
            name: action.name,
            value: action.value
          }
        ]
      }
    }

    case PROJECTS_SAVED_FILTER: {
      const urlFilters = {...action.urlFilters};
      if (!urlFilters.search) {
        urlFilters.search = null;
      }
      if (!urlFilters.sort) {
        urlFilters.sort = {};
      }

      const selectedFilters = [];
      Object.keys(urlFilters).forEach(key => {
        const value = Array.isArray(urlFilters[key]) ?
          urlFilters[key].map(item => +item) :
          urlFilters[key];

        const res = {name: key, value};
        if (key === 'Last Updated') {
          res.disabled = true;
          selectedFilters.unshift(res);
        } else {
          selectedFilters.push(res);
        }
      });

      return {
        ...state,
        selectedFilters
      }
    }

    case PROJECTS_CHANGE_FILTER_TYPE: {
      return {
        ...state,
        selectedFilters: state.selectedFilters.map(item => {
          return item.name === action.filterName ? {
            name: action.newFilterName,
            title: action.newFilterLabel,
            value: action.defaultValue ? (Array.isArray(action.defaultValue) ? action.defaultValue : [action.defaultValue]) : null
          } : item;
        })
      };
    }

    case PROJECTS_ADD_FILTERS: {
      const selectedFilters = [...state.selectedFilters];
      const res = {
        name: action.filter.name,
        title: action.filter.label,
        value: action.filter.defaultValue ?
         (Array.isArray(action.filter.defaultValue) ? action.filter.defaultValue : [action.filter.defaultValue]) : null,
        disabled: action.filter.disabled
      };

      if (action.filter.required) {
        selectedFilters.unshift(res);
      } else {
        selectedFilters.push(res);
      }

      return {
        ...state,
        selectedFilters
      };
    }

    case PROJECT_UPDATE: {
      const newProjecList = state.data.map((p) => {
        if (p.QcProjectIDs !== action.project.QcProjectIDs) {
          return p;
        }

        return action.project;
      });

      return {
        ...state,
        data: newProjecList
      }
    }

    default: {
      return state;
    }
  }
}
