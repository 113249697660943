import config from './config';

const isNeedsFixesRequestStatus = statusID => statusID === config.requestStatuses.NEEDS_FIXES;

const isUserCanManage = userPermissionType => {
  return !!userPermissionType && userPermissionType >= config.requestUserPermission.CAN_MANAGE;
};

const allowMarkRequestAsDone = ({RequestStatusID, UserPermissionType}) => {
  return isNeedsFixesRequestStatus(RequestStatusID) && isUserCanManage(UserPermissionType);
};

const allowSubmitReplacement = request => {
  if (isUserCanManage(request.UserPermissionType)) {
    const nonCancelledRevisions = (request.timeline || []).filter(line => typeof line.RequestID === 'number' &&
      line.CurrentRequestStatusID !== config.requestStatuses.CANCELLED);
    const nonCancelledRevisionsCount = nonCancelledRevisions.length;
    if (nonCancelledRevisionsCount && nonCancelledRevisions[nonCancelledRevisionsCount - 1].RequestID !== request.requestId) {
      return false;
    }
    if (isNeedsFixesRequestStatus(request.RequestStatusID)) {
      return true;
    }
    if (request.QcStatusID === config.qcStatus.QC_PASS) {
      const nextNonCancelledRequests = (request.timeline || []).filter(line => typeof line.RequestID === 'number' &&
        line.RequestID > request.requestId && line.CurrentRequestStatusID !== config.requestStatuses.CANCELLED);
      return nextNonCancelledRequests.length === 0;
    }
  }
  return false;
};

const allowViewConflictsIssue = ({/*IsQodIssue, */IsDeleted, IsLocked}) => /*!!IsQodIssue && */!IsDeleted && !!IsLocked;

const allowAddIssue = ({allow_add_issues}) => !!allow_add_issues;

const allowEditIssue = ({allowEditOnRequest, IsDeleted, IsLocked}) => !!allowEditOnRequest && !IsDeleted && !IsLocked;

const allowDeleteIssue = isQodIssue => !!isQodIssue;

const allowViewIssueImageForNonManager = ({ImageExists}) => !!ImageExists;

const allowCtrlIssueImageForManager = ({ImageExists, IsDeleted}) => !!ImageExists || !IsDeleted;

const showReportBtnForClient = ({IsReportSent}) => !!IsReportSent;

const rules = {};

const CLIENT_USER_STATIC_RULES = [
    'header:show-menu',
    'header:show-requests',
    'header:show-analytics',
    'header:show-settings',
    'header:show-create-qc-request-btn',
    'header:show-search-btn',
    'header:user:show-settings-and-preferences',
    'settings:general:notifications',
    'settings:reporting',
    'settings:qc-profiles',
    'settings:schedules',
    'report-details:show-create-fixlist-btn',
    'report-details:show-batch-reports-btn',
];

rules[config.roles.STUDIO] = {
  static: [
    ...CLIENT_USER_STATIC_RULES,
    'header:show-dashboard',
  ],

  dynamic: {
    'report-details:show-email-report-btn': showReportBtnForClient,
    'report-details:show-print-report-btn': showReportBtnForClient,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    //'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    //'report-details:show-submit-replacement-btn': allowSubmitReplacement,
    'report-details:show-issue-image-btn': allowViewIssueImageForNonManager
  }
};

rules[config.roles.PRODUCTION] = {
  static: [
    ...CLIENT_USER_STATIC_RULES,
  ],

  dynamic: {
    'report-details:show-email-report-btn': showReportBtnForClient,
    'report-details:show-print-report-btn': showReportBtnForClient,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    //'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    //'report-details:show-submit-replacement-btn': allowSubmitReplacement,
    'report-details:show-issue-image-btn': allowViewIssueImageForNonManager
  }
};

rules[config.roles.FINISHING_HOUSE] = {
  static: [
    ...CLIENT_USER_STATIC_RULES,
    'header:show-dashboard',
  ],

  dynamic: {
    'report-details:show-email-report-btn': showReportBtnForClient,
    'report-details:show-print-report-btn': showReportBtnForClient,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    //'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    //'report-details:show-submit-replacement-btn': allowSubmitReplacement,
    'report-details:show-issue-image-btn': allowViewIssueImageForNonManager
  }
};

const INTERNAL_MANAGER_USER_STATIC_RULES = [
  'header:show-admin',
  'header:show-dashboard',
  'header:show-projects',
  'header:show-requests',
  'header:show-analytics',
  'header:show-settings',
  'header:show-create-qc-request-btn',
  'header:show-search-btn',
  'header-mobile:show-manage-profile',
];

const INTERNAL_USER_STATIC_RULES = [
  'report-details:show-general-report-btn',
  'report-details:show-batch-edit-issues-btn',
];

rules[config.roles.CLIENT_SERVICE] = {
  static: [
    ...INTERNAL_MANAGER_USER_STATIC_RULES,
    ...INTERNAL_USER_STATIC_RULES,
    'settings:general:notifications',
    'settings:qc-profiles',
    'report-details:show-create-fixlist-btn',
    'report-details:show-email-report-btn',
    'report-details:show-batch-reports-btn',
    'report-details:show-print-report-btn',
    'report-details:show-import-issues-btn',
    'report-details:show-more-info-issue-btn',
    'report-details:fill-report-frame-rate',
  ],

  dynamic: {
    'report-details:show-issue-image-btn': allowCtrlIssueImageForManager,
    'report-details:modal-issue-image:show-footer': isDeletedIssue => !isDeletedIssue,
    'report-details:show-conflicts-issue-btn': allowViewConflictsIssue,
    'report-details:show-add-issue-btn' : allowAddIssue,
    'report-details:show-edit-issue-btn': allowEditIssue,
    'report-details:show-delete-issue-btn': allowDeleteIssue,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    'report-details:show-submit-replacement-btn': allowSubmitReplacement
  }
};

rules[config.roles.OPS_MANAGER] = {
  static: [
    ...INTERNAL_MANAGER_USER_STATIC_RULES,
    ...INTERNAL_USER_STATIC_RULES,
    'settings:general:notifications',
    'settings:qc-profiles',
    'report-details:show-create-fixlist-btn',
    'report-details:show-email-report-btn',
    'report-details:show-batch-reports-btn',
    'report-details:show-print-report-btn',
    'report-details:show-import-issues-btn',
    'report-details:show-more-info-issue-btn',
  ],

  dynamic: {
    'report-details:show-issue-image-btn': allowCtrlIssueImageForManager,
    'report-details:modal-issue-image:show-footer': isDeletedIssue => !isDeletedIssue,
    'report-details:show-conflicts-issue-btn': allowViewConflictsIssue,
    'report-details:show-add-issue-btn' : allowAddIssue,
    'report-details:show-edit-issue-btn': allowEditIssue,
    'report-details:show-delete-issue-btn': allowDeleteIssue,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    'report-details:show-submit-replacement-btn': allowSubmitReplacement
  }
};

rules[config.roles.DW_MANAGER] = {
  static: [
    ...INTERNAL_MANAGER_USER_STATIC_RULES,
    ...INTERNAL_USER_STATIC_RULES,
    'settings:general:notifications',
    'report-details:show-create-fixlist-btn',
    'report-details:show-email-report-btn',
    'report-details:show-print-report-btn',
    'report-details:show-import-issues-btn',
    'report-details:show-more-info-issue-btn',
    'report-details:fill-report-frame-rate',
  ],

  dynamic: {
    'report-details:complete-dw-qc-btn': ({IsDwOperator}) => !!IsDwOperator,
    'report-details:show-issue-image-btn': allowCtrlIssueImageForManager,
    'report-details:modal-issue-image:show-footer': isDeletedIssue => !isDeletedIssue,
    'report-details:show-conflicts-issue-btn': allowViewConflictsIssue,
    'report-details:show-add-issue-btn' : allowAddIssue,
    'report-details:show-edit-issue-btn': allowEditIssue,
    'report-details:show-delete-issue-btn': allowDeleteIssue,
    'report-details:show-upload-issues-statuses-btn': isUserCanManage,
    'report-details:show-mark-done-btn': allowMarkRequestAsDone,
    'report-details:show-submit-replacement-btn': allowSubmitReplacement
  }
};

rules[config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY] = {
  static: [
    'admin:show-user-admin'
  ]
};

rules[config.roles.MANAGE_USERS__PERMISSIONS] = {
  ...rules[config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY]
};


rules[config.roles.MANAGE_USERS] = {
  ...rules[config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY]
};

rules[config.roles.OPERATOR] = {
  static: [
    ...INTERNAL_USER_STATIC_RULES,
    'header:show-requests',
    'header-mobile:show-manage-profile',
    'report-details:complete-dw-qc-btn',
    'report-details:show-import-issues-btn',
    'report-details:show-more-info-issue-btn',
  ],

  dynamic: {
    'report-details:show-add-issue-btn' : allowAddIssue,
    'report-details:show-edit-issue-btn': allowEditIssue,
    'report-details:show-delete-issue-btn': allowDeleteIssue,
    'report-details:show-issue-image-btn': allowViewIssueImageForNonManager
  }
};

rules[config.roles.ARTWORK_STUDIO] = {
  static: [
    'header:show-artwork-dashboard',
    'header:show-artwork-assets',
    'header:show-analytics',
    'header:show-settings',
    'header-mobile:show-manage-profile',
    'settings:general:notifications',
  ],
};

rules[config.roles.ARTWORK_POST_PARTNER] = {
  static: [
    'header:show-artwork-dashboard',
    'header:show-artwork-assets',
    'header:show-analytics',
    'header:show-settings',
    'header-mobile:show-manage-profile',
    'settings:general:notifications',
  ],
};

rules[config.roles.ARTWORK_OPERATOR] = {
  static: [
    'header:show-artwork-assets',
    'header:show-artwork-request-queue',
    'header:show-artwork-my-requests',
  ],
};

rules[config.roles.ARTWORK_MANAGER] = {
  static: [
    'header:show-artwork-assets',
    'header:show-artwork-request-queue',
    'admin:show-artwork-admin',
  ],
};

rules[config.roles.SHOW_DASHBOARD] = {
  static: [
    'header:show-dashboard',
  ],
};

rules[config.roles.ADMIN] = {
  static: [
    'admin:reload-cache-and-config',
    'admin:show-analytics',
  ],
};

rules[config.roles.MANAGE_OPERATOR_SCHEDULE] = {
  static: [
    'admin:operator-schedule',
  ],
};

rules[config.roles.NETFLIX_INTERNAL] = {
  static: [
    'admin:show-netflix-admin',
  ],
};

export default rules;