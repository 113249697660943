import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ARTWORK_ADTL_CLEAR,
  ARTWORK_ADTL_TOGGLE_OPERATOR_MODE,
  ARTWORK_ADTL_TOGGLE_DARK_MODE,
  ARTWORK_ADTL_SET_LOADING,
  ARTWORK_ADTL_SET_DETAIL,
  ARTWORK_ADTL_UPDATE_DETAIL,
  ARTWORK_ADTL_UPDATE_DETAIL_ISSUE,
  ARTWORK_ADTL_ADD_DETAIL_ISSUE,
  ARTWORK_ADTL_DELETE_DETAIL_ISSUE,
  ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL,
  ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL,
  ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL,
  ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL,
  ARTWORK_ADTL_CHANGE_VIDEO_PARAMS,
  ARTWORK_ADTL_TOGGLE_COMMENT_TO_VIEW,
} from './actions';
import {isArtworkOperatorUser, isArtworkManagerUser} from '../../../utils/general';

const initialState = {
  loaders: {
    getReportDetail: false,
    getReportComments: false,
    loadingAssetMetadata: false,
    loadingSpecDocuments: false,
    runningAutoQC: false,
  },

  reportDetail: null,
  reportDetailFilters: null,
  isDarkMode: false,
  isOperatorMode: isArtworkOperatorUser() || isArtworkManagerUser(),

  isShowUploadNewVersionModal: false,

  assetMetadataModalData: null,
  qcInstructionsModalData: null,
  specDocsModalData: null,

  videoPlayer: {
    selectedIssue: {},
    isChangedSelecting: false,
    isPlaying: false,
    currentIssueID: null
  },

  commentIdToView: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ARTWORK_ADTL_CLEAR: {
      return {...initialState};
    }

    case ARTWORK_ADTL_TOGGLE_OPERATOR_MODE: {
      return {
        ...state,
        isOperatorMode: action.modeIsOn || initialState.isOperatorMode
      };
    }

    case ARTWORK_ADTL_TOGGLE_DARK_MODE: {
      return {
        ...state,
        isDarkMode: action.modeIsOn || initialState.isDarkMode
      };
    }

    case ARTWORK_ADTL_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ARTWORK_ADTL_SET_DETAIL: {
      let comments;
      if (action.data && action.data.asset_id === (state.reportDetail || {}).asset_id) {
        comments = state.reportDetail.comments;
      }
      return {
        ...state,
        reportDetail: action.data ? {...action.data, comments} : initialState.reportDetail,
        reportDetailFilters: action.data ? (action.filters || state.reportDetailFilters || {}) : initialState.reportDetailFilters
      }
    }

    case ARTWORK_ADTL_UPDATE_DETAIL: {
      const {reportDetail} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        updReportDetail = {...reportDetail, ...action.data};
      }
      return {
        ...state,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_ADTL_UPDATE_DETAIL_ISSUE: {
      const {reportDetail} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issues = [...reportDetail.issues];
        const issueIndex = issues.findIndex(i => i.issue_id === action.issue.issue_id);
        if (issueIndex >= 0) {
          issues[issueIndex] = {...action.issue};
          updReportDetail = {...reportDetail, issues};
        }
      }
      return {
        ...state,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_ADTL_ADD_DETAIL_ISSUE: {
      const {reportDetail} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issuesCount = (reportDetail.issues || []).length + 1;
        updReportDetail = {
          ...reportDetail,
          issues_count: issuesCount,
          issues: [...reportDetail.issues || [], action.issue]
        };
      }
      return {
        ...state,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_ADTL_DELETE_DETAIL_ISSUE: {
      const {reportDetail} = state;
      let updReportDetail;
      if ((reportDetail || {}).asset_id === action.assetId) {
        const issues = [...reportDetail.issues].filter(({issue_id}) => issue_id !== action.issueId);
        updReportDetail = {...reportDetail, issues};
      }
      return {
        ...state,
        reportDetail: updReportDetail || reportDetail
      }
    }

    case ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL: {
      return {
        ...state,
        isShowUploadNewVersionModal: action.isShow || initialState.isShowUploadNewVersionModal
      };
    }

    case ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL: {
      return {
        ...state,
        assetMetadataModalData: action.isShow && action.asset ? action.asset : initialState.assetMetadataModalData
      };
    }

    case ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL: {
      return {
        ...state,
        qcInstructionsModalData: action.isShow && action.asset ? action.asset : initialState.qcInstructionsModalData
      };
    }

    case ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL: {
      return {
        ...state,
        specDocsModalData: action.isShow && action.asset ? action.asset : initialState.specDocsModalData
      };
    }

    case ARTWORK_ADTL_CHANGE_VIDEO_PARAMS: {
      return {
        ...state,
        videoPlayer: {
          ...state.videoPlayer,
          ...action.data
        }
      };
    }

    case ARTWORK_ADTL_TOGGLE_COMMENT_TO_VIEW: {
      return {
        ...state,
        commentIdToView: action.commentId || initialState.commentIdToView
      };
    }

    default: {
      return state;
    }
  }
}
