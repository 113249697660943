import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col, FormGroup, ControlLabel, FormControl, Button, ButtonToolbar, Checkbox} from 'react-bootstrap';

class CsGeneralNotes extends Component {

  handleChangeGeneralNote = value => {
    const {data: {replacement}, onChange} = this.props;
    onChange('replacement', {...replacement, general_note: value || ''});
  };

  handleChangeFileCreationNote = value => {
    const {data: {replacement}, onChange} = this.props;
    onChange('replacement', {...replacement, file_creation_note: value || ''});
  };

  handleChangeFileDeliveryNote = value => {
    const {data: {replacement}, onChange} = this.props;
    onChange('replacement', {...replacement, file_delivery_note: value || ''});
  };

  handleChangeOverageFlag = value => {
    const {data: {replacement}, onChange} = this.props;
    onChange('replacement', {...replacement, overage_request: value || false});
  }

  componentDidMount() {
    const {data: {origin_request: {GeneralNote, FileCreationNote, FileDeliveryNote}, replacement: {general_note, file_creation_note, file_delivery_note}}} = this.props;
    if (typeof general_note !== 'string' || typeof file_creation_note !== 'string' || typeof file_delivery_note !== 'string') {
      const newReplacement = {...this.props.data.replacement};
      newReplacement.general_note = GeneralNote || '';
      newReplacement.file_creation_note = FileCreationNote || '';
      newReplacement.file_delivery_note = FileDeliveryNote || '';
      this.props.onChange('replacement', newReplacement);
    }
  }

  handleChangeOrBlur = ({target: {value}}) => {
    this.handleChangeGeneralNote(value);
  };

  handleDelete = () => {
    this.handleChangeGeneralNote('');
  };

  handleUseOriginalValue = () => {
    const {data: {origin_request: {GeneralNote}}} = this.props;
    this.handleChangeGeneralNote(GeneralNote);
  };

  handleTextareaBlur = ({target: {value}}) => {
    this.handleChangeFileCreationNote(value);
  };

  handleDeleteFileCreationNote = () => {
    this.handleChangeFileCreationNote('');
  }

  handleUseOriginalFileCreationNote = () => {
    const {data: {origin_request: {FileCreationNote}}} = this.props;
    this.handleChangeFileCreationNote(FileCreationNote);
  }

  handleFileDeliveryNoteBlur = ({target: {value}}) => {
    this.handleChangeFileDeliveryNote(value);
  };

  handleDeleteFileDeliveryNote = () => {
    this.handleChangeFileDeliveryNote('');
  }

  handleUseOriginalFileDeliveryNote = () => {
    const {data: {origin_request: {FileDeliveryNote}}} = this.props;
    this.handleChangeFileDeliveryNote(FileDeliveryNote);
  }

  render() {
    const {data: {replacement: {general_note, file_creation_note, file_delivery_note, overage_request}, origin_request: {GeneralNote, FileCreationNote, FileDeliveryNote}}} = this.props;
    return (
      <StyledCsGeneralNotes className="step-body-wrapper">
        <Row>
          <Col md={10} xs={12}>
            <FormGroup>
              <ControlLabel>General Notes</ControlLabel>
              <FormControl
                type="text"
                componentClass="textarea"
                placeholder=""
                value={general_note}
                onChange={this.handleChangeOrBlur}
                onBlur={this.handleChangeOrBlur}
              />
            </FormGroup>
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                className="btn-in-modal"
                onClick={this.handleDelete}
                disabled={!general_note}
              >
                Clear
              </Button>
              {GeneralNote && general_note !== GeneralNote &&
                <Button
                  bsStyle="primary"
                  className="btn-in-modal"
                  onClick={this.handleUseOriginalValue}
                >
                  Use original value
                </Button>
              }
            </ButtonToolbar>
            <FormGroup>
              <ControlLabel>File Creation Notes</ControlLabel>
              <FormControl
                  name="file_creation_note"
                  type="text"
                  componentClass="textarea"
                  placeholder=""
                  value={file_creation_note}
                  onChange={this.handleTextareaBlur}
                  onBlur={this.handleTextareaBlur}
              />
            </FormGroup>
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                className="btn-in-modal"
                onClick={this.handleDeleteFileCreationNote}
                disabled={!file_creation_note}
              >
                Clear
              </Button>
              {FileCreationNote && file_creation_note !== FileCreationNote &&
                <Button
                  bsStyle="primary"
                  className="btn-in-modal"
                  onClick={this.handleUseOriginalFileCreationNote}
                >
                  Use original value
                </Button>
              }
            </ButtonToolbar>
            <FormGroup>
              <ControlLabel>File Delivery Notes</ControlLabel>
              <FormControl
                  name="file_delivery_note"
                  type="text"
                  componentClass="textarea"
                  placeholder=""
                  value={file_delivery_note}
                  onChange={this.handleFileDeliveryNoteBlur}
                  onBlur={this.handleFileDeliveryNoteBlur}
              />
            </FormGroup>
            <ButtonToolbar>
              <Button
                bsStyle="primary"
                className="btn-in-modal"
                onClick={this.handleDeleteFileDeliveryNote}
                disabled={!file_delivery_note}
              >
                Clear
              </Button>
              {FileDeliveryNote && file_delivery_note !== FileDeliveryNote &&
                <Button
                  bsStyle="primary"
                  className="btn-in-modal"
                  onClick={this.handleUseOriginalFileDeliveryNote}
                >
                  Use original value
                </Button>
              }
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Checkbox
              checked={overage_request || false}
              onChange={({target: {checked}}) => this.handleChangeOverageFlag(checked)}
            >
              Overage Request
            </Checkbox>
          </Col>
        </Row>
      </StyledCsGeneralNotes>
    );
  }
}

const StyledCsGeneralNotes = styled.div`
  .form-group {
    label {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
    }
    .form-control {
      height: 39px;
      min-height: 80px;
      max-width: 100%;
      min-width: 100%;
      resize: vertical;
      font-size: 13px;
      line-height: 14px;
    }
  }

  .btn {
    margin-bottom: 15px !important;
    padding: 7px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background-color: #33333f !important;
    opacity: 0.8 !important;
    border-radius: 100px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 13px !important;
    line-height: 16px !important;
    white-space: nowrap;
    &:not([disabled]):hover {
      opacity: 1 !important;
    }
  }

  @media (max-width: 767px) {
    .submit-replacement-modal & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

CsGeneralNotes.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CsGeneralNotes;
