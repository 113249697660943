import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class RecoverForm extends Component {
  render() {
    return (
      <div>
        <p>
          <label>Email</label>
          <span className="text-icon">
            <i className="fa fa-user"/>
          </span>
          <input
            type="email"
            pattern="[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,8}$"
            name="email"
            placeholder="email@domain.com"
            autoComplete="off"
            autoFocus={true}
            value={this.props.email}
            onChange={this.props.onChange}
            />
        </p>
        <p><input type="submit" name="" value="Reset Password" onClick={this.props.onRecover}/></p>
        <div className="forgot">
          <Link to="/login" onClick={this.props.onHideMessage}>sign in</Link>
        </div>
      </div>
    );
  }
}

RecoverForm.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRecover: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired
};

export default RecoverForm;
