import React from 'react';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';

export default () => {
  return (
    <TopStyled className="top">
      <Col sm={12} xs={12}>
        <p className="title">Analytics</p>
      </Col>
    </TopStyled>
  );
};

const TopStyled = styled(Row)`
  background-color: #fff;
  padding: 22px 0;
  .title {
    color: #282828;
    font-size: 15px;
    padding: 0 10px;
  }
`;
