import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {isInternalUser} from '../../../../../utils/general';
import ReorderProjectGroupsModal from "./ReorderProjectGroupsModal";
import EditProjectGroupStepsModal from "./EditProjectGroupStepsModal";
import TextEditableField from "../../../../RequestDetails/components/forms/TextEditableField";

function EstimatedQuote(props) {
  const {data} = props;
  const {
    requestCreator,
    preparedFor,
    projectTitle,
    revisionNum,
    quotationDate,
    expirationDate,
    paymentTerms,
    contentType,
    generalRuntime,
    formatListNew,
    totalCost,
    qcTypeStepsDataList,
    itemizedList
  } = data;
  const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const [showReorderProjectGroupsModal, setShowReorderProjectGroupsModal] = useState(false);
  const [showEditGroupStepsModal, setShowEditGroupStepsModal] = useState(false);

  const handleReorderProjectGroupsClick = () => {
    setShowReorderProjectGroupsModal(true);
  }

  const closeReorderProjectGroupsClick = () => {
    setShowReorderProjectGroupsModal(false);
  }

  const submitReorderProjectGroups = (sortedGroups) => {
    props.submitReorderProfileList(sortedGroups);
    setShowReorderProjectGroupsModal(false);
  }

  const handleEditGroupStepsClick = () => {
    setShowEditGroupStepsModal(true);
  }

  const closeEditGroupStepsClick = () => {
    setShowEditGroupStepsModal(false);
  }

  const submitEditGroupSteps = (editSteps, qcTypesHeaders) => {
    props.submitEditCostSteps(editSteps, qcTypesHeaders);
    setShowEditGroupStepsModal(false);
  }

  const handleExportStepsAsScvClick = () => {
    props.exportStepsAsCsv(itemizedList);
  }

  let sortableProfileItems = [];
  props.data2 && props.data2.qc_profile_id && props.data2.qc_profile_id.forEach(profile => {
    let sortableItem = {
      id: profile.profile_uuid,
      name: profile.profile_name + (profile.version_tag ? ' - ' + profile.version_tag : '')
    };
    if (profile.video_format) {
      let videoSortItems = [];
      for (const vf of profile.video_format) {
        videoSortItems.push({
          id: vf.profile_uuid,
          name: vf.additional_description
        });
      }
      sortableItem.video_formats = videoSortItems;
    }
    if (profile.captions) {
      let videoSortItems = [];
      for (const vf of profile.captions) {
        videoSortItems.push({
          id: vf.profile_uuid,
          name: vf.additional_description
        });
      }
      sortableItem.video_formats = videoSortItems;
    }
    sortableProfileItems.push(sortableItem);
  });

  let rowNum = 1;

  return (
    <StyledCont>
      {showReorderProjectGroupsModal && <ReorderProjectGroupsModal groups={sortableProfileItems} onSubmit={submitReorderProjectGroups} onClose={closeReorderProjectGroupsClick}/>}
      {showEditGroupStepsModal && <EditProjectGroupStepsModal qcTypeStepsDataList={qcTypeStepsDataList} itemizedList={itemizedList} productCodes={props.productCodes} onClose={closeEditGroupStepsClick} onSave={submitEditGroupSteps}/>}
      <div className="scope-of-services">
        <div>
          SCOPE OF SERVICES
        </div>
        <div>
          <div>
            {contentType} at {generalRuntime}
          </div>
          {formatListNew.map((arr, idx) => !!arr.formatCostsList.length && (
            <div key={idx}>
              {arr.formatCostsList.map(format => (
                <div key={`${idx}-${format}`}>
                  {format}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="sales-quotation">
        Sales Quotation
      </div>
      <div className="sales-quotation-fields">
        <strong>
          Prepared for:
        </strong>
        <div>
          {props.changeEstimatedQuoteField ? <TextEditableField
              name="preparedFor"
              value={preparedFor}
              onConfirm={(l, n, newValue, callback) => {
                props.changeEstimatedQuoteField(n, newValue);
                callback();
              }}
              hiddenHint
          /> : preparedFor}
        </div>
        <strong>
          Quotation Date:
        </strong>
        <div>
          {quotationDate}{revisionNum > 0 ? ` - R${revisionNum}` : ''}
        </div>
        <strong>
          Expiration Date:
        </strong>
        <div>
          {expirationDate}
        </div>
        <strong>
          Payment Terms:
        </strong>
        <div>
          {paymentTerms}
        </div>
      </div>
      <div className="table-block">
        <table className="table">
          <thead>
            <tr>
              <th className="acct-mgr" colSpan={2}>
                Resillion US Acct Mgr
              </th>
              <th className="project-title">
                Project Title
              </th>
              <th className="department">
                Department
              </th>
              <th className="runtime">
                Runtime
              </th>
              <th className="due-date">
                Due Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="acct-mgr" colSpan={2}>
                {requestCreator}
              </td>
              <td className="project-title">
                {projectTitle}
              </td>
              <td className="department">
                Mastering
              </td>
              <td className="runtime">
                {generalRuntime}
              </td>
              <td className="due-date">
                {quotationDate}
              </td>
            </tr>
            <tr className="no-bg">
              <td colSpan={6}>
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
        {props.submitReorderProfileList && <button onClick={handleReorderProjectGroupsClick}>Reorder project groups</button>}
        {props.submitEditCostSteps && <button onClick={handleEditGroupStepsClick}>Edit cost steps</button>}
        {props.submitEditCostSteps && <button onClick={handleExportStepsAsScvClick}>Export cost steps as CSV</button>}
        <table className="table">
          <thead>
            <tr>
              <th className="item-num">
                Item #
              </th>
              <th className="quantity">
                Quantity
              </th>
              <th className="description">
                Step Description
              </th>
              <th className="description">
                Stock Description
              </th>
              <th>
                Revision
              </th>
              {isInternalUser ? <th className="product-code">
                Product Code
              </th> : null}
              <th className="unit-price" colSpan={2}>
                Unit Price
              </th>
              <th className="line-total">
                Line Total
              </th>
            </tr>
          </thead>
          <tbody className="cost-items">
            {qcTypeStepsDataList.map(({qcType, qcTypeText, steps}, qcTypeIdx) => (
              <Fragment key={`f-${qcTypeIdx}`}>
                <tr>
                  <td className="format" colSpan={8}>
                    {qcTypeText}
                  </td>
                  <td className="line-total"/>
                </tr>
                {steps.map((costItem, cIdx) => (
                  <tr key={`f-${qcTypeIdx}-${cIdx}`}>
                    <td className="item-num">
                      {rowNum++}
                    </td>
                    <td className="quantity">
                      {costItem.quantity.toFixed(2)}
                    </td>
                    <td className="description">
                      {costItem.description}
                    </td>
                    <td className="description">
                      {costItem.stockDescription}
                    </td>
                    <td className="revision">
                      {costItem.revision}
                    </td>
                    {isInternalUser ? <td className="product-code">
                      {costItem.productCode}
                    </td> : null}
                    <td className="unit-price" colSpan={2}>
                      {currencyFormat.format(costItem.unitPrice)}
                    </td>
                    <td className="line-total">
                      {currencyFormat.format(costItem.lineTotal)}
                    </td>
                  </tr>
                ))}
              </Fragment>
            ))}
            <tr className="no-bg">
              <td colSpan={9}>
                &nbsp;
              </td>
            </tr>
            <tr className="no-bg">
              <td className="total" colSpan={8}>
                <i>Total:</i>
              </td>
              <td className="line-total">
                {currencyFormat.format(totalCost)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </StyledCont>
  );
}

const StyledCont = styled.div`
  color: #282828;
  font-size: 13px;
  font-weight: 400;
  .scope-of-services {
    font-weight: 700;
    > div {
      :first-child {
        color: #4f8abe;
        font-size: 14px;
      }
      :last-child {
        margin-top: 10px;
        > div {
          + div {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .sales-quotation {
    margin-top: 35px;
    margin-bottom: 15px;
    color: #4f8abe;
    font-size: 18px;
    + div {
      display: grid;
      grid-template-columns: max-content auto;
      row-gap: 15px;
      column-gap: 30px;
      align-items: end;
      strong {
        color: #5092c8;
      }
    }
  }
  .sales-quotation-fields {
    .form-group {
      margin-bottom: 0;
      .form-control {
        min-height: unset;
        height: unset;
        padding: 0;
      }
    }
  }
  .table-block {
    margin-top: 30px;
    max-width: 100%;
    overflow-x: auto;
    .table {
      margin: 0;
      min-width: 680px;
      border-collapse: collapse;
      th, td {
        padding: 1px;
        font-size: 12px;
        text-align: center;
        &.acct-mgr {
          width: 13.5%;
        }
        &.item-num {
          width: 6%;
        }
        &.quantity {
          width: 7.5%;
        }
        &.project-title {
          width: 50.8%;
        }
        &.description {
          width: 30.8%;
        }
        &.department {
          width: 9.7%;
        }
        &.runtime {
          width: 11.6%;
        }
        &.product-code {
          width: 20%;
        }
        &.unit-price {
          width: 11.3%;
        }
        &.due-date,
        &.line-total {
          width: 14.4%;
        }
      }
      th {
        padding-top: 3px;
        border: 2px solid #4f8abe;
        background-color: #4f8abe;
        color: #fff;
        white-space: nowrap;
      }
      td {
        border: 2px solid #f2f2f2;
        background-color: #fff;
        color: #404040;
      }
      tbody {
        tr {
          &.no-bg {
            td {
              border-color: #fff;
              background-color: #fff;
            }
          }
        }
        &.cost-items {
          tr {
            &:not(.no-bg):nth-child(odd) {
              td {
                background-color: #c5d9f1;
              }
            }
            td {
              padding-top: 5px;
              padding-bottom: 3px;
              &.description {
                text-align: left;
              }
              &.line-total {
                border-color: #fff !important;
                background-color: #ccdef0 !important;
                font-weight: 600;
                text-align: right;
              }
              &.total {
                text-align: right;
                i {
                  padding-right: 40px;
                }
              }
              &.format {
                font-size: 14px;
                font-weight: 600;
                text-align: left;
              }
            }
          }
        }
      }
    }
    button {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
`;

export default EstimatedQuote;
