import $ from 'jquery';
import config from '../../../config';
import qs from 'qs';

export const ANALYTICS_IS_LOADING = 'ANALYTICS_IS_LOADING';
export const ANALYTICS_TABLE_IS_LOADING = 'ANALYTICS_TABLE_IS_LOADING';
export const ANALYTICS_GET_REPORTS = 'ANALYTICS_GET_REPORTS';
export const ANALYTICS_GET_REPORT = 'ANALYTICS_GET_REPORT';
export const ANALYTICS_CHANGE_MENU_ITEM = 'ANALYTICS_CHANGE_MENU_ITEM';
export const ANALYTICS_GET_FILTERED_REPORT = 'ANALYTICS_GET_FILTERED_REPORT';

const isValidUrlFiltersReport = (reports, report) => {
  return reports.map(item => item.name).includes(report);
};

const isValidSortFilter = (currentReport, filter, value) => {
  if (filter === 'sort') {
    const headers = (currentReport && currentReport.columns) || [];
    return headers.map(item => item.name).includes(value);
  }
  return false;
};

const getValidFilter = (allowedFilters, filter, value)  => {
  const isExist = allowedFilters.map(item => item.name).includes(filter);

  if (isExist) {
    const existFilter = allowedFilters.find(item => item.name === filter) || {};

    if (['select', 'selectAlt', 'selectStr'].includes(existFilter.type)) {
      const isCreatable = existFilter.type === 'selectStr' && !!existFilter.isCreatable;
      const existFilterValues = existFilter.values ? existFilter.values.map(f => f.value) : [];
      return Array.isArray(value) ?
        value.filter(item => isCreatable || existFilterValues.includes(['selectStr'].includes(existFilter.type) ? item : +item)) :
        null;
    } else if (existFilter.type === 'date') {
      const res = {...value};
      const isObj = typeof res === 'object' && Object.keys(res).length ? Object.keys(res) : [];
      isObj.forEach(key => {
        const date = new Date(res[key]);
        if (!date.getTime()) {
          delete res[key];
        }
      });
      return Object.keys(res).length ? res : null;
    } else if (existFilter.type === 'period' || existFilter.type === 'periodAlt') {
      const intValue = parseInt(value, 10);
      return !isNaN(intValue) ? intValue : null;
    } else if (existFilter.type === 'string') {
      return value;
    } else if (existFilter.type === 'bool' && (typeof value === 'boolean' || ['true', 'false'].includes(value))) {
      return {isBool: true, value: String(value) === 'true'};
    }
  }
  return null;
};

const getValidFilters = (reports, allFilters, urlFilters, activeMenuItem) => {
  const currentReport = reports && reports.length &&
    reports.find(item => item.name === activeMenuItem);
  const res = {};

  urlFilters && Object.keys(urlFilters).forEach(key => {

    if (isValidSortFilter(currentReport, key, urlFilters[key])) {
      res[key] = urlFilters[key];
    } else {
      const validFilter = getValidFilter(allFilters, key, urlFilters[key]);
      if (validFilter) {
        res[key] = typeof validFilter === 'object' && validFilter.isBool ? validFilter.value : validFilter;
      }
    }
  });

  return res;
};

const getCurrentAllFilters = (reports, activeMenuItem, defaultValue = []) => {
  return ((reports || []).find(item => item.name === activeMenuItem) || {}).filters || defaultValue;
};

export const getReports = (history, urlFilters = {}) => dispatch => {
  dispatch({type: ANALYTICS_IS_LOADING, loading: true});

  let activeMenuItem = urlFilters && urlFilters.report;

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/analytics/reports`,
    data: {
      report: activeMenuItem
    }
  })
  .done(res => {
    if (res.reports && res.reports.length && (!activeMenuItem || !isValidUrlFiltersReport(res.reports, activeMenuItem))) {
      activeMenuItem = res.reports[0].name;
      urlFilters = {};
    }
    const filters = {...urlFilters};
    delete filters.report;

    const allFilters = getCurrentAllFilters(res.reports, activeMenuItem);
    const validUrlFilters = getValidFilters(res.reports, allFilters, filters, activeMenuItem);

    dispatch({type: ANALYTICS_GET_REPORTS, reports: res.reports, validUrlFilters});
    dispatch({type: ANALYTICS_IS_LOADING, loading: false});

    dispatch(changeMenuItem(activeMenuItem));
    dispatch(getReportTable(activeMenuItem, validUrlFilters, history, false, allFilters));

    return validUrlFilters;
  })
  .fail(() => {
    dispatch({type: ANALYTICS_IS_LOADING, loading: false});
  });
};

const getReportTableFilters = (activeMenuItem, filters, state) => {
  const {reports} = state.analytics;
  const allFilters = getCurrentAllFilters(reports, activeMenuItem);
  const resFilters = {};
  filters && Object.keys(filters).forEach(filterKey => {
    if (Array.isArray(filters[filterKey])) {
      resFilters[filterKey] = filters[filterKey].map(item => (typeof item.value === "undefined" || item.value === null) ? item : item.value);
    } else if (allFilters && allFilters.length) {

      const existFilter = allFilters.find(item => item.name === filterKey) || {};
      resFilters[filterKey] = existFilter && ['select', 'selectAlt', 'selectStr'].includes(existFilter.type) ?
        [filters[filterKey]] :
        filters[filterKey];

    } else {
      resFilters[filterKey] = filters[filterKey];
    }
  });

  allFilters.forEach(filter => {
    if (filter.hasOwnProperty('defaultValue') && !resFilters.hasOwnProperty(filter.name)) {
      resFilters[filter.name] = filter.defaultValue;
    }
  });

  return resFilters;
};

const getFiltersQuery = filters => {
  const resFilters = {...filters};
  Object.keys(resFilters).forEach(key => {
    const filter = resFilters[key];
    const isEmptyObj = typeof filter === 'object' && !Object.keys(filter).length;
    const isEmpty = (typeof filter !== 'boolean' && !filter) || (Array.isArray(filter) && !filter.length) || isEmptyObj;
    if (isEmpty) {
      delete resFilters[key];
    }
  });
  return resFilters;
};

const convertFiltersToArray = filters => {
  return Object.keys(filters).map(key => {
    const value = filters[key];
    if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && (value || typeof value === 'boolean'))) {
      return {
        name: key,
        value
      };
    }
    return null;
  }).filter(item => item !== null);
};

export const getReportTable = (activeMenuItem, filters, history, isLoadMore, reportFilters) => (dispatch, getState) => {
  dispatch({type: ANALYTICS_TABLE_IS_LOADING, loading: true, isLoadMore});

  const state = getState();
  const start = isLoadMore ? (state.analytics.filteredReport.data || []).length : 0;
  const queryFilters = getReportTableFilters(activeMenuItem, filters, state);
  const resFilters = {...queryFilters};
  delete resFilters.sort;
  const query = {report: activeMenuItem, ...getFiltersQuery(queryFilters)};

  history.replace({pathname: `/analytics`, search: `${qs.stringify(query)}`});

  if (reportFilters && reportFilters.length) {
    dispatch({type: ANALYTICS_TABLE_IS_LOADING, loading: false});
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/analytics/search?report=${activeMenuItem}`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(convertFiltersToArray(resFilters))
    }
  })
  .done(res => {
    let {data = [], total = 0} = res.report;
    if (isLoadMore) {
      data = [...state.analytics.filteredReport.data, ...data]
    }
    dispatch({type: ANALYTICS_GET_FILTERED_REPORT, filteredReport: {data, total}, activeMenuItem});
  })
  .fail(() => {
    if (isLoadMore) {
      dispatch({type: ANALYTICS_TABLE_IS_LOADING, loading: false});
    } else {
      dispatch({type: ANALYTICS_GET_FILTERED_REPORT, filteredReport: {data: [], total: 0}, activeMenuItem});
    }
  });
};

export const changeMenuItem = activeMenuItem => (dispatch, getState) => {
  dispatch({type: ANALYTICS_CHANGE_MENU_ITEM, activeMenuItem});

  const {reports} = getState().analytics;
  const allFilters = getCurrentAllFilters(reports, activeMenuItem, null);
  if (allFilters) {
    return new Promise((resolve, reject) => {
      resolve({report: {filters: allFilters}});
    });
  }

  dispatch({type: ANALYTICS_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/analytics/report?report=${activeMenuItem}`
  })
  .always(() => {
    dispatch({type: ANALYTICS_IS_LOADING, loading: false});
  })
  .done(res => {
    dispatch({type: ANALYTICS_GET_REPORT, report: res.report});
  });
};

export const getSignedTokenForDownloadFiles = (path, appliedFilters, activeMenuItem, exportType) => {
  const filters = encodeURIComponent(JSON.stringify(appliedFilters));
  const query = `report=${activeMenuItem}&filters=${filters}&export=${exportType}`;
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/util/download-file`,
    data: {
      path: `/services${path}`,
      query
    }
  })
  .done(res => {
    const token = encodeURIComponent(res.token);
    window.location.href = `${config.apiUrl}${path}?${query}&t=${token}`;
  })
  .fail(() => {});
};

export const upload = (activeMenuItem, filters, type) => (dispatch, getState) => {
  const path = `/v1/qc_on_demand/analytics/search`;
  const appliedFilters = getReportTableFilters(activeMenuItem, filters, getState());
  const resFilters = {...appliedFilters};
  delete resFilters.sort;
  getSignedTokenForDownloadFiles(path, convertFiltersToArray(resFilters), activeMenuItem, type);
};
