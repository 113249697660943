import React from 'react';

export default class HeaderHelpIcon extends React.PureComponent<React.HTMLAttributes<HTMLSpanElement>> {
  render() {
    return (
      <span {...this.props}>
        <svg
          data-aqc="true"
          aria-hidden="true"
          viewBox="0 0 20 20"
          className="">
            <g fill="none">
              <circle cx="10" cy="10" r="8.25" stroke="currentColor" strokeWidth="1.5"></circle>
              <path fill="currentColor" d="m10.5 10.478.07.747-.07-.747ZM7 8.25a.75.75 0 0 0 1.5 0H7ZM10 7c.502 0 .95.148 1.258.396.29.233.492.58.492 1.104h1.5c0-.977-.408-1.754-1.05-2.271-.623-.502-1.424-.729-2.2-.729V7Zm-.75 4v1h1.5v-1h-1.5Zm2.5-2.5c0 .494-.146.739-.307.884-.186.166-.506.3-1.014.347l.14 1.494c.675-.064 1.355-.26 1.874-.724.543-.486.807-1.174.807-2.001h-1.5ZM8.5 8.25c0-.414.154-.703.383-.898C9.124 7.146 9.503 7 10 7V5.5c-.78 0-1.527.23-2.09.71C7.332 6.704 7 7.415 7 8.25h1.5ZM10.75 11a.244.244 0 0 1-.066.169.182.182 0 0 1-.114.056l-.14-1.494c-.614.058-1.18.56-1.18 1.269h1.5Z"></path>
              <circle cx="10" cy="14" r="1" fill="currentColor"></circle>
            </g>
        </svg>
      </span>
    );
  }
}
