import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import {Tabs, Tab} from 'react-bootstrap';
import filterBtnImg from '../../../../../assets/images/filter-btn.svg';
import arrowLeftImg from '../../../../../assets/images/request/arrow-left.svg'
import dottedLineImg from '../../../../../assets/images/request/dotted-line.png';
import dottedLineBlackImg from '../../../../../assets/images/request/dotted-line-black.png';
import ellipseImg from '../../../../../assets/images/request/ellipse.svg';
import ellipseGreenImg from '../../../../../assets/images/request/ellipse-green.svg';
import ellipseBlueImg from '../../../../../assets/images/request/ellipse-blue.svg';
import expandImg from '../../../../../assets/images/expand.svg';
import Select, {components} from 'react-select-v2';

class TimeLine extends React.PureComponent {
  state = {
    showSliders: false
  };

  scrollInterval = null;

  timelineResizeObserver = new ResizeObserver(entries => {
    this.toggleSliders();
  })

  componentDidMount() {
    this.toggleSliders();

    // Observe resize timeline div
    this.timelineResizeObserver.observe(document.querySelector('.timeline'));

    setTimeout(() => {
      const navEl = document.getElementsByClassName('nav-tabs')[0];
      if (navEl && navEl.scrollLeft === 0 && navEl.children) {
        const activeTab = [].find.call(navEl.children, li => li.className.indexOf('active') >= 0);
        if (activeTab) {
          navEl.scrollLeft = activeTab.offsetLeft;
        }
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.scrollInterval);

    if (this.timelineResizeObserver) {
      this.timelineResizeObserver.disconnect();
    }
  }

  toggleSliders = () => {
    const navEl = document.getElementsByClassName('nav-tabs')[0];
    let navWidth = 0;
    if (navEl && navEl.children) {
      [].forEach.call(navEl.children, li => {
        navWidth += li.clientWidth;
      });
    }
    const timelineEl = document.getElementsByClassName('timeline')[0];
    timelineEl && this.setState({
      showSliders: timelineEl.clientWidth < navWidth
    });
  };

  arrowMouseDown = direction => {
    this.scrollInterval = setInterval(function() {
      if (direction === 'left') {
        document.getElementsByClassName('nav-tabs')[0].scrollLeft -= 3;
      } else if (direction === 'right') {
        document.getElementsByClassName('nav-tabs')[0].scrollLeft += 3;
      }
    }, 10);
  };

  arrowMouseUp = () => {
    clearInterval(this.scrollInterval);
  };

  showFilters = () => {
    this.setState({showMobileFiltersModal: true});
  };

  hideFilters = () => {
    this.setState({showMobileFiltersModal: false});
  };

  getVersions() {
    const {reportDetails} = this.props;
    return reportDetails && reportDetails.replacement;
  }

  getTimeline() {
    const {timeline} = this.props;
    return timeline;
  }

  changeTabFromSelect = event => {
    const {onSelect} = this.props;
    onSelect(event.value);
  };

  getMobileTimelineOptions = (timeline, requestId) => {
    if (!timeline || !timeline.length) {
      return [null];
    }

    const {reportDetails: details} = this.props;
    const formatVersions = (details || {}).versions || [];
    const isMultiFormats = formatVersions.length > 1;
    const showProfileInVersion = isMultiFormats && formatVersions.some(v => v.QcProfileID !== details.QcProfileID);

    const timelines = timeline.filter(line => line.RequestID && typeof line.RequestID === 'number');
    const requestIDs = timelines.map(line => line.RequestID);
    const mobileTimelineOptions =
      timelines.map(line => {
        const shortDate = moment(line.StatusUpdateDate).format('M/D');
        const fullDate = moment(line.StatusUpdateDate).format('YYYY-MM-DD HH:mm A');
        const title = isMultiFormats ? line.Title :
          <div>
            <span className="date" title={fullDate} style={{fontWeight: 'bold', paddingRight: '3px'}}>{shortDate}</span>
            <span className="name">{line.Title}</span>
          </div>;
        return {
          value: line.RequestID,
          label: title
        };
      });

    const selectedTimelineOption = mobileTimelineOptions.find(v => v.value === requestId);

    if (isMultiFormats) {
      return [formatVersions.map(formatVersion => {
        const {VersionName, QcProfile} = formatVersion;
        const options = requestIDs.includes(formatVersion.RequestID) ? mobileTimelineOptions : [{
          value: formatVersion.RequestID,
          label: 'Original Request'
        }];
        return {
          label: showProfileInVersion ? <><span style={{marginRight: '7px', opacity: 0.7}}>{QcProfile}</span>{VersionName}</> : VersionName,
          options
        };
      }), selectedTimelineOption];
    }

    return [mobileTimelineOptions, selectedTimelineOption];
  };

  renderMobileTimeline(options, selectedOption) {
    if (!options) {
      return null;
    }

    const {onSelect} = this.props;
    const DropdownIndicator = props => (
      <components.DropdownIndicator {...props}>
        <img src={expandImg} alt="" width="9"/>
      </components.DropdownIndicator>
    );
    return (
      <Select
        className="visible-xs"
        value={selectedOption}
        options={options}
        onChange={option => onSelect(option.value)}
        isSearchable={false}
        components={{DropdownIndicator, IndicatorSeparator: null}}
        styles={{
          control: (styles, {menuIsOpen}) => ({
            ...styles,
            backgroundColor: menuIsOpen ? '#fff' : '#F2F2F2',
            border: '0.5px solid #DADADA',
            borderRadius: menuIsOpen ? '3px 3px 0 0' : '3px',
            boxShadow: 'none',
            fontSize: '13px',
            minHeight: '30px'
          }),
          dropdownIndicator: styles => ({...styles, padding: '8px 12px', opacity: '0.6'}),
          menu: styles => ({
            ...styles,
            marginTop: 0,
            border: '0.5px solid #DADADA',
            borderTop: 'none',
            borderRadius: '0 0 3px 3px',
            boxShadow: 'none',
            fontSize: '13px'
          }),
          singleValue: (styles, {selectProps}) => (selectProps.menuIsOpen ? styles : {
            ...styles,
            color: 'rgba(0, 0, 0, 0.7)'
          }),
          valueContainer: styles => ({...styles, paddingLeft: '6px'}),
          groupHeading: styles => ({...styles, paddingLeft: '10px'}),
          option: styles => ({...styles, paddingLeft: '10px'}),
          container: styles => ({...styles, margin: '14px 20px', padding: 0})
        }}
      />
    );
  }

  render() {
    const {onSelect, requestId} = this.props;
    const {showSliders} = this.state;

    const timeline = this.getTimeline();
    const [mobileTimelineOptions, selectedMobileTimeline] = this.getMobileTimelineOptions(timeline, Number(requestId));
    const replacement = this.getVersions();
    const dueDate = replacement && replacement.length && replacement[replacement.length - 1].DueDate ?
      moment(replacement[replacement.length - 1].DueDate) : null;
    const shortDueDate = dueDate ? dueDate.format('M/D') : '';
    const fullDueDate = dueDate ? dueDate.format('YYYY-MM-DD HH:mm A') : '';
    const dueDateEl = <div><span className="date due-date" title={fullDueDate}>Due {shortDueDate}</span></div>;

    return (
     <Fragment>
      <ListViewTabStyled className="hidden-xs">
        <ListViewTabsStyled
          id="list-view-tabs-timeline"
          ref="tabs"
          className={`list-view-tabs-timeline ${showSliders ? 'show-sliders' : null}`}
          activeKey={Number(requestId)}
          onSelect={onSelect}
          animation={false}
        >
          {showSliders ?
            <div
              className="timeline-arrow timeline-arrow-left"
              onMouseDown={() => {this.arrowMouseDown('left')}}
              onMouseUp={this.arrowMouseUp}
              onMouseLeave={this.arrowMouseUp}
            /> : null
          }
          <Tab eventKey="first-row" title={''} className="not-tab" disabled/>
          {
            timeline && timeline.length ?
              timeline.map((line, i) => {
                const shortDate = moment(line.StatusUpdateDate).format('M/D');
                const fullDate = moment(line.StatusUpdateDate).format('YYYY-MM-DD HH:mm A');
                const title =
                  <div>
                    <span className="date" title={fullDate}>{shortDate}</span>
                    <span className="name">{line.Title}</span>
                  </div>;

                  const tabProps = {}
                  if (line.RequestID == null) {
                    tabProps.disabled = true;
                    tabProps.className = "not-tab";
                  }

                return (
                  <Tab key={i} eventKey={line.RequestID} title={title} {...tabProps}>
                  </Tab>
                );
              }) : null
          }
          <Tab eventKey="due-date" title={dueDateEl} className="not-tab" disabled/>
          <Tab eventKey="select" title="" className="not-tab"/>
          {showSliders ?
            <div
              className="timeline-arrow timeline-arrow-right"
              onMouseDown={() => {this.arrowMouseDown('right')}}
              onMouseUp={this.arrowMouseUp}
              onMouseLeave={this.arrowMouseUp}
            /> : null
          }
        </ListViewTabsStyled>
        <i className="visible-xs filter-btn" onClick={this.showFilters}/>
      </ListViewTabStyled>
      {this.renderMobileTimeline(mobileTimelineOptions, selectedMobileTimeline)}
     </Fragment>
    );
  }
}

const TABLE_HEADER_HEIGHT = '31px';

const ListViewTabStyled = styled.div`
  position: relative;
  .filters {
    margin-top: ${TABLE_HEADER_HEIGHT};
  }
  @media (max-width: 768px) {
    .filter-btn {
      background: url(${filterBtnImg}) no-repeat center center;
      position: absolute;
      top: 185px;
      right: 3px;
      height: 20px;
      width: 20px;
    }
  }

  .show-sliders {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const ListViewTabsStyled = styled(Tabs)`
  position: relative;
  .timeline-arrow {
    background: url(${arrowLeftImg}) no-repeat center center #000000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    top: 1px;
    height: 47px;
    width: 13px;
    z-index: 1;

    &:hover {
      opacity: 0.6;
    }
  }

  .timeline-arrow-left {
    left: 0;
  }

  .timeline-arrow-right {
    transform: scaleX(-1);
    right: 0;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  > .nav-tabs {
    align-items: flex-end;
    background: #f3f3f3;
    border-bottom: 0;
    display: flex;
    height: 50px;
    flex-wrap: nowrap;
    padding: 0;
    overflow-x: auto;
    -ms-overflow-style: none;  // Internet Explorer 10+
    scrollbar-width: none;  // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    > li {
      background: url(${dottedLineImg}) repeat-x center center;
      margin-bottom: 0;
      transition: none;
      &:hover {
        margin-bottom: 0;
      }

      &:first-of-type {
        a {
          border: 0;
        }
      }

      &:nth-last-of-type(2) {
        > a{
          background-image: url(${ellipseGreenImg});
          margin-right: 55px;
          .date {
            background: #f3f3f3;
            color: #6A8943;
            cursor: default;
            font-size: 8px;
            font-weight: normal;
            padding-left: 3px;
            width: 75px;
            top: 16px;
            left: 30px;
            z-index: 1;
          }
        }
      }

      &:last-child {
        background: transparent;
        border: transparent;
        display: none;
        > a {
          opacity: 1;
          padding: 0 5px 5px;
        }
        &:hover > a, &:focus > a {
          background: transparent;
          cursor: default;
          font-weight: normal;
          outline: none;
        }

        &.disabled > a,
        &.disabled:hover > a,
        &.disabled:focus > a {
          opacity: 0.5;
        }
      }

      > a {
        background: url(${ellipseBlueImg}) no-repeat center right 20px;
        border: 0;
        border-radius: 0;
        color: #29A4CB;
        font-size: 9px;
        height: 50px;
        line-height: 20px;
        padding: 12px 45px 12px 0;
        position: relative;
        transition: none;
        white-space: nowrap;

        > div {
          border-left: 1px solid black;
          height: 26px;
          padding: 12px 0 0 3px;
        }

        &:hover, &:focus {
          border: 0;
          outline: none;
        }
        .date {
          font-weight: bold;
          position: absolute;
          top: 7px;
          left: 3px;
        }
      }

      &:hover > a, &:focus > a {
        border-right: 0;
        outline: none;
      }

      &.active > a {
        background: url(${dottedLineBlackImg}) repeat-x center center #525252;
        color: #29A4CB;
        height: 50px;
        margin: 0 -1px 0 0;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 1;

        > div {
          height: 50px;
          padding: 24px 0 12px 3px;
        }

        .date {
          top: 7px;
        }
      }

      &.disabled > a {
        background: url(${ellipseImg}) no-repeat center right 20px;
        color: #282828;

        span {
          pointer-events: all;
          color: #282828;
          cursor: default;
        }
      }
    }
  }

  @media (max-width: 992px) {
    > .nav-tabs {
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    .timeline-arrow {
      display: none;
    }
    > .nav-tabs {
      background: none;
      border: none;
      flex-wrap: nowrap;
      margin: 0 -5px;
      padding: 0 4px 0 0;
      overflow-x: initial;
      > li {
        background: none;
        display: none;
        a > div {
          border: none;
        }

        .Select.has-value.Select--single > .Select-control,
        .Select.Select--single > .Select-control,
        .Select--multi > .Select-control, .Select-input {
          background: #F2F2F2;
          border-radius: 3px;
          height: 24px;
        }
        &:last-child {
          display: block;
          width: 100%;
          .Select.has-value.Select--single > .Select-control .Select-value-label,
          .Select.Select--single > .Select-control .Select-value-label,
          .Select--multi > .Select-control .Select-value-label {
            font-weight: bold;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
    &.list-view-tabs-timeline > .tab-content {
      padding-top: 0;
    }
  }
`;

TimeLine.propTypes = {
  requestId: PropTypes.any.isRequired,
  reportDetails: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    reportDetails: state.reportdetails.details,
    timeline: state.reportdetails.timeline
  };
}

export default connect(
  mapStateToProps
)(TimeLine);