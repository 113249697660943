/* eslint "complexity":"off" */
import {
  USER_SET_IS_LOGOUT
} from '../Login/actions';
import {
  QUOTE_PROJECT_CLEAR,
  QUOTE_PROJECT_SET_LOADING,
  QUOTE_PROJECT_RECEIVE,
  QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL,
} from './actions';

const initialState = {
  loaders: {
    loadingQuote: false,
    sendingEmail: false,
  },
  quoteData: null,
  estimatedQuote: null,
  clientInfo: null,
  dictionaries: {},
  isShowEmailProjectOnboardingFormModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case QUOTE_PROJECT_CLEAR: {
      return {...initialState};
    }

    case QUOTE_PROJECT_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case QUOTE_PROJECT_RECEIVE: {
      return {
        ...state,
        quoteData: action.quoteData || state.quoteData,
        estimatedQuote: action.estimatedQuote || state.estimatedQuote,
        clientInfo: action.clientInfo || state.clientInfo,
        dictionaries: action.dictionaries || state.dictionaries,
      };
    }

    case QUOTE_PROJECT_TOGGLE_EMAIL_PROJECT_ONBOARDING_FORM_MODAL: {
      return {
        ...state,
        isShowEmailProjectOnboardingFormModal: action.isShow || initialState.isShowEmailProjectOnboardingFormModal
      };
    }

    default:
      return state;
  }
}
