import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import BlockHeader from '../BlockHeader';
import ConfigModal from './ConfigModal';
import Request from './Request';

const LEFT_GAP_WIDTH = 24;
const MIN_COLUMN_WIDTH = 100;

class RequestsByStatus extends React.PureComponent {
  localStorageData = JSON.parse(localStorage.getItem('dashboardRequestsByStatus')) || {};

  state = {
    period: 2,
    columns: this.localStorageData.columns ? this.localStorageData.columns : {
      not_started: true,
      in_progress: true,
      completed: true
    },
    otherOptions: this.localStorageData.otherOptions ? this.localStorageData.otherOptions : {
      at_risk: true,
      overdue: true
    },
    showConfigModal: false
  };

  dropdownOptions = [
    {label: "This Year", value: 3},
    {label: "Past 60 days", value: 2},
    {label: "Past 30 days", value: 1}
  ];

  componentDidMount() {
    window.addEventListener('resize', this.updateComponent);
    const {filters} = this.props;
    this.setState({
      period: filters.period
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateComponent);
  }

  updateComponent = () => {
    this.forceUpdate()
  };

  saveToLocalStorage = () => {
    const {columns, otherOptions} = this.state;
    localStorage.setItem('dashboardRequestsByStatus', JSON.stringify({
      columns,
      otherOptions
    }));
  };

  onSelectChange = period => {
    this.setState({
      period: period.value
    });
    this.props.getData(period.value);
  };

  onToggleConfigModal = () => {
    this.setState({
      showConfigModal: !this.state.showConfigModal
    });
  };

  onColumnChange = name => {
    this.setState({
      columns: {
        ...this.state.columns,
        [name]: !this.state.columns[name]
      }
    }, this.saveToLocalStorage);
  };

  onOtherOptionChange = (name) => {
    this.setState({
      otherOptions: {
        ...this.state.otherOptions,
        [name]: !this.state.otherOptions[name]
      }
    });
    this.saveToLocalStorage();
  };

  getMeasure = () => {
    if (!this.refs.graph) {
      return 0;
    }
    let measure = 0;
    const {data} = this.props;

    // Calculate one Request width
    const graphWidth = this.refs.graph.offsetWidth - LEFT_GAP_WIDTH;
    const totalCount = data.reduce((sum, request) => {
      return sum + request['All'];
    }, 0);
    measure = totalCount > 0 ? graphWidth / totalCount : 0;

    //Recalculate one Request width if needed
    let newGraphWidth = graphWidth;
    let newTotalCount = 0;
    data.forEach(request => {
      if (measure * request['All'] >= MIN_COLUMN_WIDTH) {
        newTotalCount += request['All'];
      } else {
        newGraphWidth -= MIN_COLUMN_WIDTH;
      }
    });
    if (graphWidth !== newGraphWidth || newTotalCount !== totalCount) {
      measure = newTotalCount > 0 ? newGraphWidth / newTotalCount : 0;
    }

    return measure;
  };

  render() {
    const {data, statuses, loading, filters: {topFilters}} = this.props;
    const {period, showConfigModal, columns, otherOptions} = this.state;
    const measure = this.getMeasure();
    return (
      <StyledRequestsByStatus className="box">
        {loading ? <Loader/> : null}
        <BlockHeader
          title="Requests by Status"
          legend={true}
          dropDown={{
            value: period,
            options: this.dropdownOptions,
            onChange: this.onSelectChange
          }}
          configureBtn={{
            onClick: this.onToggleConfigModal
          }}
        />
        <ConfigModal
          show={showConfigModal}
          columns={columns}
          otherOptions={otherOptions}
          onColumnChange={this.onColumnChange}
          onOtherOptionChange={this.onOtherOptionChange}
        />
        <div className="graph" ref="graph">
          <div className="gap">
            <div className="progress-line"/>
          </div>
          {data.map((request, index) => {
            const statusId = request.Status.replace(' ', '_').toLowerCase();
            return columns[statusId] ? (
              <Request
                key={index}
                request={request}
                measure={measure}
                otherOptions={otherOptions}
                period={period}
                statuses={statuses}
                topFilters={topFilters}
              />
            ) : null;
          })}
        </div>
      </StyledRequestsByStatus>
    );
  }
}

const StyledRequestsByStatus = styled.div`
  min-height: 174px;
  position: relative;

  .graph {
    border-radius: 0 0 5px 5px;
    display: flex;
    height: 111px;

    > div {
      border-right: 1px solid #e2e2e2;
      height: 100%;
      &:last-of-type {
        border: 0;
        border-radius:  0 0 5px 0;
        .footer-legend {
          border-radius:  0 0 5px 0;
        }
      }
      &.gap {
        width: ${LEFT_GAP_WIDTH}px;
      }
      .progress-line {
        background-color: #f3f3f3;
        display: flex;
        height: 27px;
        width: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    margin-right: 25px !important;

    .title {
      width: 85px;
    }
  }
`;

RequestsByStatus.propTypes = {
  filters: PropTypes.object,
  data: PropTypes.array,
  statuses: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  getData: PropTypes.func.isRequired
};

export default RequestsByStatus;
