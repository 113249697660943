/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  REQUEST_DETAILS_CLEAR,
  REQUEST_DETAILS_SET_ACTIVE_TAB,
  REQUEST_DETAILS_CHANGE,
  REQUEST_DETAILS_UPDATE,
  REQUEST_DETAILS_CREATE_WO,
  REQUEST_DETAILS_PRECUT_WO,
  REQUEST_DETAILS_GET_RECEIVE,
  REQUEST_DETAILS_IS_LOADING,
  REQUEST_DETAILS_IS_SAVING,
  REQUEST_DETAILS_SOME_IS_LOADING,
  REQUEST_DETAILS_COST_IS_LOADING,
  REQUEST_DETAILS_COST_RECEIVE,
  REQUEST_DETAILS_SUB_STEPS_IS_LOADING,
  REQUEST_DETAILS_SUB_STEPS_RECEIVE,
  REQUEST_DETAILS_MARK_EPISODES_AS_SAVED,
  REQUEST_DETAILS_MARK_FORMATS_AS_SAVED,
  REQUEST_DETAILS_RESET_UNSAVED_VIEW,
  REQUEST_DETAILS_CANCELLED,
  REQUEST_DETAILS_UNCANCEL,
  REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING,
  REQUEST_DETAILS_MEMFIS_FILE_LIST_RECEIVE,
  REQUEST_DETAILS_VERSIONS_IS_LOADING,
  REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING,
  REQUEST_DETAILS_ATTACHMENT_ADD,
  REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING,
  REQUEST_DETAILS_ATTACHMENT_IS_DELETING,
  REQUEST_DETAILS_ATTACHMENT_DELETE,
  REQUEST_DETAILS_CAPTIONS_IS_UPLOADING,
  REQUEST_DETAILS_CAPTIONS_ADD,
  REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING,
  REQUEST_DETAILS_CAPTIONS_IS_DELETING,
  REQUEST_DETAILS_CAPTIONS_DELETE,
  REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING,
  REQUEST_DETAILS_CAPTIONS_FILTERS,
  REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST,
  REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING,
  REQUEST_DETAILS_PROXIES_IS_LOADING,
  REQUEST_DETAILS_PROXIES_DELETE,
  REQUEST_DETAILS_PROXIES_RECEIVE,
  REQUEST_DETAILS_PROXIES_CREATE,
  REQUEST_DETAILS_PROXY_IS_LOADING,
  REQUEST_DETAILS_PERMISSIONS_IS_LOADING,
  REQUEST_DETAILS_PERMISSIONS_RECEIVE,
  REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP,
  REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL,
  REQUEST_DETAILS_ATTACHMENTS_IS_LOADING,
  REQUEST_DETAILS_ATTACHMENTS_RECEIVE,
  REQUEST_DETAILS_CAPTIONS_IS_LOADING,
  REQUEST_DETAILS_CAPTIONS_RECEIVE,
  REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS,
  REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME,
  REQUEST_DETAILS_SEASON_REPLACEMENTS_IS_LOADING,
  REQUEST_DETAILS_SEASON_REPLACEMENTS_TOGGLE_POPUP,
  REQUEST_DETAILS_XYTECH_INFO_IS_LOADING,
  REQUEST_DETAILS_XYTECH_INFO_RECEIVE,
} from './actions';

const initialState = {
  activeTab: 1,
  loading: false,
  loadingSelectedVersion: false,
  saving: false,
  savingParam: null,
  detailsFilters: {},
  details: null,
  loadingVersions: false,
  loadingVersionsForProxies: false,
  loadingSome: false,
  loadingCost: false,
  costDetails: null,
  loadingSubSteps: false,
  loadingMemfisFileList: false,
  subSteps: [],
  memfisFileList: [],
  unsavedViews: {
    media: false,
    season: false
  },
  loadingAttachments: false,
  uploadingAttachment: false,
  downloadingAttachment: false,
  deletingAttachment: false,
  loadingCaptions: false,
  uploadingCaptions: false,
  downloadCaptionsInProgress: false,
  deleteCaptionsInProgress: false,
  loadingCaptionsFilters: false,

  loadingProjectWorkOrderList: false,
  projectWorkOrderList: [],
  deliveryCategoryList: [],

  loadingProxy: false,
  loadingProxies: false,
  usersWithNewProxies: [],

  loadingPermissions: false,

  isShowSeasonStatusPopup: false,

  modeWoStepsModal: null,
  editWorkOrderStepsModal: {
    loadingDictionariesForEditSteps: false,
    filters: {
      step_assignees: [],
      step_statuses: [],
      step_categories: [],
      product_codes: [],
      product_codes_for_profile: []
    }
  },

  editCompanyFieldName: null,

  isLoadingSeasonReplacements: false,
  seasonReplacementsPopupData: null,

  loadingXytechInfo: false,
  xytechInfo: []
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case REQUEST_DETAILS_CLEAR: {
      return {...initialState};
    }

    case REQUEST_DETAILS_SET_ACTIVE_TAB: {
      return {...state, activeTab: action.activeTab}
    }

    case REQUEST_DETAILS_CHANGE: {
      const {unsavedViews} = state;
      return {
        ...state,
        details: action.details,
        unsavedViews: (action.unsavedView ? {...unsavedViews, [action.unsavedView]: true} : unsavedViews)
      };
    }

    case REQUEST_DETAILS_UPDATE: {
      return {
        ...state,
        details: {...state.details, ...action.data}
      };
    }

    case REQUEST_DETAILS_IS_LOADING: {
      return {
        ...state,
        loading: action.loading,
        loadingSelectedVersion: action.loadingSelectedVersion || initialState.loadingSelectedVersion
      };
    }

    case REQUEST_DETAILS_GET_RECEIVE: {
      const initState = action.needToResetState ? initialState : state;
      return {
        ...initState,
        loading: false,
        details: action.details,
        detailsFilters: {...initialState.detailsFilters, ...action.filters}
      };
    }

    case REQUEST_DETAILS_IS_SAVING: {
      return {
        ...state,
        saving: action.saving,
        savingParam: action.savingParam || initialState.savingParam
      };
    }

    case REQUEST_DETAILS_CREATE_WO: {
      const details = {...state.details, WOID: action.id, AdditionalDescription: action.wo_description};
      if (!details.MemfisCompanyID && action.MemfisCompanyID) {
        details.MemfisCompanyID = action.MemfisCompanyID;
      }
      return {
        ...state,
        saving: false,
        details
      };
    }

    case REQUEST_DETAILS_PRECUT_WO: {
      const details = {...state.details};
      details.Episodes = action.episodes;
      details.TotalEpisodes = action.episodes.length;
      return {
        ...state,
        saving: false,
        details
      };
    }

    case REQUEST_DETAILS_CANCELLED: {
      const details = {...state.details};
      if (action.statusId) {
        details.RequestStatusID = action.statusId;
        details.RequestStatus = 'Cancelled';
        if (details.IsArtworkRequest) {
          details.AssetName = null;
        }
      } else {
        details.HasEpisodesToCancel = action.HasEpisodesToCancel;
      }
      ['RequestTitle', ...details.IsArtworkRequest ? [] : ['Title', 'TitleAlt', 'WoTitle']].forEach(k => {
        if (details[k]) {
          details[k] = `CANCELED - ${details[k]}`;
        }
      });
      return {
        ...state,
        saving: false,
        details
      };
    }

    case REQUEST_DETAILS_UNCANCEL: {
      const details = {...state.details, ...action.data};
      ['RequestTitle', 'Title', 'TitleAlt', 'WoTitle'].forEach(k => {
        if (details[k]) {
          details[k] = details[k].replace(/^CANCELED - /,'');
        }
      });
      return {
        ...state,
        saving: false,
        details
      };
    }

    case REQUEST_DETAILS_SOME_IS_LOADING: {
      return {
        ...state,
        loadingSome: action.loading
      };
    }

    case REQUEST_DETAILS_COST_IS_LOADING: {
      return {
        ...state,
        loadingCost: action.loading
      };
    }

    case REQUEST_DETAILS_COST_RECEIVE: {
      return {
        ...state,
        loadingCost: false,
        costDetails: action.data
      };
    }

    case REQUEST_DETAILS_MARK_EPISODES_AS_SAVED: {
      return {
        ...state,
        unsavedViews: {...state.unsavedViews, season: false}
      };
    }

    case REQUEST_DETAILS_MARK_FORMATS_AS_SAVED: {
      return {
        ...state,
        unsavedViews: {...state.unsavedViews, media: false}
      };
    }

    case REQUEST_DETAILS_RESET_UNSAVED_VIEW: {
      const {unsavedViews} = state;
      return {
        ...state,
        unsavedViews: (action.unsavedView ? {...unsavedViews, [action.unsavedView]: false} : unsavedViews)
      };
    }

    case REQUEST_DETAILS_SUB_STEPS_IS_LOADING: {
      return {
        ...state,
        loadingSubSteps: action.loading
      };
    }

    case REQUEST_DETAILS_SUB_STEPS_RECEIVE: {
      return {
        ...state,
        loadingSubSteps: false,
        subSteps: action.data || initialState.subSteps
      };
    }

    case REQUEST_DETAILS_MEMFIS_FILE_LIST_IS_LOADING: {
      return {
        ...state,
        loadingMemfisFileList: action.loading
      };
    }

    case REQUEST_DETAILS_MEMFIS_FILE_LIST_RECEIVE: {
      return {
        ...state,
        loadingMemfisFileList: false,
        memfisFileList: action.data || initialState.memfisFileList
      };
    }

    case REQUEST_DETAILS_VERSIONS_IS_LOADING: {
      return {
        ...state,
        loadingVersions: action.loading
      };
    }

    case REQUEST_DETAILS_ATTACHMENTS_IS_LOADING: {
      return {
        ...state,
        loadingAttachments: action.loading
      };
    }

    case REQUEST_DETAILS_ATTACHMENTS_RECEIVE: {
      return {
        ...state,
        loadingAttachments: false,
        details: {...state.details, Attachments: action.data},
        detailsFilters: {...state.detailsFilters, ...action.filters}
      };
    }

    case REQUEST_DETAILS_ATTACHMENT_IS_UPLOADING: {
      return {
        ...state,
        uploadingAttachment: action.loading
      };
    }

    case REQUEST_DETAILS_ATTACHMENT_ADD: {
      const attachments = [...state.details.Attachments || []];
      const index = attachments.findIndex(a => a.id === action.data.id);
      if (index === -1) {
        attachments.push(action.data);
      } else {
        attachments[index] = action.data;
      }
      return {
        ...state,
        uploadingAttachment: false,
        details: {...state.details, Attachments: attachments, ...action.request_statuses}
      };
    }

    case REQUEST_DETAILS_ATTACHMENT_IS_DOWNLOADING: {
      return {
        ...state,
        downloadingAttachment: action.loading
      };
    }

    case REQUEST_DETAILS_ATTACHMENT_IS_DELETING: {
      return {
        ...state,
        deletingAttachment: action.loading
      };
    }

    case REQUEST_DETAILS_ATTACHMENT_DELETE: {
      const attachments = [...state.details.Attachments || []];
      const index = attachments.findIndex(a => a.id === action.attachmentId);
      if (index !== -1) {
        attachments.splice(index, 1);
      }
      return {
        ...state,
        deletingAttachment: false,
        details: {...state.details, Attachments: attachments, ...action.request_statuses}
      };
    }

    case REQUEST_DETAILS_CAPTIONS_IS_LOADING: {
      return {
        ...state,
        loadingCaptions: action.loading
      };
    }

    case REQUEST_DETAILS_CAPTIONS_RECEIVE: {
      return {
        ...state,
        loadingCaptions: false,
        details: {...state.details, CaptionsList: action.data}
      };
    }

    case REQUEST_DETAILS_CAPTIONS_IS_UPLOADING: {
      return {
        ...state,
        uploadingCaptions: action.loading
      };
    }

    case REQUEST_DETAILS_CAPTIONS_ADD: {
      const captions = [...state.details.CaptionsList || []];
      const index = captions.findIndex(a => a.captions_id === action.data.captions_id);
      if (index === -1) {
        captions.push(action.data);
      } else {
        captions[index] = action.data;
      }
      return {
        ...state,
        uploadingCaptions: false,
        details: {...state.details, CaptionsList: captions}
      };
    }

    case REQUEST_DETAILS_CAPTIONS_IS_DOWNLOADING: {
      return {
        ...state,
        downloadCaptionsInProgress: action.loading
      };
    }

    case REQUEST_DETAILS_CAPTIONS_IS_DELETING: {
      return {
        ...state,
        deleteCaptionsInProgress: action.loading
      };
    }

    case REQUEST_DETAILS_CAPTIONS_DELETE: {
      const captions = [...state.details.CaptionsList || []];
      const index = captions.findIndex(a => a.captions_id === action.captionsId);
      if (index !== -1) {
        captions.splice(index, 1);
      }
      return {
        ...state,
        deleteCaptionsInProgress: false,
        details: {...state.details, CaptionsList: captions}
      };
    }

    case REQUEST_DETAILS_CAPTIONS_FILTERS_IS_LOADING: {
      return {
        ...state,
        loadingCaptionsFilters: action.loading
      };
    }

    case REQUEST_DETAILS_CAPTIONS_FILTERS: {
      return {
        ...state,
        loadingCaptionsFilters: false,
        detailsFilters: {...state.detailsFilters, ...action.data}
      };
    }

    case REQUEST_DETAILS_GET_PROJECT_WORK_ORDER_LIST: {
      return {
        ...state,
        loadingProjectWorkOrderList: action.loading,
        projectWorkOrderList: action.work_orders || state.projectWorkOrderList,
        deliveryCategoryList: action.delivery_categories || state.deliveryCategoryList
      };
    }

    case REQUEST_DETAILS_PROXIES_VERSIONS_IS_LOADING: {
      return {
        ...state,
        loadingVersionsForProxies: action.loading
      };
    }

    case REQUEST_DETAILS_PROXIES_IS_LOADING: {
      return {
        ...state,
        loadingProxies: action.loading
      };
    }

    case REQUEST_DETAILS_PROXIES_DELETE: {
      const watermarked_assets = [...state.details.watermarked_assets];
      const index = watermarked_assets.findIndex(a => a.WatermarkedAssetID === action.id);
      if (index !== -1) {
        watermarked_assets.splice(index, 1);
      }
      return {
        ...state,
        loadingProxies: false,
        details: {...state.details, watermarked_assets}
      };
    }

    case REQUEST_DETAILS_PROXIES_RECEIVE: {
      const {
        users_for_proxies,
        episodes_with_raw_proxy,
        episodes_requests,
        has_raw_proxy,
        latest_proxy
      } = action;
      const watermarked_assets = action.watermarked_assets || [];
      let usersWithNewProxies, detailsFilters;
      if (!action.withUsers) {
        usersWithNewProxies = state.usersWithNewProxies.filter(userId => !watermarked_assets.find(a => a.UserID === userId));
        detailsFilters = {...state.detailsFilters};
      } else {
        usersWithNewProxies = initialState.usersWithNewProxies;
        detailsFilters = {...state.detailsFilters, users_for_proxies}
      }
      return {
        ...state,
        loadingProxies: false,
        details: {
          ...state.details,
          watermarked_assets,
          episodes_with_raw_proxy,
          episodes_requests,
          has_raw_proxy,
          latest_proxy
        },
        usersWithNewProxies,
        detailsFilters
      };
    }

    case REQUEST_DETAILS_PROXIES_CREATE: {
      return {
        ...state,
        loadingProxies: false,
        usersWithNewProxies: [...state.usersWithNewProxies, action.userId]
      };
    }

    case REQUEST_DETAILS_PROXY_IS_LOADING: {
      return {
        ...state,
        loadingProxy: action.loading
      };
    }

    case REQUEST_DETAILS_PERMISSIONS_IS_LOADING: {
      return {
        ...state,
        loadingPermissions: action.loading
      };
    }

    case REQUEST_DETAILS_PERMISSIONS_RECEIVE: {
      return {
        ...state,
        loadingPermissions: false,
        details: {...state.details, UserPermissions: action.data}
      };
    }

    case REQUEST_DETAILS_TOGGLE_SEASON_STATUS_POPUP: {
      return {
        ...state,
        isShowSeasonStatusPopup: action.isShow || initialState.isShowSeasonStatusPopup
      };
    }

    case REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL: {
      return {
        ...state,
        modeWoStepsModal: action.mode || initialState.modeWoStepsModal
      };
    }

    case REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS: {
      return {
        ...state,
        editWorkOrderStepsModal: {
          ...state.editWorkOrderStepsModal,
          loadingDictionariesForEditSteps: action.loading,
          filters: action.filters || state.editWorkOrderStepsModal.filters
        }
      };
    }

    case REQUEST_DETAILS_EDIT_COMPANY_FIELD_NAME: {
      return {
        ...state,
        editCompanyFieldName: action.fieldName || initialState.editCompanyFieldName
      };
    }

    case REQUEST_DETAILS_SEASON_REPLACEMENTS_IS_LOADING: {
      return {
        ...state,
        isLoadingSeasonReplacements: action.loading || initialState.isLoadingSeasonReplacements
      };
    }

    case REQUEST_DETAILS_SEASON_REPLACEMENTS_TOGGLE_POPUP: {
      return {
        ...state,
        seasonReplacementsPopupData: action.data || initialState.seasonReplacementsPopupData
      };
    }

    case REQUEST_DETAILS_XYTECH_INFO_IS_LOADING: {
      return {
        ...state,
        loadingXytechInfo: action.loading
      };
    }

    case REQUEST_DETAILS_XYTECH_INFO_RECEIVE: {
      return {
        ...state,
        loadingXytechInfo: false,
        xytechInfo: action.data
      };
    }

    default:
      return state;
  }
}
