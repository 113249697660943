import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Row, Col, Button} from 'react-bootstrap';
import Select from 'react-select';
import {
  FiltersStyled, FilterHeaderStyled, FilterBodyStyled,
  FilterBottomStyled, IconStyled, FilterSelectStyled
} from './FiltersStyled';
import Loader from '../../../components/Loader';
import Filter from './Filter';
import {HEADER_HEIGHT, TOP_HEIGHT, TOP_PADDING_1, TOP_PADDING_2} from './Table';

class Filters extends Component {
  state = {
    open: true
  };

  setHeight = () => {
    try {
      const filtersHeight = document.getElementById("filters").parentElement.getBoundingClientRect().height;
      document.getElementById("AnalyticsTable").style.maxHeight = `calc(100vh - ${HEADER_HEIGHT} - ${TOP_HEIGHT} - ${filtersHeight}px - (2 * ${TOP_PADDING_1}) - (2 * ${TOP_PADDING_2}))`;
    } catch {}
  };

  componentDidUpdate(prevProps) {
    const {filters, activeMenuItemName} = this.props;
    const isChangedActiveMenuItem = activeMenuItemName !== prevProps.activeMenuItemName;
    if (isChangedActiveMenuItem && !this.state.open) {
      this.setState({open: true});
    }
    if (isChangedActiveMenuItem ||
        JSON.stringify(filters) !== JSON.stringify(prevProps.filters)) {
      this.setHeight();
    }
  }

  toggleFiltersOpen = () => {
    setTimeout(this.setHeight, 350);
    this.setState({
      open: !this.state.open
    });
  };

  getSortOptions() {
    const {headers} = this.props;
    return headers && headers.length && headers.map(header => {
      return {
        label: header.label,
        value: header.name
      }
    });
  }

  onSortChange = event => {
    const {onChange} = this.props;
    onChange('sort', event.value);
  };

  resetFilters = () => {
    const {resetFilters} = this.props;
    resetFilters();
  };

  applyFilters = () => {
    const {applyFilters} = this.props;
    applyFilters();
  };

  render() {
    const {open} = this.state;
    const {filters, loading, upload, onChange, usedFilters, activeMenuItemName} = this.props;
    const sortOptions = []; // this.getSortOptions();

    return (
      <FiltersStyled>
        {loading ? <Loader/> : null}
        <FilterHeaderStyled
          isHiddenReportOptions={filters && !filters.length && !loading}
        >
          <span className="title pull-left">{activeMenuItemName}</span>
          <div className="pull-right">
            <ul className="menu">
              <li
                className="report-option"
                onClick={this.toggleFiltersOpen}
                aria-controls="filters"
                aria-expanded={open}
              >
                <button className="btn-link"><IconStyled setting/><span className="hidden-xs">Report Options</span></button>
              </li>
              <li className="hidden-xs">
                <button className="btn-link" onClick={upload}><IconStyled upload/><span>Export</span></button>
              </li>
            </ul>
          </div>
        </FilterHeaderStyled>
        <Collapse in={open}>
          <div id="filters" className="clearfix">
            <FilterBodyStyled>
              <Col md={7}>
                <div className="block">
                  {filters && filters.length ? (
                    <Fragment>
                      <div className="title"><IconStyled filt/><span>Filter</span></div>
                      <div className="filters-block">
                        {filters.map((filter, index) => {
                          return (
                            <Filter
                              key={index}
                              filter={filter}
                              value={usedFilters[filter.name] || (filter.type !== 'bool' ? '' : usedFilters[filter.name])}
                              onChange={onChange}
                            />
                          )
                        })}
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </Col>
              <Col md={4}>
                <div className="block">
                  {sortOptions.length ? (
                    <Fragment>
                      <div className="title"><IconStyled sort/><span>Sort</span></div>
                      <div className="filters-block">
                        <Row>
                          <Col md={9}>
                            <FilterSelectStyled>
                              <Select
                                value={usedFilters.sort || ''}
                                options={sortOptions}
                                onChange={this.onSortChange}
                              />
                            </FilterSelectStyled>
                          </Col>
                        </Row>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </Col>
            </FilterBodyStyled>
            <FilterBottomStyled>
              <div>
                <Button className="grey" onClick={this.applyFilters}>Apply</Button>
                <Button className="white" onClick={this.resetFilters}>Reset to Default</Button>
              </div>
            </FilterBottomStyled>
          </div>
        </Collapse>
      </FiltersStyled>
    );
  }
}

Filters.propTypes = {
  headers: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  applyFilters: PropTypes.func.isRequired,
  sortTable: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  usedFilters: PropTypes.object.isRequired,
  activeMenuItemName: PropTypes.string.isRequired
};

export default Filters;
