import React from 'react';
import {Row, Col, OverlayTrigger, Popover, ControlLabel} from 'react-bootstrap';
import SelectAdvanced from '../../../../../components/forms/SelectAdvanced';
import {getHiddenWizardFields, buildDefaultAudioFormat, buildDefaultVideoFormat, formatDateTz} from '../../../../../utils/general';

const formatApprovedDate = approvedOn => {
  return formatDateTz(approvedOn, '', 'll[\r\n]hh:mm A z');
}

function QcProfiles(props) {
  const {
    data,
    distributorQcProfiles,
    qcProfiles,
    qcTypes,
    onChange
  } = props;

  const handleFieldValueChanged = (name, value) => {
    value = Number(value);
    onChange(name, value);
    if (name === 'qc_type_id') {
      const qcType = qcTypes.find(t => t.value === value);
      const hiddenFields = getHiddenWizardFields(qcProfiles, data.qc_profile_id);
      onChange('video_format', qcType.is_video ? [buildDefaultVideoFormat(hiddenFields, qcType)] : null);
      onChange('audio_format', qcType.is_audio && !qcType.is_video ? buildDefaultAudioFormat(hiddenFields) : null);
      onChange('caption_id', null);
      onChange('version_tag', null);
      onChange('additional_description', null);
      if (qcType.is_video) {
        ['select_files_type_id', 'selectedFiles', 'uploadedFiles'].forEach(k => {
          onChange(k, undefined);
        });
      }
    }
  };

  const qcProfileList = distributorQcProfiles.length > 0 ? distributorQcProfiles : qcProfiles;
  const profileOptions = qcProfileList && qcProfileList.map(profile => {
    const option = {};
    option.value = profile.value;
    option.profile_name = profile.name;
    option.name = profile.name;
    if (profile.approved_on) {
      const approvedOnDate = formatApprovedDate(profile.approved_on);
      option.approved_on = approvedOnDate;
      option.name = profile.name + ' (Approved ' + approvedOnDate + ')';
    }
    return option;
  });
  const selectedOption = profileOptions && profileOptions.find(p => p.value === data.qc_profile_id);

  const renderQcTypeItem = item => {
    return (
      <Col md={6} xs={12}>
        <div
          className={`qc-types-item${data.qc_type_id === item.value ? ' qc-type-selected' : ''}`}
          onClick={() => handleFieldValueChanged('qc_type_id', item.value)}
          >
          <span className="icon-circle-check"/><span className="qc-type-name">{item.name}</span>
          <div className="qc-type-note">
            {item.description}
          </div>
        </div>
      </Col>
    );
  };

  const useDefaultDistributorProfile = distributorQcProfiles.length === 1;
  let defaultDistributorProfile = null;

  if (useDefaultDistributorProfile) {
    defaultDistributorProfile = distributorQcProfiles[0].name;
    if (distributorQcProfiles[0].approved_on) {
      const approvedOnDate = formatApprovedDate(distributorQcProfiles[0].approved_on);
      defaultDistributorProfile += ' (Approved ' + approvedOnDate + ')';
    }
  }

  const qcProfileOptionRenderer = (option) => {
    if (option.approved_on) {
      return (
        <>
          <span>{option.profile_name}</span>
          <div className="approved">(Approved {option.approved_on})</div>
        </>
      );
    }

    return option.name;
  };

  return (
    <Row>
      <Col className="qc-profiles" md={3} xs={12}>
        {
          useDefaultDistributorProfile ? (
            <div className="qc-profile-default">
              <Row className="qc-profiles-and-types-label">
                <Col md={12} xs={12}>
                  <ControlLabel>QC Profile</ControlLabel>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  { defaultDistributorProfile }
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12}>
                  Using the default QC profile available for this distributor.
                </Col>
              </Row>
            </div>
          ) :
            <div className="qc-profile-select">
              <SelectAdvanced
                label="QC Profile"
                labelSeparator=""
                name="qc_profile_id"
                options={profileOptions}
                value={data.qc_profile_id}
                placeholder=""
                onSelect={handleFieldValueChanged}
                title={selectedOption && selectedOption.name}
                optionRenderer={qcProfileOptionRenderer}
                />
              {
                data.qc_profile_id ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Popover id="popover-what-is-this-qc-profile" title="What is this?">A QC Profile allows you to specify the level of QC you desire for a given project.
                    You can edit and create QC profiles in your preferences section.  For more information, please contact sales@us.resillion.com</Popover>}
                    >
                    <span className="overlay-what-is-this">what is this?</span>
                  </OverlayTrigger>
                ) : null
              }
            </div>
        }
      </Col>
      <Col className="qc-types" md={9} xs={12}>
        <Row className="qc-profiles-and-types-label">
          <Col md={12} xs={12}>
            <ControlLabel>QC Type</ControlLabel>
          </Col>
        </Row>
        <div className="qc-types-items">
          {qcTypes.map((t, i) => (i % 2) === 0 ? (
            <div key={`qc-type-items-${i}`}>
              <Row>
                {renderQcTypeItem(t)}
                {(i + 1) < qcTypes.length ? renderQcTypeItem(qcTypes[i + 1]) : null}
              </Row>
              <Row>
                <Col md={6} xs={12}><div className="qc-type-line"/></Col>
                {(i + 1) < qcTypes.length ? <Col md={6} xs={12}><div className="qc-type-line"/></Col> : null}
              </Row>
            </div>
          ) : null)}
        </div>
      </Col>
    </Row>
  );
}

export default QcProfiles;
