import styled from 'styled-components';
import {
  StyledBaseCloseModal
} from '../../../../styled/ModalStyled';

export const StyledCloseModal = styled(StyledBaseCloseModal)`
  position: absolute;
  top: 49px;
  right: 43px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledModalHeader = styled.div`
  padding: 47px 70px 23px 40px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  color: #282828;

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

export const StyledModalBody = styled.div`
  padding: 30px 57px 20px;
  position: fixed;
  top: 89px;
  bottom: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  &:not(.edit-record-modal-body) {
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
  }
  &.edit-record-modal-body {
    @media (min-width: 768px) {
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
    }
  }

  .btn-toolbar {
    margin-top: 30px;
    margin-left: -1px;
    .btn {
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #FFFFFF;
      background: #33333F;
      opacity: 0.8;
      padding: 11px 26px 12px;
      border-radius: 100px;
      border: none;
      outline: none;
      box-shadow: none;
      &.btn-cancel {
        margin-left: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background: none;
        color: #282828;
        opacity: 0.8;
        border: #ccc 1px solid !important;

        &:hover {
          background: #f5f5f5 !important;
        }
      }
      &:not([disabled]):hover {
        opacity: 1;
      }
      &[disabled] {
        color: #c8c8c8;
        opacity: 0.65;
      }
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (min-width: 768px) {
    label {
      color: #282828;
    }
  }
`;
