import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Overlay, Popover} from 'react-bootstrap';
import moreInfoImg from '../../../../../assets/images/requests/more-info.svg';
import moreInfoActiveImg from '../../../../../assets/images/requests/more-info-active.svg';
import {isOperatorUser, buildReportLink} from '../../../../utils/general';

export class TitleCell extends React.PureComponent {
  state = {
    showOverlay: false,
  };

  constructor(props) {
    super(props);

    this.popoverFields = [{
      title: 'Due',
      field: 'request_sla'
    }, {
      title: 'Requested By',
      field: 'RequestedBy'
    }];

    if (isOperatorUser()) {
      this.popoverFields.push({
        title: 'WO Memfis',
        field: 'WOMemfis'
      }, {
        title: 'DW QC Status',
        field: 'DwQcStatus'
      });
    }
  }

  handleClickMoreInfo = ({target}) => {
    this.setState(s => ({targetOverlay: target, showOverlay: !s.showOverlay}));
  };

  renderPopover() {
    const {row} = this.props;

    return (
      <Popover id={`popover-more-info-${row.RequestID}`} className="request-more-info-popover">
        <dl>
          {
            this.popoverFields.map((item, index) => (
              <Fragment key={`${row.RequestID}-${index}`}>
                <dt>
                  {item.title}
                </dt>
                <dd>
                  {row[item.field] || 'N/A'}
                </dd>
              </Fragment>
            ))
          }
        </dl>
      </Popover>
    );
  }

  render() {
    const {showOverlay, targetOverlay} = this.state;
    const {value, className, row} = this.props;
    const moreInfoPopover = this.renderPopover();

    return (
      <StyledCell className={className}>
        <div ref={r => {this.cellRef = r;}}>
          <Link title="Open Report" to={buildReportLink(row)}>{value}</Link>
          <div className={`more-info-btn${showOverlay ? ' active' : ''}`} onClick={this.handleClickMoreInfo}/>
          <Overlay
            rootClose
            placement="left"
            container={this.cellRef}
            show={showOverlay}
            target={targetOverlay}
            onHide={() => this.setState({showOverlay: false})}
            >
            {moreInfoPopover}
        </Overlay>
        </div>
      </StyledCell>
    );
  }
}

const StyledCell = styled.td`
  @media (max-width: 767px) {
    position: relative;
    padding-top: 16px !important;
    padding-right: 10px !important;
    padding-bottom: 8px !important;

    a {
      font-size: 13px;
      line-height: 14px;
    }

    .more-info-btn {
      background: url(${moreInfoImg}) no-repeat;
      background-position: 0 3px;
      background-position: 8px 3px;
      height: 21px;
      width: 19px;
      position: absolute;
      top: 13px;
      right: -13px;
      &.active {
        background-image: url(${moreInfoActiveImg});
      }
    }

    .request-more-info-popover {
      background: #FFFFFF;
      border: 0.5px solid #E0E0E0;
      box-sizing: border-box;
      box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.13);
      border-radius: 3px;
      top: 15px !important;
      right: 0 !important;
      left: unset !important;
      min-width: 187px;
      z-index: 1;

      .arrow {
        display: none;
      }

      .popover-content {
        padding: 8px 0 6px 18px;
      }

      dl {
        margin: 0;

        dt, dd {
          font-family: 'Roboto',sans-serif;
          font-size: 13px;
          line-height: 16px !important;
          color: #282828;
        }
        dt {
          padding-top: 10px;
          padding-bottom: 3px;
        }
        dd {
          padding-bottom: 10px;
          &:not(:last-of-type) {
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
`;

TitleCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  className: PropTypes.string
};

export default TitleCell;