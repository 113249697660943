import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import config from '../../../../config';

export const buildPasswordInstruction = () => (
  <>
    Password must be at least {config.passwordMinLength} characters and contain ALL of the following:
    <span
      style={{
        paddingLeft: '24px',
        paddingBottom: '10px',
        display: 'block'
      }}
    >
      {[
        'One upper-case letter',
        'One lower-case letter',
        'One Number (0-9)',
        <>One of the following special characters <strong>!@#$%&*</strong></>
      ].map((t, i) => (
        <span
          key={i}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            columnGap: '4px'
          }}
        >
          <i
            className="fa-solid fa-circle-small"
            style={{
              fontSize: '4px',
              width: '12px',
              textAlign: 'center'
            }}
          />
          {t}
        </span>
      ))}
    </span>
    *Password cannot contain or match username (email)
  </>
);

class ResetForm extends Component {
  render() {
    const {isCreatingPassword} = this.props;
    return (
      <div>
        <p>
          <label>Password</label>
          <span className="text-icon">
            <i className="fa fa-lock"/>
          </span>
          <input
            type="password"
            name="forgotPassword"
            placeholder=""
            defaultValue=""
            autoComplete="off"
            onChange={this.props.onChange}
            />
        </p>
        <p>
          <label>Confirm Password</label>
          <span className="text-icon">
            <i className="fa fa-lock"/>
          </span>
          <input
            type="password"
            name="forgotConfirm"
            placeholder=""
            autoComplete="off"
            onChange={this.props.onChange}
            />
        </p>
        <p>
          <input
            type="submit"
            name=""
            value={`${isCreatingPassword ? 'Create' : 'Update'} password`}
            onClick={this.props.onReset}
          />
        </p>
        <div className="forgot">
          <Link to="/login" onClick={this.props.onHideMessage}>sign in</Link>
        </div>
        <br/>
        <div className="pwd-instructions">
          {buildPasswordInstruction()}
        </div>
      </div>
    );
  }
}

ResetForm.propTypes = {
  isCreatingPassword: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired
};

export default ResetForm;
