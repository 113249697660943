import React, {useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {setActiveTab, updateEditDateModal, updateMilestoneDate, lockRequest} from '../actions';
import Tabs, {getTabTitle} from '../../../components/Tabs';
import {Tab, Row, Col} from 'react-bootstrap';
import Filters from './Filters';
import EpisodesTable from './episodes/Table';
import SeasonTitle from './season/Title';
import SeasonTable from '../../../components/scheduleTable/Table';
import episodesImg from '../../../../assets/images/season/episodes.svg';
import episodesActiveImg from '../../../../assets/images/season/episodes-active.svg';
import seasonImg from '../../../../assets/images/season/season.svg';
import seasonActiveImg from '../../../../assets/images/season/season-active.svg';
import {isUserCanManage} from '../../../utils/general';

function CategoryTabs(props) {
  const {dispatch, seasonDetails, onChangeSeason} = props;
  const {activeTab, details, selectedSeasonId, editDateModal} = seasonDetails;
  const [showScheduledDates, setShowScheduledDates] = useState(false);

  const handleChangeActiveTab = key => {
    dispatch(setActiveTab(key));
  }

  const episodesTabTitle = getTabTitle("Episodes");
  const seasonScheduleTabTitle = getTabTitle("Season Schedule");

  return (
    <StyledTabs
      id="tabs"
      activeKey={activeTab}
      onSelect={handleChangeActiveTab}
      animation={false}
      >
      <Tab eventKey={1} title={episodesTabTitle} className="episodes-view">
        <Row>
          <Col xs={12} md={2}>
            <Filters
              seasons={details.seasons}
              selectedSeasonId={selectedSeasonId}
              onChangeFilter={onChangeSeason}
            />
          </Col>
          <Col xs={12} md={10}>
            <div className="season-details-right">
              <EpisodesTable
                data={details.episodes || []}
                canManage={isUserCanManage(details.user_permission_type)}
              />
            </div>
          </Col>
        </Row>
      </Tab>
      {details.scheduling_headers && details.scheduling_headers.length > 0 ?
        <Tab eventKey={2} title={seasonScheduleTabTitle} className="season-view">
          <Row>
            <Col xs={12} md={2}>
              <Filters
                seasons={details.seasons}
                selectedSeasonId={selectedSeasonId}
                onChangeFilter={onChangeSeason}
              />
            </Col>
            <Col xs={12} md={10}>
              <div className="season-details-right">
                <SeasonTitle
                  showScheduledDates={showScheduledDates}
                  onChangeShow={(name, value) => {setShowScheduledDates(value)}}
                />
                <SeasonTable
                  headers={details.scheduling_headers}
                  rows={details.episode_project_list}
                  rowTitle={{
                    name: 'Title',
                    id: 'Title'
                  }}
                  editDateModal={editDateModal}
                  showScheduledDates={showScheduledDates}
                  showCalendarLink={true}
                  onUpdateModal={updateEditDateModal}
                  onUpdateDate={updateMilestoneDate}
                  onLockRow={lockRequest}
                />
              </div>
            </Col>
          </Row>
        </Tab> : null
      }
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)`
  margin-top: 10px;
  @media (max-width: 767px) {
    margin: 0;
    padding-top: 98px;
    background-color: #f3f3f3;
  }
  > .nav-tabs {
    @media (max-width: 767px) {
      margin-left: 25px;
    }
    > li {
      margin-bottom: 0;
      a {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        color: #bebebe;
        margin-right: 0;
        @media (max-width: 767px) {
          border-bottom: none;
        }
        &#tabs-tab-1 .tab-title {
          background-image: url(${episodesImg});
          background-position: left 9px center;
        }
        &#tabs-tab-2 .tab-title {
          background-image: url(${seasonImg});
          background-position: left 11px center;
        }
        .tab-title {
          padding: 10px 7px 10px 27px;
          @media (max-width: 767px) {
            background-color: #EAEAEA;
          }
          .text {
            display: inline;
            @media (max-width: 767px) {
              font-size: 11px;
              line-height: 12px;
            }
          }
        }
      }
      &.active {
        a {
          background-color: #fff;
          box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
          color: #282828;
          &#tabs-tab-1 .tab-title {
            background-image: url(${episodesActiveImg});
          }
          &#tabs-tab-2 .tab-title {
            background-image: url(${seasonActiveImg});
          }
          @media (max-width: 767px) {
            .tab-title {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .tab-content {
    border-top: 1px solid #ececec;
    padding-top: 0;
    @media (max-width: 767px) {
      background-color: #fff;
      border-top: none;
    }
  }
  .season-details-right {
    border-left: 1px solid #ededed;
    min-height: calc(100vh - 140px);
    position: relative;
    @media (max-width: 767px) {
      border-left: none;
    }
  }
`;

export default connect()(CategoryTabs);
