import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {emptyFunction} from '../../utils/general';
import {toggleShowSidebar} from '../actions';
import MobileSidebarHeader from './MobileSidebarHeader';
import Breadcrumbs from './Breadcrumbs';
import noDataImg from '../../../assets/images/sidebar/no-data.svg';

class Sidebar extends Component {
  closeSidebar = () => {
    const {dispatch} = this.props;
    dispatch(toggleShowSidebar(false));
  };

  render() {
    const {breadcrumbsData, children, className, captionsClick} = this.props;
    return (
      <Fragment>
        <StyledSidebar className={className}>
          <MobileSidebarHeader closeSidebar={this.closeSidebar}/>
          <Breadcrumbs
            data={breadcrumbsData}
            captionsClick={captionsClick}
          />
          {children}
          {!children ? <div className="no-data">No Recent Activity</div> : null}
        </StyledSidebar>
        <StyledOverlay className="overlay" onClick={this.closeSidebar}/>
      </Fragment>
    );
  }
}

const StyledSidebar = styled.aside`
  --layouts-sidebar-bg-color: #f0f0f0;
  background: var(--layouts-sidebar-bg-color);
  border-left: 2px solid #c2c2c2;
  height: 100%;
  width: 397px;
  position: absolute;
  right: 0;
  top: 0;
  transition: right 0.3s ease-in-out,width 0.3s ease-in-out;

  &.sidebar-report-details-comments {
    &.sidebar-fixed {
      position: fixed;
      bottom: 0;
    }
  }

  body.sidebar-collapsed & {
    right: -397px;
  }

  .no-data {
    background: url(${noDataImg}) no-repeat top center;
    color: #909090;
    font-size: 15px;
    margin: -42px 0 0 -61px;
    padding-top: 65px;
    text-align: center;
    height: 85px;
    width: 122px;
    position: absolute;
    top: 50vh;
    left: 50%;
  }

  @media (max-width: 768px) {
    left: 2.66%;
    position: fixed;
    z-index: 5;
    width: 97.44%;
    body.sidebar-collapsed & {
      left: auto;
      right: -100%;
    }
  }
`;

const StyledOverlay = styled.div`
  display: none;
  @media (max-width: 768px) {
    background: black;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0.4;
    width: 133px;
    z-index: 4;
    body.sidebar-collapsed & {
      display: none;
    }
  }
`;

Sidebar.defaultProps = {
  breadcrumbsData: [{
    name: 'All Activity',
    active: true,
    handleClick: emptyFunction
  }]
};

Sidebar.propTypes = {
  breadcrumbsData: PropTypes.array,
  className: PropTypes.string
};

export default connect()(Sidebar);
