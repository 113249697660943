import $ from 'jquery';
import config from '../../../../config';
import {getTimezone} from '../../../utils/general';
import {changeTopFilters} from '../components/TopFilters/actions';
import {
  NonNumericFilters,
} from './utils';

export const ARTWORK_ALST_CLEAR = 'ARTWORK_ALST_CLEAR';
export const ARTWORK_ALST_TOGGLE_OPERATOR_MODE = 'ARTWORK_ALST_TOGGLE_OPERATOR_MODE';
export const ARTWORK_ALST_SET_PROJECTS_VIEW = 'ARTWORK_ALST_SET_PROJECTS_VIEW';
export const ARTWORK_ALST_SET_OPENED_ASSET_ID = 'ARTWORK_ALST_SET_OPENED_ASSET_ID';
export const ARTWORK_ALST_SAVED_FILTER = 'ARTWORK_ALST_SAVED_FILTER';
export const ARTWORK_ALST_CHANGE_FILTER = 'ARTWORK_ALST_CHANGE_FILTER';
export const ARTWORK_ALST_CHANGE_FILTER_TYPE = 'ARTWORK_ALST_CHANGE_FILTER_TYPE';
export const ARTWORK_ALST_ADD_FILTERS = 'ARTWORK_ALST_ADD_FILTERS';
export const ARTWORK_ALST_REMOVE_FILTERS = 'ARTWORK_ALST_REMOVE_FILTERS';
export const ARTWORK_ALST_SET_LOADING = 'ARTWORK_ALST_SET_LOADING';
export const ARTWORK_ALST_SET_FILTERS = 'ARTWORK_ALST_SET_FILTERS';
export const ARTWORK_ALST_SET_PROJECTS = 'ARTWORK_ALST_SET_PROJECTS';
export const ARTWORK_ALST_UPDATE_PROJECT_ASSETS = 'ARTWORK_ALST_UPDATE_PROJECT_ASSETS';
export const ARTWORK_ALST_UPDATE_DETAIL = 'ARTWORK_ALST_UPDATE_DETAIL';
export const ARTWORK_ALST_ADD_DETAIL_ISSUE = 'ARTWORK_ALST_ADD_DETAIL_ISSUE';
export const ARTWORK_ALST_DELETE_DETAIL_ISSUE = 'ARTWORK_ALST_DELETE_DETAIL_ISSUE';

export const clearAllAssets = () => ({type: ARTWORK_ALST_CLEAR});

export const toggleOperatorMode = (modeIsOn) => ({type: ARTWORK_ALST_TOGGLE_OPERATOR_MODE, modeIsOn});

export const setProjectsView = (view) => ({type: ARTWORK_ALST_SET_PROJECTS_VIEW, view});

export const setOpenedAssetId = (assetId, projectId) => ({type: ARTWORK_ALST_SET_OPENED_ASSET_ID, assetId, projectId});

const getValidFilter = (allFilters, filter, value) => {
  const allowedFilters = allFilters ? Object.keys(allFilters) : [];
  const isExist = allowedFilters.includes(filter);

  if (isExist) {
    const isNonNumericFilter = NonNumericFilters.includes(filter);

    const existFilter = allFilters[filter] || {};

    const existFilterValues = existFilter ? existFilter.map(f => f.value) : [];
    return Array.isArray(value) ?
      value.filter(item => existFilterValues.includes(!isNonNumericFilter && !isNaN(+item) ? +item : item)).map(item => (!isNonNumericFilter && !isNaN(+item) ? +item : item)) :
      null;
  }

  return null;
};

export const getValidFilters = (allFilters, urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: filters.sortDirect === 'desc' ? 'desc' : 'asc'
      }
    } else if (key !== 'search') {
      let validFilter = getValidFilter(allFilters, key, filters[key]);
      if (!validFilter) {
        const othFilters = {};
        allFilters.filters.forEach(item => {
          othFilters[item.name] = item.values;
        });
        validFilter = getValidFilter(othFilters, key, filters[key]);
      }
      if (validFilter) {
        res[key] = validFilter;
      }
    } else {
      res[key] = filters[key];
    }
  });

  return res;
};

export const getValidFiltersForInitSearch = (urlFilters) => {
  const res = {};

  const filters = {...urlFilters};
  delete filters.tab;
  delete filters.viewTab;

  filters && Object.keys(filters).forEach(key => {

    if (key === 'sort') {
      res[key] = {
        field: filters[key],
        direction: (filters.sortDirect || filters.sortdirect) === 'desc' ? 'desc' : 'asc'
      }
    } else if (!['sortDirect', 'sortdirect'].includes(key)) {
      res[key] = filters[key];
    }
  });

  return res;
};

const DASHBOARD_FILTERS_ARR = [
  'from', 'to', 'state', 'period', 'date_type',
];

export const prepareSingleFiltersParams = filters => {
  const preparedFilters = {...filters};
  DASHBOARD_FILTERS_ARR.forEach(key => {
    if (Array.isArray(preparedFilters[key])) {
      preparedFilters[key] = preparedFilters[key].length > 0 ? preparedFilters[key][0] : null;
    }
  });
  return preparedFilters;
};

export const savedAllLeftFilters = urlFilters => ({type: ARTWORK_ALST_SAVED_FILTER, urlFilters});

export const savedState = (history, selectedFilters, localStorageItemName) => {
  const filters = {};
  selectedFilters.forEach(filter => {
    if ((Array.isArray(filter.value) && filter.value.length) || filter.value) {
      filters[filter.name] = filter.value;
      if (filter.isStudioParameter) {
        filters.__parameter_names = [...filters.__parameter_names || [], filter.name];
      }
    }
    if (filter.name === 'sort') {
      filters[filter.name] = filter.value.field;
      filters.sortDirect = filter.value.direction;
    }
  });

  [...DASHBOARD_FILTERS_ARR/*, 'qc_status', 'last_updated'*/].forEach(key => {
    delete filters[key];
  });
  if (window.location.search.includes('qc_status')) {
    delete filters.qc_status;
  }
  if (window.location.search.includes('last_updated')) {
    delete filters.last_updated;
  }
  localStorage.setItem(localStorageItemName || 'artworkReportFilters', JSON.stringify(filters));
};

export const prepareFiltersParams = (filters, studioParameterNames) => {
  if (!filters) {
    return {};
  }

  const filtersObj = {};
  filters.forEach(filter => {
    const isStudioParameter = Boolean(filter.isStudioParameter || (Array.isArray(studioParameterNames) && studioParameterNames.includes(filter.name)));
    const name = isStudioParameter ? filter.name : filter.name.replace(/ /g, '_').toLowerCase();
    //filtersObj[name] = filter.value;
    if (isStudioParameter) {
      if (filter.value && filter.value.length) {
        filtersObj.parameters = {...filtersObj.parameters, [name]: filter.value};
      }
    } else if (name === 'last_updated') {
      let timezone = Array.isArray(filter.value) && filter.value[0] === 6/*today*/ ? getTimezone() : null;
      filtersObj[name] = timezone ? [timezone] : filter.value;
    } else {
      filtersObj[name] = filter.value;
    }
  });

  delete filtersObj.__parameter_names;

  if (filtersObj.sort) {
    const {field, direction} = filtersObj.sort;
    filtersObj.field = field ? field.toLowerCase() : null;
    filtersObj.direction = direction;
    delete filtersObj.sort;
  }

  return prepareSingleFiltersParams(filtersObj);
};

export const getProjects = (history, topFilters, startedFilters, parameterNames) => (dispatch, getState) => {
  const state = getState();

  !startedFilters && savedState(history, state.artworkAssets.selectedFilters);

  if (!topFilters) {
    topFilters = state.artworkTopFilters.values;
  }
  localStorage.setItem('artworkReportTopFilters', JSON.stringify(topFilters));
  !!startedFilters && dispatch(changeTopFilters(topFilters));

  const filtersObj = {
    ...prepareFiltersParams(state.artworkAssets.selectedFilters, parameterNames),
    title_id: topFilters.titleId,
    project_id: topFilters.projectId,
    amazon_series_id: topFilters.amazonSeriesId,
    amazon_concept_name: topFilters.amazonConceptName,
    amazon_asset_type: topFilters.amazonAssetType,
  };

  const loadingName = 'projects';
  dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/projects`,
    data: {
      filters: JSON.stringify(filtersObj)
    }
  })
  .done(res => {
    let {data, total} = res.projects;
    dispatch({type: ARTWORK_ALST_SET_PROJECTS, data, total});
    dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
    dispatch(changeTopFilters(topFilters));
  })
  .fail(() => {
    dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
  });
};

export const getProjectAssets = (project, isLoadMore, searchString) => (dispatch, getState) => {
  const state = getState();
  const filtersObj = {
    ...prepareFiltersParams(state.artworkAssets.selectedFilters),
    title_id: state.artworkAssets.titleId,
    project_id: state.artworkAssets.projectId,
  };
  const start = isLoadMore ? project.assets.data.length : 0;
  if (isLoadMore) {
    searchString = project.assets.search;
  }
  if (searchString && (!filtersObj.search || !filtersObj.search.toLowerCase().includes(searchString.toLowerCase()))) {
    filtersObj.search = (filtersObj.search ? filtersObj.search + ' ' : '') + searchString;
  }

  const loadingName = 'getProjectAssets';
  dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/projects/${project.project_id}/assets`,
    data: {
      start,
      limit: config.pagingSize,
      filters: JSON.stringify(filtersObj)
    }
  })
  .done(res => {
    let {data, total} = res.assets;
    if (isLoadMore) {
      data = [...project.assets.data, ...data];
      total = total || project.assets.total;
    }
    dispatch({type: ARTWORK_ALST_UPDATE_PROJECT_ASSETS, project, assets: {data, total, search: searchString}});
    dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
  })
  .fail(() => {
    dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
  });
};

export const changeFilter = (history, name, value) => dispatch => {
  dispatch({type: ARTWORK_ALST_CHANGE_FILTER, name, value});
  return dispatch(getProjects(history));
};

export const changeFilterType = (history, filter, newSelectedFilter) => dispatch => {
  dispatch({type: ARTWORK_ALST_CHANGE_FILTER_TYPE, filter, newSelectedFilter});
  return dispatch(getProjects(history));
};

export const addFilter = filter => ({type: ARTWORK_ALST_ADD_FILTERS, filter});

export const removeFilter = filter => ({type: ARTWORK_ALST_REMOVE_FILTERS, filter});

export const getFilters = (history, storedReportFilters, srcFilters) => (dispatch, getState) => {
  const loadingName = 'filters';
  dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/filters`,
    data: {
      filters: JSON.stringify(prepareSingleFiltersParams(storedReportFilters))
    }
  })
    .then(res => {
      const validUrlFilters = getValidFilters(res, srcFilters);
      const urlParams = {};
      const someFilters = {};
      Object.keys(validUrlFilters).forEach(filter => {
        if (['search', 'sort'].includes(filter)) {
          urlParams[filter] = validUrlFilters[filter];
        } else {
          someFilters[filter] = validUrlFilters[filter];
        }
      });
      dispatch(savedAllLeftFilters(urlParams));
      return {allowedFilters: res.filters, someFilters};
    })
    .then(({allowedFilters, someFilters}) => {
      dispatch({type: ARTWORK_ALST_SET_FILTERS, allowedFilters});

      const someFiltersKeys = Object.keys(someFilters).sort((a, b) => {
        if (DASHBOARD_FILTERS_ARR.includes(a)) {
          if (a === 'to' && b === 'from') {
            return 1;
          }
          return -1;
        }
        if (DASHBOARD_FILTERS_ARR.includes(b)) {
          return 1;
        }
        return a.localeCompare(b);
      });
      someFiltersKeys.forEach(filterName => {
        const filter = allowedFilters.find(item => item.name === filterName);
        if (filter) {
          const initFilterValue = someFilters[filterName];
          if (filter.defaultValue && initFilterValue && initFilterValue.length === 1 && initFilterValue[0] === filter.defaultValue) {
            return;
          }
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: someFilters[filterName],
            disabled: true
          }));
        }
      });

      const requiredFilters = allowedFilters.filter(filter => filter.required);
      requiredFilters.forEach(filter => {
        if (true || !Object.keys(someFilters).includes(filter.name)) {
          dispatch(addFilter({
            name: filter.name,
            label: filter.title,
            type: filter.type,
            multi: filter.multi,
            forFilterName: filter.forFilterName,
            hasAdditionalFilters: filter.hasAdditionalFilters,
            isStudioParameter: filter.isStudioParameter,
            studioID: filter.studioID,
            isOrderedValues: filter.isOrderedValues,
            orderNum: filter.orderNum,
            defaultValue: filter.defaultValue,
            disabled: true,
            required: true
          }));
        }
      });

      dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
    })
    .then(() => ({selectedFilters: getState().artworkAssets.selectedFilters, allowedFilters: getState().artworkAssets.allowedFilters}))
    .then(({selectedFilters, allowedFilters}) => {
      const additionalFilters = allowedFilters.filter(filter => filter.forFilterName);
      additionalFilters.forEach(filter => {
        const selMainFilter = selectedFilters.find(f => f.name === filter.forFilterName);
        if (!selMainFilter) {
          dispatch(removeFilter(filter));
        } else if (selMainFilter.value && (Array.isArray(selMainFilter.value) ? selMainFilter.value.length && selMainFilter.value[0] : selMainFilter.value)) {
          dispatch(addFilter({...filter, disabled: true}));
        }
      });
    })
    .then(() => getState().artworkAssets.selectedFilters)
    .then(selectedFilters => savedState(history, selectedFilters))
    .catch(() => {
      dispatch({type: ARTWORK_ALST_SET_LOADING, name: loadingName, loading: false});
    });
};
