import {
  ARTWORK_DASHBOARD_GET_DATA, ARTWORK_DASHBOARD_SET_LOADING, ARTWORK_DASHBOARD_GET_REQUEST_QUEUE_DATA, ARTWORK_DASHBOARD_GET_REQUESTS_BY_STATUS_DATA,
  ARTWORK_DASHBOARD_GET_ISSUES_BY_TYPE_DATA, ARTWORK_DASHBOARD_GET_LARGEST_NUMBER_DATA, ARTWORK_DASHBOARD_GET_REPLACEMENT_CONST_DATA,
  ARTWORK_DASHBOARD_GET_PASS_PERCENTAGE_DATA,
  ARTWORK_DASHBOARD_CLEAR,
  ARTWORK_DASHBOARD_CHANGE_CHART_FILTERS,
} from './actions';
import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';

const initialState = {
  selectedChartFilters: {
    requestQueue: {
      type: 1
    },
    requestsByStatus: {
      period: 3
    },
    issuesByType: {
      period: 3
    },
    replacementLargestNumber: {
      period: 3,
      group: 1
    },
    replacementCost: {
      period: 3,
      group: 1
    },
    passPercentage: {
      period: 3,
      group: 0
    },
  },
  requestQueue: {
    request_queue: [],
    request_list: [],
    month: '',
    year: ''
  },
  requestsByStatus: [],
  issuesByType: [],
  replacementLargestNumber: [],
  replacementCost: [],
  passPercentage: {},
  useAdvancedPassPercentage: false,
  loaders: {
    dashboard: false,
    requestQueue: false,
    requestsByStatus: false,
    issueByType: false,
    numberOfReplacements: false,
    replacementCost: false,
    passPercentage: false,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ARTWORK_DASHBOARD_GET_DATA: {
      const requestQueue = {
        request_queue: action.data.request_queue,
        request_list: action.data.request_list,
        month: action.data.month,
        year: action.data.year
      };

      return {
        ...state,
        selectedChartFilters: {
          ...state.selectedChartFilters,
          requestQueue: {
            ...state.selectedChartFilters.requestQueue,
            month: action.data.month,
            year: action.data.year
          }
        },
        requestQueue,
        requestsByStatus: action.data.requests_by_status,
        issuesByType: action.data.issues_by_type,
        replacementLargestNumber: action.data.replacement_largest_number,
        replacementCost: action.data.replacement_cost,
        passPercentage: action.data.pass_percentage,
        useAdvancedPassPercentage: action.data.use_advanced_pass_percentage,
      }
    }

    case ARTWORK_DASHBOARD_GET_REQUEST_QUEUE_DATA: {
      return {...state, requestQueue: action.data}
    }

    case ARTWORK_DASHBOARD_GET_REQUESTS_BY_STATUS_DATA: {
      return {...state, requestsByStatus: action.data}
    }

    case ARTWORK_DASHBOARD_GET_ISSUES_BY_TYPE_DATA: {
      return {...state, issuesByType: action.data}
    }

    case ARTWORK_DASHBOARD_GET_LARGEST_NUMBER_DATA: {
      return {...state, replacementLargestNumber: action.data}
    }

    case ARTWORK_DASHBOARD_GET_REPLACEMENT_CONST_DATA: {
      return {...state, replacementCost: action.data}
    }

    case ARTWORK_DASHBOARD_GET_PASS_PERCENTAGE_DATA: {
      return {...state, passPercentage: action.data}
    }

    case ARTWORK_DASHBOARD_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.value
        }
      }
    }

    case ARTWORK_DASHBOARD_CLEAR:
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case ARTWORK_DASHBOARD_CHANGE_CHART_FILTERS: {
      return {
        ...state,
        selectedChartFilters: {
          ...state.selectedChartFilters,
          [action.name]: action.value
        }
      };
    }

    default:
      return state;
  }
}
