import React, {useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Modal, Button} from 'react-bootstrap';

function SwitchCompanyModal(props) {
  const {companies, onSelect, onClose, isInitCompany} = props;
  const [selCompany, setSelCompany] = useState(null);
  return (
    <ModalStyled
      show
      onHide={onClose}
      backdrop={isInitCompany ? "static" : undefined}
      backdropClassName={isInitCompany ? "modal-backdrop--init-company" : undefined}
      dialogClassName={isInitCompany ? "modal-dialog--init-company" : undefined}
      keyboard={!isInitCompany}
      animation={!isInitCompany}
      enforceFocus={false}
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title
          componentClass="div"
        >
          {isInitCompany ?
            <>
              Resillion US
              <label>
                Company
              </label>
            </> : 'Change company'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalContainerStyled>
          {companies.map(c => (
            <Button
              key={c.value || 'c'}
              title="Click to select a company"
              onClick={() => {
                if (isInitCompany) {
                  onSelect(c);
                } else {
                  setSelCompany(selCompany && c.value === selCompany.value ? null : c);
                }
              }}
              disabled={!c.value}
              className={selCompany && c.value === selCompany.value ? 'is-selected' : undefined}
            >
              {c.logo_url ? <div className="company-logo"><img src={c.logo_url} alt=""/></div> : null}
              <span className="company-name">
                {c.name}
              </span>
              <span className="company-type">
                {!c.value ? 'Current company' : c.type_name}
              </span>
            </Button>
          ))}
        </ModalContainerStyled>
      </Modal.Body>
      <Modal.Footer>
        {isInitCompany ?
          'You will need to select the company you want to continue with' :
          <>
            <Button
              bsStyle="default"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              disabled={!selCompany}
              onClick={() => onSelect(selCompany)}
            >
              Change
            </Button>
          </>
        }
      </Modal.Footer>
      <ModalGlobalStyled/>
    </ModalStyled>
  );
}

const ModalGlobalStyled = createGlobalStyle`
  .modal-backdrop--init-company {
    background: linear-gradient(122.07deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D no-repeat;
    opacity: 1 !important;
  }
  .modal-dialog--init-company {
    .modal-content {
      padding-bottom: 15px !important;
      background: #fbfdff;
      box-shadow: 0 0 5px 0 rgba(9, 40, 61, 0.5);
      border: none;
    }
    .modal-header {
      padding-bottom: 0 !important;
    }
    .modal-footer {
      padding-bottom: 15px !important;
      text-align: left !important;
    }
  }
  .loader-container {
    position: fixed !important;
    z-index: 1051 !important;
  }
`;

const ModalStyled = styled(Modal)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .modal-dialog {
    @media (min-width: 768px) {
      width: fit-content;
      min-width: 300px;
      max-width: 500px;
    }
  }
  .modal-content {
    padding: 25px 15px;
    border-radius: 0;
  }
  .modal-header {
    border: none;
    .close {
      margin-top: -15px;
    }
  }
  .modal-title {
    font-size: 20px;
    label {
      margin-top: 10px;
      margin-bottom: 0;
      display: block;
      font-size: 18px;
      color: #1b212e;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
  .modal-footer {
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    font-size: 12px;
    button {
      padding: 11px 26px 12px;
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #fff;
      border-radius: 100px;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      &.btn-primary {
        background-color: #646464 !important;
        &:not(:disabled):hover {
          background-color: #565656 !important;
        }
        &:disabled {
          color: #c8c8c8;
        }
      }
      &.btn-default {
        &:not(:hover) {
          background-color: transparent !important;
        }
        color: #282828;
        border: #ccc 1px solid !important;
      }
    }
  }
  .modal-body {
    padding-top: 0;
  }
`;

const ModalContainerStyled = styled.div`
  padding-top: 15px;
  max-height: calc(100vh - 275px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: rgba(255, 255, 255, 1);
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(0, 0, 0, .2);
      -webkit-box-shadow: none;
    }
  }
  .btn {
    padding: 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    border-color: #cbcbcb;
    border-radius: 4px;
    outline: none !important;
    text-align: left;
    white-space: normal;
    position: relative;
    &:not(:disabled):hover {
      background-color: #fff;
      border-color: #68a1d3;
      color: #2a7cc5;
    }
    &.is-selected {
      border-color: #68a1d3;
    }
    .company-logo {
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .company-type {
      position: absolute;
      right: 4px;
      top: -15px;
      font-size: 11px;
    }
  }
`;

export default SwitchCompanyModal;
