import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, Collapse} from 'react-bootstrap';
import Select from 'react-select';
import ColoredFilter from './ColoredFilter';
import SelectStyled from './SelectStyled';
import StyledBtn from './FilterBtnStyled';
import InputAdvanced from '../forms/InputAdvanced';
import searchImg from '../../../assets/images/search.svg';
import filterImg from '../../../assets/images/filter.svg';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ALL_OPTION_VALUE = -100;
const VISIBLE_OPTIONS_LIMIT = 10;

class Filter extends React.PureComponent {
  state = {
    searchString: null,
    hideMoreThan_10_Options: true
  };

  handleClick = (filterName, filterValue, newValue) => {
    const {onChangeFilters, filter} = this.props;
    const value = newValue === null ? null :
      (filterValue && !filterValue.includes(ALL_OPTION_VALUE) && newValue !== ALL_OPTION_VALUE) ?
        (filter.multi === false ? (filterValue.includes(newValue) ? filterValue : [newValue]) :
          filterValue.includes(newValue) ?
            filterValue.filter(item => item !== newValue) :
              [...filterValue, newValue]
        ) :
          [newValue];
    if (filterName === 'due_date') {
      onChangeFilters('due_date', {'due_date': value, 'due_date_start': null, 'due_date_end': null});
      return;
    }
    onChangeFilters(filterName, value && value.length ? value : null);
  };

  handleSelect = event => {
    const {onChangeFilterType, filter} = this.props;
    onChangeFilterType(filter.name, event.value, event.label);
  };

  isSelected = item => {
    const {value} = this.props;
    return (value && value.includes(item.value));
  };

  getClassName(item) {
    const {value} = this.props;
    return ((item.name === 'All' && value === null) || this.isSelected(item)) ? 'selected' : '';
  }

  isVisible = item => {
    return item.name === 'All'|| item.value < 0 || this.isSelected(item);
  };

  renderFilter() {
    const {filter, value} = this.props;
    if (!filter || !filter.values || !filter.values.length) {
      return null;
    }

    const {hideMoreThan_10_Options, searchString} = this.state;
    const values = [...filter.values].filter(o => !searchString || o.name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
    const visibleCount = values.reduce((a, c) => a + (this.isVisible(c) ? 1 : 0), 0);
    const showMoreOrLessBtn = values.length > VISIBLE_OPTIONS_LIMIT && visibleCount <= VISIBLE_OPTIONS_LIMIT;
    const showSelectedItemsOnTop = hideMoreThan_10_Options && showMoreOrLessBtn && values.length > VISIBLE_OPTIONS_LIMIT;
    !filter.isOrderedValues && values.sort((a, b) => {
      if (a.name === 'All') {
          return -1;
      }
      if (b.name === 'All') {
        return 1;
      }
      if (a.value < 0) {
        return b.name === 'All' ? 1 : -1;
      }
      if (b.value < 0) {
        return a.name === 'All' ? -1 : 1;
      }
      if (showSelectedItemsOnTop && this.isSelected(a) && !this.isSelected(b)) {
        return -1;
      }
      if (showSelectedItemsOnTop && this.isSelected(b) && !this.isSelected(a)) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
    return (
      <FilterStyled id={`filter-${filter.name}`}>
      {
        (showMoreOrLessBtn || searchString) && !hideMoreThan_10_Options ?
          <>
            <InputAdvanced
              formGroupClassName={`search-options-box${searchString ? '' : ' empty'}`}
              placeholder="Search"
              value={searchString || ''}
              onChange={(n, v) => this.setState({searchString: v})}
              onBlur={(n, v) => this.setState({searchString: v})}
              onClear={() => this.setState({searchString: null})}
              forceReset
            />
            {!values.length && <div className="no-matches" title="No options match"><Button>No matches</Button></div>}
          </> : null
      }
      {
        values.map((item, i) => (hideMoreThan_10_Options && i >= VISIBLE_OPTIONS_LIMIT && !this.isVisible(item)) ? null :
          <div key={i}>
            <Button
              onClick={() => this.handleClick(filter.name, value, item.value)}
              className={this.getClassName(item)}
              title={`${item.name}${item.total >= 0 ? ` :: ${item.total}` : ''}`}
              >{item.name} <span className="total">
                {item.total}
              </span>
            </Button>
          </div>
        )
      }
      {
        showMoreOrLessBtn ?
          <StyledBtn
            className="show-more-options"
            onClick={() => this.setState({hideMoreThan_10_Options: !hideMoreThan_10_Options, searchString: null})}
          >
            {hideMoreThan_10_Options ? 'Show more options' : 'Show less'}
          </StyledBtn> : null
      }
      {filter.name === 'due_date' && this.renderDueDateRangeFilter()}
      </FilterStyled>
    );
  }

  onChangeDueDateStart(startDate) {
    const {onChangeFilters} = this.props;
    let formattedDate = null;
    if (startDate) {
      formattedDate = moment(startDate).format('YYYY-MM-DD');
    }
    onChangeFilters('due_date', {'due_date': [5], 'due_date_start': formattedDate});
  }

  onChangeDueDateEnd(endDate) {
    const {onChangeFilters} = this.props;
    let formattedDate = null;
    if (endDate) {
      formattedDate = moment(endDate).format('YYYY-MM-DD');
    }
    onChangeFilters('due_date', {'due_date': [5], 'due_date_end': formattedDate});
  }

  renderDueDateRangeFilter() {
    const {shownFilters} = this.props;
    const dueDateStartFilter = shownFilters.filter(filter => filter.name === 'due_date_start')[0];
    const dueDateEndFilter = shownFilters.filter(filter => filter.name === 'due_date_end')[0];
    const startDate = dueDateStartFilter && dueDateStartFilter.value;
    const endDate = dueDateEndFilter && dueDateEndFilter.value;
    return (
      <div className="due-date-range-filter">
        <DatePicker
          selected={startDate ? moment(startDate).toDate() : null}
          placeholderText='start date'
          onChange={date => this.onChangeDueDateStart(date)}
          locale="en"
          isClearable
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
        <span>-</span>
        <DatePicker
          selected={endDate ? moment(endDate).toDate() : null}
          placeholderText='end date'
          onChange={date => this.onChangeDueDateEnd(date)}
          locale="en"
          isClearable
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    );
  }

  render() {
    const {coloredFilterName, filter, filtersOptions, value, isOpen, onToggleFilterOpen} = this.props;
    const open = typeof isOpen !== 'boolean' || isOpen;

    return (
      <StyledWrapFilter className={filter.type ? `type-${filter.type}` : undefined}>
        <SelectStyled>
          <Select
            value={filter.name}
            options={filtersOptions}
            onChange={this.handleSelect}
            disabled={filter.disabled || filtersOptions.length <= 1}
            />
          <div
            className="filter-collapse-arrow"
            onClick={onToggleFilterOpen}
            aria-controls={`filter-${filter.name}`}
            aria-expanded={open}
            />
        </SelectStyled>
        {
          filter.name === coloredFilterName ? (
            <ColoredFilter
              filter={filter}
              value={value}
              onClick={this.handleClick}
              />
          ) : (
            <Collapse in={open}>
              {this.renderFilter()}
            </Collapse>
          )
        }
      </StyledWrapFilter>
    );
  }
}

const StyledWrapFilter = styled.div`
  @media (max-width: 991px) {
    .requests-content & {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.type-info {
    pointer-events: none;
    opacity: 0.8;
    .Select-arrow-zone {
      visibility: hidden;
    }
  }

  .due-date-range-filter {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin: 0 5px;
    }

    input {
      width: 110px;
    }

    .react-datepicker {
      font-size: 0.8em;
    }
    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__day-name, .react-datepicker__day {
      width: 1.7em;
      line-height: 1.7em;
      margin: 0.166em;
    }
    .react-datepicker__current-month {
      font-size: 0.9em;
    }
    .react-datepicker__navigation {
      line-height: 1.7em;
      border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #ccc;
      left: 1em;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ccc;
      right: 1em;
    }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
      width: unset;
    }
  }
`;

const FilterStyled = styled.div`
  border-bottom: 1px solid #ddd;
  color: #282828;
  margin: 5px 0 12px;
  padding: 7px;

  .btn {
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-size: 10px;
    margin-bottom: 1px;
    outline: none;
    opacity: 0.7;
    padding: 7px;
    &.selected {
      background-color: #585858;
      color: #fff;
      opacity: 1;
    }

    span {
      margin-left: 10px;
    }
  }

  @media (max-width: 991px) {
    .requests-content & {
      margin: 0;
      border-bottom-color: #e0e0e0;
      background-color: #F8F8F8;
      border-top: 0.5px solid rgba(0,0,0,0.2);
    }
  }

  @media (min-width: 992px) {

  .btn {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .requests-content & {
      display: block !important;
      height: auto !important;
    }
  }

  .form-group.search-options-box {
    margin: 0 -7px 10px;
    width: calc(100% + 14px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      padding: 6px 28px;
      width: 100%;
      height: auto;
      background-image: url(${searchImg}) !important;
      background-repeat: no-repeat !important;
      background-size: 16px 16px !important;
      background-position: 5px center !important;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      color: #282828;
      font-family: 'Roboto',sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      &::placeholder {
        color: rgba(40, 40, 40, 0.4) !important;
        font-weight: 400;
      }
      &:not(:focus) {
        opacity: 0.5;
        box-shadow: none !important;
        &:hover {
          opacity: 0.85;
        }
      }
    }
    i {
      position: absolute;
      top: 6.5px;
      right: 7px;
      font-size: 14px;
      color: #555;
      opacity: 0.75;
      cursor: pointer;
      &:hover {
        color: #222;
      }
    }
    &.empty {
      i {
        display: none;
      }
    }
    &:not(.empty) {
      padding-right: 18px;
      background-image: url(${filterImg}) !important;
      background-repeat: no-repeat !important;
      background-size: 16px 16px !important;
      background-position: right center !important;
      i {
        right: 25px;
      }
    }

    + .no-matches {
      color: rgba(40,40,40,0.5);
      .btn {
        pointer-events: none;
      }
    }
  }
`;

Filter.propTypes = {
  isOpen: PropTypes.bool,
  onToggleFilterOpen: PropTypes.func,
  coloredFilterName: PropTypes.string,
  filter: PropTypes.object.isRequired,
  filtersOptions: PropTypes.array.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onChangeFilterType: PropTypes.func.isRequired,
  value: PropTypes.any
};

export default Filter;

