import React from 'react';
import PropTypes from 'prop-types';

export const DurationCell = React.memo(({row, className}) => {
  const DurationArr = row.Duration && row.Duration.split(':');
  return (
    <td className={className} title="Duration">
      {DurationArr && DurationArr.length === 4 ?
        <span>{DurationArr[0]}:{DurationArr[1]}:<b>{DurationArr[2]}:{DurationArr[3]}</b></span> : row.Duration
      }
    </td>
  );
});

DurationCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string
};
