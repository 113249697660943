import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import SelectControl from './SelectControl';

function SelectableCell(props) {
  const {
    className, value, options, onChange, onFocus, onBlur, placeholder, isProductCode, minWidth,
    assigneeName, isAssigneeControl, isClearable, menuMaxHeight
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  let isInactiveAssignee;
  let selectedOption = options.find(o => o.value === value);
  let preparedOptions = options;
  let onInputChange;
  if (isProductCode) {
    if (value && !selectedOption) {
      selectedOption = {value, name: value};
      if (!searchTerm) {
        preparedOptions = [selectedOption, ...preparedOptions];
      }
    }
    onInputChange = (text, action) => {
      if (action.action === 'input-change') {
        setSearchTerm(text || '');
      } else if (action.action === 'menu-close') {
        setSearchTerm('');
      }
    };
  } else if (assigneeName && !selectedOption) {
    isInactiveAssignee = options.length > 0;
    selectedOption = {value, name: assigneeName, isDisabled: true};
    if (!searchTerm) {
      preparedOptions = [selectedOption, ...preparedOptions];
    }
  }

  const startX = useRef(0);
  const startY = useRef(0);

  const mouseHasMovedAfterMouseDown = event => {
    return Math.abs(startX.current - event.clientX) >= 5 || Math.abs(startY.current - event.clientY) >= 5;
  }

  const onReadViewClick = event => {
    if (!mouseHasMovedAfterMouseDown(event)) {
      setEditMode(true);
      onFocus();
    }
  }

  if (isAssigneeControl) {
    onInputChange = (text, action) => {
      if (action.action === 'input-change') {
        setSearchTerm(text || '');
      } else if (action.action === 'menu-close') {
        setSearchTerm('');
      }
    };
  }

  return (
    <StyledCell className={[className, isInactiveAssignee ? 'is-inactive-assignee' : ''].filter(Boolean).join(' ')}>
      {
        editMode ?
          <SelectControl
            selectedOption={selectedOption}
            value={value}
            options={preparedOptions}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={() => {setEditMode(false);onBlur();}}
            placeholder={placeholder}
            menuIsOpen
            onInputChange={onInputChange}
            minWidth={minWidth}
            menuMaxHeight={menuMaxHeight}
            isClearable={isClearable}
            menuPortalTarget={props.menuPortalTarget}
            menuPlacement={props.menuPlacement}
            isSelectText={isProductCode}
          /> :
          <div
            className={`selected-option${selectedOption ? '' : ' empty'}`}
            onClick={onReadViewClick}
            onMouseDown={e => {
              startX.current = e.clientX;
              startY.current = e.clientY;
            }}
          >
            <span>{selectedOption ? (selectedOption.shortName || selectedOption.name) : placeholder === undefined ? 'Select...' : placeholder}</span>
          </div>
      }
    </StyledCell>
  );
}

const StyledCell = styled.td`
  > .selected-option {
    padding-left: 2px;
    min-height: 30px;
    border: 0.5px solid transparent;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.empty {
      color: #808080;
    }
  }
  &:not(.disabled) {
    > .selected-option {
      :hover {
        background-color: #f2f2f2;
        border-color: #b3b3b3;
      }
    }
  }
  &.is-inactive-assignee {
    position: relative;
    :after {
      content: 'Inactive user';
      color: red;
      position: absolute;
      top: 5px;
      left: 90px;
      font-size: 11px;
    }
  }
`;

export default SelectableCell;