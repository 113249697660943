import React, {useState} from 'react';
import styled from 'styled-components';
import SelectControl from './SelectControl';

function MultiSelectableCell(props) {
  const {
    className, value, options, onChange, onFocus, onBlur, placeholder, minWidth, disabled, menuMaxHeight
  } = props;

  const [editMode, setEditMode] = useState(false);

  // for a short list of options:
  return (
    <StyledCell className={className}>
      <SelectControl
        selectedOption={options.filter(o => ((value || 0) & o.value) === o.value).map(o => ({...o}))}
        options={options}
        onChange={v => onChange(v.map(o => o.value).reduce((a, b) => a | b, 0))}
        onFocus={onFocus}
        onBlur={() => {
          setEditMode(false);
          onBlur();
        }}
        placeholder={disabled ? placeholder : 'Select...'}
        menuMaxHeight={menuMaxHeight}
        minWidth={minWidth}
        isClearable={editMode}
        menuPortalTarget={props.menuPortalTarget}
        menuPlacement={props.menuPlacement}
        multiSelectProps={{
          isMulti: true,
          closeMenuOnSelect: false,
          blurInputOnSelect: false,
          getOptionLabel: o => o.name,
          className: editMode ? 'categories-list' : 'categories-list2',
          classNamePrefix: editMode ? 'categories-select' : 'categories-select2',
          onMenuOpen: editMode ? undefined : () => setEditMode(true),
          isDisabled: disabled
        }}
      />
    </StyledCell>
  );
}

const StyledCell = styled.td`
  .categories-list2 {
    .categories-select2__control {
      .categories-select2__value-container {
        &.categories-select2__value-container--has-value {
          .categories-select2__multi-value {
            .categories-select2__multi-value__label {
              padding-left: 6px;
              padding-right: 6px;
            }
            .categories-select2__multi-value__remove {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default MultiSelectableCell;