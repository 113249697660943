import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Checkbox} from 'react-bootstrap';
import IssueTableStyled from './IssueTableStyled';
import Table from '../../../components/table/Table';
import {TimeCodeCell} from './table/TimeCodeCell';
import {DurationCell} from './table/DurationCell';
import {SeverityCell} from './table/SeverityCell';
import {getSortedRowsBy} from '../../../utils/general';

class ImportIssuesTable extends Component {
  state = {
    checkedIssues: [...this.props.checkedIssues],
    tableSort: {}
  };

  handleCheckIssue = (checked, {IssueID}) => {
    const {checkedIssues} = this.state;
    const {onCheckIssues} = this.props;
    const index = checkedIssues.indexOf(IssueID);

    const newValue = [...checkedIssues];
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(IssueID);
    }
    this.setState({checkedIssues: newValue});
    onCheckIssues(newValue);
  };

  checkboxCell = ({value, row, className, isChecked, onChange}) => (
    <td className={className} style={row.SeverityColor ? {borderLeftColor: `#${row.SeverityColor}`} : {}}>
      <Checkbox
        checked={isChecked(row)}
        onChange={({target: {checked}}) => onChange(checked, row)}
      />
    </td>
  );

  checkboxHeaderCell = ({className, isChecked, onChange}) => (
    <th className={className}>
      <Checkbox
        title={isChecked() ? 'Deselect All' : 'Select All'}
        checked={isChecked()}
        onChange={({target: {checked}}) => onChange(checked)}
      />
    </th>
  );

  yesNoQodIssueCell = ({value, row, className}) => (
    <td className={className} title="Is QOD Issue">
      {value ? 'Yes' : 'No'}
    </td>
  );

  importedCell = ({value, className, title}) => (
    <td className={className} title={title}>
      {value ? 'Yes' : 'No'}
    </td>
  );

  headers = {
    IssueID: {
      title: '',
      headCell: {
        component: this.checkboxHeaderCell,
        componentArgs: {
          className: 'checkbox-cell',
          onChange: checked => {
            const checkedIssues = checked ? this.props.data.map(i => i.IssueID) : [];
            this.setState({checkedIssues});
            this.props.onCheckIssues(checkedIssues);
          },
          isChecked: () => (this.state.checkedIssues.length === this.props.data.length && this.props.data.length > 0)
        }
      },
      className: 'checkbox-cell',
      component: this.checkboxCell,
      componentArgs: {
        isChecked: row => this.state.checkedIssues.includes(row.IssueID),
        onChange: this.handleCheckIssue
      }
    },
    Timecode: {
      title: 'Timeсode',
      className: 'issue-timeсode',
      component: TimeCodeCell
    },
    Duration: {
      title: 'Duration',
      className: 'issue-duration',
      component: DurationCell
    },
    LocationAndChannel: {
      title: 'Location',
      className: 'location'
    },
    Category: {
      title: 'Category',
      className: 'issue-category capitalized-all'
    },
    IssueType: {
      title: 'Type',
      className: 'issue-type capitalized-all'
    },
    Description: {
      title: 'Description',
      className: 'description capitalized-all'
    },
    Severity: {
      title: 'Severity',
      className: 'issue-severity',
      component: SeverityCell
    },
    EnteredBy: {
      title: 'Entered By',
      className: 'entered-by'
    },
    IsQodIssue: {
      title: 'Is QOD Issue',
      className: 'is-qod-issue',
      component: this.yesNoQodIssueCell
    },
    Imported: {
      title: 'Imported',
      className: 'is-imported',
      component: this.importedCell
    },
  };

  sortAltFields = {
    Timecode: 'TimeCodeStart',
    Severity: 'SeverityPriority'
  };

  handleHeaderCellClick = (field, direction) => {
    if (field !== 'IssueID') {
      this.setState({
        tableSort: {field, direction}
      });
    }
  };

  getStatus(row) {
    return {
      1: 'type-low',
      2: 'type-low',
      3: 'type-middle',
      4: 'type-middle',
      5: 'type-hight'
    }[row.SeverityTypeID];
  }

  getRowClassNames = row => {
    return [this.getStatus(row), row.IsDeleted ? 'issue-deleted' : ''].filter(Boolean).join(' ') || undefined;
  };

  render() {
    const {data} = this.props;
    const {tableSort} = this.state;

    return (
      <div className="table-container midl_in">
        <StyledMobileTable issueBorderWidth="4.87px">
          <Table
            className={data.length ? undefined : ' empty-issues-table'}
            isSeparatedRows
            headers={this.headers}
            rows={getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields)}
            sort={tableSort}
            onHeaderCellClick={this.handleHeaderCellClick}
            getRowClassNames={row => this.getRowClassNames(row)}
            emptyText="No Issues"
          />
        </StyledMobileTable>
      </div>
    );
  }
}

const StyledMobileTable = styled(IssueTableStyled)`
  overflow-y: auto;
  max-height: calc(100vh - 104px - 71px);

  .table {
    &.empty-issues-table {
      > thead {
        display: none;
      }
      > tbody {
        > tr {
          > td {
            border: none;
          }
        }
      }
    }
    > thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 2;
      > tr {
        > th {
          white-space: nowrap;
          &.checkbox-cell {
            > .checkbox {
              margin: 0;
              label {
                padding-top: 0;
                padding-bottom: 0;
                min-height: 15px;
              }
            }
          }
        }
      }
    }
    > tbody {
      > tr {
        cursor: default;
        > td {
          &.checkbox-cell {
            > .checkbox {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    max-height: calc(100vh - 68px - 83px);

    .table {
      margin-bottom: 0;

      > thead {
        &:after {
          content: "";
          display: block;
          height: 1.5em;
          width: 100%;
          background: transparent;
        }
        > tr {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          border-bottom: 1px solid #ddd;
          > th {
            &:not(.checkbox-cell) {
              display: none;
            }
            &.checkbox-cell {
              padding-left: 19px;
              padding-bottom: 19px;
              width: 100%;
              order: 1;
              border-bottom: none;
              > .checkbox {
                label {
                  min-height: 22px;
                  font-size: 14px;
                  line-height: 22px;
                  &:after{
                    content: attr(title);
                  }
                }
              }
            }
          }
        }
      }

      > tbody {
        > tr {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &.tr-collapsed > td {
            position: relative;

            &:before {
              padding-bottom: 0;
            }

            > div {
              border-radius: 3px;
              position: absolute;
              top: 7px;
              right: 30px;
              width: 130px;
            }
          }

          &.tr-separate {
            padding: 6px 0 0 24px;

            > th {
              border-top: 1px solid #ddd;
              margin-bottom: 23px;
              width: 100%;
            }
          }

          > td {
            border-bottom: none;
            border-left: ${props => props.issueBorderWidth || '4px'} solid transparent;
            margin-bottom: 15px;
            padding: 8px 19px 0;
            text-align: left !important;

            &.data-table-empty {
              text-align: center !important;
              padding: 0 !important;
            }
            > span {
              float: left;
              overflow-wrap: break-word;
            }
            &:before {
              content: attr(title);
              padding-bottom: 4px;
              width: 100%;
              color: #282828;
              float: right;
              font-weight: bold;
              text-transform: none;
              font-size: 15px;
              line-height: 16px;
            }
            // First line
            &.checkbox-cell {
              order: 1;
              padding-top: 0;
              padding-right: 2px;
              width: 52px;
              font-size: 15px;
              line-height: 16px;
              color: rgba(40, 40, 40, 0.7);
            }
            &.issue-timeсode {
              order: 2;
              padding-top: 0;
              padding-right: 2px;
              width: calc(100% - 52px);
              border-left: none;
              font-size: 15px;
              line-height: 16px;
              color: rgba(40, 40, 40, 0.7);
            }
            // Second line
            &.issue-duration {
              order: 4;
              width: 30%;
              font-size: 13px;
              line-height: 14px;
            }
            &.location {
              order: 5;
              width: 30%;
              font-size: 13px;
              line-height: 14px;
            }
            &.issue-category {
              display: none;
            }
            &.issue-type {
              order: 6;
              width: 40%;
              font-size: 13px;
              line-height: 14px;
            }
            // Third line
            &.description {
              order: 7;
              width: 100%;
              font-size: 13px;
              line-height: 17px;
              color: rgba(40, 40, 40, 0.7);
            }
            // Fourth line
            &.issue-severity {
              order: 9;
              width: 50%;
              font-size: 13px;
              line-height: 14px;
            }
            &.entered-by {
              order: 10;
              width: 50%;
              font-size: 13px;
              line-height: 14px;
            }
            // line #5
            &.is-qod-issue {
              order: 11;
              width: 50%;
              font-size: 13px;
              line-height: 14px;
            }
            &.is-imported {
              order: 12;
              width: 50%;
              font-size: 13px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
`;

ImportIssuesTable.propTypes = {
  data: PropTypes.array.isRequired,
  checkedIssues: PropTypes.array.isRequired,
  onCheckIssues: PropTypes.func.isRequired,
};

export default ImportIssuesTable;
