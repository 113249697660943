import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import CommentIcon from '../../../../../assets/images/issueTable/comment-white.svg';
import VideoIcon from '../../../../../assets/images/issueTable/video.svg';
import {getUserRoles, isOperatorUser, isClientServiceUser, formatDateTz} from '../../../../utils/general';
import Can from '../../../../components/Can';
import {ConflictArea} from '../../../WOConflicts/constants';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {resetFixNotesOfIssue} from '../../actions';
import {IssueStatusesOptions} from '../../utils';

const CollapsedRow = React.memo((props) => {
  const {
    row, onClickDiscuss, onClickFixNotes, onClickView, onClickEdit, onClickDelete, isEnabledInteractiveView,
    canManage, canComment, onClickUndelete, allowEditOnRequest, onClickDuplicate, isValidTimeCodeSettings
  } = props;
  let dispatch = useDispatch();
  let history = useHistory();
  const userRoles = getUserRoles();

  const handleResetFixNotes = () => {
    !!dispatch && dispatch(resetFixNotesOfIssue(row, history));
  };

  return (
    <CollapsedRowStyled>
      {!!canComment &&
        <Button
          onClick={() => onClickDiscuss(row)}
          >
          <img src={CommentIcon} alt="discuss"/> <span>Discuss Issue</span>
        </Button>
      }
      {
        !isOperatorUser() && !!canManage && !row.IsDeleted &&
          <Button
            onClick={() => onClickFixNotes(row)}
            >
            <span className="d-fl">
              <i className="fas fa-circle-check"/>
              Fix Notes
            </span>
          </Button>
      }
      {
        isClientServiceUser() && !row.IsDeleted && !!row.ClientStatusChangedBy && IssueStatusesOptions.some(o => o.value === row.ClientStatusID) &&
          <Button
            onClick={handleResetFixNotes}
          >
            <span className="d-fl">
              <i className="fas fa-circle-xmark"/>
              Reset Fix Notes
            </span>
          </Button>
      }
      {
        isEnabledInteractiveView ? (
          <Button
            title="Go to issue in Live View"
            onClick={() => onClickView(row)}
            >
            <img src={VideoIcon} alt="view"/> <span>Live View</span>
          </Button>
        ) : null
      }
      <Can
        roles={userRoles}
        perform="report-details:show-conflicts-issue-btn"
        data={row}
        yes={() => (
          <Button
            title="Click to view and resolve conflicts"
            onClick={() => {
              try {
                history.push(`/conflicts/${ConflictArea.ISSUE}/${row.IssueID}`);
              } catch {}
            }}
            >
            <span><i className="far fa-not-equal"/>Conflicts View</span>
          </Button>
        )}
      />
      {!!onClickEdit &&
        <Can
          roles={userRoles}
          perform="report-details:show-edit-issue-btn"
          data={{...row, allowEditOnRequest}}
          yes={() => (
            <Button
              title={isValidTimeCodeSettings ? 'Click to edit Issue' : 'Editing an issue is disallowed until MEMFIS sends START OF FILE and frame rate for this request'}
              onClick={() => onClickEdit(row)}
              disabled={!isValidTimeCodeSettings}
              >
              <span><i className="far fa-edit"/>Edit Issue</span>
            </Button>
          )}
        />
      }
      {!!onClickDuplicate &&
        <Can
          roles={userRoles}
          perform="report-details:show-edit-issue-btn"
          data={{...row, allowEditOnRequest}}
          yes={() => (
            <Button
              title={isValidTimeCodeSettings ? 'Click to duplicate Issue' : 'Issue duplication is disallowed until MEMFIS sends START OF FILE and frame rate for this request'}
              onClick={() => onClickDuplicate(row)}
              disabled={!isValidTimeCodeSettings}
              >
              <span>
                <i
                  className="far fa-clone"
                />
                Duplicate Issue
              </span>
            </Button>
          )}
        />
      }
      {row.IsDeleted ?
        !!onClickUndelete &&
          <Can
            roles={userRoles}
            perform="report-details:show-delete-issue-btn"
            data={row.IsQodIssue}
            yes={() => (
              <Button
                title="Click to undelete Issue"
                onClick={() => onClickUndelete(row)}
                >
                <span><i className="far fa-trash-restore"/>Undelete Issue</span>
              </Button>
            )}
          /> :
            !!onClickDelete &&
              <Can
                roles={userRoles}
                perform="report-details:show-delete-issue-btn"
                data={row.IsQodIssue}
                yes={() => (
                  <Button
                    title="Click to delete Issue"
                    onClick={() => onClickDelete(row)}
                    >
                    <span><i className="far fa-trash"/>Delete Issue</span>
                  </Button>
                )}
              />
      }
      <Can
        roles={userRoles}
        perform="report-details:show-more-info-issue-btn"
        yes={() => (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={(
              <StyledPopover
                id="issue-overlay"
                title="Issue Info"
              >
                {['CreatedBy', 'CreatedOn', 'ModifiedBy', 'ModifiedOn'].map(f => (
                  <dl
                    key={f}
                  >
                    <dt>
                      {f.replace(/([A-Z]|[0-9]+)/g, ' $1')}
                    </dt>
                    <dd
                      className={row[f] ? undefined: 'no-val'}
                    >
                      {(['CreatedOn', 'ModifiedOn'].includes(f) ? formatDateTz(row[f], '', 'll HH:mm A z') : row[f]) || 'N/A'}
                    </dd>
                  </dl>
                ))}
              </StyledPopover>
            )}
          >
            <Button
              title="Click to view more info"
            >
              <span className="d-fl">
                <i
                  className="far fa-circle-info"
                />
                More Info
              </span>
            </Button>
          </OverlayTrigger>
        )}
      />
    </CollapsedRowStyled>
  );
});

const StyledPopover = styled(Popover)`
  border-radius: 4px;
  .popover-title {
    border-radius: 3px 3px 0 0;
    font-size: 13px;
  }
  .popover-content {
    border-radius: 4px;
    color: rgb(29,28,29);
    font-size: 12px;
    dl {
      margin: 0;
      + dl {
        margin-top: 15px;
      }
    }
    .no-val {
      opacity: 0.65;
    }
  }
`;

const CollapsedRowStyled = styled.div`
  background-color: #575757;
  padding: 4px;

  .btn {
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    font-size: 10px;
    &:hover, &:focus {
      background-color: transparent;
    }
    &:disabled {
      cursor: default;
    }
    img {
      margin-right: 5px;
    }
    i {
      font-size: 13px;
      &.fa-not-equal {
        margin-right: 6px;
        padding-left: 3px;
      }
      &.fa-edit, &.fa-clone {
        margin-right: 7px;
        padding-left: 3px;
      }
      &.fa-trash, &.fa-trash-restore {
        margin-right: 6px;
        @media (max-width: 767px) {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
      &.fa-circle-check {
        font-size: 16px;
      }
    }
    .d-fl {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 6px;
      i {
        font-size: 16px;
      }
    }

    .submit-replacement-modal & {
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

CollapsedRow.defaultProps = {
  canComment: true,
  canManage: true,
};

CollapsedRow.propTypes = {
  row: PropTypes.object,
  canComment: PropTypes.bool,
  canManage: PropTypes.bool,
  onClickDiscuss: PropTypes.func,
  onClickFixNotes: PropTypes.func,
  onClickView: PropTypes.func,
  isEnabledInteractiveView: PropTypes.bool
};

export default CollapsedRow;
