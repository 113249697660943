import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import {FormGroup, ControlLabel} from 'react-bootstrap';
import SelectStyled from '../../../components/filters/SelectStyled';
import expandImg from '../../../../assets/images/expand2.svg';
import configureImg from '../../../../assets/images/dashboard/configure.svg';

class BlockHeader extends Component {
  onChange = e => {
    const {dropDown} = this.props;
    if (e.value !== (dropDown.value.value ? dropDown.value.value : dropDown.value)) {
      dropDown.onChange(e);
    }
  };

  render() {
    const {className, title, legend, dropDown, configureBtn} = this.props;
    return (
      <BlockHeaderStyled className={`clearfix ${className || ''}${legend ? ' mobile-two-lines' : ''}`}>
        <div className="title">{title}</div>
        <div className="right">
          {legend ? (
            <LegendStyled>
              <div><i className="at-risk icon"/> At Risk</div>
              <div><i className="overdue icon"/> Overdue</div>
            </LegendStyled>
          ) : null}
          {dropDown ? (
            <FilterSelectStyled>
              {dropDown.label ?
                <ControlLabel>{dropDown.label}:</ControlLabel> : null}
              <div className={dropDown.label ? 'bold' : null}>
                <Select
                  value={dropDown.value}
                  options={dropDown.options}
                  onChange={this.onChange}
                  searchable={false}
                />
              </div>
            </FilterSelectStyled>
          ) : null}
          {configureBtn ? (
            <ConfigureStyled src={configureImg} alt="" onClick={configureBtn.onClick}/>
          ) : null}
        </div>
        {legend ? <hr/> : null}
      </BlockHeaderStyled>
    );
  }
}

const BlockHeaderStyled = styled(SelectStyled)`
  align-items: center;
  background: #FFFFFF;
  border-radius: 5px 5px 0 0;
  color: #282828;
  height: 68px;
  display: flex;
  padding: 15px 20px;
  .clearfix {
    clear: both;
  }
  .title {
    font-size: 13px;
    font-weight: bold;
    line-height: 14px;
  }
  .right {
    margin-left: auto;
    display: flex;
  }

  hr {
    display: none;
  }

  @media (max-width: 991px) {
    border-radius: 3px 3px 0 0;
    padding: 10px 15px 10px 24px;
    height: 55px;

    &.block-header-request-queue {
      padding-left: 15px;
    }

    &.mobile-two-lines {
      padding-bottom: 46px;
      height: 91px;
      hr {
        display: block;
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        margin: 0;
        border-color: #EFEFEF;
      }
    }
  }
`;

const LegendStyled = styled.div`
  color: #282828;
  display: flex;
  font-size: 10px;
  margin-right: 30px;
  padding-top: 7px;
  div {
    margin-left: 24px;
    i {
      border-radius: 3px;
      display: inline-block;
      margin: 0 3px 2px 0;
      height: 4px;
      width: 19px;
      &.at-risk {
        background-color: #FFC737;
      }
      &.overdue {
        background-color: #E20606;
      }
    }
  }

  @media (max-width: 991px) {
    .box .mobile-two-lines & {
      position: absolute;
      top: 58px;
      left: 0;
      margin: 0;
    }
  }
`;

const FilterSelectStyled = styled(FormGroup)`
  margin: 0;
  display: flex;
  .control-label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 27px;
    margin: 0;
  }

  .Select.has-value.Select--single .Select-control,
  .Select.Select--single .Select-control {
    background: transparent;
    border: none;
    min-width: 100px;
    .Select-multi-value-wrapper {
      width: 86px;
      .Select-value .Select-value-label {
        font-size: 13px !important;
      }
    }  
    .Select-arrow-zone {
      .Select-arrow {
        background-image: url(${expandImg});
        margin-top: 9px;
      }

      @media (max-width: 991px) {
        padding-right: 0;
        .Select-arrow {
          min-width: 20px;
        }
      }
    }
  }
  
  .bold {
    .Select-placeholder,
    .Select-value {
      font-size: 13px !important;
      .Select-value-label {
        font-weight: bold !important;
      }
    }
  }

  @media (max-width: 991px) {
    .box .mobile-two-lines & {
      position: absolute;
      top: 58px;
      right: 15px;
      margin: 0;
    }
  }
`;

const ConfigureStyled = styled.img`
  cursor: pointer;
  margin-left: 25px;
  margin-right: 10px;

  @media (max-width: 991px) {
    margin-right: 3px;
  }
`;

BlockHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  legend: PropTypes.bool,
  dropDown: PropTypes.object,
  configureBtn: PropTypes.object
};

export default BlockHeader;
