/* eslint no-native-reassign: "off" */
import 'bootstrap/dist/css/bootstrap.css';
import 'react-select/dist/react-select.css';
import 'react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-lightbox/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './assets/fonts/fontawesome/css/all.css';

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {syncHistoryWithStore} from 'react-router-redux';
import Cookies from 'js-cookie';
import configureStore from './app/store';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import PrivateRoute from './app/components/PrivateRoute';
import Login from './app/pages/Login/container';
import Dashboard from './app/pages/Dashboard/container';
import Requests from './app/pages/Requests/container';
import NewRequest from './app/pages/NewRequest/container';
import SeasonDetails from './app/pages/SeasonDetails/container';
import ReportDetails from './app/pages/ReportDetails/container';
import Analytics from './app/pages/Analytics/container';
import RequestByUUID from './app/pages/RequestByUUID/container';
import RequestByWOMemfis from './app/pages/RequestByWOMemfis/container';
import {ConflictArea} from './app/pages/WOConflicts/constants';
import {getCurrentDomainObj, buildRedirectUrlToLoginPage} from './app/utils/general';
import config from './config';
import GlobalStyles from './indexStyled';
import $ from 'jquery';
import axiosInstance from './app/utils/axios';
import * as FullStory from '@fullstory/browser';
import TagManager from 'react-gtm-module';
import smartlookClient from 'smartlook-client';

import {makeAsyncComponent} from './app/components/AsyncLoad';

const LazyProjects = React.lazy(() => import('./app/pages/Projects/container'));
const LazyQodRequestDetails = React.lazy(() => import('./app/pages/RequestDetails/container'));
const LazySettings = React.lazy(() => import('./app/pages/Settings/container'));
const LazyAdmin = React.lazy(() => import('./app/pages/Admin/container'));
const LazyAdminAnalytics = React.lazy(() => import('./app/pages/Admin/Analytics/container'));
const LazyAdminOperatorSchedule = React.lazy(() => import('./app/pages/Admin/OperatorSchedule/container'));
const LazyWOConflicts = React.lazy(() => import('./app/pages/WOConflicts/container'));
const LazyConvertManualWO = React.lazy(() => import('./app/pages/ConvertManualWO/container'));
const LazyArtworkAssets = React.lazy(() => import('./app/pages/Artwork/Assets/container'));
const LazyArtworkAssetDetails = React.lazy(() => import('./app/pages/Artwork/AssetDetails/container'));
const LazyArtworkDashboard = React.lazy(() => import('./app/pages/Artwork/Dashboard/container'));
const LazyArtworkRequestQueue = React.lazy(() => import('./app/pages/Artwork/RequestQueue/container'));
const LazyArtworkAdminParameters = React.lazy(() => import('./app/pages/Artwork/Admin/Parameters/container'));
const LazyArtworkAdminSpecs = React.lazy(() => import('./app/pages/Artwork/Admin/Specs/container'));
const LazyQuoteProject = React.lazy(() => import('./app/pages/QuoteProject/container'));
const LazyQuoteClientOnboardingForm = React.lazy(() => import('./app/pages/QuoteClientOnboardingForm/container'));
const LazyNetflixRequestDetails = React.lazy(() => import('./app/pages/Netflix/RequestDetails/container'));
const LazyNetflixAdminSteps = React.lazy(() => import('./app/pages/Netflix/Admin/Steps/container'));
const LazyAutoQcSpecs = React.lazy(() => import('./app/pages/Admin/AutoQcSpecs/container'));

const Projects = makeAsyncComponent(LazyProjects);
const QodRequestDetails = makeAsyncComponent(LazyQodRequestDetails);
const Settings = makeAsyncComponent(LazySettings);
const Admin = makeAsyncComponent(LazyAdmin);
const AdminAnalytics = makeAsyncComponent(LazyAdminAnalytics);
const AdminOperatorSchedule = makeAsyncComponent(LazyAdminOperatorSchedule);
const WOConflicts = makeAsyncComponent(LazyWOConflicts);
const ConvertManualWO = makeAsyncComponent(LazyConvertManualWO);
const ArtworkAssets = makeAsyncComponent(LazyArtworkAssets);
const ArtworkAssetDetails = makeAsyncComponent(LazyArtworkAssetDetails);
const ArtworkDashboard = makeAsyncComponent(LazyArtworkDashboard);
const ArtworkRequestQueue = makeAsyncComponent(LazyArtworkRequestQueue);
const ArtworkAdminParameters = makeAsyncComponent(LazyArtworkAdminParameters);
const ArtworkAdminSpecs = makeAsyncComponent(LazyArtworkAdminSpecs);
const QuoteProject = makeAsyncComponent(LazyQuoteProject);
const QuoteClientOnboardingForm = makeAsyncComponent(LazyQuoteClientOnboardingForm);
const NetflixRequestDetails = makeAsyncComponent(LazyNetflixRequestDetails);
const NetflixAdminSteps = makeAsyncComponent(LazyNetflixAdminSteps);
const AutoQcSpecs = makeAsyncComponent(LazyAutoQcSpecs);

if (process.env.NODE_ENV === 'production') {
  console.warn = () => {};
  console.error = () => {};
}

$.ajaxSetup({
  headers: {
    Authorization: Cookies.get('__auth', getCurrentDomainObj())
  },
  statusCode: {
    401: () => {
      if (window.location.pathname !== '/login') {
        !!config.fullStoryOrgId && FullStory.anonymize();
        window.location.href = buildRedirectUrlToLoginPage();
      }
    }
  },
  beforeSend: function(xhr, data) {
    const accessToken = Cookies.get('__auth', getCurrentDomainObj());
    accessToken && xhr.setRequestHeader('Authorization', accessToken);
  }
});

function refreshTokens() {
  const result = $.ajax({
    method: 'POST',
    alwaysAllowed: true,
    refreshRetry: true,
    async : false,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    headers: {
      Authorization: Cookies.get('__auth', getCurrentDomainObj())
    },
    url: `${config.apiUrl}/auth/refresh-token`,
    xhrFields: {
      withCredentials: true
    }
  }).fail(error => {
    !!config.fullStoryOrgId && FullStory.anonymize();
    Cookies.remove('__auth', getCurrentDomainObj());
    window.location.href = buildRedirectUrlToLoginPage();
  });
  return result;
}

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
  if (!Cookies.get('__studio', getCurrentDomainObj()) && options.alwaysAllowed !== true) {
    jqXHR.abort();
    return;
  }

  let payload = ''
  let tokenData = {}

  if (options.refreshRetry === true) {
      return;
  }

  const accessToken = Cookies.get('__auth', getCurrentDomainObj());
  if (accessToken) {
    try {
      payload = accessToken.split('.')[1]
      if (payload) {
        tokenData = JSON.parse(atob(payload))
      }
    } catch (error) {
      !!config.fullStoryOrgId && FullStory.anonymize();
      window.location.href = buildRedirectUrlToLoginPage();
    }

    if (!tokenData) {
      return;
    }

    const tokenExpDate = tokenData.exp || 0;
    const accessTokenExpDate = Math.floor(tokenExpDate / 1000);

    const nowTime = Math.floor(new Date().getTime() / 1000)

    const isAccessTokenExpired = (nowTime - accessTokenExpDate) > 0;

    if (isAccessTokenExpired) {
      const newTokenData = refreshTokens();

      if (config.smartlookId) {
        const roles = Cookies.get('__role', getCurrentDomainObj());
        if (roles && (roles.indexOf(config.roles.CLIENT_SERVICE) >= 0 || roles.indexOf(config.roles.OPERATOR) >= 0 || roles.indexOf(config.roles.OPS_MANAGER) >= 0)) {
          smartlookClient.record({ forms: true, numbers: true, emails: true, ips: true });
        }

        const userName = Cookies.get('__username', getCurrentDomainObj());
        const userEmail = Cookies.get('__email', getCurrentDomainObj());
        userEmail && smartlookClient.identify(userEmail, {
          name: userName,
          userEmail
          // other custom properties
        });
      }

      const authorization = `Bearer${newTokenData.responseJSON.access_token}`;

      axiosInstance.defaults.headers.common.Authorization = authorization;

      Cookies.remove('__auth', getCurrentDomainObj());
      Cookies.set('__auth', authorization, getCurrentDomainObj());
    }
  }
  return jqXHR;
});

const role = Cookies.get('__role', getCurrentDomainObj());
if (role && !role.match(/^\[.*\]$/)) {
  Cookies.remove('__role', getCurrentDomainObj());
  Cookies.set('__role', [role], getCurrentDomainObj());
}
const avatar = Cookies.get('__avatar', getCurrentDomainObj());
const store = configureStore({
  user: {
    name: Cookies.get('__username', getCurrentDomainObj()),
    avatar: avatar ? `${avatar.replace(/[?]rnd=.*$/, '')}?rnd=${Math.random()}` : avatar,
    phoneNumber: Cookies.get('__phone_number', getCurrentDomainObj()),
    email: Cookies.get('__email', getCurrentDomainObj()),
    role: role ? JSON.parse(Cookies.get('__role', getCurrentDomainObj())) : [],
    studio: Cookies.get('__studio', getCurrentDomainObj())
  }
});

const history = syncHistoryWithStore(createBrowserHistory(), store);

!!config.fullStoryOrgId && FullStory.init({orgId: config.fullStoryOrgId});

!!config.gtmId && TagManager.initialize({gtmId: config.gtmId});

!!config.smartlookId && smartlookClient.init(config.smartlookId);

const ROLE_STUDIO = config.roles.STUDIO;
const ROLE_CLIENT_SERVICE = config.roles.CLIENT_SERVICE;
const ROLE_PRODUCTION = config.roles.PRODUCTION;
const ROLE_OPERATOR = config.roles.OPERATOR;
const ROLE_OPS_MANAGER = config.roles.OPS_MANAGER;
const ROLE_FINISHING_HOUSE = config.roles.FINISHING_HOUSE;
const ROLE_DW_MANAGER = config.roles.DW_MANAGER;
const ROLE_ARTWORK_STUDIO = config.roles.ARTWORK_STUDIO;
const ROLE_ARTWORK_POST_PARTNER = config.roles.ARTWORK_POST_PARTNER;
const ROLE_ARTWORK_OPERATOR = config.roles.ARTWORK_OPERATOR;
const ROLE_ARTWORK_MANAGER = config.roles.ARTWORK_MANAGER;

const ROLES_CLIENTS = [ROLE_STUDIO, ROLE_PRODUCTION, ROLE_FINISHING_HOUSE];
const ROLES_MANAGERS = [ROLE_CLIENT_SERVICE, ROLE_OPS_MANAGER, ROLE_DW_MANAGER];
const ROLES_ARTWORK_CLIENTS = [ROLE_ARTWORK_STUDIO, ROLE_ARTWORK_POST_PARTNER];

render(
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/forgot-password" component={Login}/>
        <Route exact path="/create-password" component={Login}/>
        <Route
          exact
          path="/quote/onboarding-form"
          component={QuoteClientOnboardingForm}
        />

        <PrivateRoute
          exact
          path="/"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ...ROLES_ARTWORK_CLIENTS]}
          component={Dashboard}
          />
        <PrivateRoute
          exact
          path="/projects"
          resource={ROLES_MANAGERS}
          component={Projects}
        />
        <PrivateRoute
          exact
          path="/requests"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ROLE_OPERATOR]}
          component={Requests}
          />
        <PrivateRoute
          exact
          path="/requests/new"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS]}
          component={NewRequest}
          />
        <PrivateRoute
          exact
          path="/requests/:requestId"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ROLE_OPERATOR]}
          component={ReportDetails}
          />
        <PrivateRoute
          exact
          path="/requests/seasons/:requestId"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS]}
          component={SeasonDetails}
        />
        <PrivateRoute
          exact
          path="/requests/details/:requestId([0-9]+|WO[0-9]{1,10})"
          resource={ROLES_MANAGERS}
          component={QodRequestDetails}
        />
        <PrivateRoute
          exact
          path="/requests/details/:requestId([0-9a-z-]{12,36})"
          resource={[
            config.roles.NETFLIX_INTERNAL
          ]}
          component={NetflixRequestDetails}
        />
        <PrivateRoute
          exact
          path="/requests/uuid/:requestUUID"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ROLE_OPERATOR]}
          component={RequestByUUID}
        />
        <PrivateRoute
          exact
          path="/requests/wo/:woID"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ROLE_OPERATOR]}
          component={RequestByWOMemfis}
        />
        <PrivateRoute
          exact
          path="/requests/quote-project/:quoteProjectId"
          resource={[ROLE_CLIENT_SERVICE]}
          component={QuoteProject}
        />
        <PrivateRoute
          exact
          path="/analytics"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ...ROLES_ARTWORK_CLIENTS]}
          component={Analytics}
          />
        <PrivateRoute
          exact
          path={`/settings(|/general/profile)`}
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ROLE_OPERATOR, ...ROLES_ARTWORK_CLIENTS]}
          component={Settings}
        />
        <PrivateRoute
          exact
          path="/settings/general/notifications"
          resource={[...ROLES_CLIENTS, ...ROLES_MANAGERS, ...ROLES_ARTWORK_CLIENTS]}
          component={Settings}
        />
        <PrivateRoute
          exact
          path="/settings/reporting/severities"
          resource={ROLES_CLIENTS}
          component={Settings}
        />
        <PrivateRoute
          exact
          path="/settings/qc-profiles"
          resource={[...ROLES_CLIENTS, ROLE_CLIENT_SERVICE, ROLE_OPS_MANAGER]}
          component={Settings}
        />
        <PrivateRoute
          exact
          path="/settings/schedules"
          resource={ROLES_CLIENTS}
          component={Settings}
        />
        <PrivateRoute
          path="/admin/:category(user)"
          resource={[
            config.roles.MANAGE_USERS,
            config.roles.MANAGE_USERS__PERMISSIONS,
            config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY,
          ]}
          component={Admin}
        />
        <PrivateRoute
          path="/admin/:category(company|distributor|series|spec)"
          resource={ROLES_MANAGERS}
          component={Admin}
        />
        <PrivateRoute
          path="/admin/convert-manual-wo"
          resource={ROLES_MANAGERS}
          component={ConvertManualWO}
        />
        <PrivateRoute
          exact
          path={`/conflicts/:conflictArea(${ConflictArea.ISSUE}|${ConflictArea.WORK_ODER}|${ConflictArea.WO_STEP})/:id(\\d+)`}
          resource={ROLES_MANAGERS}
          component={WOConflicts}
        />
        <PrivateRoute
          exact
          path="/admin/analytics"
          resource={[
            config.roles.ADMIN,
          ]}
          component={AdminAnalytics}
        />
        <PrivateRoute
            path="/admin/autoqc-specs"
            resource={ROLES_MANAGERS}
            component={AutoQcSpecs}
        />
        <PrivateRoute
          exact
          path="/admin/operator-schedule/:scheduleType(master|weekly)"
          resource={[
            config.roles.MANAGE_OPERATOR_SCHEDULE,
          ]}
          component={AdminOperatorSchedule}
        />
        <PrivateRoute
          exact
          path="/admin/netflix/steps"
          resource={[
            config.roles.NETFLIX_INTERNAL
          ]}
          component={NetflixAdminSteps}
        />
        <PrivateRoute
          exact
          path="/admin/artwork/parameters"
          resource={[
            ROLE_ARTWORK_MANAGER,
          ]}
          component={ArtworkAdminParameters}
        />
        <PrivateRoute
          exact
          path="/admin/artwork/specs"
          resource={[
            ROLE_ARTWORK_MANAGER,
          ]}
          component={ArtworkAdminSpecs}
        />
        <PrivateRoute
          exact
          path="/(assets|report)"
          resource={[...ROLES_ARTWORK_CLIENTS, ROLE_ARTWORK_OPERATOR, ROLE_ARTWORK_MANAGER]}
          component={ArtworkAssets}
        />
        <PrivateRoute
          exact
          path="/assets/:assetId([0-9]+)"
          resource={[...ROLES_ARTWORK_CLIENTS, ROLE_ARTWORK_OPERATOR, ROLE_ARTWORK_MANAGER]}
          component={ArtworkAssetDetails}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          resource={ROLES_ARTWORK_CLIENTS}
          component={ArtworkDashboard}
        />
        <PrivateRoute
          exact
          path="/artwork/:category(my-requests)"
          resource={[ROLE_ARTWORK_OPERATOR]}
          component={ArtworkRequestQueue}
        />
        <PrivateRoute
          exact
          path="/artwork/:category(request-queue)"
          resource={[ROLE_ARTWORK_OPERATOR, ROLE_ARTWORK_MANAGER]}
          component={ArtworkRequestQueue}
        />
        <Redirect to="/login" />
      </Switch>
      <GlobalStyles/>
    </Router>
  </Provider>,
  document.getElementById('root')
);
