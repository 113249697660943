import styled from 'styled-components';

export const StyledStudiosTable = styled.div`
  position: relative;
  margin: 0 0 20px;
  .table-info {
    margin: 0 0 11px;
    font-style: normal;
    color: #282828;
    .studio-select-text, .studio-partner-select-text {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      & + div {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        opacity: 0.5;
        margin-top: 4px;
      }
    }
  }
  .table-buttons-container {
    margin: 2px 0 0;
    width: 222px;
    max-width: 100%;
    float: right;
    z-index: 1;
    position: relative;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .form-group, .icon-filter, .img-filter {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .icon-filter {
      font-size: 16px;
      opacity: 0.5;
      padding: 0 16px 0 21px;
    }

    .img-filter {
      width: 16px;
      max-height: 31px;
      margin: 0 16px 0 21px;
    }

    .form-group {
      width: 100%;
      margin-bottom: 25px;

      .form-control {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 29px;
        color: #282828;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: none;
        padding: 0 10px 0 29px;
        height: auto;
      }
    }

    .img-search {
      position: absolute;
      top: 5px;
      left: 4px;
      width: 20px;
      height: 20px;
      z-index: 4;
    }
  }
  .table-block {
    > .table {
      border-collapse: separate;
      color: #282828;
      margin: 0;

      > thead > tr > th {
        border: none;
        padding: 0;
        height: 0;
      }

      > tbody {
        > tr {
          cursor: pointer;

          .icon-circle-check {
            font-size: 21.6px;
            color: #F3F3F3;
          }

          &:hover {
            .icon-circle-check {
              color: #dadada;
            }
          }

          &.selected-row {
            background-color: #F9F9F9;
            box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);

            .icon-circle-check {
              color: #E75593;
            }
          }

          &.disabled-row {
            pointer-events: none;
            &.selected-row {
              .icon-circle-check {
                color: #A1CD86;
              }
            }
          }

          > td {
            padding-top: 18px;
            vertical-align: top;
            color: #282828;
            border-top: 1px solid #e5e5e5;
            padding-left: 0;
            padding-right: 16px;

            &:nth-child(2) {
              width: 183px;
              font-weight: 700;
              font-size: 15px;
              line-height: 16px;
            }

            &:nth-child(3) {
              font-weight: 500;
              font-size: 13px;
              line-height: 14px;

              .row:last-child {
                padding-top: 3px;
                font-weight: 300;
                line-height: 17px;
                opacity: 0.7;
              }
            }

            &:first-child {
              width: 90px;
              padding: 16px 27px 31px 26px;
            }

            &:last-child {
              width: 151px;
              padding-top: 19px;
              padding-left: 16px;
              text-align: center;

              img {
                max-width: 99px;
                max-height: 32px;
              }
            }

            &.data-table-empty {
              cursor: default;
              width: 100% !important;
              text-align: center;
              font-size: 13px;
            }
          }

          &:last-child {
            > td {
              border-bottom: 1px solid #e5e5e5;
            }
          }
        }
      }
    }
  }
`;

export const StyledSearchImg = styled.img`
  position: absolute;
  top: 5px;
  left: 4px;
  width: 20px;
  height: 20px;
  z-index: 4;

   + .form-group {
    position: relative;

    .form-control.search-title-input {
      &.empty {
        + i {
          display: none !important;
        }
      }

      + i {
        position: absolute;
        top: 8px;
        right: 6px;
        font-size: 14px;
        color: #555;
        cursor: pointer;
        z-index: 3;
        &:hover {
          color: #222;
        }
      }
    }
  }
`;