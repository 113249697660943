import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LinkCell = (props) => {
    const {value, row, className, link_field} = props;
    const url = row[link_field];
    return (
        <StyledLinkCell
            className={className}
        >
            <a title="Open Link" href={url} target="_blank" rel="noopener noreferrer">{value}</a>
        </StyledLinkCell>
    );
};

const StyledLinkCell = styled.td`
  a {
    color: #29A4CB;
    font-weight: bold;
    &:hover {
      color: #228daf;
    }
  }
`;

LinkCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string
};

export default LinkCell;
