import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Issue extends Component {
  getUserInitials = () => {
    const {Username} = this.props.issue;
    const splittedUsername = (typeof Username === 'string') ? Username.split(' ') : [];
    const [name, lastName] = splittedUsername;
    const nameFirstChar = name ? name.charAt(0).toUpperCase() : '';
    const lastNameFirstChar = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${nameFirstChar}${lastNameFirstChar}`;
  };

  render() {
    const {TimeCodeStart, TimeCodeEnd, Description, IssueType} = this.props.issue;
    const userInitials = this.getUserInitials();
    const issueDescription = `${IssueType}${Description ? `: ${Description}` : ''}`;

    return (
      <StyledIssue>
        {userInitials ?
          <div className="initials">
            {userInitials}
          </div> : null
        }
        <strong>{TimeCodeStart}{TimeCodeEnd ? ` - ${TimeCodeEnd}` : ''}</strong>
        <p title={issueDescription}>{issueDescription}</p>
      </StyledIssue>
    );
  }
}

const StyledIssue = styled.div`
  background: rgb(255, 255, 255, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #ffffff;
  margin-bottom: 22px;
  min-height: 94px;
  max-height: 110px;
  padding: 24px 18px 16px;
  position: relative;
  width: 369px;
  max-width: 100%;
  
  .initials {
    background-color: #e75592;
    border-radius: 17px;
    line-height: 33px;
    text-align: center;
    height: 33px;
    width: 33px;
    position: absolute;
    top: -17px;
    right: 29px;
  }
  
  strong {
    display: block;
    font-size: 13px;
    margin-bottom: 7px;
    opacity: 1;
  }
  p {
    display: block;
    font-size: 15px;
    line-height: 19px;
    max-height: 38px;
    overflow: hidden;
    text-transform: uppercase;
  }
`;

Issue.propTypes = {
  issue: PropTypes.object.isRequired
};

export default Issue;