import React from 'react';
import styled from 'styled-components';
import PlusIcon from '../../../../../../assets/images/plus.svg';
import MinusIcon from '../../../../../../assets/images/minus.svg';

export const ActionsCell = ({row}) => {
  return (
    <CellStyled>
      {row.request_count ? <span/> : null}
    </CellStyled>
  );
};

const CellStyled = styled.td`
  text-align: center;
  border-left: 0.5px solid rgba(0, 0, 0, 0.2) !important;
  width: 46px;
  padding-right: 0 !important;
  span {
    background: url(${PlusIcon}) no-repeat;
    display: inline-block;
    height: 16px;
    padding: 0;
    vertical-align: bottom;
    width: 17px;
    tr.open & {
      background: url(${MinusIcon}) no-repeat;
    }
  }
`;
