import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IssueTable from './ReplacementIssueTable';
import SelectFiles from '../../NewRequest/components/screens/SelectFiles';
import Loader from '../../../components/Loader';
import {SELECT_FILES_OPTIONS} from '../../NewRequest/constants';
import styled from 'styled-components';
import classNames from 'classnames';
import isMobile from 'is-mobile';

class ReplacementForm extends Component {
  timer = null;

  isShowSelectOptionsOnStart = () => {
    const {files} = this.props;
    let showSelectOptions = true;
    if (files) {
      // copied from this.handleFilesChange
      const {select_files_type_id, uploadedFiles, selectedFiles} = files;
      if (select_files_type_id) {
        if (select_files_type_id > SELECT_FILES_OPTIONS.UploadNow) {
          showSelectOptions = false;
        } else if (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && uploadedFiles && uploadedFiles.length) {
          showSelectOptions = false;
        } else if (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && selectedFiles && selectedFiles.length) {
          showSelectOptions = false;
        }
      }
    }
    return showSelectOptions;
  };

  state = {
    issues: this.props.issues || [],
    files: this.props.files || {},
    showSelectOptions: this.isShowSelectOptionsOnStart()
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {issues} = nextProps;
    if (!this.state.issues.length && issues.length) {
      this.setState({issues});
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleClickSelectFile = () => {
    this.setState({showSelectOptions: true});
  };

  handleChangeIssueStatus = (issueId, status) => {
    const {issues} = this.state;
    const issue = issues.find(i => i.IssueID === issueId);
    issue.ClientStatusID = status.value;
    this.setState({issues});
    this.props.onChange('issues', issues);
  };

  handleFilesChange = (name, value) => {
    const {files} = this.state;
    files[name] = value;

    let delayHideOptions = 3000;
    let showSelectOptions = true;
    let manualUploads = [];
    let packageIDs = [];
    let uploadLater = false;
    let mediaDelivery = false;
    const {select_files_type_id, uploadedFiles, selectedFiles} = files;
    if (select_files_type_id) {
      if (select_files_type_id > SELECT_FILES_OPTIONS.UploadNow) {
        files.uploadedFiles = undefined;
        showSelectOptions = false;
        delayHideOptions = 0;
        if (select_files_type_id === SELECT_FILES_OPTIONS.UploadLater) {
          uploadLater = true;
        } else if (select_files_type_id === SELECT_FILES_OPTIONS.PhysicalMediaDelivery) {
          mediaDelivery = true;
        }
      } else if (select_files_type_id === SELECT_FILES_OPTIONS.UploadNow && uploadedFiles && uploadedFiles.length) {
        manualUploads = [...uploadedFiles];
        showSelectOptions = false;
      } else if (select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera && selectedFiles && selectedFiles.length) {
        packageIDs = selectedFiles.map(asset => (asset.id));
        showSelectOptions = false;
      }
    }

    if (!showSelectOptions) {
      this.clearTimeout();
      this.timer = setTimeout(() => {
        this.setState({showSelectOptions: false});
        const submitReplacementModal = document.getElementsByClassName('submit-replacement-modal')[0];
        const submitReplacementModalBody = submitReplacementModal &&
          submitReplacementModal.getElementsByClassName('modal-body-custom')[0];
        if (submitReplacementModalBody) {
          submitReplacementModalBody.scrollTop = 0;
        }
      }, delayHideOptions);
    }

    this.setState({files});

    this.props.onChange('manual_uploads', manualUploads);
    this.props.onChange('package_ids', packageIDs);
    this.props.onChange('upload_later', uploadLater);
    this.props.onChange('media_delivery', mediaDelivery);
    this.props.onChange('files', files);
  };

  renderFiles() {
    const {select_files_type_id, selectedFiles, uploadedFiles} = this.state.files;
    const files = [].concat(
      selectedFiles && (!select_files_type_id || select_files_type_id === SELECT_FILES_OPTIONS.IBMAspera) ?
        selectedFiles : []
    ).concat(uploadedFiles && select_files_type_id === SELECT_FILES_OPTIONS.UploadNow ? uploadedFiles : []);
    const assetName = (!select_files_type_id || !files.length) ? null : files[0].asset_name;
    const currentFile =
     select_files_type_id === SELECT_FILES_OPTIONS.PhysicalMediaDelivery ? 'Physical Media Delivery' :
     select_files_type_id === SELECT_FILES_OPTIONS.UploadLater ? 'To be uploaded later' :
     (assetName || null);
    const {showSelectOptions} = this.state;
    const className = classNames({
      'visible-select-files-options': showSelectOptions,
      'is-mobile-version': isMobile()
    });
    return (
      <StyledFiles className={className}>
        {
          showSelectOptions ? (
            <SelectFiles
              showAsperaStatus={false}
              allowFileUpload={this.props.allowFileUpload}
              data={{...this.state.files, request_id: this.props.requestId || 0}}
              onChange={this.handleFilesChange}
              notSetDefaultUploadOption={this.props.notSetDefaultUploadOption}
            />
          ) : (
            <div className="file-button">
              <div>File/s</div>
              <div className="file-selecting">
                  <div className="current-file">
                    {currentFile}
                    {
                      select_files_type_id <= SELECT_FILES_OPTIONS.UploadNow && files.length > 1 ? (
                      <div>
                         <div className="more-files collapse">
                           {
                             files.slice(1).map((a, i) => (<div key={`a-${i + 1}`}>{a.asset_name}</div>))
                           }
                         </div>
                         <div
                           className="more-files-labels collapsed"
                           data-toggle="collapse"
                           data-target=".more-files"
                           >
                           <span className="list-expand-label">+ {files.length - 1} more...</span>
                           <span className="list-collapse-label">collapse list</span>
                         </div>
                      </div>
                      ) : null
                    }
                  </div>
                  <div className="select-df-file" onClick={() => this.handleClickSelectFile()}>Select Different File/s</div>
              </div>
            </div>
          )
        }
      </StyledFiles>
    );
  }

  render() {
    const {
      loading, saving, onClickFixNotes, onClickDiscuss, onClickViewIssue, onClickIssueImage, isEnabledInteractiveView,
    } = this.props;
    const {issues} = this.state;
    return (
      <div className="replacement-form">
        {(loading || saving) ? <Loader/> : null}
        {this.renderFiles()}
        <div>You can select a custom reaction for each issue. We will update these issues when we create a replacement.</div>
        <IssueTable
          loading={loading}
          saving={saving}
          data={issues}
          onSelectStatus={this.handleChangeIssueStatus}
          onClickFixNotes={onClickFixNotes}
          onClickDiscuss={onClickDiscuss}
          onClickView={onClickViewIssue}
          onClickImage={onClickIssueImage}
          isEnabledInteractiveView={isEnabledInteractiveView}
          />
      </div>
    );
  }
}

const StyledFiles = styled.div`
  background: #f3f3f3;
  border-radius: 3px;
  padding: 16px 20px 2px;
  margin-bottom: 17px;
  > div {
    &.file-button {
      padding-bottom: 19px;
      * {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: #282828;
      }
      > div:first-child {
        opacity: 0.5;
        margin-bottom: 5px;
      }
      .file-selecting {
        display: flex;
        align-items: flex-start;

        .list-collapse-label {
          display: none;
        }
        .more-files-labels {
          padding-top: 2px;
          span {
            font-weight: bold;
            font-size: 10px;
            line-height: 11px;
            color: #0DB4D9;
            padding: 0 0 0 1px;
            &:hover {
              color: #004A8D;
              cursor: pointer;
            }
          }
        }
        .more-files {
          div {
            padding-top: 7px;
          }
          &.collapse.in {
            + .more-files-labels {
              padding-top: 7px;
              .list-expand-label {
                display: none;
              }
              .list-collapse-label {
                display: unset;
              }
            }
          }
          &.collapsing {
            + .more-files-labels {
              span {
                visibility: hidden;
              }
            }
          }
        }
      }
      .current-file {
        display: inline-block;
        opacity: 1;
        padding-right: 16px;
      }
      .select-df-file {
        display: inline-block;
        font-weight: bold;
        color: #0DB4D9;
        opacity: 1;
        &:hover {
          color: #004A8D;
          cursor: pointer;
        }
      }
    }
    &.select-files {
      .select-files-header {
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        color: #282828;
        opacity: 0.5;
      }
      .select-file-options {
        padding-top: 0;
        .select-file-option {
          &.option-upload-now {
            .btn {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 38px;
              max-height: 38px;
              text-align: center;
              border: none;
              outline: none;
              box-shadow: none;
              border-radius: 100px;
              padding: 0 36px;
              background: #33333F;
              opacity: 0.8;
              &:not([disabled]):hover {
                opacity: 1;
              }
              margin-bottom: 10px;
              @media (min-width: 992px) {
                margin-top: 20px;
              }
            }
          }
          &.option-physical-media-delivery {
            .qc-type-line {
              display: none;
            }
          }
          > .row:first-child {
            margin-top: 19px !important;
          }
          .qc-types-item {
            &:not(.qc-type-selected):hover {
              .icon-circle-check {
                color: #dadada;
              }
            }
          }
          .requires-ibm-aspera {
            padding-top: 20px;
            img {
              margin-bottom: 18px;
            }
          }
        }

        .file-upload-status {
          margin-top: -19px;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .select-files .select-file-options:not(.lg) {
      padding-right: 21px;
    }
  }

  @media (max-width: 767px) {
    .submit-replacement-modal & {
      background: #fff;
      padding: 0;
      margin: 0;

      &.visible-select-files-options + .table-container {
        display: none;
      }

      > div {
        &.file-button {
          background: rgba(196, 196, 196, 0.4);
          padding: 18px 10px 21px 23px;
          margin: 0 0 26px;

          * {
            font-size: 15px;
            line-height: 21px;
          }
          > div:first-child {
            font-size: 13px;
            line-height: 14px;
            padding: 1px 0 2px;
          }
          .file-selecting {
            flex-wrap: wrap;
            .more-files-labels {
              span {
                font-size: 11px;
                line-height: 12px;
              }
            }
          }
          .current-file {
            line-height: 21px;
            word-break: break-all;
            padding-bottom: 12px;
          }
          .select-df-file {
            line-height: 17px;
            margin: 5px 0;
          }
        }

        &.select-files {
          background: #fff;
          padding: 18px 0 0 23px;
          margin: 0;

          .select-files-header {
            font-size: 13px;
            line-height: 14px;
            padding: 1px 0 2px;
          }

          .select-file-options {
            .select-file-option {
              .qc-types-item {
                margin-right: 25px;
              }
              .select-files-option-data {
                width: 100%;
              }
              .requires-ibm-aspera {
                width: 100%;
                padding: 0 0 0 48px;
              }
            }
          }
        }
      }
    }
  }

  &.is-mobile-version {
    .select-files {
      .select-file-options {
        .select-file-option {
          &.option-upload-now {
            display: none;
          }
        }
      }
    }
  }
`;

ReplacementForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  allowFileUpload: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  files: PropTypes.object,
  requestId: PropTypes.number,
  issues: PropTypes.array.isRequired,
  onClickFixNotes: PropTypes.func,
  onClickDiscuss: PropTypes.func,
  onClickViewIssue: PropTypes.func,
  onClickIssueImage: PropTypes.func,
  isEnabledInteractiveView: PropTypes.bool,
};

export default ReplacementForm;
