import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {formatDate} from '../../../utils/general';

class VersionDetails extends Component {

  constructor(props) {
    super(props);

    this.fileNameTextRef = React.createRef();
  }

  timelineResizeObserver = new ResizeObserver(entries => {
    this.handleMuddleEllipsisFilename();
  });

  componentDidMount() {
    this.timelineResizeObserver.observe(document.querySelector('#report-details--container'));
  }

  componentWillUnmount() {
    if (this.timelineResizeObserver) {
      this.timelineResizeObserver.disconnect();
    }
  }

  handleMuddleEllipsisFilename = () => {
    const {data: {assets}} = this.props;
    const hasAssets = Boolean(assets && assets.length);
    if (this.fileNameTextRef.current && hasAssets) {
      const filenameElmWidth = this.fileNameTextRef.current.offsetWidth;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = getComputedStyle(this.fileNameTextRef.current).font;

      const filenameTextWidth = context.measureText(assets[0].AssetName).width;

      if ((filenameTextWidth - filenameElmWidth) > 5) {
        let splitAt = assets[0].AssetName.length - 7; // 7 - file extension + couple symbols at end
        let rightFilename = assets[0].AssetName.substr(splitAt);
        let leftFilename = assets[0].AssetName.substr(0, splitAt);

        while (context.measureText(leftFilename + '...' + rightFilename).width > filenameElmWidth) {
          leftFilename = assets[0].AssetName.substr(0, --splitAt);
        }
        this.fileNameTextRef.current.childNodes[1].textContent = leftFilename + '...' + rightFilename;
      } else {
        this.fileNameTextRef.current.childNodes[1].textContent = assets[0].AssetName;
      }
    }
  };

  render() {
    const {data} = this.props;
    const {assets} = data;
    const hasAssets = Boolean(assets && assets.length);
    const hasMoreAssets = hasAssets && assets.length > 1;
    let filesFormats;
    if (hasMoreAssets) {
      filesFormats = new Set(assets.map(a => (a.FileFormat)));
    }

    return hasMoreAssets ? (
      <StyledWrapDetails>
        <StyledDetails>
          <dl>
            <dt>Requested by</dt>
            <dd>{data.RequestedBy || '[none]'}</dd>
          </dl>
          <dl>
            <dt>QC Duration</dt>
            <dd>{data.qc_duration || '[none]'}</dd>
          </dl>
          <dl>
            <dt>Created On</dt>
            <dd>{formatDate(data.SubmittedOn, '', 'MMMM D, YYYY') || '[none]'}</dd>
          </dl>
        </StyledDetails>
        <StyledDetails className={`assets${hasMoreAssets ? ' has-more-assets' : ''}`}>
          <dl>
            <dt>Format</dt>
            <dd>
              {hasAssets ? assets[0].FileFormat || '[none]' : '[none]'}
              {
                hasMoreAssets && filesFormats && filesFormats.size > 1 ? (
                  <div>
                     <div className="more-assets collapse">
                       {
                         assets.slice(1).map((a, i) => (<div key={`af-${i + 1}`}>{a.FileFormat || '[none]'}</div>))
                       }
                     </div>
                  </div>
                ) : null
              }
            </dd>
          </dl>
          <dl>
            <dt>File</dt>
            <dd>
              {hasAssets ? assets[0].AssetName : '[none]'}
              {
                hasMoreAssets ? (
                  <div>
                     <div className="more-assets collapse">
                       {
                         assets.slice(1).map((a, i) => (<div key={`a-${i + 1}`}>{a.AssetName}</div>))
                       }
                     </div>
                     <div
                       className="more-assets-labels collapsed"
                       data-toggle="collapse"
                       data-target=".more-assets"
                       >
                       <span className="list-expand-label">+ {assets.length - 1} more...</span>
                       <span className="list-collapse-label">collapse list</span>
                     </div>
                  </div>
                ) : null
              }
            </dd>
          </dl>
        </StyledDetails>
      </StyledWrapDetails>
    ) : (
      <StyledWrapDetails>
        <StyledDetails>
          <dl>
            <dt>Format</dt>
            <dd>{hasAssets ? assets[0].FileFormat || '[none]' : '[none]'}</dd>
          </dl>
          <dl>
            <dt>Requested by</dt>
            <dd>{data.RequestedBy || '[none]'}</dd>
          </dl>
          <dl>
            <dt>QC Duration</dt>
            <dd>{data.qc_duration || '[none]'}</dd>
          </dl>
          <dl>
            <dt>Created On</dt>
            <dd>{formatDate(data.SubmittedOn, '', 'MMMM D, YYYY') || '[none]'}</dd>
          </dl>
          <dl ref={this.fileNameTextRef}>
            <dt>File</dt>
            <dd title={hasAssets ? assets[0].AssetName : '[none]'}>{hasAssets ? assets[0].AssetName : '[none]'}</dd>
          </dl>
        </StyledDetails>
      </StyledWrapDetails>
    );
  }
}

const StyledWrapDetails = styled.div`
  background-color: #f3f3f3;

  @media (max-width: 768px) {
    background-color: #f3f3f3;
    border: 1px solid #8f8f8f;
    margin: 0 18px;
    padding: 5px 20px 50px;
    position: relative;
      &:before {
        border-top: 1px solid #DEDEDE;
        bottom: 45px;
        content: "";
        display: inline-block;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
    
    @media (max-width: 768px) {
      border: 0;
      margin: 0 -15px;
      box-shadow: inset 0 1px 0 0 #DEDEDE;
    }
`;

const StyledDetails = styled.div`
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  font-size: 10px;
  line-height: 11px;
  padding: 0 34px;
  min-height: 55px;
  margin-right: 80px;
  dl {
    margin: 0 20px;
    flex-shrink: 0;
    dt {
      color: rgba(40, 40, 40, 0.5);
    }
    dd {
      color: #282828;
      word-break: break-word;
    }
    &:first-child {
      min-width: 62px;
    }
    &:last-child {
      flex: auto;
    }
  }
  &.assets {
    height: 44px;
    align-items: flex-start;
    padding-bottom: 26px;
    &.has-more-assets {
      min-height: 60px;
      height: auto;
    }
    .list-collapse-label {
      display: none;
    }
    .more-assets-labels {
      padding-top: 2px;
      span {
        font-weight: bold;
        font-size: 10px;
        line-height: 11px;
        color: #0DB4D9;
        padding: 0 0 0 1px;
        &:hover {
          color: #004A8D;
          cursor: pointer;
        }
      }
    }
    .more-assets {
      div {
        padding-top: 7px;
      }
      &.collapse.in {
        + .more-assets-labels {
          padding-top: 7px;
          .list-expand-label {
            display: none;
          }
          .list-collapse-label {
            display: unset;
          }
        }
      }
      &.collapsing {
        + .more-assets-labels {
          span {
            visibility: hidden;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    display: flex;
    margin: 0;
    overflow: auto;
    padding: 5px 0;
    &.assets {
      padding: 5px 0;
    }
    dl {
      margin: 0 20px 0 0;
      width: auto;
      dd {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

VersionDetails.propTypes = {
  data: PropTypes.object.isRequired
};

export default VersionDetails;
