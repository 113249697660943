import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, ControlLabel} from 'react-bootstrap';

class CheckboxAdvanced extends Component {
  handleChange = event => {
    this.props.onChange(event.target.name, event.target.checked, this.props.elem.task_id);
  };

  render() {
    const {label, name, value, disabled} = this.props;
    return (
      <FormGroup className="checkbox">
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={value}
          disabled={disabled ? 'disabled' : null}
          onChange={this.handleChange}
          />
        <ControlLabel htmlFor={name}>
          {label}
        </ControlLabel>
      </FormGroup>
    );
  }
}

CheckboxAdvanced.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  elem: PropTypes.object
};

CheckboxAdvanced.defaultProps = {
  label: '',
  name: '',
  value: false,
  onChange: () => {},
  elem: {}
};

export default CheckboxAdvanced;
