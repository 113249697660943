import React, {Component} from 'react';
import Spinner from 'react-spinner';
import PropTypes from 'prop-types';

class Loader extends Component {
  render() {
    const {className} = this.props;
    return (
      <div className={`loader-container${className ? ` ${className}` : ''}`}>
        <Spinner/>
      </div>
    );
  }
}

Loader.propTypes = {
  className: PropTypes.string
};

export default Loader;
