import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Loader from '../../../../components/Loader';
import {EstimatedCostStyled} from '../../../NewRequest/components/forms/EstimatedCost';

function CostDetailsComponent({requestQcCost, loadingCost, costDetails, costDetailsTab, activeTab, onViewTab}) {
  const useQcCost = !costDetails && typeof requestQcCost === 'number';
  const useCostDetails = costDetails && costDetails.success;
  const noValue = !(useQcCost || useCostDetails);
  return (
    <StyledCostDetails
      className={classNames({
        'col-estimated-cost': true,
        'undefined-cost-value': noValue && typeof requestQcCost === 'undefined',
      })}
    >
      {loadingCost ? <Loader/> : null}
      <EstimatedCostStyled>
        <div
          className={classNames({
            'estimated-cost-info': true,
            'no-value': noValue,
            'disabled': activeTab === costDetailsTab,
          })}
          onClick={onViewTab}
          title="View details"
        >
          Estimated Cost
          <div className="estimated-cost-value">
            {useQcCost ? `$${requestQcCost.toFixed(2)}` : useCostDetails ? `$${costDetails.estimated_cost.toFixed(2)}` : 'N/A'}
          </div>
        </div>
      </EstimatedCostStyled>
    </StyledCostDetails>
  );
}

const StyledCostDetails = styled.div`
  &.col-estimated-cost {
    padding: 0 15px 15px;
    width: fit-content;
    min-width: 125px;
    max-width: 240px;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      min-width: unset;
      max-width: 100%;
    }

    > div {
      margin: 0;

      .estimated-cost-info {
        &.no-value {
          color: #E75592;
        }
        &:hover {
          cursor: pointer;
          background-color: #f3f0f0;
          box-shadow: -2px -2px 2px 2px #f3f0f0, 2px 2px 2px 8px #f3f0f0;
        }
        &.disabled {
          pointer-events: none;
        }
      }
    }

    .loader-container {
      padding-top: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media (max-width: 767px) {
        padding-left: 40px;
        text-align: left;
      }

      + div {
        opacity: 0.5;
        min-height: 45px;
      }
    }
  }
`;

export default CostDetailsComponent;
