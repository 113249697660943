import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const ForgotPasswordButton = React.memo(({onClick}) => (
  <div className="forgot">
    <Link to="forgot-password" onClick={onClick}>forgot password?</Link>
  </div>
))

class LoginForm extends Component {
  render() {
    const {isNeedCodeTOTP, codeTOTP, onHideMessage, onChange} = this.props;

    let twoFAInstructions = null;
    if (isNeedCodeTOTP) {
      twoFAInstructions = (
        <div className="totp-instructions">
          You will need to set up two-factor authentication (2FA) in order to receive an Authentication Code and access your account.
          <ul>
            <li>Install an authenticator app to your computer or phone, such as: Authy, Google Authenticator, or Microsoft Authenticator. We recommend installing the Authy app on your mobile device.</li>
            <li>In the app of your choice, click or tap on "Add account" and take a photo of the QR code that can be found in the QOD onboarding email. This code is unique to your account.</li>
            <li>The app will generate a 6-digit code you will need to enter each time you log into QC on Demand.</li>
          </ul>
        </div>
      )
    }
    return (
      <div>
        {isNeedCodeTOTP ?
          <p>
            <label>Authentication Code</label>
            <span className="text-icon">
              <i className="fas fa-user-secret"/>
            </span>
            <input
              type="text"
              name="codeTOTP"
              placeholder=""
              autoComplete="off"
              autoFocus={true}
              value={codeTOTP}
              onChange={event => {
                onHideMessage();
                onChange(event);
              }}
            />
          </p> :
            <>
              <p>
                <label>Email</label>
                <span className="text-icon">
                  <i className="fa fa-user"/>
                </span>
                <input
                  type="email"
                  pattern="[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,8}$"
                  name="email"
                  placeholder=""
                  autoComplete="off"
                  autoFocus={true}
                  value={this.props.email}
                  onChange={onChange}
                  />
              </p>
              <p>
                <label>Password</label>
                <span className="text-icon">
                  <i className="fa fa-lock"/>
                </span>
                <input
                  type="password"
                  name="password"
                  placeholder=""
                  autoComplete="off"
                  onChange={onChange}
                  />
              </p>
            </>
        }
        <p><input type="submit" name="" value="login" onClick={this.props.onLogin}/></p>
        {!isNeedCodeTOTP &&
          <ForgotPasswordButton
            onClick={onHideMessage}
          />
        }
        { twoFAInstructions }
      </div>
    );
  }
}

LoginForm.propTypes = {
  email: PropTypes.string,
  codeTOTP: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onHideMessage: PropTypes.func.isRequired,
  isNeedCodeTOTP: PropTypes.bool.isRequired
};

export default LoginForm;
