import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ControlLabel} from 'react-bootstrap';

class RadioButtonsAdvanced extends Component {
  handleChange = event => {
    const {options} = this.props;
    const {name, dataset} = event.target;
    // get value in such way because event.target.value is always string
    const {value} = options.filter(item => item.name === dataset.name)[0];
    this.props.onChange(name, value);
  };

  render() {
    const {id, className, label, labelSeparator, name, value, disabled, options} = this.props;

    return (
      <StyledRadioButtons className={`form-group radio-buttons ${className}`}>
        <ControlLabel>{label}{labelSeparator}</ControlLabel>
        <div className="field">
          {
            options.map((item, index) => {
              const inputId = `${id || 'radio'}-${index}-${id ? 'btn' : Math.random()}`;
              return (
                <Fragment key={inputId}>
                  <input
                    id={inputId}
                    name={name}
                    data-name={item.name}
                    type="radio"
                    value={item.value}
                    checked={item.value === value}
                    disabled={(disabled || item.disabled) ? 'disabled' : null}
                    onChange={this.handleChange}
                  />
                  <ControlLabel htmlFor={inputId} title={item.title}>
                    <span className="circle"/>
                    {item.name}
                    {item.info}
                  </ControlLabel>
                </Fragment>
              );
            })
          }
        </div>
      </StyledRadioButtons>
    );
  }
}

const StyledRadioButtons = styled.div`
  clear: both;
  .field {
    label {
      cursor: pointer;
      display: flex;
      float: left;
      font-size: 12px;
      line-height: 23px;
      margin: 15px 43px 39px 0;
      .circle {
        background: #e4e4e4;
        border: 3px solid #e4e4e4;
        border-radius: 10px;
        display: inline-block;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
      }
      input {
        display: none;
        
        &:checked ~ .circle {
          background-color: #0DB4D9;
        }
      }
    }

    input {
      display: none;

      &:checked + label .circle {
        background-color: #0DB4D9;
      }
    }
  }
`;

RadioButtonsAdvanced.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

RadioButtonsAdvanced.defaultProps = {
  id: '',
  className: '',
  label: '',
  labelSeparator: ':',
  name: '',
  checked: false,
  onChange: () => {}
};

export default RadioButtonsAdvanced;
