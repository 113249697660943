import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import {Link} from 'react-router-dom';
import {Grid, Row, Col} from 'react-bootstrap';
import {
  setActiveTab,
  updateRequestField,
  updateRequestStatus,
  showEditCompanyModal
} from '../actions';
import {CONTENT_TYPES} from '../../NewRequest/constants';
import CostDetailsComponent from './forms/EstCostDetailsView';
import {
  getOptionName,
  formatDateTz,
  formatDueDate,
  formatDurationAsMinutes,
  isDisabledEditingRequestField,
  isCanceledSeason,
  buildReportLink
} from '../../../utils/general';
import TextEditableField, {TextField} from './forms/TextEditableField';
import config from '../../../../config';
import UpdateWOsConfirmModal from './modals/UpdateWOsConfirmModal';
import StudioPartnersField, {StyledBtnCompany} from './forms/StudioPartnersField';

const TAB_KEY_COST = 4;

function mapStateToPropsForCostDetails(state) {
  return {
    requestQcCost: state.requestdetails.details.QcCost,
    loadingCost: state.requestdetails.loadingCost,
    costDetails: state.requestdetails.costDetails,
    costDetailsTab: TAB_KEY_COST,
    activeTab: state.requestdetails.activeTab
  };
}

function mapDispatchToPropsForCostDetails(dispatch) {
  return {
    onViewTab: () => dispatch(setActiveTab(TAB_KEY_COST))
  };
}

const CostDetailsView = connect(mapStateToPropsForCostDetails, mapDispatchToPropsForCostDetails)(CostDetailsComponent);

function Top(props) {
  const {dispatch, loading, details, savingParam, dictionaries, detailsFilters} = props;
  const {
    Title, Tag, RequestFormat, Runtime, ContentTypeID, SlaID, StudioID, ProductionID, DistributorID,
    QcProfileID, QcTypeID, RequestStatus, QcStatus, QcStatusID, DueDate, RequestID, IsChangesPending,
    FinishingHouseID, RequestStatusID, is_allow_change_request_status, has_raw_proxy
  } = details;
  const isArtworkRequest = !!details.IsArtworkRequest;
  const isSeasonRequest = ContentTypeID === CONTENT_TYPES.Season;
  const isEpisodeRequest = ContentTypeID === CONTENT_TYPES.Episode;
  const isTrailerRequest = ContentTypeID === CONTENT_TYPES.Trailer;
  const allowEditEpisodeName = isEpisodeRequest || isTrailerRequest;
  const episodeNameField = isTrailerRequest ? 'TrailerEpisodeName' : 'EpisodeName';
  let qcStatusClassName = 'default' + (isSeasonRequest ? ' is-season' : '');
  if (QcStatusID === config.qcStatus.QC_FAILED) {
    qcStatusClassName = 'failed';
  } else if (QcStatusID === config.qcStatus.QC_PASS) {
    qcStatusClassName = 'pass';
  } else if (QcStatusID === config.qcStatus.REVIEW) {
    qcStatusClassName = 'review';
  }
  const qcType = dictionaries.qc_types.find(t => t.value === QcTypeID) || {};
  const isDisabledEditing = isDisabledEditingRequestField(details);
  const isCanceledSeasonRequest = isSeasonRequest && isCanceledSeason(Title);

  const [showConfirmUpdateWOs, setShowConfirmUpdateWOs] = useState(null);

  const handleChangeRequestField = (label, name, value, callback) => {
    let currentValue = details[name];
    let newValue = value;
    if (name === 'Runtime') {
      currentValue = formatDurationAsMinutes(details.Runtime) || 'None';
      newValue = formatDurationAsMinutes(value);
    } else if (name === 'SlaID') {
      currentValue = getOptionName(dictionaries.sla_list, details.SlaID);
      newValue = getOptionName(dictionaries.sla_list, value);
    } else if (name === 'DueDate') {
      currentValue = formatDueDate(details.DueDate);
      newValue = formatDueDate(value);
    }

    confirmAlert({
      title: `The Request ${label} has been changed`,
      childrenElement: () => (
        <>
          <OldNewValuesStyled>
            <div>
              <div><strong>Current {label.replace('%', '')}</strong></div>
              <div className={currentValue !== null ? undefined : 'no-value'}>
                {currentValue !== null ? `${currentValue}` : 'None'}
              </div>
            </div>
            <div>
              <div><strong>New {label.replace('%', '')}</strong></div>
              <div className={newValue !== null ? undefined : 'no-value'}>
                {newValue !== null ? `${newValue}` : 'None'}
              </div>
            </div>
          </OldNewValuesStyled>
          <div>Should request be updated?</div>
        </>
      ),
      buttons: [{
        label: 'No'
      }, {
        label: 'Yes',
        onClick: () => dispatch(updateRequestField(label, name, value, callback))
      }]
    });
  };

  const handleChangeRequestTitle = (label, name, value, callback) => {
    const newValue = (value || '').trim();
    if (!newValue) {
      return;
    }

    setShowConfirmUpdateWOs({
      onClose: () => setShowConfirmUpdateWOs(null),
      onConfirm: updateType => {
        callback && callback();
        setShowConfirmUpdateWOs(null);
        dispatch(updateRequestField(label, name, newValue, null, null, updateType));
      },
      modalTitle: <>You are about to update the title from <strong>{Title}</strong> to <strong>{newValue}</strong>.</>,
      modalText: 'Would you like to:',
      submitButtonLabel: 'OK',
      updateTypes: [{
        value: RequestID, name: 'Update the title of this request only', title: 'Update the title of this request and work order only'
      }, {
        value: -RequestID, name: 'Update the series title with all associated requests and work orders'
      }]
    });
  };

  const handleChangeRequestTag = (label, name, value, callback) => {
    const newValue = (value || '').trim();
    const save = needToUpdate => {
      dispatch(updateRequestField(label, name, newValue, () => {
        callback && callback();
        setShowConfirmUpdateWOs(null);
      }, needToUpdate));
    };
    if (!qcType.is_video && !qcType.is_audio && !qcType.is_captions) {
      save(false);
    } else {
      let labelText = 'Update additional description of existing work orders';
      if (qcType.is_captions) {
        labelText = labelText + ', new value: Closed Caption QC' + (newValue.length ? ' - ' + newValue : '');
      }

      let warning = null;
      if (isSeasonRequest || isEpisodeRequest) {
        warning = 'These changes will apply to all episodes of the ' + details.SeasonProjectName + ' ' + details.RequestFormat + ' season';
      }

      setShowConfirmUpdateWOs({
        onClose: () => setShowConfirmUpdateWOs(null),
        onConfirm: save,
        paramName: 'tag',
        oldValue: Tag,
        newValue,
        warning,
        checkboxLabel: labelText
      });
    }
  };

  const handleChangeRequestStatus = (label, name, statusId, callback) => {
    const statusName = getOptionName(detailsFilters.statuses_for_closed_request, statusId);
    confirmAlert({
      childrenElement: () =>
        <h5 style={{fontWeight: 400, lineHeight: 1.3}}>
          You are about to change the request status to <i>{statusName}</i>. Are you sure?
          {!has_raw_proxy &&
            <>
              <br/><br/>
              NOTE: The raw proxy for this request has been deleted and new watermarked proxies cannot be created for additional users.
            </>
          }
        </h5>,
      buttons: [{
        label: has_raw_proxy ? 'Cancel' : 'No'
      }, {
        label: has_raw_proxy ? 'OK' : 'Yes',
        onClick: () => dispatch(updateRequestStatus({RequestStatusID: statusId, RequestStatus: statusName}, callback))
      }]
    });
  };

  const titleFieldProps = {
    label: "Title",
    value: Title,
    linkProps: {
      href: `/requests?search=${encodeURIComponent(Title)}`,
      target: "_blank",
      title: "Click to view related requests"
    }
  };

  let disabledEditingMsg = null;
  if (isDisabledEditing) {
    disabledEditingMsg = (
      <div
        className="disabledEditingMsg"
      >
        Request editing is disabled for cancelled requests
      </div>
    );
  }

  return (
    <StyledTop>
      {showConfirmUpdateWOs !== null && <UpdateWOsConfirmModal {...showConfirmUpdateWOs} />}
      <div>
        <div className="col-common-info">
          <Grid>
            <Row>
              <Col xs={12} className="col-info">
                {(loading || savingParam === 'Title' || isSeasonRequest || isDisabledEditing) ? <TextField {...titleFieldProps}/> :
                  <TextEditableField
                    {...titleFieldProps}
                    name="Title"
                    onConfirm={handleChangeRequestTitle}
                    disabled={isSeasonRequest || isDisabledEditing}
                  />
                }
              </Col>
              <Col xs={12} className="col-info">
                {loading ? <TextField label="Tag" value={Tag}/> :
                  <TextEditableField
                    label="Tag"
                    name="Tag"
                    value={Tag}
                    onConfirm={handleChangeRequestTag}
                    maxLength={50}
                    placeholder=""
                    disabled={isDisabledEditing}
                  />
                }
              </Col>
              <Col xs={12} className="col-info">
                <label>Format</label>
                <div>{RequestFormat}</div>
              </Col>
              <Col xs={12} className="col-info">
                {(loading || savingParam === 'Episodes') && !isSeasonRequest ? <TextField label="Runtime" value={formatDurationAsMinutes(Runtime)}/> :
                  <TextEditableField
                    label="Runtime"
                    name="Runtime"
                    value={isSeasonRequest ? 'N/A' : Runtime}
                    onConfirm={(isSeasonRequest || isDisabledEditing) ? undefined : handleChangeRequestField}
                    disabled={isSeasonRequest || isDisabledEditing}
                    isRuntime={!isSeasonRequest}
                  />
                }
              </Col>
              <Col xs={12} className="col-info">
                {(loading || savingParam === 'Episodes') && allowEditEpisodeName ?
                  <TextField label="Episode Name" value={details[episodeNameField]}/> :
                  <TextEditableField
                    title={isTrailerRequest ? 'Trailer Episode Name' : undefined}
                    label="Episode Name"
                    name={episodeNameField}
                    value={allowEditEpisodeName ? details[episodeNameField] : 'N/A'}
                    onConfirm={(!allowEditEpisodeName || isDisabledEditing) ? undefined : handleChangeRequestField}
                    disabled={!allowEditEpisodeName || isDisabledEditing}
                    maxLength={250}
                    placeholder=""
                  />
                }
              </Col>
            </Row>
            <Row className="row-line hidden-xs">
              <Col xs={12} className="col-line">
                <hr/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="col-info">
                <label>Studio</label>
                <div className="cont--company-edit">
                  <StyledLink
                    to={`/requests?studio[0]=${StudioID}`}
                    target="_blank"
                    title="Click to view related requests"
                  >
                    {getOptionName(dictionaries.studios, StudioID)}
                  </StyledLink>
                  {!isArtworkRequest &&
                    <StyledBtnCompany
                      className="btn--company-edit"
                      title="Edit"
                      onClick={() => dispatch(showEditCompanyModal('StudioID'))}
                    >
                      <span><i className="far fa-edit"/></span>
                    </StyledBtnCompany>
                  }
                </div>
              </Col>
              <Col xs={12} className="col-info">
                <label>Distributor</label>
                <div>{getOptionName(dictionaries.distributors, DistributorID)}</div>
              </Col>
              <StudioPartnersField
                component={Col}
                componentArgs={{xs: 12, className: 'col-info'}}
                dictionaries={dictionaries}
                dictionaryName="productions"
                label="Production"
                name="ProductionID"
                partners={ProductionID}
                onChange={() => {}}
                onClickEdit={() => dispatch(showEditCompanyModal('ProductionID'))}
                isPartnerRequest={!!details.IsProductionRequest}
                isArtworkRequest={isArtworkRequest}
                seasonProjectName={(isSeasonRequest || isEpisodeRequest) ? details.SeasonProjectName : null}
                requestId={RequestID}
              />
              <StudioPartnersField
                component={Col}
                componentArgs={{xs: 12, className: 'col-info'}}
                dictionaries={dictionaries}
                dictionaryName="finishing_houses"
                label="Post Partner"
                name="FinishingHouseID"
                partners={FinishingHouseID}
                onChange={() => {}}
                onClickEdit={() => dispatch(showEditCompanyModal('FinishingHouseID'))}
                isFinishingHouse
                isPartnerRequest={!!details.IsFinishingHouseRequest}
                isArtworkRequest={isArtworkRequest}
                seasonProjectName={(isSeasonRequest || isEpisodeRequest) ? details.SeasonProjectName : null}
                requestId={RequestID}
              />
              <Col xs={12} className="col-info">
                <label>
                  QC Profile
                  {(((dictionaries.qc_profiles || []).find(p => p.value === QcProfileID)) || {}).active === false &&
                    <sup style={{marginLeft: '7px', color: '#E75592'}}>archived</sup>
                  }
                </label>
                <div className="display--flex">
                  <StyledLink
                    to={`/requests?qc_profile[0]=${QcProfileID}`}
                    target="_blank"
                    title="Click to view related requests"
                  >
                    {getOptionName(dictionaries.qc_profiles, QcProfileID)}
                  </StyledLink>
                  {!isArtworkRequest &&
                    <StyledLinkBtn
                      to={`/settings/qc-profiles?id=${QcProfileID}`}
                      target="_blank"
                      title="Edit profile/template"
                    >
                      <span>
                        <i className="far fa-edit"/>
                      </span>
                    </StyledLinkBtn>
                  }
                </div>
              </Col>
            </Row>
            <Row className="row-line hidden-xs">
              <Col xs={12} className="col-line">
                <hr/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="col-info">
                {
                  (loading || !is_allow_change_request_status || isCanceledSeasonRequest) ?
                    <>
                      <label>Request Status</label>
                      <div>{isCanceledSeasonRequest ? 'Cancelled' : RequestStatus}</div>
                    </> :
                      <TextEditableField
                        label="Request Status"
                        name="RequestStatusID"
                        value={RequestStatusID}
                        onConfirm={handleChangeRequestStatus}
                        options={[...(detailsFilters.statuses_for_closed_request || []), {value: RequestStatusID, name: RequestStatus, disabled: true}]}
                        isSelectControl
                      />
                }
              </Col>
              <Col xs={12} className="col-info">
                <label>QC Status</label>
                <div>
                  <QcStatusStyled
                    className={qcStatusClassName}
                    title="Open Report page"
                    to={buildReportLink(details)}
                  >
                    {QcStatus ? `${QcStatus}${IsChangesPending ? ' / Pending' : ''}` : (isSeasonRequest ? '-' : 'N/A')}
                  </QcStatusStyled>
                </div>
              </Col>
              <Col xs={12} className="col-info">
                {loading ? <TextField label="Turnaround Time" value={getOptionName(dictionaries.sla_list, SlaID)}/> :
                  <TextEditableField
                    label="Turnaround Time"
                    name="SlaID"
                    value={SlaID}
                    onConfirm={handleChangeRequestField}
                    options={dictionaries.sla_list}
                    isSelectControl
                    disabled={isDisabledEditing}
                  />
                }
              </Col>
              {
                !isSeasonRequest ?
                  <Col xs={12} className="col-info">
                    {loading ?
                      <TextField
                        label="Due Date"
                        value={formatDueDate(DueDate)}
                      /> :
                      <TextEditableField
                        label="Due Date"
                        name="DueDate"
                        isDate
                        value={typeof DueDate === 'number' ? DueDate : (formatDateTz(DueDate, '', 'x')) || null}
                        valueForNonEditModeInSelect={'N/A'}
                        onConfirm={handleChangeRequestField}
                        allowReset
                        resetValue={0}
                        disabled={isDisabledEditing}
                      />
                    }
                  </Col> : <Col xs={12} className="col-info"/>
              }
              <Col xs={12} className="col-info">
                <label>QC Type</label>
                <div>{getOptionName(dictionaries.qc_types, QcTypeID)}</div>
              </Col>
            </Row>
          </Grid>
        </div>
        <CostDetailsView/>
      </div>
      { disabledEditingMsg }
    </StyledTop>
  );
}

export const StyledLinkBtn = styled(Link)`
  margin-left: 8px;
  text-decoration: none !important;
  span {
    padding: 3px 6px;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: rgba(9,30,66,0.04);
    color: #42526E;
    cursor: pointer;
    :hover {
      background: rgba(9,30,66,0.1);
    }
  }
  &[aria-describedby] {
    span {
      background: rgb(37, 56, 88) !important;
      transition-duration: 0s, 0.15s;
      color: rgb(244, 245, 247);
    }
  }
`;

export const StyledLink = styled(Link)`
  border-radius: 3px;
  &:hover {
    background-color: #f5f5f5;
    box-shadow: -5px -3px 0 #f5f5f5, 5px -3px 0 0 #f5f5f5, -5px 3px 0 #f5f5f5, 5px 3px 0 0 #f5f5f5;
    text-decoration: none;
  }
`;

export const QcStatusStyled = styled(StyledLink)`
  border-radius: 3px;

  &.default {
    color: #555;
    &:not(.is-season) {
      text-decoration: underline;
    }
  }

  &.failed {
    color: #ff3d00;
    text-decoration: underline;
  }
  &.pass {
    color: #4eb947;
    text-decoration: underline;
  }
  &.review {
    color: #ffbf03;
    text-decoration: underline;
  }

  &:hover {
    background-color: #f5f5f5;
    box-shadow: -5px -3px 0 #f5f5f5, 5px -3px 0 0 #f5f5f5, -5px 3px 0 #f5f5f5, 5px 3px 0 0 #f5f5f5;
    text-decoration: none;
  }
`;

const StyledTop = styled.div`
  margin: 5px 15px;
  padding: 15px 0 0;
  border: 1px solid #ececec;
  font-size: 13px;

  @media (max-width: 767px) {
    margin: 0;
    border: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .col-common-info {
    flex-grow: 1;

    .col-info {
      @media (min-width: 768px) {
        width: 20%;
      }

      > label + div {
        margin-bottom: 15px;
        &.display--flex {
          display: flex;
          align-items: flex-start;
          width: 100%;
        }
      }
    }

    .row-line {
      .col-line {
        width: calc(80% + 140px);
        max-width: 100%;
        hr {
          margin-top: 0;
          margin-bottom: 12px;
          border: 0;
          border-top: 4px solid #f7f7f7;
        }
      }
    }
  }

  label {
    display: block;

    &.control-label {
      margin-bottom: 0;
    }
  }

  .editable-control {
    width: 100%;
  }

  .cont--company-edit {
    display: flex;
    align-items: flex-start;
  }

  .disabledEditingMsg {
    padding: 0 15px 15px;
  }
`;

const OldNewValuesStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  > div {
    + div {
      margin-top: 8px;
    }
    > div {
      &:first-child {
        margin-bottom: 3px;
      }
      &.no-value {
        font-weight: 300;
        opacity: 0.65;
      }
    }
  }
`;

Top.propTypes = {
  dictionaries: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.requestdetails.loading,
    details: state.requestdetails.details,
    detailsFilters: state.requestdetails.detailsFilters,
    savingParam: state.requestdetails.savingParam
  };
}

export default React.memo(connect(
  mapStateToProps
)(Top));
