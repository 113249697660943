import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormGroup, ControlLabel} from 'react-bootstrap';
import Select, {components} from 'react-select-v2';
import expandImg from '../../../../../assets/images/expand.svg';

function MultiSelect(props) {
  const {
    label, options, value, onChange, requiredValue, isDisabled, isClearable, isMulti, formGroupClassName
  } = props;

  return (
    <FormGroup
      className={formGroupClassName}
    >
      <ControlLabel>
        {label}
      </ControlLabel>
      <div className="field">
        <StyledSelect
          className="select-in-cell custom-multi-select--list"
          classNamePrefix="custom-multi-select--select"
          isMulti={isMulti}
          isClearable={typeof isClearable === 'boolean' ? isClearable : (!requiredValue || value.some(v => !requiredValue.includes(v.value)))}
          getOptionLabel={o => o.name}
          options={options}
          value={value.map((v) => ({...v, isFixed: !!requiredValue && requiredValue.includes(v.value)}))}
          onChange={onChange}
          closeMenuOnSelect={!isMulti}
          blurInputOnSelect={!isMulti}
          isDisabled={isDisabled}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: diProps => (
              <components.DropdownIndicator {...diProps}>
                <img src={expandImg} alt=""/>
              </components.DropdownIndicator>
            )
          }}
          maxMenuHeight={200}
          styles={{
            multiValue: (styles, {data}) => {
              return data.isFixed ? {...styles, backgroundColor: 'gray'} : styles;
            },
            multiValueLabel: (styles, {data}) => (
              data.isFixed ? {
                ...styles,
                paddingRight: styles.paddingLeft,
                fontWeight: 'bold',
                color: 'white'
              } : styles
            ),
            multiValueRemove: (styles, {data}) => {
              return data.isFixed ? {...styles, display: 'none'} : styles;
            },
          }}
        />
      </div>
      </FormGroup>
  );
}

const StyledSelect = styled(Select)`
  &.custom-multi-select--list {
    .custom-multi-select--select__control {
      &.custom-multi-select--select__control--is-focused {
        border-color: #007eff;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 3px rgb(0 126 255 / 10%);
      }
      &.custom-multi-select--select__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      @media (min-width: 768px) {
        &:not(.custom-multi-select--select__control--menu-is-open) {
          &:not(:hover) {
            background-color: #f3f3f3;
            border-color: #e9e9e9;
          }
        }
      }
    }
    .custom-multi-select--select__menu {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .custom-multi-select--select__multi-value__label {
      white-space: normal;
    }
  }
`;

MultiSelect.defaultProps = {
  isMulti: true,
  value: []
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  requiredValue: PropTypes.array,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool.isRequired,
};

export default MultiSelect;
