import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  ADMIN_OP_SCHEDULE_CLEAR,
  ADMIN_OP_SCHEDULE_IS_LOADING,
  ADMIN_OP_SCHEDULE_IS_SAVING,
  ADMIN_OP_SCHEDULE_RECEIVE,
  ADMIN_OP_SCHEDULE_SELECT_WEEK,
  ADMIN_OP_SCHEDULE_CHANGE_SCHEDULE,
} from './actions';
import {
  EDITABLE_WEEKS_LIMIT,
  getScheduleByWeekAsCopy,
} from './utils';

const initialState = {
  isLoading: false,
  isSaving: false,
  selectedWeek: '',
  schedule: [],
  schedulesByWeeks: {},
  dictionaries: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ADMIN_OP_SCHEDULE_CLEAR: {
      return initialState;
    }

    case ADMIN_OP_SCHEDULE_IS_LOADING: {
      return {
        ...state,
        isLoading: action.loading || action.saving || initialState.isLoading
      };
    }

    case ADMIN_OP_SCHEDULE_IS_SAVING: {
      return {
        ...state,
        isSaving: action.saving || action.loading || initialState.isSaving
      };
    }

    case ADMIN_OP_SCHEDULE_RECEIVE: {
      const {dictionaries, data} = action;
      let schedulesByWeeks, schedule;
      let selectedWeek = state.selectedWeek;
      if (Array.isArray(data)) {
        schedulesByWeeks = {[selectedWeek]: data};
        schedule = JSON.parse(JSON.stringify(data));
      } else {
        schedulesByWeeks = data;
        if (!selectedWeek && Object.keys(schedulesByWeeks).length >= EDITABLE_WEEKS_LIMIT) {
          const allWeeks = Object.keys(schedulesByWeeks).sort();
          selectedWeek = allWeeks[allWeeks.length - EDITABLE_WEEKS_LIMIT];
        }
        schedule = getScheduleByWeekAsCopy(schedulesByWeeks, selectedWeek);
      }
      if (!dictionaries.offices) {
        let officeSet = new Set();
        (dictionaries.operators || []).forEach((operator) => {
          if (operator.office) {
            officeSet.add(operator.office);
          }
        });
        dictionaries.offices = Array.from(officeSet).sort().map(name => ({value: name, name}));
      }
      return {
        ...state,
        isLoading: initialState.isLoading,
        isSaving: initialState.isSaving,
        selectedWeek,
        schedule,
        schedulesByWeeks,
        dictionaries
      };
    }

    case ADMIN_OP_SCHEDULE_SELECT_WEEK: {
      return {
        ...state,
        selectedWeek: action.week,
        schedule: getScheduleByWeekAsCopy(state.schedulesByWeeks, action.week),
      };
    }

    case ADMIN_OP_SCHEDULE_CHANGE_SCHEDULE: {
      return {
        ...state,
        schedule: action.schedule,
      };
    }

    default:
      return state;
  }
}
