import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SwitchAdvanced from '../../../../components/forms/SwitchAdvanced';
import isMobile from 'is-mobile';

const Title = props => {
  const {showScheduledDates, onChangeShow, className} = props;

  const switchProps = isMobile() ? {
    handleDiameter: 22,
    height: 24,
    width: 42,
    onColor: '#EDEDED'
  } : {};

  return (
    <StyledTitle className={className}>
      <h2>Season Schedule</h2><span className="hint">{`${isMobile() ? 'Tap' : 'Click'} a Cell to Edit`}</span>
      <div className="switch-wrap">
        <SwitchAdvanced
          {...switchProps}
          name="show-scheduled-dates"
          value={showScheduledDates}
          onChange={onChangeShow}
        />
        <span>Show Scheduled Dates</span>
      </div>
    </StyledTitle>
  );
};

const StyledTitle = styled.div`
  background-color: #f9f9f9;
  border-bottom: 1px solid #e4e4e4;
  height: 51px;
  padding: 15px 19px 0;

  h2 {
    color: #282828;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    margin: 0 10px 0 0;
  }
  span.hint {
    color: rgba(40, 40, 40, 0.5);
    font-size: 10px;
    font-style: italic;
    font-weight: normal;
  }
  .switch-wrap {
    color: #595959;
    display: flex;
    float: right;
    font-size: 11px;
    line-height: 24px;
    .switch-adv {
      margin-right: 7px;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #fff;
    padding: 21px 25px 20px;

    h2 {
      margin-bottom: 3px;
    }

    span.hint {
      font-size: 11px;
    }
    .switch-wrap {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .switch-adv {
        margin: 0;
        .switch-field {
          display: flex;
        }
        .react-switch-bg {
          border: none !important;
        }
        .react-switch-handle {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
      }
      span {
        font-size: 15px;
        color: #595959;
      }
    }
  }
`;

Title.propTypes = {
  showScheduledDates: PropTypes.bool.isRequired,
  onChangeShow: PropTypes.func.isRequired
};

export default Title;