export const ConflictArea = {
  ISSUE: 'issue',
  WORK_ODER: 'wo',
  WO_STEP: 'step'
};

export const Version = {
  QOD: 'QOD',
  MEMFIS: 'MEMFIS'
};
