import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';
import RadioButtonsAdvanced from "../../../../components/forms/RadioButtonsAdvanced";

class AddPatchStepsOptionsModal extends Component {
    state = {
        overage_company: null
    };

    handleSubmit = () => {
        const {onSubmit} = this.props;
        onSubmit(this.state.overage_company);
    };

    handleSelectOverageCompany = (_, value) => {
        this.setState({overage_company: value});
    };

    overageCompanyOptions = [
        {value: 'STUDIO', name: 'Studio'},
        {value: 'PRODUCTION', name: 'Production'},
        {value: 'NO_OVERAGE', name: 'No overage'},
    ];

    render() {
        const {onClose} = this.props;
        return (
            <ModalStyled
                className="cs-create-request-modal"
                show={true}
                onHide={onClose}
                enforceFocus={false}
            >
                <Modal.Header>
                    <Modal.Title>Overage Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <span>Which company should be charged with the overage?:</span>
                        <RadioButtonsAdvanced
                            className="clearfix"
                            labelSeparator=""
                            name="update_type"
                            options={this.overageCompanyOptions}
                            value={this.state.overage_company}
                            onChange={this.handleSelectOverageCompany}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonStyled
                        bsStyle="default"
                        onClick={onClose}
                    >
                        Cancel
                    </ButtonStyled>
                    <ButtonStyled
                        bsStyle="primary"
                        onClick={this.handleSubmit}
                    >
                        Add Patch Steps
                    </ButtonStyled>
                </Modal.Footer>
            </ModalStyled>
        );
    }
}

const ModalStyled = styled(Modal)`
  .modal-header {
    border-bottom: none;
    h4 {
      padding: 5px 15px 0;
    }
  }
  .modal-body {
    padding: 0 15px;
  }
  .modal-footer {
    border-top: none;
  }
  .switch-field {
    margin-top: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 100px;
  font-weight: bold;

  & + & {
    margin-left: 20px !important;
  }

  &:not(:disabled):hover {
    background-color: #f1f1f1 !important;
  }

  &.btn-primary {
    color: #5d3996;

    &:disabled {
      opacity: 0.65;
      cursor: default;
    }

    &:not(:disabled):hover {
      color: #401782;
    }
  }

  &.btn-default {
    color: #bbb;

    &:hover {
      color: #969696;
    }
  }
`;

AddPatchStepsOptionsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddPatchStepsOptionsModal;