import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  REQUEST_WOID_IS_LOADING
} from './actions';

const initialState = {
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case REQUEST_WOID_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    default:
      return state;
  }
}
