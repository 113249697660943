import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {COLORS} from './Calendar';

const ScheduleRow = props => {
  const {milestone} = props;
  const date = milestone.StatusUpdateDate ? moment(milestone.StatusUpdateDate).format('M/D') : '';

  return (
    <StyledRow className="milestone">
      <div className="date">{date}</div>
      <div className="name">{milestone.Title}</div>
    </StyledRow>
  );
};

const StyledRow = styled.div`
  display: flex;
  line-height: 47px;
  padding-left: 17px;
  position: relative;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 49px;
  }
  &:before {
    border-radius: 4px;
    content: '';
    position: absolute;
    top: 19px;
    left: -5px;
    width: 8px;
    height: 8px;
  }
  &.milestone {
    &:nth-child(5n + 1):before {
      background-color: ${COLORS[0]};
    }
    &:nth-child(5n + 2):before {
      background-color: ${COLORS[1]};
    }
    &:nth-child(5n + 3):before {
      background-color: ${COLORS[2]};
    }
    &:nth-child(5n + 4):before {
      background-color: ${COLORS[3]};
    }
    &:nth-child(5n + 5):before {
      background-color: ${COLORS[4]};
    }
    @media (max-width: 767px) {
      &:last-of-type {
        padding-bottom: 36px;
      }
    }
  }
  .date {
    border-bottom: 1px solid #7c7c7c;
    height: 47px;
    font-weight: bold;
    padding-left: 7px;
    width: 55px;
    @media (max-width: 767px) {
      height: 49px;
    }
  }
  .name {
    border-bottom: 1px solid #7c7c7c;
    height: 47px;
    flex-grow: 1;
    @media (max-width: 767px) {
      height: 49px;
    }
  }
`;

ScheduleRow.propTypes = {
  milestone: PropTypes.object.isRequired
};

export default ScheduleRow;