import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Row, Col, Button, Collapse, DropdownButton, MenuItem, Alert} from 'react-bootstrap';
import SelectOrCheckbox from '../forms/SelectOrCheckbox';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import AudioConfiguration from '../forms/AudioConfiguration';
import {
  VIDEO_FORMAT_FIELDS, getVideoFormatFilter, VIDEO_FORMAT_FIELD_FORMAT_ID, VIDEO_FORMAT_FIELD_RESOLUTION_ID,
  VIDEO_FORMAT_FIELD_COLOR_SPACE_ID, VIDEO_FORMAT_FIELD_CAPTION_ID, VIDEO_FORMAT_FIELD_TEXTLESS,
  VIDEO_FILTER_NAME_FORMATS, VIDEO_FILTER_NAME_VIDEO_CAPTIONS
} from '../../../NewRequest/constants';
import {
  getHiddenWizardFields, buildDefaultAudioFormat, buildDefaultVideoFormat, getColorByOption
} from '../../../../utils/general';
import classNames from 'classnames';
import {getProfileMediaConfiguration} from '../../actions';
import Loader from '../../../../components/Loader';
import isMobile from 'is-mobile';

const isValidFieldValue = v => v !== undefined && v !== null;

export const filterCombinationsForVideo = (combinations, withAudio, videoFields) => {
  return combinations.filter(c => {
    const hasValidAudioFields = isValidFieldValue(c.AudioSoundID1) || isValidFieldValue(c.AudioSoundID2) || isValidFieldValue(c.AudioSoundID3);
    const isValidAudio = withAudio ? hasValidAudioFields : !hasValidAudioFields;
    if (!isValidAudio) {
      return false;
    }
    return videoFields.every(field => field.name === VIDEO_FORMAT_FIELD_FORMAT_ID || isValidFieldValue(c[field.altName]));
  });
};

export const filterCombinationsForAudioOnly = combinations => {
  return combinations.filter(c => (
    (isValidFieldValue(c.AudioSoundID1) || isValidFieldValue(c.AudioSoundID2) || isValidFieldValue(c.AudioSoundID3)) &&
    !VIDEO_FORMAT_FIELDS.some(f => f.name !== VIDEO_FORMAT_FIELD_FORMAT_ID && isValidFieldValue(c[f.altName]))
  ));
};

export const getVideoFields = hiddenFields => {
  return hiddenFields ? VIDEO_FORMAT_FIELDS.filter(item => !hiddenFields.includes(item.filterName)) : VIDEO_FORMAT_FIELDS;
};

const preFillAudioFormat = (combination, isValidAs1, isValidAs2, isValidAs3, audioFormat, hiddenFields, filters) => {
  if (audioFormat.hasOwnProperty('audio_configuration')) {
    isValidAs1 && audioFormat.audio_configuration.data.push({
      type_id: filters.audio_types[0].value,
      sound_id: combination.AudioSoundID1,
      theatrical: false
    });
    isValidAs2 && audioFormat.audio_configuration.data.push({
      type_id: filters.audio_types[0].value,
      sound_id: combination.AudioSoundID2,
      theatrical: false
    });
    isValidAs3 && audioFormat.audio_configuration.data.push({
      type_id: filters.audio_types[0].value,
      sound_id: combination.AudioSoundID3,
      theatrical: false
    });
  }
  if (!hiddenFields.includes('audio_format') && !isValidFieldValue(audioFormat.format_id) && filters.audio_formats.length) {
    audioFormat.format_id = filters.audio_formats[0].value;
  }
};

export const preFillVideoFormat = (combination, videoFormat, videoFields, hiddenFields, filters) => {
  const values = {[VIDEO_FORMAT_FIELD_FORMAT_ID]: videoFormat[VIDEO_FORMAT_FIELD_FORMAT_ID]};
  videoFields.forEach(field => {
    const value = combination[field.altName];
    if (field.name === VIDEO_FORMAT_FIELD_FORMAT_ID) {
      if (isValidFieldValue(value)) {
        values[field.name] = value;
      } else if (filters[VIDEO_FILTER_NAME_FORMATS].length) {
        values[field.name] = filters[VIDEO_FILTER_NAME_FORMATS][0].value;
      }
    } else {
      values[field.name] = isValidFieldValue(value) ? value : null;
    }
  });
  const isValidAs1 = isValidFieldValue(combination.AudioSoundID1);
  const isValidAs2 = isValidFieldValue(combination.AudioSoundID2);
  const isValidAs3 = isValidFieldValue(combination.AudioSoundID3);
  if (isValidAs1 || isValidAs2 || isValidAs3) {
    values.audio_format = buildDefaultAudioFormat(hiddenFields);
    preFillAudioFormat(combination, isValidAs1, isValidAs2, isValidAs3, values.audio_format, hiddenFields, filters);
  }
  return values;
};

export const preFillOnlyAudioFormat = (combination, audioFormat, hiddenFields, filters) => {
  const isValidAs1 = isValidFieldValue(combination.AudioSoundID1);
  const isValidAs2 = isValidFieldValue(combination.AudioSoundID2);
  const isValidAs3 = isValidFieldValue(combination.AudioSoundID3);
  if (isValidAs1 || isValidAs2 || isValidAs3) {
    const values = buildDefaultAudioFormat(hiddenFields);
    values.format_id = audioFormat.format_id;
    preFillAudioFormat(combination, isValidAs1, isValidAs2, isValidAs3, values, hiddenFields, filters);
    return values;
  }
  return null;
};

class Formats extends Component {
  resetValues = false;

  state = {
    isLoadingCombinations: false,
    combinations: [],
    openedFormats: []
  };

  componentDidMount() {
    const {data} = this.props;
    const {qc_profile_id, qc_type_id} = data;
    this.setState({isLoadingCombinations: true});
    getProfileMediaConfiguration(qc_profile_id, qc_type_id).then(
      res => {
        if (this.isUnmounted) {
          return;
        }
        this.setState({isLoadingCombinations: false, combinations: res || []});
      },
      error => {
        if (this.isUnmounted) {
          return;
        }
        this.setState({isLoadingCombinations: false});
        console.log(error);
      }
    );
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getHiddenFields() {
    const {data, filters} = this.props;
    return getHiddenWizardFields(filters.qc_profiles, data.qc_profile_id);
  }

  handleSingleAudioFormatFieldValueChanged = (name, value) => {
    const {data, onSelect} = this.props;
    const formatValues = {...data.audio_format};
    formatValues[name] = value;
    onSelect('audio_format', formatValues);
    onSelect('version_tag', null);
    onSelect('additional_description', null);
  };

  handleVideoFormatFieldValueChanged = (index, name, value) => {
    const {data, onSelect} = this.props;
    const videoFormats = [...data.video_format];
    videoFormats[index][name] = value;
    delete videoFormats[index].version_tag;
    delete videoFormats[index].additional_description;
    onSelect('video_format', videoFormats);
  };

  handleClickRemoveAudioFormatFromVideo = index => {
    const {data, onSelect} = this.props;
    const videoFormats = [...data.video_format];
    delete videoFormats[index].audio_format;

    const {openedFormats} = this.state;
    delete openedFormats[openedFormats.findIndex(i => i === index)];

    onSelect('video_format', videoFormats);
  };

  handleVideoFormatChanged = (index, values) => {
    const {data, onSelect} = this.props;
    const videoFormats = [...data.video_format];

    delete videoFormats[index].audio_format;
    delete videoFormats[index].version_tag;
    delete videoFormats[index].additional_description;

    if (!(values.audio_format || {}).audio_configuration || !values.audio_format.audio_configuration.data.length) {
      const {openedFormats} = this.state;
      const openedFormatIndex = openedFormats.findIndex(i => i === index);
      openedFormatIndex >= 0 && delete openedFormats[openedFormatIndex];
    }

    videoFormats[index] = {...videoFormats[index], ...values};
    onSelect('video_format', videoFormats);
  };

  handlePreFillVideoFormat = (combination, formatIndex, videoFields, hiddenFields) => {
    const {data, filters} = this.props;
    const values = preFillVideoFormat(combination, data.video_format[formatIndex], videoFields, hiddenFields, filters);
    this.handleVideoFormatChanged(formatIndex, values);
  };

  renderPreFillButton(combinations, onSelect, pullRight = false, index = 0) {
    return (
      <DropdownButton
        key={`dropdown-prefill-button-${index}`}
        id={`dropdown-prefill-button-${index}`}
        bsStyle="default"
        title="Pre-Fill"
        pullRight={pullRight}
      >
        {combinations.map((combination, i) => (
          <MenuItem
            key={`dropdown-prefill-button-${index}-${i}`}
            eventKey={combination.task_id}
            onSelect={() => onSelect(combination)}
          >
            {['video_format', 'video', 'audio', 'texted', 'caption'].map((k, ki) => combination[k] && (
              <span
                style={{backgroundColor: `#${getColorByOption(combination, k)}`}}
                key={`dropdown-prefill-button-${index}-${i}-${k}-${ki}`}
                className="pre"
                title={k !== 'texted' ? k.charAt(0).toUpperCase() + k.slice(1) : undefined}
              >
                {combination[k]}
              </span>
            ))}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }

  renderPreFillUnavailable() {
    return (
      <StyledNoPreFill
        bsStyle="warning"
      >
        No-prefill available
      </StyledNoPreFill>
    );
  }

  renderPreFillVideoButton(index, withAudio, videoFields, hiddenFields) {
    const {isLoadingCombinations, combinations} = this.state;
    if (isLoadingCombinations) {
      return <div className="dropdown-loader hidden-xs"><Loader/></div>;
    }
    const placeholder = () => this.props.isNewQuote ? null : <div className="dropdown-placeholder hidden-xs"/>;
    if (!combinations.length) {
      return placeholder();
    }
    const filteredCombinations = filterCombinationsForVideo(combinations, withAudio, videoFields);
    if (!filteredCombinations.length) {
      return placeholder();
    }
    const onSelect = (combination) => this.handlePreFillVideoFormat(combination, index, videoFields, hiddenFields);
    return this.renderPreFillButton(filteredCombinations, onSelect, !isMobile(), index);
  }

  handlePreFillOnlyAudioFormat = (combination, hiddenFields) => {
    const {data, onSelect, filters} = this.props;
    const values = preFillOnlyAudioFormat(combination, data.audio_format, hiddenFields, filters);
    if (values) {
      onSelect('audio_format', values);
    }
  };

  renderPreFillOnlyAudioButton(hiddenFields) {
    const {isLoadingCombinations, combinations} = this.state;
    if (isLoadingCombinations) {
      return <div className="dropdown-loader"><Loader/></div>;
    }
    if (!combinations.length) {
      return null;
    }
    const filteredCombinations = filterCombinationsForAudioOnly(combinations);
    if (!filteredCombinations.length) {
      return null;
    }
    return this.renderPreFillButton(filteredCombinations, c => this.handlePreFillOnlyAudioFormat(c, hiddenFields));
  }

  getVideoFormatFieldClassNames = field => {
    const {name} = field;
    return classNames({
      'video-format-field': true,
      format_id: name === VIDEO_FORMAT_FIELD_FORMAT_ID,
      resolution_id: name === VIDEO_FORMAT_FIELD_RESOLUTION_ID,
      color_space_id: name === VIDEO_FORMAT_FIELD_COLOR_SPACE_ID,
      caption_id: name === VIDEO_FORMAT_FIELD_CAPTION_ID,
      textless: name === VIDEO_FORMAT_FIELD_TEXTLESS,
    });
  };

  renderVideoFormatFields = (videoFormatItem, itemIndex, filtersRes) => {
    const {filters, data} = this.props;
    return filtersRes.map((field, i) => (
      <div
        key={`video-format-${itemIndex}-select-${i}`}
        className={this.getVideoFormatFieldClassNames(field)}
        >
        <SelectOrCheckbox
          className="clearfix"
          label={field.label}
          labelSeparator=""
          id={`${field.name}-${itemIndex}`}
          name={`${field.name}-${itemIndex}`}
          options={getVideoFormatFilter(filters, field.filterName, data.qc_profile_id)}
          value={videoFormatItem[field.name]}
          placeholder=""
          onChange={(_, value) => this.handleVideoFormatFieldValueChanged(itemIndex, field.name, value)}
        />
      </div>
    ));
  };

  renderAudioFormatField(hiddenFields, audioFormat, onSelect) {
    const {filters} = this.props;
    return !hiddenFields.includes('audio_format') ? (
      <SelectAdvanced
        className="audio-format-id"
        label="Audio Format"
        labelSeparator=""
        name="format_id"
        options={filters.audio_formats}
        value={audioFormat.format_id}
        placeholder=""
        onSelect={onSelect}
      />
    ) : null;
  }

  renderAudioConfigField(hiddenFields, audioFormat, onChange) {
    const {filters} = this.props;
    return !hiddenFields.includes('audio_configuration') ? (
      <AudioConfiguration
        values={audioFormat.audio_configuration}
        filters={filters}
        onChange={onChange}
      />
    ) : null;
  }

  renderAudioFormatForVideo(audioFormat, videoFormatIndex, hiddenFields) {
    return (
      <div className="audio-format">
        {!this.props.isNewQuote && <Button
          className="icon-trash"
          title="Remove Audio Format"
          onClick={() => this.handleClickRemoveAudioFormatFromVideo(videoFormatIndex)}
        />}
        {this.renderAudioFormatField(hiddenFields, audioFormat,
          (name, value) => {
            const format = {...audioFormat, [name]: value};
            this.handleVideoFormatFieldValueChanged(videoFormatIndex, 'audio_format', format);
          }
        )}
        {this.renderAudioConfigField(hiddenFields, audioFormat,
          (name, value) => {
           const config = {...audioFormat.audio_configuration, [name]: value};
           const format = {...audioFormat, audio_configuration: config};
           this.handleVideoFormatFieldValueChanged(videoFormatIndex, 'audio_format', format);
          }
        )}
      </div>
    );
  }

  renderSingleAudioFormat() {
    const {data, isNewQuote} = this.props;
    const {audio_format} = data;
    if (!audio_format) {
      return null;
    }
    const hiddenFields = this.getHiddenFields();
    const audioConfig = this.renderAudioConfigField(hiddenFields, audio_format,
      (name, value) => {
        const config = {...audio_format.audio_configuration, [name]: value};
        this.handleSingleAudioFormatFieldValueChanged('audio_configuration', config);
      }
    );
    const preFillBtn = audioConfig ? this.renderPreFillOnlyAudioButton(hiddenFields) : null;
    const isPreFillUnavailable = !preFillBtn && audioConfig && isNewQuote;
    const {isLoadingCombinations} = this.state;
    return (
      <Col className="audio-format" md={isPreFillUnavailable ? 12 : 8} xs={12}>
        {isNewQuote && isLoadingCombinations ? <Loader/> : isPreFillUnavailable ? this.renderPreFillUnavailable() : <>
        <div className={`format-name${preFillBtn ? ' with-prefill-btn' : ''}`}>
          <span>Audio</span>{preFillBtn}
        </div>
        {this.renderAudioFormatField(hiddenFields, audio_format, this.handleSingleAudioFormatFieldValueChanged)}
        {audioConfig}
        </>}
      </Col>
    );
  }

  handleClickAddAnotherVideoFormat = hiddenFields => {
    const {data, filters, onSelect} = this.props;
    const {video_format, qc_type_id} = data;
    const qcType = filters.qc_types.find(t => t.value === qc_type_id);
    onSelect('video_format', [...video_format, buildDefaultVideoFormat(hiddenFields, qcType)]);
  };

  handleClickRemoveVideoFormat = videoFormatIndex => {
    const {data, onSelect} = this.props;
    const videoFormats = [...data.video_format];
    videoFormats.splice(videoFormatIndex, 1);
    onSelect('video_format', videoFormats);
  };

  isOpenedFormat = videoFormatIndex => {
    const {openedFormats} = this.state;
    return openedFormats.includes(videoFormatIndex);
  };

  toggleFormatOpen = videoFormatIndex => {
    const isOpen = this.isOpenedFormat(videoFormatIndex);
    const {openedFormats} = this.state;
    if (isOpen) {
      openedFormats.splice(openedFormats.findIndex(i => i === videoFormatIndex), 1);
      this.setState({openedFormats});
    } else {
      this.setState({openedFormats: [...openedFormats, videoFormatIndex]});
    }
  };

  handleAddAudioFormat = (videoFormatIndex, hiddenFields) => {
    const {data, onSelect} = this.props;
    const videoFormats = [...data.video_format];
    videoFormats[videoFormatIndex].audio_format = buildDefaultAudioFormat(hiddenFields);
    onSelect('video_format', videoFormats);
    this.toggleFormatOpen(videoFormatIndex);
  };

  renderAudioFormatButton(audioFormat, itemIndex, qcType, qcProfilesHiddenFields) {
    if (!qcType.is_audio) {
      return null;
    }
    if (!audioFormat) {
      return !this.props.isNewQuote && (
        <span
          className="btn-af add-audio-format"
          onClick={() => this.handleAddAudioFormat(itemIndex, qcProfilesHiddenFields)}
          >
          Add Audio Format
        </span>
      );
    }
    const isOpen = this.isOpenedFormat(itemIndex);
    return (
      <span
        className="btn-af open-audio-format"
        onClick={() => this.toggleFormatOpen(itemIndex)}
        aria-controls={`audio-video-${itemIndex}`}
        aria-expanded={isOpen}
        >
        {isOpen ? 'Hide' : 'View'} Audio Format
      </span>
    );
  }

  renderPreFillOnlyCaptionsButton(onSelect) {
    const {isLoadingCombinations, combinations} = this.state;
    if (isLoadingCombinations) {
      return <div className="dropdown-loader"><Loader/></div>;
    }
    if (!combinations.length) {
      return null;
    }
    return this.renderPreFillButton(combinations, onSelect);
  }

  renderOnlyCaptions(isCaptions) {
    const {data, filters, onSelect} = this.props;
    const {caption_id, qc_type_id} = data;
    const qcType = filters.qc_types.find(t => t.value === qc_type_id);
    if (!qcType.is_captions) {
      return null;
    }

    const captionsFilter = filters[VIDEO_FILTER_NAME_VIDEO_CAPTIONS];

    if (this.props.isNewQuote) {
      const captions = [...data.captions || []];
      if (!captions.length) {
        captions.push({});
      }
      const handleChange = (newCaptionId, captionIdx) => {
        const newCaptionsArr = [...captions];
        newCaptionsArr[captionIdx].caption_id = newCaptionId;
        onSelect('captions', newCaptionsArr);
      };
      const preFillBtns = captions.map((captionItem, captionIdx) => this.renderPreFillOnlyCaptionsButton(c => handleChange(c.CaptionID, captionIdx)));
      const {isLoadingCombinations} = this.state;
      return (
        <Col className="captions-only captions-container" md={12} xs={12}>
          {isLoadingCombinations ? <Loader/> : !preFillBtns.some(Boolean) ? this.renderPreFillUnavailable() : <>
          <div className="format-name">
            <span>Captions</span>
            <span
              className="btn-add-another"
              onClick={() => onSelect('captions', [...captions, {}])}
            >
              Add Another CAPTIONS Format
            </span>
          </div>
          {captions.map((captionItem, captionIdx) => {
            const preFillBtn = preFillBtns[captionIdx];
            return (
              <Row key={captionIdx}>
                <Col className="captions-only captions-item" md={4} xs={12}>
                  <SelectAdvanced
                    labelSeparator=""
                    name="caption_id"
                    options={captionsFilter}
                    value={captionItem.caption_id}
                    placeholder=""
                    onSelect={(n, v) => handleChange(v, captionIdx)}
                  />
                  {preFillBtn}
                  {captions.length > 1 ?
                    <Button
                      className="icon-trash"
                      disabled={captions.length === 1}
                      onClick={() => {
                        const newCaptionsArr = [...captions];
                        newCaptionsArr.splice(captionIdx, 1);
                        onSelect('captions', newCaptionsArr);
                      }}
                      title="Remove this version"
                    /> : <Button className="icon-trash btn-placeholder hidden-xs" disabled/>}
                </Col>
              </Row>
            );
          })}
          </>}
        </Col>
      );
    }

    const preFillBtn = this.renderPreFillOnlyCaptionsButton(c => onSelect('caption_id', c.CaptionID));
    return (
      <Col className="captions-only" md={3} xs={12}>
        <div className={`format-name${preFillBtn ? ' with-prefill-btn' : ''}`}>
          <span>Captions</span>{preFillBtn}
        </div>
        <SelectAdvanced
          labelSeparator=""
          name="caption_id"
          options={captionsFilter}
          value={caption_id}
          placeholder=""
          onSelect={onSelect}
        />
      </Col>
    );
  }

  renderVideoFormats(qcType, video_format) {
    const {isNewQuote} = this.props;
    const {isLoadingCombinations} = this.state;
    const qcProfilesHiddenFields = this.getHiddenFields();
    const filtersRes = getVideoFields(qcProfilesHiddenFields);
    const preFillBtns = video_format.map((videoFormatItem, itemIndex) => this.renderPreFillVideoButton(itemIndex, qcType.is_audio, filtersRes, qcProfilesHiddenFields));
    return (
      <Col className="video-formats" md={12} xs={12}>
        {isNewQuote && isLoadingCombinations ? <Loader/> : !preFillBtns.some(Boolean) ? this.renderPreFillUnavailable() :
          <>
            <div className="format-name">
              <span>Video</span>
              <span
                className="btn-add-another"
                onClick={() => this.handleClickAddAnotherVideoFormat(qcProfilesHiddenFields)}
                >
                Add Another VIDEO Format
              </span>
            </div>
            <div className="format-fields-names hidden-xs">
              {filtersRes.map((field, i) => (
                <div key={`field-label-${i}`} className={this.getVideoFormatFieldClassNames(field)}>
                  {field.label}
                </div>
              ))}
            </div>
            {
              video_format.map((videoFormatItem, itemIndex) => {
                const audioFormat = videoFormatItem.audio_format;
                const isOpen = this.isOpenedFormat(itemIndex);
                return (
                  <div
                    className={`video-format-item${isOpen ? ' expanded' : ''}`}
                    key={`video-format-item-${itemIndex}`}
                    >
                    <div className="video-format">
                      {this.renderVideoFormatFields(videoFormatItem, itemIndex, filtersRes)}
                      <div className="vf-buttons">
                        {preFillBtns[itemIndex]}
                        {this.renderAudioFormatButton(audioFormat, itemIndex, qcType, qcProfilesHiddenFields)}
                        {video_format.length > 1 ?
                        <Button
                          className="icon-trash"
                          disabled={video_format.length === 1}
                          onClick={() => this.handleClickRemoveVideoFormat(itemIndex)}
                          title="Remove this version"
                          /> : <Button className="icon-trash btn-placeholder hidden-xs" disabled/>}
                      </div>
                    </div>
                    {
                      audioFormat ?
                        <Collapse in={isOpen}>
                          <div id={`audio-video-${itemIndex}`}>
                            {this.renderAudioFormatForVideo(audioFormat, itemIndex, qcProfilesHiddenFields)}
                          </div>
                        </Collapse> : null
                    }
                  </div>
                );
              })
            }
          </>
        }
      </Col>
    );
  }

  render() {
    const {data, filters, isNewQuote} = this.props;
    const {video_format, qc_type_id} = data;
    const qcType = filters.qc_types.find(t => t.value === qc_type_id);
    let formatsContent;
    if (video_format) {
      formatsContent = this.renderVideoFormats(qcType, video_format);
    } else if (qcType.is_audio) {
      formatsContent = this.renderSingleAudioFormat();
    } else {
      formatsContent = this.renderOnlyCaptions();
    }
    return (
      <StyledFormats className="step-body-wrapper formats-step" isNewQuote={!!isNewQuote}>
        <Row>
          {formatsContent}
        </Row>
      </StyledFormats>
    );
  }
}

const StyledFormats = styled.div`
  .new-request .new-request-wrapper & {
    &.formats-step {
      margin-top: 30px;
  
      .form-group {
        margin-bottom: 38px;
  
        label {
          margin-bottom: 12px;
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
        }
        
        &.radio-buttons .field label {
          font-size: 12px;
          line-height: 23px;
          margin: 15px 43px 0 0;
          min-width: 75px;
        }
      }
  
      .format-name {
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        margin-bottom: 19px;
      }
  
      .audio-format {
  
        .switch-field.switch-not-sure {
          margin-top: 3px;
        }
  
        .audio-config {
  
          &.disabled {
            opacity: 0.4;
            pointer-events: none;
          }
  
          .form-group {
            margin-bottom: 5px;
          }
  
          hr {
            border-top: 1px solid #e5e5e5;
            margin: 3px 0 13px;
  
            &.last-line {
              margin-bottom: 10px !important;
            }
          }
  
          .type-field {
            padding-right: 4px;
  
            .Select-value .Select-value-label {
              font-weight: 700;
            }
          }
  
          .sound-field {
            padding-left: 3px;
          }
  
          .theatrical-field {
            label {
              display: inline-block;
              width: 72px;
              vertical-align: top;
              font-weight: 700;
              font-size: 13px;
              line-height: 39px;
              margin: 0;
            }
            .field {
              display: inline-block;
              width: 78px;
            }
          }
  
          .btn-toolbar {
            float: right;
            margin: 0 23px;
  
            .icon-trash {
              font-size: 22px;
              line-height: 38px;
              color: #282828;
              opacity: 0.8;
  
              &:hover {
                opacity: 1;
              }
            }
          }
        }
  
        .btn.btn-default.add-another {
          font-weight: 700;
          font-size: 13px;
          color: #28C0F0;
  
          &:not([disabled]):hover {
            color: #1a99c1;
          }
        }
      }

      .video-formats {
        .format-name {
          font-weight: bold;
          font-size: 18px;
          line-height: 19px;
          color: #282828;
          padding-top: 1px;
          padding-left: 7px;
          .btn-add-another {
            margin-left: 27px;
            font-weight: 700;
            font-size: 13px;
            color: #28C0F0;
            &:hover {
              color: #1a99c1;
              cursor: pointer;
            }
          }
        }
        .format-fields-names {
          padding: 0 37px 0 19px;
          margin-bottom: 9px;
          font-weight: 700;
          font-size: 13px;
          line-height: 14px;
          display: flex;
          align-items: center;
          > div {
            text-indent: 4px;
            &:not(:first-of-type) {
              padding-left: 15px;
            }
          }
        }
        @media (min-width: 768px) {
            .video-format-field {
              min-width: 150px;
              &.format_id {
                width: 24%;
              }
              &.resolution_id {
                width: 14%;
              }
              &.color_space_id {
                width: 18%;
              }
              &.caption_id {
                width: 14%;
              }
              &.textless {
                min-width: 78px;
                width: 7%;
              }
            }
        }
        .video-format-item {
          border: 1px solid #F2F2F2;
          &:not(:first-of-type) {
            margin-top: 14px;
          }
          &.expanded {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            .video-format {
              background: #FFFFFF;
            }
          }
          .video-format {
            padding: 13px 37px 17px 19px;
            background: #F9F9F9;
            @media (min-width: 768px) {
              display: flex;
              align-items: center;
            }
            > div {
              @media (min-width: 768px) {
                &:not(:first-of-type) {
                  padding-left: 15px;
                }
              }
              &.vf-buttons {
                @media (min-width: 992px) {
                  padding-left: 20px;
                }
                @media (min-width: 768px) {
                  flex-grow: 1;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                .icon-trash {
                  font-size: 22px;
                  line-height: 38px;
                  color: #282828;
                  &:not([disabled]) {
                    opacity: 0.8;
                    &:hover {
                      opacity: 1;
                    }
                  }
                  &.btn-placeholder {
                    visibility: hidden;
                    color: transparent;
                  }
                }
                .btn-af {
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 14px;
                  color: #575757;
                  &:hover {
                    color: #282828;
                    cursor: pointer;
                  }
                  &.open-audio-format[aria-expanded="false"] {
                    color: #29A4CB;
                    &:hover {
                      color: #228daf;
                    }
                  }
                  &.add-audio-format {
                    color: #e75592;
                    &:hover {
                      color: #d23577;
                    }
                  }
                }
              }
            }
            .form-group {
              @media (min-width: 768px) {
                margin-bottom: 0;
                &.radio-buttons {
                  .field {
                    label {
                      margin-top: 0;
                      margin-bottom: 0;
                    }
                  }
                }
                > .control-label {
                  display: none;
                }
              }
            }
          }
          .audio-format {
            padding: 24px 33px 15px 53px;
            background: #F9F9F9;
            position: relative;
            .icon-trash {
              position: absolute;
              top: 17px;
              right: 37px;
              font-size: 22px;
              line-height: 38px;
              color: #282828;
              opacity: 0.8;
              &:hover {
                opacity: 1;
              }
            }
            .form-group {
              margin-bottom: 15px;
              &.audio-format-id {
                margin-bottom: 20px;
                @media (min-width: 768px) {
                  width: calc(25% - 11px);
                }
              }
            }
          }
        }

        .Select.is-searchable.Select--single:not(.is-open) .Select-control,
        .Select.is-searchable.Select--single:not(.is-focused) .Select-control {
          background-color: #fff;
        }
      }
    }
    
    @media (min-width: 992px) {
      &.formats-step {
        .audio-format.col-md-8 {
          .format-name+.form-group.audio-format-id {
            width: calc(50% - 111px);
          }
        }
      }
    }
  }

  .dropdown-placeholder {
    width: 70px;
    visibility: hidden;
  }

  .dropdown-loader {
    width: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .loader-container {
      position: relative;
      padding: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .dropdown.btn-group {
    width: 70px;
    @media (max-width: 767px) {
      width: 100%;
    }
    .pre {
      padding: 2px 5px;
      background-color: #444;
      color: #fff;
      white-space: nowrap;
      border-radius: 4px;
      font-size: 13px;
      line-height: 14px;
      & + .pre {
        margin-left: 2px;
      }
    }
    .btn {
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      color: #29A4CB !important;
      &:hover {
        color: #228daf !important;
      }
      &[aria-expanded="true"] {
        color: #575757 !important;
        &:hover {
          color: #282828 !important;
        }
      }
    }

    .dropdown-menu {
      > li > a {
        padding: 3px 10px;
        :hover, :focus {
          background-color: #eaeaea;
        }
      }
      &.dropdown-menu-right {
        background-color: #eaeaea;
        > li > a {
          :hover, :focus {
            background-color: #fff;
          }
        }
      }
    }
  }

  .audio-format, .captions-only {
    .format-name.with-prefill-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 16px;
      .dropdown.btn-group, .dropdown-loader {
        margin-left: 15px;
      }
      .dropdown-loader {
        margin-top: -4px;
        .loader-container {
          .react-spinner {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .captions-only {
    .format-name {
      margin-bottom: 12px !important;
    }
    &.captions-container {
      margin-bottom: 23px;
      .btn-add-another {
        margin-left: 27px;
        font-weight: 700;
        font-size: 13px;
        color: #28C0F0;
        &:hover {
          color: #1a99c1;
          cursor: pointer;
        }
      }
      .captions-item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        .form-group {
          margin: 0 !important;
          width: 100% !important;
        }
        .btn.icon-trash {
          font-size: 22px !important;
          line-height: 38px !important;
          color: #282828 !important;
          &:not([disabled]) {
            opacity: 0.8 !important;
            &:hover {
              opacity: 1 !important;
            }
          }
          &.btn-placeholder {
            visibility: hidden;
            color: transparent;
          }
        }
      }
    }
  }

  ${props => props.isNewQuote && css`
    .form-group {
      pointer-events: none !important;
      &.audio-configuration {
        button {
          visibility: hidden;
        }
      }
    }
  `}
`;

const StyledNoPreFill = styled(Alert)`
  border-radius: 0;
`;

Formats.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

Formats.defaultProps = {
  data: {}
};

export default Formats;
