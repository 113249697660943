import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import 'bootstrap';
import Loader from '../../components/Loader';
import {clearSearch, changeSearchInput, searchData, getRequests} from './actions.js';
import SearchInput from './components/SearchInput';
import SearchResults from './components/SearchResults';
import {SCOPE_ISSUES, SCOPE_TITLES} from './constants';
import backImg from '../../../assets/images/back-white.svg';

class Search extends Component {
  handleClose = redirectUrl => {
    const {dispatch, history, location} = this.props;
    if (typeof redirectUrl === 'string' && redirectUrl && location.pathname.length > 1 && redirectUrl.startsWith(location.pathname)) {
      history.push(redirectUrl);
      history.go();
    } else {
      const {body} = document;
      body.className = body.className.replace('search-open', '').trim();
      dispatch(clearSearch());
    }
  };

  handleChangeSearchInput = (value, isBlur) => {
    const {dispatch} = this.props;
    dispatch(changeSearchInput(value));
  };

  handleSearch = (value, callback) => {
    const {dispatch} = this.props;
    dispatch(searchData(value, callback));
  };

  handleLoadRequests = (scope, data) => {
    const {dispatch, search} = this.props;
    const params = {scope: scope.toLowerCase()};
    if (scope === SCOPE_ISSUES && data.source && data.source.toLowerCase() === 'description') {
      params.name = 'issue_description';
      params.value = search.appliedSearchString;
      params.issue_type_id = data.id;
    } else {
      if (scope === SCOPE_ISSUES) {
        params.name = 'issue_type_id';
      } else if (scope === SCOPE_TITLES) {
        params.name = 'series_id';
      } else {
        params.name = 'distributor_id';
      }
      params.value = data.id;
    }
    dispatch(getRequests(params));
  };

  render() {
    const {search} = this.props;
    const {loading, termLoading, matchingTerm, inputSearchString, appliedSearchString, results,
      loadingRequestsByParam
    } = search;
    const appliedSearch = results && Object.keys(results).length;
    return (
      <SearchStyled>
        {loading ? <Loader/> : null}
        <div className="search-fixed">
          <div className="pull-left">
            <div className="back-btn" onClick={this.handleClose}/>
            <form action="#" onSubmit={event => {event.preventDefault();}}>
              <SearchInput
                loading={termLoading}
                matchingTerm={matchingTerm}
                value={inputSearchString}
                appliedValue={appliedSearchString}
                onChange={this.handleChangeSearchInput}
                onEnter={this.handleSearch}
                />
            </form>
          </div>
          <div className="pull-right">
            <div className="icon-close-wizard" onClick={this.handleClose}/>
          </div>
        </div>
        <div className={`search-main${appliedSearch ? ' applied' : ''}`}>
          {
            appliedSearch ? (
              <SearchResults
                search={appliedSearchString}
                data={results}
                onClose={this.handleClose}
                onLoadRequests={this.handleLoadRequests}
                loadingRequestsByParam={loadingRequestsByParam}
                />
            ) : null
          }
        </div>
      </SearchStyled>
    );
  }
}

const SearchStyled = styled.div`
  z-index: 10040;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  display: none;
  background: linear-gradient(122.07deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D;
  transition: display 0.3s ease-in-out, height 0.3s ease-in-out;

  @media (max-width: 767px) {
    background: linear-gradient(100.26deg, #0DB4D9 0%, rgba(2, 80, 173, 0.25) 99.75%), #004A8D;
  }

  body.search-open & {
    display: block;
    height: auto;
    min-height: 100vh;
  }

  .search-fixed {
    padding: 37px 34px 0 53px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10060;
    display: flex;
    .pull-left {
      display: flex;
      width: calc(100% - 36px);
      form {
        width: 99%;
      }
    }
    .pull-right {
      display: flex;
      width: 36px;
    }
    .icon-close-wizard {
      margin-top: 3px;
      color: #fff;
      font-weight: normal;
      font-size: 36px;
      line-height: 1;
      &:hover {
        cursor: pointer;
        color: #f3f3f3;
      }
    }
    @media (max-width: 767px) {
      padding: 0 25px;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .back-btn {
        background: url(${backImg}) no-repeat;
        background-position: 0 3px;
        height: 20px;
        width: 20px;
        margin-right: 15px;
      }
      .pull-left {
        width: unset;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        padding-right: 8px;
      }
      .pull-right {
        width: unset;
      }
      .icon-close-wizard {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .search-main {
    padding: 0 34px 0 53px;
    @media (min-width: 992px) {
      padding-right: 238px;
    }
    position: fixed;
    top: 108px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10060;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
    &.applied {
      background: #f0f0f0;
    }

    @media (max-width: 767px) {
      top: 63px;
      padding: 0;
    }
  }

  > .loader-container {
    z-index: 10080 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    .react-spinner {
      top: 49% !important;
    }
  }
`;

Search.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    search: state.search
  };
}

export default connect(
  mapStateToProps
)(withRouter(Search));
