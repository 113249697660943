import $ from 'jquery';
import config from '../../../../../config';
import {getErrorMessage} from '../../../../utils/general';
import {showError} from '../../../../layouts/actions';
import {setQcProfile} from "../../../Settings/actions";

export const NET_ADMIN_STEPS_SET_LOADING = 'NET_ADMIN_STEPS_SET_LOADING';
export const NET_ADMIN_STEPS_CHANGE_TOP_FILTERS = 'NET_ADMIN_STEPS_CHANGE_TOP_FILTERS';
export const NET_ADMIN_STEPS_RECEIVE = 'NET_ADMIN_STEPS_RECEIVE';
export const NET_ADMIN_QC_PROFILE_RECEIVE = 'NET_ADMIN_QC_PROFILE_RECEIVE';

export const setLoading = (name, loading) => ({type: NET_ADMIN_STEPS_SET_LOADING, name, loading});

export const getProfile = profileID => dispatch => {
  const loadingName = 'isLoadingSteps';
  dispatch(setLoading(loadingName, true));
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/netflix/admin/qc_profile/${profileID}`
  })
      .done(res => {
        dispatch({
          type: NET_ADMIN_QC_PROFILE_RECEIVE,
          ...res
        });

        res.qc_tasks.forEach(task => {
          task['task_id'] = task.NetflixQcTaskID;
          task['qctype'] = task.TaskLabel || task.TaskName;
        });

        dispatch(setQcProfile({
          templateBuilderSettings: {
            qcProfileData: {},
            steps: res.steps,
            combinations: res.qc_tasks,
          }
        }));
      })
      .fail(error => {
        dispatch({
          type: NET_ADMIN_QC_PROFILE_RECEIVE
        });
        dispatch(showError(`Could not get the Netflix steps.  ${getErrorMessage(error)}`));
      })
      .always(() => {
        dispatch(setLoading(loadingName, false));
      });
};