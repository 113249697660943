import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled, {css} from 'styled-components';
import {
    StyledBaseCloseModal,
    StyledMobileModal,
    StyledModal,
    StyledModalBackdrop,
    StyledModalDialog,
    StyledModalSubmitFooter
} from '../../../../styled/ModalStyled';
import {saveBatchEditIssues} from '../../actions.js';
import {Button, Checkbox, FormControl} from 'react-bootstrap';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import Table from "../../../../components/table/Table";
import {TimeCodeCell} from "../table/TimeCodeCell";
import {SeverityCell} from "../table/SeverityCell";
import Loader from "../../../../components/Loader";

const Steps = {
    ISSUES_TABLE: 1,
    EDIT_ISSUES: 2,
    DELETE_ISSUES: 3,
};

const IssueFields = {
    STATUS: 1,
    SEVERITY: 2,
    DESCRIPTION: 3,
}

const CheckboxHeaderCell = ({className, isChecked, onChange}) => (
    <th className={className}>
        <Checkbox
            title={isChecked() ? 'Deselect All' : 'Select All'}
            checked={isChecked()}
            onChange={({target: {checked}}) => onChange(checked)}
        />
    </th>
);

const CheckboxCell = ({row, className, isChecked, onChange}) => (
    <td className={className}>
        <Checkbox
            checked={isChecked(row)}
            onChange={({target: {checked}}) => onChange(checked, row)}
        />
    </td>
);

const BatchEditIssuesModal = props => {
    const {dispatch, reportdetails} = props;
    const {issue_severities, issue_statuses} = reportdetails.filters;

    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, []);

    const modalBodyRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(Steps.ISSUES_TABLE);
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);

    const [issueStatusId, setIssueStatusId] = useState(null);
    const [issueSeverityId, setIssueSeverityId] = useState(null);
    const [issueDescription, setIssueDescription] = useState(null);

    const handleClose = () => props.onCloseModal();

    const handleBack = () => {
        try {
            modalBodyRef.current.scrollTop = 0;
        } catch {}
        setCurrentStep(Steps.ISSUES_TABLE);
    }

    const handleSubmitSelectedIssuesForEdit = () => {
        setCurrentStep(Steps.EDIT_ISSUES);
    };

    const handleSubmitSelectedIssuesForDelete = () => {
        setCurrentStep(Steps.DELETE_ISSUES);
    };

    const handleSubmitEdit = () => {
      const batchEditIssueParams = {};

      const includeStatusForSave = selectedFields.some(field => field === IssueFields.STATUS);
      if (includeStatusForSave && issueStatusId != null) {
        batchEditIssueParams['status_id'] = issueStatusId;
      }

      const includeSeverityForSave = selectedFields.some(field => field === IssueFields.SEVERITY);
      if (includeSeverityForSave && issueSeverityId != null) {
        batchEditIssueParams['severity_id'] = issueSeverityId;
      }

      const includeDescriptionForSave = selectedFields.some(field => field === IssueFields.DESCRIPTION);
      if (includeDescriptionForSave && issueDescription != null && issueDescription.length > 0) {
        batchEditIssueParams['description'] = issueDescription;
      }

      if (Object.keys(batchEditIssueParams).length === 0) {
        alert('Nothing to save');
        return;
      }

      batchEditIssueParams['issue_ids'] = selectedIssues.map(i => i.IssueID);
      dispatch(saveBatchEditIssues(reportdetails.details.RequestID, batchEditIssueParams))
        .then(() => handleClose());
    };

    const handleSubmitDelete = () => {
        const batchEditIssueParams = {
            delete: true
        };
        batchEditIssueParams['issue_ids'] = selectedIssues.map(i => i.IssueID);
        dispatch(saveBatchEditIssues(reportdetails.details.RequestID, batchEditIssueParams))
            .then(() => handleClose());
    }

    const handleFieldValueChanged = (name, value) => {
        if (name === 'status_id') {
            setIssueStatusId(value);
            return;
        }

        setIssueSeverityId(value);
    };

    const handleTextareaBlur = event => {
        setIssueDescription(event.target.value);
    };

    const isSelected = row => selectedIssues.some(({IssueID}) => IssueID === row.IssueID);

    const handleSelect = (checked, row) => {
        if (checked) {
            setSelectedIssues([...selectedIssues, row]);
            return;
        }
        setSelectedIssues(selectedIssues.filter(({IssueID}) => IssueID !== row.IssueID));
    };

    const headers = {
        IssueID: {
            title: '',
            headCell: {
                component: CheckboxHeaderCell,
                componentArgs: {
                    className: 'checkbox-cell',
                    onChange: checked => setSelectedIssues(checked ? reportdetails.issues : []),
                    isChecked: () => {
                        const allLength = reportdetails.issues.length;
                        return selectedIssues.length === allLength && allLength > 0;
                    }
                }
            },
            className: 'checkbox-cell',
            component: CheckboxCell,
            componentArgs: {
                isChecked: isSelected,
                onChange: handleSelect
            }
        },
        Timecode: {
            title: 'Timecode',
            component: TimeCodeCell
        },
        LocationAndChannel: {
            title: 'Location',
            className: 'location'
        },
        Category: {
            title: 'Category',
            className: 'capitalized-all'
        },
        IssueType: {
            title: 'Type',
            className: 'capitalized-all'
        },
        Description: {
            title: 'Description',
            className: 'description capitalized-all'
        },
        Severity: {
            title: 'Severity',
            component: SeverityCell
        },
        Status: {
            title: 'Status'
        },
    };

    const renderIssueTable = () => {
        return (
          <div>
            <div>Choose issues</div>
            <Table
                className="choose-issues"
                stripped={false}
                headers={headers}
                rows={reportdetails.issues}
                emptyText="No issues available for edit"
            />
          </div>
        );
    }

    const renderEditFields = () => {
        const includeStatusForSave = selectedFields.some(field => field === IssueFields.STATUS);
        return (
            <div>
                <div>Choose the batch action(s) you wish to perform on the selected issues</div>
                <table className="batch-actions">
                    <tbody>
                    <tr>
                        <CheckboxCell
                          className="field-checkbox"
                          isChecked={() => includeStatusForSave}
                          onChange={checked => checked ? setSelectedFields([...selectedFields, IssueFields.STATUS]) : setSelectedFields(selectedFields.filter(field => field !== IssueFields.STATUS))}
                        />
                        <td className="field-label">Status</td>
                        <td className="field-value">
                          <div>
                            <SelectAdvanced
                                labelSeparator=""
                                name="status_id"
                                placeholder="Select Status"
                                options={issue_statuses}
                                value={issueStatusId}
                                onSelect={handleFieldValueChanged}
                            />
                          </div>
                        </td>
                    </tr>
                    <tr>
                        <CheckboxCell
                            className="field-checkbox"
                            isChecked={() => selectedFields.some(field => field === IssueFields.SEVERITY)}
                            onChange={checked => checked ? setSelectedFields([...selectedFields, IssueFields.SEVERITY]) : setSelectedFields(selectedFields.filter(field => field !== IssueFields.SEVERITY))}
                        />
                        <td className="field-label">Severity</td>
                        <td className="field-value">
                            <div>
                                <SelectAdvanced
                                    labelSeparator=""
                                    name="severity_id"
                                    placeholder="Select Severity"
                                    options={issue_severities}
                                    value={issueSeverityId}
                                    onSelect={handleFieldValueChanged}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <CheckboxCell
                            className="field-checkbox"
                            isChecked={() => selectedFields.some(field => field === IssueFields.DESCRIPTION)}
                            onChange={checked => checked ? setSelectedFields([...selectedFields, IssueFields.DESCRIPTION]) : setSelectedFields(selectedFields.filter(field => field !== IssueFields.DESCRIPTION))}
                        />
                        <td className="field-label">Description</td>
                        <td className="field-value">
                            <div>
                                <FormControl
                                    name="issue_description"
                                    type="text"
                                    componentClass="textarea"
                                    rows="3"
                                    placeholder="Enter issue description"
                                    defaultValue={issueDescription}
                                    onChange={handleTextareaBlur}
                                    onBlur={handleTextareaBlur}
                                />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const renderConfirmDeleteIssues = () => {
        return (
            <div>
                <div>Are you sure you want to delete the selected issues?</div>
            </div>
        );
    }

    const hasSelectedIssues = selectedIssues.length > 0;

    return (
        <StyledMobileModal>
            <StyledModalBackdrop
                onClick={handleClose}
            />
            <StyledModalDialog className="mobile-modal-dialog-custom">
                {reportdetails.completing && <Loader className="full-screen"/>}
                <StyledModal>
                    <StyledModalHeader className="modal-header-custom">
                        <div className="back-btn" onClick={currentStep === Steps.ISSUES_TABLE ? handleClose : handleBack}/>
                        <div className="modal-header-content">
                            <h4>Batch Edit Issues</h4>
                            <div>
                                <StyledBaseCloseModal className="icon-close-modal" title="Cancel" onClick={handleClose}/>
                            </div>
                        </div>
                    </StyledModalHeader>
                    <StyledModalBody
                        ref={modalBodyRef}
                        className="modal-body-custom"
                        currentStep={currentStep}
                    >
                        {currentStep === Steps.ISSUES_TABLE &&
                          renderIssueTable()
                        }
                        {currentStep === Steps.EDIT_ISSUES &&
                          renderEditFields()
                        }
                        {currentStep === Steps.DELETE_ISSUES &&
                          renderConfirmDeleteIssues()
                        }
                    </StyledModalBody>
                    <StyledSubmitModalFooter>
                        <div>
                            {currentStep === Steps.ISSUES_TABLE &&
                                <>
                                  <Button bsStyle="primary" onClick={handleSubmitSelectedIssuesForEdit} disabled={!hasSelectedIssues}>Edit</Button>
                                  <Button bsStyle="primary" onClick={handleSubmitSelectedIssuesForDelete} disabled={!hasSelectedIssues}>Delete</Button>
                                </>
                            }
                            {currentStep === Steps.EDIT_ISSUES && <Button bsStyle="primary" onClick={handleSubmitEdit}>Save</Button>}
                            {currentStep === Steps.DELETE_ISSUES && <Button bsStyle="primary" onClick={handleSubmitDelete}>Save</Button>}
                            <Button bsStyle="default" onClick={handleClose}>Cancel</Button>
                            {(currentStep === Steps.EDIT_ISSUES || currentStep === Steps.DELETE_ISSUES) && <Button bsStyle="default" onClick={handleBack}>Back to Select Issues</Button>}
                        </div>
                    </StyledSubmitModalFooter>
                </StyledModal>
            </StyledModalDialog>
        </StyledMobileModal>
    );
};

const StyledModalHeader = styled.div`
  padding: 31px 35px 0 45px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    padding: 8.5px 0 8.5px 23px;
    @media (max-width: 767px) {
      padding:0 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  position: fixed;
  top: 91px;
  bottom: 71px;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 46px;
  padding-right: 27px;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    ${props => props.currentStep === Steps.START && css`
      overflow-y: hidden;
    `}
    ${props => props.currentStep !== Steps.START && css`
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
    `}
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    bottom: 83px;
    padding: 0 23px;
  }

  .loader-container {
    z-index: 1070;
  }

  table.choose-issues {
    th {
      text-align: start;
    }

    th .checkbox {
      margin: 0;
      max-height: 20px;
    }

    td .checkbox {
      margin: 0;
    }
  }

  table.batch-actions {
    margin-top: 20px;
  }

  td.field-checkbox {
    vertical-align: top;
    .checkbox {
      margin-top: 6px;
    }
  }
  td.field-label {
    width: 150px;
    vertical-align: top;
    padding-top: 7px;
  }
  td.field-value {
    width: 500px;
  }
`;

const StyledSubmitModalFooter = styled(StyledModalSubmitFooter)`
  background-color: #F4F4F4;
  box-shadow: 0px -4px 0 #fff;
  > div {
    float: none;
  }
  button {
    margin-right: 18px;
    &.btn-default {
      background: #ddd !important;
      @media (min-width: 768px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
        &:hover {
          background: #ccc !important;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
        }
      }
    }
  }
`;

BatchEditIssuesModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        reportdetails: state.reportdetails
    };
}

export default connect(
    mapStateToProps
)(BatchEditIssuesModal);
