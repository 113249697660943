import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {StyledCloseModal} from '../../../../styled/ModalStyled';
import {Modal, Button} from 'react-bootstrap';
import Draggable from 'react-draggable';
import InputAdvanced from '../../../../components/forms/InputAdvanced';
import backImg from '../../../../../assets/images/back.svg';

const MODAL_WIDTH = 380;

class EditFrameRateModal extends Component {

    state = {
        frameRate: this.props.timeCodeSettings.reportFrameRate || '',
        startTimeCode: this.props.timeCodeSettings.startFileTimecode || ''
    };

    componentDidMount() {
        document.body.classList.add('modal-open--overflow-auto');

        const timer = setInterval(() => {
            const modalDialog = document.getElementsByClassName('modal-dialog--framerate')[0];
            if (modalDialog) {
                try {
                    if (window.innerHeight > modalDialog.clientHeight) {
                        modalDialog.parentElement.style.top = `${Math.floor((window.innerHeight - modalDialog.clientHeight) / 2)}px`;
                    }
                    clearInterval(timer);
                } catch (e) {
                    clearInterval(timer);
                }
                try {
                    modalDialog.getElementsByClassName('form-control')[0].focus();
                } catch {}
            }
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open--overflow-auto');
    }

    handleClose = () => {
        const {onClose} = this.props;
        onClose();
    };

    handleConfirm = () => {
        const {onConfirm} = this.props;
        let frameRate = this.state.frameRate;

        if ((frameRate || '').trim()) {
            onConfirm(frameRate, this.state.startTimeCode);
        }
    };


    handleChangeFrameRate = (name, value) => {
        this.setState({frameRate: value});
    };

    handleBlurFrameRate = (name, value) => {
        this.setState({frameRate: value});
    }

    handleChangeStartTimeCode = (name, value) => {
        this.setState({startTimeCode: value});
    };

    handleBlurStartTimeCode = (name, value) => {
        this.setState({startTimeCode: value});
    }

    handleKeyDown = keyCode => {
        try {
            if (keyCode === 13) {
                this.handleConfirm();
            }
        } catch {}
    };

    render() {
        const {frameRate, startTimeCode} = this.state;
        const {timeCodeSettings} = this.props;
        return (
            <Draggable handle=".modal-header">
                <StyledModal
                    animation={false}
                    backdrop={false}
                    show
                    onHide={this.handleClose}
                    dialogClassName="modal-dialog--framerate"
                >
                    <Modal.Header>
                        <div className="back-btn" onClick={() => this.handleClose()}/>
                        <h4>Frame Rate</h4>
                        <StyledCloseModal className="icon-close-modal" onClick={() => this.handleClose()}/>
                    </Modal.Header>
                    <Modal.Body>
                        <InputAdvanced
                            label="Frame rate"
                            labelSeparator=""
                            placeholder="00.00"
                            value={frameRate || ''}
                            forceReset
                            onChange={this.handleChangeFrameRate}
                            onBlur={this.handleBlurFrameRate}
                            onKeyDown={this.handleKeyDown}
                        />
                        {timeCodeSettings.startFileTimecode ? null : <InputAdvanced
                            label="Enter SMPTE timecode for create the 'Start of File' issue"
                            labelSeparator=""
                            placeholder="00:00:00:00"
                            value={startTimeCode || ''}
                            forceReset
                            onChange={this.handleChangeStartTimeCode}
                            onBlur={this.handleBlurStartTimeCode}
                            onKeyDown={this.handleKeyDown}
                          />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="default"
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleConfirm}
                            disabled={!frameRate}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </StyledModal>
            </Draggable>
        );
    }
}

const StyledModal = styled(Modal)`
  padding: 0 !important;
  width: ${MODAL_WIDTH}px;
  height: fit-content;
  max-height: calc(100vh - 2px);
  left: calc((100vw - ${MODAL_WIDTH}px) / 2);
  transition: top .15s ease-out 0.01s;

  @media (max-width: ${MODAL_WIDTH}px) {
    width: 98vw;
    height: 90vh;
    top: 5vh;
    left: 1vw;
  }

  * {
    font-family: 'Roboto', sans-serif !important;
  }

  .modal-dialog {
    width: auto;
    margin: 0;

    .modal-content {
      box-shadow: none;
      border-radius: 0;

      .modal-header {
        padding: 24px 24px 0;
        height: 69px;
        position: relative;
        background-color: #F9F9F9;
        border: none;
        h4 {
          font-weight: normal;
          font-size: 18px;
          line-height: 19px;
          color: #282828;
          margin: 0;
        }
        .icon-close-modal {
          top: 21px;
          right: 25px;
        }

        @media (max-width: 767px) {
          padding: 19px 0 0;
          background-color: #fff;

          h4 {
            font-weight: bold;
            line-height: 20px;
            padding-left: 56px;
          }

          .icon-close-modal {
            top: 22px;
            right: 31px;
            font-size: 13px;
            &:before {
              content: 'Cancel';
              font-family: 'Roboto', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 14px;
              color: #999;
            }
          }

          .back-btn {
            background: url(${backImg}) no-repeat;
            background-position: 0;
            height: 15px;
            width: 20px;
            position: absolute;
            top: 22px;
            left: 23px;
          }
        }
      }

      .modal-body {
        padding: 15px 24px;
        max-height: calc(100vh - 69px - 71px - 4px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        label {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;

          @media (max-width: 767px) {
            font-size: 15px;
            line-height: 16px;
          }
        }

        .form-control {
          &::placeholder {
            opacity: 0.75 !important;
          }
        }
      }

      .modal-footer {
        height: 71px;
        padding: 17px 24px 0;
        background-color: #F9F9F9;
        border: none;

        @media (max-width: 767px) {
          padding: 0;
        }

        button {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            color: #fff;
            background-color: #646464 !important;
            border-radius: 100px;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            padding: 11px 40px 12px;

            &.btn-primary {
              @media (min-width: 992px) {
                &:hover:not([disabled]) {
                  background-color: #565656 !important;
                }
              }

              &[disabled] {
                color: #c8c8c8;
                /*cursor: default;*/
              }

              @media (max-width: 767px) {
                border-radius: 0;
                text-transform: uppercase;
                font-size: 13px;
                line-height: 14px;
                background-color: #484848 !important;
                margin: 0;
                height: 100%;
                width: 100%;
              }
            }

            &.btn-default {
              background-color: transparent !important;
              color: #282828;
              padding-left: 0;
              padding-right: 0;
              margin-right: 18px;

              @media (max-width: 767px) {
                display: none;
              }
            }
        }
      }
    }
  }
`;

EditFrameRateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    timeCodeSettings: PropTypes.object.isRequired,
};

export default EditFrameRateModal;
