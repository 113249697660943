import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {
  showEditWoStepsModal,
  getDictionariesForEditSteps
} from '../actions';
import Loader from '../../../components/Loader';
import Table from '../../../components/table/Table';
import DateCell from '../../../components/table/cells/DateCell';
import CostCell from './tablecells/CostCell';
import {IsBillableCell} from './tablecells/IsBillableCell';
import {HoursSpentCell} from './tablecells/HoursSpentCell';
import {checkDiscountPercentStep} from './editWorkOrderStepsTable/utils';
import {
  isDisabledEditingRequestStep,
  isNeedsFixesRequest,
  isPassedRequest,
} from '../../../utils/general';
import {CONTENT_TYPES} from "../../NewRequest/constants";
import {confirmAlert} from 'react-confirm-alert';

function WorkOrderTab(props) {
  const {dispatch, details, subSteps, isLoadingSubSteps, onClickEdit} = props;
  const isNeedsFixesRequestStatus = isNeedsFixesRequest(details);
  const isPassedQcStatus = isPassedRequest(details);
  const isCreatedWO = Boolean(details.WOID);
  const isBilledRequest = Boolean(details.IsBilled);
  const isDisabledEditingWOStep = !isPassedQcStatus && !isNeedsFixesRequestStatus && isDisabledEditingRequestStep(details);

  const onEditSteps = () => {
    if (onClickEdit) {
      onClickEdit();
      return;
    }
    dispatch(getDictionariesForEditSteps());
    dispatch(showEditWoStepsModal());
  };

  const handleEditSteps = () => {
    if (isBilledRequest) {
      confirmAlert({
        childrenElement: () => (
          <div style={{fontWeight: 500, fontSize: '14px', lineHeight: '19px'}}>
            This request has already been billed and is locked for further editing. Please contact the billing department.
          </div>
        ),
        buttons: [{
          label: 'Cancel'
        }, {
          label: 'OK'
        }, {
          label: 'Add Patch Steps',
          onClick: onEditSteps
        }]
      });
    } else {
      onEditSteps();
    }
  };

  const productCodeCell = ({value, row, className}) => (
    <td className={className} title="Product Code">
      {row.hasOwnProperty('Cost') && row.Cost == null ? <span style={{fontWeight: "bold", color: "#E75592"}}>{value}</span> : value}
    </td>
  );

  const headers = {
    StepOrder: {
      title: 'Step',
      className: 'step-order'
    },
    Details: {
      title: 'Description',
      className: 'step-description'
    },
    Label: {
      title: 'Label',
      className: 'step-label'
    },
    ProductCode: {
      title: 'Product Code',
      className: 'product-code',
      component: productCodeCell
    },
    Unit: {
      title: 'Unit',
      className: 'step-unit'
    },
    Assignee: {
      title: 'Assigned To',
      className: 'assigned-to'
    },
    HoursSpent: {
      title: 'Time Spent',
      className: 'time-spent',
      component: HoursSpentCell,
      componentArgs: {
        defaultValue: '-'
      }
    },
    Status: {
      title: 'Status',
      className: 'status'
    },
    ...(isCreatedWO ? {
      CompletionTime: {
        title: 'Completion Time',
        className: 'completion-time',
        component: DateCell,
        componentArgs: {
          defaultValue: '-',
          fromFormat: 'x',
          toFormat: 'll[\r\n]hh:mm A z',
          useTimezone: true
        }
      }
    } : {}),
    IsBillable: {
      title: 'Is Billable',
      className: 'is-billable',
      component: IsBillableCell
    },
    Cost: {
      title: 'Cost',
      className: 'cost',
      component: CostCell
    },
    Rate: {
      title: 'Rate',
      className: 'rate',
      component: CostCell
    },
    OverrideRate: {
      title: 'Override Rate',
      className: 'override-rate',
      component: CostCell,
      componentArgs: {
        isPercent: (step) => checkDiscountPercentStep(step, isCreatedWO),
        onChange: () => {}
      }
    },
    Quantity: {
      title: 'Quantity',
      className: 'quantity',
      component: CostCell,
      componentArgs: {
        onChange: () => {},
        isDecimal: true
      }
    },
    EstimatedHours: {
      title: 'Est. Hours',
      className: 'estimated-hours',
      component: HoursSpentCell,
      componentArgs: {
        asNumber: true
      }
    },
  };

  let stepsDisableEditHint = null;
  if (isDisabledEditingWOStep) {
    stepsDisableEditHint = (
      <div
        className="disabledEditingMsg"
      >
        You can not edit steps after a work order is cancelled or completed.
      </div>
    );
  } else if ((isNeedsFixesRequestStatus || isPassedQcStatus) && details.MemfisStatus) {
    stepsDisableEditHint = (
      <div
        className="disabledEditingMsg"
      >
        <i
          className="fal fa-triangle-exclamation"
          style={{margin: '0 5px', fontSize: '15px'}}
        />
        WO MEMFIS Status: {details.MemfisStatus}
      </div>
    );
  }

  let editStepsDescription = null;
  if (details.ContentTypeID === CONTENT_TYPES.Season) {
    editStepsDescription = (
        <div>This list of steps will be apply only for NEW episodes. Any changes will not affect existing episodes (requests).</div>
    );
  }

  return (
    <StyledWorkOrder>
      {isLoadingSubSteps ? <Loader/> : null}
      { stepsDisableEditHint }
      { editStepsDescription }
      <div className="sub-steps">
        <div className="table-block">
          <Table
            headers={headers}
            rows={subSteps}
            stripped={false}
            getRowClassNames={row => `${row.StepStatusID === 0 ? 'canceled' : ''}${isDisabledEditingWOStep ? ' disabled' : ''}`}
            emptyText={isLoadingSubSteps ? 'Loading...' : 'We could not find the steps for this request'}
          />
        </div>
        { !isDisabledEditingWOStep &&
          <div className="table-controls">
            <Button
              bsStyle="primary"
              disabled={isLoadingSubSteps}
              onClick={handleEditSteps}
            >
              Edit Steps
            </Button>
          </div>
        }
      </div>
    </StyledWorkOrder>
  );
}

const StyledWorkOrder = styled.div`
  position: relative;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    + div, + div + div {
      opacity: 0.5;
    }
  }

  .disabledEditingMsg {
    margin-bottom: 10px;
  }

  .sub-steps {
    background: #f9f9f9;
    margin-bottom: 15px;
    border-radius: 3px;
    padding: 10px 0 14px;

    .table-block {
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;

      .table {
        border-collapse: separate;
        border-spacing: 0;
        margin: 0;

        > thead {
          > tr > th {
            vertical-align: bottom;
            border: none;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            text-align: left;
            white-space: nowrap;
            color: rgba(40, 40, 40, 0.7);
            padding: 0 10px 5px 0;
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 16px;
            }
            &.step-unit {
              min-width: 90px;
            }
          }
        }

        > tbody > tr {
          > td {
            vertical-align: middle;
            border: none;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: left;
            color: rgb(29, 28, 29);
            padding: 12px 10px 12px 0;
            background: rgba(255, 255, 255, 0.5);
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 16px;
            }
            &.step-unit {
              min-width: 90px;
            }
            &.completion-time {
              padding-top: 11px;
              padding-bottom: 11px;
              line-height: 14px;
              white-space: pre-wrap;
            }
            &.data-table-empty {
              padding: 15px;
              text-align: center;
              color: rgba(40, 40, 40, 0.7);
            }
            span, div {
              pointer-events: none;
            }
          }

          &.canceled {
            text-decoration: line-through;
            .form-control.on-edit-mode {
              text-decoration: line-through;
            }
          }

          &.canceled, &.disabled {
            pointer-events: none;
          }
        }
      }
    }

    .table-controls {
      .btn {
        margin-left: 10px;
      }
    }
  }
`;

WorkOrderTab.propTypes = {
  details: PropTypes.object.isRequired,
  subSteps: PropTypes.array.isRequired,
  isLoadingSubSteps: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func
};

export default connect()(WorkOrderTab);
