import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {formatDate} from '../../../utils/general';
import {
  NEW_REQUEST_STEP_CHOICES, NEW_REQUEST_STEP_REVIEW_AND_CONFIRM
} from '../../NewRequest/constants';
import {Row, Col, Button, Clearfix} from 'react-bootstrap';

class StepFooter extends Component {
  render() {
    const {resumeData, steps, data, currentStep, onResume, onBack, onCancel, onNext} = this.props;
    if (currentStep === NEW_REQUEST_STEP_CHOICES) {
      return resumeData && (!data || !data.request_type_id) ? (
        <StyledStepFooter className="step-content-container step-footer step-choices step-resume">
          <hr/>
          <div className="info">
            There is an auto-saved version of the request you began creating on <b>{formatDate(resumeData.date, '', 'MMMM D, YYYY HH:mm A')}</b>. Would
            you like to resume creation of that request?
          </div>
          <div className="action-resume" onClick={onResume}>
            Resume
          </div>
        </StyledStepFooter>
      ) : null;
    }
    return (
      <StyledStepFooter className="step-content-container step-footer">
        <Row>
          <div className="col-progress-bar clearfix">
            <div className={`current-progress${currentStep === NEW_REQUEST_STEP_REVIEW_AND_CONFIRM ? ' submit' : ''}`}>
              {steps.map((s, i) => (i === 0 || s.id > currentStep) ? null : (
                <div
                  key={`header-step-${i}`}
                  className={`header-step${s.id === currentStep ? ' header-current-step' : ''}`}
                  >
                  {typeof s.name === 'string' ? s.name : s.name[data.request_type_id]}
                </div>
              ))}
            </div>
          </div>
        </Row>
        <Row className="footer-buttons">
          <Col md={6} xs={6} className="col-back-button">
            <Button
              title="Back"
              className="btn-back"
              bsStyle="default"
              onClick={onBack}
              disabled={!onBack}
              >
              Back
            </Button>
          </Col>
          <Col md={6} xs={6} className="col-cancel-next-buttons">
            <div className="buttons-container">
              <Button
                title="Cancel"
                bsStyle="default"
                onClick={onCancel}
                >
                Cancel
              </Button>
              <Button
                title={currentStep >= NEW_REQUEST_STEP_REVIEW_AND_CONFIRM ? 'Submit' : 'Next'}
                bsStyle="primary"
                onClick={onNext}
                disabled={!onNext}
                >
                {currentStep >= NEW_REQUEST_STEP_REVIEW_AND_CONFIRM ? 'Submit' : 'Next'}
              </Button>
            </div>
          </Col>
        </Row>
      </StyledStepFooter>
    );
  }
}

const StyledStepFooter = styled(Clearfix)`
  &.step-footer {
    &.step-resume {
      color: #fff;
      text-align: center;
      height: 211px;
      width: calc(293px*3 + 39px*2 + 15px*2);

      hr {
        margin: 0;
        padding: 0;
        color: #fff;
        opacity: 0.2;
      }

      .info {
        width: 533px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: normal;
        margin: 35px auto 16px;

        b {
          font-weight: 500;
        }
      }

      .action-resume {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
        width: 128px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 38px;
        border-radius: 100px;
        margin: 0 auto;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    &:not(.step-resume) {

      .col-progress-bar {
        width: 100%;
        height: 6px;
        overflow: hidden;
        background-color: #fff;

        .current-progress {
          height: 6px;
          background-color: #E75592;
          float: left;

          .header-step {
           display: inline-block;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            opacity: 0;
            margin: 0 0 0 28px;

            &:first-child {
              margin-left: 88px;
            }

            &:last-child {
              margin-right: 26px;
            }

            &.header-current-step {
              font-weight: 500;
              font-size: 15px;
            }
          }

          &.submit {
            .header-step:last-child {
              margin-right: 40px;
            }
          }
        }
      }

      .footer-buttons {
        background-color: #f3f3f3;
        height: 71px;
        padding-top: 17px;

        .col-back-button {
          padding-left: 88px;
        }

        .col-cancel-next-buttons {

          .buttons-container {
            float: right;
            margin-right: 12px;

            @media (min-width: 768px) {
              margin-right: 92px;
            }

            .btn-primary {
              margin-left: 18px;
              padding: 0 50px;
            }
          }
        }
      }
    }
  }
`;

StepFooter.propTypes = {
  resumeData: PropTypes.object,
  data: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  onResume: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func
};

export default StepFooter;
