import axiosInstance from '../../utils/axios';
import {getErrorMessage} from '../../utils/general';
import {showError, hideAllNotifications} from '../../layouts/actions';
import {SCOPE_COMMENTS} from './constants';

export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CHANGE_INPUT = 'SEARCH_CHANGE_INPUT';
export const SEARCH_TERM_IS_LOADING = 'SEARCH_TERM_IS_LOADING';
export const SEARCH_TERM_IS_DONE = 'SEARCH_TERM_IS_DONE';
export const SEARCH_APPLY_INPUT = 'SEARCH_APPLY_INPUT';
export const SEARCH_IS_LOADING = 'SEARCH_IS_LOADING';
export const SEARCH_IS_DONE = 'SEARCH_IS_DONE';
export const SEARCH_REQUESTS_IS_LOADING = 'SEARCH_REQUESTS_IS_LOADING';
export const SEARCH_REQUESTS_IS_DONE = 'SEARCH_REQUESTS_IS_DONE';

export const clearSearch = () => dispatch => {
  dispatch(hideAllNotifications());
  dispatch({type: SEARCH_CLEAR});
};

export const changeSearchInput = (value, isBlur) => (dispatch, getState) => {
  dispatch(hideAllNotifications());
  dispatch({type: SEARCH_CHANGE_INPUT, value});
  const state = getState();
  const {appliedSearchString} = state.search;
  if (!value || value.length < 3 || (value === appliedSearchString && isBlur === true)) {
    return;
  }
  dispatch({type: SEARCH_TERM_IS_LOADING, loading: true});
  axiosInstance.get(`/v1/qc_on_demand/search/term`, {
    params: {
      phrase: value
    }
  })
  .then(res => {
    dispatch({type: SEARCH_TERM_IS_DONE, matchingTerm: res});
  })
  .catch(error => {
    dispatch({type: SEARCH_TERM_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error), 'Search Term'));
  });
};

export const searchData = (value, callback) => dispatch => {
  dispatch(hideAllNotifications());
  dispatch({type: SEARCH_APPLY_INPUT, value});
  if (!value) {
    callback();
    return;
  }
  dispatch({type: SEARCH_IS_LOADING, loading: true});
  axiosInstance.get(`/v1/qc_on_demand/search`, {
    params: {
      phrase: value
    }
  })
  .then(res => {
    const comments = res[SCOPE_COMMENTS.toLowerCase()];
    if (comments && comments.total) {
      const commentMaxLength = 60;
      const {data} = comments;
      data.forEach(item => {
        const {Comment} = item;
        const substrs = [];
        if (Comment.length > commentMaxLength) {
          const temp = new RegExp(`(\\b\\w*${value})`, 'i').exec(Comment) || new RegExp(`(${value})`, 'i').exec(Comment);
          if (temp) {
            const {index} = temp;
            if (index > 0) {
              substrs.push('');
            }
            let endIndex = index + Math.max(value.length + 3, commentMaxLength);
            while ((endIndex + 1) < Comment.length && Comment[endIndex + 1].match(/\w/)) {
              endIndex++;
            }
            substrs.push(Comment.substring(index, ++endIndex).trim());
            if (endIndex < Comment.length) {
              substrs.push('');
            }
          }
        }
        item.preparedComment = substrs.join('...') || Comment;
      });
    }
    dispatch({type: SEARCH_IS_DONE, results: {...res, [SCOPE_COMMENTS.toLowerCase()]: comments}});
    callback();
  })
  .catch(error => {
    dispatch({type: SEARCH_IS_LOADING, loading: false});
    dispatch(showError(getErrorMessage(error), 'Search'));
  });
};

export const getRequests = reqParams => dispatch => {
  dispatch(hideAllNotifications());
  dispatch({type: SEARCH_REQUESTS_IS_LOADING, loadingByParam: reqParams});
  axiosInstance.get(`/v1/qc_on_demand/search/requests`, {
    params: {
      filters: JSON.stringify({[reqParams.name]: reqParams.value})
    }
  })
  .then(res => {
    dispatch({type: SEARCH_REQUESTS_IS_DONE, requests: res.requests});
  })
  .catch(error => {
    dispatch({type: SEARCH_REQUESTS_IS_LOADING, loadingByParam: null});
    dispatch(showError(getErrorMessage(error), 'Search Requests'));
  });
};
