import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config from '../../../../../config';

export const QcStatusCell = React.memo(({value, row, className, title}) => {
  const {QcStatusID} = row;
  let statusColor = '555';
  if (QcStatusID === config.qcStatus.QC_FAILED) {
    statusColor = 'ff3d00';
  } else if (QcStatusID === config.qcStatus.QC_PASS) {
    statusColor = '4eb947';
  } else if (QcStatusID === config.qcStatus.REVIEW) {
    statusColor = 'ffbf03';
  }
  return (
    <StyledCell
      className={className}
      title={title}
      statusColor={statusColor}
    >
      {value}
    </StyledCell>
  );
});

const StyledCell = styled.td`
  color: ${props => props.statusColor ? `#${props.statusColor}` : '#282828'} !important;
`;

QcStatusCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
};
