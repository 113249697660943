import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {SCOPE_REQUESTS, SCOPE_ISSUES, SCOPE_TITLES, SCOPE_COMMENTS} from '../constants';
import RequestsTable from './table/RequestsTable';
import SectionTable from './table/SectionTable';
import ViewAllLink from './table/ViewAllLink';
import {CommentsSection} from './comments/Section';
import {CommentsShowMore} from './comments/ShowMore';

class Section extends Component {
  state = {
    showMoreComments: false
  };

  handleToggleShowMoreComments = () => {
    this.setState({showMoreComments: !this.state.showMoreComments});
  };

  buildAnalyticsLink = (search, scope, data) => {
    let param;
    if (scope === SCOPE_ISSUES) {
      if (data.find(i => i.source && i.source.toLowerCase() === 'description')) {
        return `/analytics?issue_search=${search}`;
      }
      param = 'issue_types';
    } else if (scope === SCOPE_TITLES) {
      param = 'series';
    } else {
      param = scope.toLowerCase();
    }
    return `/analytics?${param}=${data.map(i => (i.id)).join(',')}`;
  };

  isCommentsScope = () => {
    const {scope} = this.props;
    return scope === SCOPE_COMMENTS;
  };

  isRequestsScope = () => {
    const {scope} = this.props;
    return scope === SCOPE_REQUESTS;
  };

  getViewAllUrl = () => {
    if (this.isCommentsScope()) {
      return null;
    }
    const {search, scope, scopeData} = this.props;
    const {data} = scopeData;
    return this.isRequestsScope() ? `/requests?cs_user[0]=-100&last_updated[0]=4&search=${search}` : this.buildAnalyticsLink(search, scope, data);
  };

  renderBody() {
    const {search, scope, scopeData, onCloseSearch, onLoadRequests, loadingRequestsByParam} = this.props;
    const {total, data} = scopeData;
    const isRequests = this.isRequestsScope();
    const isComments = this.isCommentsScope();
    const viewAllLinkTo = this.getViewAllUrl();
    return (
      <div className={`scope-section-body${isComments ? ' comments' : isRequests ? ' requests' : ''}`}>
        {
          isComments ?
            <CommentsSection
              data={data}
              total={total}
              onCloseSearch={onCloseSearch}
              showMoreComments={this.state.showMoreComments}
              onToggleShowMore={this.handleToggleShowMoreComments}
            /> : isRequests ? (
            <RequestsTable
              loading={false}
              data={data}
              total={total}
              viewAllLinkTo={viewAllLinkTo}
              onCloseSearch={onCloseSearch}
              />
          ) : (
            <SectionTable
              loading={false}
              search={search}
              scope={scope}
              data={data}
              total={total}
              viewAllLinkTo={viewAllLinkTo}
              onCloseSearch={onCloseSearch}
              onLoadRequests={onLoadRequests}
              loadingRequestsByParam={loadingRequestsByParam}
              />
          )
        }
      </div>
    );
  }

  render() {
    const {scope, scopeData, onCloseSearch} = this.props;
    const {total} = scopeData;
    return (
      <SectionStyled>
        <div className="scope-section-header">
          <div className="total-results">{scope}&nbsp;<span>{scopeData.total || 0}</span></div>
          {
            this.isCommentsScope() ?
              <CommentsShowMore
                total={total}
                showMore={this.state.showMoreComments}
                onToggle={this.handleToggleShowMoreComments}
              /> :
                <ViewAllLink
                  to={this.getViewAllUrl()}
                  onClick={onCloseSearch}
                  total={total}
                />
          }
        </div>
        {
          scopeData.total > 0 ? this.renderBody() : null
        }
      </SectionStyled>
    );
  }
}

const SectionStyled = styled.div`
  margin: 0 0 22px;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  position: relative;

  @media (max-width: 767px) {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid #EFEFEF;
    padding-top: 10px;
    margin-top: -1px;
    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .scope-section-header {
    background: #f9f9f9;
    .total-results {
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      color: #3C3C3C;
      span {
        font-weight: normal;
        padding-left: 6px;
      }
    }
    height: 62.25px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      background: #fff;
      border-bottom: 1px solid #EFEFEF;
      height: 51px;
      padding: 0 25px;
    }
  }

  .scope-section-body {
    padding: 0 37px 23px 31px;
    &.requests, &.comments {
      padding-left: 0 !important;
      padding-right: 0;
    }
    position: relative;
    .loader-container {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      background-color: rgba(255, 255, 255, 0.5) !important;
      .react-spinner {
        top: 49% !important;
      }
    }

    @media (max-width: 767px) {
      padding-bottom: 0;
      padding-left: 25px;
      padding-right: 0;
    }
  }
`;

Section.propTypes = {
  search: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  scopeData: PropTypes.object.isRequired,
  onCloseSearch: PropTypes.func.isRequired,
  onLoadRequests: PropTypes.func.isRequired,
  loadingRequestsByParam: PropTypes.object
};

export default Section;
