import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from './Section';
import {
  SCOPE_EVERYTHING, SCOPE_REQUESTS, SCOPE_DISTRIBUTORS, SCOPE_TITLES, SCOPE_ISSUES, SCOPE_COMMENTS
} from '../constants';

class SearchResults extends React.PureComponent {
  scopes = [SCOPE_EVERYTHING, SCOPE_REQUESTS, SCOPE_DISTRIBUTORS, SCOPE_TITLES, SCOPE_ISSUES, SCOPE_COMMENTS];
  state = {
    scope: SCOPE_EVERYTHING
  };

  handleSelectScope = scopeName => {
    this.setState({scope: scopeName});
  };

  getScopeData = scope => {
    return this.props.data[scope.toLowerCase()] || {total: 0, data: []};
  };

  render() {
    const {search, onClose, onLoadRequests, loadingRequestsByParam} = this.props;
    const {scope} = this.state;
    const isEverything = scope === SCOPE_EVERYTHING;
    return (
      <div>
        <Scopes>
          <div>Search Scope</div>
          <ul>
          {
            this.scopes.map((scopeName, i) => (
              <li
                key={`scope-${i}`}
                onClick={() => this.handleSelectScope(scopeName)}
                className={scope === scopeName ? 'selected' : null}
                >
                <span>{scopeName}</span>
              </li>
            ))
          }
          </ul>
        </Scopes>
        <Sections>
          <div className="left">
            {
              this.scopes.slice(1).map((scopeName, i) => (
                <div key={`scope-result-number-${i}`}>
                  {scopeName}&nbsp;<span>{this.getScopeData(scopeName).total || 0}</span>
                </div>
              ))
            }
          </div>
          <div className="right">
            {
              this.scopes.slice(1).map((scopeName, i) => (isEverything || scope === scopeName) ? (
                <Section
                  key={`scope-result-section-${i}`}
                  search={search}
                  scope={scopeName}
                  scopeData={this.getScopeData(scopeName)}
                  onCloseSearch={onClose}
                  onLoadRequests={onLoadRequests}
                  loadingRequestsByParam={loadingRequestsByParam}
                  />
              ) : null)
            }
          </div>
        </Sections>
      </div>
    );
  }
}

const Scopes = styled.div`
  padding: 16px 0 22px;
  align-items: center;
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 0 15px;
    background: #F9F9F9;
  }

  > div {
    font-size: 13px;
    line-height: 14px;
    color: #282828;
    opacity: 0.2;
    display: flex;
    float: left;
    width: 8.8%;
    min-width: 97px;

    @media (max-width: 767px) {
      opacity: 1;
      margin-bottom: 14px;
      width: auto;
      min-width: unset;
      padding: 0 25px;
      float: none;
    }
  }

  ul {
    width: 91.2%;
    min-width: calc(100% - 97px);
    list-style: none;
    float: left;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      padding: 0;
      margin-right: 2px;
      span {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: #3C3C3C;
        opacity: 0.7;
        border-radius: 100px;
        height: 30px;
        padding: 0 23px;
        transition: opacity 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
      }
      @media (min-width: 768px) {
        &:hover span {
          cursor: pointer;
          opacity: 1;
          background: #dcdcdc;
        }
      }
      &.selected span {
        font-weight: 500;
        color: #fff;
        background: #474747;
        opacity: 1;
        cursor: default;
      }
    }

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      box-sizing: border-box;
      float: none;
      padding: 0 25px;
      width: auto;
      min-width: unset;
      max-width: 100%;
      overflow-x: auto;
      li {
        margin-right: 17px;
        span {
          padding: 0;
          transition: all 0.3s ease-in-out;
        }
        &.selected {
          span {
            padding: 0 17px;
            transition: all 0.3s ease-in-out;
          }
          pointer-events: none;
        }
        &:nth-child(1) {
          margin-right: 36px;
        }
        &:nth-child(2) {
          margin-right: 24px;
        }
        &:last-child {
          margin-right: 0;
          padding-right: 25px;
        }
      }
    }
  }
`;

const Sections = styled.div`
  flex-direction: row;
  display: flex;
  .left, .right {
    display: flex;
    flex-direction: column;
  }
  .left {
    width: 8.8%;
    min-width: 101px;
    padding-top: 12px;
    > div {
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      color: #3C3C3C;
      padding: 11px 0;
      span {
        font-weight: bold;
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 91.2%;
    min-width: calc(100% - 101px);

    @media (max-width: 767px) {
      width: 100%;
      min-width: unset;
      background: #fff;
    }
  }
`;

SearchResults.propTypes = {
  search: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadRequests: PropTypes.func.isRequired,
  loadingRequestsByParam: PropTypes.object
};

export default SearchResults;
