/* eslint "complexity":"off" */
import {NEW_REQUEST_STEP_CHOICES} from '../NewRequest/constants';
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  NEWREQUEST_CHANGE_STEP, NEWREQUEST_APPLY_VERSION, NEWREQUEST_AUTO_SAVE, NEWREQUEST_CHANGE_FORM_FIELD,
  NEWREQUEST_CHANGE_SUBSTEP, NEWREQUEST_CLEAN_FORM, NEWREQUEST_FORM_RECEIVE,
  NEWREQUEST_FORM_REQUEST, NEWREQUEST_GET_AUTO_SAVED, NEWREQUEST_GET_FORM_DICTIONARIES,
  NEWREQUEST_GET_REPORTS, NEWREQUEST_IS_LOADING, NEWREQUEST_IS_LOADING_AUTO_SAVED,
  NEWREQUEST_IS_LOADING_REPORTS, NEWREQUEST_RESET_FORM, NEWREQUEST_SAVE_DISTRIBUTOR, NEWREQUEST_SET_UNSAVED,
  NEWREQUEST_FORM_SAVE
} from './actions';
import _ from 'lodash';

const initialState = {
  currentStep: NEW_REQUEST_STEP_CHOICES,
  currentSubStep: null,
  values: {},
  dictionaries: {},
  loading: false,
  loadingReports: false,
  loadingAutoSaved: false,
  autoSavedVersion: null,
  saving: false,
  isSaved: false,
  reports: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEWREQUEST_CHANGE_STEP: {
      return {...state, currentStep: action.step};
    }

    case NEWREQUEST_CHANGE_SUBSTEP: {
      return {...state, currentSubStep: action.step};
    }

    case NEWREQUEST_IS_LOADING: {
      const dictionaries = {...state.dictionaries};
      if (action.loading && !action.for_company_ids && dictionaries.distributors && dictionaries.qc_profiles) {
        dictionaries.distributors = [];
        dictionaries.qc_profiles = [];
      }
      return {...state, loading: action.loading, dictionaries};
    }

    case NEWREQUEST_GET_FORM_DICTIONARIES: {
      return {
        ...state,
        dictionaries: action.dictionaries,
        loading: false
      };
    }

    case NEWREQUEST_CHANGE_FORM_FIELD: {
      const values = state.values;
      values[action.name] = action.value;
      return {...state, values};
    }

    case NEWREQUEST_RESET_FORM: {
      return {
        ...state,
        values: action.values || {},
        reports: null
      };
    }

    case NEWREQUEST_FORM_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case NEWREQUEST_FORM_SAVE: {
      return {
        ...state,
        loading: false,
        isSaved: true
      };
    }

    case NEWREQUEST_FORM_RECEIVE: {
      return {
        ...state,
        loading: false
      };
    }

    case NEWREQUEST_SET_UNSAVED: {
      return {...state, isSaved: false};
    }

    case NEWREQUEST_AUTO_SAVE: {
      return {...state, saving: action.saving};
    }

    case NEWREQUEST_IS_LOADING_AUTO_SAVED: {
      return {...state, loadingAutoSaved: action.loading};
    }

    case NEWREQUEST_GET_AUTO_SAVED: {
      return {
        ...state,
        loadingAutoSaved: false,
        autoSavedVersion: action.data
      };
    }

    case NEWREQUEST_APPLY_VERSION: {
      return {...state, ...action.data};
    }

    case NEWREQUEST_IS_LOADING_REPORTS: {
      return {...state, loadingReports: action.loading};
    }

    case NEWREQUEST_GET_REPORTS: {
      return {
        ...state,
        reports: [...action.reports],
        loadingReports: false
      };
    }

    case NEWREQUEST_SAVE_DISTRIBUTOR: {
      const dictionaries = {...state.dictionaries};
      if (action.distributor) {
        dictionaries.distributors.push(action.distributor);
        dictionaries.distributors = _.orderBy(dictionaries.distributors, [d => d.name.toLowerCase()]);
      }
      return {
        ...state,
        saving: action.saving,
        dictionaries
      };
    }

    case USER_SET_IS_LOGOUT:
    case NEWREQUEST_CLEAN_FORM: {
      return {
        ...initialState,
        values: {},
        reports: null
      };
    }

    default:
      return state;
  }
}
