import styled, {css} from 'styled-components';
import {Row} from 'react-bootstrap';
import SelectStyled from '../../../components/filters/SelectStyled';
import settingImg from '../../../../assets/images/analystic/setings.svg';
import uploadImg from '../../../../assets/images/analystic/export-white.svg';
import sortImg from '../../../../assets/images/analystic/sort.svg';
import filterImg from '../../../../assets/images/analystic/filter.svg';

export const FiltersStyled = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

  .clearfix {
    clear: both;
  }
`;

export const FilterSelectStyled = styled(SelectStyled)`
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  .Select.is-searchable.Select--single .Select-control,
  .Select.is-searchable.Select--single .Select-control {
    border: 1px solid #E9E9E9;
    box-shadow: none;
    background: #fff;
    .Select-placeholder,
    .Select-value {
      color: #aaa !important;
      font-size: 11px !important;
      .Select-value-label {
        font-size: 11px !important;
      }
    }
  }

  .Select--multi .Select-control {
    border: 1px solid #E9E9E9;
    box-shadow: none;
    .Select-multi-value-wrapper {
      height: auto;
      .Select-value .Select-value-label {
        font-size: 11px !important;
      }
    }
    .Select-placeholder {
      font-size: 11px !important;
    }
    .Select-value {
      padding: 0 !important;
      .Select-value-icon {
        padding: 0 5px;
        font-size: 14px;
        &:hover {
          background-color: rgb(255, 189, 173);
          color: rgb(222, 53, 11);
        }
      }
    }
    .Select-input {
      height: 24px;
      input {
        font-size: 11px;
        padding: 0;
      }
    }
  }
`;

export const FilterHeaderStyled = styled.div`
  background: #575757;
  color: #FFFFFF;
  height: 44px;
  padding: 0 20px;
  .title {
    font-size: 13px;
    font-weight: bold;
    padding: 13px 0;
  }
  .menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 10px;
      padding: 10px 0;
      &:hover:not(.report-option) .btn-link span {
        max-width: 1000px;
        transition: all 1.5s ease-in-out;
      }
      &:not(.report-option) span {
        max-width: 0;
        overflow: hidden;
        transition: all 0.1s ease-in-out;
      }
      &.report-option {
        background-color: #464646;
        padding-right: 15px;
        padding-left: 15px;
        ${props => props.isHiddenReportOptions && css`
          display: none !important;
        `}
      }
    }

    .btn-link {
      border: none;
      color: #ffffff;
      display: flex;
      height: 22px;
      line-height: 23px;
      white-space: nowrap;
      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }
      span {
        color: #FFFFFF;
        font-size: 11px;
        opacity: 0.5;
      }
    }
  }
  + div {
    ${props => props.isHiddenReportOptions && css`
      display: none !important;
    `}
  }
`;

export const IconStyled = styled.i`
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin: 0 3px;
  height: 21px;
  width: 21px;

  ${props => props.sort && css`
    background-image: url(${sortImg});
  `}
  ${props => props.setting && css`
    background-image: url(${settingImg});
  `}
  ${props => props.upload && css`
    background-image: url(${uploadImg});
  `}
  ${props => props.filt && css`
    background-image: url(${filterImg});
  `}
`;

export const FilterBodyStyled = styled(Row)`
  .block {
    padding: 20px;
  }
  .title {
    align-items: center;
    color: #282828;
    display: flex;
    font-size: 11px;
  }
  .filters-block {
    margin-left: 38px;
    [class*="col-"] {
      padding: 0 3px;
    }
  }
`;

export const FilterBottomStyled = styled.div`
  padding: 0 40px;
  > div {
    border-top: 1px solid #f0f0f0;
    padding: 20px 0;
  }
  .btn {
    border-radius: 100px;
    border: none;
    font-size: 12px;
    margin-right: 5px;
    padding: 10px 30px;
    &:hover, &:focus {
      opacity: 0.9;
      outline: none;
    }
    &.grey {
      background-color: #595959;
      color: #FFFFFF;
      font-weight: bold;
    }
    &.white {
      background-color: #ffffff;
      color: #7C7C7C;
    }
  }
`;
