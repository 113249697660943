import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {formatDate} from '../../../../../utils/general';

export const TitleCell = ({value, row, onCloseSearch, className}) => {
  const {RequestID, IssueID, CommentID, CommentType} = row;

  const buildLinkParams = () => {
    if (IssueID && CommentID) {
      return `?issue_id=${IssueID}&comment_id=${CommentID}`;
    }
    if (CommentID && CommentType === 'general') {
      return `?general_comment_id=${CommentID}`;
    }
    return '';
  };

  const linkTo = `/requests/${RequestID}${buildLinkParams()}`;

  return (
    <StyledCell className={className}>
      <Link to={linkTo} onClick={() => onCloseSearch(linkTo)}>{value}</Link>
      <div className="submitted-on">{formatDate(row.SubmittedOn, '', 'MMMM D, YYYY HH:mm A')}</div>
    </StyledCell>
  );
};

const StyledCell = styled.td`
  a {
    font-weight: bold;
    color: #29A4CB;

    &:hover {
      color: #228daf;
    }
  }

  @media (min-width: 768px) {
    .submitted-on {
      display: none;
    }
  }

  @media (max-width: 767px) {
    a {
      font-size: 13px;
      line-height: 14px;
    }
    .submitted-on {
      padding-top: 3px;
    }
  }
`;

TitleCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  onCloseSearch: PropTypes.func,
  className: PropTypes.string
};
