import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../../../components/table/Table';
import {getOptionName, getTextlessOptionName, formatSeasonNameForTitle} from '../../../../utils/general';
import {getVideoFormatOptionName, buildVideoFormatName, isNewQuoteProject} from '../../constants';
import TextEditableField from '../../../RequestDetails/components/forms/TextEditableField';
import {getExistingTagsForNewRequest} from '../../actions';
import Loader from '../../../../components/Loader';
import _ from 'lodash';

/*const QC_TYPE_AUDIO_VIDEO = 1;
const QC_TYPE_AUDIO = 2;
const QC_TYPE_VIDEO = 3;*/
const QC_TYPE_S_AND_P = 4;
const QC_TYPE_CAPTION_QC = 5;
const QC_TYPE_HARDING_TEST = 15;

const prepareAudioSounds = (data, filters) => {
  return _.uniq((((data.audio_format || {}).audio_configuration || {}).data || []).map(ac => getOptionName(filters.audio_sounds, ac.sound_id))).sort((a, b) => b.localeCompare(a)).join('/');
};

const prepareAdditionalDesc = (data, filters, onChange) => {
  const isNoValue = vf => !vf.additional_description && vf.additional_description !== '';

  const {qc_profile_id, qc_type_id} = data;

  const qcProfile = filters.qc_profiles.find(p => p.value === qc_profile_id);
  if (qcProfile && qcProfile.name) {
    onChange('profile_name', qcProfile.name);
  }

  if (data.video_format) {
    const videoFormats = [...data.video_format];
    videoFormats.forEach((vf, index) => {
      if (isNoValue(vf)) {
        const textlessName = getTextlessOptionName(vf.textless);
        const vfFields = [
          getVideoFormatOptionName(filters, 'video_resolutions', qc_profile_id, vf.resolution_id),
          getVideoFormatOptionName(filters, 'video_color_spaces', qc_profile_id, vf.color_space_id),
          textlessName === 'Textless' ? textlessName : '',
          getVideoFormatOptionName(filters, 'video_formats', qc_profile_id, vf.format_id)
        ].filter(Boolean);
        vf.additional_description = ((vf.version_tag ? `${vf.version_tag} - ` : '') + vfFields.join(' ') + ' QC').trim();
        if (qc_type_id === QC_TYPE_HARDING_TEST) {
          vf.additional_description = 'Harding Test QC';
        }
      }
    });
    onChange('video_format', videoFormats);
  } else if (data.captions) {
    const captionsFormats = [...data.captions];
    captionsFormats.forEach((vf, index) => {
      if (isNoValue(vf)) {
        vf.additional_description = ('Closed Caption QC' + (vf.version_tag ? ` - ${vf.version_tag}` : '')).trim();
      }
    });
    onChange('captions', captionsFormats);
  } else if (isNoValue(data)) {
    let desc;
    if (qc_type_id === QC_TYPE_S_AND_P) {
      desc = (data.version_tag ? `${data.version_tag} - ` : '') + 'S&P QC';
    } else if (qc_type_id === QC_TYPE_CAPTION_QC) {
      desc = 'Closed Caption QC' + (data.version_tag ? ' - ' + data.version_tag : '');
    } else if (qc_type_id === QC_TYPE_HARDING_TEST) {
      desc = 'Harding Test QC';
    } else if (data.audio_format) {
      desc = ((data.version_tag ? `${data.version_tag} - ` : '') + (prepareAudioSounds(data, filters) || 'Audio') + ' QC').trim();
    } else {
      desc = getOptionName(filters.qc_types, qc_type_id, '') + ' QC';
    }
    onChange('additional_description', desc);
  }
};

const prepareVersions = (data, filters, reqTitle, quoteProfileIndex) => {
  const {qc_profile_id, qc_type_id} = data;
  const isNewQuote = typeof quoteProfileIndex === 'number';

  const qcProfile = filters.qc_profiles.find(p => p.value === qc_profile_id);
  const profileName = qcProfile && qcProfile.name;

  return data.video_format ? data.video_format.map((vf, index) => ({
    quoteProfileIndex,
    qc_profile_id,
    qc_type_id,
    index,
    profileName: profileName,
    versionName: buildVideoFormatName(filters, qc_profile_id, vf),
    reqTitle: reqTitle + (vf.version_tag ? ` - ${vf.version_tag}` : ''),
    reqTag: vf.version_tag,
    additionalDesc: vf.additional_description,
    revisionsNum: isNewQuote ? (vf.revisions_num || 0) : undefined,
  })) : data.captions ? data.captions.map((vf, index) => ({
    quoteProfileIndex,
    qc_profile_id,
    qc_type_id,
    index,
    profileName: profileName,
    versionName: 'CC',
    reqTitle: reqTitle + (vf.version_tag ? ` - ${vf.version_tag}` : ''),
    reqTag: vf.version_tag,
    additionalDesc: vf.additional_description,
    revisionsNum: isNewQuote ? (vf.revisions_num || 0) : undefined,
  })) : [{
    quoteProfileIndex,
    qc_profile_id,
    qc_type_id,
    profileName: profileName,
    versionName: qc_type_id === QC_TYPE_CAPTION_QC ? 'CC' : qc_type_id === QC_TYPE_S_AND_P ? 'S&P' : data.audio_format ? (prepareAudioSounds(data, filters) || 'Audio') : '',
    reqTitle: reqTitle + (data.version_tag ? ` - ${data.version_tag}` : ''),
    reqTag: data.version_tag,
    additionalDesc: data.additional_description,
    revisionsNum: isNewQuote ? (data.revisions_num || 0) : undefined,
  }];
};

function CsTags(props) {
  const {data, filters, onChange} = props;
  const {series_id, trailer_episode_name} = data;
  const isNewQuote = isNewQuoteProject(data);
  const seasonNum = isNewQuote ? data.season_name : (data.season || {}).season_number;
  const reqTitle = (data.title || ((filters.series.find(s => s.series_id === series_id) || {}).series_name || series_id)) +
    (trailer_episode_name ? ` / ${trailer_episode_name}` : seasonNum ? formatSeasonNameForTitle(seasonNum) : '');

  const [isLoading, setLoading] = useState(false);
  const [existingTags, setExistingTags] = useState([]);

  useEffect(() => {
    if (isNewQuote) {
      return;
    }

    setLoading(true);
    getExistingTagsForNewRequest(data).then(
      res => {
        setLoading(false);
        setExistingTags(res.tags);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [data, isNewQuote]);

  useEffect(() => {
    if (isNewQuote) {
      const qcProfilesList = [...data.qc_profile_id || []];
      qcProfilesList.forEach((item, itemIdx) => {
        prepareAdditionalDesc(item, filters, (n, v) => {
          qcProfilesList[itemIdx][n] = v;
        });
        if (item.video_format) {
          item.video_format.forEach(vf => vf.revisions_num = vf.revisions_num !== undefined ? vf.revisions_num : 1);
        }
        if (item.captions) {
          item.captions.forEach(c => c.revisions_num = c.revisions_num !== undefined ? c.revisions_num : 1);
        }
      });
      onChange('qc_profile_id', qcProfilesList);
    } else {
      prepareAdditionalDesc(data, filters, onChange);
    }
  }, [onChange, filters, data, isNewQuote]);

  const versions = !isNewQuote ?  prepareVersions(data, filters, reqTitle) :
    (data.qc_profile_id || []).map((item, itemIdx) => prepareVersions(item, filters, reqTitle, itemIdx)).reduce((a, item) => a.concat(item), []);

  const isExistsTag = (tag, versionName) => {
    const prepareTag = t => (t || '').trim().toLowerCase();
    tag = prepareTag(tag);
    return versions.filter(v => prepareTag(v.reqTag) === tag && (v.versionName || '') === (versionName || '')).length > 1 ||
           existingTags.some(t => prepareTag(t.Tag) === tag && (t.Version || '') === (versionName || ''));
  };

  const updateAddDesc = (row, oldTag, newTag) => {
    const {index, quoteProfileIndex} = row;
    const itemData = isNewQuote ? data.qc_profile_id[quoteProfileIndex] : data;

    if (itemData.qc_type_id === QC_TYPE_CAPTION_QC) {
      const newDesc = 'Closed Caption QC' + (newTag ? ' - ' + newTag : '');
      if (isNewQuote) {
        const qcProfilesList = [...data.qc_profile_id || []];
        qcProfilesList[quoteProfileIndex].captions[index].additional_description = newDesc;
        onChange('qc_profile_id', qcProfilesList);
      } else {
        onChange('additional_description', newDesc);
      }
      return;
    }

    const curDesc = (itemData.video_format ? itemData.video_format[index] : itemData).additional_description;
    const newDesc = (!oldTag || !curDesc) ? (curDesc ? `${newTag} - ${curDesc}` : newTag) :
        curDesc.replace(new RegExp(`^${oldTag} [-] `), newTag ? `${newTag} - ` : '').trim();
    if (isNewQuote) {
      const qcProfilesList = [...data.qc_profile_id || []];
      if (qcProfilesList[quoteProfileIndex].video_format) {
        qcProfilesList[quoteProfileIndex].video_format[index].additional_description = newDesc;
      } else {
        qcProfilesList[quoteProfileIndex].additional_description = newDesc;
      }
      onChange('qc_profile_id', qcProfilesList);
    } else if (itemData.video_format) {
      itemData.video_format[index].additional_description = newDesc;
      onChange('video_format', itemData.video_format);
    } else {
      onChange('additional_description', newDesc);
    }
  };

  const cell = ({value, row, className, name}) => {
    const isRevisionsNumCell = name === 'revisions_num';
    const isTagCell = name === 'version_tag';
    return (
      <Cell className={className}>
        <TextEditableField
          value={value}
          onConfirm={(l, n, newValue, callback) => {
            newValue = isRevisionsNumCell ? (newValue || 0) : (newValue || '').trim();
            if (isRevisionsNumCell ? (value || 0) === newValue : value === newValue) {
              callback();
              return;
            }
            let itemData = data;
            if (isNewQuote) {
              const qcProfilesList = [...data.qc_profile_id || []];
              itemData = qcProfilesList[row.quoteProfileIndex];
              if (itemData.video_format) {
                itemData.video_format[row.index][name] = newValue;
              } else if (itemData.captions) {
                itemData.captions[row.index][name] = newValue;
              } else {
                itemData[name] = newValue;
              }
              onChange('qc_profile_id', qcProfilesList);
            } else if (data.video_format) {
              data.video_format[row.index][name] = newValue;
              onChange('video_format', data.video_format);
            } else {
              onChange(name, newValue);
            }
            callback();
            if (isTagCell && ((itemData.video_format && itemData.qc_type_id !== QC_TYPE_HARDING_TEST) || itemData.audio_format || itemData.qc_type_id === QC_TYPE_CAPTION_QC || itemData.qc_type_id === QC_TYPE_S_AND_P)) {
              updateAddDesc(row, value, newValue);
            }
          }}
          hiddenHint
          {...isRevisionsNumCell ? {
            isNumber: true,
            min: 0,
            valueForNonEditModeInSelect: '0'
          } : {}}
          hideActionButtons
        />
        {isTagCell && isExistsTag(value, row.versionName) &&
          <i className="fas fa-exclamation-triangle" title={`duplicate ${value ? '' : 'empty '}tag`}/>
        }
      </Cell>
    );
  };

  const headers = {
    reqTitle: {
      title: 'Title',
      className: 'req-title'
    },
    profileName: {
      title: 'QC Profile',
      className: 'req-version-name'
    },
    versionName: {
      title: 'Version',
      className: 'req-version-name'
    },
    reqTag: {
      title: 'Tag',
      className: 'req-tag',
      component: cell,
      componentArgs: {
        name: 'version_tag'
      }
    },
    additionalDesc: {
      title: 'Additional Description',
      className: 'req-additional-desc',
      component: cell,
      componentArgs: {
        name: 'additional_description'
      }
    }, ...isNewQuote ? {
      revisionsNum: {
        title: '# of Revisions',
        className: 'req-revisions-num',
        component: cell,
        componentArgs: {
          name: 'revisions_num'
        }
      },
    } : {}
  };

  return (
    <div className="step-body-wrapper cs-tags">
      {isLoading && <Loader className="full-screen"/>}
      <div className="table-container uploads-table reports-table cs-tags-table midl_in">
        <div>
          You can edit {isNewQuote ? 'revisions, ' : ''}tag and additional description values for new request
        </div>
        <div className="table-block">
          <Table
            headers={headers}
            rows={versions}
            stripped={false}
          />
        </div>
        {!!existingTags.length &&
          <>
            <SimilarRequests>
              <div className="sr--header">
                Similar Existing Requests
              </div>
              <div className="sr--list">
                <div className="table-block">
                  <Table
                    headers={{
                      Title: {
                        title: 'Title',
                        className: 'req-title'
                      },
                      Version: {
                        title: 'Version',
                        className: 'req-version-name'
                      },
                      Tag: {
                        title: 'Tag',
                        className: 'req-tag',
                      },
                      AdditionalDescription: {
                        title: 'Additional Description',
                      },
                      WOMemfis: {
                        title: 'WO Memfis'
                      },
                    }}
                    rows={existingTags}
                    stripped={false}
                  />
                </div>
              </div>
            </SimilarRequests>
          </>
        }
      </div>
    </div>
  );
};

const Cell = styled.td`
  position: relative;

  i {
    position: absolute;
    left: 0;
    top: 18px;
    font-size: 12px;
    color: #ffbc00;
  }

  .text-editable-field--in-edit-mode + i {
    opacity: 0.3;
  }

  > .form-group {
    margin: 0;

    @media (min-width: 768px) {
      min-width: 170px;
    }

    > div {
      display: flex;
    }

    .editable-control {
      @media (min-width: 992px) {
        width: 100%;
      }
    }
  }
`;

const SimilarRequests = styled.div`
  margin-top: 20px;
`;

CsTags.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CsTags;
