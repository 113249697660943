import React from 'react';
import styled from 'styled-components';
import {confirmAlert} from 'react-confirm-alert';
import {
  updateRequestField,
} from '../actions';
import {
  formatDueDate,
  formatDurationAsMinutes,
} from '../../../../utils/general';

function ChangeFieldConfirmAlert(props) {
  const {dispatch, details, label, name, value, callback, options} = props;
  let currentValue = details[name];
  let newValue = value;
  if (name === 'Runtime') {
    currentValue = formatDurationAsMinutes(currentValue).replace(/^-$/, '');
    newValue = formatDurationAsMinutes(value).replace(/^-$/, '');
  } else if (name === 'DueDate') {
    currentValue = formatDueDate(currentValue);
    newValue = formatDueDate(value);
  } else if (['Title', 'PrimaryTitle'].includes(name)) {
    newValue = (value || '').trim();
    if (!newValue) {
      return <></>;
    }
  } else if (Array.isArray(options) && options.length) {
    currentValue = (options.find(o => o.value === currentValue) || {}).name;
    newValue = (options.find(o => o.value === newValue) || {}).name;
  }

  const isCancelRequest = name === 'NetflixWorkOrderStatus' && (value || '').toUpperCase() === 'CANCELLED';

  confirmAlert({
    title: `The Request ${label} has been changed`,
    childrenElement: () => (
      <>
        <OldNewValuesStyled>
          <div>
            <div><strong>Current {label.replace('%', '')}</strong></div>
            <div className={currentValue ? undefined : 'no-value'}>
              {currentValue || 'None'}
            </div>
          </div>
          <div>
            <div><strong>New {label.replace('%', '')}</strong></div>
            <div className={newValue ? undefined : 'no-value'}>
              {newValue || 'None'}
            </div>
          </div>
        </OldNewValuesStyled>
        <div>
          {isCancelRequest ? 'Are you sure you want to cancel request?' : 'Should request be updated?'}
        </div>
      </>
    ),
    buttons: [{
      label: 'No'
    }, {
      label: 'Yes',
      onClick: () => dispatch(updateRequestField({label, name, value, callback, needToReloadSteps: isCancelRequest}))
    }]
  });

  return <></>;
}

const OldNewValuesStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  > div {
    + div {
      margin-top: 8px;
    }
    > div {
      &:first-child {
        margin-bottom: 3px;
      }
      &.no-value {
        font-weight: 300;
        opacity: 0.65;
      }
    }
  }
  + div {
    font-size: 13px;
  }
`;

export default ChangeFieldConfirmAlert;
