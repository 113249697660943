/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {logout} from '../../pages/Login/actions';
import dashboardImg from "../../../assets/images/header/mobile/dashboard.svg";
import dashboardActiveImg from "../../../assets/images/header/mobile/dashboard-active.svg";
import requestsImg from "../../../assets/images/header/mobile/requests.svg";
import requestsActiveImg from "../../../assets/images/header/mobile/requests-active.svg";
import analyticsImg from "../../../assets/images/header/mobile/analytics.svg";
import analyticsActiveImg from "../../../assets/images/header/mobile/analytics-active.svg";
import settingsImg from "../../../assets/images/header/mobile/settings.svg";
import settingsActiveImg from "../../../assets/images/header/mobile/settings-active.svg";
import newRrequestImg from "../../../assets/images/header/mobile/new-request.svg";
import newRrequestActiveImg from "../../../assets/images/header/mobile/new-request-active.svg";
import logoutImg from "../../../assets/images/header/mobile/logout.svg";
import hamburgerImg from '../../../assets/images/header/mobile/hamburger.svg';
import maleIcon from '../../../assets/images/header/male.svg';
import {isManagerUser, getUserRoles, allowViewUserAdminPage} from '../../utils/general';
import {showClientServiceCreateRequestModal} from '../actions';
import Can from '../../components/Can';
import {showCreateNewUserPopup} from '../../pages/Admin/actions';

class MobileHeaderMenu extends Component {
  closeMobileHeaderMenu = () => {
    const {body} = document;
    if (body.className.indexOf('mobile-header-menu-open') !== -1) {
      body.className = body.className.replace('mobile-header-menu-open', '').trim();
    }
  };

  handleLogout = () => {
    this.closeMobileHeaderMenu();
    const {dispatch, history} = this.props;
    dispatch(logout(history));
  };

  handleNewRequestClick = event => {
    if (isManagerUser()) {
      event.preventDefault();
      const {dispatch} = this.props;
      dispatch(showClientServiceCreateRequestModal(true)).then(() => {
        this.closeMobileHeaderMenu();
      });
    } else {
      this.closeMobileHeaderMenu();
    }
  };

  handleClickAdminUser = () => {
    const {dispatch, match} = this.props;
    !allowViewUserAdminPage() && match.path === '/admin/user' && dispatch(showCreateNewUserPopup());
    this.closeMobileHeaderMenu();
  };

  handleClickAssets = () => {
    this.closeMobileHeaderMenu();
    if (/^[/]\bassets\b/.test(window.location.pathname)) {
      window.location.href = '/assets';
    }
  }

  render() {
    const {user} = this.props;
    const {avatar, name, email, studio, phoneNumber} = user;
    return (
      <StyledMobileHeaderMenu>
        <div className="mobile-menu-top">
          <div className="user-profile">
            {avatar ? <HeaderAvatar avatar={avatar}><span/></HeaderAvatar> : <HeaderAvatarPlaceholder/>}
            <div className="user-name">{name}</div>
            <div className="user-studio">{studio}</div>
            <div className="user-email">{email}</div>
            {phoneNumber ? <div className="user-phone-number">{phoneNumber}</div> : null}
          </div>
          <div className="hamburger" onClick={this.closeMobileHeaderMenu}/>
        </div>
        <ul>
          <Can
            roles={getUserRoles()}
            perform="header:show-dashboard"
            yes={() => (
              <li>
                <NavLink
                  title="Dashboard"
                  to="/"
                  onClick={this.closeMobileHeaderMenu}
                  activeClassName={
                    window.location.pathname.length > 1 ? "" : "active"
                  }
                >
                  <i className="dashboard-item" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-artwork-dashboard"
            yes={() => (
              <li>
                <NavLink
                  title="Dashboard"
                  to="/dashboard"
                  onClick={this.closeMobileHeaderMenu}
                  activeClassName="active"
                >
                  <i className="dashboard-item" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-create-qc-request-btn"
            yes={() => (
              <li className="new-request-menu-item">
                <NavLink
                  title="Create QC Request" to="/requests/new"
                  onClick={this.handleNewRequestClick}
                  activeClassName="active"
                >
                  <i className="new-request-item" />
                  <span>Create QC Request</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-projects"
            yes={() => (
              <li>
                <NavLink title="Projects" to="/projects" onClick={this.closeMobileHeaderMenu} activeClassName="active">
                  <i className="requests-item" />
                  <span>Projects</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-requests"
            yes={() => (
              <li>
                <NavLink title="Requests" to="/requests" onClick={this.closeMobileHeaderMenu} activeClassName="active">
                  <i className="requests-item" />
                  <span>Requests</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-artwork-my-requests"
            yes={() => (
              <li>
                <NavLink
                  title="My Artwork Requests"
                  to="/artwork/my-requests"
                  activeClassName="active"
                  onClick={this.closeMobileHeaderMenu}
                >
                  <i className="far fa-images"/>
                  <span>My Artwork Requests</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-artwork-request-queue"
            yes={() => (
              <li>
                <NavLink
                  title="Artwork Request Queue"
                  to="/artwork/request-queue"
                  activeClassName="active"
                  onClick={this.closeMobileHeaderMenu}
                >
                  <i className="far fa-images"/>
                  <span>Artwork Request Queue</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-artwork-assets"
            yes={() => (
              <li>
                <NavLink
                  title="Assets"
                  to="/assets"
                  activeClassName="active"
                  onClick={this.handleClickAssets}
                >
                  <i className="fas fa-photo-video"/>
                  <span>Assets</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-analytics"
            yes={() => (
              <li>
                <NavLink title="Analytics" to="/analytics" onClick={this.closeMobileHeaderMenu} activeClassName="active">
                  <i className="analytics-item" />
                  <span>Analytics</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header:show-menu"
            yes={() => (
              <li>
                <NavLink title="Settings" to="/settings" onClick={this.closeMobileHeaderMenu} activeClassName="active">
                  <i className="settings-item" />
                  <span>Settings</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="header-mobile:show-manage-profile"
            yes={() => (
              <li>
                <NavLink title="Manage Profile" to="/settings/general/profile" onClick={this.closeMobileHeaderMenu} activeClassName="active">
                  <i className="settings-item" />
                  <span>Manage Profile</span>
                </NavLink>
              </li>
            )}
          />
          <Can
            roles={getUserRoles()}
            perform="admin:show-user-admin"
            yes={() => (
              <li>
                <NavLink
                  title={allowViewUserAdminPage() ? 'User Admin' : 'Create Client'}
                  to="/admin/user"
                  onClick={this.handleClickAdminUser}
                  activeClassName="active"
                >
                  <i className={allowViewUserAdminPage() ? 'fas fa-users' : 'fas fa-user-plus'}/>
                  <span>{allowViewUserAdminPage() ? 'User Admin' : 'Create Client'}</span>
                </NavLink>
              </li>
            )}
          />
          {user.companies && user.companies.length ?
            <li>
              <NavLink
                title="Switch to another company"
                to="#"
                onClick={this.props.onSwitchCompany}
                activeClassName=""
              >
                <i className="far fa-house-user" />
                <span>
                  Change company
                </span>
              </NavLink>
            </li> : null
          }
          <li>
            <NavLink title="Logout" to="/login" onClick={this.handleLogout} activeClassName="">
              <i className="logout-item" />
              <span>Logout</span>
            </NavLink>
          </li>
        </ul>
        <div className="overlay" onClick={this.closeMobileHeaderMenu}/>
      </StyledMobileHeaderMenu>
    );
  }
}

const StyledMobileHeaderMenu = styled.aside`
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    body:not(.mobile-header-menu-open) & {
      width: 0;
      left: -100vw;
      .overlay {
        width: 0;
        left: -100vw;
        right: unset;
        opacity: 0;
        transition: opacity 1ms ease-in-out, width 0.3s ease-in-out, left 0.3s ease-in-out, right 0.3s ease-in-out;
      }
    }

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(#fff, #fff), linear-gradient(transparent, transparent);
    background-position: 0 0, 75vw 0;
    background-size: 75vw 100vh, 25vw 100vh;
    background-repeat: no-repeat;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;

    .overlay {
      position: fixed;
      top: 0;
      left: 75vw;
      right: 0;
      bottom: 0;
      width: 25vw;
      opacity: 0.4;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      background: #000;
    }

    .mobile-menu-top {
      width: 75vw;
      background: #3E3E3E;
      padding: 4.2vh 8.5vw 5.1vh 6.7vw;
      position: relative;
      .hamburger {
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url(${hamburgerImg});
        width: 21px;
        height: 16px;
        opacity: 1 !important;
        position: absolute;
        top: 21px;
        right: 8.5vw;
      }
      .user-profile {
        color: #FFFFFF;
        word-break: break-all;
        .user-name {
          font-weight: 300;
          font-size: 23px;
          line-height: 24px;
          padding-top: 1vh;
        }
        .user-studio {
          font-weight: bold;
          font-size: 15px;
          line-height: 16px;
          padding-bottom: 1vh;
        }
        .user-email, .user-phone-number {
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          padding-top: 0.8vh;
        }
      }
    }

    ul {
      list-style: none;
      padding: 3.2vh 0 0 16vw;
      margin: 0;
      width: 75vw;
      > li {
        padding: 2.8vh 2vw 2.8vh 0;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        &.new-request-menu-item {
          border-bottom-color: transparent;
          display: none;
        }
        > a {
          color: #8e8e93;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          span {
            padding-left: 3.8vw;
          }
          i {
            height: 21px;
            width: 27px;
            &.dashboard-item {
              background: url(${dashboardImg}) no-repeat center center;
            }
            &.new-request-item {
              background: url(${newRrequestImg}) no-repeat center center;
            }
            &.requests-item {
              background: url(${requestsImg}) no-repeat center center;
            }
            &.analytics-item {
              background: url(${analyticsImg}) no-repeat center center;
            }
            &.settings-item {
              background: url(${settingsImg}) no-repeat center center;
            }
            &.logout-item {
              background: url(${logoutImg}) no-repeat center center;
            }
            &.far,
            &.fas {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &.active {
            color: #5d3996;
            i {
              &.dashboard-item {
                background: url(${dashboardActiveImg}) no-repeat center center;
              }
              &.new-request-item {
                background: url(${newRrequestActiveImg}) no-repeat center center;
              }
              &.requests-item {
                background: url(${requestsActiveImg}) no-repeat center center;
              }
              &.analytics-item {
                background: url(${analyticsActiveImg}) no-repeat center center;
              }
              &.settings-item {
                background: url(${settingsActiveImg}) no-repeat center center;
              }
            }
          }
        }
      }
    }
  }
`;

const HeaderAvatar = styled.div`
  width: 24vw;
  height: 24vw;
  max-width: 90px;
  max-height: 90px;
  span {
    background-color: transparent;
    background-image: url(${props => (props.avatar)});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    border-radius: 100px;
  }
`;

const HeaderAvatarPlaceholder = styled(HeaderAvatar)`
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${maleIcon});
  background-size: 90px;
  border-radius: 100px;
`;

MobileHeaderMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default connect()(withRouter(MobileHeaderMenu));
