import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWizardCheckbox = styled.label`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #E4E4E4;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #E75592;
    border: none;
  }

  .checkboxes-as-disabled & {
    input:checked ~ .checkmark {
      background-color: #bbb;
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const WizardCheckbox = ({checked, onChange, withLabel, title}) => (
  <StyledWizardCheckbox className="checkbox-container" title={title}>
    <input type="checkbox" checked={checked} onChange={onChange}/>{withLabel ? <label/> : null}
    <span className="checkmark"/>
  </StyledWizardCheckbox>
);

export const CheckboxCell = ({row, isChecked, onChange}) => (
  <td className="checkbox-cell">
    <WizardCheckbox
      checked={isChecked(row)}
      onChange={event => onChange(event.target.checked, row)}
    />
  </td>
);

CheckboxCell.propTypes = {
  row: PropTypes.object,
  isChecked: PropTypes.any,
  onChange: PropTypes.func
};
