import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {
  getOptionName,
  prepareOptions,
  isDisabledEditingRequestField,
} from '../../../../../utils/general';
import TextEditableField, {TextField} from '../../../../RequestDetails/components/forms/TextEditableField';
import ChangeFieldConfirmAlert from '../ChangeFieldConfirmAlert';

function General(props) {
  const dispatch = useDispatch();
  const requestDetails = useSelector(state => state.netflixRequestDetails);
  const {
    loaders,
    details,
    filters: dictionaries,
  } = requestDetails;
  const {
    DistributionList,
    ClientContactID,
    CSUserID,
    AdditionalDescription,
    GeneralNote,
    MemfisCompanyID,
  } = details;
  const loading = loaders.isLoadingDetails;
  const isDisabledEditing = isDisabledEditingRequestField(details);
  const memfisClientOptions = prepareOptions(dictionaries.memfis_clients, MemfisCompanyID);
  const memfisContactOptions = prepareOptions(dictionaries.memfis_contacts, ClientContactID);
  const csUserOptions = prepareOptions(dictionaries.cs_users, CSUserID);

  const handleChangeField = (label, name, value, callback, options) => {
    ChangeFieldConfirmAlert({dispatch, details, label, name, value, callback, options});
  };

  return (
    <StyledGeneral>
      {(loading || isDisabledEditing) ?
        <TextField
          label="Distribution List"
          value={DistributionList}
        /> :
        <TextEditableField
          label="Distribution List"
          name="DistributionList"
          value={DistributionList}
          onConfirm={handleChangeField}
          isTextArea
          allowReset
          tooltip="list of email addresses, will be used for send report feature"
        />
      }
      {(loading || isDisabledEditing) ?
        <TextField
          label="Client Contact"
          value={getOptionName(memfisContactOptions, ClientContactID)}
        /> :
        <TextEditableField
          label="Client Contact"
          name="ClientContactID"
          value={ClientContactID}
          options={memfisContactOptions}
          onConfirm={handleChangeField}
          isSelectControl
          allowReset
        />
      }
      {(loading || isDisabledEditing) ?
        <TextField
          label="Client Service Representative User"
          value={getOptionName(csUserOptions, CSUserID)}
        /> :
        <TextEditableField
          label="Client Service Representative User"
          name="CSUserID"
          value={CSUserID}
          options={csUserOptions}
          onConfirm={handleChangeField}
          isSelectControl
          allowReset
        />
      }
      {((loading || isDisabledEditing) ?
          <TextField
            label="Additional Description"
            value={AdditionalDescription}
          /> :
          <TextEditableField
            label="Additional Description"
            name="AdditionalDescription"
            value={AdditionalDescription}
            onConfirm={handleChangeField}
            maxLength={255}
            isTextArea
          />
        )
      }
      {(loading || isDisabledEditing) ?
        <TextField
          label="General Notes"
          value={GeneralNote}
        /> :
        <TextEditableField
          label="General Notes"
          name="GeneralNote"
          value={GeneralNote}
          onConfirm={handleChangeField}
          isTextArea
          allowReset
        />
      }
      {((MemfisCompanyID && isDisabledEditing) || loading) ?
        <TextField
          label="MEMFIS Client"
          value={getOptionName(memfisClientOptions, MemfisCompanyID)}
        /> :
          memfisClientOptions.length && !isDisabledEditing ?
            <TextEditableField
              label="MEMFIS Client"
              name="MemfisCompanyID"
              value={MemfisCompanyID}
              options={memfisClientOptions}
              onConfirm={handleChangeField}
              isSelectControl
              allowReset
              selectProps={{selectClassName: 'menu_position_top'}}
            /> : null
      }
    </StyledGeneral>
  );
}

const StyledGeneral = styled.div`
`;

export default General;
