import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import classNames from 'classnames';
import Comment from './Comment';
import CommentImg from '../../../../../assets/images/issueTable/comment-disabled.svg';
import CommentHoverImg from '../../../../../assets/images/issueTable/comment.svg';
import {getUserRoles} from '../../../../utils/general';
import Can from '../../../../components/Can';
import currentPositionImg from '../../../../../assets/images/video/current-position.svg';

export const getIssueCommentsCount = (issue) => {
  return issue.Comments + (issue.ClientStatusesCount || (issue.ClientStatusID ? 1 : 0)) + (issue.ImagesCount || 0);
};

class Issue extends React.PureComponent {
  state = {
    isShowAllComments: false
  };

  getCommentsCount = () => {
    const {issue} = this.props;
    return getIssueCommentsCount(issue);
  };

  onClickMore = e => {
    e.stopPropagation();
    const {issue, onClickMore} = this.props;
    if (!issue.comments || issue.comments.length < this.getCommentsCount()) {
      onClickMore(issue);
    }
    this.setState({
      isShowAllComments: true
    });
  };

  onClickIssue = () => {
    const {issue, onSelectIssue} = this.props;
    onSelectIssue(issue);
  }

  render() {
    const {isShowAllComments} = this.state;
    const {issue, selectedIssue, canComment, onDiscussIssue, onClickIssueImage, onClickEdit, allowEditOnRequest,
      onClickDelete, onClickUndelete, onClickDuplicate, isValidTimeCodeSettings
    } = this.props;
    const issueClasses = classNames({
      issue: true,
      [`issue-${issue.IssueID}`]: true,
      selected: selectedIssue
    });

    const commentsCount = this.getCommentsCount();
    const hasCommentsList = !!(issue.comments || []).length;

    return (
      <IssueStyled
        className={issueClasses}
        onClick={this.onClickIssue}
      >
        <BorderStyled className={`content${issue.IsDeleted ? ' issue-deleted' : ''}`} issueColor={issue.SeverityColor}>
          <div className="time-code" title="SMPTE timecode">
            {issue.TimeCodeStart}{issue.TimeCodeEnd ? ` - ${issue.TimeCodeEnd}` : ''}
          </div>
          <div className="additional-actions">
            {!!canComment &&
              <CommentIconStyled
                isActive={!!commentsCount}
                title="Discuss Issue"
                onClick={event => {
                  event.stopPropagation();
                  onDiscussIssue(issue);
                }}
              />
            }
            <Can
              roles={getUserRoles()}
              perform="report-details:show-issue-image-btn"
              data={issue}
              yes={() => (
                <ImageIconStyled
                  className={`fa fa-image${!issue.ImageExists ? ' greyed' : ''}`}
                  title="Issue Images"
                  onClick={event => {
                    event.stopPropagation();
                    onClickIssueImage(issue);
                  }}
                />
              )}
            />
            <Can
              roles={getUserRoles()}
              perform="report-details:show-edit-issue-btn"
              data={{...issue, allowEditOnRequest}}
              yes={() => (
                <ImageIconStyled
                  className={`${!isValidTimeCodeSettings ? 'disabled fa-light' : 'far'} fa-edit`}
                  title={isValidTimeCodeSettings ? 'Click to edit Issue' : 'Editing an issue is disallowed until MEMFIS sends START OF FILE and frame rate for this request'}
                  onClick={event => {
                    event.stopPropagation();
                    !!isValidTimeCodeSettings && onClickEdit(issue);
                  }}
                />
              )}
            />
            <Can
              roles={getUserRoles()}
              perform="report-details:show-edit-issue-btn"
              data={{...issue, allowEditOnRequest}}
              yes={() => (
                <ImageIconStyled
                  className={`${!isValidTimeCodeSettings ? 'disabled fa-light' : 'far'} fa-clone`}
                  title={isValidTimeCodeSettings ? 'Click to duplicate Issue' : 'Issue duplication is disallowed until MEMFIS sends START OF FILE and frame rate for this request'}
                  onClick={event => {
                    event.stopPropagation();
                    !!isValidTimeCodeSettings && onClickDuplicate(issue);
                  }}
                />
              )}
            />
            {issue.IsDeleted ?
              <Can
                roles={getUserRoles()}
                perform="report-details:show-delete-issue-btn"
                data={issue.IsQodIssue}
                yes={() => (
                  <ImageIconStyled
                    className="far fa-trash-restore"
                    title="Click to undelete Issue"
                    onClick={event => {
                      event.stopPropagation();
                      onClickUndelete(issue);
                    }}
                  />
                )}
              /> :
                <Can
                  roles={getUserRoles()}
                  perform="report-details:show-delete-issue-btn"
                  data={issue.IsQodIssue}
                  yes={() => (
                    <ImageIconStyled
                      className="far fa-trash"
                      title="Click to delete Issue"
                      onClick={event => {
                        event.stopPropagation();
                        onClickDelete(issue);
                      }}
                    />
                  )}
                />
            }
          </div>
          <div className="description">
            {issue.IssueType}{issue.Description ? `: ${issue.Description}` : ''}
          </div>
        </BorderStyled>
        {!canComment ? null : isShowAllComments ? (
          hasCommentsList ? issue.comments.map((comment, index) => {
            return (
              <Comment
                key={index}
                comment={comment}
              />
            )
          }) : null
        ) : commentsCount && (hasCommentsList || issue.FirstComment || issue.FirstCommentUser || issue.ClientStatusesCount || issue.ClientStatusID || issue.ImagesCount) ? (
          <Comment
            isFirstComment
            comment={
              hasCommentsList ? issue.comments[0] :
                (issue.FirstComment || issue.FirstCommentUser) ? {UserName: issue.FirstCommentUser, Comment: issue.FirstComment, UserImageUrl: issue.FirstCommentUserLogo} :
                  issue.ImagesCount ? {UserName: issue.FirstImageUser, UserImageUrl: issue.FirstImageUserLogo, IssueImageUrl: issue.FirstImageUrl} :
                  issue.ClientStatusesCount ? {UserName: issue.FirstClientStatusUser, UserImageUrl: issue.FirstClientStatusUserLogo, StatusID: issue.FirstClientStatusID} :
                   {StatusID: issue.ClientStatusID, ClientStatus: issue.ClientStatus}
            }
            length={commentsCount}
            onClickMore={this.onClickMore}
          />
        ) : null}
        <div className='currentPosition'>Current Position</div>
      </IssueStyled>
    );
  }
}

const BorderStyled = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 0 4px rgba(0,0,0,0.1);

  border-left: ${props => props.issueBorderWidth || '4px'} solid transparent;
  border-color: ${props => `#${props.issueColor}` || ''};
  &.issue-deleted {
    > .description, > .time-code {
      text-decoration: line-through;
    }
  }
`;

const IssueStyled = styled.div`
  border-radius: 3px;
  font-size: 12px;
  margin-top: 15px;

  .currentPosition {
    display: none;
    background: url(${currentPositionImg}) center left 5px no-repeat;
    background-color: #515151;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: default;
    font-size: 9px;
    outline: none;
    padding: 4px 15px;
    margin-top: 10px;
  }

  .btn {
    border: none;
    background: none;
    font-size: 12px;
    font-weight: bold;
    outline: none;
    padding: 0;
  }

  .content {
    padding: 15px;
    .time-code {
      font-weight: bold;
      color: #282828;
      display: inline-block;
    }
    .description {
      margin-top: 15px;
      opacity: 0.5;
      text-transform: uppercase;
    }
    .additional-actions {
      float: right;
      display: flex;
    }
  }
`;

const CommentIconStyled = styled.i`
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: block;
  margin: 0 3px;
  height: 21px;
  width: 21px;
  background-image: url(${CommentImg});

  ${props => props.isActive && css`
    background-image: url(${CommentHoverImg});
  `}

  &:not(.disabled, [disabled]):hover {
    box-shadow: 0 0 0 1px #ededed;
    background-color: #f3f3f3;
    border-radius: 3px;
  }
`;

export const ImageIconStyled = styled.i`
  cursor: pointer;
  color: #434343;
  font-size: 14px;
  padding: 3px;
  margin: 0 3px;

  &.greyed {
    color: #dadada;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(.disabled, [disabled]):hover {
    box-shadow: 0 0 0 1px #ededed;
    background-color: #f3f3f3;
    border-radius: 3px;
    &.greyed {
      color: #bbb;
    }
  }
`;

Issue.propTypes = {
  issue: PropTypes.object.isRequired,
  selectedIssue: PropTypes.bool.isRequired,
  allowEditOnRequest: PropTypes.bool,
  canComment: PropTypes.bool.isRequired,
  onDiscussIssue: PropTypes.func.isRequired,
  onSelectIssue: PropTypes.func.isRequired,
  onClickMore: PropTypes.func.isRequired,
  onClickIssueImage: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDuplicate: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickUndelete: PropTypes.func.isRequired,
};

export default Issue;