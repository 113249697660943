import React from 'react';
import PropTypes from 'prop-types';
import {HeaderCell} from './cells/HeaderCell';
import {HeaderFilterCell} from './cells/HeaderFilterCell';

export const HeaderRow = ({headers, sort, onHeaderCellClick}) => (
  <thead>
    <tr>
      {Object.keys(headers).map((k, i) => {
        const customHeaderCellArgs = headers[k].headCellArgs;
        if (customHeaderCellArgs && customHeaderCellArgs.filterable) {
          return (
            <HeaderFilterCell
              key={i}
              field={k}
              sort={sort}
              onSort={onHeaderCellClick}
              className={headers[k].className}
              title={headers[k].title}
              {...customHeaderCellArgs}
            />
          );
        }
        const customHeaderCell = headers[k].headCell;
        return customHeaderCell && customHeaderCell.component && customHeaderCell.componentArgs ?
          <customHeaderCell.component {...customHeaderCell.componentArgs} key={i}/> :
          <HeaderCell headers={headers} sort={sort} onClick={onHeaderCellClick} field={k} key={i}/>;
      })}
    </tr>
  </thead>
);

HeaderRow.propTypes = {
  headers: PropTypes.object.isRequired,
  sort: PropTypes.object,
  onHeaderCellClick: PropTypes.func
};
