import React from 'react';
import PropTypes from 'prop-types';

export const TimeCodeCell = React.memo(({row, className}) => {
  const timeCodeStartArr = row.TimeCodeStart && row.TimeCodeStart.split(':');
  const timeCodeEndArr = row.TimeCodeEnd && row.TimeCodeEnd.split(':');

  return (
    <td
      className={className}
      title="SMPTE Timeсode"
      style={row.SeverityColor ? {borderLeftColor: `#${row.SeverityColor}`} : {}}
    >
      {timeCodeStartArr && timeCodeStartArr.length === 4 ?
        <span>{timeCodeStartArr[0]}:{timeCodeStartArr[1]}:<b>{timeCodeStartArr[2]}:{timeCodeStartArr[3]}</b></span> : row.TimeCodeStart || null
      }
      {row.TimeCodeEnd ? <span> - </span> : null}
      {timeCodeEndArr && timeCodeEndArr.length === 4 ?
        <span>{timeCodeEndArr[0]}:{timeCodeEndArr[1]}:<b>{timeCodeEndArr[2]}:{timeCodeEndArr[3]}</b></span> : row.TimeCodeEnd || null
      }
    </td>
  );
});

TimeCodeCell.propTypes = {
  row: PropTypes.object,
  className: PropTypes.string
};
