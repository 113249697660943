import {USER_SET_IS_LOGOUT} from '../../../../pages/Login/actions';
import {
  ARTWORK_TOP_FILTERS_CLEAR,
  ARTWORK_TOP_FILTERS_CHANGE,
  ARTWORK_TOP_FILTERS_SET_LOADING,
  ARTWORK_TOP_FILTERS_SET_DICTIONARIES,
} from './actions';

const initialState = {
  loaders: {
    dictionaries: false,
  },

  dictionaries: {},

  values: {
    titleId: 0,
    projectId: 0,

    amazonSeriesId: 0,
    amazonConceptName: null,
    amazonAssetType: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SET_IS_LOGOUT:
    case ARTWORK_TOP_FILTERS_CLEAR: {
      return {...initialState};
    }

    case ARTWORK_TOP_FILTERS_CHANGE: {
      return {
        ...state,
        values: action.values
      };
    }

    case ARTWORK_TOP_FILTERS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case ARTWORK_TOP_FILTERS_SET_DICTIONARIES: {
      return {
        ...state,
        dictionaries: action.dictionaries || initialState.dictionaries
      }
    }

    default: {
      return state;
    }
  }
}
