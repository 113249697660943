import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {
  toggleAdditionalReportsModal,
  downloadAttachment
} from '../../actions';
import Loader from '../../../../components/Loader';
import AdditionalReportsTable from '../table/AdditionalReportsTable';

const AdditionalReportsModal = props => {
  const {
    dispatch,
    match,
    reportdetails: {
      isAttachmentDownloading,
      details: {
        additional_reports
      }
    }
  } = props;
  const requestId = +match.params.requestId;

  const handleClose = () => dispatch(toggleAdditionalReportsModal(false));

  const handleDownload = attachment => {
    const attachmentId = attachment.id;
    dispatch(downloadAttachment(attachmentId, requestId));
  };

  return (
    <StyledMobileModal>
      <StyledModalBackdrop
        onClick={handleClose}
      />
      <StyledModalDialog className="mobile-modal-dialog-custom">
        {isAttachmentDownloading && <Loader className="full-screen"/>}
        <StyledModal>
          <StyledModalHeader className="modal-header-custom">
            <div className="back-btn" onClick={handleClose}/>
            <div className="modal-header-content">
              <h4>Additional Reports</h4>
              <div>
                <StyledBaseCloseModal className="icon-close-modal" title="Close" onClick={handleClose}/>
              </div>
            </div>
          </StyledModalHeader>
          <StyledModalBody className="modal-body-custom">
            <AdditionalReportsTable
              data={additional_reports}
              onDownload={handleDownload}
            />
          </StyledModalBody>
        </StyledModal>
      </StyledModalDialog>
    </StyledMobileModal>
  );
};

const StyledModalHeader = styled.div`
  padding: 31px 35px 0 45px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    padding: 8.5px 0 8.5px 23px;
    @media (max-width: 767px) {
      padding:0 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  position: fixed;
  top: 104px;
  bottom: 71px;
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 46px;
  padding-right: 27px;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      -webkit-box-shadow: none;
      background-color: #c4c4c4;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    bottom: 83px;
    padding: 0;
    &.step-version {
      padding: 0 23px;
    }
  }

  .loader-container {
    z-index: 1070;
  }
`;

AdditionalReportsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  reportdetails: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    reportdetails: state.reportdetails
  };
}

export default connect(
  mapStateToProps
)(withRouter(AdditionalReportsModal));
