import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

class BlockFooter extends Component {
  render() {
    const {linkTo} = this.props;
    return (
      <StyledBlockFooter>
        <Link to={linkTo} className="btn btn-default">View All</Link>
      </StyledBlockFooter>
    );
  }
}

const StyledBlockFooter = styled.div`
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  height: 49px;
  margin-top: 10px;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  .btn {
    background: #4E4E4E;
    border-radius: 100px;
    border: none;
    color: #FFFFFF;
    font-size: 10px;
    text-transform: uppercase;
    &:focus, &:hover {
      outline: none;
    }
  }

  @media (max-width: 991px) {
    border-radius: 0 0 3px 3px;
    margin: 0;
    padding: 0;
    height: 45px;
    .btn {
      width: 100%;
      height: 100%;
      border-radius: 0 0 3px 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

BlockFooter.defaultProps = {
  linkTo: '#'
};

BlockFooter.propTypes = {
  linkTo: PropTypes.string
};

export default BlockFooter;