import React from 'react';
import styled from 'styled-components';
import noData from '../../../../assets/images/dashboard/no-chart.svg'

const NoData = () => {
  return (
    <StyledNoData>
      <img src={noData} alt=""/>
      <p><strong>Hmmmm...</strong></p>
      <p>Looks like we don't have enough data to generate this chart yet</p>
    </StyledNoData>
  );
};

const StyledNoData = styled.div`
  color: #a2a2a2;
  font-size: 10px;
  line-height: 14px;
  padding-bottom: 20px;
  text-align: center;
  
  img {
    margin: 25px 0 15px;
  }
  
  p {
    margin-bottom: 5px;
  }
  
  b {
    font-size: 12px;
    margin-left: 5px;
  }
`;

export default NoData;