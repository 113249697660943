/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import 'bootstrap';
import Loader from '../../../../components/Loader';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import {
    getGeneralReportInfo, saveGeneralReportAudioChannels, saveGeneralReportInfo
} from '../../actions';
import {Row, Col, Button} from 'react-bootstrap';
import DateField from "../../../Analytics/components/DateField";
import moment from "moment";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Tabs, {getTabTitle} from '../../../../components/Tabs';
import {Tab} from 'react-bootstrap';
import ReactJson from 'react-json-view';
import SelectAdvanced from "../../../../components/forms/SelectAdvanced";

const styles = {
    audio_channel_header: {
        margin: 0,
        width: '95%',
    },
};

function AudioChannelRow(props) {
    const {audio_track, audio_config, audio_config_fail, audio_peak, audio_peak_fail, audio_language} = props;

    const handleChangeConfig = (value) => {
        props.onChangeConfig(props.audio_track, value);
    };

    const handleChangeConfigFail = (value) => {
        props.onChangeConfigFail(props.audio_track, value);
    };

    const handleChangePeak = (value) => {
        props.onChangePeak(props.audio_track, value);
    };

    const handleChangePeakFail = (value) => {
        props.onChangePeakFail(props.audio_track, value);
    };

    const handleChangeLanguage = (value) => {
        props.onChangeLanguage(props.audio_track, value);
    };

    const handleRemoveChannel = () => {
        props.onRemoveChannel(props.audio_track);
    }

    const configClass = audio_config_fail ? "red-color" : "";
    const peakClass = audio_peak_fail ? "red-color" : "";

    return (
        <Row
          key={`audio_channel-${audio_track}`}
          className="sortable-row-helper"
          title="Use drag and drop to change the order of channels"
          >
          <Col md={1} xs={6}>
            {audio_track}
          </Col>
          <Col md={2} xs={6} className={configClass}>
            <input
                type="text"
                value={audio_config}
                onChange={(event) => handleChangeConfig(event.target.value)}
            />
          </Col>
            <Col md={1} xs={6} className="type-field">
              <input
                type="checkbox"
                checked={audio_config_fail}
                onChange={event => handleChangeConfigFail(event.target.checked ? 1 : 0)}
              />
            </Col>
          <Col md={2} xs={6} className={peakClass}>
            <input
                type="text"
                value={audio_peak}
                onChange={(event) => handleChangePeak(event.target.value)}
            />
          </Col>
            <Col md={1} xs={6} className="type-field">
                <input
                    type="checkbox"
                    checked={audio_peak_fail}
                    onChange={event => handleChangePeakFail(event.target.checked ? 1 : 0)}
                />
            </Col>
            <Col md={2} xs={6}>
                <input
                    type="text"
                    value={audio_language}
                    onChange={(event) => handleChangeLanguage(event.target.value)}
                />
            </Col>
          <Col md={1} xs={6} className="sound-field">
            <button
                onClick={handleRemoveChannel}
            >Remove</button>
          </Col>
        </Row>
      );
}

const SortableAudioChannelItem = SortableElement(AudioChannelRow);

const SortableAudioChannelList = SortableContainer(({items, onChangeConfig, onChangeConfigFail, onChangePeak, onChangePeakFail, onChangeLanguage, onRemoveChannel}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableAudioChannelItem
          key={`item-${value.audio_track}`}
          index={index}
          audio_track={value.audio_track}
          audio_config={value.audio_config}
          audio_config_fail={value.audio_config_fail}
          audio_peak={value.audio_peak}
          audio_peak_fail={value.audio_peak_fail}
          audio_language={value.audio_language}
          onChangeConfig={onChangeConfig}
          onChangeConfigFail={onChangeConfigFail}
          onChangePeak={onChangePeak}
          onChangePeakFail={onChangePeakFail}
          onChangeLanguage={onChangeLanguage}
          onRemoveChannel={onRemoveChannel}
        />
      ))}
    </div>
  );
});

class GeneralReportInfoModal extends Component {

    state = {
        showLoader: false,
        general_info: {},
        audio_channels: [],
        metadata: [],
        selectedMetadataId: null,
        fields: [
            {name: 'qc_num', label: 'QC #'},
            {name: 'title', label: 'Title'},
            {name: 'aspect_ratio', label: 'Aspect Ratio'},
            {name: 'language', label: 'Language'},
            {name: 'element_description', label: 'Element Description'},
            {name: 'manufacturing_vendor', label: 'Manufacturing Vendor'},
            {name: 'manufacture_date', label: 'Manufacturing Date', type: 'date'},
            {name: 'date', label: 'Original QC Date', type: 'date'},
            {name: 'revised_date', label: 'Revised Date', type: 'date'},
            {name: 'format', label: 'Format'},
            {name: 'standard', label: 'Standard'},
            {name: 'frame_rate', label: 'Frame Rate'},
            {name: 'program_duration_min_new', label: 'Program Duration SMPTE'},
            {name: 'textless_duration', label: 'Textless Duration SMPTE'},
            {name: 'reel_id', label: 'Reel ID'},
            {name: 'horizontal_blanking', label: 'Horizontal  Blanking'},
            {name: 'luminance_level', label: 'Luminance Level'},
            {name: 'chroma_level', label: 'Chroma Level'},
            {name: 'set_up', label: 'SetUp'},
            {name: 'first_line', label: 'First Line'},
            {name: 'last_line', label: 'Last Line'},
            {name: 'sampling_ratio', label: 'Sampling Ratio'},
            {name: 'white_point', label: 'White Point'},
            {name: 'codec_resolution', label: 'Video Resolution'},
            {name: 'codec_codec', label: 'Video Codec'},
            {name: 'codec_compression_mode', label: 'Compression Mode'},
            {name: 'codec_video_bit_rate', label: 'Video Bit Rate'},
            {name: 'codec_video_bit_depth', label: 'Video Bit Depth'},
            {name: 'codec_audio_codec', label: 'Audio Codec'},
            {name: 'codec_audio_format', label: 'Audio Format'},
            {name: 'codec_samples', label: 'Audio Samples'},
            {name: 'codec_audio_bit_depth', label: 'Audio Bit Depth'},
            {name: 'codec_audio_bit_rate', label: 'Audio Bit Rate'},
            {name: 'calm_act_compliant', label: 'LKFS Compliant, yes or no'},
            {name: 'cc', label: 'Closed Captions'},
            {name: 'commercial_blacks_num', label: 'Total # Ad Breaks'},
            {name: 'color_space', label: 'Color Space'},
            {name: 'medium', label: 'Medium'},
            {name: 'pse_test', label: 'PSE Test'},
            {name: 'pass_fail', label: 'Pass Fail', type: 'select', options: [{value: 'FAILED', name: 'FAILED'}, {value: 'PASSED', name: 'PASSED'}, {value: 'REVIEW', name: 'REVIEW'}]},
            {name: 'max_fall', label: 'MaxFALL'},
            {name: 'max_cll', label: 'MaxCLL'},
            {name: 'part', label: 'Part'},
            {name: 'total_parts', label: 'Total Parts'},
            {name: 'qc_location', label: 'QC Location'},
            {name: 'review_fail_item_notes', label: 'Review Fail Item Notes', type: 'textarea'},
            {name: 'general_notes', label: 'General Notes', type: 'textarea'},
            {name: 'trim_passes_mastering_display', label: 'Trim Passes Mastering Display'},
            {name: 'trim_passes_1000_nit', label: '1000 Nit'},
            {name: 'trim_passes_600_nit', label: '600 Nit'},
            {name: 'trim_passes_100_nit', label: '100 Nit'},
            {name: 'audio_loudness_51_17703', label: 'Audio Loudness 5.1 1770-3'},
            {name: 'audio_loudness_51_17703_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_51_17701', label: 'Audio Loudness 5.1 1770-1'},
            {name: 'audio_loudness_51_17701_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_20_17703', label: 'Audio Loudness 2.0 1770-3'},
            {name: 'audio_loudness_20_17703_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_20_17701', label: 'Audio Loudness 2.0 1770-1'},
            {name: 'audio_loudness_20_17701_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_atmos_17703', label: 'Audio Loudness Atmos 1770-3'},
            {name: 'audio_loudness_atmos_17703_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_atmos_17701', label: 'Audio Loudness Atmos 1770-1'},
            {name: 'audio_loudness_atmos_17701_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_51_17704', label: 'Audio Loudness 5.1 1770-4'},
            {name: 'audio_loudness_51_17704_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_20_17704', label: 'Audio Loudness 2.0 1770-4'},
            {name: 'audio_loudness_20_17704_fail', label: 'Fail', type: 'checkbox'},
            {name: 'audio_loudness_atmos_17704', label: 'Audio Loudness Atmos 1770-4'},
            {name: 'audio_loudness_atmos_17704_fail', label: 'Fail', type: 'checkbox'},
        ]
    };


    loadGeneralReportInfo = () => {
        const {dispatch, reportdetails} = this.props;
        this.setState({
            showLoader: true
        });
        dispatch(getGeneralReportInfo(reportdetails.details.RequestID))
            .done(res => {
                this.setState({
                    general_info: res.general_info,
                    audio_channels: res.audio_channels || [],
                    metadata: res.metadata || [],
                    selectedMetadataId: (res.metadata && res.metadata.length > 0 ? res.metadata[0].metadata_id : 0)
                });
            })
            .always(() => {
                this.setState({
                    showLoader: false
                });
            });
    };

    componentDidMount() {
        document.body.classList.add('modal-open');
        this.loadGeneralReportInfo();
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    handleCloseModal = () => {
        this.props.onCloseModal();
    };

    handleInputChange = (fieldName, value) => {
        const newGeneralReportData = {
            ...this.state.general_info,
            [fieldName]: value
        };
        this.setState({general_info: newGeneralReportData});
    }

    handleDateChange = (fieldName, value) => {
        let newValue = null;
        if (!!value && !!value.getTime()) {
            newValue = moment(value).format('YYYY-MM-DD');
        }

        const newGeneralReportData = {
            ...this.state.general_info,
            [fieldName]: newValue
        };
        this.setState({general_info: newGeneralReportData});
    }

    handleSaveGeneralReport = () => {
        const {dispatch, reportdetails} = this.props;
        this.setState({
            showLoader: true
        });
        dispatch(saveGeneralReportInfo(reportdetails.details.RequestID, this.state.general_info))
            .always(() => {
                this.setState({
                    showLoader: false
                });
            });
    }

    onAudioChannelSortEnd = ({oldIndex, newIndex}) => {
        const audioChannels = arrayMove(this.state.audio_channels, oldIndex, newIndex);
        audioChannels[oldIndex].audio_track = oldIndex + 1;
        audioChannels[newIndex].audio_track = newIndex + 1;

        this.setState({audio_channels: audioChannels});
    };

    onChangeAudioChannelConfig = (audioChannelTrack, configValue) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels[audioChannelTrack - 1].audio_config = configValue;
        this.setState({audio_channels: audioChannels});
    }

    onChangeAudioChannelConfigFail = (audioChannelTrack, configFail) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels[audioChannelTrack - 1].audio_config_fail = configFail;
        this.setState({audio_channels: audioChannels});
    }

    onChangeAudioChannelPeak = (audioChannelTrack, peakValue) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels[audioChannelTrack - 1].audio_peak = peakValue;
        this.setState({audio_channels: audioChannels});
    }

    onChangeAudioChannelPeakFail = (audioChannelTrack, peakFail) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels[audioChannelTrack - 1].audio_peak_fail = peakFail;
        this.setState({audio_channels: audioChannels});
    }

    onChangeAudioChannelLanguage = (audioChannelTrack, languageValue) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels[audioChannelTrack - 1].audio_language = languageValue;
        this.setState({audio_channels: audioChannels});
    }

    handleAddAudioChannel = () => {
        const {reportdetails} = this.props;
        const audioChannels = [...this.state.audio_channels];
        audioChannels.push({
            qod_request_id: reportdetails.details.RequestID,
            audio_track: audioChannels.length + 1,
            audio_config: '',
            audio_config_fail: 0,
            audio_peak: '',
            audio_peak_fail: 0,
            audio_language: ''
        });
        this.setState({audio_channels: audioChannels});
    }

    handleRemoveAudioChannel = (channelTrack) => {
        const audioChannels = [...this.state.audio_channels];
        audioChannels.splice(channelTrack - 1, 1);
        // correct values for track number field
        let index = 1;
        audioChannels.forEach(c => c.audio_track = index++);
        this.setState({audio_channels: audioChannels});
    }

    handleSaveAudioChannels = () => {
        const {dispatch, reportdetails} = this.props;
        this.setState({
            showLoader: true
        });
        dispatch(saveGeneralReportAudioChannels(reportdetails.details.RequestID, this.state.audio_channels))
            .always(() => {
                this.setState({
                    showLoader: false
                });
            });
    }

    handleChangeMetadataFile = (_, value) => {
        this.setState({
            selectedMetadataId: value
        });
    }

    render() {
        const metadataObj = this.state.metadata.find(m => m.metadata_id === this.state.selectedMetadataId) || {};
        const metadata = JSON.parse(metadataObj.metadata || '{}');
        return (
            <StyledMobileModal className="mobile-modal-with-issue-table">
                <StyledModalBackdrop
                    onClick={() => this.handleCloseModal()}
                />
                <StyledModalDialog className="mobile-modal-dialog-custom">
                    {this.state.showLoader ? <Loader/> : null}
                    <StyledModal>
                        <StyledModalHeader className="modal-header-custom">
                            <div className="back-btn" onClick={this.handleCloseModal}/>
                            <div className="modal-header-content">
                                <h4>General Report</h4>
                                <div>
                                    <StyledBaseCloseModal className="icon-close-modal" title="Close" onClick={() => this.handleCloseModal()}/>
                                </div>
                            </div>
                            <div className="modal-fields">
                                <StyledTabs
                                    id="tabs"
                                    animation={false}
                                >
                                    <Tab eventKey={1} title={getTabTitle("General")} className="general-view2">
                                        {this.state.fields.map(field => {
                                            return (
                                                <div className="field-wrapper">
                                                    <span className="field-label">{field.label}:</span>
                                                    {field.type === 'select' ?
                                                        <select
                                                          value={this.state.general_info[field.name]}
                                                          onChange={(event) => this.handleInputChange(field.name, event.target.value)}
                                                        >
                                                          <option value="">--Please choose an option--</option>
                                                          {field.options.map(opt => {
                                                            return (
                                                              <option value={opt.value}>{opt.name}</option>
                                                            )})
                                                          }
                                                        </select> :
                                                        field.type === 'date' ?
                                                        <div className="inline-block datepicker">
                                                            <DateField
                                                                value={this.state.general_info[field.name]}
                                                                onChange={(id, value) => this.handleDateChange(field.name, value)}
                                                            />
                                                        </div> :
                                                        field.type === 'textarea' ?
                                                            <textarea
                                                                className="field-input"
                                                                value={this.state.general_info[field.name]}
                                                                onChange={(event) => this.handleInputChange(field.name, event.target.value)}
                                                                rows="5"
                                                            /> :
                                                            field.type === 'checkbox' ?
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.general_info[field.name]}
                                                                    onChange={(event) => this.handleInputChange(field.name, event.target.checked ? 1 : 0)}
                                                                /> :
                                                                <input
                                                                    type="text"
                                                                    className="field-input"
                                                                    value={this.state.general_info[field.name]}
                                                                    onChange={(event) => this.handleInputChange(field.name, event.target.value)}
                                                                />
                                                    }
                                                </div>
                                            )
                                        })}
                                        <Row>
                                            <Col md={12} xs={12} className="modal-btn-toolbar">
                                                <Button
                                                    bsStyle="primary"
                                                    onClick={this.handleSaveGeneralReport}
                                                    title="Save general report data"
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={2} title={getTabTitle("Audio Channels")} className="audio-channels-view">
                                        <div>
                                            {this.state.audio_channels.length > 0 ?
                                                <Row style={styles.audio_channel_header}>
                                                    <Col md={1} xs={6}>
                                                        Track Number
                                                    </Col>
                                                    <Col md={2} xs={6}>
                                                        Name
                                                    </Col>
                                                    <Col md={1} xs={6} className="type-field">
                                                        Fail
                                                    </Col>
                                                    <Col md={2} xs={6} className="sound-field">
                                                        Peak
                                                    </Col>
                                                    <Col md={1} xs={6} className="type-field">
                                                        Peak Fail
                                                    </Col>
                                                    <Col md={2} xs={6} className="type-field">
                                                        Language
                                                    </Col>
                                                    <Col md={1} xs={6} className="type-field"/>
                                                </Row> : null
                                            }
                                            <SortableAudioChannelList
                                                items={this.state.audio_channels}
                                                onSortEnd={this.onAudioChannelSortEnd}
                                                onChangeConfig={this.onChangeAudioChannelConfig}
                                                onChangeConfigFail={this.onChangeAudioChannelConfigFail}
                                                onChangePeak={this.onChangeAudioChannelPeak}
                                                onChangePeakFail={this.onChangeAudioChannelPeakFail}
                                                onChangeLanguage={this.onChangeAudioChannelLanguage}
                                                onRemoveChannel={this.handleRemoveAudioChannel}
                                            />
                                            <button
                                                onClick={this.handleAddAudioChannel}
                                                title="Add new channel"
                                            >
                                                Add new channel
                                            </button>
                                            <Row>
                                                <Col md={12} xs={12} className="modal-btn-toolbar">
                                                    <Button
                                                        bsStyle="primary"
                                                        onClick={this.handleSaveAudioChannels}
                                                        title="Save audio channels"
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={3} title={getTabTitle("Metadata")}>
                                        <SelectAdvanced
                                            label="File"
                                            placeholder="Select file"
                                            options={this.state.metadata}
                                            valueKey="metadata_id"
                                            labelKey="filename"
                                            value={this.state.selectedMetadataId}
                                            onSelect={this.handleChangeMetadataFile}
                                        />
                                        <ReactJson
                                            src={metadata}
                                            name={false}
                                            quotesOnKeys={false}
                                            // sortKeys={true}
                                            collapseStringsAfterLength={40}
                                            iconStyle='square'
                                            collapsed={1}
                                            enableClipboard={false}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                        />
                                    </Tab>
                                </StyledTabs>
                            </div>
                        </StyledModalHeader>
                    </StyledModal>
                </StyledModalDialog>
                <GlobalStyled/>
            </StyledMobileModal>
        );
    }
}

const StyledModalHeader = styled.div`
  padding: 21px 25px 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8.5px 0 8.5px 23px;

    @media (max-width: 767px) {
      display: none;
    }
  }
  .modal-fields {
    padding: 19px 0;
    max-height: calc(100vh - 21px - 40px);
    display: flex;

    @media (min-width: 992px) {
      .col-md-7 {
        width: 61.69354839%;
      }
    }

    @media (max-width: 767px) {
      padding: 3px 25px 33px 23px;
      max-height: calc(100vh - 19px - 49px);
    }

    .field-wrapper {
      margin-bottom: 10px;

      .field-label {
        width: 200px;
        display: inline-block;
      }
      
      .field-input {
        min-width: 50%;
      }
      .inline-block {
        display: inline-block;
      }
      .react-datepicker__input-container input {
        font-size: 14px !important;
      }
    }

    .react-datepicker {
      font-size: 0.9em;
    }
    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__day-name, .react-datepicker__day {
      width: 1.7em;
      line-height: 1.7em;
      margin: 0.166em;
    }
    .react-datepicker__current-month {
      font-size: 1em;
    }
    .react-datepicker__navigation {
      top: 1em;
      line-height: 1.7em;
      border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #ccc;
      left: 1em;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ccc;
      right: 1em;
    }
  }
  .modal-btn-toolbar button:not(.close) {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background: #414141 !important;
    opacity: 0.8;
    border-radius: 100px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 11px 40px 12px;
    &.btn-primary {
      @media (min-width: 992px) {
        min-width: 128px;
      }
      &:disabled, &.disabled {
        background: #e0e0e1 !important;
        color: #c8c8c8;
        cursor: default;
      }
      &:not([disabled]):hover {
        opacity: 1;
      }
    }
    &.btn-default {
      background-color: transparent !important;
      color: #282828;
      margin-left: 17px;

      padding-left: 20px;
      padding-right: 20px;
      background: none;
      opacity: 0.8;
      border: #ccc 1px solid !important;

      &:hover {
        background: #f5f5f5 !important;
      }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  .modal-btn-toolbar {
    @media (max-width: 767px) {
      display: flex;
      justify-content: flex-start;
      button:first-child {
        order: 2;
      }
      button:last-child {
        order: 1;
        margin: 0 34px 0 0;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 45px;

  .tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    padding-top: 10px;

    .audio-channels-view {
      input[type="text"] {
        width: 100%;
      }
      .modal-btn-toolbar {
        margin-top: 10px;
      }
    }
    .react-json-view {
      width: 85vw;
    }
  }

  > .nav-tabs {
    float: none;
    margin-right: 0;

    @media (max-width: 767px) {
      margin-left: 25px;
    }

    > li {
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 13px;
      }

      a {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        color: #818284;
        margin-right: 0;

        @media (max-width: 767px) {
          border-bottom: none;
        }

        .tab-title {
          padding: 10px 27px 10px 7px !important;

          @media (min-width: 768px) {
            padding-left: 13px !important;
          }

          @media (max-width: 767px) {
            background-color: #EAEAEA;
          }

          .text {
            display: inline;

            @media (max-width: 767px) {
              font-size: 11px;
              line-height: 12px;
            }
          }
        }
      }

      &.active {
        border-bottom: 2px solid grey;
        a {
          background-color: #fff;
          box-shadow: 0 0 10px rgba(0,0,0,0.05);
          color: #282828;
        }
      }
    }
  }
`;

const GlobalStyled = createGlobalStyle`
  div.sortable-row-helper {
    z-index: 1070;
    background-color: #f1f4f7;
    width: 95%;
    margin: 0 0 3px;
    padding: 2px;
    border: 2px solid #e3e9ef;
    cursor: move;
  }
  div.red-color {
    color: red !important;
  }
`;

GeneralReportInfoModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    reportdetails: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        reportdetails: state.reportdetails
    };
}

export default connect(
    mapStateToProps
)(GeneralReportInfoModal);