import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import confirmImg from '../../../../../assets/images/settings/confirm.svg';
import cancelImg from '../../../../../assets/images/settings/cancel.svg';
import MultiSelect from './MultiSelect';
import _ from 'lodash';

function MultiSelectEditable(props) {
  const {
    label, name, options, value, onConfirm, onCancel, className
  } = props;

  const [currentValue, setCurrentValue] = useState(value || []);

  useEffect(() => {
    setCurrentValue(value || []);
  }, [value, setCurrentValue]);

  const handleCancel = () => {
    setCurrentValue(value || []);
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(label, name, currentValue);
    } else {
      handleCancel();
    }
  };

  const hasChanges = JSON.stringify(_.orderBy(currentValue)) !== JSON.stringify(_.orderBy(value || []));

  return (
    <StyledField
      className={className}
    >
      <MultiSelect
        label={label}
        options={options || []}
        value={currentValue.map(i => (options || []).find(o => o.value === i)).filter(Boolean)}
        onChange={selected => setCurrentValue((selected || []).map(i => i.value))}
      />
      {(hasChanges || onCancel) &&
        <>
          <img src={confirmImg} title="Save" alt="" onClick={hasChanges ? handleConfirm : handleCancel} />
          <img src={cancelImg} title="Cancel" alt="" onClick={handleCancel} />
        </>
      }
    </StyledField>
  );
}

const StyledField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  > .form-group {
    flex-grow: 1;
  }
  > img {
    margin-top: 26px;
    margin-left: 15px;
    flex: 0 0 21px;
    cursor: pointer;
  }
`;

export default MultiSelectEditable;
