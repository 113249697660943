import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectAdvanced from '../../../../components/forms/SelectAdvanced';
import {Button, ButtonToolbar, Row, Col, ControlLabel} from 'react-bootstrap';
import Switch from 'react-switch';

class AudioConfiguration extends Component {
  UNSAFE_componentWillMount() {
    const {values, onChange} = this.props;
    if (!values.data.length) {
      this.addNewAudioConfigItem(values.data);
      onChange('data', values.data);
    }
  }

  addNewAudioConfigItem(array) {
    const {filters} = this.props;
    const item = {
      type_id: filters.audio_types[0].value,
      sound_id: filters.audio_sounds[0].value,
      theatrical: false
    };
    array.push(item);
  }

  handleAudioConfigChanged = (name, value) => {
    const {values, onChange} = this.props;
    const audioConfig = /(type_id|sound_id|theatrical)-(\d+)/.exec(name);
    const propField = audioConfig[1];
    const propIndex = Number(audioConfig[2]);
    values.data[propIndex][propField] = value;
    onChange('data', values.data);
  };

  handleConfigsNumberChanged = index => {
    const {values, onChange} = this.props;
    if (typeof index === 'undefined') {
      this.addNewAudioConfigItem(values.data);
    } else {
      values.data.splice(index, 1);
    }
    onChange('data', values.data);
    this.forceUpdate();
  };

  renderConfigs = () => {
    const {values, filters, disabled} = this.props;
    const isNotSure = values.not_sure === true;
    const isDisabled = isNotSure || disabled === true;
    return values.data.map((item, i) => {
      return (
        <Row
          className={`audio-config i-${i} ${isDisabled ? 'disabled' : ''}`}
          key={`audio_configuration-${i}`}
          >
          <Col md={12} xs={12}>
            <hr/>
          </Col>
          <Col md={3} xs={6} className="type-field">
            <SelectAdvanced
              name={`type_id-${i}`}
              value={item.type_id}
              options={filters.audio_types}
              onSelect={this.handleAudioConfigChanged}
              disabled={isDisabled}
              placeholder=""
              />
          </Col>
          <Col md={3} xs={6} className="sound-field">
            <SelectAdvanced
              name={`sound_id-${i}`}
              value={item.sound_id}
              options={filters.audio_sounds}
              onSelect={this.handleAudioConfigChanged}
              disabled={isDisabled}
              placeholder=""
              />
          </Col>
          <Col md={3} xs={6} className="theatrical-field">
            <SelectAdvanced
              label="Theatrical"
              labelSeparator=""
              name={`theatrical-${i}`}
              value={item.theatrical}
              options={[{value: true, name: 'Yes'}, {value: false, name: 'No'}]}
              onSelect={this.handleAudioConfigChanged}
              disabled={isDisabled}
              placeholder=""
              />
          </Col>
          <Col md={3} xs={6}>
            <ButtonToolbar>
            {
              values.data.length > 1 ?
              <Button
                bsStyle="default"
                onClick={() => this.handleConfigsNumberChanged(i)}
                disabled={isDisabled || values.data.length === 1}
                title="Remove this configuration"
                >
                <span className="icon-trash"/>
              </Button> : null
            }
            </ButtonToolbar>
          </Col>
          {i === (values.data.length - 1) ? <Col md={12} xs={12}><hr className="last-line"/></Col> : null}
        </Row>
      );
    });
  };

  render() {
    const {values, onChange, disabled} = this.props;
    const isDisabled = disabled === true;
    return (
      <div className="form-group audio-configuration">
        <ControlLabel>Audio Configuration</ControlLabel>
        <div className="field">
          <div className="switch-field switch-not-sure">
            <Switch
              onChange={value => onChange('not_sure', value)}
              checked={values.not_sure}
              className={`react-switch${values.not_sure ? ' checked' : ''}`}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#F6F6F6"
              onColor="#FFFFFF"
              offHandleColor="#A9A9A9"
              onHandleColor="#7FCF18"
              activeBoxShadow="0px 0px 1px 1px transparent"
              handleDiameter={22}
              height={28}
              width={48}
              disabled={isDisabled}
              />
            <span>I am not sure</span>
          </div>
        </div>
        {this.renderConfigs()}
        <Button
          className="add-another"
          onClick={() => this.handleConfigsNumberChanged()}
          disabled={values.not_sure || isDisabled}
        >
          Add Another
        </Button>
      </div>
    );
  }
}

AudioConfiguration.propTypes = {
  values: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

AudioConfiguration.defaultProps = {
  values: {not_sure: false, data: []}
};

export default AudioConfiguration;
