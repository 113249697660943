import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select-v2';
import NavigationHamburgerIcon from '../../Artwork/components/Images/NavigationHamburgerIcon';
import DropdownIndicatorSvg from '../../Artwork/components/Images/DropdownIndicatorFillSvg';
import DropdownIndicatorDotsSvg from '../../Artwork/components/Images/DropdownIndicatorDotsSvg';
import Loader from '../../../components/Loader';
import {CONTENT_TYPES} from '../../NewRequest/constants';
import _ from 'lodash';
import isMobile from 'is-mobile';

const FILTER_CONTENT_OPTION_ALL = 0;
const FILTER_OPTION_ALL = '__ALL__';

const CustomSelect = (props) => {
  const selectProps = {...props};
  delete selectProps.isDropdownIndicatorDots;
  return (
    <Select
      className="versions-list"
      classNamePrefix="versions-select"
      placeholder=""
      isSearchable={!isMobile()}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: diProps => (
          <components.DropdownIndicator {...diProps}>
            {props.isDropdownIndicatorDots ? <DropdownIndicatorDotsSvg/> : <DropdownIndicatorSvg/>}
          </components.DropdownIndicator>
        )
      }}
      {...selectProps}
    />
  );
};

function TopFilters(props) {
  const {loading, dictionaries, values, onChange} = props;

  const contentOptions = [
    {SeriesID: FILTER_CONTENT_OPTION_ALL, SeriesName: 'All Content'},
    ...dictionaries.content
  ];
  const selContentOption = (values.content && contentOptions.find(o => o.SeriesID === values.content.SeriesID && o.ContentTypeID === values.content.ContentTypeID)) || contentOptions[0];
  const isAllContent = selContentOption.SeriesID === FILTER_CONTENT_OPTION_ALL;
  const isSeasonContent = [CONTENT_TYPES.Season, CONTENT_TYPES.Episode].includes(selContentOption.ContentTypeID);

  const seasonOptions = [
    {value: FILTER_OPTION_ALL, name: 'All Seasons'},
    ...(isSeasonContent ? selContentOption.SeasonNums.split(',').map(seasonNum => ({value: seasonNum, name: `Season ${seasonNum}`})) : [])
  ];
  const selSeasonOption = (values.season && seasonOptions.find(o => o.value === values.season.value)) || seasonOptions[0];
  const isAllSeasons = selSeasonOption.value === FILTER_OPTION_ALL;

  const episodeOptions = [
    {value: FILTER_OPTION_ALL, name: 'All Episodes'},
  ];
  if (!isAllSeasons) {
    const contentKey = `${selContentOption.SeriesID}--s-${selSeasonOption.value}`;
    const contentDict = dictionaries.contentMap[contentKey];
    if (contentDict && contentDict.episodes) {
      const epNums = Object.keys(contentDict.episodes).sort();
      epNums.forEach(epNum => {
        episodeOptions.push({value: epNum, name: `Episode ${epNum}`, episodeData: contentDict.episodes[epNum]});
      });
    }
  }
  const selEpisodeOption = (values.episode && episodeOptions.find(o => o.value === values.episode.value)) || episodeOptions[0];
  const isAllEpisodes = selEpisodeOption.value === FILTER_OPTION_ALL;

  const versionOptions = [
    {value: FILTER_OPTION_ALL, name: 'All Versions'},
  ];
  let contentDict;
  if (!isAllContent) {
    let versionsList;
    if (isSeasonContent) {
      if (!isAllEpisodes) {
        const {episodeData} = selEpisodeOption;
        if (episodeData) {
          versionsList = _.groupBy(episodeData, 'Version');
        }
      } else {
        const contentKey = `${selContentOption.SeriesID}--s-${selSeasonOption.value}`;
        contentDict = dictionaries.contentMap[contentKey];
        if (contentDict) {
          versionsList = contentDict.versions;
        }
      }
    } else if (!isAllContent) {
      const contentKey = `${selContentOption.SeriesID}--${selContentOption.ContentTypeID}`;
      contentDict = dictionaries.contentMap[contentKey];
      if (contentDict) {
        versionsList = contentDict.versions;
      }
    }
    if (versionsList) {
      const versionNames = Object.keys(versionsList).sort();
      versionNames.forEach(versionName => {
        versionOptions.push({value: versionName, versionData: versionsList[versionName]});
      });
    }
  }
  const selVersionOption = (values.version && versionOptions.find(o => o.value === values.version.value)) || versionOptions[0];

  const revisionOptions = [
    {value: FILTER_OPTION_ALL, name: 'All Revisions'},
  ];
  if (!isAllContent) {
    let revisionsList;
    if (selVersionOption.value !== FILTER_OPTION_ALL) {
      const {versionData} = selVersionOption;
      if (versionData) {
        revisionsList = _.uniq(versionData.map(({RevisionNumber}) => (RevisionNumber)));
      }
    } else if (contentDict) {
      revisionsList = contentDict.revisions;
    } else if (!isAllEpisodes) {
      const {episodeData} = selEpisodeOption;
      if (episodeData) {
        revisionsList = _.uniq(episodeData.map(({RevisionNumber}) => (RevisionNumber)));
      }
    }
    if (revisionsList) {
      revisionsList.sort().forEach(revisionNum => {
        revisionOptions.push({value: revisionNum, name: (revisionNum > 0 ? `Replacement ${revisionNum}` : 'Original')});
      });
    }
  }
  const selRevisionOption = (values.revision && revisionOptions.find(o => o.value === values.revision.value)) || revisionOptions[0];

  const handleSelectContent = (option) => {
    if (option && !_.isEqual(option, selContentOption)) {
      onChange('content', {content: option.SeriesID === FILTER_CONTENT_OPTION_ALL ? null : {...option}});
    }
  };

  const prepareSelectedOption = (option) => option.value === FILTER_OPTION_ALL ? null : {...option};
  const isAllowOptionToSelect = (option, prevSelOption) => !!option && option.value !== prevSelOption.value;

  const handleSelectSeason = (option) => {
    if (isAllowOptionToSelect(option, selSeasonOption)) {
      onChange('season', {content: {...selContentOption}, season: prepareSelectedOption(option)});
    }
  };

  const handleSelectEpisode = (option) => {
    if (isAllowOptionToSelect(option, selEpisodeOption)) {
      onChange('episode', {content: {...selContentOption}, season: {...selSeasonOption}, episode: prepareSelectedOption(option)});
    }
  };

  const handleSelectVersion = (option) => {
    if (isAllowOptionToSelect(option, selVersionOption)) {
      onChange('version', {...values, version: prepareSelectedOption(option), revision: null});
    }
  };

  const handleSelectRevision = (option) => {
    if (isAllowOptionToSelect(option, selRevisionOption)) {
      onChange('revision', {...values, revision: prepareSelectedOption(option)});
    }
  };

  return (
    <StyledTopFilters>
      {loading ? <Loader/> : null}
      <div className="hamburger">
        <div className="hamburger-wrapper">
          <NavigationHamburgerIcon className="navigation-hamburger-icon" />
        </div>
      </div>
      <CustomSelect
        getOptionValue={o => `${o.SeriesID}--${o.ContentTypeID}`}
        getOptionLabel={o => {
          if (o.ContentType) {
            return <>{o.SeriesName}<sub>{o.ContentType}</sub></>;
          }
          return o.SeriesName;
        }}
        options={contentOptions}
        value={selContentOption}
        onChange={handleSelectContent}
        isDropdownIndicatorDots
      />
      {isSeasonContent &&
        <>
          <CustomSelect
            getOptionValue={o => o.value}
            getOptionLabel={o => o.name}
            options={seasonOptions}
            value={selSeasonOption}
            isDisabled={isAllContent}
            onChange={handleSelectSeason}
          />
          {!isAllSeasons &&
            <CustomSelect
              getOptionValue={o => o.value}
              getOptionLabel={o => o.name}
              options={episodeOptions}
              value={selEpisodeOption}
              isDisabled={isAllSeasons}
              onChange={handleSelectEpisode}
            />
          }
        </>
      }
      {!(isAllContent || (isSeasonContent && isAllSeasons)) &&
        <>
          <CustomSelect
            getOptionValue={o => o.value}
            getOptionLabel={o => o.name || o.value || <sub className="empty">without a name</sub>}
            options={versionOptions}
            value={selVersionOption}
            isDisabled={isAllContent || (isSeasonContent && isAllSeasons)}
            onChange={handleSelectVersion}
          />
          <CustomSelect
            getOptionValue={o => o.value}
            getOptionLabel={o => o.name}
            options={revisionOptions}
            value={selRevisionOption}
            isDisabled={isAllContent || (isSeasonContent && isAllSeasons)}
            onChange={handleSelectRevision}
          />
        </>
      }
    </StyledTopFilters>
  );
};

const FILTERS_HEIGHT = '30px';

const StyledTopFilters = styled.div`
  @media (max-width: 767px) {
    display: none;
  }

  margin-bottom: 14px;
  height: ${FILTERS_HEIGHT};
  background: #f3f3f3;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${FILTERS_HEIGHT};
    padding: 6px 0 0 18px;
    background: rgba(255,255,255,0.3);
    text-align: left;
    z-index: 1;
    .react-spinner {
      width: 20px;
      height: 20px;
    }
  }

  .hamburger {
    width: 52px;
    display: inline-block;
    border-right: 1px solid rgba(0,0,0,.1);
  }

  .hamburger-wrapper {
    display: flex;
    align-items: center;
    height: ${FILTERS_HEIGHT};
    justify-content: center;

    span {
      margin-left: 5px;
      display: flex;
    }
  }

  .versions-select__control {
    border: none;
    min-height: ${FILTERS_HEIGHT};
    font-size: 10px;
    padding-left: 14px;
    box-shadow: none;
    background: inherit;
    border-radius: 0;
    cursor: pointer;
    &:not(.versions-select__control--menu-is-open) {
      :hover {
        background-color: #ececec;
      }
    }
  }

  .versions-select__indicator-separator {
    display: none;
  }

  .versions-select__dropdown-indicator {
    padding: 0;
    margin-right: 9px;
  }

  .versions-select__placeholder, .versions-select__single-value {
    color: rgba(0, 0, 0, .7);
  }

  .versions-select__single-value {
    sub {
      &.empty {
        bottom: 0;
        font-size: 100%;
        opacity: 0.75;
      }
      &:not(.empty) {
        display: none;
      }
    }
  }

  .versions-select__option {
    cursor: pointer;
    sub {
      bottom: 0;
      margin-left: 5px;
      padding: 3px;
      border-radius: 3px;
      background-color: #b1b1b1;
      color: #fff;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 0.9px;
    }
  }

  .versions-select__menu {
    border-radius: 0;
    margin-top: 0;
    font-size: 10px;
    background: #f3f3f3;
  }

  .versions-select__menu-list {
    padding-top: 0;
  }

  .versions-list {
    display: inline-block;
    width: 200px;
    border-right: 1px solid rgba(0, 0, 0, .1);
  }
`;

TopFilters.propTypes = {
  loading: PropTypes.bool,
  dictionaries: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TopFilters;