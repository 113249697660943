import moment from 'moment';
import 'moment-timezone';
import Cookies from 'js-cookie';
import config from '../../config';
import {CONTENT_TYPES, isNewQuoteProject} from '../pages/NewRequest/constants';

export const emptyFunction = () => {};

export function ensureArray(value) {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}

export function buildDateFeatured(data, formatDateFunc) {
  const start = formatDateFunc(data.start, 'DD/MM/YYYY');
  return start;
}

export const getErrorMessage = (error, errorCodeToSearch) => {
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error.responseJSON) {
    const hasErrorsArr = Array.isArray(error.responseJSON.errors) && error.responseJSON.errors.length;
    if (errorCodeToSearch && hasErrorsArr) {
      const errorByCode = error.responseJSON.errors.find(item => item.code === errorCodeToSearch);
      if (errorByCode) {
        return [errorByCode.message, error.responseJSON.message];
      }
    }
    const errorText = hasErrorsArr ? ` (${error.responseJSON.errors[0].message})` : undefined;
    return `${error.responseJSON.message}${errorText !== undefined ? errorText : ''}`;
  }

  let errorMessage;
  if (error.response && error.response.data) {
    const {response: {data, statusText}} = error;
    errorMessage = (data.errors || []).map(e => e.message).filter(Boolean).join('.  ') || data.message || statusText;
  } else if (typeof error.message === 'string') {
    errorMessage = error.message;
  }

  if (!errorMessage) {
    errorMessage = error.statusText === 'error' ? 'Something went wrong' : error.statusText;
  }

  return errorMessage;
};

export const getErrorMessageByCode = (error, code) => {
  let message, errors;
  if (error && typeof error === 'object' && error.responseJSON && Array.isArray(error.responseJSON.errors)) {
    message = error.responseJSON.message;
    errors = error.responseJSON.errors;
  } else if (error && error.response && error.response.data && Array.isArray(error.response.data.errors)) {
    message = error.response.data.message;
    errors = error.response.data.errors;
  }
  if (errors) {
    const errorByCode = errors.find(i => i.code === code);
    if (errorByCode) {
      return errors.length === 1 && (message || '').startsWith(errorByCode.message) ? message : errorByCode.message;
    }
  }
  return null;
};

export const getErrorStatus = error => {
  if (error && typeof error === 'object') {
    return error.responseJSON ? error.responseJSON.status : error.status;
  }
  return null;
};

export const formatDurationAsMinutes = runtimeInSec => {
  if (runtimeInSec) {
    if (runtimeInSec < 3600) {
      return moment.utc(runtimeInSec * 1000).format('mm[m] ss[s]');
    }
    return moment.utc(runtimeInSec * 1000).format('HH[h] mm[m] ss[s]');
  }
  return '-';
};

export const parseRuntimeInSec = runtimeInSec => {
  if (typeof runtimeInSec !== 'number') {
    return null;
  }
  const sec = runtimeInSec % 60;
  const totalMinute = Math.floor(runtimeInSec / 60);
  const min = totalMinute % 60;
  const hour = Math.floor(totalMinute / 60);
  const parts = [`${hour < 10 ? 0 : ''}${hour}:${min < 10 ? 0 : ''}${min}`, `${sec < 10 ? 0 : ''}${sec}:00`];
  const hourWithColon = hour > 0 ? `${hour < 10 ? 0 : ''}${hour}:` : '';
  const runtimeWithColon = `${hourWithColon}${min < 10 ? 0 : ''}${min}:${sec < 10 ? 0 : ''}${sec}`;
  const secondWithColon = sec > 0 ? `:${sec < 10 ? 0 : ''}${sec}` : '';
  const runtimeWithColonForMins = `${hourWithColon}${min < 10 && hourWithColon ? 0 : ''}${min}${secondWithColon}`;
  const runtime = `${hour}h ${min}m ${sec}s`.replace(/\b0{1}(h|m|s)\b/g, '').replace(/\s{2,}/g, ' ').trim();
  return {hour, min, sec, parts, full: `${parts[0]}:${parts[1]}`, runtimeWithColon, runtimeWithColonForMins, runtime};
};

const getFrameRate = frameRate => {
  let res = frameRate || 23.976;
  if (Math.trunc(res) === 23) {
    res = 23.976;
  }
  return res;
}

const getTimebaseForFrameRate = frameRate => Math.round(getFrameRate(frameRate));

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 3600;
const MINUTES_PER_HOUR = 60;

export const getSecondsFromTimeCode = (timecodeProxy, frameRate, defaultValue) => {
  let result = 0;
  if (timecodeProxy) {
    const [tcHours, tcMinutes, tcSeconds, tcFrames] = timecodeProxy.split(/[:;]/g);

    if (!tcHours && !tcMinutes && !tcSeconds && !tcFrames && defaultValue) {
      return defaultValue;
    }

    if (tcHours) {
      result += Number(tcHours) * SECONDS_PER_HOUR;
    }
    if (tcMinutes) {
      result += Number(tcMinutes) * SECONDS_PER_MINUTE;
    }
    if (tcSeconds) {
      result += Number(tcSeconds);
    }
    if (tcFrames) {
      result += Number(tcFrames / getFrameRate(frameRate));
    }
  } else if (defaultValue) {
    return defaultValue;
  }
  return isNaN(result) ? 0 : result;
};

const div = (dividend, divisor) => {
  return Math.floor(dividend / divisor);
}

export const getSecondsFromTimeCodeSmpte = (timecodeSmpte, frameRateSettings, defaultValue) => {
  const {frameRate, isDropFrame} = frameRateSettings || {};
  let result = 0;
  if (timecodeSmpte) {
    const [tcHours, tcMinutes, tcSeconds, tcFrames] = timecodeSmpte.split(/[:;]/g);

    if (!tcHours && !tcMinutes && !tcSeconds && !tcFrames && defaultValue) {
      return defaultValue;
    }
    if (tcHours) {
      result += Number(tcHours) * SECONDS_PER_HOUR;
    }
    if (tcMinutes) {
      result += Number(tcMinutes) * SECONDS_PER_MINUTE;
    }
    if (tcSeconds) {
      result += Number(tcSeconds);
    }
    const totalSeconds = result;
    let totalFrames = totalSeconds * getTimebaseForFrameRate(frameRate) + Number(tcFrames || 0);

    if (isDropFrame) {
      const dropFrames = 2; // for 29.97
      const totalMinutes = Number(tcHours || 0) * MINUTES_PER_HOUR + Number(tcMinutes || 0);
      totalFrames -= dropFrames * (totalMinutes - div(totalMinutes, 10));
    }

    result = totalFrames / getFrameRate(frameRate);
  } else if (defaultValue) {
    return defaultValue;
  }
  return isNaN(result) ? 0 : result;
};

export const getTimeCodeForVideoPlayer = (secs, frameRateSettings, startFileOffsetSeconds) => {
  const {frameRate, isDropFrame} = frameRateSettings || {};

  const secondsNumber = (secs || 0) + (startFileOffsetSeconds || 0);

  let frames = Math.round(secondsNumber * getFrameRate(frameRate));

  const timebase = getTimebaseForFrameRate(frameRate);
  const framesPerMinute = SECONDS_PER_MINUTE * timebase;
  const framesPerHour = SECONDS_PER_HOUR * timebase;

  if (isDropFrame) {
    // Algorithm adapted from: https://github.com/opencinemac/vtc-py/blob/main/vtc/_timecode_dropframe.py
    const dropFrames = 2; // for 29.97
    const framesPerMinuteDrop = framesPerMinute - dropFrames;
    const framesPer10minutesDrop = framesPerMinuteDrop * 9 + framesPerMinute;
    const tensOfMinutes = div(frames, framesPer10minutesDrop);
    let remainingFrames = frames % framesPer10minutesDrop;
    let adjustment = 9 * dropFrames * tensOfMinutes;
    if (remainingFrames < framesPerMinute) {
        // If our remaining frames are less than a whole minute, we aren't going to drop again.
    } else {
        remainingFrames -= timebase;
        adjustment += dropFrames;

        const minutesDrop = div(remainingFrames, framesPerMinuteDrop);
        adjustment += minutesDrop * dropFrames;
    }
    frames += adjustment;
  }

  let tcHours = Math.floor(frames / framesPerHour);
  frames = frames % framesPerHour;

  let tcMinutes = Math.floor(frames / framesPerMinute);
  frames = frames % framesPerMinute;

  let tcSeconds = Math.floor(frames / timebase);
  frames = frames % timebase;

  if (tcHours < 10) {tcHours = '0' + tcHours;}
  if (tcMinutes < 10) {tcMinutes = '0' + tcMinutes;}
  if (tcSeconds < 10) {tcSeconds = '0' + tcSeconds;}
  if (frames < 10) {frames = '0' + frames;}

  return `${tcHours}:${tcMinutes}:${tcSeconds}:${frames}`;
};

export const getSortedRowsBy = (rows, field, direction, altFields, altFieldsGet) => {
  if (!field) {
    return rows;
  }

  const newRows = rows.slice();
  newRows.sort((a, b) => {
    if (altFieldsGet && typeof altFieldsGet[field] === 'function') {
      a = altFieldsGet[field](a[field], a);
      b = altFieldsGet[field](b[field], b);
    } else {
      a = a[(altFields || {})[field] || field];
      b = b[(altFields || {})[field] || field];
    }

    if (direction === 'desc') {
      const tmp = a;
      a = b;
      b = tmp;
    }

    if (typeof a === 'number') {
      return a - b;
    }

    if (typeof a === 'boolean') {
      return (a === b) ? 0 : a ? -1 : 1;
    }

    return (a || '').localeCompare(b || '');
  });

  return newRows;
};

export const getAsperaConnect = (params) => {
  try {
    return new window.AW4.Connect(params);
  } catch(err) {
    console.error('Could not connect to Aspera. window.AW4 = ', window.AW4, JSON.stringify(err, null, 4));
    return null;
  }
};

export const asperaUploadFile = (dataTransferObj, asperaWeb, asperaTransferSpec, successCallback) => {
  const files = dataTransferObj.dataTransfer.files;
  if (files.length === 0) {
    return;
  }
  console.log(files.map(file => ({source: file.name})));
  const transferSpec = {
    ...asperaTransferSpec,
    paths: files.map(file => ({source: file.name})),
    authentication: 'token'
  // cookie: `u-${new Date().getTime()}`
  };
  asperaWeb.startTransfer(transferSpec, {allow_dialogs: false}, {
    error: err => {
      console.log(`Failed to start aspera transfer: ${JSON.stringify(err, null, 4)}`);
    },
    success: res => {
      console.log('Started aspera transfer: ', res);
      const resultTransferSpec = res.transfer_specs[0];
      const app_id = resultTransferSpec.aspera_connect_settings.app_id;
      successCallback(app_id, resultTransferSpec.uuid, resultTransferSpec.transfer_spec.paths);
    }
  });
};

export const asperaUploadStatus = (asperaWeb, appId, transferId, successCallback) => {
  asperaWeb.initSession(appId);
  asperaWeb.getAllTransfers({
    error: err => {
      console.log(`Failed Get All Transfers: ${JSON.stringify(err, null, 4)}`);
    },
    success: res => {
      console.log('Started Get All Transfers: ', res);
      const transferInfo = res.transfers.find(t => t.uuid === transferId);
      if (transferInfo) {
        successCallback(transferInfo.status);
      }
    }
  });
};

export const getTimezone = () => moment.tz.guess();

export const getBrowserUtcOffset = () => {
    return moment().utcOffset() * 60;
}

const formatDateFromTo = (date, fromFormat, toFormat, useTZ) => {
  if (date && date !== '' && date !== 'N/A') {
    const momentDate = fromFormat === 'x' ? moment(date, fromFormat) : moment(date, [(fromFormat || ''), moment.ISO_8601], false);
    let timezone = useTZ ? getTimezone() : null;
    let result;
    if (timezone) {
      result = momentDate.tz(timezone).format(toFormat || 'YYYY-MM-DD');
    } else {
      result = momentDate.format(toFormat || 'YYYY-MM-DD');
    }
    return toFormat === 'x' ? +result : result;
  }
  return date || '';
};

export const formatDate = (date, fromFormat, toFormat) => {
  return formatDateFromTo(date, fromFormat, toFormat, false);
};

export const formatDateTz = (date, fromFormat, toFormat) => {
  return formatDateFromTo(date, fromFormat, toFormat, true);
};

export const formatDueDate = date => formatDateTz(date, typeof date === 'number' ? 'x' : '', 'll HH:mm A z') || 'N/A';

export const getCurrentDomainObj = () => {
  if (process.env.NODE_ENV === 'production') {
    const split = window.location.host.split('.');
    return { domain: `${split[split.length - 2]}.${split[split.length - 1]}`, expires: 30 };
  }
  return {};
};

export const getCurrentPageToSave = () => {
  const {pathname, search, hash} = window.location;
  return `${pathname}${search}${hash}`;
};

export const buildRedirectUrlToLoginPage = () => {
  const curPage = getCurrentPageToSave();
  if (window.location.pathname === '/login') {
    return curPage;
  }
  const redirectParam = curPage.length > 1 ? `?r=${encodeURIComponent(curPage)}` : '';
  return `/login${redirectParam}`;
};

export const getUserRoles = () => {
  return Cookies.get('__auth') ? JSON.parse(Cookies.get('__role', getCurrentDomainObj()) || '[]') : [];
};

export const isProductionUser = () => {
  return getUserRoles().includes(config.roles.PRODUCTION);
};

export const isProductionUserWithoutDashboard = () => {
  return isProductionUser() && !getUserRoles().includes(config.roles.SHOW_DASHBOARD);
};

export const isFinishingHouseUser = () => {
  return getUserRoles().includes(config.roles.FINISHING_HOUSE);
};

export const isStudioPartnerUser = () => {
  return isProductionUser() || isFinishingHouseUser();
};

export const isOperatorUser = () => {
  return getUserRoles().includes(config.roles.OPERATOR);
};

export const isClientServiceUser = () => {
  return getUserRoles().includes(config.roles.CLIENT_SERVICE);
};

export const isOpsManagerUser = () => {
  return getUserRoles().includes(config.roles.OPS_MANAGER);
};

export const isDwManagerUser = () => {
  return getUserRoles().includes(config.roles.DW_MANAGER);
};

export const isManagerUser = () => {
  return isClientServiceUser() || isOpsManagerUser() || isDwManagerUser();
};

export const isManagerUserCanEditQcProfiles = () => {
  return isClientServiceUser() || isOpsManagerUser();
};

export const isManagerCanCreateQuote = () => {
  return isClientServiceUser();
};

export const isInternalUser = () => {
  return isManagerUser() || isOperatorUser();
};

export const isNetflixInternalUser = () => {
  return getUserRoles().includes(config.roles.NETFLIX_INTERNAL);
};

export const isStudioUser = () => {
  return getUserRoles().includes(config.roles.STUDIO);
};

export const isAdminUser = () => {
  return getUserRoles().includes(config.roles.ADMIN);
};

export const isAllowManageUsers = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS);
};

export const isAllowManageUsersPermissions = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS__PERMISSIONS);
};

export const isAllowCreateClientUsersOnly = () => {
  return getUserRoles().includes(config.roles.MANAGE_USERS__CREATE_CLIENTS_ONLY);
};

export const allowViewUserAdminPage = () => {
  return isAllowManageUsers() || isAllowManageUsersPermissions();
};

export const isArtworkStudioUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_STUDIO);
};

export const isArtworkPostPartnerUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_POST_PARTNER);
};

export const isArtworkClientUser = () => {
  return isArtworkStudioUser() || isArtworkPostPartnerUser();
};

export const isArtworkOperatorUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_OPERATOR);
};

export const isArtworkManagerUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_MANAGER);
};

export const isArtworkDeliveryUser = () => {
  return getUserRoles().includes(config.roles.ARTWORK_DELIVERY);
};

const isManagerAsStudioPartnerUser = newRequestValues => {
  if (isManagerUser() && newRequestValues && (typeof newRequestValues.cs_production_id === 'number' ||
      (typeof newRequestValues.cs_finishing_house_id === 'number' && newRequestValues.cs_finishing_house_id > 0))) {
    return true;
  }
  return false;
};

export const isStudioPartnerOrManagerAsSuchUser = newRequestValues => {
  return isStudioPartnerUser() || isManagerAsStudioPartnerUser(newRequestValues);
};

export const isAllowEditOldOperatorSchedules = () => {
  return getUserRoles().includes(config.roles.EDIT_OLD_SCHEDULES);
};

export const getHiddenWizardFields = (qcProfiles, qcProfileId) => {
  const hiddenFieldsStr = (qcProfiles.find(item => item.value === qcProfileId) || {}).hidden_wizard_fields;
  return hiddenFieldsStr ? hiddenFieldsStr.split(',').map(s => s.trim()) : [];
};

export const buildDefaultAudioFormat = hiddenFields => {
  return hiddenFields.includes('audio_configuration') ? {} : {audio_configuration: {not_sure: false, data: []}};
};

export const buildDefaultVideoFormat = (/*hiddenFields, qcType*/) => {
  const value = /*qcType.is_audio ? {audio_format: buildDefaultAudioFormat(hiddenFields)} : */{};
  return {...value, files: {}};
};

export const getTextlessOptionName = value => {
  const options = {
    0: 'Texted',
    1: 'Textless',
    2: 'Textless & Texted'
  };
  return options[value] || null;
};

export const getOptionName = (options, value, defaultText) => {
  return ((options || []).find(o => o.value === value) || {}).name || (typeof defaultText === 'string' ? defaultText : '-');
};

const isPresentTextlessVersion = value => {
  return [1/*Textless*/, 2/*Textless & Texted*/].includes(value);
};

const showRuntimeTextlessField = videoFormats => {
  return Array.isArray(videoFormats) && videoFormats.length > 1 &&
         videoFormats.some(f => isPresentTextlessVersion(f.textless)) &&
         videoFormats.some(f => !isPresentTextlessVersion(f.textless));
};

export const isRequiredRuntimeTextless = data => {
  if (!isNewQuoteProject(data)) {
    return showRuntimeTextlessField(data.video_format);
  }
  const selQcProfilesList = data.qc_profile_id || [];
  return selQcProfilesList.some(profileData => {
    return showRuntimeTextlessField(profileData.video_format);
  });
};

const QC_TYPE_COLOR = 'e75593';
const TEXTED_COLOR = '8FBC8F';
const TEXTLESS_COLOR = 'FFD700';
const TEXTLESS_AND_TEXTED_COLOR = '32CD32';

const getMixedColor = (color, colorAdmixture) => {
  if (!color) {
    return colorAdmixture;
  } else if (!colorAdmixture) {
    return color;
  }

  let mixedColorArray = '';
  const hexColorArray = color.match(/.{2}/g);
  const hexColorAdmixtureArray = colorAdmixture.match(/.{2}/g);

  for (let i = 0; i < hexColorArray.length; i++) {
    const avg = Math
      .floor(
        (parseInt(hexColorArray[i], 16) + parseInt(hexColorAdmixtureArray[i], 16)) / 2
      )
      .toString(16);
    mixedColorArray += (avg === 0 ? '00' : avg.toString());
  }
  return mixedColorArray;
};

export const getColorByOption = (combination, optionName)=> {
  if ('video' === optionName) {
    return getMixedColor(combination.ColorSpaceColor,  combination.ResolutionColor);
  } else if ('audio' === optionName) {
    return getMixedColor(combination.AudioSoundColor1,  combination.AudioSoundColor2);
  } else if ('texted' === optionName) {
    if (combination.texted === 'Textless') {
      return TEXTLESS_COLOR;
    } else if (combination.texted === 'Texted') {
      return TEXTED_COLOR;
    } else if (combination.texted === 'Textless & Texted') {
      return TEXTLESS_AND_TEXTED_COLOR;
    }
  } else if ('caption' === optionName) {
    return combination.CaptionColor;
  }
  return QC_TYPE_COLOR;
};

export const isCanceledSeason = title => {
  return (title || '').startsWith('CANCELED - ');
};

const isDisabledEditingRequestFieldByRequestStatus = (statusID, title) => {
  return [
    config.requestStatuses.CANCELLED
  ].includes(statusID) || (statusID === config.requestStatuses.NONE && isCanceledSeason(title));
};

const isDisabledEditingRequestStepByRequestStatus = (statusID, title) => {
  return isDisabledEditingRequestFieldByRequestStatus(statusID, title) || [
    config.requestStatuses.CLOSED,
    config.requestStatuses.NEEDS_FIXES
  ].includes(statusID);
};

const MemfisStatus = {
  CANCELLED: 'Cancelled',
  COMPLETE: 'Complete'
};

const isDisabledEditingRequestFieldByWoMemfisStatus = status => {
  return [
    MemfisStatus.CANCELLED.toLowerCase()
  ].includes((status || '').toLowerCase());
};

const isDisabledEditingRequestStepByWoMemfisStatus = status => {
  return isDisabledEditingRequestFieldByWoMemfisStatus(status) || [
    MemfisStatus.COMPLETE.toLowerCase()
  ].includes((status || '').toLowerCase());
};

export const isDisabledEditingRequestField = ({RequestStatusID, MemfisStatus, Title}) => {
  return isDisabledEditingRequestFieldByRequestStatus(RequestStatusID, Title) ||
         isDisabledEditingRequestFieldByWoMemfisStatus(MemfisStatus);
};

export const isDisabledEditingRequestStep = ({RequestStatusID, MemfisStatus, Title}) => {
  return isDisabledEditingRequestStepByRequestStatus(RequestStatusID, Title) ||
         isDisabledEditingRequestStepByWoMemfisStatus(MemfisStatus);
};

export const isNeedsFixesRequest = ({RequestStatusID}) => {
  return RequestStatusID === config.requestStatuses.NEEDS_FIXES;
};

export const isPassedRequest = ({QcStatusID}) => {
  return QcStatusID === config.qcStatus.QC_PASS;
};

const isAllowedUserPermission = (userPermissionType, expectedPermissionType) => {
  return !!userPermissionType && userPermissionType >= expectedPermissionType;
};

export const isUserCanComment = userPermissionType => {
  return isAllowedUserPermission(userPermissionType, config.requestUserPermission.CAN_COMMENT);
};

export const isUserCanManage = userPermissionType => {
  return isAllowedUserPermission(userPermissionType, config.requestUserPermission.CAN_MANAGE);
};

export const formatSeasonNameForTitle = (seasonNum) => {
  if (typeof seasonNum === 'number' || (typeof seasonNum === 'string' && seasonNum.match(/^\d+$/))) {
    return ` S${String(seasonNum).padStart(2, '0')}`;
  }
  return ` S_${seasonNum}`;
};

export const formatEpisodeNumber = (episodeNum) => {
  if (typeof episodeNum === 'number' || (typeof episodeNum === 'string' && episodeNum.match(/^\d+$/))) {
    return `Ep${String(episodeNum).padStart(2, '0')}`;
  }
  return `Ep_${episodeNum}`;
};

export const getDefaultRedirectUrl = () => {
  if (isArtworkClientUser()) {
    return '/dashboard';
  }
  return (isInternalUser() || isProductionUserWithoutDashboard()) ? '/requests' : '/';
};

export const buildAssetDetailsLink = assetId => {
  return `/assets/${assetId}`;
};

export const buildReportLink = ({ContentTypeID, RequestID, QcProjectID}) => {
  if (ContentTypeID === 5/*artwork content type id*/) {
    return buildAssetDetailsLink(RequestID);
  }
  if (ContentTypeID === CONTENT_TYPES.Season) {
    return `/requests/seasons/${QcProjectID}`;
  }
  return `/requests/${RequestID}`;
};

export const isInvalidPasswordWithMessage = (password, email) => {
  if (!password) {
    return "Password cannot be blank";
  }

  if (password.length < config.passwordMinLength) {
    return "Password length cannot be less than " + config.passwordMinLength;
  }

  if (["admin", "client", "qwerty"].includes(password.toLowerCase())) {
    return "Password cannot be too simple";
  }

  if (!password.match(/^[A-Za-z0-9!@#$%&*]+$/)
      || !password.match(/^.*[a-z]+.*$/)
      || !password.match(/^.*[A-Z]+.*$/)
      || !password.match(/^.*[0-9]+.*$/)
      || !password.match(/^.*[!@#$%&*]+.*$/)
  ) {
    return "Password must include ALL of the following: lowercase, uppercase, numerals, and special characters !@#$%&*";
  }

  if (email && password.toLowerCase().indexOf(email.split('@')[0].toLowerCase()) !== -1) {
    return 'Password cannot contain or match username (email)';
  }

  return null;
};

export const isInvalidPassword = (password, email) => {
  return !!isInvalidPasswordWithMessage(password, email);
};

export const OfficeFlagOptions = [
  {name: 'Burbank', value: config.officeFlag.BURBANK},
  {name: 'New York', value: config.officeFlag.NEW_YORK}
];

export const isSelectedOffice = officeFlag => [config.officeFlag.BURBANK, config.officeFlag.NEW_YORK].includes(officeFlag);

export const isQcStatusReview = qcStatusId => qcStatusId === config.qcStatus.REVIEW;

export const isValidFileName = name => {
  return /^(?=[\S])[^\\/:*?"<>|]+$/.test(name);
};

export const USA_COUNTRY_ID = 1;

export const PHONE_PLACEHOLDER = '+1(XXX)XXX-XXXX or (XXX)XXX-XXXX';

export const ZIP_CODE_PLACEHOLDER = 'XXXXX-XXXX or XXXXX';

export const isValidZipCode = t => {
  return /^([0-9]{5}([-][0-9]{4})?)$/.test(t);
};

export const isValidEmail = t => {
  return /^[A-z0-9._%+-]+@[A-z0-9.-]+[.][A-z]{2,8}$/.test(t);
};

export const isValidEmailsList = t => {
  const arr = (t || '').split(/(,|;|\n|\r)/g).map(s => s.trim()).filter(s => !['', ',', ';'].includes(s));
  return arr.length > 0 && arr.every(isValidEmail);
};

export const isValidIPAddress = t => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(t);
};

export const isValidPhoneNumber = t => {
  return /^([+]1)?[(][0-9]{3}[)][0-9]{3}-[0-9]{4}$/.test(t);
};

export const preparePhoneNumber = value => {
  let preparedValue = '';
  const cleanedValue = (value || '').replace(/[^0-9]/g, '');
  if (cleanedValue.length === 10) {
    preparedValue = `(${cleanedValue.substr(0,3)})${cleanedValue.substr(3,3)}-${cleanedValue.substr(6)}`;
  } else {
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i === 0) {
        preparedValue += '+';
      }
      preparedValue += cleanedValue[i];
      if (i === 0) {
        preparedValue += '(';
      } else if (i === 3) {
        preparedValue += ')';
      } else if (i === 6) {
        preparedValue += '-';
      }
    }
  }
  return preparedValue;
};

export const prepareOptions = (options, value) => {
  if (!options) {
    options = [];
  }
  if (value && !options.find(o => o.value === value)) {
    options.push({value, name: value});
  }
  return options;
};
