import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import sortUpImg from '../../../../assets/images/issueTable/sort-up.svg';
import sortDownImg from '../../../../assets/images/issueTable/sort-down.svg';
import sortUpActiveImg from '../../../../assets/images/issueTable/sort-up-active.svg';
import sortDownActiveImg from '../../../../assets/images/issueTable/sort-down-active.svg';

export const HeaderCell = ({headers, field, sort = {}, onClick = () => {}}) => {
  const sorted = sort.field === field;
  let nextField = field;
  let nextDirection = sorted ? (sort.direction === 'asc' ? 'desc' : 'asc') : 'asc';

  if (sort.direction === 'desc' && sorted) {
    nextField = undefined;
    nextDirection = undefined;
  }

  const sortEl = sort.direction === 'asc' ?
    <SortArrowStyled className="sort-up"/> :
    <SortArrowStyled className="sort-down"/>;

  const hint = sorted ? 'Click to Sort' : typeof headers[field].hint === 'string' ? headers[field].hint :
    typeof headers[field].title === 'string' ? headers[field].title : field;
  return (
    <th onClick={() => onClick(nextField, nextDirection)} className={headers[field].className} title={hint}>
      {headers[field].title} {sorted && sortEl}{!sorted && headers[field].sortPlaceholder}
    </th>
  );
};

const SortArrowStyled = styled.span`
  display: inline-block;
  height: 10px;
  width: 10px;
  &.sort-down {
    background-image: url(${sortUpImg}), url(${sortDownActiveImg});
  }
  &.sort-up {
    background-image: url(${sortUpActiveImg}), url(${sortDownImg});
  }
  background-position: right top, right bottom;
  background-repeat: no-repeat, no-repeat;
`;

HeaderCell.propTypes = {
  headers: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  sort: PropTypes.object,
  onClick: PropTypes.func
};
