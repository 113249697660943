import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import {
  StyledModalBackdrop, StyledModalDialog, StyledModal, StyledMobileModal
} from '../../../../styled/ModalStyled';
import styled from 'styled-components';

class MobileFiltersModal extends Component {
  render() {
    const {onClose, children} = this.props;
    return (
      <StyledMobileModal>
        <StyledModalBackdrop onClick={onClose}/>
        <StyledFiltersModalDialog className="mobile-modal-dialog-custom">
          <StyledModal>
            <div className="modal-header-custom">
              <div className="back-btn" onClick={onClose}/>
              <h4>Filters</h4>
            </div>
            <StyledModalBody>
              {children}
            </StyledModalBody>
          </StyledModal>
        </StyledFiltersModalDialog>
      </StyledMobileModal>
    );
  }
}

const StyledFiltersModalDialog = styled(StyledModalDialog)`
  left: 20% !important;
`;

const StyledModalBody = styled.div`
  max-height: calc(100% - 70px);
  overflow-x: auto;
  padding: 0 20px;
  > div {
    margin: 0;
    padding-bottom: 60px;
  }
  .add-another-btn {
    background: #4E4E4E;
    bottom: 0;
    color: #fff;
    height: 60px;
    left: 20%;
    padding: 0;
    position: fixed;
    text-transform: uppercase;
    width: calc(100% - 20%);
  }
`;

MobileFiltersModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default MobileFiltersModal;
