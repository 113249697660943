import styled from 'styled-components';
import ArrowDownIcon from '../../../../assets/images/issueTable/arrow-down.svg';
import ArrowUpIcon from '../../../../assets/images/issueTable/arrow-up.svg';

const LOW_COLOR_BORDER = '#E6E6E6';

const IssueTableStyled = styled.div`
  max-width: 100%;
  overflow-x: auto;

  .table {
    border-collapse: separate;
    color: #282828;

    &.withoutSort > thead > tr > th {
      cursor: default;
    }

    > thead > tr > th {
      border-width: 1px;
      cursor: pointer;
      font-size: 10px;
      min-width: 40px;
      max-width: 350px;
      text-align: left;
      padding-right: 12px;
      padding-left: 12px;

      &.arrow-cell {
        cursor: default;
        width: 10px;
      }
      &.actions {
        cursor: default;
      }
      &.location {
        text-align: center;
      }

      &:first-child {
        border-left: ${props => props.issueBorderWidth || '4px'} solid transparent;

        @media (max-width: 767px) {
          .mobile-modal-with-issue-table & {
            border-left-width: 4px;
          }
        }
      }
    }

    > tbody {
      &:before {
        content: "-";
        display: block;
        line-height: 5px;
        color: transparent;

        @media (max-width: 767px) {
          .mobile-modal-with-issue-table & {
            line-height: 0;
          }
        }
      }

      > tr {
        background-color: transparent;
        cursor: pointer;
        &:focus .arrow, &:hover .arrow {
          opacity: 1;
        }

        &.tr-separate {
          cursor: auto;
          > th {
            border: none;
            padding: 2px;
          }
        }

        > td {
          border: none;
          border-bottom: 1px solid #ddd;
          font-size: 10px;
          padding: 12px;
          vertical-align: middle;
          &:first-child {
            border-left: ${props => props.issueBorderWidth || '4px'} solid ${LOW_COLOR_BORDER};

            @media (max-width: 767px) {
              .mobile-modal-with-issue-table & {
                border-left-width: 4px;
              }
            }
          }

          &.location {
            text-align: center;
          }
          &.description {
            color: #4c4c4c;
          }

          &.data-table-empty {
            text-align: center;
            width: 100% !important;
            border-left-color: transparent;
          }

          &.capitalized-all {
            text-transform: uppercase;
          }
        }

        &.tr-collapsed > td {
          cursor: auto;
          border: none;
          border-left: ${props => props.issueBorderWidth || '4px'} solid transparent;
          padding: 0;

          @media (max-width: 767px) {
            .mobile-modal-with-issue-table & {
              border-left-width: 4px;
            }
          }
        }

        &.issue-deleted > td:not(.empty--cell) {
          &, > * {
            text-decoration: line-through;
          }
        }

        &.type-hight {
          background-color: #e8c0c0;
        }

        .arrow {
          background: url(${ArrowDownIcon}) no-repeat;
          display: inline-block;
          height: 10px;
          opacity: 0.2;
          padding: 0;
          vertical-align: middle;
          width: 10px;
        }
        &.open .arrow {
          background: url(${ArrowUpIcon}) no-repeat;
          opacity: 1;
        }
      }
    }
  }

  .fix-list-modal & .table {
    > thead > tr > th {
      padding-top: 7px;
      padding-bottom: 3px;

      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(7) {
        min-width: 108px;
      }
      &:nth-child(8) {
        min-width: 77px;
      }
    }
  }

  .submit-replacement-modal & .table {
    > thead > tr > th {
      padding-top: 8px;
      padding-bottom: 3px;

      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        min-width: 108px;
      }
    }
  }
`;

export default IssueTableStyled;
