export const EDITABLE_WEEKS_LIMIT = 4;

const getScheduleByWeek = (schedules, week) => {
  return (schedules[week] || []).filter(s => !s.isDeleted);
};

export const getScheduleByWeekAsStr = (schedules, week) => {
  return JSON.stringify(getScheduleByWeek(schedules, week));
};

export const getScheduleByWeekAsCopy = (schedules, week) => {
  return JSON.parse(getScheduleByWeekAsStr(schedules, week));
};
