import React, {Component} from 'react';
import styled from 'styled-components';
import warningImg from '../../../../assets/images/warning.svg';
import PropTypes from 'prop-types';
import config from '../../../../config';

class ResultMessage extends Component {
  render() {
    const {requestStatus, qcStatus, qcStatusId, isChangesPending} = this.props;
    let reportStatusName = 'default';
    if (qcStatusId === config.qcStatus.QC_FAILED) {
      reportStatusName = 'failed';
    } else if (qcStatusId === config.qcStatus.QC_PASS) {
      reportStatusName = 'pass';
    } else if (qcStatusId === config.qcStatus.REVIEW) {
      reportStatusName = 'review';
    }
    const isFailedQc = (config.qcStatus.QC_FAILED === qcStatusId);
    return (
      <StyledMessage className={reportStatusName}>
        <button title='Report Status'>{requestStatus}</button>
        {isFailedQc ? <img src={warningImg} alt=""/> : null}
        <strong>{qcStatus ? `${qcStatus}${isChangesPending ? ' / Pending' : ''}` : ''}</strong>
      </StyledMessage>
    );
  }
}

const StyledMessage = styled.div`
  color: #ffffff;
  display: flex;
  height: 28px;
  font-size: 11px;
  line-height: 29px;
  margin: 0 -15px;
  padding: 0 15px;
  &.failed {
    background-color: #ff3d00;
  }
  &.pass {
    background-color: #4eb947;
  }
  &.review {
    background-color: #ffbf03;
  }
  &.default {
    background-color: #e5e5e5;

    button {
      background-color: #4b4b4b;
      color: #fff;
    }

    strong {
      color: #282828;
    }
  }
  button {
    background-color: #ffffff;
    border: 0;
    border-radius: 3px;
    color: #5e5e5e;
    font-size: 10px;
    line-height: 20px;
    margin: 2px 13px 2px 10px;
    height: 24px;
    width: auto;
    padding-right: 12px;
    padding-left: 11px;
  }
  img {
    vertical-align: middle;
    margin-right: 2px;
    max-width: 18px;
  }
  strong {
    font-size: 13px;
    margin-right: 3px;
  }

  @media (max-width: 768px) {
    button {
      margin-left: 0;
    }
    &.pass {
      align-items: baseline;
      height: auto;
      display: flex;
      line-height: 19px;
      padding: 5px 20px
    }
  }
`;

ResultMessage.propTypes = {
  requestStatus: PropTypes.string,
  qcStatus: PropTypes.string,
  qcStatusId: PropTypes.number
};

export default ResultMessage;
