import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage, isArtworkClientUser} from '../../../utils/general';
import {showError, showSuccess, showInfo, toggleShowSidebar} from '../../../layouts/actions';
import {QC_RESULT__PASS} from '../Assets/utils';
import {
  ARTWORK_ALST_UPDATE_DETAIL,
  ARTWORK_ALST_ADD_DETAIL_ISSUE,
  ARTWORK_ALST_DELETE_DETAIL_ISSUE,
  setOpenedAssetId,
} from '../Assets/actions';

export const ARTWORK_ADTL_CLEAR = 'ARTWORK_ADTL_CLEAR';
export const ARTWORK_ADTL_TOGGLE_OPERATOR_MODE = 'ARTWORK_ADTL_TOGGLE_OPERATOR_MODE';
export const ARTWORK_ADTL_TOGGLE_DARK_MODE = 'ARTWORK_ADTL_TOGGLE_DARK_MODE';
export const ARTWORK_ADTL_SET_LOADING = 'ARTWORK_ADTL_SET_LOADING';
export const ARTWORK_ADTL_SET_DETAIL = 'ARTWORK_ADTL_SET_DETAIL';
export const ARTWORK_ADTL_UPDATE_DETAIL = 'ARTWORK_ADTL_UPDATE_DETAIL';
export const ARTWORK_ADTL_UPDATE_DETAIL_ISSUE = 'ARTWORK_ADTL_UPDATE_DETAIL_ISSUE';
export const ARTWORK_ADTL_ADD_DETAIL_ISSUE = 'ARTWORK_ADTL_ADD_DETAIL_ISSUE';
export const ARTWORK_ADTL_DELETE_DETAIL_ISSUE = 'ARTWORK_ADTL_DELETE_DETAIL_ISSUE';
export const ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL = 'ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL';
export const ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL = 'ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL';
export const ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL = 'ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL';
export const ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL = 'ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL';
export const ARTWORK_ADTL_CHANGE_VIDEO_PARAMS = 'ARTWORK_ADTL_CHANGE_VIDEO_PARAMS';
export const ARTWORK_ADTL_TOGGLE_COMMENT_TO_VIEW = 'ARTWORK_ADTL_TOGGLE_COMMENT_TO_VIEW';

export const clearAssetDetails = () => ({type: ARTWORK_ADTL_CLEAR});

export const toggleOperatorMode = (modeIsOn) => ({type: ARTWORK_ADTL_TOGGLE_OPERATOR_MODE, modeIsOn});

export const toggleDarkMode = (modeIsOn) => ({type: ARTWORK_ADTL_TOGGLE_DARK_MODE, modeIsOn});

export const toggleCommentToView = (commentId) => ({type: ARTWORK_ADTL_TOGGLE_COMMENT_TO_VIEW, commentId});

export const getReportDetail = (report, onlyThumbnail = false) => (dispatch, getState) => {
  const {asset_id} = report;
  const {openedAssetId} = getState().artworkAssets;
  if (openedAssetId) {
    dispatch({type: ARTWORK_ADTL_SET_DETAIL, data: {...report}});
    dispatch(setOpenedAssetId(asset_id, report.project_id));
  }
  const loadingName = 'getReportDetail';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${asset_id}`,
    data: {
      only_thumbnail: onlyThumbnail
    }
  })
  .done(res => {
    const {data, filters} = res;
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_SET_DETAIL, data, filters});
    if (data && data.is_assigned && !data.is_viewed) {
      dispatch(markAssetAsViewed(asset_id));
      if (data.qc_instructions) {
        dispatch(showQcInstructions(data));
      }
    }
  })
  .fail(error => {
    if (openedAssetId) {
      dispatch(clearAssetDetails());
      dispatch(setOpenedAssetId(null));
      dispatch(toggleShowSidebar(false));
    }
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});

    if (isArtworkClientUser()) {
      dispatch(showError('You don\'t have permissions to this asset.'));
    } else {
      dispatch(showError(`Could not get report detail #${asset_id} data.  ${getErrorMessage(error)}`));
    }
  });
};

export const getReportComments = (assetId) => (dispatch, getState) => {
  const loadingName = 'getReportComments';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/comments`,
    data: {
      mark_as_read: true
    }
  })
  .done(res => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {has_new_comment: false, comments: res}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {has_new_comment: false, comments: res}});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get report comments.  ${getErrorMessage(error)}`));
  });
};

const loadAssetMetadata = asset => (dispatch, getState) => {
  const {asset_id: assetId} = asset;
  const loadingName = 'loadingAssetMetadata';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/metadata`
  })
  .done(res => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {metadata: res}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {metadata: res}});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get asset metadata.  ${getErrorMessage(error)}`));
  });
};

export const showAssetMetadata = asset => dispatch => {
  const showPopup = asset => ({type: ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL, isShow: true, asset});
  const hasMetadata = metadata => metadata && Object.keys(metadata).length;
  if (hasMetadata(asset.metadata)) {
    dispatch(showPopup(asset));
  } else {
    dispatch(loadAssetMetadata(asset))
      .then(
        metadata => {
          if (hasMetadata(metadata)) {
            dispatch(showPopup({...asset, metadata}));
          } else {
            dispatch(showInfo('Metadata not found'));
          }
        }
      );
  }
};

export const hideAssetMetadata = () => ({type: ARTWORK_ADTL_TOGGLE_ASSET_METADATA_MODAL, isShow: false});

export const refreshAssetSpec = asset => dispatch => {
  const loadingName = 'RefreshAssetSpec';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/mam/refresh_spec?request_id=${asset.asset_id}`,
  })
  .done(() => {
    window.location.reload();
  })
  .always(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
  });
};

export const resetAssetSpec = asset => (dispatch, getState) => {
  const loadingName = 'onResetAssetSpec';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  const {asset_id: assetId} = asset;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/reset_spec`,
  })
  .done(res => {
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: res});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: res});
    }
    //window.location.reload();
  })
  .always(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
  });
};

export const redeliverAsset = asset => (dispatch, getState) => {
  const loadingName = 'onRedeliverAsset';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  const {asset_id: assetId} = asset;
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/redeliver`,
  })
  .done(res => {
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: res});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: res});
    }
  })
  .fail(error => {
    dispatch(showError(getErrorMessage(error)));
  })
  .always(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
  });
};

export const showQcInstructions = asset => ({type: ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL, isShow: true, asset});

export const hideQcInstructions = () => ({type: ARTWORK_ADTL_TOGGLE_QC_INSTRUCTIONS_MODAL, isShow: false});

export const showSpecDocuments = asset => (dispatch, getState) => {
  const showPopup = asset => ({type: ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL, isShow: true, asset});
  if (asset.spec_docs && asset.spec_docs.every(d => d.SpecDocFileUrl)) {
    dispatch(showPopup(asset));
  } else {
    const {asset_id: assetId} = asset;
    const loadingName = 'loadingSpecDocuments';
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
    $.ajax({
      method: 'GET',
      url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/spec_docs`
    })
    .done(({spec_docs}) => {
      dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
      dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {spec_docs}});
      if (getState().artworkAssets.openedAssetId === assetId) {
        dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {spec_docs}});
      }
      if (spec_docs && spec_docs.length) {
        dispatch(showPopup({...asset, spec_docs}));
      } else {
        dispatch(showInfo('We had trouble getting spec documents.'));
      }
    })
    .fail(error => {
      dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
      dispatch(showError(`Could not get asset spec documents.  ${getErrorMessage(error)}`));
    });
  }
};

export const hideSpecDocuments = () => ({type: ARTWORK_ADTL_TOGGLE_SPEC_DOCS_MODAL, isShow: false});

const markAssetAsViewed = assetId => (dispatch, getState) => {
  const loadingName = 'MarkAssetAsViewed';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/viewed`
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {is_viewed: true}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {is_viewed: true}});
    }
  })
  .always(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
  });
};

export const toggleIsOverrideIssue = (assetId, issue) => (dispatch, getState) => {
  const loadingName = 'toggleIsOverrideIssue';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  const isOverride = !issue.is_override;
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/issues/${issue.issue_id}/override`,
    data: {
      is_override: isOverride
    }
  })
  .done(res => {
    const {qc_status_name} = res;
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, is_override: isOverride}});
    if (qc_status_name) {
      dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {qc_status_name}});
      if (getState().artworkAssets.openedAssetId === assetId) {
        dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {qc_status_name}});
      }
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not override issue.  ${getErrorMessage(error)}`));
  });
};

export const addCommentToIssue = (assetId, issue, comment, parentCommentId) => dispatch => {
  const loadingName = 'addCommentToIssue';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/comment`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({comment, parent_comment_id: parentCommentId || undefined})
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, comment_text: comment.map(({text}) => text).join('')}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add a comment.  ${getErrorMessage(error)}`));
  });
};

export const deleteIssueComment = (assetId, issue) => dispatch => {
  const loadingName = 'DeleteIssueComment';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/comment`,
    method: 'DELETE'
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, comment_text: null}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete a comment.  ${getErrorMessage(error)}`));
  });
};

export const addCommentToAsset = (assetId, comment, parentCommentId) => dispatch => {
  const loadingName = 'addCommentToAsset';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: 'onUpdateAssetComment'});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/comment`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({comment, parent_comment_id: parentCommentId || undefined})
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(getReportComments(assetId));
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add a comment.  ${getErrorMessage(error)}`));
  });
};

export const updateAssetComment = (asset, commentId, comment, commentText) => (dispatch, getState) => {
  const {asset_id: assetId} = asset;
  const loadingName = 'onUpdateAssetComment';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  const comments = [...asset.comments];
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/comment/${commentId}`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({comment})
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess('Comment has been successfully updated.'));
    const commentItem = comments.find(c => c.comment_id === commentId);
    commentItem.comment_text = commentText;
    commentItem.comment_json = comment;
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {comments}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {comments}});
    }
    dispatch(getReportComments(assetId));
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update a comment.  ${getErrorMessage(error)}`));
  });
};

export const deleteAssetComment = (assetId, commentId) => dispatch => {
  const loadingName = 'onDeleteAssetComment';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: 'onUpdateAssetComment'});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/comment/${commentId}`,
    method: 'DELETE'
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess('Comment has been successfully deleted.'));
    dispatch(getReportComments(assetId));
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete a comment.  ${getErrorMessage(error)}`));
  });
};

export const getMentions = (assetId, commentType) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/mention`,
      data: {comment_type: commentType}
    })
      .done(res => {
        resolve(res);
      })
      .fail(error => {
        reject(error);
      });
  });
};

export const addCustomIssue = (assetId, qcResultId, parameterLabel, callback) => (dispatch, getState) => {
  const loadingName = 'addCustomIssue';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  const issue = {
    param_label: parameterLabel,
    param_value: 'Custom',
    is_custom_issue: true,
    qc_result_id: qcResultId,
    is_override: qcResultId === QC_RESULT__PASS ? null : false,
  };
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/issue`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(issue)
  })
  .done(res => {
    callback && callback(res.issue_id);
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_ADD_DETAIL_ISSUE, assetId, issue: {...issue, ...res}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_ADD_DETAIL_ISSUE, assetId, issue: {...issue, ...res}});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not add custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const deleteCustomIssue = (assetId, issueId, callback) => (dispatch, getState) => {
  const loadingName = 'deleteCustomIssue';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issueId}`,
    method: 'DELETE'
  })
  .done(() => {
    callback && callback();
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_DELETE_DETAIL_ISSUE, assetId, issueId});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_DELETE_DETAIL_ISSUE, assetId, issueId});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not delete custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const updateCustomIssue = (assetId, issue) => dispatch => {
  const loadingName = 'updateCustomIssue';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(issue)
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL_ISSUE, assetId, issue});
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update custom parameter.  ${getErrorMessage(error)}`));
  });
};

export const updateIssueTimecode = (assetId, issue, timecode) => dispatch => {
  const loadingName = 'updateIssueTimecode';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/issues/${issue.issue_id}/timecode`,
    method: 'POST',
    data: timecode
  })
  .done(res => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL_ISSUE, assetId, issue: {...issue, ...res}});
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update time codes.  ${getErrorMessage(error)}`));
  });
};

export const changeAssetQcStatus = (assetId, qcStatusName) => (dispatch, getState) => {
  const loadingName = 'changeAssetQcStatus';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/qc_status`,
    method: 'PUT',
    data: {
      qc_status_name: qcStatusName
    }
  })
  .done(() => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: {qc_status_name: qcStatusName}});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: {qc_status_name: qcStatusName}});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not update status.  ${getErrorMessage(error)}`));
  });
};

export const runAutoQC = assetId => (dispatch, getState) => {
  const loadingName = 'runningAutoQC';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/auto_qc`,
    method: 'POST'
  })
  .done(res => {
    const {qc_status_name, issues} = res;
    let actionData = {issues};
    if (qc_status_name) {
      actionData.qc_status_name = qc_status_name;
    }
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: ARTWORK_ADTL_UPDATE_DETAIL, assetId, data: actionData});
    if (getState().artworkAssets.openedAssetId === assetId) {
      dispatch({type: ARTWORK_ALST_UPDATE_DETAIL, assetId, data: actionData});
    }
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not run auto QC.  ${getErrorMessage(error)}`));
  });
};

export const showUploadNewVersionModal = () => ({type: ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL, isShow: true});
export const closeUploadNewVersionModal = () => ({type: ARTWORK_ADTL_TOGGLE_UPLOAD_NEW_VERSION_MODAL, isShow: false});

export const uploadNewVersion = (history, assetId, isCreateWorkOrder) => dispatch => {
  const loadingName = 'uploadNewVersion';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/assets/${assetId}/new_version`,
    method: 'POST',
    data: {
      is_create_work_order: isCreateWorkOrder
    }
  })
  .done(replacementAssetId => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(getReportDetail({asset_id: replacementAssetId}, true));
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not upload a new version.  ${getErrorMessage(error)}`));
  });
};

export const changeVideoParams = data => dispatch => {
  dispatch({type: ARTWORK_ADTL_CHANGE_VIDEO_PARAMS, data});
};

export const getProxy = () => (dispatch, getState) => {
  const assetId = (getState().artworkAssetDetails.reportDetail || {}).asset_id;
  if (!assetId) {
    return;
  }
  const loadingName = 'getProxy';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${assetId}/secure_proxy`
  })
  .done(res => {
    const {reportDetail} = getState().artworkAssetDetails;
    if (+assetId === (reportDetail || {}).asset_id) {
      const data = {...res};
      const {isPendingRequestProxy} = reportDetail;
      if (isPendingRequestProxy) {
        if (data.request_proxy_status === 'none') {
          data.request_proxy_status = 'pending';
        } else {
          data.isPendingRequestProxy = undefined;
        }
      }
      dispatch({type: ARTWORK_ADTL_SET_DETAIL, data: {...reportDetail, ...data, asset_file_url: data.request_proxy}});
    }
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not get a secure proxy info.`));
  });
};

export const generateProxy = assetId => (dispatch, getState) => {
  const loadingName = 'generateProxy';
  dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/requests/${assetId}/secure_proxy`
  })
  .done(() => {
    const {reportDetail} = getState().artworkAssetDetails;
    if (+assetId === (reportDetail || {}).asset_id) {
      dispatch({type: ARTWORK_ADTL_SET_DETAIL, data: {...reportDetail, request_proxy_status: 'pending', isPendingRequestProxy: true}});
    }
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showSuccess('Your watermarked proxy video is being prepared.'));
  })
  .fail(error => {
    dispatch({type: ARTWORK_ADTL_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not generate a secure proxy. Please contact qodsupport@us.resillion.com for assistance`));
  });
};
