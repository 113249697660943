import {
  USER_SET_DATA, USER_HIDE_LOGIN_MESSAGE, USER_SAVE_PREVIOUS_PAGE, USER_SET_IS_LOGIN, USER_SET_IS_LOGOUT,
  USER_SET_WELCOME, USER_SHOW_LOGIN_ERROR_MESSAGE, USER_SHOW_LOGIN_MESSAGE, USER_SET_DATA_FIELD,
  USER_SET_2FA, USER_RESET_2FA, USER_SHOW_LOADING, USER_HIDE_LOADING
} from './actions';

const initialState = {
  isLogin: false,
  role: '',
  name: '',
  avatar: '',
  phoneNumber: '',
  studio: '',
  companies: null,
  loginMessage: '',
  errorLoginMessage: '',
  isShowResendBtnToCreatePwd: false,
  isShowResendBtnToResetPwd: false,
  previousPage: '',
  showWelcomeMessage: false,
  counterOfTriesToEnterCodeTOTP: 0,
  showLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SHOW_LOGIN_MESSAGE: {
      return {...state, loginMessage: action.message};
    }

    case USER_SHOW_LOGIN_ERROR_MESSAGE: {
      return {
        ...state,
        loginErrorMessage: action.message,
        isShowResendBtnToCreatePwd: action.showResendBtnToCreatePwd || initialState.isShowResendBtnToCreatePwd,
        isShowResendBtnToResetPwd: action.showResendBtnToResetPwd || initialState.isShowResendBtnToResetPwd
      };
    }

    case USER_HIDE_LOGIN_MESSAGE: {
      return {
        ...state,
        loginMessage: '',
        loginErrorMessage: '',
        isShowResendBtnToCreatePwd: initialState.isShowResendBtnToCreatePwd,
        isShowResendBtnToResetPwd: initialState.isShowResendBtnToResetPwd
      };
    }

    case USER_SET_DATA: {
      return {
        ...state,
        name: action.name,
        avatar: action.avatar,
        phoneNumber: action.phoneNumber,
        studio: action.studio,
        email: action.email || state.email,
        role: action.role,
        companies: action.companies
      };
    }

    case USER_SET_DATA_FIELD: {
      return {...state, [action.name]: action.value};
    }

    case USER_SET_WELCOME: {
      return {...state, showWelcomeMessage: action.showWelcomeMessage};
    }

    case USER_SAVE_PREVIOUS_PAGE: {
      return {...state, previousPage: action.previousPage};
    }

    case USER_SET_IS_LOGIN: {
      return {...state, isLogin: true};
    }

    case USER_SET_IS_LOGOUT: {
      return {...state, isLogin: false};
    }

    case USER_SET_2FA: {
      return {
        ...state,
        counterOfTriesToEnterCodeTOTP: action.attempts
      };
    }

    case USER_RESET_2FA: {
      return {
        ...state,
        counterOfTriesToEnterCodeTOTP: initialState.counterOfTriesToEnterCodeTOTP
      };
    }

    case USER_SHOW_LOADING: {
      return {...state, showLoading: true};
    }

    case USER_HIDE_LOADING: {
      return {...state, showLoading: false};
    }

    default: {
      return state;
    }
  }
}
