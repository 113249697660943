import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getSortedRowsBy} from '../../../../utils/general';
import {HeaderRow} from '../../../../components/table/HeaderRow';
import {Row} from '../../../../components/table/Row';
import RowWithoutRequest from './RowWithoutRequest';
import DateCell from '../../../../components/table/cells/DateCell';
import TitleCell from './TitleCell';
import StatusCell from './StatusCell';
import CostCell from './CostCell';
import isMobile from 'is-mobile';

class Table extends Component {
  headers = {
    Title: {
      title: 'Episode # / Title',
      className: 'episode-title',
      component: TitleCell
    },
    RequestFormat: {
      title: 'Format',
      className: 'format'
    },
    LastModified: {
      title: 'Last Update',
      className: 'last-update',
      component: DateCell,
      componentArgs: {
        toFormat: isMobile() ? 'MMMM D, YYYY' : 'MMMM D, YYYY HH:mm A'
      }
    },
    RequestStatus: {
      component: StatusCell,
      className: 'status',
      title: 'Request Status'
    },
    DueDate: {
      title: 'Due Date',
      className: 'due-date',
      component: DateCell,
      componentArgs: {
        toFormat: isMobile() ? 'MMMM D, YYYY' : 'MMMM D, YYYY HH:mm A'
      }
    },
    Cost: {
      title: 'Cost',
      className: 'cost',
      component: CostCell
    }
  };

  state = {
    tableSort: {}
  };

  onHeaderCellClick = (field, direction) => {
    this.setState({
      tableSort: {field, direction}
    });
  };

  render() {
    const {data, canManage} = this.props;
    const {tableSort} = this.state;
    const rows = getSortedRowsBy(data, tableSort.field, tableSort.direction, this.sortAltFields);

    return (
      <StyledWrapTable>
        <table>
          <HeaderRow headers={this.headers} sort={tableSort} onHeaderCellClick={this.onHeaderCellClick}/>
          <tbody ref="tbody">
          {rows.map((row, index) => {
            return row.RequestID ? (
              <Row
                key={index}
                rowKey={index}
                headers={this.headers}
                row={row}
              />
            ) : (
              <RowWithoutRequest
                key={index}
                row={row}
                canManage={canManage}
              />
            )
          })}
          {!rows.length ? (
            <tr><td className="data-table-empty" colSpan={Object.keys(this.headers).length}>{"No Data"}</td></tr>
          ) : null}
          </tbody>
        </table>
      </StyledWrapTable>
    );
  }
}

const StyledWrapTable = styled.div`
  padding: 30px 0 0 20px;
  @media (max-width: 767px) {
    padding: 0 0 20px 25px;
  }
  > table {
    display: block;
   @media (min-width: 768px) {
    > thead {
      position: sticky;
      top: 0;
      display: block;
      > tr > th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
        padding: 12px;
        text-align: left;
      }
    }
    > tbody {
      max-height: calc(100vh - 59px - 54px - 114px);
      display: block;
      overflow-y: scroll;
      > tr {
        border-bottom: 1px solid #e6e6e6;
        &:hover {
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
        }
        > td {
          padding: 12px;
          &.data-table-empty {
            text-align: center;
            width: 100% !important;
          }
        }
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        -webkit-box-shadow: none;
        background-color: #c4c4c4;
      }
    }
    tr {
      display: block;
      th, td {
        display: block;
        float: left;
        width: calc(100% / 6);
      }
      &:before, &:after {
        display: table;
        content: " ";
        clear: both;
      }
    }
   }
   @media (max-width: 767px) {
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
        td {
          border: none;
          flex-grow: 4;
          font-size: 13px;
          line-height: 14px;
          padding-bottom: 18px;
          &:before {
            clear: both;
            display: block;
            content: '';
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-weight: bold;
            line-height: 16px;
            margin-bottom: 4px;
            color: #282828;
            opacity: 1;
          }
          &.episode-title {
            width: 70%;
            flex-grow: 1;
            order: 1;
            font-size: 15px;
            line-height: 16px;
            padding-bottom: 16px;
            padding-top: 16px;
          }
          &.status {
            width: 30%;
            order: 2;
            flex-grow: 2;
            text-align: right;
            padding-right: 25px;
            padding-bottom: 16px;
            padding-top: 16px;
          }
          &.format {
            width: 100%;
            order: 3;
            flex-grow: 3;
            &:before {
              content: 'Format';
            }
          }
          &.last-update {
            width: 37%;
            order: 4;
            &:before {
              content: 'Last Update';
            }
          }
          &.due-date {
            width: 35%;
            order: 5;
            &:before {
              content: 'Due Date';
            }
          }
          &.cost {
            width: 28%;
            order: 6;
            &:before {
              content: 'Cost';
            }
          }
          &.create-btn-cell {
            order: 7;
            &:before {
              content: 'Create';
              color: transparent;
            }
          }
        }
      }
    }
   }
  }
`;

Table.propTypes = {
  data: PropTypes.array,
  canManage: PropTypes.bool
};

export default Table;
