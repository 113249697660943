import $ from 'jquery';
import config from '../../../../../config';
import {getErrorMessage} from '../../../../utils/general';
import {showError, showSuccess} from '../../../../layouts/actions';

export const ARTWORK_ADMIN_PARAMS_IS_LOADING = 'ARTWORK_ADMIN_PARAMS_IS_LOADING';
export const ARTWORK_ADMIN_PARAMS_IS_SAVING = 'ARTWORK_ADMIN_PARAMS_IS_SAVING';
export const ARTWORK_ADMIN_PARAMS_SET_CURRENT_STUDIO = 'ARTWORK_ADMIN_PARAMS_SET_CURRENT_STUDIO';
export const ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA = 'ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA';
export const ARTWORK_ADMIN_PARAMS_RECEIVE = 'ARTWORK_ADMIN_PARAMS_RECEIVE';
export const ARTWORK_ADMIN_PARAMS_DELETE = 'ARTWORK_ADMIN_PARAMS_DELETE';
export const ARTWORK_ADMIN_PARAMS_TOGGLE_SEGMENT_SPEC_PRM_MODAL = 'ARTWORK_ADMIN_PARAMS_TOGGLE_SEGMENT_SPEC_PRM_MODAL';

export const changeCurrentStudio = currentStudioId => ({type: ARTWORK_ADMIN_PARAMS_SET_CURRENT_STUDIO, currentStudioId});

const setParamModalData = data => ({type: ARTWORK_ADMIN_PARAMS_SET_PARAM_MODAL_DATA, data});

export const openParamModal = param => dispatch => {
  dispatch(setParamModalData(param));
};

export const closeParamModal = () => dispatch => {
  dispatch(setParamModalData(null));
};

export const getParameters = () => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_LOADING,
    loading: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters`
  })
  .done(res => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_RECEIVE,
      ...res,
    });
  })
  .fail(error => {
    dispatch(showError(`Could not get asset parameters.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_LOADING,
      loading: false
    });
  });
};

export const deleteParameter = param => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters/${param.ParameterID}`
  })
  .done(() => {
    dispatch(showSuccess('Parameter has been successfully deleted'));
    dispatch({type: ARTWORK_ADMIN_PARAMS_DELETE, param});
  })
  .fail(error => {
    const message = getErrorMessage(error);
    if (message && message.toLowerCase().indexOf(' is used') !== -1) {
      dispatch(getParameters());
    }
    dispatch(showError(`Could not delete parameter.  ${message}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};

export const saveParameter = param => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_parameters`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(param)
  })
  .done(() => {
    dispatch(showSuccess(`Parameter has been successfully ${param.ParameterID ? 'updated' : 'created'}`));
    dispatch(getParameters());
  })
  .fail(error => {
    dispatch(showError(`Could not save parameter.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};

export const toggleSegmentSpecParamsModal = studioId => ({
  type: ARTWORK_ADMIN_PARAMS_TOGGLE_SEGMENT_SPEC_PRM_MODAL,
  data: studioId ? {studioId} : null
});

export const saveSegmentSpecParams = pattern => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_patterns`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(pattern)
  })
  .done(res => {
    dispatch(showSuccess(`Pattern has been successfully saved`));
    return res;
  })
  .fail(error => {
    dispatch(showError(`Could not save a pattern.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};

export const deleteSegmentSpecParams = pattern => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_patterns/${pattern.patternID}`
  })
  .done(() => {
    dispatch(showSuccess(`Pattern has been successfully deleted`));
  })
  .fail(error => {
    dispatch(showError(`Could not delete a pattern.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
};

export const checkPatternWithFilename = (pattern, companyID, filename) => dispatch => {
  dispatch({
    type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
    saving: true
  });
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/admin/asset_patterns/check`,
    data: {
      pattern: pattern,
      company: companyID,
      filename: filename
    }
  })
  .done(() => {
    dispatch(showSuccess('Filename matches pattern'));
  })
  .fail(error => {
    dispatch(showError('Filename does not match pattern'));
  })
  .always(() => {
    dispatch({
      type: ARTWORK_ADMIN_PARAMS_IS_SAVING,
      saving: false
    });
  });
}