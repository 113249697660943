import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Checkbox, Button} from 'react-bootstrap';
import Table from '../../../../../components/table/Table';
import {getSortedRowsBy, buildReportLink} from '../../../../../utils/general';
import config from '../../../../../../config';
import {QcStatusStyled} from '../../../../RequestDetails/components/Top';

const CheckboxHeaderCell = ({className, isChecked, onChange}) => (
  <th className={className}>
    <Checkbox
      title={isChecked() ? 'Deselect All' : 'Select All'}
      checked={isChecked()}
      onChange={({target: {checked}}) => onChange(checked)}
    />
  </th>
);

const CheckboxCell = ({row, className, isChecked, onChange}) => (
  <td className={className}>
    <Checkbox
      checked={isChecked(row)}
      onChange={({target: {checked}}) => onChange(checked, row)}
    />
  </td>
);

const QcStatusCell = ({className, value, row}) => (
  <td className={className}>
    <QcStatusStyled
      className={
        row.QcStatusID === config.qcStatus.QC_FAILED ? 'failed' :
        row.QcStatusID === config.qcStatus.QC_PASS ? 'pass' :
        row.QcStatusID === config.qcStatus.REVIEW ? 'review' : 'default'
      }
      title="Open Report"
      target="_blank"
      to={buildReportLink(row)}
      onClick={e => e.stopPropagation()}
    >
      {value}
    </QcStatusStyled>
  </td>
);

const BatchReportsTable = props => {
  const {data, onSelect, selected, tableSort, setTableSort, tableFilter, setTableFilter, forManager} = props;

  const filterEntries = Object.entries(tableFilter);
  const filteredData = !filterEntries.length ? data : data.filter(r => {
    return filterEntries.every(([filterName, filterValues]) => {
      const rowValueStr = String(r[filterName]).toLowerCase();
      const selectedOptions = (filterValues || {}).selectedOptions || [];
      if (selectedOptions.length) {
        return selectedOptions.some(o => String(o).toLowerCase() === rowValueStr);
      }
      const searchString = ((filterValues || {}).searchString || '').trim().toLowerCase();
      return !searchString || rowValueStr.indexOf(searchString) !== -1;
    });
  });

  const isFiltered = filterEntries.some(([filterName, filterValues]) => ((filterValues || {}).selectedOptions || []).length || (filterValues || {}).searchString);

  const isSelected = row => selected.some(({RequestID}) => RequestID === row.RequestID);

  const handleSelect = (checked, row) => onSelect(checked ? [...selected, row] : selected.filter(({RequestID}) => RequestID !== row.RequestID));

  const handleRowClick = row => handleSelect(!isSelected(row), row);

  const handleGetFilter = fieldName => ({
    ...tableFilter[fieldName],
    options: data.map(r => r[fieldName])
  });

  const handleFilter = (fieldName, filterName, filterValue) => {
    let fieldVal;
    if (filterName) {
      const newSelectedOptions = filterName === 'selectedOptions' ? filterValue :
        (!filterValue.trim() || !tableFilter[fieldName] || !tableFilter[fieldName].selectedOptions)? (tableFilter[fieldName] || {}).selectedOptions :
        tableFilter[fieldName].selectedOptions.filter(o => String(o).toLowerCase().indexOf(filterValue.trim().toLowerCase()) !== -1);
      fieldVal = {
        ...tableFilter[fieldName],
        selectedOptions: newSelectedOptions,
        [filterName]: filterValue
      };
    }
    setTableFilter({
      ...tableFilter,
      [fieldName]: fieldVal
    });
  };

  const headers = {
    RequestID: {
      title: '',
      headCell: {
        component: CheckboxHeaderCell,
        componentArgs: {
          className: 'checkbox-cell',
          onChange: checked => onSelect(checked ? filteredData.filter(r => r.QcStatus) : []),
          isChecked: () => {
            const allLength = filteredData.filter(r => r.QcStatus).length;
            return selected.length === allLength && allLength > 0;
          }
        }
      },
      className: 'checkbox-cell',
      component: CheckboxCell,
      componentArgs: {
        isChecked: isSelected,
        onChange: handleSelect
      }
    },
    ...(forManager ? {
    WOMemfis: {
      title: 'WO Memfis',
      className: 'wo-memfis',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    }} : {}),
    Title: {
      title: 'Title',
      className: 'wo-title',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    AdditionalDescription: {
      title: 'Additional Description',
      className: 'wo-description',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    RequestTitle: {
      title: 'Request Title',
      className: 'request-title',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    Tag: {
      title: 'Tag',
      className: 'request-tag',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    RequestFormat: {
      title: 'Format',
      className: 'request-format',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    Revision: {
      title: 'Revision',
      className: 'request-revision',
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
    QcStatusFullName: {
      title: 'QC Status',
      className: 'request-qc-status',
      component: QcStatusCell,
      headCellArgs: {
        sortable: true,
        filterable: true,
        getFilter: handleGetFilter,
        onFilter: handleFilter,
      }
    },
  };

  const handleHeaderCellClick = (field, direction) => {
    if (field !== 'RequestID') {
      setTableSort({field, direction});
    }
  };

  return (
    <div className="table-container midl_in">
      <TableContainer
        isFiltered={isFiltered}
        forManager={forManager}
      >
        <div className="table-buttons-container">
          <Button
            onClick={() => setTableFilter({})}
          >
            Clear Filters
          </Button>
        </div>
        <Table
          stripped={false}
          headers={headers}
          rows={getSortedRowsBy(filteredData, tableSort.field, tableSort.direction)}
          sort={tableSort}
          onHeaderCellClick={handleHeaderCellClick}
          onRowClick={row => row.QcStatus ? handleRowClick(row) : undefined}
          getRowClassNames={row => isSelected(row) ? 'is-selected' : !row.QcStatus ? 'is-disabled' : undefined}
          emptyText="No Work Orders"
        />
      </TableContainer>
    </div>
  );
};

const TableContainer = styled.div`
  padding-bottom: 15px;
  max-width: 100%;
  min-height: calc(260px + 34px);
  overflow-x: auto;
  @media (min-width: 768px) {
    overflow: hidden;
  }

  .table-buttons-container {
    margin-bottom: 10px;
    width: fit-content;
    ${props => !props.isFiltered && css`
      visibility: hidden;
    `}
    .btn {
      padding: 6px 12px;
      width: fit-content;
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      outline: none !important;
      color: #29a4cb;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      &[disabled] {
        display: none;
      }
      &:not([disabled]):hover {
        color: #228daf;
      }
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    padding-right: 12px;

    @media (min-width: 768px) {
      thead {
        margin-right: -12px;
        padding-right: 22px;
        display: block;
        /*overflow-y: scroll;*/
      }
      tbody {
        margin-right: -12px;
        padding-right: 5px;
        min-height: 225px;
        max-height: calc(100vh - 210px - 34px);
        display: block;
        overflow-y: scroll;
      }
      tr {
        display: block;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        &:before, &:after {
          display: table;
          content: " ";
          clear: both;
        }
      }
      th, td {
        display: block;
        float: left;
        border: none !important;
        &.wo-memfis {
          width: 115px;
        }
        &.request-title,
        &.wo-title,
        &.wo-description {
          width: 18%;
        }
        &.request-format,
        &.request-tag {
          ${props => props.forManager && css`
            width: calc((46% - 38px - 115px - 110px - 110px) / 2);
          `}
          ${props => !props.forManager && css`
            width: calc((46% - 38px - 110px - 110px) / 2);
          `}
        }
        &.request-revision {
          width: 110px;
        }
        &.request-qc-status {
          width: 110px;
        }
      }
      td {
        word-break: break-all;
      }
    }

    tbody tr {
      &:not(.is-disabled) {
        &:hover,
        &.is-selected {
          background-color: #f9f9f9;
          a {
            &:hover {
              background-color: #fff;
              box-shadow: -5px -3px 0 #fff, 5px -3px 0 0 #fff, -5px 3px 0 #fff, 5px 3px 0 0 #fff;
            }
          }
        }
      }
      &.is-disabled {
        td {
          &:not(.request-qc-status) {
            opacity: 0.65;
            pointer-events: none;
          }
          &.request-qc-status {
            a:not(:hover) {
              opacity: 0.75;
            }
          }
        }
      }
    }

    th, td {
      border: none;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      color: #282828;
      text-align: left;
      &.checkbox-cell {
        width: 38px;
        > .checkbox {
          margin: 0;
          max-width: 13px;
          max-height: 13px;
          label {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 13px;
          }
          input[type="checkbox"] {
            margin-top: 0;
          }
        }
      }
    }

    th {
      padding: 3px 10px;
      vertical-align: top;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      white-space: nowrap;
      &:not(.checkbox-cell) {
        &:hover {
          color: #6a6a6a;
        }
      }
      &.request-revision,
      &.request-qc-status {
        .dropdown-menu {
          left: unset !important;
          right: 0 !important;
        }
      }
    }

    td {
      padding: 6px 10px;
      vertical-align: top;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      &.data-table-empty {
        width: 100% !important;
        text-align: center !important;
        @media (max-width: 767px) {
          text-align: left !important;
        }
      }
    }
  }
`;

BatchReportsTable.propTypes = {
  data: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  tableSort: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  tableFilter: PropTypes.object.isRequired,
  setTableFilter: PropTypes.func.isRequired,
  forManager: PropTypes.bool.isRequired,
};

export default BatchReportsTable;
