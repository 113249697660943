import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import {parseRuntimeInSec, formatDurationAsMinutes} from '../../../../utils/general';

class RuntimeInput extends Component {
  resetValues = false;
  timer = null;

  prepareValue = seconds => {
    const runtime = parseRuntimeInSec(seconds) || {};
    const {showFullWithoutMillis} = this.props;
    return showFullWithoutMillis ? (runtime.full || '').replace(/:00$/, '') : (runtime.runtimeWithColon || '');
  };

  state = {
    value: this.prepareValue(this.props.value),
    hint: formatDurationAsMinutes(this.props.value)
  };

  clearTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  resetFilter(value) {
    this._input.value = value || '';
  }

  componentDidUpdate(prevProps) {
    const {value, reset, forceReset} = this.props;
    if (value !== prevProps.value/* && this.prepareValue(prevProps.value) === this.state.value*/) {
      this.setState({value: this.prepareValue(value), hint: formatDurationAsMinutes(value)});
    } else if (forceReset || ((JSON.stringify(this.props) !== JSON.stringify(prevProps)) && reset)) {
      this.resetFilter(this.prepareValue(value));
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  convertRuntimeToSec = runtimeWithColon => {
    if (runtimeWithColon) {
      runtimeWithColon = runtimeWithColon.replace(/[^0-9:]/g, '').replace(/^:+/, '').replace(/:+$/, '');
    }
    if (!runtimeWithColon || !runtimeWithColon.match(/^(\d+:){0,}\d+$/)) {
      return null;
    }
    const temp = runtimeWithColon.split(':').slice(0, 3);
    const sec = temp.length > 1 ? Number(temp[temp.length - 1]) : 0;
    const min = Number(temp[temp.length - (temp.length > 1 ? 2 : 1)]);
    return sec + (min * 60) + (temp.length === 3 ? Number(temp[0]) * 60 * 60 : 0);
  };

  applyChange = () => {
    this.clearTimeout();
    const {name, onChange} = this.props;
    const value = this._input.value;
    const seconds = value ? this.convertRuntimeToSec(value) : null;
    const preparedValue = this.prepareValue(seconds);
    this.resetValues = typeof seconds !== 'number' || seconds === this.props.value || preparedValue !== value;
    onChange(name, seconds, parseRuntimeInSec(seconds));
    this.setState({value: preparedValue, hint: formatDurationAsMinutes(seconds)});
    if (this.resetValues) {
      this.forceUpdate();
    }
  };

  handleChange = event => {
    this.clearTimeout();
    this.timer = setInterval(() => {
      this.applyChange();
    }, 1000);

    this.resetValues = false;
    this.setState({value: event.target.value});
  };

  render() {
    const {name, showFullWithoutMillis, label, withoutLabel, disabled} = this.props;
    const {value, hint} = this.state;
    return (
      <FormGroup>
        {withoutLabel ? null : <ControlLabel>{label || 'Runtime'}</ControlLabel>}
        <FormControl
          type="text"
          name={name}
          placeholder={showFullWithoutMillis ? '00:00:00' : '00:00'}
          value={value}
          title={hint}
          onChange={this.handleChange}
          onBlur={this.applyChange}
          inputRef={ref => {
            this._input = ref;
          }}
          disabled={disabled === true}
        />
      </FormGroup>
    );
  }
}

RuntimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  reset: PropTypes.bool,
  forceReset: PropTypes.bool,
  showFullWithoutMillis: PropTypes.bool,
  label: PropTypes.string,
  withoutLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default RuntimeInput;
