import $ from 'jquery';
import config from '../../../../config';
import {getErrorMessage} from '../../../utils/general';
import {showError, showSuccess} from '../../../layouts/actions';

export const ADMIN_OP_SCHEDULE_CLEAR = 'ADMIN_OP_SCHEDULE_CLEAR';
export const ADMIN_OP_SCHEDULE_IS_LOADING = 'ADMIN_OP_SCHEDULE_IS_LOADING';
export const ADMIN_OP_SCHEDULE_IS_SAVING = 'ADMIN_OP_SCHEDULE_IS_SAVING';
export const ADMIN_OP_SCHEDULE_RECEIVE = 'ADMIN_OP_SCHEDULE_RECEIVE';
export const ADMIN_OP_SCHEDULE_SELECT_WEEK = 'ADMIN_OP_SCHEDULE_SELECT_WEEK';
export const ADMIN_OP_SCHEDULE_CHANGE_SCHEDULE = 'ADMIN_OP_SCHEDULE_CHANGE_SCHEDULE';

export const clearAll = () => ({type: ADMIN_OP_SCHEDULE_CLEAR});

export const getSchedules = (scheduleType, isInitialLoading) => dispatch => {
  dispatch({type: ADMIN_OP_SCHEDULE_IS_LOADING, loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/operator_schedule/${scheduleType}`
  })
  .done(res => {
    dispatch({type: ADMIN_OP_SCHEDULE_RECEIVE, ...res});
  })
  .fail(error => {
    dispatch({type: ADMIN_OP_SCHEDULE_IS_LOADING, loading: false});
    if (isInitialLoading) {
      dispatch(showError(`Could not load a ${scheduleType} schedule.  ${getErrorMessage(error)}`));
    }
  });
};

export const updateMasterSchedule = schedule => dispatch => {
  dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/operator_schedule/master`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(schedule)
  })
  .done(() => {
    dispatch(getSchedules('master'));
    dispatch(showSuccess(`Schedule has been successfully updated`));
  })
  .fail(error => {
    dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: false});
    dispatch(showError(`Could not update a master schedule.  ${getErrorMessage(error)}`));
  });
};

export const updateWeeklySchedule = (week, schedule) => dispatch => {
  dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/operator_schedule/weekly`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({week, schedule})
  })
  .done(() => {
    dispatch(getSchedules('weekly'));
    dispatch(showSuccess(`Schedule has been successfully updated`));
  })
  .fail(error => {
    dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: false});
    dispatch(showError(`Could not update a weekly schedule.  ${getErrorMessage(error)}`));
  });
};

export const resetWeeklySchedule = week => dispatch => {
  dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/qc_on_demand/admin/operator_schedule/weekly/reset`,
    data: {
      week
    }
  })
  .done(() => {
    dispatch(getSchedules('weekly'));
    dispatch(showSuccess(`Schedule successfully reset`));
  })
  .fail(error => {
    dispatch({type: ADMIN_OP_SCHEDULE_IS_SAVING, loading: false});
    dispatch(showError(`Could not reset a weekly schedule.  ${getErrorMessage(error)}`));
  });
};

export const selectWeek = week => ({type: ADMIN_OP_SCHEDULE_SELECT_WEEK, week});

export const changeSchedule = schedule => ({type: ADMIN_OP_SCHEDULE_CHANGE_SCHEDULE, schedule});
