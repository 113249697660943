/* eslint "complexity":"off" */
import {USER_SET_IS_LOGOUT} from '../../../pages/Login/actions';
import {
  NET_REQUEST_DETAILS_CLEAR,
  NET_REQUEST_DETAILS_SET_ACTIVE_TAB,
  NET_REQUEST_DETAILS_SET_LOADING,
  NET_REQUEST_DETAILS_UPDATE,
  NET_REQUEST_DETAILS_RECEIVE,
  NET_REQUEST_DETAILS_WO_STEPS_RECEIVE,
  NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL,
  NET_REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS,
} from './actions';
import {DEFAULT_TAB} from './components/tabs/Tabs';

const initialState = {
  activeTab: DEFAULT_TAB,
  loaders: {
    isLoadingDetails: false,
    isSavingDetails: false,
    isLoadingWoSteps: false,
    isLoadingWoStepDictionaries: false,
    isSavingWoSteps: false,
  },
  filters: {},
  details: null,
  costDetails: null,
  woSteps: [],
  woStepsModalData: {
    isShow: false,
    filters: {
      step_assignees: [],
      step_statuses: [],
      step_categories: [],
      product_codes: [],
      product_codes_for_profile: []
    }
  },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case USER_SET_IS_LOGOUT:
    case NET_REQUEST_DETAILS_CLEAR: {
      return {...initialState};
    }

    case NET_REQUEST_DETAILS_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab
      };
    }

    case NET_REQUEST_DETAILS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case NET_REQUEST_DETAILS_UPDATE: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data
        }
      };
    }

    case NET_REQUEST_DETAILS_RECEIVE: {
      return {
        ...state,
        filters: action.filters || initialState.filters,
        details: action.details
      };
    }

    case NET_REQUEST_DETAILS_WO_STEPS_RECEIVE: {
      const {wo_steps, cost_details} = action.data || {};
      return {
        ...state,
        woSteps: wo_steps || initialState.woSteps,
        costDetails: cost_details || initialState.costDetails
      };
    }

    case NET_REQUEST_DETAILS_TOGGLE_WO_STEPS_MODAL: {
      return {
        ...state,
        woStepsModalData: action.isShow ? {
          ...initialState.woStepsModalData,
          isShow: true
        } : initialState.woStepsModalData
      };
    }

    case NET_REQUEST_DETAILS_GET_DICTIONARIES_FOR_EDIT_STEPS: {
      return {
        ...state,
        woStepsModalData: {
          ...state.woStepsModalData,
          filters: action.filters || initialState.woStepsModalData.filters
        }
      };
    }

    default:
      return state;
  }
}
