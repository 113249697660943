import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const LinkRequestCell = (props) => {
  const {value, row, className, title} = props;
  const url = value && row.RequestID && `/requests/details/${row.RequestID}`;
  return (
    <StyledLinkRequestCell
      className={className}
      title={title}
    >
      {url ? <Link title="Open Request" to={url} target="_blank">{value}</Link> : value}
    </StyledLinkRequestCell>
  );
};

const StyledLinkRequestCell = styled.td`
  a {
    color: #29A4CB;
    font-weight: bold;
    &:hover {
      color: #228daf;
    }
  }
`;

LinkRequestCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string
};

export default LinkRequestCell;
