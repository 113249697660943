import axios from 'axios';
import Cookies from 'js-cookie';
import * as FullStory from '@fullstory/browser';
import smartlookClient from 'smartlook-client';
import {getCurrentDomainObj, buildRedirectUrlToLoginPage} from './general';
import config from '../../config';
import configureStore from '../store';
import {savePreviousPageUrl} from '../pages/Login/actions';

export const params = (object, p = false, v = true) => {
  return Object.keys(object)
    .map(key => {
      const value = object[key] !== null && object[key] !== undefined ? object[key] : '';
      if (Array.isArray(value)) {
        return value.map(x => `${key}${p ? '[]' : ''}=${x}`).join('&');
      }
      return `${key}=${v && value && typeof value === 'string' ? encodeURIComponent(value) : value}`;
    })
    .join('&');
};

const controller = new AbortController();

const axiosInstance = axios.create({
  signal: controller.signal,
  baseURL: config.apiUrl,
  withCredentials: true
});

const authHeaderVal = Cookies.get('__auth', getCurrentDomainObj());
if (authHeaderVal) {
  axiosInstance.defaults.headers.common.Authorization = authHeaderVal;
}

axiosInstance.interceptors.request.use(
  async reqConfig => {
    if (!Cookies.get('__studio', getCurrentDomainObj()) && reqConfig.alwaysAllowed !== true) {
      controller.abort();
    } else if (reqConfig.refreshRetry !== true) {
      const accessToken = Cookies.get('__auth', getCurrentDomainObj());
      if (accessToken) {
        let payload = '';
        let tokenData = {};
        try {
          payload = accessToken.split('.')[1]
          if (payload) {
            tokenData = JSON.parse(atob(payload))
          }
        } catch (error) {
          !!config.fullStoryOrgId && FullStory.anonymize();
          window.location.href = buildRedirectUrlToLoginPage();
        }

        if (tokenData) {
          const tokenExpDate = tokenData.exp || 0;
          const accessTokenExpDate = Math.floor(tokenExpDate / 1000);

          const nowTime = Math.floor(new Date().getTime() / 1000)

          const isAccessTokenExpired = (nowTime - accessTokenExpDate) > 0;

          if (isAccessTokenExpired) {
            const newConfig = {...reqConfig};

            const newTokenResponse = await axios({
              method: 'POST',
              headers: {
                'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
                Authorization: Cookies.get('__auth', getCurrentDomainObj())
              },
              url: `${config.apiUrl}/auth/refresh-token`,
              withCredentials: true
            }).catch(error => {
              !!config.fullStoryOrgId && FullStory.anonymize();
              !!config.smartlookId && smartlookClient.disable();
              Cookies.remove('__auth', getCurrentDomainObj());
              window.location.href = buildRedirectUrlToLoginPage();
            });

            if (config.smartlookId) {
              const roles = Cookies.get('__role', getCurrentDomainObj());
              if (roles && (roles.indexOf(config.roles.CLIENT_SERVICE) >= 0 || roles.indexOf(config.roles.OPERATOR) >= 0 || roles.indexOf(config.roles.OPS_MANAGER) >= 0)) {
                smartlookClient.record({ forms: true, numbers: true, emails: true, ips: true });
              }

              const userName = Cookies.get('__username', getCurrentDomainObj());
              const userEmail = Cookies.get('__email', getCurrentDomainObj());
              userEmail && smartlookClient.identify(userEmail, {
                name: userName,
                userEmail
                // other custom properties
              });
            }

            const authorization = `Bearer${newTokenResponse.data.access_token}`;

            newConfig.headers.common.Authorization = authorization;
            axiosInstance.defaults.headers.common.Authorization = authorization;

            Cookies.remove('__auth', getCurrentDomainObj());
            Cookies.set('__auth', authorization, getCurrentDomainObj());

            return newConfig;
          }
        }
      }
    }

    return reqConfig;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return (response || {}).data;
  },
  error => {
    if (error.response) {
      const res = error.response;
      if (res && res.status === 401) {
        if (window.location.pathname !== '/login') {
          configureStore({}).dispatch(savePreviousPageUrl());
          !!config.fullStoryOrgId && FullStory.anonymize();
          !!config.smartlookId && smartlookClient.disable();
          window.location.href = buildRedirectUrlToLoginPage();
        }
        return Promise.reject(error);
      }
      if (res &&
        res.data &&
        res.data.message) {
        console.error(res.data.message);
      } else if (
        res &&
        res.data &&
        res.data.errors &&
        res.data.errors.length &&
        res.data.errors[0].message) {
        console.error(res.data.errors[0].message);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
