import React from 'react';
import PropTypes from 'prop-types';

export const Cell = ({value, className, title}) => (
  <td className={className} title={typeof title === 'string' ? title : ''}>{value}</td>
);

Cell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.any
};
