import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {ButtonGroup, Button} from 'react-bootstrap';

class ButtonsGroup extends Component {
  groupChange = newValue => {
    const {value, onChange} = this.props;
    if (value !== newValue) {
      onChange(newValue);
    }
  };

  render() {
    const {btns, value} = this.props;
    return (
      <StyledButtonsGroup
        className={btns && btns.length > 4 ? 'btn-group-scrollable' : undefined}
      >
        {btns && btns.length ? btns.map((item, index) => {
          return (
            <Button
              key={index}
              active={+value === item.id}
              variant="secondary"
              onClick={() => this.groupChange(item.id)}
            >{item.title}</Button>
          );
        }) : null}
      </StyledButtonsGroup>
    );
  }
}

const StyledButtonsGroup = styled(ButtonGroup)`
  display: flex;
  margin-top: 24px;
  justify-content: center;
  .btn {
    color: #9F9F9F;
    background: #dedede;
    border: 1px solid #CDCDCD;
    box-shadow: none;
    font-size: 10px;
    padding: 4px 10px;
    &.active {
      background: #FFFFFF;
      border: 1px solid #CDCDCD;
      color: #282828;
      font-weight: bold;
      opacity: 1;
      z-index: 0;
      &:focus, &:hover {
        opacity: 0.7;
        outline: none;
      }
    }
    &:focus, &:hover {
      border: 1px solid #CDCDCD;
      opacity: 1;
      outline: none;
    }
  }
  &.btn-group-scrollable {
    @media (max-width: 390px) {
      margin-left: 1%;
      margin-right: 1%;
      max-width: 98%;
      overflow-x: auto;
      justify-content: flex-start;
    }
  }
`;

ButtonsGroup.propTypes = {
  btns: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default ButtonsGroup;
