import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../../../components/Loader';
import EstimatedCost from '../../NewRequest/components/forms/EstimatedCost';

function CostDetails(props) {
  const {isLoading, data, isSeasonRequest} = props;

  return (
    <StyledCostDetails>
      {isLoading ? <Loader/> : null}
      {isLoading && !data ? null :
        <EstimatedCost
          data={data}
          isSeasonRequest={isSeasonRequest}
        />
      }
    </StyledCostDetails>
  );
}

const StyledCostDetails = styled.div`
  position: relative;
  padding-bottom: 15px;
  min-height: 80px;

  @media (min-width: 768px) {
    width: 80%;
  }

  > div {
    margin: 0;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    + div {
      opacity: 0.5;
      min-height: 45px;
    }
  }
`;

CostDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  isSeasonRequest: PropTypes.bool.isRequired
};

export default CostDetails;
