import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StudiosTable from '../table/StudiosTable';
import styled from 'styled-components';

class StudiosForPartner extends Component {
  render() {
    const {data, filters, onSelect} = this.props;
    return (
      <StepBodyStyled>
        <StudiosTable
          data={filters.studios}
          onSelect={id => onSelect('studio_id', id)}
          selected={data.studio_id}
          />
      </StepBodyStyled>
    );
  }
}

const StepBodyStyled = styled.div`
  margin: 29px 20px 10px 73px;
`;

StudiosForPartner.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

StudiosForPartner.defaultProps = {
  data: {}
};

export default StudiosForPartner;
