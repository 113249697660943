import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal, Button, Clearfix} from 'react-bootstrap';
import styled from 'styled-components';
import RadioButtonsAdvanced from '../../../components/forms/RadioButtonsAdvanced';
import SelectAdvanced from '../../../components/forms/SelectAdvanced';
import {showClientServiceCreateRequestModal} from '../../actions';
import config from '../../../../config';
import {getSortedRowsBy} from '../../../utils/general';

class ClientServiceCreateRequestModal extends Component {
  state = {
    userType: null,
    companyId: null
  };

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(showClientServiceCreateRequestModal(false));
  };

  handleSubmit = () => {
    const {userType, companyId} = this.state;
    const {dispatch, history} = this.props;
    dispatch(showClientServiceCreateRequestModal(false));
    const paramName = userType === config.roles.STUDIO ? 'studio' : userType === config.roles.PRODUCTION ? 'production' : 'finishing_house';
    const urlParams = `?${`cs_${paramName}=${companyId}`}`;
    history.push(`/requests/new${urlParams}`);
  };

  handleSelectCompany = (_, value) => {
    this.setState({companyId: value});
  };

  handleSelectUserType = (_, value) => {
    this.setState({userType: value, companyId: null});
  };

  render() {
    const {studios, productions, finishing_houses} = this.props.data;
    const {companyId, userType} = this.state;
    return (
      <Modal
        className="cs-create-request-modal"
        show={true}
        onHide={this.handleClose}
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>Select Studio/Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContainerStyled className="container-fluid">
            <Clearfix>
              <RadioButtonsAdvanced
                label="User Type"
                labelSeparator=""
                name="user_type"
                options={[
                  {value: config.roles.STUDIO, name: 'Studio'},
                  {value: config.roles.PRODUCTION, name: 'Production'},
                  {value: config.roles.FINISHING_HOUSE, name: 'Post Partner'}
                ]}
                value={userType}
                onChange={this.handleSelectUserType}
              />
            </Clearfix>
            {
              userType === config.roles.STUDIO ?
                <SelectAdvanced
                  label="Studio"
                  labelSeparator=""
                  options={getSortedRowsBy(studios, 'name')}
                  value={companyId}
                  onSelect={this.handleSelectCompany}
                /> :
                  userType === config.roles.PRODUCTION ?
                    <SelectAdvanced
                      label="Production"
                      labelSeparator=""
                      options={getSortedRowsBy(productions, 'name')}
                      value={companyId}
                      onSelect={this.handleSelectCompany}
                    /> : userType === config.roles.FINISHING_HOUSE &&
                      <SelectAdvanced
                        label="Post Partner"
                        labelSeparator=""
                        options={finishing_houses}
                        value={companyId}
                        onSelect={this.handleSelectCompany}
                      />
            }
          </ModalContainerStyled>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStyled
            bsStyle="primary"
            onClick={this.handleSubmit}
            disabled={!companyId}
            >
            Submit
          </ButtonStyled>
          <ButtonStyled bsStyle="default" onClick={this.handleClose}>Cancel</ButtonStyled>
        </Modal.Footer>
      </Modal>
    );
  }
}

const ButtonStyled = styled(Button)`
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-radius: 100px;

    &.btn-primary {
      padding-left: 20px;
      padding-right: 20px;
      background: #33333F;
      opacity: 0.8;

      &:disabled, &.disabled {
        background: #e0e0e1;
        color: #c8c8c8;
        cursor: default;
      }

      &:not([disabled]):hover {
        opacity: 1;
      }
    }
`;

const ModalContainerStyled = styled.div`
`;

ClientServiceCreateRequestModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default connect()(withRouter(ClientServiceCreateRequestModal));
