import $ from 'jquery';
import config from '../../../../../config';

export const ARTWORK_TOP_FILTERS_CLEAR = 'ARTWORK_TOP_FILTERS_CLEAR';
export const ARTWORK_TOP_FILTERS_CHANGE = 'ARTWORK_TOP_FILTERS_CHANGE';
export const ARTWORK_TOP_FILTERS_SET_LOADING = 'ARTWORK_TOP_FILTERS_SET_LOADING';
export const ARTWORK_TOP_FILTERS_SET_DICTIONARIES = 'ARTWORK_TOP_FILTERS_SET_DICTIONARIES';

export const clearAllTopFilters = () => ({type: ARTWORK_TOP_FILTERS_CLEAR});

export const changeTopFilters = (values) => ({type: ARTWORK_TOP_FILTERS_CHANGE, values});

export const getDictionaries = () => (dispatch, getState) => {
  const loadingName = 'dictionaries';
  dispatch({type: ARTWORK_TOP_FILTERS_SET_LOADING, name: loadingName, loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/qc_on_demand/artwork/dictionaries`,
  })
  .then(res => {
    dispatch({type: ARTWORK_TOP_FILTERS_SET_DICTIONARIES, dictionaries: res});
  })
  .then(() => [getState().artworkTopFilters.dictionaries, getState().artworkTopFilters.values])
  .then(([dictionaries, values]) => {
    const {
      titleId,
      projectId,
      amazonSeriesId,
      amazonConceptName,
      amazonAssetType,
    } = values;
    if (!(titleId || projectId || amazonSeriesId || amazonConceptName || amazonAssetType)) {
      return;
    }
    const t = {
      titleId: 'titles',
      projectId: 'projects',
      amazonSeriesId: 'amazon_series',
      amazonConceptName: 'amazon_concept_names',
      amazonAssetType: 'amazon_asset_types',
    };
    Object.keys(t).forEach(k => {
      if (['titleId', 'projectId', 'amazonSeriesId'].includes(k)) {
        const options = (k === 'amazonSeriesId' ? (dictionaries.amazon_filters || {})[t[k]] : dictionaries[t[k]]) || [];
        if (!options.some(o => o.value === +values[k])) {
          values[k] = 0;
        }
      } else if (['amazonConceptName', 'amazonAssetType'].includes(k)) {
        const options = (dictionaries.amazon_filters || {})[t[k]] || [];
        if (!options.some(o => o.value === values[k])) {
          values[k] = null;
        }
      }
    });
    const pathname = window.location.pathname.replace(/\W/g, '').toLowerCase();
    const localStorageFilterName = 'artwork' + pathname.charAt(0).toUpperCase() + pathname.substring(1) + 'TopFilters';
    if (localStorage.getItem(localStorageFilterName)) {
      localStorage.setItem(localStorageFilterName, JSON.stringify(values));
    }
    dispatch(changeTopFilters(values));
  })
  .always(() => {
    dispatch({type: ARTWORK_TOP_FILTERS_SET_LOADING, name: loadingName, loading: false});
  });
};
