import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReportsTable from '../table/ReportsTable';
import {QC_REQUEST_TYPES} from '../../../NewRequest/constants';

class OriginalReport extends Component {
  UNSAFE_componentWillMount() {
    const {onLoadReports, reports} = this.props;
    if (!reports) {
      onLoadReports();
    }
  }

  render() {
    const {data, reports, onSelect} = this.props;
    return (
      <div className="step-body-wrapper original-report">
        <ReportsTable
          data={reports || []}
          onSelect={report => {
            onSelect('origin_request_id', report.RequestID);
            onSelect('origin_request', report);
            if (data.request_type_id === QC_REQUEST_TYPES.Episode.id) {
              onSelect('general', {...data.general, runtime_in_sec: null, episode_number: null, episode_name: null});
            } else if (data.replacement) {
              onSelect('replacement', {...data.replacement, general_note: null, file_creation_note: null, file_delivery_note: null});
            }
          }}
          selected={data.origin_request_id}
          requestTypeId={data.request_type_id}
          />
      </div>
    );
  }
}

OriginalReport.propTypes = {
  data: PropTypes.object.isRequired,
  reports: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onLoadReports: PropTypes.func.isRequired
};

OriginalReport.defaultProps = {
  data: {}
};

export default OriginalReport;
