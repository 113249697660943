import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {StyledModalBackdrop, StyledModalDialog, StyledModal, StyledBaseCloseModal, StyledMobileModal} from '../../../../styled/ModalStyled';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/scheduleTable/Table';
import {Button} from 'react-bootstrap';
import isMobile from 'is-mobile';
import Title from '../../../SeasonDetails/components/season/Title';

const EditScheduleModal = props => {
  const {
    loading, details, headers, milestones, lockScheduling, editDateModal, onUpdateModal, onUpdateDate, onLockRow,
    onClose
  } = props;

  const [showScheduledDates, setShowScheduledDates] = useState(false);

  const handleDone = () => {
    alert('Done!');
  }

  const handleClose = () => {
    onClose(false);
  }

  return (
    <StyledMobileModal>
      <StyledModalBackdrop
        onClick={handleClose}
      />
      <StyledModalDialog className="mobile-modal-dialog-custom">
        {loading ? <Loader/> : null}
        <StyledModal>
          <StyledModalHeader className="modal-header-custom">
            <div className="visible-xs back-btn" onClick={handleClose}/>
            <div className="modal-header-content">
              <h4>Feature Schedule</h4>
              <div>
                <StyledBaseCloseModal className="icon-close-modal" onClick={handleClose}/>
              </div>
            </div>
          </StyledModalHeader>
          <StyledModalBody className="modal-body-custom">
            {
              isMobile() ?
                <Title
                  className="show-scheduled-dates-title"
                  showScheduledDates={showScheduledDates}
                  onChangeShow={(_, value) => {setShowScheduledDates(value)}}
                /> : null
            }
            <Table
              headers={headers}
              rows={[{
                RequestID: details.RequestID,
                ProjectID: details.QcProjectID,
                Title: details.Title,
                lock_scheduling: lockScheduling,
                milestones
              }]}
              rowTitle={{
                name: 'Title',
                id: 'Title'
              }}
              editDateModal={editDateModal}
              showScheduledDates={isMobile() ? showScheduledDates : true}
              onUpdateModal={onUpdateModal}
              onUpdateDate={onUpdateDate}
              onLockRow={onLockRow}
            />
            <Button
              className="hidden-xs"
              bsStyle="primary"
              onClick={handleDone}
              disabled={false}
              >
              Done
            </Button>
            <Button
              className="hidden-xs"
              bsStyle="default"
              onClick={handleClose}
              >
              Cancel
            </Button>
          </StyledModalBody>
        </StyledModal>
      </StyledModalDialog>
    </StyledMobileModal>
  );
};

const StyledModalHeader = styled.div`
  padding: 31px 35px 0 45px;
  position: fixed;
  top: 0;
  right: 0;
  left: 10%;
  z-index: 1060;
  .modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  h4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #282828;
    margin: 10px 0 0;
  }
  h4 + div {
    padding: 8.5px 0 8.5px 23px;
    @media (max-width: 767px) {
      padding:0 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }
`;

const StyledModalBody = styled.div`
  position: fixed;
  top: 104px;
  bottom: ${props => (props.bottom || '71px')};
  right: 0;
  left: 10%;
  z-index: 1060;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 46px;
  padding-right: 27px;
  -webkit-overflow-scrolling: touch;

  button {
    background-color: #6f6f6f;
    border: none;
    border-radius: 100px;
    box-shadow: none;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    margin-top: 41px;
    margin-right: 22px;
    outline: none;
    padding: 11px 34px 12px;
    text-align: center;
    &.btn-primary {
      &:hover {
        background-color: #565656;
      }
    }
    &.btn-default {
      background-color: transparent;
      color: #282828;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .loader-container {
    z-index: 1070;
  }

  @media (max-width: 991px) {
    left: 2.66%;
  }

  @media (max-width: 767px) {
    padding: 0;
    bottom: 0;
    table tbody tr {
      border-bottom: none;
    }
  }

  .show-scheduled-dates-title {
    padding-top: 0;
    h2 {
      display: none;
      color: #fff;
    }
  }
`;

EditScheduleModal.propTypes = {
  details: PropTypes.object.isRequired,
  headers: PropTypes.array,
  milestones: PropTypes.array,
  lockScheduling: PropTypes.number.isRequired,
  editDateModal: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onUpdateModal: PropTypes.func.isRequired,
  onUpdateDate: PropTypes.func.isRequired,
  onLockRow: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default EditScheduleModal;
